import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../../../../environments/pom/Environment'

const mutation = graphql`
  mutation UpdateFilterMutation(
    $gridId: ID!
    $grid: String!
    $filter: String!
    $settings: JSON!
    $companyId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        GridFilter(id: $gridId) {
          update(grid: $grid, filter: $filter, settings: $settings) {
            id
            tableId
            grid
            filter
            settings
          }
        }
      }
    }
  }
`

export default (variables, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
