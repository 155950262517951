import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'

import { Routes } from '../../configs/RouteConfig'
import { useRouteMatch } from 'react-router-dom'
import NewsletterForm from './NewsletterForm'
import SupportContainer from '../Support/SupportContainer/SupportContainer'

const Newsletter = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { url } = useRouteMatch('/:lang')
  return (
    <SupportContainer
      prevStep={url + Routes.overview}
      title={props.t('NEWSLETTER REGISTER')}
      // eslint-disable-next-line
      // prettier-ignore
      description={props.t('REGISTER HERE IF YOU ALWAYS WANT TO STAY UP TO DATE')}
      form={<NewsletterForm />}
      {...props}
    />
  )
}

export default withTranslation('translation')(Newsletter)
