import React, { useContext, useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

import {
  ButtonPrimary,
  portalTheme,
  RouteLink,
  SimpleNote,
  StyledInput,
} from 'pyrexx-react-library'
import SimpleLink from '../../components/Simple/SimpleLink'

import {
  ContainerStyled,
  DescriptionStyled,
  StyledCol,
  TitleStyled,
} from './Login.styles'
import AuthenticateMutation from '../../mutations/AuthenticateMutation'
import { Routes } from '../../configs/RouteConfig'
import UserContext from '../../helper/userContext'
import SimpleButton from '../../components/Simple/SimpleButton'

const Login = () => {
  const {
    handleLoginRequestData,
    currentLanguage,
    getCompanySwitcherRedirectIfNeeded,
    user,
    isAuth,
    redirectRoute,
  } = useContext(UserContext)
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()

  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    status: '',
    message: '',
  })

  const schema = Yup.object({
    username: Yup.string().required(t('IS REQUIRED')),
    password: Yup.string().required(t('IS REQUIRED')),
  })

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: (values, formikb) => {
      setSubmitStatus({ ...submitStatus, loading: true })
      const { username, password } = values
      AuthenticateMutation(
        username,
        password,
        async (requestData) => {
          const isLoginSuccessful = await handleLoginRequestData(requestData)

          if (isLoginSuccessful) {
            const newLocation = getCompanySwitcherRedirectIfNeeded(
              location?.state?.redirect,
              location?.state?.search
            )

            history.push(newLocation)
          } else {
            setSubmitStatus({
              loading: false,
              status: 'error',
              message: t('LOGIN FAILED'),
            })
          }
        },
        // eslint-disable-next-line handle-callback-err
        (err) => {
          console.log('err', err)
          setSubmitStatus({
            loading: false,
            status: 'error',
            // eslint-disable-next-line
            // prettier-ignore
            message: t("AN UNEXPECTED ERROR OCCURRED, PLEASE TRY AGAIN OR CALL THE SUPPORT"),
          })
        }
      )
    },
  })

  const navStyles = {
    className: 'navigation-menu',
    addActiveClassName: 'navigation-menu-active',
  }

  const customThemeRouteLink = {
    components: {
      routeLink: {
        paddingBottom: '0.3em',
        borderBottomColor: portalTheme.color.linkColorPrimaryActive,
      },
    },
  }

  if (isAuth) {
    return (
      <ContainerStyled xs sm md>
        <Row justify='center'>
          <Col xs='content'>
            {t('YOU ARE ALREADY LOGGED IN AS: {USERNAME}', {
              USERNAME: user.userName,
            })}
          </Col>
        </Row>
        <Row justify='center'>
          <Col xs='content'>
            {t(
              'YOU WILL BE AUTOMATICALLY REDIRECTED IN 5 SECONDS OR CLICK ON THE LINK HERE'
            )}
          </Col>
        </Row>
        <Row justify='center'>
          <Col xs='content'>
            <SimpleButton
              onClick={() => {
                redirectRoute(Routes.overview)
              }}
            >
              {t('GO TO OVERVIEW PAGE')}
            </SimpleButton>
          </Col>
        </Row>
      </ContainerStyled>
    )
  }

  return (
    <ContainerStyled xs sm md>
      <Row justify='center'>
        <Col xs={10} sm={10} md={6} lg={5}>
          <TitleStyled>
            {t('TRAFFIC SAFETY PORTAL:')}
            <br />
            {t('FOR CUSTOMERS OF THE PYREXX SERVICE')}
          </TitleStyled>
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs={10} sm={10} md={6} lg={5}>
          <DescriptionStyled>
            {
              // eslint-disable-next-line
              // prettier-ignore
              t("THE PORTAL FOR PYREXX SERVICE CUSTOMERS IN THE HOUSING INDUSTRY")
            }
          </DescriptionStyled>
        </Col>
      </Row>

      <Row justify='center' style={{ marginBottom: '0.3em' }}>
        <Col xs={5} sm={5} md={3} lg={2} style={{ paddingLeft: '2rem' }}>
          <RouteLink
            theme={customThemeRouteLink}
            navLinkElement={
              <NavLink
                key='1'
                to={'/' + currentLanguage + Routes.login}
                onClick={() => {
                  return null
                }}
                className={navStyles.className}
                activeClassName={navStyles.addActiveClassName}
              >
                {t('LOGIN')}
              </NavLink>
            }
          />
        </Col>
        <Col
          xs={5}
          sm={5}
          md={3}
          lg={2}
          style={{ textAlign: 'right', paddingRight: '2rem' }}
        >
          {/* <RouteLink */}
          {/*  theme={customThemeRouteLink} */}
          {/*  navLinkElement={ */}
          {/*    <NavLink */}
          {/*      key='1' */}
          {/*      to={'/' + currentLanguage + Routes.register} */}
          {/*      onClick={() => { */}
          {/*        return null */}
          {/*      }} */}
          {/*      className={navStyles.className} */}
          {/*      activeClassName={navStyles.addActiveClassName} */}
          {/*    > */}
          {/*      {t('REGISTER')} */}
          {/*    </NavLink> */}
          {/*  } */}
          {/* /> */}
        </Col>
      </Row>

      <Row justify='center' style={{ marginBottom: '0.3em' }} nogutter>
        <Col xs={10} sm={10} md={6} lg={4}>
          {submitStatus.status && (
            <SimpleNote
              noteStatus={submitStatus.status}
              text={submitStatus.message}
            />
          )}
          {!submitStatus.status && user?.autoLogout && (
            <SimpleNote
              noteStatus='warning'
              text={t(
                'YOU WERE LOGGED OUT AUTOMATICALLY AFTER {MINUTES} MINUTES INACTIVITY. PLEASE LOGIN AGAIN',
                { MINUTES: 60 }
              )}
            />
          )}
        </Col>
      </Row>

      <Row justify='center' nogutter>
        <StyledCol xs={10} sm={10} md={6} lg={4}>
          <form onSubmit={formik.handleSubmit}>
            <Row justify='center' nogutter>
              <Col xs={10}>
                <StyledInput
                  name='username'
                  id='username'
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  label={t('USERNAME')}
                  feedbackMessage={
                    formik.touched.username && formik.errors.username
                  }
                  feedbackStyle='invalid'
                  setFieldValue={formik.setFieldValue}
                  isLoginInput
                />
              </Col>
            </Row>

            <Row justify='center' nogutter style={{ marginTop: '.5rem' }}>
              <Col xs={10}>
                <StyledInput
                  type='password'
                  name='password'
                  id='password'
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  label={t('PASSWORD')}
                  feedbackMessage={
                    formik.touched.password && formik.errors.password
                  }
                  feedbackStyle='invalid'
                  setFieldValue={formik.setFieldValue}
                  isLoginInput
                />

                <Row justify='end' nogutter>
                  <Col xs='content'>
                    <SimpleLink
                      to={'/' + currentLanguage + Routes.forgotPassword}
                      font='quicksand'
                    >
                      {t('FORGOT PASSWORD?')}
                    </SimpleLink>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row justify='center' nogutter style={{ marginTop: '2rem' }}>
              <Col xs='content'>
                <ButtonPrimary type='submit' isLoading={submitStatus.loading}>
                  {t('SIGN IN')}
                </ButtonPrimary>
              </Col>
            </Row>
          </form>
        </StyledCol>
      </Row>
    </ContainerStyled>
  )
}

export default Login
