import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonPrimary, Icon, StyledInput } from 'pyrexx-react-library'
import { Badge } from '../Badges'

import * as S from './ActionCenter.styles'
import UserContext from '../../helper/userContext'

const DownloadModal = (props) => {
  const { t } = useTranslation()
  const {
    gridApi = {},
    selectedRows = [],
    setDownloadProgress = () => {},
    withPDFDownload = false,
    downloadModal = '',
    modalId = 'modal-download',
    closeDownloadModal = () => {},
    handlePDFPromises = (rows, user, setPdfFilesDownload) => {},
    fileDownloadName = t('FILE'),
  } = props
  const ref = useRef({
    savedDataSource: null,
  })
  const { user } = useContext(UserContext)
  const numberOfItems = selectedRows.length
  const labelText = t('SELECTED')

  const [pdfFilesDownload, setPdfFilesDownload] = useState({
    total: 0,
    complete: 0,
    downloadAllRows: false,
  })

  const [xlsxFilter, setXlsxFilter] = useState(false)
  const [csvFilter, setCsvFilter] = useState(false)
  const [pdfFilter, setPdfFilter] = useState(false)
  const isFilterSelected = !!(xlsxFilter || csvFilter || pdfFilter)

  const [downloadAllProcess, setDownloadAllProcess] = useState({
    inProcess: false,
    startRow: 0,
    fileCount: 1,
  })

  const [message, setMessage] = useState('')
  const clearMessage = () => setMessage('')

  useEffect(() => {
    // Resets modal after it has been closed
    if (!downloadModal && !downloadAllProcess.inProcess) {
      setXlsxFilter(false)
      setCsvFilter(false)
      setPdfFilter(false)
      clearMessage()
    }
  }, [downloadModal, downloadAllProcess.inProcess])

  useEffect(() => {
    if (isFilterSelected) {
      clearMessage()
    }
  }, [isFilterSelected])

  useEffect(() => {
    if (
      pdfFilesDownload.total > 0 &&
      pdfFilesDownload.complete < pdfFilesDownload.total
    ) {
      // console.log('not complete', pdfFilesDownload)
      let percent = (
        pdfFilesDownload.complete /
        (pdfFilesDownload.total / 100)
      ).toFixed(2)
      if (pdfFilesDownload.downloadAllRows) {
        percent = (percent / 2 + 50).toFixed(2)
        percent = Number(percent)
      }
      setDownloadProgress(percent === 0 ? 1 : percent)
    } else {
      // console.log('is complete', pdfFilesDownload)
      setDownloadProgress(0)
    }
  }, [pdfFilesDownload, setDownloadProgress])

  const exportData = useCallback(
    (exportParams = {}, rows = [], downloadAllRows = false) => {
      const exportPDF = (rows, downloadAllRows) => {
        setPdfFilesDownload({
          total: rows.length,
          complete: 0,
          downloadAllRows,
        })

        handlePDFPromises(rows, user, setPdfFilesDownload)
      }
      console.log('exportData called', xlsxFilter, csvFilter)
      if (xlsxFilter) {
        gridApi.exportDataAsExcel(exportParams)
      }

      if (csvFilter) {
        console.log('gridApi.exportDataAsCsv(exportParams)', exportParams)
        gridApi.exportDataAsCsv(exportParams)
      }

      if (pdfFilter) {
        exportPDF(rows, downloadAllRows)
      }
    },
    [csvFilter, gridApi, handlePDFPromises, pdfFilter, user, xlsxFilter]
  )

  useEffect(() => {
    if (downloadAllProcess.inProcess) {
      if (downloadAllProcess.startRow === 0) {
        setDownloadProgress(1)
        gridApi.gridOptionsWrapper.gridOptions.serverSideInfiniteScroll = false
        console.log('generateDownloadDataSource executed')
        const downloadDataSource = generateDownloadDataSource()
        gridApi.setServerSideDatasource(downloadDataSource)
      } else {
        setTimeout(() => {
          console.log('generateDownloadDataSource executed')
          const downloadDataSource = generateDownloadDataSource()
          gridApi.setServerSideDatasource(downloadDataSource)
        }, 2000)
      }
    } else {
      setTimeout(() => {
        setDownloadProgress(0)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    downloadAllProcess.inProcess,
    downloadAllProcess.startRow,
    setDownloadProgress,
  ])

  const getWhichColumns = useCallback(() => {
    let whichColumns
    if (
      gridApi.gridOptionsWrapper.gridOptions?.defaultCsvExportParams
        ?.skipColumns
    ) {
      const columnKeys = gridApi
        .getColumnDefs()
        .filter(
          (column) =>
            !gridApi.gridOptionsWrapper.gridOptions?.defaultCsvExportParams?.skipColumns.includes(
              column.colId
            )
        )
        .map((column) => column.colId)
      whichColumns = {
        columnKeys,
      }
    } else {
      whichColumns = {
        allColumns: true,
      }
    }
    return whichColumns
  }, [gridApi])

  const getExportParams = useCallback(
    (addFilenamePrefix = '') => {
      const whichColumns = getWhichColumns()
      const currentDate = new Date()
      const exportParams = {
        ...whichColumns,
        fileName:
          fileDownloadName +
          addFilenamePrefix +
          '_' +
          currentDate.toISOString().slice(0, 10).replace(/-/g, ''),
      }
      return exportParams
    },
    [fileDownloadName, getWhichColumns]
  )

  const generateDownloadDataSource = useCallback(() => {
    const downloadDataSource =
      ref.current.savedDataSource.getDownloadDataSource(
        (loadedProps) => {
          const {
            finished = false,
            startRow = 0,
            maxFiles = 1,
          } = loadedProps || {}
          console.log('loadedProps', loadedProps)
          const filenamePrefix =
            maxFiles > 1
              ? '_' + downloadAllProcess.fileCount + t('FROM') + maxFiles
              : ''
          const exportParams = getExportParams(filenamePrefix)
          if (withPDFDownload) {
            const allRows = []
            gridApi.forEachNode((node) => {
              allRows.push(node.data.download)
              console.log('node.data.download', node.data.download)
            })
            exportData({ ...exportParams, onlySelected: false }, allRows, true)
          } else {
            console.log('export shoulkd start now', exportParams)
            exportData({ ...exportParams, onlySelected: false })
          }

          if (finished) {
            gridApi.gridOptionsWrapper.gridOptions.serverSideInfiniteScroll = true
            gridApi.setServerSideDatasource(
              downloadDataSource.getServerSideDataSource()
            )
            setDownloadAllProcess({
              inProcess: false,
              startRow: 0,
              fileCount: 1,
            })
          } else {
            setDownloadAllProcess({
              inProcess: true,
              startRow,
              fileCount: downloadAllProcess.fileCount + 1,
            })
          }
        },
        setDownloadProgress,
        pdfFilter,
        1000, // chunk size
        downloadAllProcess.startRow,
        1000
      )

    return downloadDataSource
  }, [
    downloadAllProcess.fileCount,
    downloadAllProcess.startRow,
    exportData,
    getExportParams,
    gridApi,
    pdfFilter,
    setDownloadProgress,
    t,
    withPDFDownload,
  ])

  const handleExport = (typeOfDownload) => {
    const exportParams = getExportParams()
    if (
      gridApi.gridOptionsWrapper.gridOptions?.defaultCsvExportParams
        ?.processCellCallback
    ) {
      exportParams.processCellCallback =
        gridApi.gridOptionsWrapper.gridOptions?.defaultCsvExportParams.processCellCallback
    }
    if (isFilterSelected) {
      try {
        switch (typeOfDownload) {
          case 'selected': {
            if (numberOfItems > 0) {
              exportData({ ...exportParams, onlySelected: true }, selectedRows)
              closeDownloadModal()
            } else {
              setMessage(t('SELECT ROWS'))
            }
            break
          }

          case 'all': {
            if (Object.keys(gridApi).length === 0) return
            ref.current.savedDataSource =
              gridApi.gridOptionsWrapper.gridOptions.serverSideDatasource
            const currentStoreType =
              gridApi.gridOptionsWrapper.gridOptions.serverSideInfiniteScroll
            // Exports already loaded rows to xlsx or csv, 30 = cache size
            if (currentStoreType === 'full' && !withPDFDownload) {
              closeDownloadModal()
              exportData({ ...exportParams, onlySelected: false })
              break
            } else {
              closeDownloadModal()
              setDownloadAllProcess({
                inProcess: true,
                startRow: 0,
                fileCount: 1,
              })
              break
            }
          }

          default:
            break
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      setMessage('SELECT AN OPTION')
    }
  }

  return (
    <S.DownloadModalContainer>
      <S.DownloadModal
        isOpen={!!downloadModal}
        handleModalClose={closeDownloadModal}
        id={modalId}
      >
        <S.TitleRow>
          <Icon icon='export' size='50px' />
          <div
            style={{ display: 'flex', flexFlow: 'column nowrap', gap: '4px' }}
          >
            {downloadModal === 'selected' && (
              <S.IconWithText>
                <Badge>{numberOfItems}</Badge>
                <span>{labelText}</span>
              </S.IconWithText>
            )}
            <span>{t('EXPORT AS')}</span>
          </div>
        </S.TitleRow>

        <S.CheckboxesRow>
          <StyledInput
            label='.xlsx'
            type='checkbox'
            value={xlsxFilter}
            onChange={() => setXlsxFilter(!xlsxFilter)}
            id={'xlsx-filter' + modalId}
            name={'xlsx-filter' + modalId}
            setFieldValue={() => {}}
          />

          <StyledInput
            label='.csv'
            type='checkbox'
            value={csvFilter}
            onChange={() => setCsvFilter(!csvFilter)}
            id={'csv-filter' + modalId}
            name={'csv-filter' + modalId}
            setFieldValue={() => {}}
          />
        </S.CheckboxesRow>

        {withPDFDownload && (
          <S.CheckboxesRow>
            <StyledInput
              label='.pdf'
              type='checkbox'
              value={pdfFilter}
              onChange={() => setPdfFilter(!pdfFilter)}
              id={'pdf-filter' + modalId}
              name={'pdf-filter' + modalId}
              setFieldValue={() => {}}
            />
          </S.CheckboxesRow>
        )}

        <S.ButtonRow>
          <strong>{message}</strong>

          <ButtonPrimary onClick={() => handleExport(downloadModal)}>
            {t('EXPORT')}
          </ButtonPrimary>
        </S.ButtonRow>
      </S.DownloadModal>
    </S.DownloadModalContainer>
  )
}

export default DownloadModal
