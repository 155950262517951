/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractTypeType = "ASP" | "B2C_ONE_TIME" | "DWA_CONTRACTOR" | "DWA_SUBCONTRACTOR" | "MINI_MAINTENANCE_CONTRACT" | "MISC_SERVICE" | "NONE" | "ONE_TIME_EXTERNAL_MAINTENANCE" | "ONE_TIME_GRAFFITI" | "ONLY_TS_ON_DEMAND" | "REMOTE_MAINTENANCE_CONTRACT" | "SD_MAINTENANCE_CONTRACT" | "SD_PURCHASE_ASSEMBLY_NO_TS" | "SD_PURCHASE_ASSEMBLY_TS_ON_DEMAND" | "SD_PURCHASE_WITHOUT_ASSEMBLY" | "UMBRELLA_GRAFFITI" | "%future added value";
export type ContractsQueryVariables = {|
  companyId: string
|};
export type ContractsQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +Contracts: ?$ReadOnlyArray<?{|
        +id: string,
        +tableId: number,
        +name: ?string,
        +type: ?ContractTypeType,
        +billingPeriods: $ReadOnlyArray<?{|
          +id: string,
          +tableId: number,
          +from: any,
          +to: ?any,
        |}>,
        +serviceCategory: ?{|
          +id: string,
          +tableId: number,
          +name: string,
        |},
      |}>
    |}
  |}
|};
export type ContractsQuery = {|
  variables: ContractsQueryVariables,
  response: ContractsQueryResponse,
|};
*/


/*
query ContractsQuery(
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      Contracts(options: {endRow: -1}) {
        id
        tableId
        name
        type
        billingPeriods(options: {endRow: -1}) {
          id
          tableId
          from
          to
        }
        serviceCategory {
          id
          tableId
          name
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "options",
    "value": {
      "endRow": -1
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": (v2/*: any*/),
  "concreteType": "Contract",
  "kind": "LinkedField",
  "name": "Contracts",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v2/*: any*/),
      "concreteType": "BillingPeriod",
      "kind": "LinkedField",
      "name": "billingPeriods",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "from",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "to",
          "storageKey": null
        }
      ],
      "storageKey": "billingPeriods(options:{\"endRow\":-1})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceCategory",
      "kind": "LinkedField",
      "name": "serviceCategory",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": "Contracts(options:{\"endRow\":-1})"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "274f6d079530fe1b99af45ec755e5b59",
    "id": null,
    "metadata": {},
    "name": "ContractsQuery",
    "operationKind": "query",
    "text": "query ContractsQuery(\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      Contracts(options: {endRow: -1}) {\n        id\n        tableId\n        name\n        type\n        billingPeriods(options: {endRow: -1}) {\n          id\n          tableId\n          from\n          to\n        }\n        serviceCategory {\n          id\n          tableId\n          name\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '43b431c006745c59258703c37aabe76f';

module.exports = node;
