export function formatUserSalutationDE({
  salutation = '',
  title = '',
  firstname = '',
  lastname = '',
} = {}) {
  let formattedUserName = ''

  if (salutation.length > 0) {
    formattedUserName += salutation
  }

  if (title.length > 0) {
    if (formattedUserName.length > 0) {
      formattedUserName += ' '
    }
    formattedUserName += title
  }

  if (lastname.length > 0) {
    if (formattedUserName.length > 0) {
      formattedUserName += ' '
    }
    formattedUserName += lastname
  }

  // if (firstname.length > 0) {
  //   if (formattedUserName.length > 0) {
  //     formattedUserName += ' '
  //   }
  //   formattedUserName += firstname
  // }

  return formattedUserName
}

export function formatUserSalutationEN({
  salutation = '',
  title = '',
  firstname = '',
  lastname = '',
} = {}) {
  let formattedUserName = ''

  if (title.length > 0) {
    formattedUserName += title
  }

  if (!(title.length > 0) && salutation.length > 0) {
    if (formattedUserName.length > 0) {
      formattedUserName += ' '
    }
    formattedUserName += salutation
  }

  if (lastname.length > 0) {
    if (formattedUserName.length > 0) {
      formattedUserName += ' '
    }
    formattedUserName += lastname
  }

  // if (firstname.length > 0) {
  //   if (formattedUserName.length > 0) {
  //     formattedUserName += ' '
  //   }
  //   formattedUserName += firstname
  // }

  return formattedUserName
}
