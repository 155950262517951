import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import RoleInformationItem from './RoleInformationItem'

import { useUserContext } from '../../../helper/userContext'

const query = `
  query RoleInformation($lang: String) {
    getFaqItemsByCategory(portal: "pom", category: "roleinformation", lang: $lang) {
      id
      name
      questions {
        id
        question
        answer
      }
    }
  }
`

async function fetchData(query, variables) {
  const res = await fetch(process.env.REACT_APP_PSP_API_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({ query, variables }),
  })
  const json = await res.json()
  return json
}

const RoleInformation = () => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  const variables = {
    lang: currentLanguage,
  }

  const { isLoading, error, data } = useQuery(
    'role-information',
    () => fetchData(query, variables),
    {
      refetchOnWindowFocus: false,
    }
  )

  if (isLoading) {
    return <p>{t('LOADING')}</p>
  }

  if (error) {
    return <p>{t('NO ITEMS AVAILABLE')}</p>
  }

  const questions = data?.data?.questions || []

  if (questions.length === 0) {
    return <p>{t('NO ITEMS AVAILABLE')}</p>
  }

  return (
    <Wrapper>
      {questions.map((q) => (
        <RoleInformationItem
          key={q.id}
          name={q.question}
          description={q.answer}
        />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`

export default RoleInformation
