import React from 'react'
import styled from 'styled-components/macro'

const StyledDotLoader = styled.div`
  /**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
  .dot-flashing {
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background-color: #F4A522
    color: #F4A522;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
  }

  .dot-flashing::before,
  .dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  .dot-flashing::before {
    left: -35px;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background-color: #F4A522;
    color: #F4A522;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  .dot-flashing::after {
    left: 35px;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background-color: #F4A522;
    color: #F4A522;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #F4A522;
    }
    50%,
    100% {
      background-color: #f4d5a6;
    }
  }
`

const DotLoader = (props) => {
  return (
    <StyledDotLoader>
      <div className='dot-flashing' />
    </StyledDotLoader>
  )
}

export default DotLoader
