import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation TransferTokenMutation($transferToken: String!) {
    info: transferToken(transferToken: $transferToken) {
      status
      Me {
        id
        name
        firstname
        lastname
        salutation
        title
        can
        Companies(options: null) {
          id
          name
        }
      }
      accessToken
      refreshToken
    }
  }
`

export default (transferToken, callBack, errorCallBack) => {
  const variables = {
    transferToken
  }

  commitMutation(environment(), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack
  })
}
