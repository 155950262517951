import React from 'react'
import styled from 'styled-components/macro'

import { ButtonIcon } from 'pyrexx-react-library'
import { DropdownBadge } from '../Badges'

const ArrowButton = styled(ButtonIcon).attrs((props) => ({
  icon: 'arrow-left',
  color: '#4AB4E2',
  ...props,
}))`
  transition: 250ms ease-in-out;
  ${(props) => props.isActive && 'transform: scale(-1, 1);'}
`

const ArrowWithBadge = ({ isActive, handleClick, children }) => {
  return (
    <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
      <ArrowButton isActive={isActive} onClick={handleClick} />
      <DropdownBadge>{children}</DropdownBadge>
    </div>
  )
}

export default ArrowWithBadge
