import styled from 'styled-components/macro'
import React from 'react'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  //padding: 24px;
  background-color: #3f51b5;
  color: white;
  background-color: #97f8e5;
  color: black;
  font-size: 2.5rem;

  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border-radius: 50%;
`

const PlaceholderProfilePic = ({
  firstname = '',
  lastname = '',
  size = '150px',
  style,
  className,
}) => {
  const firstLetter = firstname[0]
  const secondLetter = lastname[0]

  return (
    <Wrapper size={size} style={style} className={className}>
      {firstLetter + secondLetter}
    </Wrapper>
  )
}

export default PlaceholderProfilePic
