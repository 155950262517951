import styled from 'styled-components/macro'
import {
  Menu as M,
  MenuButton as MButton,
  MenuPopover as MPopover,
  MenuItems as MItems,
  MenuLink as MLink,
} from '@reach/menu-button'
import { EmptyButtonCSS } from '../Simple/EmptyButton'

import '@reach/menu-button/styles.css'

export const Menu = M
export const MenuLink = MLink
export const MenuPopover = MPopover

export const MenuButton = styled(MButton)`
  display: flex;
  align-items: center;
  justify-content: center;

  ${EmptyButtonCSS}
`

export const MenuItems = styled(MItems)`
  background: #fff;
  color: #333;
  font-size: 100%;
  border: none;
  padding: 0.5rem 0;

  position: relative;
  z-index: 1001;

  width: 16rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  & > [data-reach-menu-item] {
    display: flex;
    gap: 10px;
    align-items: center;

    padding: 1rem 1.5rem;

    white-space: break-spaces;
  }

  & > [data-reach-menu-item] > svg {
    flex-shrink: 0;
  }

  & > [data-reach-menu-item][data-selected] {
    background: rgb(242, 242, 242);
    color: #333;
  }

  @keyframes slide-down {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &[data-reach-menu-list],
  &[data-reach-menu-items] {
    border-radius: 5px;
    animation: slide-down 0.2s ease;
  }
`
