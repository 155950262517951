import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import SimpleLink from '../../Simple/SimpleLink'

import UserContext from '../../../helper/userContext'
import { Routes } from '../../../configs/RouteConfig'
import { Label } from './MainHeader.styles'

const UnauthenticatedHeader = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()

  return (
    <SimpleLink to={'/' + currentLanguage + Routes.login} font='quicksand'>
      <Label>{t('LOGIN')}</Label>
    </SimpleLink>
  )
}

export default UnauthenticatedHeader
