function dummyTranslate(text) {
  return text
}

const appointmentStatusEnumList = (value) => {
  switch (value) {
    case 'CLOSED':
    case 'CHECKED':
    case 'DONE':
      return dummyTranslate('EXECUTED')

    case 'OPEN':
    case 'DIAGNOSE_PENDING':
    case 'PENDING': // this is not implemented in the enum list is just for special uses
      return dummyTranslate('PENDING')

    case 'DEACTIVATED':
    case 'NOT_EXECUTED': // this is not implemented in the enum list is just for special uses
      return dummyTranslate('NOT EXECUTED')

    case 'NONE':
    default:
      return ''
  }
}

export default appointmentStatusEnumList
