import styled from 'styled-components/macro'
import { Row } from 'react-grid-system'

import {
  ButtonIcon,
  portalTheme,
  Searchbox,
  SimpleModal,
} from 'pyrexx-react-library'

export const Burger = styled(ButtonIcon).attrs(() => ({
  icon: 'mobile-menu',
  size: '25px',
}))`
  padding-left: 0.75rem;
  margin: 0;
`

export const RightSection = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 32px;
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${portalTheme.font.size.bodyRegular};
  font-family: ${portalTheme.font.family.quicksand};

  padding: 1rem;
  padding-right: 0;

  &:hover,
  &:focus {
    color: ${portalTheme.color.linkColorPrimaryHover};
  }

  color: #000;
`

export const MainHeaderRow = styled(Row)`
  height: 70px;
`

export const CustomSearchbox = styled(Searchbox)`
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #666;
  padding: 0;
  padding-bottom: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
  & input {
    padding-left: 0.5rem;
  }
  height: unset;
`

export const StyledModal = styled(SimpleModal)`
  z-index: 9999988;
  justify-content: flex-end;
  align-items: start;
  & [data-simple-modal-content] {
    height: 100vh;
    max-height: 100vh;
    max-width: 80vw;
  }
`
