import React from 'react'
import { Container, Row, Col } from 'react-grid-system'

const PageContainer = (props) => {
  const { children, noGutter, ...restOfProps } = props

  return (
    <Container
      xs
      sm
      md
      lg
      style={{ padding: 0, height: '100%' }}
      {...restOfProps}
    >
      <Row nogutter={noGutter} style={{ height: '100%' }}>
        <Col>{children}</Col>
      </Row>
    </Container>
  )
}

export default PageContainer
