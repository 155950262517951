import React from 'react'

import UsageUnitTypeRendererHelper from '../../../components/AgGrid/RendererHelper/UsageUnitTypeRendererHelper'

const UsageUnitTypeRenderer = ({ value = '' }) => {
  if (!value) {
    return '-'
  }

  return <UsageUnitTypeRendererHelper value={value} />
}

export default UsageUnitTypeRenderer
