import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

const Divider = styled.hr`
  margin: 24px -24px;
  border: 1px solid var(--pyrexx-dark-blue);
  opacity: 0.5;
`

const FeedbackWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const InputLabel = styled.label`
  cursor: pointer;
`

const Feedback = ({ id, handleFeedbackChange = () => {} }) => {
  const { t } = useTranslation()

  return (
    <>
      <Divider />

      <FeedbackWrapper>
        <span>{t('WAS THIS INFORMATION USEFUL?')}</span>

        <InputsWrapper>
          <InputWrapper>
            <input
              type='radio'
              id={'yes_' + id}
              name={'faq-feedback_' + id}
              value='yes'
              onChange={handleFeedbackChange}
            />
            <InputLabel htmlFor={'yes_' + id}>{t('YES')}</InputLabel>
          </InputWrapper>

          <InputWrapper>
            <input
              type='radio'
              id={'no_' + id}
              name={'faq-feedback_' + id}
              value='no'
              onChange={handleFeedbackChange}
            />
            <InputLabel htmlFor={'no_' + id}>{t('NO')}</InputLabel>
          </InputWrapper>
        </InputsWrapper>
      </FeedbackWrapper>
    </>
  )
}

export default Feedback
