import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query CountAppointmentHistoryQuery(
    $filters: [Filter]
    $companyId: ID!
    $usageUnitId: ID!
  ) {
    Me {
      id
      Company(id: $companyId) {
        UsageUnit(id: $usageUnitId) {
          data: countAppointment(options: { filters: $filters })
        }
      }
    }
  }
`
