import ReactDOM from 'react-dom'
import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { CKEditor as CKEditorReact } from '@ckeditor/ckeditor5-react'
import { Col, Row } from 'react-grid-system'
import CustomEditor from '../../editor/build/customEditor'
import VariablesBox from '../../editor/VariablesBox'
import VariablePreview from '../../editor/VariablePreview'
import {
  availableVariables,
  department,
  letter,
  letterCreator,
} from '../../editor/availableVariables'
import UpdateSaveButton from '../../components/UpdateSavedButton/UpdateSaveButton'

const numberOfLines = 12
const arrayOfLines = Array.from({ length: numberOfLines }, (_, i) => i + 1)
const margin = 16
// const doubleEditorMargin = 2 * 14.4
const doubleEditorMargin = 0
const lineHeight = 24
const editorHeight =
  (margin * 2 + lineHeight) * numberOfLines -
  (numberOfLines - 1) * margin +
  doubleEditorMargin

const SidebarOverride = ({
  formik,
  updateFunction,
  loading,
  setFormikValuesClone,
  formikValuesClone,
  setContainerFormErrors,
  containerFormErrors,
  sidebarContainerKeyId,
}) => {
  const { t } = useTranslation()

  const editorRef = useRef(null)

  const noChangedContent = useCallback(() => {
    return (
      formik.values?.[sidebarContainerKeyId.containerKey]?.[
        sidebarContainerKeyId.id
      ]?.data ===
      formikValuesClone?.[sidebarContainerKeyId.containerKey]?.[
        sidebarContainerKeyId.id
      ]?.data
    )
  }, [
    formik.values,
    formikValuesClone,
    sidebarContainerKeyId.containerKey,
    sidebarContainerKeyId.id,
  ])

  const editorConfig = {
    // The configuration of the Products plugin. It specifies a function that will allow
    // the editor to render a React <VariablePreview> component inside a product widget.
    variables: {
      variablesRenderer: (id, domElement) => {
        const variable = availableVariables.find(
          (variable) => variable.id === id
        )

        const handleRemove = (id) => {
          editorRef.current.execute('removeVariable', id)
          editorRef.current.editing.view.focus()
        }

        // For React 18
        // const variableElement = createRoot(domElement)
        ReactDOM.render(
          <VariablePreview id={id} handleRemove={handleRemove} {...variable} />,
          domElement
        )
      },
    },
  }

  // WITH MULTI
  // const generateMultiData = (current, rows = 1) => {
  //   const rowsArray = [...Array(rows).keys()]
  //
  //   return rowsArray.reduce((prev, curr, index) => {
  //     const number = parseInt(index) + 1
  //     const id = 'row-' + number
  //
  //     const currentData = current.getData({ rootName: id, trim: 'none' })
  //
  //     return prev + currentData
  //   }, '')
  // }

  // A handler executed when the user types or modifies the editor content.
  // It updates the state of the application.
  const handleEditorDataChange = (evt, editor) => {
    console.log('data changed')
    formik.setFieldValue(
      sidebarContainerKeyId.containerKey +
        '.' +
        sidebarContainerKeyId.id +
        '.data',
      editor.getData()
    )
  }

  // A handler executed when the editor has been initialized and is ready.
  // It synchronizes the initial data state and saves the reference to the editor instance.
  const handleEditorReady = (editor) => {
    editorRef.current = editor

    editor.editing.view.change((writer) => {
      writer.setStyle(
        'height',
        editorHeight + 'px',

        editor.editing.view.document.getRoot()
      )
    })
  }

  // WITH MULTI
  // const getInitialData = (initialData = '') => {
  //   const parser = new DOMParser()
  //   const temp = parser.parseFromString(
  //     initialData,
  //     'text/html'
  //   ).documentElement
  //
  //   const dataArray = [...temp.querySelectorAll('p')].reduce(
  //     (prev, curr, index) => {
  //       return {
  //         ...prev,
  //         ['row-' + (index + 1)]: curr.outerHTML,
  //       }
  //     },
  //     {}
  //   )
  //
  //   // console.log({ dataArray })
  //   return dataArray
  // }

  const handleSave = () => {
    if (editorRef.current) {
      setFormikValuesClone(formik.values)
      const newContainerFormErrors = containerFormErrors.reduce(
        (prev, current) => {
          if (
            sidebarContainerKeyId.containerKey +
              '_' +
              sidebarContainerKeyId.id !==
            current
          ) {
            return [...prev, current]
          } else {
            return [...prev]
          }
        },
        []
      )
      setContainerFormErrors(newContainerFormErrors)
      const data = editorRef.current.getData()
      updateFunction(data)

      // WITH MULTI
      // const multiData = generateMultiData(editorRef.current, numberOfLines)
      // updateFunction(multiData)
      // formik.setFieldValue(
      //   sidebarContainerKeyId.containerKey +
      //     '.' +
      //     sidebarContainerKeyId.id +
      //     '.data',
      //   multiData
      // )
      // console.log({ multiData })
    }
  }

  // WITH MULTI
  // const initialData = getInitialData(
  //   formik.values?.[sidebarContainerKeyId.containerKey]?.[
  //     sidebarContainerKeyId.id
  //   ]?.data
  // )

  return (
    <div>
      <h3 style={{ margin: '0 0 15px 0' }}>{t('ADD LETTER HEADER')}</h3>
      <p>{t('VARIABLEN HINZUFÜGEN UND ANWENDEN')}</p>

      <VariablesEditorWrapper>
        <EditorWrapper>
          <NumbersBar>
            {arrayOfLines.map((l) => (
              <p key={l} style={{ margin: '2px 0' }}>
                {/* {l} */}
              </p>
            ))}
          </NumbersBar>

          <div style={{ width: '100%' }}>
            {/* <Multi */}
            {/*  editorRef={editorRef} */}
            {/*  config={editorConfig} */}
            {/*  rows={numberOfLines} */}
            {/*  initialData={initialData} */}
            {/* /> */}

            <CKEditorReact
              editor={CustomEditor.VariablesEditor}
              data={
                formik.values?.[sidebarContainerKeyId.containerKey]?.[
                  sidebarContainerKeyId.id
                ]?.data
              }
              config={editorConfig}
              onChange={handleEditorDataChange}
              onReady={handleEditorReady}
            />
          </div>
        </EditorWrapper>

        <div>
          <h4 style={{ margin: 0 }}>{t('FURTHER DATA')}</h4>

          <p>
            <strong>{t('CONTACT')}</strong>
          </p>
          <VariablesBox variables={letterCreator} editorRef={editorRef} />

          <p>
            <strong>{t('DEPARTMENT')}</strong>
          </p>
          <VariablesBox variables={department} editorRef={editorRef} />

          <p>
            <strong>{t('DATE FORMAT')}</strong>
          </p>
          <VariablesBox variables={letter} editorRef={editorRef} />
        </div>
      </VariablesEditorWrapper>

      <Row
        justify='end'
        align='end'
        style={{
          paddingBottom: '20px',
          paddingTop: '20px',
        }}
      >
        <Col>
          <UpdateSaveButton
            loading={loading}
            handleSave={handleSave}
            noChangedContent={noChangedContent}
          />
        </Col>
      </Row>
    </div>
  )
}

const EditorWrapper = styled.div`
  display: flex;

  width: 100%;
  height: 100%;

  background-color: #a4def2;
  padding-right: 16px;
  padding-bottom: 24px;

  border-radius: 8px;
`

const NumbersBar = styled.div`
  padding-top: 56px;
  padding-left: 12px;
  padding-right: 6px;

  text-align: end;
  color: white;
`

const VariablesEditorWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
`

export default SidebarOverride
