import React, { useContext } from 'react'
import styled from 'styled-components/macro'

import { Icon } from 'pyrexx-react-library'
import AgGridDownload from '../../../components/AgGridDownload'
import { useTranslation } from 'react-i18next'
import { GetBulletinDownloadPathQuery } from '../queries'
import UserContext from '../../../helper/userContext'

const BulletinDownloadRenderer = (props) => {
  const { value = {}, propertyUnitId } = props
  const { t } = useTranslation()
  const { user } = useContext(UserContext)
  if (!value) {
    return t('DOWNLOAD NOT AVAILABLE')
  }

  const variables = {
    companyId: user.companyId,
    propertyUnitId: propertyUnitId,
    filters: [
      {
        name: 'tableId',
        filterType: 'text',
        operator: 'AND',
        conditions: [{ value: [value.toString()], type: 'equals' }],
      },
    ],
  }

  return (
    <AgGridDownload
      variables={variables}
      query={GetBulletinDownloadPathQuery}
      extractor={(res) => {
        return res.Me?.Company?.HouseEntrance?.data[0].download
      }}
    >
      <AnchorStyled>
        <Icon icon='download' size={20} />
      </AnchorStyled>
    </AgGridDownload>
  )
}

export default BulletinDownloadRenderer

const AnchorStyled = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`
