import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../../environments/pom/Environment'
import { isArray } from 'leaflet/src/core/Util'

const mutation = graphql`
  mutation UploadRecipientsMutation(
    $companyId: ID!
    $letterId: Int!
    $file: Upload!
  ) {
    Me {
      Company(id: $companyId) {
        businessPost {
          data: uploadRecipients(letterId: $letterId, file: $file) {
            valid
            invalid
            variables
          }
        }
      }
    }
  }
`

export default (variables, accessToken, files, callBack, errorCallBack) => {
  let uploadables

  if (files) {
    if (isArray(files)) {
      uploadables = {
        files,
      }
    } else {
      uploadables = {
        file: files,
      }
    }
  }

  commitMutation(environment(accessToken), {
    mutation,
    variables,
    uploadables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
