import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../../../environments/pom/Environment'
import { isArray } from 'leaflet/src/core/Util'

const mutation = graphql`
  mutation UploadAttachmentsMutation(
    $companyId: ID!
    $letterId: ID!
    $files: [Upload!]!
  ) {
    Me {
      Company(id: $companyId) {
        businessPost {
          uploadLetterAttachments(letterId: $letterId, files: $files) {
            filename
            status
          }
        }
      }
    }
  }
`

export default (variables, accessToken, files, callBack, errorCallBack) => {
  let uploadables

  if (files) {
    if (isArray(files)) {
      uploadables = {
        files,
      }
    } else {
      uploadables = {
        file: files,
      }
    }
  }

  commitMutation(environment(accessToken), {
    mutation,
    variables,
    uploadables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
