export default function passwordStrengthTranslations(
  text = '',
  t = (val) => val
) {
  switch (text) {
    // WARNINGS
    case 'Straight rows of keys are easy to guess':
      return t('STRAIGHT ROWS OF KEYS ARE EASY TO GUESS')

    case 'Short keyboard patterns are easy to guess':
      return t('SHORT KEYBOARD PATTERNS ARE EASY TO GUESS')

    case 'Use a longer keyboard pattern with more turns':
      return t('USE A LONGER KEYBOARD PATTERN WITH MORE TURNS')

    case 'Repeats like "aaa" are easy to guess':
      return t('REPEATS LIKE "AAA" ARE EASY TO GUESS')

    case 'Repeats like "abcabcabc" are only slightly harder to guess than "abc"':
      // eslint-disable-next-line
      return t('REPEATS LIKE "ABCABCABC" ARE ONLY SLIGHTLY HARDER TO GUESS THAN "ABC"')

    case 'Sequences like abc or 6543 are easy to guess':
      return t('SEQUENCES LIKE ABC OR 6543 ARE EASY TO GUESS')

    case 'Recent years are easy to guess':
      return t('RECENT YEARS ARE EASY TO GUESS')

    case 'Dates are often easy to guess':
      return t('DATES ARE OFTEN EASY TO GUESS')

    case 'This is a top-10 common password':
      return t('THIS IS A TOP-10 COMMON PASSWORD')

    case 'This is a top-100 common password':
      return t('THIS IS A TOP-100 COMMON PASSWORD')

    case 'This is a very common password':
      return t('THIS IS A VERY COMMON PASSWORD')

    case 'This is similar to a commonly used password':
      return t('THIS IS SIMILAR TO A COMMONLY USED PASSWORD')

    case 'A word by itself is easy to guess':
      return t('A WORD BY ITSELF IS EASY TO GUESS')

    case 'Names and surnames by themselves are easy to guess':
      return t('NAMES AND SURNAMES BY THEMSELVES ARE EASY TO GUESS')

    case 'Common names and surnames are easy to guess':
      return t('COMMON NAMES AND SURNAMES ARE EASY TO GUESS')

    // SUGGESTIONS
    case 'Use a few words, avoid common phrases':
      return t('USE A FEW WORDS, AVOID COMMON PHRASES')

    case 'No need for symbols, digits, or uppercase letters':
      return t('NO NEED FOR SYMBOLS, DIGITS, OR UPPERCASE LETTERS')

    case 'Add another word or two. Uncommon words are better.':
      return t('ADD ANOTHER WORD OR TWO. UNCOMMON WORDS ARE BETTER.')

    case 'Avoid repeated words and characters':
      return t('AVOID REPEATED WORDS AND CHARACTERS')

    case 'Avoid sequences':
      return t('AVOID SEQUENCES')

    case 'Avoid recent years':
      return t('AVOID RECENT YEARS')

    case 'Avoid years that are associated with you':
      return t('AVOID YEARS THAT ARE ASSOCIATED WITH YOU')

    case 'Avoid dates and years that are associated with you':
      return t('AVOID DATES AND YEARS THAT ARE ASSOCIATED WITH YOU')

    case "Capitalization doesn't help very much":
      return t("CAPITALIZATION DOESN'T HELP VERY MUCH")

    case 'All-uppercase is almost as easy to guess as all-lowercase':
      return t('ALL-UPPERCASE IS ALMOST AS EASY TO GUESS AS ALL-LOWERCASE')

    case "Reversed words aren't much harder to guess":
      return t("REVERSED WORDS AREN'T MUCH HARDER TO GUESS")

    case "Predictable substitutions like '@' instead of 'a' don't help very much":
      // eslint-disable-next-line
      return t("PREDICTABLE SUBSTITUTIONS LIKE '@' INSTEAD OF 'A' DON'T HELP VERY MUCH")

    default:
      return ''
  }
}

//
// // Warnings
// 'Straight rows of keys are easy to guess'
// 'Short keyboard patterns are easy to guess'
// 'Use a longer keyboard pattern with more turns'
// 'Repeats like "aaa" are easy to guess'
// 'Repeats like "abcabcabc" are only slightly harder to guess than "abc"'
// "Sequences like abc or 6543 are easy to guess"
// "Recent years are easy to guess"
// "Dates are often easy to guess"
// 'This is a top-10 common password'
// 'This is a top-100 common password'
// 'This is a very common password'
// 'This is similar to a commonly used password'
// 'A word by itself is easy to guess'
// 'Names and surnames by themselves are easy to guess'
// 'Common names and surnames are easy to guess'
//
//
// // Suggestions
// "Use a few words, avoid common phrases"
// "No need for symbols, digits, or uppercase letters"
// 'Add another word or two. Uncommon words are better.'
// 'Avoid repeated words and characters'
// 'Avoid sequences'
// 'Avoid recent years'
// 'Avoid years that are associated with you'
// 'Avoid dates and years that are associated with you'
// "Capitalization doesn't help very much"
// "All-uppercase is almost as easy to guess as all-lowercase"
// "Reversed words aren't much harder to guess"
// "Predictable substitutions like '@' instead of 'a' don't help very much"
