import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query CountPropertyUnitStatusHistoryQuery(
    $filters: [Filter]
    $companyId: ID!
    $propertyUnitId: ID!
  ) {
    Me {
      id
      Company(id: $companyId) {
        HouseEntrance(id: $propertyUnitId) {
          data: countLockPeriods(options: { filters: $filters })
        }
      }
    }
  }
`
