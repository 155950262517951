import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Icon, LoadingProgressBar } from 'pyrexx-react-library'
import DropdownWithLabel from '../DropdownWithLabel'
import { Badge } from '../Badges'

import * as S from './ActionCenter.styles'
import { StyledMountingAnimation } from '../../styles/HelperStylesComponents.styles'

const DropdownItem = ({
  icon = '',
  label = '',
  disabled = false,
  labelSeparator = false,
  selectedRows,
  onClick = () => {},
  ...rest
}) => {
  if (labelSeparator) {
    return <S.LabelSeparator>{label}</S.LabelSeparator>
  }
  return (
    <S.DropdownItemWrapper disabled={disabled} onClick={onClick} {...rest}>
      <S.IconWithText>
        <Icon icon={icon} size='30px' />
        <span>{label}</span>
      </S.IconWithText>
    </S.DropdownItemWrapper>
  )
}

const ActionCenter = (props) => {
  const {
    minimalVersion = false,
    downloadModal = false,
    dropDownItems,
    selectedRows,
    setForceRefresh,
    openDownloadModal = () => {},
    downloadProgress = '',
    numberOfItems = 0,
    labelText = '',
    openFromStart = false,
    filterModel,
    filterVariables,
  } = props
  const { t } = useTranslation()

  const generateCustomDropdownList = useCallback(
    (list) => {
      const getDropDownProps = (itemProps) => {
        switch (itemProps.type) {
          case 'support':
            return {
              disabled: true,
              icon: 'add-item',
              label: t('SUPPORT'),
            }
          case 'exportWholeList':
            return {
              disabled: false,
              icon: 'export',
              label: t('EXPORT THE WHOLE LIST'),
              onClick: () => openDownloadModal('all'),
            }
          case 'exportSelected':
            return {
              disabled: false,
              icon: 'export',
              label: t('EXPORT THE SELECTED RECORDS'),
              onClick: () => openDownloadModal('selected'),
            }
          case 'email':
            return {
              disabled: true,
              icon: 'radio-button-checked',
              label: t('SEND TO EMAIL'),
            }
          case 'addCalendar':
            return {
              disabled: true,
              icon: 'radio-button-checked',
              label: t('ADD TO CALENDAR'),
            }
          case 'showOnMap':
            return {
              disabled: true,
              icon: 'radio-button-checked',
              label: t('SHOW ON MAP'),
            }
          case 'labelSeparator':
            return {
              labelSeparator: true,
              label: itemProps.label,
            }
          case 'custom':
            if (itemProps.onClick) {
              console.log('itemProps.onClick', selectedRows)
            }

            return {
              disabled: itemProps.disabled,
              icon: itemProps.icon,
              label: itemProps.label,
              onClick: () => {
                itemProps.onClick(
                  selectedRows,
                  setForceRefresh,
                  filterModel,
                  filterVariables
                )
              },
            }
          default:
            return ''
        }
      }

      return list.map((item, index) => {
        return (
          <DropdownItem
            key={'dropDownItem' + index}
            {...getDropDownProps(item)}
          />
        )
      })
    },
    [
      filterModel,
      filterVariables,
      openDownloadModal,
      selectedRows,
      setForceRefresh,
      t,
    ]
  )

  const generateCounter = useCallback(() => {
    if (numberOfItems) {
      if (minimalVersion) {
        return (
          <Badge minWidth='37px' padding='1px'>
            {numberOfItems}
          </Badge>
        )
      }
      return (
        <S.IconWithText>
          <Badge>{numberOfItems}</Badge>
          <span>{labelText}</span>
        </S.IconWithText>
      )
    }
  }, [labelText, minimalVersion, numberOfItems])

  return (
    <DropdownWithLabel
      alignRight={minimalVersion}
      openFromStart={openFromStart}
      keepOpen={!!downloadModal}
      renderLabel={(isDropdownOpen) => {
        return (
          <S.Label
            minimalVersion={minimalVersion}
            isDropdownOpen={isDropdownOpen}
            className={numberOfItems ? 'hasItems' : ''}
          >
            {generateCounter()}
            <S.MoreActionIcon size={minimalVersion ? '35px' : '40px'} />
          </S.Label>
        )
      }}
    >
      <StyledMountingAnimation
        transitionName='DownloadAll'
        transitionEnterTimeout={1000}
        transitionLeaveTimeout={2000}
      >
        {!!downloadProgress && (
          <S.ProgressBarDropdown>
            <LoadingProgressBar boxSpinner complete={downloadProgress} />
          </S.ProgressBarDropdown>
        )}
      </StyledMountingAnimation>

      {!downloadProgress && (
        <S.Dropdown>{generateCustomDropdownList(dropDownItems)}</S.Dropdown>
      )}
    </DropdownWithLabel>
  )
}

ActionCenter.propTypes = {
  downloadModal: PropTypes.any,
  openDownloadModal: PropTypes.func,
  downloadProgress: PropTypes.any,
  numberOfItems: PropTypes.number,
  labelText: PropTypes.string,
  openFromStart: PropTypes.bool,
}

export default ActionCenter
