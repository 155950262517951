import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query CompanyDataQuery($companyId: ID!) {
    Me {
      Company(id: $companyId) {
        id
        permissions
        logo
        #        contracts: Contracts(options: { endRow: -1 }) {
        #          id
        #          tableId
        #          name
        #          billingPeriods(options: { endRow: -1 }) {
        #            id
        #            tableId
        #            from
        #            to
        #          }
        #          serviceCategory {
        #            id
        #            tableId
        #            name
        #          }
        #        }
      }
    }
  }
`
