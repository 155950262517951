import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { AttachmentsGridQuery } from '../queries'
import { DeleteAttachmentRenderer } from '../renderer'

export const useAttachmentsGrid = (letterId, getLetterAttachments) => {
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService, variables, gridFunctions) => {
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          default:
            return params.value
        }
      }
      const AttachmentsColumnDefs = [
        {
          headerName: t('FILE NAME'),
          field: 'filename',
          width: 200,
          minWidth: 200,
          sortable: false,
          filter: false,
        },
        {
          headerName: t('FILE TYPE'),
          field: 'extension',
          sortable: false,
          filter: false,
        },
        {
          headerName: t('DELETE'),
          field: 'tableId',
          sortable: false,
          filter: false,
          suppressSizeToFit: false,
          cellRenderer: DeleteAttachmentRenderer,
          cellRendererParams: {
            getLetterAttachments: getLetterAttachments,
          },
        },
      ]
      const LettersCustomFilter = []
      return {
        query: AttachmentsGridQuery,
        columnDefs: AttachmentsColumnDefs,
        customFilters: LettersCustomFilter,
        countQuery: false,
        extractor: (response) =>
          response.Me?.Company?.businessPost?.letter?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return ['copy']
          },
          components: { DeleteAttachmentRenderer },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { letterId: letterId },
      }
    },
    [getLetterAttachments, letterId, t]
  )
  return { getParams }
}
