import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import {
  AppointmentAccessRenderer,
  AppointmentMaintenanceProvidedStatusRenderer,
  AppointmentTypeRenderer,
  ContractPerformanceRenderer,
  TenantFullNameRenderer,
  UsageUnitReferenceNumberRenderer,
} from '../renderers'
import {
  Filter3MaintenanceServiceProvidedCountQuery,
  Filter3MaintenanceServiceProvidedQuery,
} from '../queries'
import {
  appointmentTypeValueGetter,
  contractPerformanceValueGetter,
  dateTimeValueGetter,
  tenantFullNameValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { DateRenderer } from '../../../components/AgGrid/renderers'
import appointmentAccessReasonValueGetter from '../valueGetter/appointmentAccessReasonValueGetter'
import { getPriorityHeightFromDisplayedColumns } from '../../../helper/calculateRowHeight'
import { AppointmentIdRenderer } from '../../Appointments/renderers'

export const useFilter3MaintenanceServiceProvidedGrid = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService) => {
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t('MAINTENANCE PROVIDED')
          case 'tenant':
            return tenantFullNameValueGetter(params)
          case 'accessReason':
            return t(appointmentAccessReasonValueGetter(params))
          case 'executionDate':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'Pp',
            })
          case 'registrations':
            return contractPerformanceValueGetter(params)
          case 'type':
            return t(appointmentTypeValueGetter(params))
          default:
            return params.value
        }
      }
      const getRowHeight = (params) => {
        const displayedColumns =
          params.node.columnApi?.getAllDisplayedVirtualColumns()
        const data = params?.data
        const getWidth = (field) =>
          params.node.columnApi?.getColumn?.(field)?.getActualWidth?.()

        const columnParams = [
          {
            field: 'registrations',
            value: data?.registrations,
            fieldProp: 'actionPosition',
            secondaryfieldProp: 'name',
            colWidth: getWidth('registrations'),
          },
          {
            field: 'accessReason',
            value: data?.accessReason,
            colWidth: getWidth('accessReason'),
          },
        ]

        return getPriorityHeightFromDisplayedColumns({
          columnParams,
          displayedColumns,
        })
      }
      const Filter3MaintenanceServiceProvidedColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          colId: 'status',
          width: 70,
          sortable: false,
          filter: false,
          cellRenderer: AppointmentMaintenanceProvidedStatusRenderer,
        },
        {
          headerName: t('ALLOCATION NUMBER'),
          field: 'usageUnit.tableId',
          cellRenderer: UsageUnitReferenceNumberRenderer,
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('APPOINTMENT ID'),
          field: 'tableId',
          width: 70,
          hide: true,
          cellRenderer: AppointmentIdRenderer,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('STREET'),
          colId: 'address.street',
          field: 'address.street',
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('STREET NR.'),
          colId: 'address.number',
          field: 'address.number',
          width: 70,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('CITY'),
          colId: 'address.city',
          field: 'address.city',
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('RESIDENT'),
          field: 'tenant',
          width: 110,
          cellRenderer: TenantFullNameRenderer,
          filter: false, // TODO: Make filterable?
        },
        {
          headerName: t('ACCESS'),
          field: 'accessReason',
          width: 80,
          cellRenderer: AppointmentAccessRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('ACCESS DATE'),
          field: 'executionDate',
          width: 100,
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'Pp',
          },
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
        },
        {
          headerName: t('CONTRACT PERFORMANCE'),
          field: 'registrations',
          width: 100,
          sortable: false,
          filter: false, // TODO: Make filterable?
          cellRenderer: ContractPerformanceRenderer,
        },
        {
          headerName: t('APPOINTMENT TYPE'),
          field: 'type',
          width: 100,
          cellRenderer: AppointmentTypeRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('CONTRACT PARTITION'),
          field: 'billingPartition.name',
          width: 140,
          ...withFilterParams,
        },
      ]
      const Filter3MaintenanceServiceProvidedCustomFilter = [
        {
          name: 'contract.serviceCategory.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [currentService.tableId],
              type: 'equals',
            },
          ],
        },
        {
          filterType: 'number',
          operator: 'AND',
          name: 'actionPosition.tableId',
          conditions: [
            {
              type: 'equals',
              value: ['1', '54', '528', '634'],
            },
          ],
        },
        {
          filterType: 'number',
          operator: 'AND',
          name: 'accessReason',
          conditions: [
            {
              type: 'equals',
              value: ['ACCESS'],
            },
          ],
        },
      ]
      return {
        query: Filter3MaintenanceServiceProvidedQuery,
        columnDefs: Filter3MaintenanceServiceProvidedColumnDefs,
        customFilters: Filter3MaintenanceServiceProvidedCustomFilter,
        countQuery: Filter3MaintenanceServiceProvidedCountQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          getRowHeight,
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
      }
    },
    [currentLanguage, t]
  )

  return { getParams }
}
