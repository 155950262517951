import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { portalTheme, ButtonPrimary } from 'pyrexx-react-library'

import { Routes } from '../../configs/RouteConfig'
import UserContext from '../../helper/userContext'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 2rem;
`

const Description = styled.p`
  font-size: 24px;
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.light};

  text-align: center;
  margin: 3rem 0;
  margin-top: 0;
`

const NotFound = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()

  return (
    <Container>
      <Description>{t('WE COULD NOT FIND THE APPOINTMENT')}</Description>

      <Link to={'/' + currentLanguage + Routes.appointments}>
        <ButtonPrimary>{t('BACK TO APPOINTMENTS')}</ButtonPrimary>
      </Link>
    </Container>
  )
}

export default NotFound
