import React, { useContext } from 'react'

import SimpleLink from '../../../../components/Simple/SimpleLink'

import { Routes } from '../../../../configs/RouteConfig'
import UserContext from '../../../../helper/userContext'
import { useTranslation } from 'react-i18next'

const TitleRenderer = (props) => {
  const { value, data } = props

  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()

  const route =
    data.status !== 'DRAFT'
      ? '/' +
        currentLanguage +
        Routes.businessMail +
        Routes.letter +
        '/' +
        data.tableId
      : '/' +
        currentLanguage +
        Routes.businessMail +
        Routes.businessMailEdit +
        '/' +
        data.tableId
  return (
    <SimpleLink to={route} style={{ fontSize: 'inherit' }}>
      {value ? value.replace(/(<([^>]+)>)/gi, '') : t('NO TITLE')}
    </SimpleLink>
  )
}

export default TitleRenderer
