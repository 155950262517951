import React from 'react'
import styled from 'styled-components/macro'

import EmptyButton from './EmptyButton'

import { SimpleCSS, FontQuicksand } from './Simple.styles'

const SimpleButton = styled(EmptyButton)`
  ${SimpleCSS}

  ${(props) => (props.font === 'quicksand' ? FontQuicksand : null)}
`

export default (props) => {
  const { children, font, ...restOfProps } = props

  return (
    <SimpleButton font={font} {...restOfProps}>
      {children}
    </SimpleButton>
  )
}
