/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LetterAttachmentsQueryVariables = {|
  companyId: string,
  letterId: string,
|};
export type LetterAttachmentsQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +letter: ?{|
          +preview: ?string,
          +pageCount: number,
          +data: ?$ReadOnlyArray<?{|
            +id: string,
            +tableId: number,
            +name: ?string,
            +extension: ?string,
            +filename: ?string,
          |}>,
        |}
      |}
    |}
  |}
|};
export type LetterAttachmentsQuery = {|
  variables: LetterAttachmentsQueryVariables,
  response: LetterAttachmentsQueryResponse,
|};
*/


/*
query LetterAttachmentsQuery(
  $companyId: ID!
  $letterId: ID!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        letter(id: $letterId) {
          preview
          pageCount
          data: attachments {
            id
            tableId
            name
            extension
            filename
          }
          id
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "letterId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "letterId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preview",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pageCount",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": "data",
  "args": null,
  "concreteType": "LetterAttachment",
  "kind": "LinkedField",
  "name": "attachments",
  "plural": true,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tableId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extension",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LetterAttachmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessPost",
                "kind": "LinkedField",
                "name": "businessPost",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "Letter",
                    "kind": "LinkedField",
                    "name": "letter",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LetterAttachmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "BusinessPost",
                "kind": "LinkedField",
                "name": "businessPost",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v2/*: any*/),
                    "concreteType": "Letter",
                    "kind": "LinkedField",
                    "name": "letter",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9a5de28b510bbc988fb08ff67fc999cf",
    "id": null,
    "metadata": {},
    "name": "LetterAttachmentsQuery",
    "operationKind": "query",
    "text": "query LetterAttachmentsQuery(\n  $companyId: ID!\n  $letterId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        letter(id: $letterId) {\n          preview\n          pageCount\n          data: attachments {\n            id\n            tableId\n            name\n            extension\n            filename\n          }\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9f5b3ddb76e5aae2d09746ad0eb2b4d1';

module.exports = node;
