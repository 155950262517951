import React, { useContext } from 'react'

import SimpleLink from '../../../../components/Simple/SimpleLink'

import { Routes } from '../../../../configs/RouteConfig'
import UserContext from '../../../../helper/userContext'

const AppointmentNumberRenderer = (props) => {
  const { appointment } = props.data

  const { currentLanguage } = useContext(UserContext)

  return (
    <SimpleLink
      to={
        '/' + currentLanguage + Routes.appointment + '/' + appointment?.tableId
      }
      style={{ fontSize: 'inherit' }}
    >
      {appointment.tableId}
    </SimpleLink>
  )
}

export default AppointmentNumberRenderer
