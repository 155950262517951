import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query CompanyLayoutForLettersQuery($companyId: ID!, $companyLayoutId: ID!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          companyLayout(id: $companyLayoutId) {
            id
            tableId
            name
            layout
          }
        }
      }
    }
  }
`
