import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { withRouter } from 'react-router-dom'

import Header from './Header/Header'
import Footer from './Footer/Footer'

import { ContainerStyled, GlobalStyle } from './Layout.styles'

const Layout = (props) => {
  useEffect(() => {
    if (!props?.location?.state?.searchChange) {
      window.scrollTo(0, 0)
    }
  }, [props, props.location])

  return (
    <ContainerStyled fluid>
      <Header />

      <Row component='main'>
        <Col xs={12} className='main-col'>
          {React.cloneElement(props.children, { ...props })}
        </Col>
      </Row>

      <Footer />
      <GlobalStyle />
    </ContainerStyled>
  )
}

export default withRouter(Layout)

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  location: PropTypes.any.isRequired,
}
