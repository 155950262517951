import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-grid-system'
import { useUserContext } from '../../../helper/userContext'
import formatDateLocale from '../../../helper/formatDateLocale'
import styled from 'styled-components/macro'
import { portalTheme } from 'pyrexx-react-library'
import lettersTypeEnumList from '../../../components/AgGrid/enumLists/lettersTypeEnumList'

const StyledLabelCol = styled(Col)`
  font-weight: ${portalTheme.font.weight.bold};
`

const StyledBigLabelCol = styled(Col)`
  font-size: 1.2em;
  font-weight: ${portalTheme.font.weight.bold};
`

const Letter = (props) => {
  const { letterObject } = props
  const { t } = useTranslation()
  const { currentLanguage } = useUserContext()

  const removeHTML = (str) => {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = str
    return tmp.textContent || tmp.innerText || ''
  }

  const containers = JSON.parse(letterObject.layout)
  const subject = removeHTML(
    containers.content.find((item) => item?.isTitle).content
  )
  const content = removeHTML(
    containers.content.find((item) => item?.id === 'letterContent').content
  )

  const translateSalutation = useCallback(
    (salutation = '') => {
      switch (salutation) {
        case 'MR':
          return t('MR.')
        case 'MRS':
          return t('MRS.')
        case 'COMPANY':
          return t('COMPANY')
        case 'NONE':
        case '':
          return ''
        default:
          return salutation
      }
    },
    [t]
  )

  const translateTitle = useCallback(
    (title = '') => {
      switch (title) {
        case 'DR':
          return t('DR.')
        case 'PROF':
          return t('PROF.')
        case 'PROF_DR':
          return t('PROF. DR.')
        case 'NONE':
        case '':
          return ''
        default:
          return title
      }
    },
    [t]
  )

  const buildFirstRecipient = useCallback(() => {
    const recipient =
      translateSalutation(letterObject?.recipient1Salutation) +
      ' ' +
      (translateTitle(letterObject?.recipient1Title)
        ? translateTitle(letterObject?.recipient1Title) + ' '
        : '') +
      letterObject?.recipient1FirstName +
      ' ' +
      letterObject?.recipient1LastName +
      ' '
    return recipient
  }, [
    letterObject.recipient1FirstName,
    letterObject.recipient1LastName,
    letterObject.recipient1Salutation,
    letterObject.recipient1Title,
    translateSalutation,
    translateTitle,
  ])

  const buildSecondRecipient = useCallback(() => {
    if (!letterObject.recipient2FirstName) {
      return ''
    }
    const recipient =
      translateSalutation(letterObject?.recipient2Salutation) +
      ' ' +
      (translateTitle(letterObject?.recipient2Title)
        ? translateTitle(letterObject?.recipient2Title) + ' '
        : '') +
      letterObject?.recipient2FirstName +
      ' ' +
      letterObject?.recipient2LastName +
      ' '
    return ', ' + recipient
  }, [
    letterObject.recipient2FirstName,
    letterObject.recipient2LastName,
    letterObject.recipient2Salutation,
    letterObject.recipient2Title,
    translateSalutation,
    translateTitle,
  ])

  const customRenderer = useCallback(
    (content) => {
      const variablesValueLabelObject = letterObject.replacements

      variablesValueLabelObject.forEach((item) => {
        const pattern = `{{var["${item.name}"]}}`
        content = content.replace(pattern, item.value)
      })
      return content
    },
    [letterObject.replacements]
  )

  return (
    <Row>
      <Col xs={3}>
        <Row style={{ backgroundColor: 'white' }}>
          <Col>
            <Row style={{ marginTop: '15px' }}>
              <StyledLabelCol>{t('TYPE')}</StyledLabelCol>
            </Row>
            <Row>
              <Col>{lettersTypeEnumList(letterObject.type)}</Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <StyledLabelCol>{t('SENT DATE')}</StyledLabelCol>
            </Row>
            <Row>
              <Col>
                {formatDateLocale(currentLanguage, letterObject.sentAt, 'P') ||
                  t('NOT SENT')}
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <StyledLabelCol>{t('CREATED DATE')}</StyledLabelCol>
            </Row>
            <Row>
              <Col>
                {formatDateLocale(currentLanguage, letterObject.createdAt, 'P')}
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <StyledLabelCol>{t('MAIL WRITER')}</StyledLabelCol>
            </Row>
            <Row>
              <Col>
                {letterObject.sender.firstname + ' ' + letterObject.sender.name}
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <StyledLabelCol>{t('MAIL RELEASER')}</StyledLabelCol>
            </Row>
            <Row>
              <Col>
                {letterObject.sender.firstname + ' ' + letterObject.sender.name}
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <StyledLabelCol>{t('INTERNAL TITLE')}</StyledLabelCol>
            </Row>
            <Row style={{ paddingBottom: '15px' }}>
              <Col>{letterObject.internalTitle || '-'}</Col>
            </Row>
          </Col>
        </Row>
        <Row
          style={{
            backgroundColor: 'white',
            marginTop: '10px',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}
        >
          <Col>
            <Row>
              <StyledLabelCol>{t('ATTACHMENTS')}</StyledLabelCol>
            </Row>

            {letterObject?.attachments?.length < 1 && (
              <Row>
                <Col>{t('NO ATTACHMENTS')}</Col>
              </Row>
            )}
            {letterObject?.attachments?.map((item) => {
              return (
                <Row key={item.filename + item.tableId}>
                  <Col>{item.filename}</Col>
                </Row>
              )
            })}
          </Col>
        </Row>
      </Col>
      <Col style={{ marginLeft: '5px' }}>
        <Row style={{ backgroundColor: 'white', paddingBottom: '30px' }}>
          <Col>
            {letterObject.type === 'SINGLE' && (
              <Row>
                <Col xs={2} style={{ marginTop: '15px' }}>
                  <StyledBigLabelCol>{t('RECIPIENT')}</StyledBigLabelCol>
                </Col>
                <Col>
                  <Row style={{ marginTop: '15px' }}>
                    <StyledLabelCol>{t('NAME')}</StyledLabelCol>
                  </Row>
                  <Row>
                    <Col>{buildFirstRecipient() + buildSecondRecipient()}</Col>
                  </Row>
                  <Row style={{ marginTop: '15px' }}>
                    <StyledLabelCol>{t('COMPANY')}</StyledLabelCol>
                  </Row>
                  <Row>
                    <Col>{letterObject?.recipientCompanyName || '-'}</Col>
                  </Row>
                  <Row style={{ marginTop: '15px' }}>
                    <StyledLabelCol>{t('ADDRESS')}</StyledLabelCol>
                  </Row>
                  <Row>
                    <Col>
                      {letterObject.addressStreetAndNo +
                        ', ' +
                        (letterObject.addressAdditional
                          ? letterObject.addressAdditional + ', '
                          : '') +
                        letterObject.addressZip +
                        ', ' +
                        letterObject.addressCity}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={2} style={{ marginTop: '15px' }}>
                <StyledBigLabelCol>{t('LETTER')}</StyledBigLabelCol>
              </Col>
              <Col>
                <Row style={{ marginTop: '15px' }}>
                  <StyledLabelCol>{t('SUBJECT')}</StyledLabelCol>
                </Row>
                <Row>
                  <Col>{subject?.replace(/(<([^>]+)>)/gi, '') || '-'}</Col>
                </Row>

                <Row style={{ marginTop: '15px' }}>
                  <StyledLabelCol>{t('CONTENT')}</StyledLabelCol>
                </Row>
                <Row>
                  <Col>
                    {customRenderer(content?.replace(/(<([^>]+)>)/gi, ''))}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default Letter
