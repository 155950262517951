import React from 'react'
import styled from 'styled-components/macro'

import { FontQuicksand, SimpleCSS } from './Simple.styles'

const SimpleAnchor = styled.a`
  ${SimpleCSS}

  ${(props) => (props.font === 'quicksand' ? FontQuicksand : null)}
`
export default (props) => {
  const { children, font, ...restOfProps } = props

  return (
    <SimpleAnchor font={font} {...restOfProps}>
      {children}
    </SimpleAnchor>
  )
}
