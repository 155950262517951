import React from 'react'
import { DateRenderer } from '../../../components/AgGrid/renderers'

const UsageUnitLockPeriodTimeOfLockRenderer = (props) => {
  const { value } = props

  return value && value[0] ? <DateRenderer value={value[0].begin} /> : ''
}

export default UsageUnitLockPeriodTimeOfLockRenderer
