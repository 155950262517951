import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../../environments/pom/Environment'

const mutation = graphql`
  mutation UpdateLetterMutation(
    $companyId: ID!
    $letterId: Int!
    $layout: String!
    $name: String!
    $recipient1Title: UserTitleType!
    $recipient1Salutation: UserSalutationType!
    $recipient1FirstName: String!
    $recipient1LastName: String!
    $recipient2Title: UserTitleType!
    $recipient2Salutation: UserSalutationType!
    $recipient2FirstName: String!
    $recipient2LastName: String!
    $recipientCompanyName: String!
    $streetAndNumber: String!
    $additionalAddressInfo: String!
    $zip: String!
    $city: String!
    $country: String!
  ) {
    Me {
      Company(id: $companyId) {
        businessPost {
          updateLetter(
            id: $letterId
            layout: $layout
            internalName: $name
            recipient1Title: $recipient1Title
            recipient1Salutation: $recipient1Salutation
            recipient1FirstName: $recipient1FirstName
            recipient1LastName: $recipient1LastName
            recipient2Title: $recipient2Title
            recipient2Salutation: $recipient2Salutation
            recipient2FirstName: $recipient2FirstName
            recipient2LastName: $recipient2LastName
            recipientCompanyName: $recipientCompanyName
            streetAndNumber: $streetAndNumber
            additionalAddressInfo: $additionalAddressInfo
            zip: $zip
            city: $city
            country: $country
          ) {
            id
            tableId
            layout
            pageCount
            preview
          }
        }
      }
    }
  }
`

export default (variables = {}, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
