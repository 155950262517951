import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { I18nextProvider } from 'react-i18next'
import { ScreenClassProvider, setConfiguration } from 'react-grid-system'
import { LicenseManager } from 'ag-grid-enterprise'

import App from './components/App'
import UserProvider from './components/UserProvider/UserProvider'
import { LoadingSpinner, portalTheme } from 'pyrexx-react-library'

import i18 from './i18'
import * as serviceWorker from './serviceWorker'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENSE_KEY)

setConfiguration(portalTheme.grid)

ReactDOM.render(
  <I18nextProvider i18n={i18}>
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <ScreenClassProvider>
          <Suspense fallback={<LoadingSpinner />}>
            <UserProvider>
              <App />
            </UserProvider>
          </Suspense>
        </ScreenClassProvider>
      </BrowserRouter>
    </DndProvider>
  </I18nextProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
