import React from 'react'
import BulletedList from '../../../components/AgGrid/renderers/BulletedList'

const SyncListFilesRenderer = (props) => {
  const { value } = props
  return (
    <BulletedList>
      {value.map((v) => (
        <li key={v.name}>{v.name}</li>
      ))}
    </BulletedList>
  )
}

export default SyncListFilesRenderer
