import styled from 'styled-components/macro'
import { Box } from '../Box'

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Header = styled.h3`
  margin: 0;
`

export const SmallerBox = styled(Box)`
  gap: 32px;

  padding: 8px 16px;
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  position: relative;

  //& > button:hover,
  //& > button:focus {
  //  background-color: none;
  //}
`

export const ErrorNoteRow = styled.div`
  display: flex;
  justify-content: center;

  & > * {
    background-color: transparent;
  }
`

export const SimpleNoteWrapper = styled.div`
  position: absolute;
  top: 9px;
  right: 70px;

  & > * {
    background-color: transparent;
  }
`
