/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CompanyLayoutPreviewQueryVariables = {|
  companyId: string,
  companyLayoutId: string,
|};
export type CompanyLayoutPreviewQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +data: ?{|
          +id: string,
          +tableId: number,
          +preview: ?string,
        |}
      |}
    |}
  |}
|};
export type CompanyLayoutPreviewQuery = {|
  variables: CompanyLayoutPreviewQueryVariables,
  response: CompanyLayoutPreviewQueryResponse,
|};
*/


/*
query CompanyLayoutPreviewQuery(
  $companyId: ID!
  $companyLayoutId: ID!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        data: companyLayout(id: $companyLayoutId) {
          id
          tableId
          preview
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyLayoutId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessPost",
  "kind": "LinkedField",
  "name": "businessPost",
  "plural": false,
  "selections": [
    {
      "alias": "data",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "companyLayoutId"
        }
      ],
      "concreteType": "CompanyLayout",
      "kind": "LinkedField",
      "name": "companyLayout",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tableId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preview",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyLayoutPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyLayoutPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "126092a076cb6863fd5aa3f4b212a32e",
    "id": null,
    "metadata": {},
    "name": "CompanyLayoutPreviewQuery",
    "operationKind": "query",
    "text": "query CompanyLayoutPreviewQuery(\n  $companyId: ID!\n  $companyLayoutId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        data: companyLayout(id: $companyLayoutId) {\n          id\n          tableId\n          preview\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c6d62ad36fab15050cf15d509f069417';

module.exports = node;
