import React from 'react'
import { useTranslation } from 'react-i18next'
import { roomTypeEnumList } from '../enumLists'

const RoomTypeRendererHelper = (props) => {
  const { value } = props
  const { t } = useTranslation()

  if (!value) {
    return ''
  }

  return <span>{t(roomTypeEnumList(value))}</span>
}

export default RoomTypeRendererHelper
