import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import FaqItem from './FaqItem'
import { Accordion } from '@reach/accordion'

import isValid from '../../../helper/isValid'

const AccordionStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`

const FaqList = (props) => {
  const { questions = [] } = props
  const { t } = useTranslation()

  return isValid(questions) ? (
    <Accordion collapsible multiple as={AccordionStyled}>
      {questions.map((question, idx) => {
        return (
          <FaqItem
            key={question.id}
            id={question.id}
            index={idx}
            header={question.question}
            content={question.answer}
            onFeedbackGiven={console.log}
          />
        )
      })}
    </Accordion>
  ) : (
    <div>{t('NO AVAILABLE CATEGORIES')}</div>
  )
}

export default FaqList
