const usageUnitBillingPartitionValueGetter = (props) => {
  const { value } = props

  if (!value) {
    return ''
  }
  let concatString = ''
  value.forEach((item) => (concatString += item?.name + '\r\n'))
  return concatString
}

export default usageUnitBillingPartitionValueGetter
