import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Col as C, Container as Cont, Row as R } from 'react-grid-system'

import { Box } from '../Box'
import { SimpleNote } from 'pyrexx-react-library'

export const Col = C
export const Row = R

export const Boxes = styled.div`
  display: flex;
  gap: 16px;
`

export const LeftBox = styled(Box)`
  flex: 1;
`

export const RightBox = styled(Box)`
  flex: 4;
`

export const EditButtonWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const Container = styled(Cont)`
  display: flex;
  flex-direction: column;

  width: 100%;

  margin: 0 !important;
  padding: 0 !important;
`

export const Title = styled.h4`
  display: flex;
  margin-bottom: 8px;

  & button {
    margin-left: 32px;
  }
`

export const Label = styled.p`
  margin-top: 0;
  word-break: break-all;
`

export const MessageWrapper = styled.div`
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};

  & > button {
    text-align: left;
  }

  & > div > div {
    line-height: 1.2;
  }
`

export const Message = ({ show = false, children }) => {
  return <MessageWrapper show={show}>{children}</MessageWrapper>
}

export const ProfilePic = styled.img`
  border-radius: 50%;
  object-fit: cover;
`

export const UserNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-right: 16px;
  background-color: #fff;
  margin-bottom: 12px;

  ${(props) =>
    !props.show &&
    css`
      height: 0;
      visibility: hidden;
      opacity: 0;
      margin-bottom: 0;
    `}
`

export const StyledSimpleNote = styled(SimpleNote)`
  & > [data-simple-note-text] {
    padding: 10px 0;
  }
`
