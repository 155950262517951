import React, { useContext } from 'react'
import { LinkButtonStyled } from '../../../../../../../../styles/HelperStylesComponents.styles'
import { Col, Row } from 'react-grid-system'
import { ButtonIcon, portalTheme } from 'pyrexx-react-library'
import styled from 'styled-components/macro'
import UserContext from '../../../../../../../../helper/userContext'

const StyledFilterItem = styled.div`
  border-bottom: 0.2px solid grey;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
`

const FilterItem = (props) => {
  const {
    setOpenFromStart,
    filterItem,
    setDeleteActive,
    setEditActive,
    setCurrentFilter,
    currentGrid,
    setMessageBoxVisible,
  } = props
  const { getValidUrlWithQuery } = useContext(UserContext)

  return (
    <StyledFilterItem>
      <Row>
        <Col>
          <LinkButtonStyled
            style={{ textAlign: 'left' }}
            type='button'
            onClick={() => {
              setCurrentFilter(filterItem)
            }}
          >
            {filterItem.filter}
          </LinkButtonStyled>
        </Col>
        <Col xs='content'>
          <Row gutterWidth={5}>
            <Col>
              <ButtonIcon
                icon='edit'
                size='20px'
                style={{ color: '#000000' }}
                onClick={() => {
                  setOpenFromStart(true)
                  setEditActive(true)
                }}
              />
            </Col>
            <Col>
              <ButtonIcon
                icon='share'
                size='20px'
                style={{ color: '#000000' }}
                onClick={() => {
                  const query =
                    '?gridId=' +
                    currentGrid.id +
                    '&filterId=' +
                    filterItem.tableId
                  setMessageBoxVisible(true)
                  navigator.clipboard.writeText(getValidUrlWithQuery(query))
                }}
              />
            </Col>
            <Col>
              <ButtonIcon
                icon='close'
                size='20px'
                style={{ color: portalTheme.color.errorColor }}
                onClick={() => {
                  setOpenFromStart(true)
                  setEditActive(false)
                  setDeleteActive({
                    id: filterItem.tableId,
                    name: filterItem.filter,
                  })
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledFilterItem>
  )
}
export default FilterItem
