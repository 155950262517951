import React, { useContext } from 'react'
import { Row, Col } from 'react-grid-system'
import styled from 'styled-components/macro'

import { portalTheme } from 'pyrexx-react-library'
import TopHeader from './TopHeader'
import MainHeader from './MainHeader'
import BottomHeader from './BottomHeader'

import UserContext from '../../../helper/userContext'

const StickyHeader = styled.header`
  position: sticky;
  top: 0;

  z-index: 1000;
`

const MainHeaderRow = styled(Row)`
  background-color: #fff;
  border-bottom: 3px solid ${portalTheme.color.basicColorAccentRed};
`

const Header = (props) => {
  const { user } = useContext(UserContext)

  return (
    <>
      <Row style={{ backgroundColor: '#222' }}>
        <Col>
          <TopHeader {...props} />
        </Col>
      </Row>

      <StickyHeader>
        <MainHeaderRow>
          <Col>
            <MainHeader {...props} />
          </Col>
        </MainHeaderRow>
      </StickyHeader>

      {user.accessToken && user.refreshToken && (
        <Row>
          <Col>
            <BottomHeader {...props} />
          </Col>
        </Row>
      )}
    </>
  )
}

export default Header
