import { useTranslation } from 'react-i18next'
import { appointmentTypeEnumList } from '../../../components/AgGrid/enumLists'

const AppointmentTypeRenderer = (props) => {
  const { value } = props

  const { t } = useTranslation()

  if (!value) {
    return ''
  }

  return t(appointmentTypeEnumList(value))
}

export default AppointmentTypeRenderer
