export function calculateRowHeightWithValue({
  value,
  fieldProp,
  secondaryFieldProp,
  colWidth = 100,
  extraRows = 0,
  defaultHeight = 50,
  isLastCol = false,
} = {}) {
  // Update values accordingly
  const lineHeight = 19.5
  const pxPerChar = 8
  const fieldPaddingBottom = 8
  const verticalCellPadding = 8 + 8 + 2
  const horizontalCellPadding = isLastCol ? 8 + 23 + 2 : 8 + 8 + 2

  let nFields = 0
  let nFieldsRows = 0

  try {
    if (Array.isArray(value) && fieldProp && secondaryFieldProp) {
      nFields = value.length

      nFieldsRows =
        value.reduce(
          (acc, curr) =>
            acc +
            Math.ceil(
              (curr[fieldProp][secondaryFieldProp].length * pxPerChar +
                horizontalCellPadding) /
                colWidth
            ),
          0
        ) +
        extraRows +
        1
    } else if (Array.isArray(value) && fieldProp) {
      nFields = value.length

      nFieldsRows =
        value.reduce(
          (acc, curr) =>
            acc +
            Math.ceil(
              (curr[fieldProp].length * pxPerChar + horizontalCellPadding) /
                colWidth
            ),
          0
        ) +
        extraRows +
        1
    } else if (Array.isArray(value)) {
      nFields = value.length

      nFieldsRows =
        value.reduce(
          (acc, curr) =>
            acc +
            Math.ceil(
              (curr.length * pxPerChar + horizontalCellPadding) / colWidth
            ),
          0
        ) +
        extraRows +
        1
    } else {
      nFields = 1

      nFieldsRows =
        Math.ceil(
          (value.length * pxPerChar + horizontalCellPadding) / colWidth
        ) +
        extraRows +
        1
    }

    const fieldHeight = nFieldsRows * lineHeight
    const extraSpace = (nFields - 1) * fieldPaddingBottom + verticalCellPadding
    const calculatedHeight = fieldHeight + extraSpace

    return calculatedHeight > defaultHeight ? calculatedHeight : defaultHeight
  } catch {
    return defaultHeight
  }
}

export function calculateRowHeightWithParams({
  params,
  field,
  fieldProp,
  secondaryFieldProp,
  extraRows = 0,
  defaultHeight = 50,
  isLastCol = false,
} = {}) {
  const data = params.data
  const value = data[field]
  const colWidth = params.node.columnApi.getColumn(field).getActualWidth()

  return calculateRowHeightWithValue({
    value,
    fieldProp,
    secondaryFieldProp,
    colWidth,
    extraRows,
    defaultHeight,
    isLastCol,
  })
}

export function getPriorityHeight({
  columnParams = [],
  defaultHeight = 50,
} = {}) {
  try {
    const calculatedHeights = columnParams.map((col) =>
      calculateRowHeightWithValue({ ...col, defaultHeight })
    )

    const sortedHeights = calculatedHeights.sort((a, b) => {
      if (a > b) return -1
      if (a < b) return 1
      return 0
    })

    const desiredHeight = sortedHeights[0]
    //
    // console.log({
    //   columnParams,
    //   calculatedHeights,
    //   sortedHeights,
    //   desiredHeight,
    // })

    return desiredHeight > defaultHeight ? desiredHeight : defaultHeight
  } catch {
    return defaultHeight
  }
}

export function getPriorityHeightFromDisplayedColumns({
  columnParams = [],
  displayedColumns = [],
  defaultHeight = 50,
} = {}) {
  try {
    const displayedColumnsParams = columnParams.filter(
      (col) =>
        col?.field ===
        displayedColumns.find(
          (displayedCol) => displayedCol?.colId === col?.field
        )?.colId
    )

    return getPriorityHeight({
      columnParams: displayedColumnsParams,
      defaultHeight,
    })
  } catch {
    return defaultHeight
  }
}
