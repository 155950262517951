import React from 'react'
import StatusLightWithLabel from '../../../../components/Status/StatusLightWithLabel'
import { useTranslation } from 'react-i18next'
import { lettersStatusValueGetter } from '../valueGetter'

const LettersStatusRenderer = (props) => {
  const { value } = props
  const { t } = useTranslation()

  const LettersStatusMap = {
    SENT: 'success',
    PENDING: 'warning',
    DRAFT: 'error',
  }

  return (
    <StatusLightWithLabel
      status={LettersStatusMap[value]}
      label={t(lettersStatusValueGetter(props))}
    />
  )
}

export default LettersStatusRenderer
