const single = (filters) => {
  const { filterType = null } = filters
  switch (filterType) {
    case 'date':
      return date({ filters })
    case 'number':
      return number({ filters })
    case 'text':
      return text({ filters })
    default:
      throw new Error('Unknown type')
  }
}

const multiple = (filters) => {
  let i = 1
  const conditions = []
  while (filters['condition' + i]) {
    conditions.push(single(filters['condition' + i]))
    i++
  }
  return conditions
}

const number = (params) => {
  const { filters: { filter = 0, filterTo = 0, type = 'equals' } = {} } = params
  return {
    value: [String(filter), String(filterTo)],
    type,
  }
}

const date = (params) => {
  const { filters: { dateFrom = 0, dateTo = 0, type = 'equals' } = {} } = params
  return {
    value: [String(dateFrom), String(dateTo)],
    type,
  }
}

const text = (params) => {
  const { filters: { filter = '', type = 'equals' } = {} } = params
  const value = Array.isArray(filter)
    ? filter.map((f) => String(f))
    : String(filter)

  return {
    value,
    type,
  }
}

export default (options) => {
  const filter = []

  Object.entries(options).forEach((params) => {
    const [name, filters] = params
    const { filterType = 'text', operator = 'AND' } = filters
    let conditions = []

    if (filters.operator) {
      conditions = multiple(filters)
    } else {
      conditions = [single(filters)]
    }

    filter.push({
      name,
      filterType,
      operator,
      conditions,
    })
  })

  return filter
}
