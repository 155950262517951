import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonPrimary, SimpleModal, SimpleNote } from 'pyrexx-react-library'
import { Box } from '../Box'

const NoEmailModal = ({
  modal = false,
  closeModal = () => {},
  modalAction = () => {},
}) => {
  const { t } = useTranslation()

  return (
    <SimpleModal isOpen={modal} handleModalClose={closeModal}>
      <Box
        style={{
          flexDirection: 'column',
          gap: '24px',
        }}
      >
        <SimpleNote
          noteStatus='warning'
          // eslint-disable-next-line
          text={t("YOU DON'T HAVE AN EMAIL ADDRESS YET. DO YOU WANT TO SAVE IT?")}
        />
        <ButtonPrimary onClick={modalAction}>{t('YES')}</ButtonPrimary>
      </Box>
    </SimpleModal>
  )
}

export default NoEmailModal
