import React, { useContext, useState } from 'react'
import { Col, Container, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'

import {
  ButtonPrimary,
  ButtonSecondary,
  helperStyles,
  Icon,
  LoadingSpinner,
  portalTheme,
  SimpleLabel,
  SimpleModal,
  SimpleNote,
  StyledInput,
} from 'pyrexx-react-library'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import FileUpload from '../../components/FileUpload/FileUpload'
import ArrowDropdown from '../../components/ArrowDropdown'
import UserContext from '../../helper/userContext'

import popup1 from '../../assets/propertyManagement/popup_1.png'
import popup2 from '../../assets/propertyManagement/popup_2.png'
import { SyncFileType } from '../../constants/enumTypesMaps'
import LoadingBox from '../../components/LoadingSpinner/LoadingBox'
import UploadSyncFileMutation from './mutations/UploadSyncFileMutation'

const StyledRow = styled(Row)`
  background-color: ${portalTheme.color.basicColorWhite};
`

const StyledInputRow = styled(Row)`
  margin-top: 2rem;
`

const StyledNoteInput = styled(StyledInput)`
  textarea {
    background-color: ${portalTheme.color.basicColorLightGrey2};
    border-color: ${portalTheme.color.basicColorLightGrey2};

    &:focus {
      background-color: ${portalTheme.color.basicColorWhite};
      border-color: ${portalTheme.color.inputBorderColorHover};
    }
  }
`

const DownloadButton = styled.button`
  ${helperStyles.buttonRemoveBrowserStylesCss};
  border: none;
  cursor: pointer;
  background-color: transparent;
`

const StyledDownloadIcon = styled(Icon)`
  color: ${portalTheme.color.linkColorPrimary};
`

const Devider = styled.div`
  height: 100%;
  width: 1px;
  background-color: #000000;
`

const StyledArrowDropdownCol = styled(Col)`
  margin-bottom: 1em;
`

const StyledExampleTemplateCol = styled(Col)`
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 1em;
  height: 46px;
`

const StyledModal = styled(SimpleModal)`
  z-index: 99999;
`
const StyledFormatRow = styled(Row)`
  background: #ffffff;
  border: 2px solid #d8d8d8;
  box-sizing: border-box;
  padding: 0.5rem;
`
const StyledFormatTextRow = styled(Row)`
  background: #e9edee;
  padding: 0.3rem;
`

const DataUpload = (props) => {
  const { currentLanguage, user } = useContext(UserContext)
  const { vIds, loading = true } = props
  const [submitStatus, setSubmitStatus] = useState({
    loading: false,
    status: false,
    message: '',
  })
  const [modalTitle, setModalTitle] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState('')

  const handleModalOpenWithContent = (content, title) => {
    setModalTitle(title)
    setModalContent(content)
    setModalOpen(true)
  }
  const handleModalClose = () => setModalOpen(false)

  const { t } = useTranslation()

  const schema = Yup.object({
    uploadType: Yup.string().required(t('PLEASE SELECT A UPLOAD TYPE')),
    contractType: Yup.string().required(t('PLEASE ASSIGN A CONTRACT')),
    dateOfInventoryData: Yup.string().required(
      t('PLEASE SELECT THE DATE OF INVENTORY DATA')
    ),
    name: Yup.string().required(t('PLEASE ENTER YOUR NAME')),
    tel: Yup.string().required(t('PLEASE ENTER YOUR CALLBACK NUMBER')),
    contactEmail: Yup.string().email(t('INCORRECT EMAIL')),
    outfittingPriority: Yup.string().required(
      t('PLEASE SELECT THE OUTFITTING PRIORITY')
    ),
    note: Yup.string(),
    fileUpload: Yup.array()
      .min(1, t('PLEASE SELECT MIN. ONE FILE'))
      .required(t('PLEASE SELECT MIN. ONE FILE'))
      .nullable(),
  })

  const formik = useFormik({
    initialValues: {
      uploadType: '',
      contractType: '',
      dateOfInventoryData: '',
      name: '',
      tel: '',
      contactEmail: '',
      outfittingPriority: '',
      note: '',
      fileUpload: [],
    },
    validationSchema: schema,
    onSubmit: (values, formikb) => {
      const variables = {
        companyId: user.companyId,
        type: values.uploadType,
        contract: parseInt(values.contractType), // TODO change this to "values.contractType" before it goes live
        date: values.dateOfInventoryData,
        uploader: values.name,
        phoneNumber: values.tel,
        email: values.contactEmail,
        note: values.note,
        outfittingPriority: values.outfittingPriority,
        files: values.fileUpload.map((file) => null),
      }
      setSubmitStatus({
        loading: true,
        status: false,
        message: '',
      })
      UploadSyncFileMutation(
        variables,
        user.accessToken,
        values.fileUpload,
        (data) => {
          setSubmitStatus({
            loading: false,
            status: 'success',
            message: t('UPLOAD COMPLETE'),
          })
          formikb.resetForm()
        },
        (data) => {
          setSubmitStatus({
            loading: false,
            status: 'error',
            message: t('UPLOAD FAILED'),
          })
        }
      )
    },
  })

  const [smokeDetectorModal, setSmokeDetectorModal] = useState(false)

  const dropdownItems = [
    {
      id: 'smoke',
      label: (
        <Row
          justify='between'
          align='center'
          onClick={() => setSmokeDetectorModal(true)}
        >
          <Col>{t('SMOKE DETECTOR SERVICES')}</Col>
          <Col xs='content'>
            <DownloadButton
              type='button'
              onClick={() => setSmokeDetectorModal(true)}
            >
              <StyledDownloadIcon
                icon='download'
                size='1rem'
                color={portalTheme.color.linkColorPrimary}
              />
            </DownloadButton>
          </Col>
        </Row>
      ),
    },
    {
      id: 'drink',
      label: (
        <Row justify='between' align='center'>
          <Col>{t('DRINKING WATER SERVICES')}</Col>
          <Col xs='content'>
            <DownloadButton type='button' onClick={() => {}}>
              <StyledDownloadIcon
                icon='download'
                size='1rem'
                color={portalTheme.color.linkColorPrimary}
              />
            </DownloadButton>
          </Col>
        </Row>
      ),
    },
  ]

  const formatTemplate = () => {
    return (
      <Container>
        <Row
          style={{
            paddingTop: '1rem',
          }}
        >
          <Col>
            <SimpleLabel>{t('MANDATORY FIELDS')}</SimpleLabel>
          </Col>
        </Row>
        <StyledFormatRow>
          <Col>
            <StyledFormatTextRow>
              <Col>{t('AN (ALLOCATION NUMBER)')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col xs={5}>{t('STREET')}</Col>
              <Col>{t('STREET NR.')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col xs={5}>{t('ZIP')}</Col>
              <Col>{t('CITY')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col>{t('ADDITIONAL ADDRESS / BUILDING (IF AVAILABLE)')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col xs={5}>{t('FLOOR')}</Col>
              <Col>{t('POSITION')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col>{t('NUMBER OF ROOMS')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col>{t('SURFACE IN SQM')}</Col>
            </StyledFormatTextRow>

            <Row
              style={{
                paddingTop: '1rem',
              }}
            >
              <Col>{t('IF AVAILABLE')}</Col>
            </Row>
            <StyledFormatTextRow>
              <Col xs={5}>{t('INHABITANT SURNAME')}</Col>
              <Col>{t('INHABITANT FIRST NAME')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col>{t('INHABITANT PHONE NUMBER')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col>{t('OWNER / HOA NAME / ABBREVIATION')}</Col>
            </StyledFormatTextRow>
          </Col>
        </StyledFormatRow>
        <Row
          style={{
            paddingTop: '1rem',
          }}
        >
          <Col>
            <SimpleLabel>{t('OPTIONAL FIELDS')}</SimpleLabel>
          </Col>
        </Row>
        <StyledFormatRow>
          <Col>
            <StyledFormatTextRow>
              <Col>{t('TYPE OF USAGE UNIT')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col>{t('CARETAKER / RESPONSIBLE PERSON')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col>{t('CARETAKER PHONE NUMBER')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col>{t('CARETAKER EMAIL')}</Col>
            </StyledFormatTextRow>
            <StyledFormatTextRow>
              <Col>{t('INHABITANT NOTE')}</Col>
            </StyledFormatTextRow>
          </Col>
        </StyledFormatRow>
        <Row
          justify='end'
          style={{
            paddingTop: '1rem',
          }}
        >
          <Col xs='content'>
            <ButtonSecondary onclick={() => {}}>
              {t('DOWNLOAD')}
            </ButtonSecondary>
          </Col>
        </Row>
      </Container>
    )
  }

  if (loading) {
    return <LoadingBox spinner />
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {submitStatus.loading && <LoadingSpinner />}
        <Row
          justify='start'
          style={{
            paddingTop: '1.5rem',
          }}
        >
          <Col
            xs='content'
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <SimpleLabel>{t('FILE INFORMATION')}</SimpleLabel>
          </Col>
        </Row>
        <StyledRow justify='center'>
          <Col xs={5}>
            <StyledInputRow justify='center'>
              <Col xs={11} md={11}>
                <StyledInput
                  type='select'
                  name='uploadType'
                  id='uploadType'
                  onChange={formik.handleChange}
                  value={formik.values.uploadType}
                  label={t('UPLOAD TYPE')}
                  feedbackMessage={
                    formik.touched.uploadType && formik.errors.uploadType
                  }
                  setFieldValue={formik.setFieldValue}
                  optionsHtmlElemets={[
                    <option key='FULL_UPDATE' value='FULL_UPDATE'>
                      {SyncFileType.FULL_UPDATE}
                    </option>,
                    <option key='INCREMENTAL_UPDATE' value='INCREMENTAL_UPDATE'>
                      {SyncFileType.INCREMENTAL_UPDATE}
                    </option>,
                    <option key='API_TENANT_UPDATE' value='API_TENANT_UPDATE'>
                      {SyncFileType.API_TENANT_UPDATE}
                    </option>,
                  ]}
                />
              </Col>
            </StyledInputRow>
            <StyledInputRow justify='center'>
              <Col xs={11} md={11}>
                <StyledInput
                  type='select'
                  name='contractType'
                  id='contractType'
                  onChange={formik.handleChange}
                  value={formik.values.contractType}
                  label={t('ASSIGN CONTRACT')}
                  feedbackMessage={
                    formik.touched.contractType && formik.errors.contractType
                  }
                  setFieldValue={formik.setFieldValue}
                  optionsHtmlElemets={vIds.map((vId) => {
                    return (
                      <option key={vId.tableId} value={vId.tableId}>
                        {vId.name}
                      </option>
                    )
                  })}
                />
              </Col>
            </StyledInputRow>
            <StyledInputRow justify='center'>
              <Col xs={11} md={11} style={{ zIndex: 10 }}>
                <StyledInput
                  locale={currentLanguage}
                  type='date'
                  name='dateOfInventoryData'
                  id='dateOfInventoryData'
                  feedbackMessage={
                    formik.touched.dateOfInventoryData &&
                    formik.errors.dateOfInventoryData
                  }
                  onChange={formik.handleChange}
                  value={formik.values.dateOfInventoryData}
                  label={t('DATE OF INVENTORY DATA')}
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </StyledInputRow>
            <StyledInputRow justify='center'>
              <Col xs={11} md={11}>
                <StyledInput
                  type='input'
                  name='tel'
                  id='tel'
                  onChange={formik.handleChange}
                  value={formik.values.tel}
                  label={t('CALLBACK NUMBER')}
                  feedbackMessage={formik.touched.tel && formik.errors.tel}
                  feedbackStyle='invalid'
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </StyledInputRow>
          </Col>
          <Col xs={1}>
            <Row
              justify='center'
              align='center'
              style={{
                height: '100%',
              }}
            >
              <Col
                xs='content'
                style={{
                  height: '90%',
                }}
              >
                <Devider />
              </Col>
            </Row>
          </Col>
          <Col xs={5}>
            <StyledInputRow justify='center'>
              <Col xs={11} md={11}>
                <StyledInput
                  type='input'
                  name='name'
                  id='name'
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  label={t('YOUR NAME')}
                  feedbackMessage={formik.touched.name && formik.errors.name}
                  feedbackStyle='invalid'
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </StyledInputRow>
            <StyledInputRow justify='center'>
              <Col xs={11} md={11}>
                <StyledInput
                  type='input'
                  name='contactEmail'
                  id='contactEmail'
                  onChange={formik.handleChange}
                  value={formik.values.contactEmail}
                  label={t('EMAIL ADDRESS FOR NOTIFICATIONS (OPTIONAL)')}
                  feedbackMessage={
                    formik.touched.contactEmail && formik.errors.contactEmail
                  }
                  feedbackStyle='invalid'
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </StyledInputRow>
            <StyledInputRow justify='center'>
              <Col xs={11} md={11}>
                <StyledInput
                  type='select'
                  name='outfittingPriority'
                  id='outfittingPriority'
                  onChange={formik.handleChange}
                  value={formik.values.outfittingPriority}
                  label={t('OUTFITTING PRIORITY')}
                  feedbackMessage={
                    formik.touched.outfittingPriority &&
                    formik.errors.outfittingPriority
                  }
                  setFieldValue={formik.setFieldValue}
                  optionsHtmlElemets={[
                    <option
                      key={1}
                      value='AS_SOON_AS_POSSIBLE_INCLUSIVE_ADDITIONAL_COST'
                    >
                      {t('EQUIP AS SOON AS POSSIBLE INCL. ADDITIONAL COSTS')}
                    </option>,
                    <option
                      key={2}
                      value='EQUIP_WITHIN_REGULAR_PYREXX_INTERVALS'
                    >
                      {t('EQUIP WITHIN REGULAR PYREXX INTERVALS')}
                    </option>,
                  ]}
                />
              </Col>
            </StyledInputRow>
            <StyledInputRow justify='center'>
              <Col xs={11} md={11}>
                <StyledNoteInput
                  type='textarea'
                  name='note'
                  id='note'
                  onChange={formik.handleChange}
                  value={formik.values.note}
                  label={t('NOTE')}
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
            </StyledInputRow>
          </Col>
        </StyledRow>
        <Row
          align='end'
          style={{
            paddingTop: '1.5rem',
          }}
        >
          <Col
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <SimpleLabel>{t('UPLOAD DATA')}</SimpleLabel>
          </Col>
          <StyledArrowDropdownCol xs='content'>
            <ArrowDropdown
              onlyClick
              label={t('EXAMPLE FILE INVENTORY DATA')}
              dropdownItems={dropdownItems}
              selected={{}}
              setSelected={() => {}}
            />
          </StyledArrowDropdownCol>
          <StyledExampleTemplateCol xs='content'>
            <Row
              justify='between'
              align='center'
              style={{
                height: '100%',
              }}
            >
              <Col>{t('FORMAT TEMPLATE')}</Col>
              <Col xs='content'>
                <DownloadButton
                  type='button'
                  onClick={() => {
                    handleModalOpenWithContent(
                      formatTemplate(),
                      t('FORMAT TEMPLATE')
                    )
                  }}
                >
                  <StyledDownloadIcon
                    icon='download'
                    size='1rem'
                    color={portalTheme.color.linkColorPrimary}
                  />
                </DownloadButton>
              </Col>
            </Row>
          </StyledExampleTemplateCol>
        </Row>
        <StyledRow justify='center'>
          <Col
            style={{
              paddingTop: '2rem',
            }}
          >
            <FileUpload
              setFieldValue={formik.setFieldValue}
              onUpload={formik.handleChange}
              files={formik.values.fileUpload}
              multiple={false}
              name='fileUpload'
              supportedFileTypes='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
              maxsizeKb={10000}
              formik={formik}
              labels={{
                maxSizeReached: t('SIZE OF FILES EXCEEDS THE ALLOWED MAXIMUM'),
              }}
            />
          </Col>
        </StyledRow>
        <Row justify='center' style={{ marginTop: '2rem' }}>
          <Col xs='content'>
            <ButtonPrimary type='submit'>{t('UPLOAD DATA')}</ButtonPrimary>
          </Col>
        </Row>
        <Row
          justify='center'
          style={{ marginBottom: '1rem', marginTop: '1rem' }}
        >
          <Col xs='content'>
            {submitStatus.status && (
              <SimpleNote
                noteStatus={submitStatus.status}
                text={submitStatus.message}
              />
            )}
          </Col>
        </Row>
      </form>

      <StyledModal
        isOpen={modalOpen}
        handleModalClose={handleModalClose}
        title={modalTitle}
      >
        {modalContent}
      </StyledModal>

      <SimpleModal
        isOpen={smokeDetectorModal}
        closeOnOutsideClick
        handleModalClose={() => setSmokeDetectorModal(false)}
        style={{ maxWidth: '75%' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <h3 style={{ margin: 0 }}>{t('FORMAT REQUIREMENTS')}</h3>
          <h3 style={{ margin: 0 }}>{t('SMOKE DETECTOR SERVICES')}</h3>
          <h4
            style={{
              padding: '0 7rem',
              alignSelf: 'flex-start',
            }}
          >
            {t('OBLIGATORY DATA')}
          </h4>
          <img src={popup1} alt='popup 1' />

          <p style={{ padding: '0 7rem' }}>
            <strong>*</strong>{' '}
            {t(
              '(P.EXP. ACCOUNTING AREA NUMBERS, CLIENT NUMBERS, OBJECT NUMBERS, ECONOMIC UNIT NUMBERS, UNIQUE RESIDENCE NUMBERS)'
            )}
            {t('UP TO 5 COLUMNS ARE TRANSFERABLE')}
          </p>

          <h4 style={{ padding: '0 7rem', alignSelf: 'flex-start' }}>
            {t('OPTIONAL DATA')}
          </h4>
          <img src={popup2} alt='popup 2' />

          <div style={{ margin: '1rem 0' }} />
          <ButtonPrimary onClick={() => setSmokeDetectorModal(false)}>
            {t('OPEN EXAMPLE FILE')}
            {'     '}
            <StyledDownloadIcon
              icon='download'
              size='1rem'
              style={{ color: '#fff' }}
            />
          </ButtonPrimary>
        </div>
      </SimpleModal>
    </>
  )
}

export default DataUpload
