function dummyTranslate(text) {
  return text
}

const appointmentAccessReasonEnumList = (value) => {
  switch (value) {
    case 'ACCESS':
      return dummyTranslate('SUCCESSFUL')

    case 'NO_ACCESS_AWAY':
      return dummyTranslate('INHABITANT NOT PRESENT')

    case 'NO_ACCESS_DENIED':
      return dummyTranslate('ACCESS DENIED')

    case 'NO_ACCESS_VACANT_NO_KEY':
      return dummyTranslate('VACANT UNIT - NO KEY')

    case 'NO_ACCESS_TENANT_OWNS_RM':
      return dummyTranslate('INHABITANT HAS OWN SD')

    case 'NO_ACCESS_RENOVATION':
      return dummyTranslate('UNIT IN RENOVATION')

    case 'NO_ACCESS_RAMSHACKLE_SURFACE':
      return dummyTranslate('UNIT HAS RAMSHACKLE SURFACE')

    case 'NO_ACCESS_HYGIENICALLY_INFEASIBLE':
      return dummyTranslate('SERVICE HYGIENICALLY INFEASIBLE')

    case 'NO_ACCESS_DENIED_NEED_RESPITE':
      return dummyTranslate('ACCESS DENIED - INHABITANT NEEDS RESPITE')

    case 'NO_ACCESS_INFEASIBLE':
      return dummyTranslate('SERVICE WAS NOT POSSIBLE')

    case 'NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE':
      return dummyTranslate('INHABITANT PRESENT - INPUT IMPOSSIBLE')

    case 'NO_ACCESS_PARTLY_DONE_SOME_LOCATIONS_LOCKED':
      return dummyTranslate('PARTIALLY DONE - SOME LOCATIONS LOCKED')

    case 'NO_ACCESS_NO_ADULT_ON_SITE':
      return dummyTranslate('NO ADULT ON SITE')

    case 'NO_ACCESS_FOREIGN_SD_INSTALLED_NO_MAINTENANCE':
      return dummyTranslate('FOREIGN SD INSTALLED. NO MAINTANANCE')

    case 'NO_ACCESS_VACANT_KEY_DOES_NOT_FIT':
      return dummyTranslate('VACANCY, KEY DOES NOT FIT')

    case 'NO_ACCESS_JUNCTION_BOX_DESTROYED':
      return dummyTranslate('CABLE CABINET DESTROYED')

    case 'NO_ACCESS_JUNCTION_BOX_OPENED':
      return dummyTranslate('CABLE CABINET OPENED')

    case 'NO_ACCESS_JUNCTION_BOX_BROKEN':
      return dummyTranslate('CABLE CABINET BROKEN')

    case 'NO_ACCESS_JUNCTION_BOX_MISSING':
      return dummyTranslate('CABLE CABINET MISSING')

    case 'NO_ACCESS_JUNCTION_BOX_GRAFFITI_FOUND':
      return dummyTranslate('ARTWORK FOUND ON CABLE CABINET')

    case 'NO_ACCESS_JUNCTION_BOX_INACCESSIBLE':
      return dummyTranslate('CABLE CABINET INACCESSIBLE')

    case 'NONE':
    default:
      return ''
  }
}

export default appointmentAccessReasonEnumList
