import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmOverlay from '../ConfirmOverlay/ConfirmOverlay'
import UserContext from '../../../../../../helper/userContext'
import DeleteFilterMutation from '../mutations/DeleteFilterMutation'

const ConfirmDelete = (props) => {
  const {
    setOpenFromStart,
    deleteActive,
    setDeleteActive,
    getFiltersData,
    setCurrentFilter,
    currentFilter,
    gridColumnApi,
    setFilterModel,
    gridApi,
    setLoadingFilter,
  } = props
  const { t } = useTranslation()
  const { user } = useContext(UserContext)

  return (
    <ConfirmOverlay
      cancelClick={() => {
        setOpenFromStart(false)
        setDeleteActive(false)
      }}
      confirmClick={() => {
        setLoadingFilter(true)
        setOpenFromStart(false)
        setDeleteActive(false)
        const variables = {
          gridId: deleteActive.id,
          companyId: user.companyId,
        }
        DeleteFilterMutation(variables, user.accessToken, (data) => {
          if (data.Me?.Company?.GridFilter?.delete) {
            getFiltersData()
            if (currentFilter.tableId === deleteActive.id) {
              setCurrentFilter(false)
              setFilterModel({})
              gridColumnApi.resetColumnState()
              gridApi.refreshHeader()
              gridColumnApi.autoSizeAllColumns()
            }
          }
        })
      }}
      confirmButtonLabel={t('DELETE NOW')}
      name={deleteActive?.name}
      noteColor='error'
      infoText={t(
        'ATTENTION IF YOU DELETE THIS FILTER IT WILL BE DELETED COMPANY-WIDE'
      )}
    />
  )
}
export default ConfirmDelete
