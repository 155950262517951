import React, { useContext, useState } from 'react'
import styled from 'styled-components/macro'

import { Icon, LoadingProgressBar, MessageBox } from 'pyrexx-react-library'
import EmptyButton from '../Simple/EmptyButton'

import { StyledMountingAnimation } from '../../styles/HelperStylesComponents.styles'
import { fetchQuery } from 'react-relay'
import environment from '../../environments/pom/Environment'
import UserContext from '../../helper/userContext'
import { useTranslation } from 'react-i18next'

const AgGridDownload = (props) => {
  const { value = {}, children, query, variables, extractor } = props
  const { user } = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [MessageBoxVisible, setMessageBoxVisible] = useState(false)
  const [messageBoxText, setMessageBoxText] = useState(false)
  const { t } = useTranslation()

  const getDownloadDetails = () => {
    return fetchQuery(environment(user.accessToken), query, variables, {
      force: true,
    })
      .then((res) => {
        return extractor(res)
      })
      .catch((e) => {
        setLoading(false)
        setMessageBoxText(
          t(
            'SOMETHING WENT WRONG WITH THE DOWNLOAD, PLEASE CONTACT SUPPORT IF THIS HAPPENS AGAIN'
          )
        )
        setMessageBoxVisible(true)
        console.log(e)
      })
  }

  const initBrowserDownload = async (downloadDetails = {}) => {
    try {
      if (!(Object.keys(downloadDetails).length > 0)) {
        console.log('downloadedetails', downloadDetails)
        setLoading(false)
        setDownloadProgress(0)
        throw new Error('No download details')
      }

      const response = await fetch(
        process.env.REACT_APP_DOWNLOAD_PATH + downloadDetails.path,
        {
          headers: {
            Authorization: 'Bearer ' + downloadDetails.token,
            Accept: 'application/pdf',
          },
        }
      )

      const reader = await response.body.getReader()

      // Step 2: get total length
      const contentLength = await +response.headers.get('Content-Length')

      // Step 3: read the data
      let receivedLength = 0 // received that many bytes at the moment
      const chunks = [] // array of received binary chunks (comprises the body)
      while (true) {
        const { done, value } = await reader.read()

        if (done) {
          setLoading(false)
          setDownloadProgress(0)
          break
        }

        chunks.push(value)
        receivedLength += value.length
        setDownloadProgress(Math.floor(receivedLength / (contentLength / 100)))
      }

      const blob = new Blob(chunks, { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)

      const tempLink = document.createElement('a')
      tempLink.href = url
      tempLink.setAttribute('download', downloadDetails.name)
      tempLink.click()
      window.URL.revokeObjectURL(url)

      return true
    } catch (e) {
      setLoading(false)
      setMessageBoxText(
        t(
          'SOMETHING WENT WRONG WITH THE DOWNLOAD, PLEASE CONTACT SUPPORT IF THIS HAPPENS AGAIN'
        )
      )
      setMessageBoxVisible(true)
      console.log(e)
    }
  }

  const startDownload = () => {
    setLoading(true)
    getDownloadDetails(value).then((downloadDetails) => {
      initBrowserDownload(downloadDetails)
    })
  }

  return (
    <>
      <StyledMountingAnimation
        transitionName='AgGridDownload'
        transitionEnterTimeout={500}
        transitionLeaveTimeout={2000}
      >
        {loading && (
          <LoadingProgressBar boxSpinner complete={downloadProgress} />
        )}
        <Container onClick={startDownload}>
          {children ?? <StyledIcon icon='detail' size='16px' />}
        </Container>
      </StyledMountingAnimation>
      <MessageBox
        staticBox
        isVisible={MessageBoxVisible}
        closeMessageBox={() => {
          setMessageBoxVisible(false)
        }}
        statusColor='warning-mark'
        updated={new Date()}
      >
        {messageBoxText}
      </MessageBox>
    </>
  )
}

export default AgGridDownload

const Container = styled(EmptyButton)`
  text-align: center;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
`
