import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { dateTimeValueGetter } from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { DateRenderer } from '../../../components/AgGrid/renderers'
import { CountSyncListQuery, SyncListQueryQuery } from '../queries'
import SyncStatusTypeRenderer from '../renderer/SyncStatusTypeRenderer'
import {
  syncStatusTypeEnumList,
  syncTypeEnumList,
} from '../../../components/AgGrid/enumLists'
import SyncListStatusFilter from '../filters/SyncListStatusFilter'
import SyncListFilesRenderer from '../renderer/SyncListFilesRenderer'
import { SyncListTypeRenderer } from '../renderer'
import SyncTypeFilter from '../filters/SyncTypeFilter'

export const useSyncListGrid = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService) => {
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t(syncStatusTypeEnumList(params))
          case 'type':
            return t(syncTypeEnumList(params))
          case 'dataUpload':
            return dateTimeValueGetter({
              value: params?.value ? params?.value : '',
              currentLanguage: currentLanguage,
            })
          case 'created':
            return dateTimeValueGetter({
              value: params?.value ? params?.value : '',
              currentLanguage: currentLanguage,
            })
          default:
            return params.value
        }
      }
      const SyncListColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          field: 'status',
          cellRenderer: SyncStatusTypeRenderer,
          width: 70,
          sortable: false, // TODO enable sorting if possible
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
          // filter: false, // TODO add status filter
          filter: 'SyncListStatusFilter',
          ...withFilterParams,
        },
        {
          headerName: t('DATA UPLOAD'),
          field: 'created',
          width: 100,
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'Pp',
          },
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
        },
        {
          headerName: t('CONTRACT NAME'),
          field: 'contract.name',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('FILE NAME'),
          colId: 'file.name',
          field: 'files',
          width: 70,
          sortable: false,
          cellRenderer: SyncListFilesRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('TYPE'),
          field: 'type',
          width: 70,
          sortable: true,
          filter: 'SyncTypeFilter',
          cellRenderer: SyncListTypeRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('DATA RECORDS'),
          field: 'dataCount',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('UPLOADER NAME'),
          field: 'uploaderName',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('DATE OF SYNC DATA'),
          field: 'dataUpload',
          width: 100,
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'Pp',
          },
          filter: 'agDateColumnFilter',
          ...withFilterParams,
        },
        {
          headerName: t('NOTE'),
          field: 'comment',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
      ]
      const SyncListCustomFilter = [
        {
          filterType: 'text',
          operator: 'AND',
          name: 'type',
          conditions: [
            {
              type: 'notEqual',
              value: ['PLEASE_SELECT'],
            },
          ],
        },
      ]
      return {
        query: SyncListQueryQuery,
        columnDefs: SyncListColumnDefs,
        customFilters: SyncListCustomFilter,
        countQuery: CountSyncListQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return ['copy']
          },
          components: {
            SyncListStatusFilter,
            SyncTypeFilter,
          },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: {},
      }
    },
    [currentLanguage, t]
  )
  return { getParams }
}
