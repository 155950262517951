import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import graphql from 'babel-plugin-relay/macro'
import { useLocation } from 'react-router-dom'

import { ButtonPrimary, StyledInput } from 'pyrexx-react-library'
import QueryRendererContainer from '../../components/QueryRendererContainer/QueryRendererContainer'

import { Routes } from '../../configs/RouteConfig'
import { useUserContext } from '../../helper/userContext'
import handleLocalStorage from '../../helper/handleLocalStorage'
import {
  CompaniesBox,
  CompanyItem,
  Loading,
  NotFoundMessage,
  Row,
} from './styles'
import getCompanyData from '../../queries/getCompanyData'

const LIMIT_COMPANIES = 4

export const PureSwitcher = ({
  companies = [],
  initialCompany = {},
  companySwitcherViaLogin = false,
  showCompanySwitcher = false,
  onChangeCompany = () => {},
  handleShowCompanySwitcher = () => {},
  loading = false,
}) => {
  const { t } = useTranslation()

  const numberOfCompanies = companies.length || 0

  const [currentCompany, setCurrentCompany] = useState(initialCompany)

  return (
    <>
      <div>
        <p>
          {t('{AMOUNT} COMPANIES', {
            AMOUNT: numberOfCompanies,
          })}
        </p>

        <CompaniesBox
          enableScroll={numberOfCompanies > LIMIT_COMPANIES}
          data-testid='companies-list'
        >
          {numberOfCompanies > 0 ? (
            companies.map((company) => (
              <CompanyItem
                key={company?.id}
                isSelected={company?.id === currentCompany?.id}
                onClick={() => setCurrentCompany(company)}
              >
                {company?.name}
              </CompanyItem>
            ))
          ) : (
            <NotFoundMessage>{t('NO COMPANIES FOUND')}</NotFoundMessage>
          )}
        </CompaniesBox>
      </div>

      {companySwitcherViaLogin && (
        <Row styled={{ marginBottom: '1rem' }}>
          <StyledInput
            type='checkbox'
            value={!showCompanySwitcher}
            onChange={handleShowCompanySwitcher}
            id='company-selector-checkbox'
            name='company-selector-checkbox'
            label={t('DO NOT SHOW ANYMORE AFTER LOGIN')}
            setFieldValue={() => {}}
          />
        </Row>
      )}

      <Row>
        <ButtonPrimary
          onClick={() => onChangeCompany(currentCompany)}
          isLoading={loading}
        >
          {t('CONFIRM')}
        </ButtonPrimary>
      </Row>
    </>
  )
}

const Switcher = (props) => {
  const {
    companies = [],
    setShowSearchBar = () => {},
    renderSearchbar = false,
  } = props

  const { user, switchCompany, redirectRoute, currentLanguage } =
    useUserContext()
  const location = useLocation()

  // const history = useHistory()
  // TODO implement redirection after company switch
  // const prevRoute = history.location.state?.from?.pathname || Routes.overview

  const initialCompany = {
    id: user?.companyId,
    name: user?.companyName,
  }

  const [showCompanySwitcher, setShowCompanySwitcher] = useState(true)
  const [loading, setLoading] = useState(false)

  const redirectPath = location?.state?.redirect
    ? location?.state?.redirect
    : '/' + currentLanguage + Routes.overview

  const onChangeCompany = async (company) => {
    setLoading(true)

    try {
      handleLocalStorage('set', 'favCompany', {
        id: company.id,
        name: company.name,
        userId: user.userId,
      })

      const companyData = await getCompanyData(company?.id, user.accessToken)
      const { logo = '', permissions = [] } = companyData?.Me?.Company || {}

      switchCompany({
        companyId: company.id,
        companyName: company.name,
        companyLogo: logo,
        userPermissions: permissions,
      })

      redirectRoute(redirectPath, true)
    } catch (e) {
      console.log('onChangeCompany', e)
    }
  }

  const handleShowCompanySwitcher = () => {
    setShowCompanySwitcher(!showCompanySwitcher)
    handleLocalStorage(
      'set',
      'showCompanySwitcherAfterLogin',
      !showCompanySwitcher
    )
  }

  const companySwitcherViaLogin = handleLocalStorage(
    'get',
    'companySwitcherViaLogin'
  )

  // Render the searchbar or not
  useEffect(() => {
    renderSearchbar && setShowSearchBar(true)
  }, [renderSearchbar, setShowSearchBar])

  return (
    <PureSwitcher
      companies={companies}
      initialCompany={initialCompany}
      companySwitcherViaLogin={companySwitcherViaLogin}
      showCompanySwitcher={showCompanySwitcher}
      onChangeCompany={onChangeCompany}
      handleShowCompanySwitcher={handleShowCompanySwitcher}
      loading={loading}
    />
  )
}

const query = graphql`
  query SwitcherQuery {
    Me {
      Companies {
        id
        name
      }
    }
  }
`

export default (props) => {
  const { searchText = '', setShowSearchBar = () => {} } = props

  return (
    <QueryRendererContainer
      query={query}
      loading={<Loading />}
      render={(data) => {
        const companies = data.Me?.Companies

        const filteredCompanies = companies.filter((company) =>
          company?.name.toLowerCase().includes(searchText.toLowerCase())
        )

        return (
          <Switcher
            companies={filteredCompanies}
            setShowSearchBar={setShowSearchBar}
            renderSearchbar={companies.length > LIMIT_COMPANIES}
          />
        )
      }}
    />
  )
}
