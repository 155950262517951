import { useTranslation } from 'react-i18next'
import { noEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessValueGetter } from '../valueGetter'

const NoEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessRenderer = (
  props
) => {
  const { t } = useTranslation()
  return t(
    noEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessValueGetter(props)
  )
}

export default NoEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessRenderer
