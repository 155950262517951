import { useTranslation } from 'react-i18next'
import { tenantStatusEnumList } from '../../../components/AgGrid/enumLists'
import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'
import React from 'react'
import { TenantStatusMap } from '../../../constants/colorMap'

const TenantStatusRenderer = (props) => {
  const { value } = props

  const { t } = useTranslation()

  if (!value) {
    return ''
  }

  return (
    <StatusLightWithLabel
      status={TenantStatusMap[value]}
      label={t(tenantStatusEnumList(value))}
    />
  )
}

export default TenantStatusRenderer
