import { useTranslation } from 'react-i18next'
import { usageUnitTypeEnumList } from '../enumLists'

const UsageUnitTypeRendererHelper = (props) => {
  const { value } = props
  const { t } = useTranslation()

  return t(usageUnitTypeEnumList(value))
}

export default UsageUnitTypeRendererHelper
