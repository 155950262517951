import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-grid-system'
import { Icon, LoadingSpinner, portalTheme } from 'pyrexx-react-library'
import { CompanyLayoutsQuery } from './queries/index'
import QueryRendererContainer from '../../components/QueryRendererContainer'
import { useUserContext } from '../../helper/userContext'
import { Routes } from '../../configs/RouteConfig'
import { useLocation } from 'react-router-dom'
import SingleCompanyLayoutItem from './SingleCompanyLayoutItem'
import { LinkButtonStyled } from '../../styles/HelperStylesComponents.styles'
import { CreateCompanyLayoutMutation } from '../CreateCompanyLayout/mutations'
import Loader from '../../components/LoadingSpinner/Loader'

const TitleBadge = styled(Col)`
  background-color: #8aa8c8;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  border-radius: 5px;
  padding-left: 3em !important;
  padding-right: 3em !important;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: ${portalTheme.font.size.headingTiny};
`

const Circle = styled.div`
  background: #c7edfd;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CompanyLayouts = () => {
  const { t } = useTranslation()
  const { user, redirectRoute } = useUserContext()
  const location = useLocation()
  const [loadingCreateNewLayout, setLoadingCreateNewLayout] = useState(false)

  return (
    <PageContainer>
      {loadingCreateNewLayout && <Loader />}
      <Container>
        <Row>
          <TitleBadge xs='content'>{t('COMPANY LAYOUTS')}</TitleBadge>
        </Row>
        <Row style={{ marginTop: '15px' }}>
          <Col style={{ backgroundColor: 'white', padding: '40px' }}>
            <Container>
              <Row>
                <Col xs='content'>
                  <LinkButtonStyled
                    style={{ fontSize: 'inherit' }}
                    type='button'
                    onClick={() => {
                      console.log('createLayout')
                      setLoadingCreateNewLayout(true)
                      CreateCompanyLayoutMutation(
                        {
                          companyId: user.companyId,
                          letterLayoutId: 1,
                        },
                        user.accessToken,
                        (data) => {
                          setLoadingCreateNewLayout(false)
                          redirectRoute(
                            Routes.admin +
                              Routes.createCompanyLayoutEdit +
                              '/' +
                              data.Me.Company.businessPost.createCompanyLayout
                                .tableId,
                            false,
                            location.pathname
                          )
                        }
                      )
                    }}
                  >
                    <Container fluid>
                      <Row>
                        <Col>
                          <Circle>
                            <Icon
                              icon='layout_setup'
                              size={40}
                              style={{ color: 'black' }}
                            />
                          </Circle>
                        </Col>
                      </Row>
                      <Row>
                        <Col style={{ color: '#4AB4E2', paddingTop: '10px' }}>
                          {t('CREATE NEW LAYOUT')}
                        </Col>
                      </Row>
                    </Container>
                  </LinkButtonStyled>
                </Col>
                <Col xs='content' style={{ marginLeft: '10px' }}>
                  <div
                    style={{ borderLeft: 'solid 1px black', height: '100%' }}
                  />
                </Col>
                <Col style={{ marginLeft: '10px' }}>
                  <QueryRendererContainer
                    query={CompanyLayoutsQuery}
                    variables={{
                      companyId: user.companyId,
                    }}
                    loading={
                      <div style={{ height: '30px', width: '30px' }}>
                        <LoadingSpinner
                          theme={{
                            components: {
                              spinner: {
                                /**
                                 * Has to be a { css } oject from styled-components
                                 */
                                fontSize: portalTheme.font.size.bodySmall,
                                fontWeight: portalTheme.font.weight.regular,
                                size: '20px',
                              },
                            },
                          }}
                          style={{
                            position: 'unset',
                            backgroundColor: 'white',
                          }}
                        />
                      </div>
                    }
                    render={(data, retry) => {
                      const companyLayouts =
                        data?.Me?.Company?.businessPost?.companyLayouts
                      if (companyLayouts.length > 0) {
                        return (
                          <Row>
                            {companyLayouts.map((layout, index) => {
                              return (
                                <SingleCompanyLayoutItem
                                  key={'singleItem' + index + layout.tableId}
                                  layout={layout}
                                  index={index}
                                  retry={retry}
                                />
                              )
                            })}
                          </Row>
                        )
                      } else {
                        return (
                          <Row
                            justify='center'
                            align='center'
                            style={{ height: '100%' }}
                          >
                            <Col xs='content'>
                              <Icon
                                icon='info'
                                size={20}
                                style={{
                                  color: portalTheme.color.warningColor,
                                }}
                              />
                            </Col>
                            <Col xs='content'>
                              {t(
                                'YOU DONT HAVE ANY SAVED LAYOUTS. PLEASE CREATE FIRST ONE'
                              )}
                            </Col>
                          </Row>
                        )
                      }
                    }}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  )
}

export default CompanyLayouts
