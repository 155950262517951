import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query ContractsOverviewQuery(
    $companyId: ID!
    $dateFilter: [Condition]!
    $serviceFilter: [Condition]!
    $contractFilter: [Condition]!
  ) {
    Me {
      Company(id: $companyId) {
        completed: countContracts(
          options: {
            filters: [
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  {
                    value: [
                      "DONE_BUT_NOT_FINAL_BILLED"
                      "FINAL_BILLED_NOT_EXPIRED_NO_MAINTENANCE"
                    ]
                    type: equals
                  }
                ]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        planned: countContracts(
          options: {
            filters: [
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  { value: ["ACTIVE", "UNDER_CONSTRUCTION"], type: equals }
                ]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        incompleted: countContracts(
          options: {
            filters: [
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  {
                    value: [
                      "DISCARDED"
                      "READY_FOR_ACTIVATION"
                      "TEMP_SUSPENDED"
                      "EXPIRED_AND_FINAL_BILLED"
                      "MERGED_INTO_OTHER_CONTRACT"
                    ]
                    type: equals
                  }
                ]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
      }
    }
  }
`
