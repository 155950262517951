import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// eslint-disable-next-line
import styled from 'styled-components/macro'

import { ButtonIcon, Icon, portalTheme } from 'pyrexx-react-library'
import SimpleAnchor from '../../components/Simple/SimpleAnchor'
import ImageModal from '../../components/ImageModal/'
import Skeleton from '../../components/Skeleton'

import { Col, Container, DataRow, TitleRow } from './styles'

const Thumbnail = ({ image = {}, loading = false }) => {
  const [modal, setModal] = useState(false)

  return (
    <DataRow>
      <Col xs={3}>
        {loading ? (
          <Skeleton style={{ width: '150px', height: '150px' }} />
        ) : (
          <>
            <img
              src={image?.url}
              alt={'appointment image ' + image?.tableId}
              width='150'
              height='150'
              // eslint-disable-next-line
              css={`
                object-fit: cover;
              `}
            />
            <ButtonIcon
              icon='detail'
              size='25px'
              style={{
                marginLeft: '8px',
                color: portalTheme.color.linkColorPrimary,
              }}
              handleClick={() => {
                setModal(true)
              }}
            />
          </>
        )}
      </Col>
      <Col xs={3}>{loading ? <Skeleton tf /> : image?.name}</Col>
      <Col xs={5}>{loading ? <Skeleton tf /> : image?.description}</Col>
      <Col
        xs={1}
        css={`
          display: flex;
          justify-content: flex-end;
        `}
      >
        {loading ? (
          <Skeleton tf />
        ) : (
          <SimpleAnchor
            href={image?.url}
            download={image?.name}
            target='_blank'
            rel='noreferrer'
          >
            <Icon icon='download' size='30px' />
          </SimpleAnchor>
        )}
      </Col>

      {!loading && (
        <ImageModal
          imageSrc={image?.url}
          id={'image-modal-' + image?.tableId}
          isOpen={modal}
          handleModalClose={() => setModal(false)}
        />
      )}
    </DataRow>
  )
}

const Thumbnails = (props) => {
  const { images = [], loading = false } = props
  const { t } = useTranslation()

  return (
    <Container>
      {images.length > 0 || loading ? (
        <>
          <TitleRow>
            <Col xs={3} />
            <Col xs={3}>{loading ? <Skeleton tf /> : t('NAME')}</Col>
            <Col xs={5}>{loading ? <Skeleton tf /> : t('DESCRIPTION')}</Col>
            <Col
              xs={1}
              css={`
                display: flex;
                justify-content: right;
              `}
            >
              {loading ? <Skeleton tf /> : t('DOWNLOAD')}
            </Col>
          </TitleRow>

          {loading ? (
            <Thumbnail loading={loading} />
          ) : (
            images.map((image) => (
              <Thumbnail key={image?.tableId} image={image} />
            ))
          )}
        </>
      ) : (
        <DataRow>
          <Col> {t('NO IMAGES AVAILABLE')}</Col>
        </DataRow>
      )}
    </Container>
  )
}

Thumbnails.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      tableId: PropTypes.any,
      url: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
}

export default Thumbnails
