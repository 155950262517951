import React from 'react'
import { useTranslation } from 'react-i18next'

import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'

import { AppointmentStatusMap } from '../../../constants/colorMap'
import { appointmentStatusEnumList } from '../../../components/AgGrid/enumLists'

export default function AppointmentStatusRenderer(props) {
  const { value } = props
  const { t } = useTranslation()
  return (
    <>
      <StatusLightWithLabel
        status={AppointmentStatusMap[value]}
        label={t(appointmentStatusEnumList(value))}
      />
      {/** value === 'DEACTIVATED' ? (
        <div>{props.data.deactivationReason}TEST</div>
      ) : (
        ''
      )**/}
    </>
  )
}
