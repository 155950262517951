import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ButtonPrimary, portalTheme } from 'pyrexx-react-library'

import { Routes } from '../../configs/RouteConfig'
import UserContext from '../../helper/userContext'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 2rem;
`

const Description = styled.p`
  font-size: 24px;
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.light};

  text-align: center;
  margin: 3rem 0;
  margin-top: 0;
`

const BusinessMailNotFound = (props) => {
  const { currentLanguage } = useContext(UserContext)

  return (
    <Container>
      <Description>
        {props.t('WE COULD NOT FIND YOUR BUSINESS MAIL')}
      </Description>

      <Link
        to={
          '/' +
          currentLanguage +
          Routes.businessMail +
          '/' +
          Routes.businessMailsGrid
        }
      >
        <ButtonPrimary>{props.t('GO TO BUSINESS MAIL LIST')}</ButtonPrimary>
      </Link>
    </Container>
  )
}

export default withTranslation('translation')(BusinessMailNotFound)
