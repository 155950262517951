import React from 'react'
import FilterItemEdit from './FilterItemEdit/FilterItemEdit'
import FilterItem from './FilterItem/FilterItem'

const FilterItemContainer = (props) => {
  const {
    setOpenFromStart,
    filterItem,
    setDeleteActive,
    setConfirmEditActive,
    setCurrentFilter,
    editActive,
    setEditActive,
    currentGrid,
    MessageBoxVisible,
    setMessageBoxVisible,
  } = props

  return editActive ? (
    <FilterItemEdit
      setOpenFromStart={setOpenFromStart}
      filterItem={filterItem}
      setDeleteActive={setDeleteActive}
      setEditActive={setEditActive}
      setConfirmEditActive={setConfirmEditActive}
      currentGrid={currentGrid}
      MessageBoxVisible={MessageBoxVisible}
      setMessageBoxVisible={setMessageBoxVisible}
    />
  ) : (
    <FilterItem
      setOpenFromStart={setOpenFromStart}
      filterItem={filterItem}
      setDeleteActive={setDeleteActive}
      setEditActive={setEditActive}
      setCurrentFilter={setCurrentFilter}
      currentGrid={currentGrid}
      MessageBoxVisible={MessageBoxVisible}
      setMessageBoxVisible={setMessageBoxVisible}
    />
  )
}
export default FilterItemContainer
