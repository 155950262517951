import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Container, Col, Row } from 'react-grid-system'

const ErrorInfo = (props) => {
  // Dont forget to use this ErrorPage only with redirect, no history push
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  console.log(props)

  return (
    <Container xs sm md>
      <Row justify='center'>
        <Col xs={10} sm={10} md={10} lg={10}>
          Error: {props?.location?.state?.errorMessage}
        </Col>
      </Row>
    </Container>
  )
}

export default withTranslation('translation')(withRouter(ErrorInfo))
