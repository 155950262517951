/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PermissionType = "ADMIN" | "APPOINTMENT" | "CONTRACT" | "DATA_UPDATE" | "INVENTORY" | "INVOICE" | "NONE" | "POST_LETTER" | "POST_VIEW_ALL" | "QUOTA" | "%future added value";
export type CreateUserMutationVariables = {|
  companyId: string,
  firstname?: ?string,
  lastname: string,
  email?: ?string,
  username: string,
  password: string,
  permissions: $ReadOnlyArray<?PermissionType>,
  contracts?: ?$ReadOnlyArray<?number>,
  billingPartitions?: ?$ReadOnlyArray<?number>,
|};
export type CreateUserMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +createUser: ?{|
        +id: string
      |}
    |}
  |}
|};
export type CreateUserMutation = {|
  variables: CreateUserMutationVariables,
  response: CreateUserMutationResponse,
|};
*/


/*
mutation CreateUserMutation(
  $companyId: ID!
  $firstname: String
  $lastname: String!
  $email: String
  $username: String!
  $password: String!
  $permissions: [PermissionType]!
  $contracts: [Int]
  $billingPartitions: [Int]
) {
  Me {
    Company(id: $companyId) {
      createUser(firstname: $firstname, name: $lastname, email: $email, salutation: 0, title: 1, username: $username, password: $password, permissions: $permissions, contracts: $contracts, billingPartitions: $billingPartitions) {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "billingPartitions"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contracts"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstname"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastname"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "permissions"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v9 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "billingPartitions",
                "variableName": "billingPartitions"
              },
              {
                "kind": "Variable",
                "name": "contracts",
                "variableName": "contracts"
              },
              {
                "kind": "Variable",
                "name": "email",
                "variableName": "email"
              },
              {
                "kind": "Variable",
                "name": "firstname",
                "variableName": "firstname"
              },
              {
                "kind": "Variable",
                "name": "name",
                "variableName": "lastname"
              },
              {
                "kind": "Variable",
                "name": "password",
                "variableName": "password"
              },
              {
                "kind": "Variable",
                "name": "permissions",
                "variableName": "permissions"
              },
              {
                "kind": "Literal",
                "name": "salutation",
                "value": 0
              },
              {
                "kind": "Literal",
                "name": "title",
                "value": 1
              },
              {
                "kind": "Variable",
                "name": "username",
                "variableName": "username"
              }
            ],
            "concreteType": "UserInfo",
            "kind": "LinkedField",
            "name": "createUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUserMutation",
    "selections": (v9/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CreateUserMutation",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "eb56298598ca6886824064de3b41327e",
    "id": null,
    "metadata": {},
    "name": "CreateUserMutation",
    "operationKind": "mutation",
    "text": "mutation CreateUserMutation(\n  $companyId: ID!\n  $firstname: String\n  $lastname: String!\n  $email: String\n  $username: String!\n  $password: String!\n  $permissions: [PermissionType]!\n  $contracts: [Int]\n  $billingPartitions: [Int]\n) {\n  Me {\n    Company(id: $companyId) {\n      createUser(firstname: $firstname, name: $lastname, email: $email, salutation: 0, title: 1, username: $username, password: $password, permissions: $permissions, contracts: $contracts, billingPartitions: $billingPartitions) {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '17279e6f19a97981949afecb9d6361fc';

module.exports = node;
