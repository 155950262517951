function dummyTranslate(text) {
  return text
}

export const agGridLocales = {
  // Set Filter
  selectAll: dummyTranslate('agGridLocale:::(SELECT ALL)'),
  selectAllSearchResults: dummyTranslate('agGridLocale:::(SELECT ALL SEARCH RESULTS)'),
  searchOoo: dummyTranslate('agGridLocale:::SEARCH...'),
  blanks: dummyTranslate('agGridLocale:::(BLANKS)'),
  noMatches: dummyTranslate('agGridLocale:::NO MATCHES'),

  // Number Filter & Text Filter
  filterOoo: dummyTranslate('agGridLocale:::FILTER...'),
  equals: dummyTranslate('agGridLocale:::EQUALS'),
  notEqual: dummyTranslate('agGridLocale:::NOT EQUAL'),
  empty: dummyTranslate('agGridLocale:::CHOOSE ONE'),

  // Number Filter
  lessThan: dummyTranslate('agGridLocale:::LESS THAN'),
  greaterThan: dummyTranslate('agGridLocale:::GREATER THAN'),
  lessThanOrEqual: dummyTranslate('agGridLocale:::LESS THAN OR EQUAL'),
  greaterThanOrEqual: dummyTranslate('agGridLocale:::GREATER THAN OR EQUAL'),
  inRange: dummyTranslate('agGridLocale:::IN RANGE'),
  inRangeStart: dummyTranslate('agGridLocale:::TO'),
  inRangeEnd: dummyTranslate('agGridLocale:::FROM'),

  // Text Filter
  contains: dummyTranslate('agGridLocale:::CONTAINS'),
  notContains: dummyTranslate('agGridLocale:::NOT CONTAINS'),
  startsWith: dummyTranslate('agGridLocale:::STARTS WITH'),
  endsWith: dummyTranslate('agGridLocale:::ENDS WITH'),

  // Date Filter
  dateFormatOoo: dummyTranslate('agGridLocale:::YYYY-MM-DD'),

  // Filter Conditions
  andCondition: dummyTranslate('agGridLocale:::AND'),
  orCondition: dummyTranslate('agGridLocale:::OR'),

  // Filter Buttons
  applyFilter: dummyTranslate('agGridLocale:::APPLY'),
  resetFilter: dummyTranslate('agGridLocale:::RESET'),
  clearFilter: dummyTranslate('agGridLocale:::CLEAR'),
  cancelFilter: dummyTranslate('agGridLocale:::CANCEL'),

  // Filter Titles
  textFilter: dummyTranslate('agGridLocale:::TEXT FILTER'),
  numberFilter: dummyTranslate('agGridLocale:::NUMBER FILTER'),
  dateFilter: dummyTranslate('agGridLocale:::DATE FILTER'),
  setFilter: dummyTranslate('agGridLocale:::SET FILTER'),

  // Side Bar
  columns: dummyTranslate('agGridLocale:::COLUMNS'),
  filters: dummyTranslate('agGridLocale:::FILTERS'),

  // columns tool panel
  pivotMode: dummyTranslate('agGridLocale:::PIVOT MODE'),
  groups: dummyTranslate('agGridLocale:::ROW GROUPS'),
  rowGroupColumnsEmptyMessage: dummyTranslate('agGridLocale:::DRAG HERE TO SET ROW GROUPS'),
  values: dummyTranslate('agGridLocale:::VALUES'),
  valueColumnsEmptyMessage: dummyTranslate('agGridLocale:::DRAG HERE TO AGGREGATE'),
  pivots: dummyTranslate('agGridLocale:::COLUMN LABELS'),
  pivotColumnsEmptyMessage: dummyTranslate('agGridLocale:::DRAG HERE TO SET COLUMN LABELS'),

  // Header of the Default Group Column
  group: dummyTranslate('agGridLocale:::GROUP'),

  // Other
  loadingOoo: dummyTranslate('agGridLocale:::LOADING...'),
  noRowsToShow: dummyTranslate('agGridLocale:::NO ROWS TO SHOW'),
  enabled: dummyTranslate('agGridLocale:::ENABLED'),

  // Menu
  pinColumn: dummyTranslate('agGridLocale:::PIN COLUMN'),
  pinLeft: dummyTranslate('agGridLocale:::PIN LEFT'),
  pinRight: dummyTranslate('agGridLocale:::PIN RIGHT'),
  noPin: dummyTranslate('agGridLocale:::NO PIN'),
  valueAggregation: dummyTranslate('agGridLocale:::VALUE AGGREGATION'),
  autosizeThiscolumn: dummyTranslate('agGridLocale:::AUTOSIZE THIS COLUMN'),
  autosizeAllColumns: dummyTranslate('agGridLocale:::AUTOSIZE ALL COLUMNS'),
  groupBy: dummyTranslate('agGridLocale:::GROUP BY'),
  ungroupBy: dummyTranslate('agGridLocale:::UN-GROUP BY'),
  resetColumns: dummyTranslate('agGridLocale:::RESET COLUMNS'),
  expandAll: dummyTranslate('agGridLocale:::EXPAND ALL'),
  collapseAll: dummyTranslate('agGridLocale:::CLOSE ALL'),
  copy: dummyTranslate('agGridLocale:::COPY'),
  ctrlC: dummyTranslate('agGridLocale:::CTRL+C'),
  copyWithHeaders: dummyTranslate('agGridLocale:::COPY WITH HEADERS'),
  paste: dummyTranslate('agGridLocale:::PASTE'),
  ctrlV: dummyTranslate('agGridLocale:::CTRL+V'),
  export: dummyTranslate('agGridLocale:::EXPORT'),
  csvExport: dummyTranslate('agGridLocale:::CSV EXPORT'),
  excelExport: dummyTranslate('agGridLocale:::EXCEL EXPORT'),

  // Enterprise Menu Aggregation and Status Bar
  sum: dummyTranslate('agGridLocale:::SUM'),
  min: dummyTranslate('agGridLocale:::MIN'),
  max: dummyTranslate('agGridLocale:::MAX'),
  none: dummyTranslate('agGridLocale:::NONE'),
  count: dummyTranslate('agGridLocale:::COUNT'),
  avg: dummyTranslate('agGridLocale:::AVERAGE'),
  average: dummyTranslate('agGridLocale:::AVERAGE'),
  filteredRows: dummyTranslate('agGridLocale:::FILTERED'),
  selectedRows: dummyTranslate('agGridLocale:::SELECTED'),
  totalRows: dummyTranslate('agGridLocale:::TOTAL ROWS'),
  totalAndFilteredRows: dummyTranslate('agGridLocale:::ROWS'),
  more: dummyTranslate('agGridLocale:::MORE'),
  to: dummyTranslate('agGridLocale:::TO'),
  of: dummyTranslate('agGridLocale:::OF'),
  page: dummyTranslate('agGridLocale:::PAGE'),
  nextPage: dummyTranslate('agGridLocale:::NEXT PAGE'),
  lastPage: dummyTranslate('agGridLocale:::LAST PAGE'),
  firstPage: dummyTranslate('agGridLocale:::FIRST PAGE'),
  previousPage: dummyTranslate('agGridLocale:::PREVIOUS PAGE'),

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: dummyTranslate('agGridLocale:::PIVOT CHART & PIVOT MODE'),
  pivotChart: dummyTranslate('agGridLocale:::PIVOT CHART'),
  chartRange: dummyTranslate('agGridLocale:::CHART RANGE'),

  columnChart: dummyTranslate('agGridLocale:::COLUMN'),
  groupedColumn: dummyTranslate('agGridLocale:::GROUPED'),
  stackedColumn: dummyTranslate('agGridLocale:::STACKED'),
  normalizedColumn: dummyTranslate('agGridLocale:::100% STACKED'),

  barChart: dummyTranslate('agGridLocale:::BAR'),
  groupedBar: dummyTranslate('agGridLocale:::GROUPED'),
  stackedBar: dummyTranslate('agGridLocale:::STACKED'),
  normalizedBar: dummyTranslate('agGridLocale:::100% STACKED'),

  pieChart: dummyTranslate('agGridLocale:::PIE'),
  pie: dummyTranslate('agGridLocale:::PIE'),
  doughnut: dummyTranslate('agGridLocale:::DOUGHNUT'),

  line: dummyTranslate('agGridLocale:::LINE'),

  xyChart: dummyTranslate('agGridLocale:::X Y (SCATTER)'),
  scatter: dummyTranslate('agGridLocale:::SCATTER'),
  bubble: dummyTranslate('agGridLocale:::BUBBLE'),

  areaChart: dummyTranslate('agGridLocale:::AREA'),
  area: dummyTranslate('agGridLocale:::AREA'),
  stackedArea: dummyTranslate('agGridLocale:::STACKED'),
  normalizedArea: dummyTranslate('agGridLocale:::100% STACKED'),

  histogramChart: dummyTranslate('agGridLocale:::HISTOGRAM'),

  // Charts
  pivotChartTitle: dummyTranslate('agGridLocale:::PIVOT CHART'),
  rangeChartTitle: dummyTranslate('agGridLocale:::RANGE CHART'),
  settings: dummyTranslate('agGridLocale:::SETTINGS'),
  data: dummyTranslate('agGridLocale:::DATA'),
  format: dummyTranslate('agGridLocale:::FORMAT'),
  categories: dummyTranslate('agGridLocale:::CATEGORIES'),
  defaultCategory: dummyTranslate('agGridLocale:::(NONE)'),
  series: dummyTranslate('agGridLocale:::SERIES'),
  xyValues: dummyTranslate('agGridLocale:::X Y VALUES'),
  paired: dummyTranslate('agGridLocale:::PAIRED MODE'),
  axis: dummyTranslate('agGridLocale:::AXIS'),
  navigator: dummyTranslate('agGridLocale:::NAVIGATOR'),
  color: dummyTranslate('agGridLocale:::COLOR'),
  thickness: dummyTranslate('agGridLocale:::THICKNESS'),
  xType: dummyTranslate('agGridLocale:::X TYPE'),
  automatic: dummyTranslate('agGridLocale:::AUTOMATIC'),
  category: dummyTranslate('agGridLocale:::CATEGORY'),
  number: dummyTranslate('agGridLocale:::NUMBER'),
  time: dummyTranslate('agGridLocale:::TIME'),
  xRotation: dummyTranslate('agGridLocale:::X ROTATION'),
  yRotation: dummyTranslate('agGridLocale:::Y ROTATION'),
  ticks: dummyTranslate('agGridLocale:::TICKS'),
  width: dummyTranslate('agGridLocale:::WIDTH'),
  height: dummyTranslate('agGridLocale:::HEIGHT'),
  length: dummyTranslate('agGridLocale:::LENGTH'),
  padding: dummyTranslate('agGridLocale:::PADDING'),
  spacing: dummyTranslate('agGridLocale:::SPACING'),
  chart: dummyTranslate('agGridLocale:::CHART'),
  title: dummyTranslate('agGridLocale:::TITLE'),
  titlePlaceholder: dummyTranslate('agGridLocale:::CHART TITLE - DOUBLE CLICK TO EDIT'),
  background: dummyTranslate('agGridLocale:::BACKGROUND'),
  font: dummyTranslate('agGridLocale:::FONT'),
  top: dummyTranslate('agGridLocale:::TOP'),
  right: dummyTranslate('agGridLocale:::RIGHT'),
  bottom: dummyTranslate('agGridLocale:::BOTTOM'),
  left: dummyTranslate('agGridLocale:::LEFT'),
  labels: dummyTranslate('agGridLocale:::LABELS'),
  size: dummyTranslate('agGridLocale:::SIZE'),
  minSize: dummyTranslate('agGridLocale:::MINIMUM SIZE'),
  maxSize: dummyTranslate('agGridLocale:::MAXIMUM SIZE'),
  legend: dummyTranslate('agGridLocale:::LEGEND'),
  position: dummyTranslate('agGridLocale:::POSITION'),
  markerSize: dummyTranslate('agGridLocale:::MARKER SIZE'),
  markerStroke: dummyTranslate('agGridLocale:::MARKER STROKE'),
  markerPadding: dummyTranslate('agGridLocale:::MARKER PADDING'),
  itemSpacing: dummyTranslate('agGridLocale:::ITEM SPACING'),
  itemPaddingX: dummyTranslate('agGridLocale:::ITEM PADDING X'),
  itemPaddingY: dummyTranslate('agGridLocale:::ITEM PADDING Y'),
  layoutHorizontalSpacing: dummyTranslate('agGridLocale:::HORIZONTAL SPACING'),
  layoutVerticalSpacing: dummyTranslate('agGridLocale:::VERTICAL SPACING'),
  strokeWidth: dummyTranslate('agGridLocale:::STROKE WIDTH'),
  offset: dummyTranslate('agGridLocale:::OFFSET'),
  offsets: dummyTranslate('agGridLocale:::OFFSETS'),
  tooltips: dummyTranslate('agGridLocale:::TOOLTIPS'),
  callout: dummyTranslate('agGridLocale:::CALLOUT'),
  markers: dummyTranslate('agGridLocale:::MARKERS'),
  shadow: dummyTranslate('agGridLocale:::SHADOW'),
  blur: dummyTranslate('agGridLocale:::BLUR'),
  xOffset: dummyTranslate('agGridLocale:::X OFFSET'),
  yOffset: dummyTranslate('agGridLocale:::Y OFFSET'),
  lineWidth: dummyTranslate('agGridLocale:::LINE WIDTH'),
  normal: dummyTranslate('agGridLocale:::NORMAL'),
  bold: dummyTranslate('agGridLocale:::BOLD'),
  italic: dummyTranslate('agGridLocale:::ITALIC'),
  boldItalic: dummyTranslate('agGridLocale:::BOLD ITALIC'),
  predefined: dummyTranslate('agGridLocale:::PREDEFINED'),
  fillOpacity: dummyTranslate('agGridLocale:::FILL OPACITY'),
  strokeOpacity: dummyTranslate('agGridLocale:::LINE OPACITY'),
  histogramBinCount: dummyTranslate('agGridLocale:::BIN COUNT'),
  columnGroup: dummyTranslate('agGridLocale:::COLUMN'),
  barGroup: dummyTranslate('agGridLocale:::BAR'),
  pieGroup: dummyTranslate('agGridLocale:::PIE'),
  lineGroup: dummyTranslate('agGridLocale:::LINE'),
  scatterGroup: dummyTranslate('agGridLocale:::X Y (SCATTER)'),
  areaGroup: dummyTranslate('agGridLocale:::AREA'),
  histogramGroup: dummyTranslate('agGridLocale:::HISTOGRAM'),
  groupedColumnTooltip: dummyTranslate('agGridLocale:::GROUPED'),
  stackedColumnTooltip: dummyTranslate('agGridLocale:::STACKED'),
  normalizedColumnTooltip: dummyTranslate('agGridLocale:::100% STACKED'),
  groupedBarTooltip: dummyTranslate('agGridLocale:::GROUPED'),
  stackedBarTooltip: dummyTranslate('agGridLocale:::STACKED'),
  normalizedBarTooltip: dummyTranslate('agGridLocale:::100% STACKED'),
  pieTooltip: dummyTranslate('agGridLocale:::PIE'),
  doughnutTooltip: dummyTranslate('agGridLocale:::DOUGHNUT'),
  lineTooltip: dummyTranslate('agGridLocale:::LINE'),
  groupedAreaTooltip: dummyTranslate('agGridLocale:::AREA'),
  stackedAreaTooltip: dummyTranslate('agGridLocale:::STACKED'),
  normalizedAreaTooltip: dummyTranslate('agGridLocale:::100% STACKED'),
  scatterTooltip: dummyTranslate('agGridLocale:::SCATTER'),
  bubbleTooltip: dummyTranslate('agGridLocale:::BUBBLE'),
  histogramTooltip: dummyTranslate('agGridLocale:::HISTOGRAM'),
  noDataToChart: dummyTranslate('agGridLocale:::NO DATA AVAILABLE TO BE CHARTED.'),
  pivotChartRequiresPivotMode: dummyTranslate('agGridLocale:::PIVOT CHART REQUIRES PIVOT MODE ENABLED.'),
  chartSettingsToolbarTooltip: dummyTranslate('agGridLocale:::MENU'),
  chartLinkToolbarTooltip: dummyTranslate('agGridLocale:::LINKED TO GRID'),
  chartUnlinkToolbarTooltip: dummyTranslate('agGridLocale:::UNLINKED FROM GRID'),
  chartDownloadToolbarTooltip: dummyTranslate('agGridLocale:::DOWNLOAD CHART'),

  // ARIA
  ariaHidden: dummyTranslate('agGridLocale:::HIDDEN'),
  ariaVisible: dummyTranslate('agGridLocale:::VISIBLE'),
  ariaChecked: dummyTranslate('agGridLocale:::CHECKED'),
  ariaUnchecked: dummyTranslate('agGridLocale:::UNCHECKED'),
  ariaIndeterminate: dummyTranslate('agGridLocale:::INDETERMINATE'),
  ariaColumnSelectAll: dummyTranslate('agGridLocale:::TOGGLE SELECT ALL COLUMNS'),
  ariaInputEditor: dummyTranslate('agGridLocale:::INPUT EDITOR'),
  ariaDateFilterInput: dummyTranslate('agGridLocale:::DATE FILTER INPUT'),
  ariaFilterInput: dummyTranslate('agGridLocale:::FILTER INPUT'),
  ariaFilterColumnsInput: dummyTranslate('agGridLocale:::FILTER COLUMNS INPUT'),
  ariaFilterValue: dummyTranslate('agGridLocale:::FILTER VALUE'),
  ariaFilterFromValue: dummyTranslate('agGridLocale:::FILTER FROM VALUE'),
  ariaFilterToValue: dummyTranslate('agGridLocale:::FILTER TO VALUE'),
  ariaFilteringOperator: dummyTranslate('agGridLocale:::FILTERING OPERATOR'),
  ariaColumnToggleVisibility: dummyTranslate('agGridLocale:::COLUMN TOGGLE VISIBILITY'),
  ariaColumnGroupToggleVisibility: dummyTranslate('agGridLocale:::COLUMN GROUP TOGGLE VISIBILITY'),
  ariaRowSelect: dummyTranslate('agGridLocale:::PRESS SPACE TO SELECT THIS ROW'),
  ariaRowDeselect: dummyTranslate('agGridLocale:::PRESS SPACE TO DESELECT THIS ROW'),
  ariaRowToggleSelection: dummyTranslate('agGridLocale:::PRESS SPACE TO TOGGLE ROW SELECTION'),
  ariaRowSelectAll: dummyTranslate('agGridLocale:::PRESS SPACE TO TOGGLE ALL ROWS SELECTION'),
  ariaSearch: dummyTranslate('agGridLocale:::SEARCH'),
  ariaSearchFilterValues: dummyTranslate('agGridLocale:::SEARCH FILTER VALUES'),
}
