import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import styled from 'styled-components/macro'
import { css } from 'styled-components'

import {
  ButtonSecondary,
  CustomCheckboxRadio,
  Icon,
  LetterLayout,
  MessageNofi,
  portalTheme,
  StyledInput,
} from 'pyrexx-react-library'

import { LinkButtonStyled } from '../../styles/HelperStylesComponents.styles'
import layoutPreviewDummyTranslations from './layoutPreviewDummyTranslations'
import companyLayoutStatusEnumList from '../AgGrid/enumLists/companyLayoutStatusEnumList'
import EmptyButton from '../Simple/EmptyButton'
import UpdateSaveButton from '../UpdateSavedButton/UpdateSaveButton'

const LayoutPreview = (props) => {
  const {
    id,
    initContainer,
    hidePreview = false,
    pageCount = 0,
    replacements = [],
    saveButtonTextActive,
    singleLetter = false,
    saveButtonTextDraft,
    submitMutation,
    overrideSendButtonMutation,
    containerFormikOverride = [],
    adminEdit = false,
    previewShow = true,
    containerFormErrors = false,
    name = '',
    nameIsReq = true,
    status,
    triggerPreview = () => {},
    specialEditorFormWithoutActiveContainer = [
      {
        formikIncludeSchema: {
          test: Yup.string().nullable(),
        },
        formikIncludeInitValues: {
          fileUploadAttachments: '',
        },
        name: 'test',
        getForm: () => {},
      },
    ],
    nameLabel = false,
    layoutChangeStatus,
    setLayoutChangeStatus,
  } = props
  const { t } = useTranslation()
  const [activeSpecialContainer, setActiveSpecialContainer] = useState(false)
  const [activeContainer, setActiveContainer] = useState(
    !adminEdit && !singleLetter ? initContainer.addressWindow : false
  )
  const [activeContainerKey, setActiveContainerKey] = useState('addressWindow')
  const [containers, setContainers] = useState(initContainer)
  const [mutateContainer, setMutateContainer] = useState(false)
  const [nameChangeLoader, setNameChangeLoader] = useState(false)
  const [contentUpdateLoader, setContentUpdateLoader] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [layoutStatus, setLayoutStatus] = useState(status)
  // eslint-disable-next-line no-unused-vars
  const [layoutName, setLayoutName] = useState(name)

  useEffect(() => {
    if (activeSpecialContainer) {
      setActiveContainer(false)
      setActiveContainerKey(false)
    }
  }, [activeSpecialContainer])

  useEffect(() => {
    if (mutateContainer) {
      const timeOutId = setTimeout(() => {
        setMutateContainer(false)
        submitMutation(
          id,
          containers,
          formik.values,
          setContentUpdateLoader,
          layoutStatus,
          (data) => {}
        )
      }, 200)
      return () => clearTimeout(timeOutId)
    }
    // eslint-disable-next-line
  }, [mutateContainer])

  const generateInitSchema = () => {
    const initSchema = { name: Yup.string() }
    Object.keys(containers).forEach((key) => {
      switch (key) {
        case 'header':
        case 'footer':
        case 'sender':
        case 'addressWindow':
          initSchema[key] = Yup.string()
          break
        case 'content':
          containers[key].forEach((singleContentObject) => {
            initSchema[singleContentObject.id] = Yup.string()
          })
          break
        default:
      }
    })

    if (containerFormikOverride) {
      containerFormikOverride.forEach((object) => {
        Object.keys(object?.formikIncludeSchema).forEach((key) => {
          if (
            typeof object?.formikIncludeSchema[key] === 'object' &&
            typeof initSchema[key] === 'object'
          ) {
            initSchema[key] = Yup.object().shape({
              ...(initSchema[key].fields ?? {}),
              ...object?.formikIncludeSchema[key].fields,
            })
          } else {
            initSchema[key] = object?.formikIncludeSchema[key]
          }
        })
      })
    }

    if (specialEditorFormWithoutActiveContainer) {
      specialEditorFormWithoutActiveContainer.forEach((object) => {
        Object.keys(object?.formikIncludeSchema).forEach((key) => {
          if (
            typeof object?.formikIncludeSchema[key] === 'object' &&
            typeof initSchema[key] === 'object'
          ) {
            initSchema[key] = Yup.object().shape({
              ...(initSchema[key].fields ?? {}),
              ...object?.formikIncludeSchema[key].fields,
            })
          } else {
            initSchema[key] = object?.formikIncludeSchema[key]
          }
        })
      })
    }

    if (nameIsReq) {
      initSchema.name = Yup.string()
        .max(140, t('NAME CAN MAX 140 CHARACTERS'))
        .required(t('NAME MUST BE AT LEAST 3 CHARACTERS'))
    } else {
      initSchema.name = Yup.string().max(140, t('NAME CAN MAX 140 CHARACTERS'))
    }

    return initSchema
  }

  const generateInitValues = () => {
    const initValues = {}
    Object.keys(containers).forEach((key) => {
      switch (key) {
        case 'header':
        case 'footer':
        case 'sender':
        case 'addressWindow':
          initValues[key] = containers[key].content
          break
        case 'content':
          containers[key].forEach((singleContentObject) => {
            initValues[singleContentObject.id] = singleContentObject.content
          })
          break
        default:
      }
    })
    if (containerFormikOverride) {
      containerFormikOverride.forEach((object) => {
        Object.keys(object?.formikIncludeInitValues).forEach((key) => {
          if (
            typeof object?.formikIncludeInitValues[key] === 'object' &&
            typeof initValues[key] === 'object'
          ) {
            initValues[key] = {
              ...(initValues[key] ?? {}),
              ...object?.formikIncludeInitValues[key],
            }
          } else {
            initValues[key] = object?.formikIncludeInitValues[key]
          }
        })
      })
    }

    if (specialEditorFormWithoutActiveContainer) {
      specialEditorFormWithoutActiveContainer.forEach((object) => {
        Object.keys(object?.formikIncludeInitValues).forEach((key) => {
          if (
            typeof object?.formikIncludeInitValues[key] === 'object' &&
            typeof initValues[key] === 'object'
          ) {
            initValues[key] = {
              ...(initValues[key] ?? {}),
              ...object?.formikIncludeInitValues[key],
            }
          } else {
            initValues[key] = object?.formikIncludeInitValues[key]
          }
        })
      })
    }

    return initValues
  }

  const schema = Yup.object({
    doubleSided: Yup.bool(),
    ...generateInitSchema(),
  })

  const formik = useFormik({
    initialValues: {
      name: name,
      doubleSided: containers?.printOptions?.doubleSided
        ? containers?.printOptions?.doubleSided
        : false,
      ...generateInitValues(),
    },
    validationSchema: schema,
    onSubmit: (values, formikb) => {
      submitMutation(
        id,
        containers,
        values,
        setContentUpdateLoader,
        layoutStatus === 'ACTIVE' ? 'DRAFT' : 'ACTIVE',
        () => {
          setLayoutStatus(layoutStatus === 'ACTIVE' ? 'DRAFT' : 'ACTIVE')
        }
      )

      console.log('submit')
    },
  })

  const updateContainer = useCallback(
    (overRideValue = false) => {
      setContentUpdateLoader(true)
      switch (activeContainerKey) {
        case 'header':
        case 'footer':
        case 'sender':
        case 'addressWindow':
          setContainers({
            ...containers,
            [activeContainerKey]: {
              ...containers[activeContainerKey],
              content:
                overRideValue !== false
                  ? overRideValue
                  : formik.values[activeContainerKey],
            },
          })
          break
        case 'content':
          setContainers({
            ...containers,
            [activeContainerKey]: containers[activeContainerKey].map(
              (object) => {
                if (object.id !== activeContainer.id) {
                  return object
                } else {
                  return {
                    ...object,
                    content:
                      overRideValue !== false
                        ? overRideValue
                        : formik.values[activeContainer.id],
                  }
                }
              }
            ),
          })
          break
        default:
      }
    },
    [activeContainer.id, activeContainerKey, containers, formik.values]
  )
  const [currentEditText, setCurrentEditText] = useState(false)

  useEffect(() => {
    switch (activeContainerKey) {
      case 'header':
      case 'footer':
      case 'sender':
      case 'addressWindow':
        setCurrentEditText(formik.values[activeContainerKey])
        break
      case 'content':
        setCurrentEditText(formik.values[activeContainer.id])
        break
      case 'default':
        break
      default:
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  useEffect(() => {
    if (activeContainer !== false) {
      setActiveSpecialContainer(false)
    }
    switch (activeContainerKey) {
      case 'header':
      case 'footer':
      case 'sender':
      case 'addressWindow':
        setCurrentEditText(containers[activeContainerKey].content)
        break
      case 'content':
        setCurrentEditText(
          containers[activeContainerKey].filter(
            (objectTmp) => objectTmp.id === activeContainer.id
          )[0].content
        )
        break
      default:
    }
  }, [activeContainer, activeContainerKey, containers])

  const showEditor = () => {
    const specialContainer = specialEditorFormWithoutActiveContainer.find(
      (object) => object.name === activeSpecialContainer
    )
    if (!activeContainer && !specialContainer) {
      return (
        <Row
          justify='center'
          align='center'
          style={{ paddingTop: '40px', paddingBottom: '40px' }}
        >
          <Col xs='content'>
            <Icon
              icon='info'
              size='16'
              aria-hidden='true'
              color={portalTheme.color.warningColor}
            />
          </Col>
          <Col xs='content'>
            {t('WHICH CONTENT YOU WANT TO EDIT? PLEASE SELECT.')}
          </Col>
        </Row>
      )
    }

    if (specialContainer) {
      return specialContainer.getForm(
        formik,
        updateContainer,
        contentUpdateLoader
      )
    }

    if (
      containerFormikOverride &&
      containerFormikOverride.find(
        (object) =>
          object.containerKey === activeContainerKey &&
          activeContainer.id === object.id
      )
    ) {
      return containerFormikOverride
        .find(
          (object) =>
            object.containerKey === activeContainerKey &&
            activeContainer.id === object.id
        )
        .getForm(formik, updateContainer, contentUpdateLoader)
    }
    console.log('DEBUG ACTIVE CONTAINER', activeContainerKey, formik.values)
    switch (activeContainerKey) {
      case 'header':
      case 'footer':
      case 'sender':
      case 'addressWindow':
        return (
          <>
            <h3 style={{ margin: '0 0 15px 0' }}>{t('SENDER ADDRESS')}</h3>
            <p>{t('PLEASE ENTER YOUR SENDER ADDRESS. MAX 90 CHARACTERS')}</p>
            <Row>
              <Col style={{ paddingTop: '20px' }}>
                <StyledInput
                  type='input'
                  key={activeContainerKey + '_key'}
                  name={activeContainerKey}
                  id={activeContainerKey + '_id'}
                  disabled={false}
                  onChange={(event) => {
                    formik.handleChange(event)
                  }}
                  value={formik.values[activeContainerKey]}
                  label={t('Edit')}
                  feedbackMessage={
                    formik.values[activeContainerKey] &&
                    formik.errors[activeContainerKey]
                  }
                  setFieldValue={formik.setFieldValue}
                  feedbackStyle='invalid'
                />
              </Col>
            </Row>
            <Row justify='end'>
              <Col style={{ paddingBottom: '20px' }}>
                <UpdateSaveButton
                  loading={contentUpdateLoader}
                  handleSave={() => {
                    updateContainer()
                  }}
                  noChangedContent={() => {
                    return (
                      currentEditText === containers[activeContainerKey].content
                    )
                  }}
                />
              </Col>
            </Row>
          </>
        )
      case 'content':
        return (
          <>
            <h3 style={{ margin: '0' }}>{t('ADD SUBJECT (OPTIONAL)')}</h3>
            <Row>
              <Col style={{ paddingTop: '20px' }}>
                <StyledInput
                  type='input'
                  name={activeContainer.id}
                  id={activeContainer.id + '_id'}
                  disabled={false}
                  onChange={(event) => {
                    formik.handleChange(event)
                  }}
                  value={formik.values[activeContainer.id]}
                  label={t('Edit')}
                  feedbackMessage={
                    formik.values[activeContainer.id] &&
                    formik.errors[activeContainer.id]
                  }
                  setFieldValue={formik.setFieldValue}
                  feedbackStyle='invalid'
                />
              </Col>
            </Row>
            <Row justify='end'>
              <Col style={{ paddingBottom: '20px' }}>
                <UpdateSaveButton
                  loading={contentUpdateLoader}
                  handleSave={() => {
                    updateContainer()
                  }}
                  noChangedContent={() => {
                    return (
                      currentEditText ===
                      containers[activeContainerKey].filter(
                        (objectTmp) => objectTmp.id === activeContainer.id
                      )[0].content
                    )
                  }}
                />
              </Col>
            </Row>
          </>
        )
      default:
    }
  }

  useEffect(() => {
    if (!firstLoad) {
      const timeOutId = setTimeout(() => {
        submitMutation(
          id,
          containers,
          formik.values,
          setContentUpdateLoader,
          layoutStatus,
          (data) => {
            setLayoutName(formik.values.name)
          }
        )
      }, 200)
      return () => clearTimeout(timeOutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containers, formik.values.doubleSided])

  useEffect(() => {
    if (!firstLoad) {
      const timeOutId = setTimeout(() => {
        const newContainer = {
          ...containers,
          header: {
            ...containers.header,
            firstPageOnly: !formik.values.headerKeep,
          },
          footer: {
            ...containers.footer,
            firstPageOnly: !formik.values.footerKeep,
          },
        }
        setContainers(newContainer)
        // setMutateContainer(true)
      }, 200)
      return () => clearTimeout(timeOutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.footerKeep, formik.values.headerKeep])

  useEffect(() => {
    if (firstLoad && name === formik.values.name) {
      setFirstLoad(false)
    }
    if (!firstLoad) {
      const timeOutId = setTimeout(() => {
        formik.setFieldTouched('name', true)
        formik.validateForm().then((errors) => {
          console.log('errors test', errors)
          if (
            errors &&
            !errors?.name &&
            Object.getPrototypeOf(errors) === Object.prototype
          ) {
            submitMutation(
              id,
              containers,
              formik.values,
              setNameChangeLoader,
              layoutStatus,
              () => {
                setLayoutName(formik.values.name)
              }
            )
          }
        })
      }, 1000)
      return () => clearTimeout(timeOutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.name])

  const customRenderer = (content) => {
    const variablesValueLabelObject = replacements

    variablesValueLabelObject.forEach((item) => {
      const pattern = `{{var["${item.name}"]}}`
      content = content.replace(pattern, item.value)
    })
    return content
  }

  return (
    <Row>
      <Col
        style={{
          backgroundColor: 'white',
          padding: '15px',
        }}
        xs={6}
      >
        <Row>
          <Col>
            <Row>
              <Col>{t('CLICK ON THE AREA YOU WANT TO CREATE')}</Col>
            </Row>
            <Row justify='end' align='center' nogutter>
              <Col>
                <StyledInput
                  type='input'
                  name='name'
                  id='name'
                  maxLength={140}
                  disabled={contentUpdateLoader}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  onBlur={() => {
                    formik.setFieldTouched('name', true)
                  }}
                  label={
                    nameLabel || t('CHOOSE THE NAME FOR YOUR COMPANY LAYOUT')
                  }
                  feedbackMessage={formik.touched.name && formik.errors.name}
                  feedbackStyle='invalid'
                  setFieldValue={formik.setFieldValue}
                />
              </Col>
              {/* <Col xs='content'> */}
              {/*  {(formik.values.name !== layoutName && !nameChangeLoader) || */}
              {/*  (nameIsReq && */}
              {/*    formik.values.name === '' && */}
              {/*    !nameChangeLoader) ? ( */}
              {/*    <Icon */}
              {/*      icon='close' */}
              {/*      size='14px' */}
              {/*      color={portalTheme.color.errorColor} */}
              {/*    /> */}
              {/*  ) : nameChangeLoader ? ( */}
              {/*    <LoadingSpinner */}
              {/*      theme={{ */}
              {/*        components: { */}
              {/*          spinner: { */}
              {/*            /***/}
              {/*             * Has to be a { css } oject from styled-components */}
              {/*             */}
              {/*            fontSize: portalTheme.font.size.bodySmall, */}
              {/*            fontWeight: portalTheme.font.weight.regular, */}
              {/*            size: '14px', */}
              {/*          }, */}
              {/*        }, */}
              {/*      }} */}
              {/*      style={{ */}
              {/*        position: 'unset', */}
              {/*        backgroundColor: 'white', */}
              {/*      }} */}
              {/*    /> */}
              {/*  ) : ( */}
              {/*    <Icon */}
              {/*      icon='bullet-point' */}
              {/*      size='14px' */}
              {/*      color={portalTheme.color.successColor} */}
              {/*    /> */}
              {/*  )} */}
              {/* </Col> */}
            </Row>
            <Row>
              <Col xs='content'>
                <LetterLayout
                  externTranslateFunction={(value) => {
                    return t(layoutPreviewDummyTranslations(value))
                  }}
                  setActiveContainerKey={setActiveContainerKey}
                  adminEdit={adminEdit}
                  customRenderer={!adminEdit ? customRenderer : false}
                  formErrors={[containerFormErrors].flat()}
                  previewShow={previewShow}
                  previewWidth={450}
                  containers={containers}
                  activeContainer={activeContainer}
                  setActiveContainer={setActiveContainer}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row
              direction='column'
              justify='between'
              style={{ height: '100%' }}
            >
              <Col xs='content'>
                <Row>
                  <Col>
                    <Row nogutter justify='end'>
                      <Col
                        xs='content'
                        style={{
                          color:
                            layoutStatus === 'ACTIVE'
                              ? portalTheme.color.successColor
                              : portalTheme.color.warningColor,
                        }}
                      >
                        {companyLayoutStatusEnumList(layoutStatus)}
                      </Col>
                    </Row>
                    <Row justify='start'>
                      <Col xs='content' style={{ paddingTop: '8px' }}>
                        <span>{formik.values.name.length}/140</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {adminEdit && (
                  <>
                    <Row>
                      <Col
                        style={{
                          fontSize: '14px',
                          paddingTop: '15px',
                          borderTop: '1px solid black',
                        }}
                      >
                        {t('PRINT OPTIONS')}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CustomCheckboxRadio
                          type='radio'
                          name='doubleSided'
                          id='doubleSidedIdFalse'
                          onChange={() => {
                            setContainers({
                              ...containers,
                              printOptions: {
                                doubleSided: true,
                              },
                            })
                            formik.setFieldValue('doubleSided', true)
                          }}
                          isChecked={formik.values?.doubleSided === true}
                          disabled={false}
                          labelElement={t('DOUBLE SIDE')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <CustomCheckboxRadio
                          type='radio'
                          name='doubleSided'
                          id='doubleSidedIdTrue'
                          onChange={() => {
                            setContainers({
                              ...containers,
                              printOptions: {
                                doubleSided: false,
                              },
                            })
                            formik.setFieldValue('doubleSided', false)
                          }}
                          isChecked={formik.values?.doubleSided === false}
                          disabled={false}
                          labelElement={t('SINGLE SIDE')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <LinkButtonStyled
                          style={{ textAlign: 'center' }}
                          type='button'
                          onClick={() => {
                            setActiveSpecialContainer('footerHeaderSetting')
                          }}
                        >
                          {t('FROM PAGE 2')}
                        </LinkButtonStyled>
                      </Col>
                    </Row>
                  </>
                )}
                {!singleLetter && (
                  <Row
                    justify='start'
                    align='center'
                    nogutter
                    style={{ marginBottom: '10px' }}
                  >
                    {!hidePreview &&
                      !adminEdit &&
                      !contentUpdateLoader &&
                      !nameChangeLoader && (
                        <>
                          <Col style={{ marginRight: '5px' }}>
                            <SideButton
                              isActive={activeContainerKey === 'addressWindow'}
                              style={{ textAlign: 'center' }}
                              type='button'
                              onClick={() => {
                                console.log('preview')
                                setActiveContainerKey('addressWindow')
                                setActiveContainer(containers.addressWindow)
                              }}
                            >
                              {t('LETTER COMPONENTS AND VARIABLES')}
                            </SideButton>
                          </Col>
                        </>
                      )}
                  </Row>
                )}
                {!adminEdit && (
                  <>
                    <Row
                      nogutter
                      style={{ marginTop: '20px' }}
                      align='center'
                      justify='start'
                    >
                      <Col
                        style={{ fontSize: '14px', marginRight: '5px' }}
                        xs='content'
                      >
                        <span>{t('PAGE COUNT') + ': '}</span>
                      </Col>
                      <MiniBadgeCol xs='content'>{pageCount}</MiniBadgeCol>
                    </Row>
                  </>
                )}
              </Col>
              <Col xs='content'>
                {!singleLetter && (
                  <Row
                    justify='start'
                    align='center'
                    nogutter
                    style={{ marginBottom: '10px' }}
                  >
                    {!hidePreview &&
                      !adminEdit &&
                      !contentUpdateLoader &&
                      !nameChangeLoader && (
                        <>
                          <Col xs='content' style={{ marginRight: '5px' }}>
                            <LinkButtonStyled
                              style={{ textAlign: 'center' }}
                              type='button'
                              onClick={() => {
                                console.log('preview')
                                setActiveContainerKey('addressWindow')
                                setActiveContainer(containers.addressWindow)
                              }}
                            >
                              {t('RECIPIENTS') + ': '}
                            </LinkButtonStyled>
                          </Col>

                          <MiniBadgeCol xs='content'>
                            {
                              formik.values?.addressWindow?.letterRecipient
                                ?.recipients.length
                            }
                          </MiniBadgeCol>
                        </>
                      )}
                  </Row>
                )}

                {!singleLetter && !adminEdit && (
                  <Row
                    justify='start'
                    align='center'
                    nogutter
                    style={{ marginBottom: '10px' }}
                  >
                    {!hidePreview && !contentUpdateLoader && !nameChangeLoader && (
                      <>
                        <Col xs='content' style={{ marginRight: '5px' }}>
                          <LinkButtonStyled
                            style={{ textAlign: 'center' }}
                            type='button'
                            onClick={() => {
                              console.log('preview')
                              setActiveSpecialContainer('uploadAttachments')
                            }}
                          >
                            {t('ATTACHMENTS') + ': '}
                          </LinkButtonStyled>
                        </Col>

                        <MiniBadgeCol xs='content'>
                          {formik.values?.attachments.length}
                        </MiniBadgeCol>
                      </>
                    )}
                  </Row>
                )}

                <Row
                  justify='start'
                  align='center'
                  style={{ marginBottom: '10px' }}
                >
                  <Col xs='content'>
                    {!hidePreview && !contentUpdateLoader && !nameChangeLoader && (
                      <LinkButtonStyled
                        style={{ textAlign: 'center' }}
                        type='button'
                        onClick={() => {
                          console.log('preview')
                          triggerPreview()
                        }}
                      >
                        {t('PREVIEW')}
                      </LinkButtonStyled>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <ButtonSecondary
                      type='button'
                      onClick={() => {
                        if (overrideSendButtonMutation) {
                          overrideSendButtonMutation(id, formik)
                        } else {
                          formik.handleSubmit()
                        }
                      }}
                      disabled={
                        (!nameIsReq &&
                          formik.values.name.length > 0 &&
                          formik.values.name.length < 3) ||
                        (nameIsReq && formik.values.name.length < 3) ||
                        contentUpdateLoader ||
                        nameChangeLoader
                      }
                      style={{
                        margin: '0px',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        fontSize: '14px',
                        minWidth: '100%',
                      }}
                    >
                      {contentUpdateLoader
                        ? t('PLEASE WAIT')
                        : layoutStatus === 'ACTIVE'
                        ? saveButtonTextActive
                        : saveButtonTextDraft}
                    </ButtonSecondary>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col
            style={{
              backgroundColor: 'white',
              padding: '15px',
              marginLeft: '5px',
            }}
          >
            {showEditor()}
          </Col>
        </Row>
      </Col>

      <MessageNofi
        style={{ backgroundColor: '#DCF5FFFF', fontSize: '26px' }}
        isVisible={layoutChangeStatus?.open}
        closeMessageBox={() => {
          setLayoutChangeStatus({ ...layoutChangeStatus, open: false })
        }}
        statusColor={layoutChangeStatus?.status}
        updated={new Date()}
      >
        {layoutChangeStatus?.msg}
      </MessageNofi>
    </Row>
  )
}

const SideButton = styled(EmptyButton)`
  padding: 12px 4px;

  margin-top: 18px;
  border: 1px solid #83a8cb;
  border-radius: 5px;

  &:hover {
    background-color: #83a8cb;
  }

  ${(props) =>
    props.isActive &&
    css`
      background-color: #83a8cb;
    `}
`

const MiniBadgeCol = styled(Col)`
  background-color: #8aa8c8;
  padding-left: 6px !important;
  padding-right: 6px !important;
  color: #fff;
  border-radius: 30%;
`

export default LayoutPreview
