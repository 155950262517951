import React, { useState, forwardRef, useImperativeHandle } from 'react'

import useUpdateEffect from '../../../hooks/useUpdateEffect'

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [contractFilter, setContractFilter] = useState([])

  useUpdateEffect(() => {
    console.log('contractFilter update: ', contractFilter)
    filterChangedCallback()
  }, [contractFilter])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return contractFilter != null && contractFilter.length > 0
      },
      getModel() {
        if (contractFilter == null || contractFilter.length <= 0) {
          return null
        }

        const model = contractFilter
        // console.log('getModel', model)
        return model
      },
      setModel(model) {
        // console.log('setModel', model)
        setContractFilter(model)
      },
    }
  })

  return <p>AppointmentContractFilter</p>
})
