import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query SyncListGridQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        data: Sync(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          status
          comment
          uploaderName
          dataUpload
          type
          dataCount
          created
          files {
            id
            name
            type
          }
          contract {
            name
          }
        }
      }
    }
  }
`
