import { useState } from 'react'
import handleLocalStorage from '../helper/handleLocalStorage'

export default function useFilter(initialState = []) {
  const [filters, setFilters] = useState(initialState)

  const saveFilters = (f) => {
    setFilters(f)
  }

  const saveFilter = (f) => {
    const filterNotFound =
      filters.filter((i) => i.filterId === f.filterId).length === 0

    if (filterNotFound) {
      setFilters((prevState) => [...prevState, f])
    }
  }

  const removeFilter = (f) => {
    // TODO IMPROVE THIS
    handleLocalStorage('clear', 'appointmentsFilter')
    handleLocalStorage('clear', 'invoicesFilter')

    setFilters((prevState) =>
      prevState.filter((i) => i.filterId !== f.filterId)
    )
  }

  const clearFilters = () => {
    setFilters([])
  }

  return { filters, saveFilters, saveFilter, removeFilter, clearFilters }
}
