import { isArray } from 'leaflet/src/core/Util'

const tenantFullNameValueGetter = (props) => {
  const { value } = props

  if ((isArray(value) && value.length < 1) || !value) {
    return ''
  }

  let tenant
  if (isArray(value)) {
    tenant = value[0]
  } else {
    tenant = value
  }

  return tenant?.firstname + ' ' + tenant?.lastname
}

export default tenantFullNameValueGetter
