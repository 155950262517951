import styled from 'styled-components/macro'

import { Icon, portalTheme } from 'pyrexx-react-library'

export const ActionCenterRow = styled.div`
  background-color: #eff4f7;
  height: ${(props) =>
    props?.actionCenterHeight ? props.actionCenterHeight : '64'}px;
  ${(props) =>
    props.isFullScreen
      ? `
      padding: 0;
      margin: 0;
    `
      : `
      padding: 12px calc(((100vw - 100% - 1rem) / 2));
      margin: 0 calc(((100vw - 100% - 1rem) / 2) * -1);
    `}
`

export const CustomBubble = styled.div`
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #ffffff;
  color: #000000;
  font-size: 14px;
  line-height: normal;
  box-shadow: 5px 5px 13px -1px #a3a3a3;
  text-align: center;
  padding: 10px;
  border-radius: 0px;
  ${(props) =>
    props.isFullScreen
      ? `
  right: calc(100% + 12px);
  top: 0;
    `
      : `
  top: calc(-100% - 12px);
  left: -50%;
    `}
  &:after {
    ${(props) =>
      props.isFullScreen
        ? `
    content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: transparent #ffffff;
    border-width: 10px 0 10px 11px;
    top: 50%;
    right: -11px;
    margin-top: -10px;
    `
        : `
            content: '';
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: #ffffff transparent;
    border-width: 11px 10px 0;
    bottom: -11px;
    left: 69%;
    margin-left: -10px;
    `}
  }
`

export const Label = styled.div`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  display: flex;

  background-color: #fff;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  transition: width 0.2s ease-in-out;
  &:hover,
  &:focus {
    background-color: #dbe7f3;
  }

  ${(props) => props.isDropdownOpen && `background-color: #dbe7f3;`};

  ${(props) =>
    props.ownFilterChanged &&
    `border: 1px solid` + portalTheme.color.warningColor + `;`};

  // Styles from mockup
  //min-height: 50px;
  border-radius: 10px;
  font-size: 14px;
`

export const Dropdown = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 15px;
  width: 340px;
  align-content: center;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
`

export const MoreActionIcon = styled(Icon).attrs((props) => ({
  icon: 'more-action',
  size: '40px',
  color: '#4ab4e2',
}))`
  ${Label}:hover &, ${Label}:focus & {
    color: #979797;
  }
`

// Downloads Modal styles
export const DownloadModalContainer = styled.div`
  position: absolute;
  z-index: 1001; // higher than header's 1000 z-index
  isolation: isolate;
`

export const CheckboxesRow = styled.div`
  display: flex;
  justify-content: space-between;

  width: 50%;

  &:nth-child(3) {
    margin: 8px 0;
  }
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin: 8px 0;

  &:last-child {
    margin-bottom: 0;
  }
`
