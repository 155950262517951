/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type ContractsOverviewQueryVariables = {|
  companyId: string,
  dateFilter: $ReadOnlyArray<?Condition>,
  serviceFilter: $ReadOnlyArray<?Condition>,
  contractFilter: $ReadOnlyArray<?Condition>,
|};
export type ContractsOverviewQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +completed: ?number,
      +planned: ?number,
      +incompleted: ?number,
    |}
  |}
|};
export type ContractsOverviewQuery = {|
  variables: ContractsOverviewQueryVariables,
  response: ContractsOverviewQueryResponse,
|};
*/


/*
query ContractsOverviewQuery(
  $companyId: ID!
  $dateFilter: [Condition]!
  $serviceFilter: [Condition]!
  $contractFilter: [Condition]!
) {
  Me {
    Company(id: $companyId) {
      completed: countContracts(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["DONE_BUT_NOT_FINAL_BILLED", "FINAL_BILLED_NOT_EXPIRED_NO_MAINTENANCE"], type: equals}]}, {name: "date", filterType: date, operator: AND, conditions: $dateFilter}, {name: "serviceCategory.tableId", filterType: text, operator: AND, conditions: $serviceFilter}, {name: "contract", filterType: number, operator: AND, conditions: $contractFilter}]})
      planned: countContracts(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["ACTIVE", "UNDER_CONSTRUCTION"], type: equals}]}, {name: "date", filterType: date, operator: AND, conditions: $dateFilter}, {name: "serviceCategory.tableId", filterType: text, operator: AND, conditions: $serviceFilter}, {name: "contract", filterType: number, operator: AND, conditions: $contractFilter}]})
      incompleted: countContracts(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["DISCARDED", "READY_FOR_ACTIVATION", "TEMP_SUSPENDED", "EXPIRED_AND_FINAL_BILLED", "MERGED_INTO_OTHER_CONTRACT"], type: equals}]}, {name: "date", filterType: date, operator: AND, conditions: $dateFilter}, {name: "serviceCategory.tableId", filterType: text, operator: AND, conditions: $serviceFilter}, {name: "contract", filterType: number, operator: AND, conditions: $contractFilter}]})
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFilter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "serviceFilter"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v5 = {
  "kind": "Literal",
  "name": "operator",
  "value": "AND"
},
v6 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "conditions",
      "variableName": "dateFilter"
    },
    {
      "kind": "Literal",
      "name": "filterType",
      "value": "date"
    },
    {
      "kind": "Literal",
      "name": "name",
      "value": "date"
    },
    (v5/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters.1"
},
v7 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "conditions",
      "variableName": "serviceFilter"
    },
    {
      "kind": "Literal",
      "name": "filterType",
      "value": "text"
    },
    {
      "kind": "Literal",
      "name": "name",
      "value": "serviceCategory.tableId"
    },
    (v5/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters.2"
},
v8 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "conditions",
      "variableName": "contractFilter"
    },
    {
      "kind": "Literal",
      "name": "filterType",
      "value": "number"
    },
    {
      "kind": "Literal",
      "name": "name",
      "value": "contract"
    },
    (v5/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters.3"
},
v9 = {
  "alias": "completed",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "DONE_BUT_NOT_FINAL_BILLED",
                      "FINAL_BILLED_NOT_EXPIRED_NO_MAINTENANCE"
                    ]
                  }
                ],
                "filterType": "text",
                "name": "status",
                "operator": "AND"
              }
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countContracts",
  "storageKey": null
},
v10 = {
  "alias": "planned",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "ACTIVE",
                      "UNDER_CONSTRUCTION"
                    ]
                  }
                ],
                "filterType": "text",
                "name": "status",
                "operator": "AND"
              }
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countContracts",
  "storageKey": null
},
v11 = {
  "alias": "incompleted",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "DISCARDED",
                      "READY_FOR_ACTIVATION",
                      "TEMP_SUSPENDED",
                      "EXPIRED_AND_FINAL_BILLED",
                      "MERGED_INTO_OTHER_CONTRACT"
                    ]
                  }
                ],
                "filterType": "text",
                "name": "status",
                "operator": "AND"
              }
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countContracts",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractsOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContractsOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cdb69606c5a63c0eb48d8e71ee67e581",
    "id": null,
    "metadata": {},
    "name": "ContractsOverviewQuery",
    "operationKind": "query",
    "text": "query ContractsOverviewQuery(\n  $companyId: ID!\n  $dateFilter: [Condition]!\n  $serviceFilter: [Condition]!\n  $contractFilter: [Condition]!\n) {\n  Me {\n    Company(id: $companyId) {\n      completed: countContracts(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"DONE_BUT_NOT_FINAL_BILLED\", \"FINAL_BILLED_NOT_EXPIRED_NO_MAINTENANCE\"], type: equals}]}, {name: \"date\", filterType: date, operator: AND, conditions: $dateFilter}, {name: \"serviceCategory.tableId\", filterType: text, operator: AND, conditions: $serviceFilter}, {name: \"contract\", filterType: number, operator: AND, conditions: $contractFilter}]})\n      planned: countContracts(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"ACTIVE\", \"UNDER_CONSTRUCTION\"], type: equals}]}, {name: \"date\", filterType: date, operator: AND, conditions: $dateFilter}, {name: \"serviceCategory.tableId\", filterType: text, operator: AND, conditions: $serviceFilter}, {name: \"contract\", filterType: number, operator: AND, conditions: $contractFilter}]})\n      incompleted: countContracts(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"DISCARDED\", \"READY_FOR_ACTIVATION\", \"TEMP_SUSPENDED\", \"EXPIRED_AND_FINAL_BILLED\", \"MERGED_INTO_OTHER_CONTRACT\"], type: equals}]}, {name: \"date\", filterType: date, operator: AND, conditions: $dateFilter}, {name: \"serviceCategory.tableId\", filterType: text, operator: AND, conditions: $serviceFilter}, {name: \"contract\", filterType: number, operator: AND, conditions: $contractFilter}]})\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cdcf390d8911145d263fa74338ab7227';

module.exports = node;
