import handleLocalStorage from './handleLocalStorage'

const ACCESS_TOKEN_KEY = 'pom_api_token'
const REFRESH_TOKEN_KEY = 'pom_api_refresh_token'
const USER_NAME_KEY = 'userName'
const USER_ID_KEY = 'userId'
const USER_FIRSTNAME_KEY = 'userFirstName'
const USER_LASTNAME_KEY = 'userLastName'
const USER_SALUTATION_KEY = 'userSalutation'
const USER_TITLE_KEY = 'userTitle'
const USER_ROLE_KEY = 'userRole'
export const USER_EMAIL_KEY = 'userEmail'
const USER_PERMISSIONS_KEY = 'userPermissions'
const COMPANY_ID_KEY = 'companyId'
const COMPANY_NAME_KEY = 'companyName'
const COMPANY_LOGO_KEY = 'companyLogo'

// GET
const getAccessToken = () => {
  return handleLocalStorage('get', ACCESS_TOKEN_KEY, null, false)
}

const getRefreshToken = () => {
  return handleLocalStorage('get', REFRESH_TOKEN_KEY, null, false)
}

const getUserInfo = () => {
  return {
    id: handleLocalStorage('get', USER_ID_KEY, '', false),
    name: handleLocalStorage('get', USER_NAME_KEY, '', false),
    firstname: handleLocalStorage('get', USER_FIRSTNAME_KEY, '', false),
    lastname: handleLocalStorage('get', USER_LASTNAME_KEY, '', false),
    salutation: handleLocalStorage('get', USER_SALUTATION_KEY, '', false),
    title: handleLocalStorage('get', USER_TITLE_KEY, '', false),
    role: handleLocalStorage('get', USER_ROLE_KEY, '', false),
    email: handleLocalStorage('get', USER_EMAIL_KEY, '', false),
  }
}

const getUserPermissions = () => {
  return handleLocalStorage('get', USER_PERMISSIONS_KEY, '', false)
}

const getCompanyInfo = () => {
  return {
    id: handleLocalStorage('get', COMPANY_ID_KEY, '', false),
    name: handleLocalStorage('get', COMPANY_NAME_KEY, '', false),
    logo: handleLocalStorage('get', COMPANY_LOGO_KEY, '', false),
  }
}

// SET
export const setToken = (accessToken, refreshToken) => {
  handleLocalStorage('set', ACCESS_TOKEN_KEY, accessToken)
  handleLocalStorage('set', REFRESH_TOKEN_KEY, refreshToken)
}
const setUserInfo = ({
  userId,
  userName,
  firstname,
  lastname,
  salutation,
  title,
  role,
  email,
}) => {
  handleLocalStorage('set', USER_ID_KEY, userId)
  handleLocalStorage('set', USER_NAME_KEY, userName)
  handleLocalStorage('set', USER_FIRSTNAME_KEY, firstname)
  handleLocalStorage('set', USER_LASTNAME_KEY, lastname)
  handleLocalStorage('set', USER_SALUTATION_KEY, salutation)
  handleLocalStorage('set', USER_TITLE_KEY, title)
  handleLocalStorage('set', USER_ROLE_KEY, role)
  handleLocalStorage('set', USER_EMAIL_KEY, email)
}

const setUserPermissions = (permissions = []) => {
  handleLocalStorage('set', USER_PERMISSIONS_KEY, permissions)
}

const setCompanyInfo = ({
  companyId = '',
  companyName = '',
  companyLogo = '',
}) => {
  handleLocalStorage('set', COMPANY_ID_KEY, companyId)
  handleLocalStorage('set', COMPANY_NAME_KEY, companyName)
  handleLocalStorage('set', COMPANY_LOGO_KEY, companyLogo)
}

// CLEAR
const clearTokens = () => {
  handleLocalStorage('clear', ACCESS_TOKEN_KEY)
  handleLocalStorage('clear', REFRESH_TOKEN_KEY)
  handleLocalStorage('clear', USER_ID_KEY)
  handleLocalStorage('clear', USER_NAME_KEY)
  handleLocalStorage('clear', USER_FIRSTNAME_KEY)
  handleLocalStorage('clear', USER_LASTNAME_KEY)
  handleLocalStorage('clear', USER_SALUTATION_KEY)
  handleLocalStorage('clear', USER_TITLE_KEY)
  handleLocalStorage('clear', USER_ROLE_KEY)
  handleLocalStorage('clear', USER_EMAIL_KEY)
  handleLocalStorage('clear', USER_PERMISSIONS_KEY)
  handleLocalStorage('clear', COMPANY_ID_KEY)
  handleLocalStorage('clear', COMPANY_NAME_KEY)
  handleLocalStorage('clear', COMPANY_LOGO_KEY)
  handleLocalStorage('clear', 'multipleCompanies')
}

const clearLogin = () => {
  handleLocalStorage('clear', ACCESS_TOKEN_KEY)
  handleLocalStorage('clear', REFRESH_TOKEN_KEY)
  handleLocalStorage('clear', USER_ID_KEY)
  handleLocalStorage('clear', USER_NAME_KEY)
  handleLocalStorage('clear', USER_FIRSTNAME_KEY)
  handleLocalStorage('clear', USER_LASTNAME_KEY)
  handleLocalStorage('clear', USER_SALUTATION_KEY)
  handleLocalStorage('clear', USER_TITLE_KEY)
  handleLocalStorage('clear', USER_ROLE_KEY)
  handleLocalStorage('clear', USER_EMAIL_KEY)
  handleLocalStorage('clear', USER_PERMISSIONS_KEY)
}

export default {
  getAccessToken,
  getRefreshToken,
  setToken,
  setUserInfo,
  getUserInfo,
  setCompanyInfo,
  getCompanyInfo,
  clearTokens,
  clearLogin,
  getUserPermissions,
  setUserPermissions,
}
