import React from 'react'
import styled from 'styled-components/macro'

import { Icon } from 'pyrexx-react-library'
import EmptyButton from '../../../../components/Simple/EmptyButton'

export const StyledButton = styled(EmptyButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  padding: 16px;
  width: 100%;

  background-color: #fff;

  & > * {
    margin: 0;
  }
`

export const StyledPanel = styled.div`
  margin: 4px 0 0 24px;
`

export const GridContainer = styled.div`
  position: relative;

  width: 100%;
`

const StyledIcon = styled(Icon).attrs(() => ({
  size: 40,
}))`
  color: var(--pyrexx-link);
  flex-shrink: 0;
`

export const ButtonIcon = ({ open = false }) => {
  return open ? <StyledIcon icon='hide' /> : <StyledIcon icon='expand' />
}
