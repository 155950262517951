import React, { useContext } from 'react'

import SimpleLink from '../../../components/Simple/SimpleLink'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'

const UsageUnitLinkRenderer = (props) => {
  const { value, data } = props

  const { currentLanguage } = useContext(UserContext)
  return (
    <SimpleLink
      to={'/' + currentLanguage + Routes.usageUnit + '/' + data?.tableId}
      style={{ fontSize: 'inherit' }}
    >
      {value}
    </SimpleLink>
  )
}

export default UsageUnitLinkRenderer
