import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { ButtonPrimary } from 'pyrexx-react-library'
import PageContainer from '../../components/PageContainer'
import Spacer from '../../components/Spacer'

import { useUserContext } from '../../helper/userContext'
import { Routes } from '../../configs/RouteConfig'
import { Box, BoxInner, CheckIcon } from './styles'
import { CenterRow } from '../../styles/HelperStylesComponents.styles'

const RequestPassword = () => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <PageContainer>
      <Box>
        <BoxInner>
          <h3>{t('YOUR ACCESS WILL BE RESTORED SOON')}</h3>
          <Spacer />
          <CenterRow>
            <CheckIcon icon='bullet-point' size='30' />
            <p>{t('WE JUST SENT A NOTIFICATION TO YOUR ADMINISTRATOR.')}</p>
          </CenterRow>
          <Spacer />
          <ButtonPrimary
            onClick={() => history.push('/' + currentLanguage + Routes.login)}
          >
            {t('BACK TO HOMEPAGE')}
          </ButtonPrimary>
          <Spacer />
        </BoxInner>
      </Box>
    </PageContainer>
  )
}

export default RequestPassword
