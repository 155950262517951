import React from 'react'
import PropTypes from 'prop-types'
import { AgGridColumn } from 'ag-grid-react'
import { useTranslation } from 'react-i18next'

import BaseGrid from './BaseGrid'

const ContractsGrid = ({
  rowData = [],
  currentData = [],
  selectedData = [],
  handleSelectionChange = () => {},
}) => {
  const { t } = useTranslation()

  return (
    <BaseGrid
      id='contracts'
      title={t('LIMIT TO SPECIFIC CONTRACTS')}
      rowData={rowData}
      currentData={currentData}
      selectedData={selectedData}
      handleSelectionChange={handleSelectionChange}
    >
      <AgGridColumn
        headerName={t('PYREXX CONTRACT ID')}
        field='tableId'
        flex='1'
        // valueFormatter={({ value }) => 'v_' + value}
      />
      <AgGridColumn headerName={t('CONTRACT NAME')} field='name' flex='2' />
    </BaseGrid>
  )
}

ContractsGrid.propTypes = {
  currentData: PropTypes.array,
  handleSelectionChange: PropTypes.func,
  rowData: PropTypes.array,
  selectedData: PropTypes.array,
}

export default ContractsGrid
