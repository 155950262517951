import React from 'react'
import Skeleton from '../Skeleton'

const WithSkeleton = ({ children, loading, ...restOfProps }) => {
  return loading ? (
    <Skeleton
      style={{ paddingTop: '16px', paddingBottom: '16px' }}
      {...restOfProps}
    />
  ) : (
    children
  )
}

export default WithSkeleton
