import React from 'react'
import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'
import { useTranslation } from 'react-i18next'
import { operationsStatusValueGetter } from '../valueGetter'
import { OperationsStatusMap } from '../../../constants/colorMap'

const OperationsStatusRenderer = (props) => {
  const { value } = props
  const { t } = useTranslation()

  return (
    <StatusLightWithLabel
      status={OperationsStatusMap[value]}
      label={t(operationsStatusValueGetter(props))}
    />
  )
}

export default OperationsStatusRenderer
