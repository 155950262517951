/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteCompanyLayoutMutationVariables = {|
  companyId: string,
  companyLayoutId: number,
|};
export type DeleteCompanyLayoutMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +deleteCompanyLayout: ?boolean
      |}
    |}
  |}
|};
export type DeleteCompanyLayoutMutation = {|
  variables: DeleteCompanyLayoutMutationVariables,
  response: DeleteCompanyLayoutMutationResponse,
|};
*/


/*
mutation DeleteCompanyLayoutMutation(
  $companyId: ID!
  $companyLayoutId: Int!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        deleteCompanyLayout(companyLayoutId: $companyLayoutId)
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyLayoutId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessPostMutation",
            "kind": "LinkedField",
            "name": "businessPost",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "companyLayoutId",
                    "variableName": "companyLayoutId"
                  }
                ],
                "kind": "ScalarField",
                "name": "deleteCompanyLayout",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteCompanyLayoutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteCompanyLayoutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d59e316133a32cf43659a7c5d281e99b",
    "id": null,
    "metadata": {},
    "name": "DeleteCompanyLayoutMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteCompanyLayoutMutation(\n  $companyId: ID!\n  $companyLayoutId: Int!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        deleteCompanyLayout(companyLayoutId: $companyLayoutId)\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a618c4c7222184df7bedad379894f3c0';

module.exports = node;
