function dummyTranslate(text) {
  return text
}

const companyLayoutStatusEnumList = (value) => {
  switch (value) {
    case 'DRAFT':
      return dummyTranslate('DRAFT')
    case 'ACTIVE': // this is not implemented in the enum list is just for special uses
      return dummyTranslate('PUBLISHED')
    case 'NONE':
    default:
      return ''
  }
}

export default companyLayoutStatusEnumList
