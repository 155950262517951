import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { AgGridReact } from 'ag-grid-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { agGridLocales } from './locales'

import AddColumnNotification from '../../components/Notifications/AddColumnNotification'
import NoRowsOverlay from './NoRowsOverlay'

import '../../styles/ag-grid.css'
import '../../styles/ag-theme-pyrexx.css'

// Default styles for testing
// import 'ag-grid-community/dist/styles/ag-grid.css'
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css'

const gridHeightNoRows = '250px'
const defaultGridHeight = '600px'

const GridContainer = styled.div`
  position: relative;

  height: 600px;
  height: ${(props) => props.gridHeight};
  width: 100%;
`

const AgGrid = (props) => {
  const {
    onGridReady,
    gridOptions,
    handleSelectionChanged = (selectedRows) => {},
    headerHeight,
    getRowHeight,
    onColumnResized,
    ...restOfProps
  } = props
  const { t } = useTranslation(['translation', 'agGridLocale'])

  const [gridHeight, setGridHeight] = useState(defaultGridHeight)

  const createGridOption = useCallback(() => {
    return {
      ...gridOptions,
      getRowId: (params) => {
        return params.data.id
      },
      getLocaleText: (params) => {
        return t(agGridLocales[params.key])
      },
    }
  }, [gridOptions, t])

  // Handle selection changed
  const onSelectionChanged = (params) => {
    const selectedRows = params.api.getSelectedRows()
    handleSelectionChanged(selectedRows)
  }

  // Handle add column
  const [columnAdded, setColumnAdded] = useState(false)

  const showNewColumnNotification = () => {
    setColumnAdded(true)
  }

  const hideNewColumnNotification = () => {
    setColumnAdded(false)
  }

  const onColumnVisible = (params) => {
    params.api.resetRowHeights()

    const column = params.columns[params.columns.length - 1]
    const { colId, visible } = column
    const newColumnNotInViewport = params.columnApi
      .getAllDisplayedVirtualColumns()
      .every((column) => column.colId !== colId)
    const condition = visible && newColumnNotInViewport

    if (condition) {
      // Restarts the timeout
      if (columnAdded) {
        hideNewColumnNotification()
      }

      showNewColumnNotification()
    }
  }

  // Hides notification after at timeout
  useEffect(() => {
    if (columnAdded) {
      const timer = setTimeout(hideNewColumnNotification, 2000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [columnAdded])

  const onToolPanelVisibleChanged = (params) => {
    if (!params.api.isToolPanelShowing()) {
      params.api.sizeColumnsToFit()
    }
  }

  const onModelUpdated = (params) => {
    const emptyRows = params.api.getRenderedNodes().length === 0
    if (emptyRows) {
      setGridHeight(gridHeightNoRows)
      params.api.showNoRowsOverlay()
    } else {
      setGridHeight(defaultGridHeight)
      params.api.hideOverlay()
    }
  }

  // TODO add sticky header
  // Handle sticky grid header
  // const stickyRef = useStickyHeader()

  return (
    <GridContainer
      className='ag-theme-pyrexx'
      gridHeight={gridHeight}
      {...restOfProps}
    >
      <AddColumnNotification
        show={columnAdded}
        message={t('NEW COLUMN ADDED')}
      />

      <AgGridReact
        gridOptions={createGridOption()}
        onGridReady={onGridReady}
        onSelectionChanged={onSelectionChanged}
        onColumnVisible={onColumnVisible}
        onToolPanelVisibleChanged={onToolPanelVisibleChanged}
        headerHeight={headerHeight}
        getRowHeight={getRowHeight}
        popupParent={document.querySelector('body')} // https://www.ag-grid.com/react-grid/context-menu/#popup-parent
        noRowsOverlayComponent={NoRowsOverlay} // https://www.ag-grid.com/react-grid/component-overlay/
        onModelUpdated={onModelUpdated}
        onColumnResized={onColumnResized}
      />
    </GridContainer>
  )
}

AgGrid.propTypes = {
  onGridReady: PropTypes.any.isRequired,
  gridOptions: PropTypes.object.isRequired,
  handleSelectionChanged: PropTypes.func,
  headerHeight: PropTypes.number,
  getRowHeight: PropTypes.func,
}

export default AgGrid
