import formatDateLocale from './formatDateLocale'
import { serviceCategoryEnumList } from '../components/AgGrid/enumLists'

export const getDateFilterModel = (
  d,
  t,
  customDateFilterKey = 'date',
  currentLanguage = 'de'
) => {
  const tableId = d?.tableId.toString() || ''
  const from = d?.from || ''
  const to = d?.to || ''

  const formattedFrom = formatDateLocale(currentLanguage, from, 'P')
  const formattedTo = formatDateLocale(currentLanguage, to, 'P')

  const label = formattedFrom + ' - ' + formattedTo
  const value = [from, to]

  // Api compatible filter condition
  const apiFilterConditions =
    value.length > 0
      ? [
          {
            value,
            type: 'inRange',
          },
        ]
      : []

  // AgGrid compatible filter mdoel
  const filterModel = {
    [customDateFilterKey]: {
      filterType: 'date',
      type: 'inRange',
      dateFrom: from,
      dateTo: to,
    },
  }

  return {
    filterId: 'date',
    tableId,
    label,
    value,
    apiFilterModel: {
      dateFilter: apiFilterConditions,
    },
    filterModel,
  }
}

export const getServiceFilterModel = (
  d,
  t,
  customServiceFilterKey = 'contract.serviceCategory.tableId'
) => {
  const tableId = d?.tableId.toString() || ''
  const label = t(serviceCategoryEnumList(d?.name)) || ''

  const value = tableId

  const apiFilterConditions = [
    {
      value,
      type: 'equals',
    },
  ]

  const filterModel = {
    [customServiceFilterKey]: {
      filterType: 'text',
      operator: 'AND',
      condition1: {
        filterType: 'text',
        type: 'equals',
        filter: value,
      },
    },
  }

  return {
    filterId: 'service',
    tableId,
    label,
    value,
    apiFilterModel: {
      serviceFilter: apiFilterConditions,
    },
    filterModel,
  }
}

export const getContractFilterModel = (
  d,
  t,
  customContractFilterKey = 'contract'
) => {
  const tableId = d?.tableId.toString() || ''
  const combinedIds = d?.combinedIds || []
  const label = d?.name || ''

  const value =
    combinedIds.length > 0 ? combinedIds.map((id) => id.toString()) : tableId

  const apiFilterConditions = [
    {
      value,
      type: 'equals',
    },
  ]

  const filterModel = {
    [customContractFilterKey]: {
      filterType: 'text',
      operator: 'AND',
      condition1: {
        filterType: 'text',
        type: 'equals',
        filter: value,
      },
    },
  }

  return {
    filterId: 'contract',
    tableId,
    label,
    value,
    apiFilterModel: {
      contractFilter: apiFilterConditions,
    },
    filterModel,
  }
}
