/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PermissionType = "ADMIN" | "APPOINTMENT" | "CONTRACT" | "DATA_UPDATE" | "INVENTORY" | "INVOICE" | "NONE" | "POST_LETTER" | "POST_VIEW_ALL" | "QUOTA" | "%future added value";
export type CompanyDataQueryVariables = {|
  companyId: string
|};
export type CompanyDataQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +id: string,
      +permissions: ?$ReadOnlyArray<?PermissionType>,
      +logo: ?string,
    |}
  |}
|};
export type CompanyDataQuery = {|
  variables: CompanyDataQueryVariables,
  response: CompanyDataQueryResponse,
|};
*/


/*
query CompanyDataQuery(
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      id
      permissions
      logo
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "companyId"
    }
  ],
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "Company",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a40f79e1a763a627871053700f6668d",
    "id": null,
    "metadata": {},
    "name": "CompanyDataQuery",
    "operationKind": "query",
    "text": "query CompanyDataQuery(\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      id\n      permissions\n      logo\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b038adad5c525cf889688dcae380daff';

module.exports = node;
