import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-grid-system'
import {
  Icon,
  MessageNofi,
  portalTheme,
  SimpleNote,
} from 'pyrexx-react-library'
import { useTranslation } from 'react-i18next'
import FileList from './FileList/FileList'
import { LinkButtonStyled } from '../../styles/HelperStylesComponents.styles'

const StyledDropArea = styled(Col)`
  background-color: #e9edee;
  height: 120px;
  border-radius: 19px;
  z-index: 1;
`

const StyledSelectFile = styled(Col)`
  height: 120px;
  width: 100%;
  border-radius: 19px;

  &:hover,
  &:visited,
  &:focus {
    background-color: ${portalTheme.color.basicColorLightGrey2};
    cursor: pointer;
    outline: 0;
    text-decoration: none;
  }
`

const StyledDropAreaText = styled.span`
  position: absolute;
  left: 2rem;
  top: 2rem;

  font-family: ${portalTheme.font.family.quicksand};
  font-weight: 500;
  font-size: 14px;
  color: #d8d8d8;
`

const StyledIcon = styled(Icon)`
  color: #d8d8d8;
`

const FileUpload = (props) => {
  const {
    uploadStatusMsg,
    setUploadStatusMsg,
    name,
    files,
    onUpload,
    multiFileMode = true,
    supportedFileTypes = '',
    formik = false,
    setFieldValue = (name, files) => {},
  } = props
  const { t } = useTranslation()

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: multiFileMode,
    accept: supportedFileTypes,
    onDrop: (acceptedFiles) => {
      const checkedFiles = []
      acceptedFiles.forEach((singleNewFile) => {
        if (
          !files.find(
            (oldFiles) =>
              oldFiles.path + oldFiles.lastModified + singleNewFile.size ===
              singleNewFile.path +
                singleNewFile.lastModified +
                singleNewFile.size
          )
        ) {
          checkedFiles.push(singleNewFile)
        }
      })
      const newUpload = [...files, ...checkedFiles]
      onUpload(name, newUpload)
      setFieldValue(name, newUpload)
    },
  })

  const removeFile = useCallback(
    (fileArrayPosition) => {
      const newFiles = [...files]
      newFiles.splice(fileArrayPosition, 1)
      onUpload(name, newFiles)
      setFieldValue(name, newFiles)
    },
    [files, name, onUpload, setFieldValue]
  )

  const moveListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = files[dragIndex]
      const hoverItem = files[hoverIndex]
      // Swap places of dragItem and hoverItem in the pets array
      const updatedFiles = [...files]
      updatedFiles[dragIndex] = hoverItem
      updatedFiles[hoverIndex] = dragItem
      setFieldValue(name, updatedFiles)
    },
    [files, name, setFieldValue]
  )

  const generatefiles = useCallback(() => {
    return (
      <FileList
        files={files}
        removeFile={removeFile}
        moveListItem={moveListItem}
      />
    )
  }, [files, moveListItem, removeFile])

  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <Container fluid style={{ position: 'relative' }}>
          <MessageNofi
            style={{ backgroundColor: '#DCF5FFFF', fontSize: '26px' }}
            isVisible={uploadStatusMsg?.open}
            closeMessageBox={() => {
              setUploadStatusMsg({ ...uploadStatusMsg, open: false })
            }}
            statusColor={uploadStatusMsg?.status}
            updated={new Date()}
          >
            {uploadStatusMsg?.msg}
          </MessageNofi>
          {formik?.touched?.fileUpload && formik?.errors?.fileUpload && (
            <Row justify='center'>
              <Col
                xs={11}
                style={{
                  paddingTop: '1rem',
                  paddingBottom: '1rem',
                }}
              >
                <SimpleNote
                  noteStatus='error'
                  text={formik?.errors?.fileUpload}
                />
              </Col>
            </Row>
          )}
          <Row
            justify='between'
            style={{ border: '1px dotted #E9EDEE', borderRadius: '19px' }}
          >
            <StyledDropArea>
              <input {...getInputProps()} />
              <StyledDropAreaText>Drag & Drop</StyledDropAreaText>
              <Row
                justify='center'
                align='center'
                style={{
                  height: '100%',
                }}
              >
                <Col xs='content'>
                  <StyledIcon
                    icon='drag-and-drop'
                    size='60px'
                    color={portalTheme.color.linkColorPrimary}
                  />
                </Col>
              </Row>
            </StyledDropArea>
            <Col xs='content' style={{ width: '5px' }} />
            <StyledSelectFile>
              <LinkButtonStyled
                type='button'
                onClick={open}
                style={{
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                {t('SELECT FILE')}
              </LinkButtonStyled>
            </StyledSelectFile>
          </Row>
          <Row justify='start' nogutter>
            <Col
              style={{
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              {files.length > 0 && generatefiles()}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

FileUpload.FileUpload = {
  name: PropTypes.string.isRequired,
  maxsizeKb: PropTypes.number.isRequired,
  onUpload: PropTypes.func.isRequired,
  supportedFileTypes: PropTypes.string,
  /**
   * Can be used for stories
   */
  files: PropTypes.arrayOf(PropTypes.object),
}
export default FileUpload
