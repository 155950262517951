import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import graphql from 'babel-plugin-relay/macro'
import { ErrorBoundary } from 'react-error-boundary'

import { ServiceCard } from 'pyrexx-react-library'
import StatusFilterButton from '../Status/StatusFilterButton'
import WithSkeleton from './WithSkeleton'
import SimpleLink from '../Simple/SimpleLink'
import QueryRendererContainer from '../QueryRendererContainer'
import NotAvailableContent from './NotAvailableContent'

import { Routes } from '../../configs/RouteConfig'
import UserContext from '../../helper/userContext'
import handleLocalStorage from '../../helper/handleLocalStorage'
import { filterOpen, filterReminded } from '../../constants/invoiceFilterModels'

import invoiceImg from '../../assets/overview/invoice.png'
import invoiceInactiveImg from '../../assets/overview/invoice-inactive.png'
import getDefaultDateFilter from '../../helper/getDefaultDateFilter'

const InvoicesCardContent = (props) => {
  const { data = {}, loading = false } = props

  const { redirectRoute } = useContext(UserContext)
  const { t } = useTranslation()

  const handleFilterChange = (filter) => {
    handleLocalStorage('set', 'invoicesFilter', filter)
    redirectRoute(Routes.invoices)
  }

  return (
    <Row align='start' style={{ width: '100%' }}>
      <Col align='center'>
        <WithSkeleton variant='lg' loading={loading}>
          <p style={{ marginTop: '0' }}>{t('OPEN')}</p>
        </WithSkeleton>

        <WithSkeleton loading={loading}>
          <Row justify='center'>
            <StatusFilterButton
              onClick={() =>
                handleFilterChange({
                  filterId: 'box-open',
                  filterModel: filterOpen,
                })
              }
              status='warning'
              label={data?.open}
              border={false}
            />
          </Row>
        </WithSkeleton>
      </Col>

      <Col align='center'>
        <WithSkeleton variant='lg' loading={loading}>
          <p style={{ marginTop: '0' }}>{t('DUNNING LETTER')}</p>
        </WithSkeleton>

        <WithSkeleton loading={loading}>
          <Row justify='center'>
            <StatusFilterButton
              onClick={() =>
                handleFilterChange({
                  filterId: 'box-reminded',
                  filterModel: filterReminded,
                })
              }
              status='error'
              label={data?.reminded}
              border={false}
            />
          </Row>
        </WithSkeleton>
      </Col>
    </Row>
  )
}

export const InvoicesCard = ({ data = {}, loading = false, error = false }) => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()

  if (error) {
    return (
      <ServiceCard
        imgSrc={invoiceInactiveImg}
        title={t('INVOICES')}
        content={<NotAvailableContent imgSrc={invoiceInactiveImg} />}
        isDisabled
      />
    )
  }

  return (
    <ServiceCard
      imgSrc={invoiceImg}
      title={t('INVOICES')}
      content={<InvoicesCardContent data={data} loading={loading} />}
      link={
        !loading && (
          <SimpleLink
            to={'/' + currentLanguage + Routes.invoices}
            font='quicksand'
          >
            {t('ALL') + ' >'}
          </SimpleLink>
        )
      }
    />
  )
}

InvoicesCard.propTypes = {
  data: PropTypes.shape({
    open: PropTypes.any,
    reminded: PropTypes.any,
  }),
  loading: PropTypes.bool,
  error: PropTypes.bool,
}

const query = graphql`
  query InvoicesCardQuery($companyId: ID!, $dateFilter: [Condition]!) {
    Me {
      invoicesStatus: Company(id: $companyId) {
        open: countInvoices(
          options: {
            filters: [
              {
                name: "status"
                filterType: number
                operator: AND
                conditions: [{ value: ["OPEN"], type: equals }]
              }
              {
                name: "invoiceDate"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
            ]
          }
        )
        reminded: countInvoices(
          options: {
            filters: [
              {
                name: "status"
                filterType: number
                operator: AND
                conditions: [{ value: ["REMINDED"], type: equals }]
              }
              {
                name: "invoiceDate"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
            ]
          }
        )
      }
    }
  }
`

export default ({ isHidden = true }) => {
  const { user } = useContext(UserContext)

  const defaultDateFilter = getDefaultDateFilter().value

  const variables = {
    companyId: user.companyId,
    dateFilter: [{ value: defaultDateFilter, type: 'inRange' }],
  }

  if (isHidden) {
    return null
  }

  return (
    <ErrorBoundary fallbackRender={() => null}>
      <QueryRendererContainer
        query={query}
        variables={variables}
        loading={<InvoicesCard loading />}
        render={(data) => {
          const invoicesStatus = data?.Me?.invoicesStatus

          return <InvoicesCard data={invoicesStatus} />
        }}
      />
    </ErrorBoundary>
  )
}
