/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LetterLayoutQueryVariables = {|
  companyId: string,
  letterLayoutId: string,
|};
export type LetterLayoutQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +letterLayout: ?{|
          +id: ?string,
          +tableId: ?number,
          +name: ?string,
          +layout: ?string,
        |}
      |}
    |}
  |}
|};
export type LetterLayoutQuery = {|
  variables: LetterLayoutQueryVariables,
  response: LetterLayoutQueryResponse,
|};
*/


/*
query LetterLayoutQuery(
  $companyId: ID!
  $letterLayoutId: ID!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        letterLayout(id: $letterLayoutId) {
          id
          tableId
          name
          layout
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "letterLayoutId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessPost",
  "kind": "LinkedField",
  "name": "businessPost",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "letterLayoutId"
        }
      ],
      "concreteType": "LetterLayout",
      "kind": "LinkedField",
      "name": "letterLayout",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tableId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "layout",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LetterLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LetterLayoutQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9213b31853f528891ecbabad2606d7b",
    "id": null,
    "metadata": {},
    "name": "LetterLayoutQuery",
    "operationKind": "query",
    "text": "query LetterLayoutQuery(\n  $companyId: ID!\n  $letterLayoutId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        letterLayout(id: $letterLayoutId) {\n          id\n          tableId\n          name\n          layout\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dda43210d7e9811edb5097d7cf69b054';

module.exports = node;
