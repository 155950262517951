function dummyTranslate(text) {
  return text
}

const serviceCategoryEnumList = (value) => {
  switch (value) {
    case 'Trinkwasseruntersuchung':
    case 'DWA':
      return dummyTranslate('DRINKING WATER ANALYSIS')

    case 'Dienstleistung Rauchwarnmelder':
    case 'SMOKE_DETECTOR':
      return dummyTranslate('SMOKE DETECTOR')

    case 'Oberflächenreinigung':
    case 'GRAFFITI_REMOVAL':
      return dummyTranslate('SURFACE CLEANING')

    case 'Messdienstleistung (Wärme/Wasser)':
    case 'MEASUREMENT_SERVICES':
      return dummyTranslate('METERING SERVICES')

    case 'Stromzähler':
    case 'ELECTRICITY_METER_READING':
      return dummyTranslate('METERING SERVICES')

    case 'ASP':
      return dummyTranslate('ASP')

    case 'Verkehrssicherung':
      return dummyTranslate('SAFETY REGULATION')

    default:
      return ''
  }
}

export default serviceCategoryEnumList
