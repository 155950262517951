import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { StyledInput } from 'pyrexx-react-library'
import FilterCheckbox from './FilterCheckbox'

import useFilterCheckbox from '../../hooks/useFilterCheckbox'
import useUpdateEffect from '../../hooks/useUpdateEffect'

const Wrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  flex-flow: column wrap;
  max-width: 350px;
`

const GridEnumFilter = (props) => {
  const {
    map = {},
    colorMap = {},
    translateLabel = (label, t) => label,
    setFilterModel = (fm) => {},
  } = props
  const { t } = useTranslation()

  // TODO CHECK WORKING ON THIS
  // const { uiFilters, boxFilters } = usePyrexxOverview()
  // console.log('GridEnumFilter', uiFilters, boxFilters)

  const filterList = Object.keys(map)
  const initialState = {}
  filterList.forEach((key) => {
    initialState[key] = false
  })

  const [allFilter, setAllFilter] = useState(false)
  const [searchFilter, setSearchFilter] = useState('')
  const [uiFilter, toggleCheckbox, setAllCheckboxes] =
    useFilterCheckbox(initialState)

  const toggleAllFilter = useCallback(() => {
    setAllFilter((prev) => {
      setAllCheckboxes(!prev)
      return !prev
    })
  }, [setAllCheckboxes])

  useUpdateEffect(() => {
    if (Object.keys(uiFilter).length === getCurrentCheckedFilterLengths()) {
      setAllFilter(true)
    }
    if (getCurrentCheckedFilterLengths() === 0) {
      setAllFilter(false)
    }
  }, [uiFilter])

  useUpdateEffect(() => {
    const fm = {
      operator: 'OR',
      filterType: 'text',
    }

    let n = 1

    filterList.forEach((filterItem) => {
      if (uiFilter[filterItem]) {
        if (Array.isArray(map[filterItem])) {
          map[filterItem].forEach((mapItem) => {
            fm['condition' + n] = {
              filterType: 'text',
              type: 'equals',
              filter: mapItem,
            }
            n++
          })
        } else {
          fm['condition' + n] = {
            filterType: 'text',
            type: 'equals',
            filter: map[filterItem],
          }
          n++
        }
      }
    })

    if (fm?.condition1) {
      setFilterModel(fm)
    } else {
      setFilterModel({})
    }
  }, [uiFilter])

  const themeInput = {
    components: {
      styledInput: {
        span: {
          fontSize: '10px !important',
        },
        fontSize: '16px',
      },
    },
  }

  const getCurrentCheckedFilterLengths = useCallback(() => {
    return Object.keys(uiFilter).filter((itemKey) => uiFilter[itemKey]).length
  }, [uiFilter])

  const generateSelectAllBox = useCallback(() => {
    if (
      Object.keys(uiFilter).length === getCurrentCheckedFilterLengths() ||
      getCurrentCheckedFilterLengths() === 0
    ) {
      return (
        <FilterCheckbox
          id='filter-checkbox_all'
          label={t('SELECT ALL')}
          checked={allFilter}
          onChange={toggleAllFilter}
        />
      )
    } else {
      return (
        <FilterCheckbox
          id='filter-checkbox_all'
          label={t('SELECT ALL')}
          checked
          overrideActiveIcon='hide'
          overrideDeactivatedIcon='hide'
          onChange={() => {
            setAllCheckboxes(false)
          }}
        />
      )
    }
  }, [
    allFilter,
    getCurrentCheckedFilterLengths,
    setAllCheckboxes,
    t,
    toggleAllFilter,
    uiFilter,
  ])

  return (
    <Wrapper>
      <StyledInput
        theme={themeInput}
        type='input'
        name='searchFilter'
        id='searchFilter'
        onChange={(event) => {
          setSearchFilter(event.target.value)
        }}
        value={searchFilter}
        label={t('SEARCH')}
        feedbackStyle='invalid'
        setFieldValue={() => {}}
      />
      {generateSelectAllBox()}
      {filterList
        .filter((item) => {
          if (!searchFilter) {
            return true
          }
          const label = translateLabel(item, t)
          const regexCheck = new RegExp(searchFilter.toLowerCase())
          return regexCheck.test(label.toLowerCase())
        })
        .map((filterItem) => (
          <FilterCheckbox
            key={filterItem}
            id={'filter-checkbox_' + filterItem.toLowerCase()}
            label={translateLabel(filterItem, t)}
            status={colorMap[filterItem]}
            checked={uiFilter[filterItem]}
            onChange={() => toggleCheckbox(filterItem)}
          />
        ))}
    </Wrapper>
  )
}

GridEnumFilter.propTypes = {
  map: PropTypes.object,
  colorMap: PropTypes.object,
  translateLabel: PropTypes.func,
  setFilterModel: PropTypes.func,
}

export default GridEnumFilter
