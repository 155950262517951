function dummyTranslate(text) {
  return text
}
export const lockReasonType = [
  'NONE',
  'OWN_SMOKE_DETECTOR',
  'TENANT_REJECTED_SMOKE_DETECTORS',
  'SMOKE_DETECTORS_NOT_USEFUL',
  'UNIT_DOESNT_EXIST',
  'TEMP_FOR_RENOVATION',
  'UNIT_DEMOLITION',
  'UNIT_SOLD',
  'MODERNIZATION',
  'LOCKED_TIL_INDIVIDUAL_ORDER',
  'CUSTOMER_WISH',
  'SYSTEM',
  'UNKNOWN',
  'TEMP_FOR_CLEARIFICATION',
  'CONTRACT_CANCELLED',
  'CONTRACT_EXPIRED',
  'HIGH_CEILING',
  'SMOKE_DETECTORS_IN_SAFE_KEEPING',
  'CUSTOMER_TEMP_DEFERRED_INSTALLATION',
  'DECENTRAL_WARM_WATER',
  'UNEXPECTED_BUSINESS_UNIT',
]

export const SyncFileType = {
  PLEASE_SELECT: dummyTranslate('PLEASE SELECT'),
  FULL_UPDATE: dummyTranslate('FULL UPDATE'),
  INCREMENTAL_UPDATE: dummyTranslate('INCREMENTAL UPDATE'),
  API_TENANT_UPDATE: dummyTranslate('TENANT UPDATE'),
  COMPANY_LOGO: dummyTranslate('COMPANY LOGO'),
  DISPOSAL_NOTIFICATION: dummyTranslate('DISPOSAL NOTIFICATION'),
  DEMOLITION_NOTIFICATION: dummyTranslate('DEMOLITION NOTIFICATION'),
  MISC: dummyTranslate('MISC'),
  DRINKWATER_DATA: dummyTranslate('DRINKWATER DATA'),
  DRINKWATER_ORDER_NO_DATA: dummyTranslate('DRINKWATER ORDER NO DATA'),
  PROPERTIES: dummyTranslate('PROPERTIES'),
}
