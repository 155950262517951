import React, { useContext } from 'react'

import SimpleLink from '../../../components/Simple/SimpleLink'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'
import handleLocalStorage from '../../../helper/handleLocalStorage'

const AppointmentPropertyNumberRenderer = (props) => {
  const { referenceNumber, houseEntrance } = props.data

  const { currentLanguage } = useContext(UserContext)

  // TODO improve
  const saveOrigin = () => {
    handleLocalStorage('set', 'usageUnitOrigin', 'appointment-history')
  }

  return (
    <SimpleLink
      to={
        '/' +
        currentLanguage +
        Routes.propertyUnit +
        '/' +
        houseEntrance?.tableId
      }
      style={{ fontSize: 'inherit' }}
      onClick={saveOrigin}
    >
      {referenceNumber}
    </SimpleLink>
  )
}

export default AppointmentPropertyNumberRenderer
