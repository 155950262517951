import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import PageContainer from '../../components/PageContainer'
import CompanyBanner from './CompanyBanner/'
import OverviewNotifications from './OverviewNotifications'
import ServiceCards from '../../components/ServiceCards/'
import SimpleLink from '../../components/Simple/SimpleLink'

import { Routes } from '../../configs/RouteConfig'
import handleLocalStorage from '../../helper/handleLocalStorage'
import UserContext from '../../helper/userContext'
import {
  GridCompanyBanner,
  GridContainer,
  GridServiceCards,
  GridSupportNotifications,
} from './Overview.styles'

/**
const defaultNotifications = [
  {
    id: 'n1',
    type: 'notification',
    status: 'error',
    text: 'Notification 1 sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
  },
  {
    id: 'n2',
    type: 'notification',
    status: 'warning',
    text: 'Notification 2 sit amet, consetetur sadipscing elitr.',
  },
  {
    id: 'n3',
    type: 'notification',
    status: 'warning',
    text: 'Notification 3 sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
  },
  {
    id: 'n4',
    type: 'notification',
    status: 'warning',
    text: 'Notification 4 sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
  },
  {
    id: 't1',
    type: 'ticket',
    status: 'error',
    text: 'Ticket 1 sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
  },
  {
    id: 't2',
    type: 'ticket',
    status: 'warning',
    text: 'Ticket 2 sit amet, consetetur sadipscing elitr.',
  },
  {
    id: 't3',
    type: 'ticket',
    status: 'warning',
    text: 'Ticket 3 sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
  },
  {
    id: 't4',
    type: 'ticket',
    status: 'warning',
    text: 'Ticket 4 sit amet, consetetur sadipscing elitr. Lorem ipsum dolor sit amet, consetetur sadipscing elitr.',
  },
]
**/
const Overview = () => {
  const { currentLanguage } = useContext(UserContext)
  const location = useLocation()
  const { t } = useTranslation()

  const logoSrc = handleLocalStorage('get', 'companyLogo')
  const companyName = handleLocalStorage('get', 'companyName')

  const multipleCompanies =
    handleLocalStorage('get', 'multipleCompanies') || false

  return (
    <PageContainer>
      <GridContainer>
        <GridCompanyBanner>
          <CompanyBanner
            logoSrc={logoSrc}
            companyName={companyName}
            multipleCompanies={multipleCompanies}
            link={
              <SimpleLink
                to={{
                  pathname: '/' + currentLanguage + Routes.companySwitcher,
                  state: { from: location },
                }}
                onClick={() => {
                  handleLocalStorage('set', 'companySwitcherViaLogin', false)
                }}
                font='quicksand'
              >
                {t('SWITCH COMPANY')}
              </SimpleLink>
            }
          />
        </GridCompanyBanner>

        <GridSupportNotifications>
          <OverviewNotifications
          // notifications={defaultNotifications}
          />
        </GridSupportNotifications>

        <GridServiceCards>
          <ServiceCards />
        </GridServiceCards>
      </GridContainer>
    </PageContainer>
  )
}

export default Overview
