import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import DropdownWithLabel from '../DropdownWithLabel'
import Dropdown from './Dropdown'

import * as S from './styles'

const OverviewFilter = (props) => {
  const {
    innerFiltersList = [],
    saveFilters = () => {},
    filters = [],
    openFromStart = false,
    align = 'right',
    keepOpen = false,
    ...rest
  } = props
  const { t } = useTranslation()

  // console.log('filters', filters)

  return (
    <DropdownWithLabel
      openFromStart={openFromStart}
      align={align}
      keepOpen={keepOpen}
      passPropsToChildren
      renderLabel={(isDropdownOpen) => (
        <S.Label isDropdownOpen={isDropdownOpen}>
          <span>{t('FILTER BY')}</span>
          <S.MoreActionIcon />
        </S.Label>
      )}
    >
      <Dropdown
        innerFiltersList={innerFiltersList}
        saveFilters={saveFilters}
        filters={filters}
        align={align}
        {...rest}
      />
    </DropdownWithLabel>
  )
}

OverviewFilter.propTypes = {
  innerFiltersList: PropTypes.arrayOf(
    PropTypes.shape({
      filterType: PropTypes.string,
      title: PropTypes.string,
      placeholderLabel: PropTypes.string,
      data: PropTypes.array,
      filterModelGenerator: PropTypes.func,
    })
  ),
  saveFilters: PropTypes.func,
  filters: PropTypes.array,
  openFromStart: PropTypes.bool,
  align: PropTypes.oneOf(['right', 'left']),
  keepOpen: PropTypes.bool,
}

export default OverviewFilter
