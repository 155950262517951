import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation ChangeEmailMutation($email: String!) {
    Me {
      changeEmail(email: $email)
    }
  }
`

export default (email, accessToken, callBack, errorCallBack) => {
  const variables = {
    email,
  }

  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
