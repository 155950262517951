import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../../../../environments/pom/Environment'

const mutation = graphql`
  mutation DeleteFilterMutation($gridId: ID!, $companyId: ID!) {
    Me {
      Company(id: $companyId) {
        GridFilter(id: $gridId) {
          delete
        }
      }
    }
  }
`

export default (variables, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
