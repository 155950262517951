import React, { forwardRef, useImperativeHandle, useState } from 'react'
import GridEnumFilter from '../../../components/GridEnumFilter'
import useUpdateEffect from '../../../hooks/useUpdateEffect'

const map = {
  ACTIVE: ['NOT_LOCKED'],
  LOCKED: ['PARTLY_LOCKED', 'COMPLETELY_LOCKED'],
}

const colorMap = {
  ACTIVE: 'success',
  LOCKED: 'disabled',
}

const translateLabel = (label, t) => {
  switch (label) {
    case 'ACTIVE':
      return t('ACTIVE')
    case 'LOCKED':
      return t('LOCKED')
    default:
      return ''
  }
}

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  return (
    <GridEnumFilter
      map={map}
      colorMap={colorMap}
      translateLabel={translateLabel}
      setFilterModel={setFilterModel}
    />
  )
})
