import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { CountStatusHistoryQuery, StatusHistoryGridQuery } from '../queries'
import {
  dateTimeValueGetter,
  usageUnitLockPeriodReasonValueGetter,
  usageUnitLockPeriodStatusValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import {
  UsageUnitLockPeriodReasonRenderer,
  UsageUnitLockPeriodStatusRenderer,
} from '../renderers'
import { DateRenderer } from '../../../components/AgGrid/renderers'
import {
  UsageUnitLockPeriodReasonFilter,
  UsageUnitLockPeriodStatusFilter,
} from '../filters'

export const useStatusHistoryGrid = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { usageUnitId } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t(usageUnitLockPeriodStatusValueGetter(params))
          case 'begin':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          case 'end':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          case 'reason':
            return t(usageUnitLockPeriodReasonValueGetter(params))
          default:
            return params.value
        }
      }
      const StatusHistoryColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          field: 'status',
          width: 70,
          filter: 'UsageUnitLockPeriodStatusFilter',
          cellRenderer: UsageUnitLockPeriodStatusRenderer,
        },
        {
          headerName: t('BEGIN'),
          field: 'begin',
          width: 70,
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
          cellRenderer: DateRenderer,
        },
        {
          headerName: t('END'),
          field: 'end',
          width: 70,
          filter: 'agDateColumnFilter',
          cellRenderer: DateRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('REASON'),
          field: 'reason',
          width: 100,
          filter: 'UsageUnitLockPeriodReasonFilter',
          cellRenderer: UsageUnitLockPeriodReasonRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('LOCK INITIATOR'),
          field: 'initiator',
          width: 70,
          ...withFilterParams,
        },
      ]
      const StatusHistoryCustomFilter = [
        {
          name: 'serviceCategory.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [currentService.tableId],
              type: 'equals',
            },
          ],
        },
      ]
      return {
        query: StatusHistoryGridQuery,
        columnDefs: StatusHistoryColumnDefs,
        customFilters: StatusHistoryCustomFilter,
        countQuery: CountStatusHistoryQuery,
        extractor: (response) => response.Me?.Company?.UsageUnit?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return ['copy']
          },
          components: {
            UsageUnitLockPeriodStatusFilter,
            UsageUnitLockPeriodReasonFilter,
          },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { usageUnitId },
      }
    },
    [currentLanguage, t]
  )
  return { getParams }
}
