import React from 'react'
import { LinkButtonStyled } from '../../../../styles/HelperStylesComponents.styles'
import { Icon } from 'pyrexx-react-library'

const PreviewRenderer = (props) => {
  const { value } = props

  const { triggerPreviewLetterQuery } = props

  return (
    <>
      <LinkButtonStyled
        onClick={() => {
          triggerPreviewLetterQuery(value)
        }}
      >
        <Icon icon='search' size='16px' />
      </LinkButtonStyled>
    </>
  )
}

export default PreviewRenderer
