import React from 'react'
import { useTranslation } from 'react-i18next'
import { serviceCategoryValueGetter } from '../valueGetter'

const ContractServiceCategoryRenderer = (props) => {
  const { t } = useTranslation()

  return <span>{t(serviceCategoryValueGetter(props))}</span>
}

export default ContractServiceCategoryRenderer
