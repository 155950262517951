import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'

import SimpleInput from '../../../components/Simple/SimpleInput'

import {
  UserInfoBox,
  UserInfoLeftSection,
  UserInfoRightSection,
} from './styles'
import styled from 'styled-components/macro'

const UsernameLabel = styled.span`
  display: block;
  color: #7a8890;
  font-family: 'Quicksand', sans-serif;
  font-weight: 500;
  font-size: 11px;
  padding: 0;
`

const Username = styled.span`
  font-family: 'Open Sans', sans-serif;
`

const UsernameFieldRenderer = ({ page, newUser, handleUserChange }) => {
  const { t } = useTranslation()

  if (page === 'create-user') {
    return (
      <SimpleInput
        label={t('USERNAME')}
        value={newUser.username}
        id='new-user-username'
        onChange={(e) => handleUserChange('username', e)}
        // feedbackMessage={error}
      />
    )
  }

  if (page === 'update-user') {
    return (
      <div>
        <UsernameLabel>{t('USERNAME')}</UsernameLabel>
        {newUser.username ? <Username>{newUser.username}</Username> : '---'}
      </div>
    )
  }

  return null
}

const UserInfoInput = (props) => {
  const { page, newUser, handleUserChange, emailError } = props
  const { t } = useTranslation()

  return (
    <UserInfoBox>
      <UserInfoLeftSection>
        <UsernameFieldRenderer page={page} {...props} />

        <SimpleInput
          type='new-password'
          label={t('NEW PASSWORD')}
          value={newUser.password}
          id='new-user-password'
          onChange={(e) => handleUserChange('password', e)}
          // feedbackMessage={error}
        />
      </UserInfoLeftSection>

      <UserInfoRightSection>
        <SimpleInput
          label={t('FIRST NAME')}
          value={newUser.firstname}
          id='new-user-firstname'
          onChange={(e) => handleUserChange('firstname', e)}
          // feedbackMessage={error}
        />

        <SimpleInput
          label={t('SURNAME')}
          value={newUser.lastname}
          id='new-user-lastname'
          onChange={(e) => handleUserChange('lastname', e)}
          // feedbackMessage={error}
        />

        <SimpleInput
          label={t('EMAIL') + ' (' + t('OPTIONAL') + ')'}
          value={newUser.email}
          id='new-user-email'
          onChange={(e) => handleUserChange('email', e)}
          feedbackMessage={emailError}
        />
      </UserInfoRightSection>
    </UserInfoBox>
  )
}

UserInfoInput.propTypes = {
  emailError: PropTypes.string,
  handleUserChange: PropTypes.func,
  id: PropTypes.string,
  newUser: PropTypes.object,
}

export default UserInfoInput
