/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type RoomUsageType = "BEDROOM" | "CHILDRENS_BEDROOM" | "COMMON_SPACES" | "COMMON_SPACES_ATTIC" | "COMMON_SPACES_BASEMENT" | "CORRIDOR" | "DININGROOM" | "GUESTROOM" | "KITCHEN" | "LIVINGROOM" | "LIVINGROOM_BEDROOM" | "LOGGIA" | "NONE" | "OFFICE_WORKROOM" | "ROOM" | "WALK_THROUGH_ROOM" | "WC_BATHROOM" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type PropertyUnitInventoryGridQueryVariables = {|
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
  companyId: string,
  propertyUnitId: string,
|};
export type PropertyUnitInventoryGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +HouseEntrance: ?{|
        +id: string,
        +tableId: number,
        +data: $ReadOnlyArray<?{|
          +id: string,
          +tableId: number,
          +referenceNumber: string,
          +floor: number,
          +position: string,
          +tenants: $ReadOnlyArray<?{|
            +id: ?string,
            +tableId: ?number,
            +firstname: string,
            +lastname: string,
          |}>,
          +inventory: $ReadOnlyArray<?{|
            +id: ?string,
            +position: ?{|
              +id: string,
              +room: ?{|
                +id: string,
                +type: ?RoomUsageType,
              |},
            |},
            +product: ?{|
              +id: string,
              +name: string,
            |},
            +quantity: ?number,
            +installationDate: ?any,
          |}>,
        |}>,
      |}
    |}
  |}
|};
export type PropertyUnitInventoryGridQuery = {|
  variables: PropertyUnitInventoryGridQueryVariables,
  response: PropertyUnitInventoryGridQueryResponse,
|};
*/


/*
query PropertyUnitInventoryGridQuery(
  $sortModel: [Sort]
  $filters: [Filter]
  $companyId: ID!
  $propertyUnitId: ID!
) {
  Me {
    Company(id: $companyId) {
      HouseEntrance(id: $propertyUnitId) {
        id
        tableId
        data: usageUnits {
          id
          tableId
          referenceNumber
          floor
          position
          tenants {
            id
            tableId
            firstname
            lastname
          }
          inventory(options: {sorting: $sortModel, filters: $filters}) {
            id
            position {
              id
              room {
                id
                type
              }
            }
            product {
              id
              name
            }
            quantity
            installationDate
          }
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "propertyUnitId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "propertyUnitId"
    }
  ],
  "concreteType": "HouseEntrance",
  "kind": "LinkedField",
  "name": "HouseEntrance",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    {
      "alias": "data",
      "args": null,
      "concreteType": "UsageUnit",
      "kind": "LinkedField",
      "name": "usageUnits",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "referenceNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "floor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "position",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "tenants",
          "plural": true,
          "selections": [
            (v5/*: any*/),
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastname",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "filters",
                  "variableName": "filters"
                },
                {
                  "kind": "Variable",
                  "name": "sorting",
                  "variableName": "sortModel"
                }
              ],
              "kind": "ObjectValue",
              "name": "options"
            }
          ],
          "concreteType": "Device",
          "kind": "LinkedField",
          "name": "inventory",
          "plural": true,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Position",
              "kind": "LinkedField",
              "name": "position",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Room",
                  "kind": "LinkedField",
                  "name": "room",
                  "plural": false,
                  "selections": [
                    (v5/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "product",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "installationDate",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyUnitInventoryGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PropertyUnitInventoryGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "048be892e042d64e95bde532bf7824bb",
    "id": null,
    "metadata": {},
    "name": "PropertyUnitInventoryGridQuery",
    "operationKind": "query",
    "text": "query PropertyUnitInventoryGridQuery(\n  $sortModel: [Sort]\n  $filters: [Filter]\n  $companyId: ID!\n  $propertyUnitId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      HouseEntrance(id: $propertyUnitId) {\n        id\n        tableId\n        data: usageUnits {\n          id\n          tableId\n          referenceNumber\n          floor\n          position\n          tenants {\n            id\n            tableId\n            firstname\n            lastname\n          }\n          inventory(options: {sorting: $sortModel, filters: $filters}) {\n            id\n            position {\n              id\n              room {\n                id\n                type\n              }\n            }\n            product {\n              id\n              name\n            }\n            quantity\n            installationDate\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0308b5b9129ac801ca802c280149ebf9';

module.exports = node;
