import React from 'react'
import Tippy from '@tippyjs/react'

import { Icon, portalTheme, SimpleNote } from 'pyrexx-react-library'

import './tooltip-style.css'

const MassLetterTooltip = ({ children }) => {
  return (
    <Tippy
      delay={[null, 16000]}
      offset={[-200, 10]}
      maxWidth='600'
      content={<SimpleNote noteStatus='warning' text={children} />}
    >
      <div>
        <Icon
          icon='info'
          size='22px'
          color={portalTheme.color.linkColorPrimary}
        />
      </div>
    </Tippy>
  )
}

export default MassLetterTooltip
