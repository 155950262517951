import styled from 'styled-components/macro'
import Badge from './Badge'

const DropdownBadge = styled(Badge)`
  background-color: #c5cfda;
  width: 100%;

  padding: 8px 12px;

  color: #000;

  text-overflow: ellipsis;
  overflow: hidden;
`

export default DropdownBadge
