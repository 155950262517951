import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query BulletinsGridQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
    $propertyUnitId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        HouseEntrance(id: $propertyUnitId) {
          data: bulletins(
            options: {
              startRow: $startRow
              endRow: $endRow
              sorting: $sortModel
              filters: $filters
            }
          ) {
            id
            tableId
            date
            floors
            timeframe
          }
        }
      }
    }
  }
`
