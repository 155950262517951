import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import {
  CountHouseEntrancesQuery,
  PropertyManagementHouseEntranceGridQuery,
} from '../queries'
import { propertyUnitBillingPartitionValueGetter } from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../configs/RouteConfig'
import SpaceHolderRendererHelper from '../../../components/AgGrid/RendererHelper/SpaceHolderRendererHelper'
import PropertyUnitIdRenderer from '../renderer/PropertyUnitIdRenderer'
import { PropertyUnitBillingPartitionRenderer } from '../renderer'
import { getPriorityHeightFromDisplayedColumns } from '../../../helper/calculateRowHeight'

export const usePropertyUnitGrid = () => {
  const { redirectRoute } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(() => {
    const getRowHeight = (params) => {
      const displayedColumns =
        params.node.columnApi?.getAllDisplayedVirtualColumns()
      const data = params?.data
      const getWidth = (field) =>
        params.node.columnApi?.getColumn?.(field)?.getActualWidth?.()

      const columnParams = [
        {
          field: 'billingPartitions',
          value: data?.billingPartitions,
          fieldProp: 'name',
          colWidth: getWidth('billingPartitions'),
          extraRows: 1,
        },
      ]

      return getPriorityHeightFromDisplayedColumns({
        columnParams,
        displayedColumns,
        defaultHeight: 70,
      })
    }
    const valueGetter = (params) => {
      const field = params.column.getColDef().field
      const colId = params.column.getColDef().colId
      const search = colId || field
      switch (search) {
        case 'billingPartitions':
          return propertyUnitBillingPartitionValueGetter(params)
        default:
          return params.value
      }
    }
    const PropertyManagementHouseEntranceColumnDefs = [
      columnCheckbox,
      {
        headerName: t('ALLOCATION NUMBER'),
        width: 70,
        sortable: false, // TODO REMOVE TBA
        filter: false,
        cellRenderer: SpaceHolderRendererHelper,
        cellRendererParams: { spaceHolderValue: 'TBA' },
      },
      {
        headerName: t('PYREXX PROPERTY ID'),
        field: 'tableId',
        width: 70,
        cellRenderer: PropertyUnitIdRenderer,
        ...withFilterParams,
        type: 'rightAligned',
      },
      {
        headerName: t('STREET'),
        field: 'address.street',
        width: 70,
        ...withFilterParams,
      },
      {
        headerName: t('STREET NR.'),
        field: 'address.number',
        width: 70,
        ...withFilterParams,
        type: 'rightAligned',
      },
      {
        headerName: t('ZIP'),
        field: 'address.zip',
        width: 70,
        ...withFilterParams,
        type: 'rightAligned',
      },
      {
        headerName: t('CITY'),
        field: 'address.city',
        width: 70,
        ...withFilterParams,
      },
      {
        headerName: t('CONTRACT PARTITION'),
        field: 'billingPartitions',
        width: 140,
        sortable: false,
        filter: false, // TODO do we need filter?
        cellRenderer: PropertyUnitBillingPartitionRenderer,
      },
    ]
    const PropertyManagementHouseEntranceCustomFilter = []
    return {
      query: PropertyManagementHouseEntranceGridQuery,
      columnDefs: PropertyManagementHouseEntranceColumnDefs,
      customFilters: PropertyManagementHouseEntranceCustomFilter,
      countQuery: CountHouseEntrancesQuery,
      extractor: (response) => response.Me?.Company?.data,
      customGridOptions: {
        getContextMenuItems: (params) => {
          return [
            {
              // custom item
              name: t('GO TO PROPERTY {TABLEID}', {
                TABLEID: params?.node?.data?.tableId,
              }),
              action: function () {
                redirectRoute(
                  Routes.propertyUnit + '/' + params?.node?.data?.tableId
                )
              },
            },
            'copy',
          ]
        },
        getRowHeight,
        components: {},
        processCellForClipboard: valueGetter,
        defaultCsvExportParams: {
          processCellCallback: valueGetter,
        },
      },
      customVariables: {},
    }
  }, [redirectRoute, t])
  return { getParams }
}
