import React from 'react'
import styled from 'styled-components/macro'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
  width: 60px;
  height: 24px;

  box-sizing: content-box;

  ${({ variant }) => {
    switch (variant) {
      case 'xs':
        return 'width: 30px;'
      case 'sm':
        return 'width: 60px;'
      case 'md':
        return 'width: 90px;'
      case 'lg':
        return 'width: 120px;'
      case 'xl':
        return 'width: 200px;'
      case 'half':
        return 'width: 50%'
      case '3/4':
      case 'tf':
        return 'width: 75%;'
      case 'full':
        return 'width: 100%;'
      case 'fill':
        return 'width: 100%; height: 100%;'
      default:
        return ''
    }
  }}

  ${({ xs }) => xs && `width: 30px;`}
  ${({ sm }) => sm && `width: 60px;`}
  ${({ md }) => md && `width: 90px;`}
  ${({ lg }) => lg && `width: 120px;`}
  ${({ xl }) => xl && `width: 200px;`}
  ${({ half }) => half && `width: 50%;`}
  ${({ tf }) => tf && `width: 75%;`}
  ${({ full }) => full && `width: 100%; `}
  ${({ fill }) => fill && `width: 100%; height: 100%; `}
`

const StyledSkeleton = styled.div`
  height: 100%;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f5f5f5, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: block;
  line-height: 1;
  width: 100%;
  animation: shimmer 1.2s ease-in-out infinite;
  color: transparent;

  @keyframes shimmer {
    0% {
      background-position: -200px 0;
    }
    100% {
      background-position: calc(200px + 100%) 0;
    }
  }
`

const Skeleton = (props) => {
  const { variant = '', className } = props

  return (
    <Wrapper variant={variant} className={className} {...props}>
      <StyledSkeleton />
    </Wrapper>
  )
}

Skeleton.propTypes = {
  variant: PropTypes.oneOf([
    'xs',
    'sm',
    'md',
    'lg',
    'xl',
    'half',
    '3/4',
    'full',
    'fill',
  ]),
}

export default Skeleton
