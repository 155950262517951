import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation AuthenticateMutation($username: String!, $password: String!) {
    info: authenticate(username: $username, password: $password) {
      status
      accessToken
      refreshToken
      Me {
        id
        name
        firstname
        lastname
        salutation
        title
        can
        role
        email
        Companies(options: null) {
          id
          name
        }
      }
    }
  }
`

export default (username, password, callBack, errorCallBack) => {
  const variables = {
    username,
    password,
  }

  commitMutation(environment(), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
