import React from 'react'
import { Col, Container, Row } from 'react-grid-system'
import { Icon, SimpleLink } from 'pyrexx-react-library'

import { Links } from '../../../../configs/LinkConfig'

const socialLinks = [
  {
    iconName: 'social-fb',
    link: Links.facebook,
  },
  {
    iconName: 'social-xing',
    link: Links.xing,
  },
  {
    iconName: 'social-linked',
    link: Links.linkedin,
  },
  {
    iconName: 'social-youtube',
    link: Links.youtube,
  },
]

const SocialFooter = () => {
  return (
    <Container xs sm md>
      <Row component='ul' align='center' justify='center'>
        {socialLinks.map((linkObject) => (
          <Col key={linkObject.iconName} component='li' xs='content'>
            <SimpleLink
              label={
                <Icon
                  icon={linkObject.iconName}
                  size='50px'
                />
              }
              url={linkObject.link}
              target='_BLANK'
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default SocialFooter
