import styled, { css } from 'styled-components/macro'

import { Icon } from 'pyrexx-react-library'

export const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  background-color: #fff;

  padding: 0 16px;

  transition: 0.2s ease-out;

  &:hover,
  &:focus {
    background-color: #dbe7f3;
  }

  ${(props) => props.isDropdownOpen && `background-color: #dbe7f3;`};

  // Styles from mockup
  border-radius: 10px;
`

export const MoreActionIcon = styled(Icon).attrs(() => ({
  icon: 'more-action',
  size: '40px',
  color: '#4ab4e2',
}))`
  ${Label}:hover &, ${Label}:focus & {
    color: #979797;
  }
`

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;

  width: 335px;

  background-color: #fff;
  box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);

  padding: 16px;
`

export const DropdownItem = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;

  padding: 16px;
  margin: 0 -16px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  & > span {
    margin-bottom: 8px;
  }
`

const dropleftOpenCSS = css`
  opacity: 1;
  transform: scale(1);
`

export const DropleftContainer = styled.div`
  border-top: 2px solid #4ab4e2;

  position: absolute;
  top: 65%;
  ${({ align }) => {
    if (align === 'right') return 'left: calc(-300px + 5px);'
    if (align === 'left') return 'right: calc(-300px + 5px);'
  }}

  box-shadow: 0 1px 4px 1px rgba(186, 191, 199, 0.4);

  opacity: 0;
  transform: scale(0);
  transition: 250ms ease-in-out;
  transform-origin: ${({ align }) => {
    if (align === 'right') return 'right top;'
    if (align === 'left') return 'left top;'
  }};

  ${(props) => props.dropleft === props.filterId && dropleftOpenCSS};
`

export const Dropleft = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #fff;
  padding: 24px;

  width: 300px;
`
