import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  height: ${(props) => props.y + 'px'};
`

const Spacer = ({ y = 24, ...restOfProps }) => {
  return <Wrapper y={y} {...restOfProps} />
}

Spacer.propTypes = {
  y: PropTypes.number,
}

export default Spacer
