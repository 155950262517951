import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import OverviewFilter from '../OverviewFilter'

import getTimeframes from '../../helper/getTimeframes'
import getServices from '../../helper/getServices'
import getContracts from '../../helper/getContracts'
import {
  getContractFilterModel,
  getDateFilterModel,
  getServiceFilterModel,
} from '../../helper/getFilterModel'
import UserContext from '../../helper/userContext'

const ContractsFilter = (props) => {
  const {
    contracts = [],
    saveFilters = () => {},
    filters = [],
    customKeys = {},
  } = props
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()

  const timeframesList = getTimeframes(contracts)
  const servicesList = getServices(contracts)
  const contractsList = getContracts(contracts)

  const {
    customDateFilterKey,
    customServiceFilterKey,
    customContractFilterKey,
  } = customKeys

  const innerFiltersList = [
    {
      filterId: 'date',
      title: t('PERIOD'),
      placeholderLabel: t('SELECT TIME PERIOD'),
      data: timeframesList,
      // generates ui, api, and grid filter models
      filterModelGenerator: (d) =>
        getDateFilterModel(d, t, customDateFilterKey, currentLanguage),
    },
    {
      filterId: 'service',
      title: t('SERVICE TYPE'),
      placeholderLabel: t('SELECT SERVICE'),
      data: servicesList,
      filterModelGenerator: (d) =>
        getServiceFilterModel(d, t, customServiceFilterKey),
    },
    {
      filterId: 'contract',
      title: t('CONTRACT'),
      placeholderLabel: t('SELECT CONTRACT'),
      data: contractsList,
      filterModelGenerator: (d) =>
        getContractFilterModel(d, t, customContractFilterKey),
    },
  ]

  return (
    <OverviewFilter
      innerFiltersList={innerFiltersList}
      saveFilters={saveFilters}
      filters={filters}
    />
  )
}

ContractsFilter.propTypes = {
  contracts: PropTypes.array,
  saveFilters: PropTypes.func,
  filters: PropTypes.array,
  openFromStart: PropTypes.bool,
  align: PropTypes.oneOf(['right', 'left']),
  keepOpen: PropTypes.bool,
}

export default ContractsFilter
