import React, { useContext, useReducer } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-grid-system'
import styled from 'styled-components/macro'

import { ButtonPrimary, SimpleNote, StyledInput } from 'pyrexx-react-library'
import QueryRendererContainer from '../../../components/QueryRendererContainer'

import UserContext from '../../../helper/userContext'
import {
  initialState,
  SET_LOADER,
  SUBMIT_FAILED,
  SUBMIT_WAS_SUCCESSFUL,
  submitReducer,
} from '../../../reducers/submitReducer'

import FetchContactDepartmentsQuery from './queries/FetchContactDepartmentsQuery'
import SubmitContactFormMutation from '../../../mutations/SubmitContactFormMutation'

const contactDepartmentTranslate = (value, t) => {
  switch (value) {
    case 'SERVICE CENTER':
      return t('SERVICE CENTER')

    case 'CONTRACT CUSTOMER SUPPORT':
      return t('CONTRACT CUSTOMER SUPPORT')

    case 'SALES':
      return t('SALES')

    default:
      return value
  }
}

// [
// <option key="service-center" value="service-center">
//   {t("SERVICE CENTER")}
// </option>,
//   <option key="contract-customer-support" value="contract-customer-support">
//     {t("CONTRACT CUSTOMER SUPPORT")}
//   </option>,
//   <option key="sales-service" value="sales-service">
//     {t("SERVICE DISTRIBUTION")}
//   </option>,
// ];

const ContactForm = ({ contactDepartments = [] }) => {
  const { user } = useContext(UserContext)
  const { t } = useTranslation()

  const [state, dispatch] = useReducer(submitReducer, initialState)

  const schema = Yup.object({
    salutation: Yup.string(),
    lastName: Yup.string().required(t('IS REQUIRED')),
    firstName: Yup.string().required(t('IS REQUIRED')),
    phoneNumber: Yup.string(),
    email: Yup.string().email().required(t('IS REQUIRED')),
    // sendEmailConfirmation: Yup.bool(),
    contactDepartmentValue: Yup.string(),
    subject: Yup.string().required(t('IS REQUIRED')),
    content: Yup.string().required(t('IS REQUIRED')),
  })

  const salutationElements = [
    <option key='mrs' value='MRS'>
      {t('MRS.')}
    </option>,
    <option key='mr' value='MR'>
      {t('MR.')}
    </option>,
    <option key='company' value='COMPANY'>
      {t('COMPANY')}
    </option>,
  ]

  const contactDepartmentElements = contactDepartments.map((c) => {
    return (
      <option key={c.value} value={c.value}>
        {contactDepartmentTranslate(c.label, t)}
      </option>
    )
  })

  const handleFormSubmit = (values, formikb) => {
    dispatch({ type: SET_LOADER })

    SubmitContactFormMutation(
      {
        salutation: values.salutation,
        lastName: values.lastName,
        firstName: values.firstName,
        email: values.email,
        subject: values.subject,
        content: values.content,
        phoneNumber: values.phoneNumber,
        contactDepartmentValue: values.contactDepartmentValue,
      },
      user.accessToken,
      ({ submitContactForm = false }) => {
        if (submitContactForm) {
          dispatch({
            type: SUBMIT_WAS_SUCCESSFUL,
            payload: t('CONTACT REQUEST WAS SUCCESSFULLY SENT'),
          })
          formikb.resetForm()
        }
      },
      () => {
        dispatch({
          type: SUBMIT_FAILED,
          payload: t(
            'AN UNEXPECTED ERROR OCCURRED, PLEASE TRY AGAIN OR CALL THE SUPPORT'
          ),
        })
      }
    )
  }

  const formik = useFormik({
    initialValues: {
      salutation: user?.salutation || '',
      lastName: user?.lastname || '',
      firstName: user?.firstname || '',
      phoneNumber: '',
      email: user?.email || '',
      // sendEmailConfirmation: false,
      contactDepartmentValue: '',
      subject: '',
      content: '',
    },
    validationSchema: schema,
    onSubmit: handleFormSubmit,
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row justify='center'>
        <Col xs={3}>
          <StyledInput
            type='select'
            name='salutation'
            id='salutation'
            onChange={formik.handleChange}
            value={formik.values.salutation}
            label={t('SALUTATION')}
            feedbackMessage={
              formik.touched.salutation && formik.errors.salutation
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
            optionsHtmlElemets={salutationElements}
          />
        </Col>

        <Col xs={5}>
          <StyledInput
            type='input'
            name='lastName'
            id='lastName'
            onChange={formik.handleChange}
            value={formik.values.lastName}
            label={t('SURNAME')}
            feedbackMessage={formik.touched.lastName && formik.errors.lastName}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>

        <Col xs={4}>
          <StyledInput
            type='input'
            name='firstName'
            id='firstName'
            onChange={formik.handleChange}
            value={formik.values.firstName}
            label={t('FIRST NAME')}
            feedbackMessage={
              formik.touched.firstName && formik.errors.firstName
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='input'
            name='phoneNumber'
            id='phoneNumber'
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            label={t('PHONE')}
            feedbackMessage={
              formik.touched.phoneNumber && formik.errors.phoneNumber
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='input'
            name='email'
            id='email'
            onChange={formik.handleChange}
            value={formik.values.email}
            label={t('EMAIL')}
            feedbackMessage={formik.touched.email && formik.errors.email}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>

        {/* <Col xs={6}> */}
        {/*  <StyledInput */}
        {/*    type='checkbox' */}
        {/*    name='sendEmailConfirmation' */}
        {/*    id='sendEmailConfirmation' */}
        {/*    onChange={formik.handleChange} */}
        {/*    value={formik.values.sendEmailConfirmation} */}
        {/*    label={t('EMAIL CONFIRMATION')} */}
        {/*    setFieldValue={formik.setFieldValue} */}
        {/*  /> */}
        {/* </Col> */}
      </Row>

      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='select'
            name='contactDepartmentValue'
            id='contactDepartmentValue'
            onChange={formik.handleChange}
            value={formik.values.contactDepartmentValue}
            label={t('INQUIRED DEPARTMENT')}
            feedbackMessage={
              formik.touched.contactDepartmentValue &&
              formik.errors.contactDepartmentValue
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
            optionsHtmlElemets={contactDepartmentElements}
          />
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='input'
            name='subject'
            id='subject'
            onChange={formik.handleChange}
            value={formik.values.subject}
            label={t('SUBJECT')}
            feedbackMessage={formik.touched.subject && formik.errors.subject}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='textarea'
            name='content'
            id='content'
            onChange={formik.handleChange}
            value={formik.values.content}
            label={t('CONTENT')}
            feedbackMessage={formik.touched.content && formik.errors.content}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>

      <Row justify='center' style={{ marginBottom: '1rem' }}>
        <Col xs='content'>
          <ButtonPrimary type='submit' isLoading={state.loading}>
            {t('SEND REQUEST')}
          </ButtonPrimary>
        </Col>
      </Row>

      {state.showComponent && (
        <Row justify='center' style={{ marginBottom: '1rem' }}>
          <Col xs='content'>
            <SimpleNote noteStatus={state.status} text={state.message} />
          </Col>
        </Row>
      )}
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  padding: 32px;
  background-color: #fff;
`

export default () => {
  return (
    <QueryRendererContainer
      query={FetchContactDepartmentsQuery}
      render={(data) => {
        const { contactDepartments = [] } = data?.Config || {}

        return <ContactForm contactDepartments={contactDepartments} />
      }}
    />
  )
}
