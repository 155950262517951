import React from 'react'
import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'
import { useTranslation } from 'react-i18next'
import { syncStatusTypeValueGetter } from '../valueGetter'
import { SyncStatusTypeMap } from '../../../constants/colorMap'

const OperationsStatusRenderer = (props) => {
  const { value } = props
  const { t } = useTranslation()

  return (
    <StatusLightWithLabel
      status={SyncStatusTypeMap[value]}
      label={t(syncStatusTypeValueGetter(props))}
    />
  )
}

export default OperationsStatusRenderer
