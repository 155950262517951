import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { columnCheckbox } from '../../../../components/AgGrid/columnDefs'
import { DateRenderer } from '../../../../components/AgGrid/renderers'
import { quotaCountQuery, quotaGridQuery } from '../queries'
import SimpleGridFilter from '../../../../components/AgGrid/filters/SimpleGridFilter'
import {
  AppointmentNumberRenderer,
  QuotaButtonRenderer,
  ReferenceNumberRenderer,
} from '../renderer'

export const useQuotaGrid = (
  setModal,
  setAppointmentId,
  triggerQuotaReleaseMutation
) => {
  const { t } = useTranslation()

  const getParams = useCallback(() => {
    const quotaGridColumnDef = [
      columnCheckbox,
      {
        headerName: t('APPOINTMENT ID'),
        field: 'appointment.id',
        width: 25,
        cellRenderer: AppointmentNumberRenderer,
      },
      {
        headerName: t('ALLOCATION NUMBER'),
        field: 'appointment.usageUnit.referenceNumber',
        width: 25,
        cellRenderer: ReferenceNumberRenderer,
      },
      {
        headerName: t('STREET'),
        field: 'appointment.houseEntrance.address.STREET',
        width: 75,
      },
      {
        headerName: t('HOUSE NUMBER'),
        field: 'appointment.houseEntrance.address.number',
        width: 10,
      },
      {
        headerName: t('CITY'),
        field: 'appointment.houseEntrance.address.city',
        width: 50,
      },
      {
        headerName: t('NAME'),
        field: 'appointment.name',
        width: 50,
        // cellRenderer: AppointmentNameRenderer,
      },
      {
        headerName: t('DATE'),
        field: 'appointment.date',
        width: 100,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['apply', 'clear', 'reset'],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
        cellRenderer: DateRenderer,
      },
      {
        headerName: t('DESCRIPTION'),
        field: 'name',
        width: 100,
      },
      {
        headerName: '',
        field: '',
        width: 100,
        cellRenderer: QuotaButtonRenderer,
        cellRendererParams: {
          setModal: setModal,
          setAppointmentId: setAppointmentId,
          triggerQuotaReleaseMutation: triggerQuotaReleaseMutation,
        },
      },
    ]

    const quotaGridCustomFilter = []
    return {
      query: quotaGridQuery,
      columnDefs: quotaGridColumnDef,
      customFilters: quotaGridCustomFilter,
      countQuery: quotaCountQuery,
      extractor: (response) => response.Me?.Company?.Quotas,
      countExtractor: (response) => response.Me?.Company?.data,
      customGridOptions: {},
      components: {
        SimpleGridFilter,
      },
    }
  }, [setModal, t, setAppointmentId, triggerQuotaReleaseMutation])
  return { getParams }
}
