import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import QueryRendererContainer from '../../components/QueryRendererContainer/QueryRendererContainer'
import PropertyManagement from './PropertyManagement'

import environment from '../../environments/pom/Environment'
import PropertyManagementContainerQuery from './queries/PropertyManagementContainer'
import UserContext from '../../helper/userContext'
import getServices from '../../helper/getServices'
import { serviceCategoryEnumList } from '../../components/AgGrid/enumLists'

const PropertyManagementContainer = ({ pageSection = '' }) => {
  const { user } = useContext(UserContext)
  const { t } = useTranslation()

  const variables = {
    companyId: user.companyId,
  }

  return (
    <QueryRendererContainer
      environment={environment(user.accessToken)}
      query={PropertyManagementContainerQuery}
      variables={variables}
      loading={<PropertyManagement loading pageSection={pageSection} />}
      render={(data) => {
        const services = getServices(data.Me?.Company?.Contracts)
        const houseEntrancesCount = data.Me?.Company?.countHouseEntrances
        const translatedServices = services.map((service) => ({
          ...service,
          label: t(serviceCategoryEnumList(service.name)),
        }))
        return (
          <PropertyManagement
            vIds={data.Me?.Company?.Contracts}
            services={translatedServices}
            houseEntrancesCount={houseEntrancesCount}
            pageSection={pageSection}
          />
        )
      }}
    />
  )
}
export default PropertyManagementContainer
