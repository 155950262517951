import styled from 'styled-components/macro'

const Pills = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 12px;

  & > * {
    flex: calc(25% - 12px) 0;
  }

  margin: 24px 0;
`

export default Pills
