import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import Spacer from '../../components/Spacer'
import Tabs from '../../components/Tabs'
import PropertyManagementOverview from './PropertyManagementOverview'
import { usePropertyUnitGrid } from './gridConfigHooks/usePropertyUnitGrid'
import { useUsageUnitGrid } from './gridConfigHooks/useUsageUnitGrid'
import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import Operations from './Operations'
import useUrlRedirection from '../../hooks/useUrlRedirection'
import InventoryDataUploadContainer from './InventoryDataUploadContainer'

const PropertyManagement = (props) => {
  const {
    loading = false,
    services = [],
    vIds = [],
    houseEntrancesCount = 0,
    pageSection = 'portfolioOverview',
  } = props
  const { t } = useTranslation()
  const urlRedirectionCallback = useUrlRedirection()

  const grids = [
    {
      id: 'propertyUnit-list',
      label: t('PROPERTIES'),
      useGetParams: usePropertyUnitGrid(),
    },
    {
      id: 'usageUnit-list',
      label: t('USAGE UNITS'),
      useGetParams: useUsageUnitGrid(),
    },
  ]
  const {
    generateServiceDropDown,
    generateTabs,
    currentService,
    setCurrentService,
    generateGrid,
  } = useAgGridUltra('propertyManagement', grids)

  useEffect(() => {
    if (services.length > 0 && !currentService) {
      const tmpService = services[0]
      setCurrentService(tmpService)
    }
  }, [currentService, services, setCurrentService])

  const OverviewTabs = [
    {
      key: 'portfolioOverview',
      label: t('PORTFOLIO OVERVIEW'),
      content: (
        <>
          <PropertyManagementOverview
            houseEntrancesCount={houseEntrancesCount}
            loading={loading}
          />
          <Spacer />
          {generateServiceDropDown(services)}
          <Spacer />
          {generateTabs()}
          {generateGrid(!(!loading && currentService))}
        </>
      ),
    },
    {
      key: 'portfolioDataUpload',
      label: t('PORTFOLIO DATA UPLOAD'),
      content: <InventoryDataUploadContainer vIds={vIds} loading={loading} />,
    },
    {
      key: 'operations',
      label: t('INCIDENTS'),
      content: <Operations />,
    },
  ]

  return (
    <PageContainer fluid>
      <PageTitle>{t('PORTFOLIO MANAGEMENT')}</PageTitle>
      <Tabs
        tabs={OverviewTabs}
        pageSection={pageSection}
        urlRedirectionCallback={urlRedirectionCallback}
      />
    </PageContainer>
  )
}

export default PropertyManagement

PropertyManagement.propTypes = {
  usageUnit: PropTypes.object,
  loading: PropTypes.bool,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.any,
      tableId: PropTypes.any,
      selected: PropTypes.any,
      disabled: PropTypes.any,
    })
  ),
}
