import { useTranslation } from 'react-i18next'
import syncListTypeValueGetter from '../valueGetter/syncListTypeValueGetter'

const SyncListTypeRenderer = (props) => {
  const { t } = useTranslation()

  return t(syncListTypeValueGetter(props))
}

export default SyncListTypeRenderer
