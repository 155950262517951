import React, { useContext } from 'react'
import { Col, Row, Container } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { ErrorBoundary } from 'react-error-boundary'

import { ServiceCard } from 'pyrexx-react-library'
import StatusFilterButton from '../Status/StatusFilterButton'
import WithSkeleton from './WithSkeleton'
import SimpleLink from '../Simple/SimpleLink'
import QueryRendererContainer from '../QueryRendererContainer'
import NotAvailableContent from './NotAvailableContent'

import { Routes } from '../../configs/RouteConfig'
import UserContext from '../../helper/userContext'
import graphql from 'babel-plugin-relay/macro'

import inventoryImg from '../../assets/overview/inventory.png'
import inventoryInactiveImg from '../../assets/overview/inventory-inactive.png'
import inventoryContentImg from '../../assets/overview/inventory-content.png'

const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CardItemTitle = styled.p`
  margin-top: 0;
  text-align: center;
`

const PropertyManagementCardContent = (props) => {
  const { data = {}, loading = false } = props
  const { generateUrl } = useContext(UserContext)
  const { t } = useTranslation()

  const statusFilterUrl = (filter) => {
    return generateUrl(Routes.propertyManagement + '?status=' + filter)
  }

  return (
    <Container style={{ width: '100%' }}>
      <Row>
        <StyledCol>
          <WithSkeleton variant='md' loading={loading}>
            <CardItemTitle>{t('ACTIVE')}</CardItemTitle>
          </WithSkeleton>
        </StyledCol>

        <StyledCol>
          <WithSkeleton variant='md' loading={loading}>
            <CardItemTitle>{t('LOCKED')}</CardItemTitle>
          </WithSkeleton>
        </StyledCol>

        <StyledCol>
          <WithSkeleton variant='md' loading={loading}>
            <CardItemTitle>{t('PARTIALLY LOCKED')}</CardItemTitle>
          </WithSkeleton>
        </StyledCol>
      </Row>

      <Row>
        <StyledCol>
          <WithSkeleton loading={loading}>
            <Row justify='center'>
              <StatusFilterButton
                to={statusFilterUrl('active')}
                variant='link'
                status='success'
                // TODO check correct status inside query (active?, pending?, locked?)
                label={data?.active}
                border={false}
              />
            </Row>
          </WithSkeleton>
        </StyledCol>

        <StyledCol>
          <WithSkeleton loading={loading}>
            <Row justify='center'>
              <StatusFilterButton
                to={statusFilterUrl('locked')}
                variant='link'
                status='disabled'
                label={data?.locked}
                border={false}
              />
            </Row>
          </WithSkeleton>
        </StyledCol>

        <StyledCol>
          <WithSkeleton loading={loading}>
            <Row justify='center'>
              <StatusFilterButton
                to={statusFilterUrl('partial')}
                variant='link'
                status='disabled'
                label={data?.partial}
                border={false}
              />
            </Row>
          </WithSkeleton>
        </StyledCol>
      </Row>
    </Container>
  )
}

const PropertyManagementCard = ({
  data = {},
  loading = false,
  error = false,
}) => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()

  if (error) {
    return (
      <ServiceCard
        imgSrc={inventoryInactiveImg}
        title={t('PORTFOLIO OVERVIEW')}
        content={<NotAvailableContent imgSrc={inventoryContentImg} />}
        isDisabled
      />
    )
  }

  return (
    <ServiceCard
      imgSrc={inventoryImg}
      title={t('PORTFOLIO OVERVIEW')}
      content={<PropertyManagementCardContent data={data} loading={loading} />}
      link={
        !loading && (
          <SimpleLink
            to={'/' + currentLanguage + Routes.propertyManagement}
            font='quicksand'
          >
            {t('ALL') + ' >'}
          </SimpleLink>
        )
      }
    />
  )
}

const query = graphql`
  query PropertyManagementCardQuery($companyId: ID!) {
    Me {
      propertyManagementStatus: Company(id: $companyId) {
        active: countUsageUnits(
          options: {
            filters: [
              {
                name: "lockPeriod.status"
                filterType: text
                operator: AND
                conditions: [{ value: ["ACTIVE"], type: equals }]
              }
            ]
          }
        )
        locked: countUsageUnits(
          options: {
            filters: [
              {
                name: "lockPeriod.status"
                filterType: text
                operator: AND
                conditions: [{ value: ["DEACTIVATED"], type: equals }]
              }
            ]
          }
        )
        partial: countUsageUnits(
          options: {
            filters: [
              {
                name: "lockPeriod.status"
                filterType: text
                operator: AND
                conditions: [{ value: ["PENDING"], type: equals }]
              }
            ]
          }
        )
      }
    }
  }
`

export default ({ isHidden = true }) => {
  const { user } = useContext(UserContext)

  const variables = {
    companyId: user.companyId,
  }

  if (isHidden) {
    return null
  }

  return (
    <ErrorBoundary fallbackRender={() => null}>
      <QueryRendererContainer
        query={query}
        variables={variables}
        loading={<PropertyManagementCard loading />}
        render={(data) => {
          const propertyManagementStatus = data?.Me?.propertyManagementStatus

          return <PropertyManagementCard data={propertyManagementStatus} />
        }}
      />
    </ErrorBoundary>
  )
}
