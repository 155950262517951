import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import passwordStrengthTranslations from './passwordStrengthTranslations'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 110%;

  visibility: hidden;
  opacity: 0;
  //transition: visibility 0s linear 500ms, opacity 500ms;
  transition: visibility 0s linear 0s, opacity 500ms;

  ${(props) =>
    props.show &&
    css`
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear 0ms, opacity 500ms;
    `}
`

const Box = styled.div`
  width: 400px;
  //background-color: hsl(0, 0%, 96%);
  background-color: #eff4f7;
  padding: 16px 24px;
  border-radius: 10px;
  box-shadow: 1px 2px 4px #ccc;
`

const Header = styled.h3``

const Body = styled.p``

const PasswordStrengthSuggestions = ({ warning = '', suggestions = [] }) => {
  const { t } = useTranslation()

  const warningExists = warning.length > 0
  const suggestionsExist = suggestions.length > 0

  return (
    <Wrapper show={warningExists || suggestionsExist}>
      <Box>
        {warningExists && (
          <div>
            <Header>{t('WARNING')}</Header>
            <Body>{passwordStrengthTranslations(warning, t)}</Body>
          </div>
        )}

        {suggestionsExist && (
          <div>
            <Header>{t('SUGGESTIONS')}</Header>
            <ul>
              {suggestions.map((suggestion, idx) => (
                <li key={'suggestion' + idx}>
                  {passwordStrengthTranslations(suggestion, t)}
                </li>
              ))}
            </ul>
          </div>
        )}
      </Box>
    </Wrapper>
  )
}

PasswordStrengthSuggestions.propTypes = {
  warning: PropTypes.string,
  suggestions: PropTypes.array,
}

export default PasswordStrengthSuggestions
