/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppointmentAccessType = "ACCESS" | "NONE" | "NO_ACCESS_22" | "NO_ACCESS_23" | "NO_ACCESS_24" | "NO_ACCESS_25" | "NO_ACCESS_26" | "NO_ACCESS_27" | "NO_ACCESS_28" | "NO_ACCESS_29" | "NO_ACCESS_30" | "NO_ACCESS_31" | "NO_ACCESS_32" | "NO_ACCESS_33" | "NO_ACCESS_34" | "NO_ACCESS_35" | "NO_ACCESS_36" | "NO_ACCESS_37" | "NO_ACCESS_38" | "NO_ACCESS_39" | "NO_ACCESS_40" | "NO_ACCESS_AWAY" | "NO_ACCESS_DENIED" | "NO_ACCESS_DENIED_NEED_RESPITE" | "NO_ACCESS_FOREIGN_SD_INSTALLED_NO_MAINTENANCE" | "NO_ACCESS_HYGIENICALLY_INFEASIBLE" | "NO_ACCESS_INFEASIBLE" | "NO_ACCESS_JUNCTION_BOX_BROKEN" | "NO_ACCESS_JUNCTION_BOX_DESTROYED" | "NO_ACCESS_JUNCTION_BOX_GRAFFITI_FOUND" | "NO_ACCESS_JUNCTION_BOX_INACCESSIBLE" | "NO_ACCESS_JUNCTION_BOX_MISSING" | "NO_ACCESS_JUNCTION_BOX_OPENED" | "NO_ACCESS_NO_ADULT_ON_SITE" | "NO_ACCESS_PARTLY_DONE_SOME_LOCATIONS_LOCKED" | "NO_ACCESS_RAMSHACKLE_SURFACE" | "NO_ACCESS_RENOVATION" | "NO_ACCESS_TENANT_OWNS_RM" | "NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE" | "NO_ACCESS_VACANT_KEY_DOES_NOT_FIT" | "NO_ACCESS_VACANT_NO_KEY" | "%future added value";
export type AppointmentStatusType = "CANCELLED" | "CHECKED" | "CLOSED" | "DEACTIVATED" | "DIAGNOSE_PENDING" | "DONE" | "NONE" | "OPEN" | "PLANNED" | "TEMP_DEFERRED" | "UNTERMINATED" | "%future added value";
export type AppointmentTypeType = "P" | "REMOTE_DATA_COLLECTION" | "T1" | "T2" | "TA" | "TI" | "TS" | "TX" | "%future added value";
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type LockReasonType = "CONTRACT_CANCELLED" | "CONTRACT_EXPIRED" | "CUSTOMER_TEMP_DEFERRED_INSTALLATION" | "CUSTOMER_WISH" | "DECENTRAL_WARM_WATER" | "HIGH_CEILING" | "LOCKED_TIL_INDIVIDUAL_ORDER" | "MODERNIZATION" | "NONE" | "OWN_SMOKE_DETECTOR" | "SMOKE_DETECTORS_IN_SAFE_KEEPING" | "SMOKE_DETECTORS_NOT_USEFUL" | "SYSTEM" | "TEMP_FOR_CLEARIFICATION" | "TEMP_FOR_RENOVATION" | "TENANT_REJECTED_SMOKE_DETECTORS" | "UNEXPECTED_BUSINESS_UNIT" | "UNIT_DEMOLITION" | "UNIT_DOESNT_EXIST" | "UNIT_SOLD" | "UNKNOWN" | "%future added value";
export type LockStatusType = "ACTIVE" | "DEACTIVATED" | "NONE" | "PENDING" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type UsageUnitTypeType = "BUSINESS" | "COMMON" | "NONE" | "PRIVATE" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type Filter1NotEquippedUsageUnitsQueryVariables = {|
  companyId: string,
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
|};
export type Filter1NotEquippedUsageUnitsQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +data: ?$ReadOnlyArray<?{|
        +id: string,
        +lockPeriods: $ReadOnlyArray<?{|
          +id: string,
          +begin: ?any,
          +status: LockStatusType,
          +reason: LockReasonType,
        |}>,
        +appointments: $ReadOnlyArray<?{|
          +id: ?string,
          +type: ?AppointmentTypeType,
          +status: ?AppointmentStatusType,
          +accessReason: ?AppointmentAccessType,
          +executionDate: ?any,
        |}>,
        +referenceNumber: string,
        +tableId: number,
        +address: ?{|
          +city: string,
          +street: string,
          +number: string,
        |},
        +tenants: $ReadOnlyArray<?{|
          +id: ?string,
          +firstname: string,
          +lastname: string,
        |}>,
        +registrations: $ReadOnlyArray<?{|
          +id: string,
          +actionPosition: ?{|
            +id: string,
            +name: ?string,
          |},
        |}>,
        +type: UsageUnitTypeType,
        +billingPartitions: $ReadOnlyArray<?{|
          +id: string,
          +name: string,
        |}>,
        +CAN: ?{|
          +one: string,
          +two: string,
          +three: string,
          +four: string,
          +five: string,
        |},
      |}>
    |}
  |}
|};
export type Filter1NotEquippedUsageUnitsQuery = {|
  variables: Filter1NotEquippedUsageUnitsQueryVariables,
  response: Filter1NotEquippedUsageUnitsQueryResponse,
|};
*/


/*
query Filter1NotEquippedUsageUnitsQuery(
  $companyId: ID!
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
) {
  Me {
    Company(id: $companyId) {
      data: UsageUnits(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}, context: RecentRegularAppointment) {
        id
        lockPeriods(options: {startRow: 0, endRow: 0, sorting: {colId: "begin", sort: "DESC"}, filters: [{filterType: text, operator: AND, name: "status", conditions: [{type: equals, value: ["ACTIVE"]}]}, {filterType: text, operator: AND, name: "reason", conditions: [{type: equals, value: ["TEMP_FOR_RENOVATION", "TEMP_FOR_CLEARIFICATION", "CUSTOMER_TEMP_DEFERRED_INSTALLATION"]}]}]}) {
          id
          begin
          status
          reason
        }
        appointments(options: {startRow: 0, endRow: 0, sorting: [{colId: "date", sort: "DESC"}], filters: [{filterType: text, operator: AND, name: "type", conditions: [{type: equals, value: ["T1", "T2", "TI"]}]}]}) {
          id
          type
          status
          accessReason
          executionDate
        }
        referenceNumber
        tableId
        address {
          city
          street
          number
        }
        tenants {
          id
          firstname
          lastname
        }
        registrations {
          id
          actionPosition {
            id
            name
          }
        }
        type
        billingPartitions {
          id
          name
        }
        CAN {
          one
          two
          three
          four
          five
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v9 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v10 = {
  "alias": "data",
  "args": [
    {
      "kind": "Literal",
      "name": "context",
      "value": "RecentRegularAppointment"
    },
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "endRow",
          "variableName": "endRow"
        },
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "sorting",
          "variableName": "sortModel"
        },
        {
          "kind": "Variable",
          "name": "startRow",
          "variableName": "startRow"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "concreteType": "UsageUnit",
  "kind": "LinkedField",
  "name": "UsageUnits",
  "plural": true,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "options",
          "value": {
            "endRow": 0,
            "filters": [
              {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "ACTIVE"
                    ]
                  }
                ],
                "filterType": "text",
                "name": "status",
                "operator": "AND"
              },
              {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "TEMP_FOR_RENOVATION",
                      "TEMP_FOR_CLEARIFICATION",
                      "CUSTOMER_TEMP_DEFERRED_INSTALLATION"
                    ]
                  }
                ],
                "filterType": "text",
                "name": "reason",
                "operator": "AND"
              }
            ],
            "sorting": {
              "colId": "begin",
              "sort": "DESC"
            },
            "startRow": 0
          }
        }
      ],
      "concreteType": "LockPeriod",
      "kind": "LinkedField",
      "name": "lockPeriods",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "begin",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "reason",
          "storageKey": null
        }
      ],
      "storageKey": "lockPeriods(options:{\"endRow\":0,\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"ACTIVE\"]}],\"filterType\":\"text\",\"name\":\"status\",\"operator\":\"AND\"},{\"conditions\":[{\"type\":\"equals\",\"value\":[\"TEMP_FOR_RENOVATION\",\"TEMP_FOR_CLEARIFICATION\",\"CUSTOMER_TEMP_DEFERRED_INSTALLATION\"]}],\"filterType\":\"text\",\"name\":\"reason\",\"operator\":\"AND\"}],\"sorting\":{\"colId\":\"begin\",\"sort\":\"DESC\"},\"startRow\":0})"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "options",
          "value": {
            "endRow": 0,
            "filters": [
              {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "T1",
                      "T2",
                      "TI"
                    ]
                  }
                ],
                "filterType": "text",
                "name": "type",
                "operator": "AND"
              }
            ],
            "sorting": [
              {
                "colId": "date",
                "sort": "DESC"
              }
            ],
            "startRow": 0
          }
        }
      ],
      "concreteType": "Appointment",
      "kind": "LinkedField",
      "name": "appointments",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        (v8/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "executionDate",
          "storageKey": null
        }
      ],
      "storageKey": "appointments(options:{\"endRow\":0,\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"T1\",\"T2\",\"TI\"]}],\"filterType\":\"text\",\"name\":\"type\",\"operator\":\"AND\"}],\"sorting\":[{\"colId\":\"date\",\"sort\":\"DESC\"}],\"startRow\":0})"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referenceNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tableId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenants",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastname",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Registration",
      "kind": "LinkedField",
      "name": "registrations",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ActionPosition",
          "kind": "LinkedField",
          "name": "actionPosition",
          "plural": false,
          "selections": (v9/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "BillingPartition",
      "kind": "LinkedField",
      "name": "billingPartitions",
      "plural": true,
      "selections": (v9/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAllocationNumber",
      "kind": "LinkedField",
      "name": "CAN",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "one",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "two",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "three",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "four",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "five",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Filter1NotEquippedUsageUnitsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "Filter1NotEquippedUsageUnitsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "32476ed9e23c1f437cdae31862d928d6",
    "id": null,
    "metadata": {},
    "name": "Filter1NotEquippedUsageUnitsQuery",
    "operationKind": "query",
    "text": "query Filter1NotEquippedUsageUnitsQuery(\n  $companyId: ID!\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n) {\n  Me {\n    Company(id: $companyId) {\n      data: UsageUnits(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}, context: RecentRegularAppointment) {\n        id\n        lockPeriods(options: {startRow: 0, endRow: 0, sorting: {colId: \"begin\", sort: \"DESC\"}, filters: [{filterType: text, operator: AND, name: \"status\", conditions: [{type: equals, value: [\"ACTIVE\"]}]}, {filterType: text, operator: AND, name: \"reason\", conditions: [{type: equals, value: [\"TEMP_FOR_RENOVATION\", \"TEMP_FOR_CLEARIFICATION\", \"CUSTOMER_TEMP_DEFERRED_INSTALLATION\"]}]}]}) {\n          id\n          begin\n          status\n          reason\n        }\n        appointments(options: {startRow: 0, endRow: 0, sorting: [{colId: \"date\", sort: \"DESC\"}], filters: [{filterType: text, operator: AND, name: \"type\", conditions: [{type: equals, value: [\"T1\", \"T2\", \"TI\"]}]}]}) {\n          id\n          type\n          status\n          accessReason\n          executionDate\n        }\n        referenceNumber\n        tableId\n        address {\n          city\n          street\n          number\n        }\n        tenants {\n          id\n          firstname\n          lastname\n        }\n        registrations {\n          id\n          actionPosition {\n            id\n            name\n          }\n        }\n        type\n        billingPartitions {\n          id\n          name\n        }\n        CAN {\n          one\n          two\n          three\n          four\n          five\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9b0f4ec96834a90f0f93d1687e19cbf1';

module.exports = node;
