export const availableVariables = [
  {
    id: 'departmentName',
    name: 'departmentName',
    value: '{{departmentName}}',
    label: 'DEPARTMENT NAME',
  },
  {
    id: 'departmentEmail',
    name: 'departmentEmail',
    value: '{{departmentEmail}}',
    label: 'DEPARTMENT EMAIL',
  },
  {
    id: 'departmentPhone',
    name: 'departmentPhone',
    value: '{{departmentPhone}}',
    label: 'DEPARTMENT PHONE',
  },
  {
    id: 'creatorFullName',
    name: 'creatorFullName',
    value: '{{creatorFullName}}',
    label: 'CREATOR NAME',
  },
  {
    id: 'creatorEmail',
    name: 'creatorEmail',
    value: '{{creatorEmail}}',
    label: 'CREATOR EMAIL',
  },
  {
    id: 'creatorPhone',
    name: 'creatorPhone',
    value: '{{creatorPhone}}',
    label: 'CREATOR PHONE',
  },
  {
    id: 'sentDate',
    name: 'sentDate',
    value: '{{sentDate}}',
    label: 'SENT DATE',
  },
  {
    id: 'tenantNumber',
    name: 'tenantNumber',
    value: '{{tenantNumber}}',
    label: 'TENANT NUMBER',
  },
]

export const department = [
  {
    id: 'departmentName',
    name: 'departmentName',
    value: '{{departmentName}}',
    label: 'DEPARTMENT NAME',
  },
  {
    id: 'departmentEmail',
    name: 'departmentEmail',
    value: '{{departmentEmail}}',
    label: 'DEPARTMENT EMAIL',
  },
  {
    id: 'departmentPhone',
    name: 'departmentPhone',
    value: '{{departmentPhone}}',
    label: 'DEPARTMENT PHONE',
  },
]

export const letterCreator = [
  {
    id: 'creatorFullName',
    name: 'creatorFullName',
    value: '{{creatorFullName}}',
    label: 'CREATOR NAME',
  },
  {
    id: 'creatorEmail',
    name: 'creatorEmail',
    value: '{{creatorEmail}}',
    label: 'CREATOR EMAIL',
  },
  {
    id: 'creatorPhone',
    name: 'creatorPhone',
    value: '{{creatorPhone}}',
    label: 'CREATOR PHONE',
  },
]

export const letter = [
  {
    id: 'sentDate',
    name: 'sentDate',
    value: '{{sentDate}}',
    label: 'SENT DATE',
  },
]
//
// export const massLetter = [
//   {
//     id: 'creatorFullName',
//     name: 'creatorFullName',
//     value: '{{creatorFullName}}',
//     label: 'CREATOR NAME',
//   },
//   {
//     id: 'KZN',
//     name: 'KZN',
//     value: '{{KZN}}',
//     label: 'KZN',
//   },
//   {
//     id: 'testvariable1',
//     name: 'testvariable1',
//     value: '{{testvariable1}}',
//     label: 'testvariable1',
//   },
//   {
//     id: 'tenantNumber',
//     name: 'tenantNumber',
//     value: '{{tenantNumber}}',
//     label: 'TENANT NUMBER',
//   },
//   {
//     id: 'dear',
//     name: 'dear',
//     value: '{{dear}}',
//     label: 'DEAR',
//   },
//   {
//     id: 'firma',
//     name: 'firma',
//     value: '{{firma}}',
//     label: 'FIRMA',
//   },
// ]
