import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import {
  formatUserSalutationEN,
  formatUserSalutationDE,
} from './utils/formatUserSalutation'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-flow: column nowrap;
`

const UserSalutation = ({ user, currentLanguage = 'de' }) => {
  const { t } = useTranslation()

  const translateSalutation = (salutation = '') => {
    switch (salutation) {
      case 'MR':
        return t('MR.')
      case 'MRS':
        return t('MRS.')
      case 'COMPANY':
        return t('COMPANY')
      case 'NONE':
      case '':
        return ''
      default:
        return salutation
    }
  }

  const translateTitle = (title = '') => {
    switch (title) {
      case 'DR':
        return t('DR.')
      case 'PROF':
        return t('PROF.')
      case 'PROF_DR':
        return t('PROF. DR.')
      case 'NONE':
      case '':
        return ''
      default:
        return title
    }
  }

  const selectUserSalutation = ({ currentLanguage, ...params }) => {
    switch (currentLanguage) {
      case 'en':
        return formatUserSalutationEN({ ...params })
      case 'de':
      default:
        return formatUserSalutationDE({ ...params })
    }
  }

  const userSalutation = selectUserSalutation({
    currentLanguage,
    salutation: translateSalutation(user?.salutation),
    title: translateTitle(user?.title),
    firstname: user?.firstname,
    lastname: user?.lastname,
  })

  return (
    <Wrapper>
      <span>{t('HELLO')}</span>
      <span>{userSalutation}</span>
    </Wrapper>
  )
}

UserSalutation.propTypes = {
  user: PropTypes.shape({
    salutation: PropTypes.string,
    title: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }).isRequired,
  currentLanguage: PropTypes.string,
}

export default UserSalutation
