import React from 'react'
import { useTranslation } from 'react-i18next'
import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'
import { AppointmentAccessReasonMap } from '../../../constants/colorMap'

export default ({ accessReason }) => {
  const { t } = useTranslation()

  const translateLabel = (value) => {
    switch (value) {
      case 'ACCESS':
        return t('ACCESS')

      default:
        return ''
    }
  }

  return (
    <StatusLightWithLabel
      status={AppointmentAccessReasonMap[accessReason]}
      label={translateLabel(accessReason)}
    />
  )
}
