import React from 'react'
import PropTypes from 'prop-types'
import { AgGridColumn } from 'ag-grid-react'
import { useTranslation } from 'react-i18next'

import BaseGrid from './BaseGrid'

const BillingPartitionsGrid = ({
  rowData = [],
  currentData = [],
  selectedData = [],
  handleSelectionChange = () => {},
}) => {
  const { t } = useTranslation()

  return (
    <BaseGrid
      id='billingPartitions'
      title={t('LIMIT TO SPECIFIC CONTRACT PARTITIONS')}
      rowData={rowData}
      currentData={currentData}
      selectedData={selectedData}
      handleSelectionChange={handleSelectionChange}
    >
      <AgGridColumn
        headerName={t('PYREXX CONTRACT PARTITION ID')}
        field='tableId'
        flex='1'
      />
      <AgGridColumn headerName={t('PARTITION NAME')} field='name' flex='1' />
      <AgGridColumn
        headerName={t('PYREXX CONTRACT ID')}
        field='contractId'
        flex='1'
        // valueFormatter={({ value }) => 'v_' + value}
      />
    </BaseGrid>
  )
}

BillingPartitionsGrid.propTypes = {
  currentData: PropTypes.array,
  handleSelectionChange: PropTypes.func,
  rowData: PropTypes.array,
  selectedData: PropTypes.array,
}

export default BillingPartitionsGrid
