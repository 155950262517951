import React from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import { Col, Container, Row } from 'react-grid-system'
import { Icon, SimpleNote } from 'pyrexx-react-library'
import { useUserContext } from '../../helper/userContext'
import { Routes } from '../../configs/RouteConfig'
import { useLocation } from 'react-router-dom'
import NavBack from '../../components/NavBack'
import DefaultLayoutIcon from './DefaultLayoutIcon.png'
import SimpleLink from '../../components/Simple/SimpleLink'

const CreateCompanyLayout = () => {
  const { t } = useTranslation()
  const { currentLanguage } = useUserContext()
  const location = useLocation()

  return (
    <PageContainer>
      <NavBack
        title={t('CREATE BUSINESS POST LAYOUT')}
        to={'/' + currentLanguage + Routes.admin + Routes.companyLayouts}
      />
      <Container
        style={{
          backgroundColor: 'white',
          padding: '20px',
        }}
      >
        <Row>
          <Col
            style={{
              fontSize: '12px',
            }}
          >
            {t('COMPANY LAYOUTS')}
          </Col>
        </Row>
        <Row>
          <Col xs='content' style={{ maxWidth: '250px' }}>
            <SimpleNote
              text={t('CURRENTLY WE ARE PROVIDING ONE LETTER LAYOUT')}
            />
          </Col>
          <Col xs='content' style={{ marginLeft: '10px', maxWidth: '130px' }}>
            <img
              src={DefaultLayoutIcon}
              style={{ width: '130px' }}
              alt='icon'
            />
            <div
              style={{
                textAlign: 'center',
                fontSize: '12px',
                paddingLeft: '10px',
                paddingRight: '10px',
                width: '130px',
              }}
            >
              {t('DEFAULT LETTER LAYOUT')}
            </div>
          </Col>
          <Col xs='content'>
            <SimpleLink
              to={{
                pathname:
                  '/' +
                  currentLanguage +
                  Routes.admin +
                  Routes.createCompanyLayoutAdd,
                state: {
                  from: location.pathname,
                },
              }}
              style={{ fontSize: 'inherit', color: 'black' }}
            >
              <Row nogutter>
                <Col xs='content'>
                  <Icon icon='edit' size={16} style={{ color: 'black' }} />
                </Col>
                <Col style={{ paddingLeft: '5px' }} xs='content'>
                  {t('CREATE LAYOUT')}{' '}
                </Col>
              </Row>
            </SimpleLink>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  )
}

export default CreateCompanyLayout
