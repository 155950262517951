import React, { useCallback, useContext, useState } from 'react'
import styled from 'styled-components/macro'

import { Icon, LoadingSpinner, portalTheme } from 'pyrexx-react-library'
import UserContext from '../../../../../helper/userContext'
import { LinkButtonStyled } from '../../../../../styles/HelperStylesComponents.styles'
import { DeleteAttachmentMutation } from '../mutations'
import { Col, Row } from 'react-grid-system'

const StyledTrashIcon = styled(Icon)`
  color: ${portalTheme.color.errorColor};
`

const DeleteAttachmentRenderer = (props) => {
  const { value = {}, getLetterAttachments } = props
  const { user } = useContext(UserContext)

  const [deleteLoading, setDeleteLoading] = useState(false)

  const deleteAttachment = useCallback(
    (tableId) => {
      setDeleteLoading(true)
      DeleteAttachmentMutation(
        {
          companyId: user.companyId,
          fileId: value,
        },
        user.accessToken,
        (data) => {
          getLetterAttachments()
        },
        (data) => {
          setDeleteLoading(false)
        }
      )
    },
    [getLetterAttachments, user.accessToken, user.companyId, value]
  )

  return (
    <Row>
      <Col>
        {deleteLoading ? (
          <LoadingSpinner
            theme={{
              components: {
                spinner: {
                  /**
                   * Has to be a { css } oject from styled-components
                   */
                  fontSize: portalTheme.font.size.bodySmall,
                  fontWeight: portalTheme.font.weight.regular,
                  size: '20px',
                },
              },
            }}
            style={{
              position: 'unset',
              backgroundColor: 'white',
            }}
          />
        ) : (
          <LinkButtonStyled
            style={{ textAlign: 'left' }}
            type='button'
            onClick={() => {
              deleteAttachment(value)
            }}
          >
            <AnchorStyled>
              <StyledTrashIcon icon='trash' size={20} />
            </AnchorStyled>
          </LinkButtonStyled>
        )}
      </Col>
    </Row>
  )
}

export default DeleteAttachmentRenderer

const AnchorStyled = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`
