/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type LockReasonType = "CONTRACT_CANCELLED" | "CONTRACT_EXPIRED" | "CUSTOMER_TEMP_DEFERRED_INSTALLATION" | "CUSTOMER_WISH" | "DECENTRAL_WARM_WATER" | "HIGH_CEILING" | "LOCKED_TIL_INDIVIDUAL_ORDER" | "MODERNIZATION" | "NONE" | "OWN_SMOKE_DETECTOR" | "SMOKE_DETECTORS_IN_SAFE_KEEPING" | "SMOKE_DETECTORS_NOT_USEFUL" | "SYSTEM" | "TEMP_FOR_CLEARIFICATION" | "TEMP_FOR_RENOVATION" | "TENANT_REJECTED_SMOKE_DETECTORS" | "UNEXPECTED_BUSINESS_UNIT" | "UNIT_DEMOLITION" | "UNIT_DOESNT_EXIST" | "UNIT_SOLD" | "UNKNOWN" | "%future added value";
export type LockStatusType = "ACTIVE" | "DEACTIVATED" | "NONE" | "PENDING" | "%future added value";
export type LockTypeType = "COMPLETELY_LOCKED" | "NOT_LOCKED" | "PARTLY_LOCKED" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type UsageUnitTypeType = "BUSINESS" | "COMMON" | "NONE" | "PRIVATE" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type PropertyManagementUsageUnitGridQueryVariables = {|
  companyId: string,
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
|};
export type PropertyManagementUsageUnitGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +data: ?$ReadOnlyArray<?{|
        +id: string,
        +tableId: number,
        +type: UsageUnitTypeType,
        +referenceNumber: string,
        +address: ?{|
          +street: string,
          +number: string,
          +zip: string,
          +city: string,
        |},
        +lockPeriods: $ReadOnlyArray<?{|
          +id: string,
          +status: LockStatusType,
          +begin: ?any,
          +end: ?any,
          +reason: LockReasonType,
        |}>,
        +inventory: $ReadOnlyArray<?{|
          +id: ?string,
          +quantity: ?number,
        |}>,
        +billingPartitions: $ReadOnlyArray<?{|
          +name: string,
          +id: string,
        |}>,
        +lockStatus: ?LockTypeType,
        +CAN: ?{|
          +one: string,
          +two: string,
          +three: string,
          +four: string,
          +five: string,
        |},
      |}>,
      +id: string,
    |},
    +id: string,
  |}
|};
export type PropertyManagementUsageUnitGridQuery = {|
  variables: PropertyManagementUsageUnitGridQueryVariables,
  response: PropertyManagementUsageUnitGridQueryResponse,
|};
*/


/*
query PropertyManagementUsageUnitGridQuery(
  $companyId: ID!
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
) {
  Me {
    Company(id: $companyId) {
      data: UsageUnits(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
        id
        tableId
        type
        referenceNumber
        address {
          street
          number
          zip
          city
        }
        lockPeriods(options: {startRow: 0, endRow: 0, sorting: [{colId: "begin", sort: "DESC"}], filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: "ACTIVE", type: equals}]}]}) {
          id
          status
          begin
          end
          reason
        }
        inventory {
          id
          quantity
        }
        billingPartitions {
          name
          id
        }
        lockStatus
        CAN {
          one
          two
          three
          four
          five
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": "data",
            "args": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "endRow",
                    "variableName": "endRow"
                  },
                  {
                    "kind": "Variable",
                    "name": "filters",
                    "variableName": "filters"
                  },
                  {
                    "kind": "Variable",
                    "name": "sorting",
                    "variableName": "sortModel"
                  },
                  {
                    "kind": "Variable",
                    "name": "startRow",
                    "variableName": "startRow"
                  }
                ],
                "kind": "ObjectValue",
                "name": "options"
              }
            ],
            "concreteType": "UsageUnit",
            "kind": "LinkedField",
            "name": "UsageUnits",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tableId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referenceNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "street",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "zip",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "options",
                    "value": {
                      "endRow": 0,
                      "filters": [
                        {
                          "conditions": [
                            {
                              "type": "equals",
                              "value": "ACTIVE"
                            }
                          ],
                          "filterType": "text",
                          "name": "status",
                          "operator": "AND"
                        }
                      ],
                      "sorting": [
                        {
                          "colId": "begin",
                          "sort": "DESC"
                        }
                      ],
                      "startRow": 0
                    }
                  }
                ],
                "concreteType": "LockPeriod",
                "kind": "LinkedField",
                "name": "lockPeriods",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "begin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "end",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "reason",
                    "storageKey": null
                  }
                ],
                "storageKey": "lockPeriods(options:{\"endRow\":0,\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":\"ACTIVE\"}],\"filterType\":\"text\",\"name\":\"status\",\"operator\":\"AND\"}],\"sorting\":[{\"colId\":\"begin\",\"sort\":\"DESC\"}],\"startRow\":0})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "inventory",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quantity",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingPartition",
                "kind": "LinkedField",
                "name": "billingPartitions",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lockStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerAllocationNumber",
                "kind": "LinkedField",
                "name": "CAN",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "one",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "two",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "three",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "four",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "five",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyManagementUsageUnitGridQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "PropertyManagementUsageUnitGridQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "aca3bf3b3efb6dfa3bea7415ea604e72",
    "id": null,
    "metadata": {},
    "name": "PropertyManagementUsageUnitGridQuery",
    "operationKind": "query",
    "text": "query PropertyManagementUsageUnitGridQuery(\n  $companyId: ID!\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n) {\n  Me {\n    Company(id: $companyId) {\n      data: UsageUnits(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n        id\n        tableId\n        type\n        referenceNumber\n        address {\n          street\n          number\n          zip\n          city\n        }\n        lockPeriods(options: {startRow: 0, endRow: 0, sorting: [{colId: \"begin\", sort: \"DESC\"}], filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: \"ACTIVE\", type: equals}]}]}) {\n          id\n          status\n          begin\n          end\n          reason\n        }\n        inventory {\n          id\n          quantity\n        }\n        billingPartitions {\n          name\n          id\n        }\n        lockStatus\n        CAN {\n          one\n          two\n          three\n          four\n          five\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '454b617ff6da4e5fa6392221ad001e6b';

module.exports = node;
