import React from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../../components/PageContainer'
import { Col, Container, Row } from 'react-grid-system'
import { useUserContext } from '../../../helper/userContext'
import { Routes } from '../../../configs/RouteConfig'
import NavBack from '../../../components/NavBack'
import { LoadingSpinner, portalTheme } from 'pyrexx-react-library'
import QueryRendererContainer from '../../../components/QueryRendererContainer'
import { useParams } from 'react-router-dom'
import { LetterQuery } from './queries'
import Letter from './Letter'

const LetterContainer = (props) => {
  const { t } = useTranslation()
  const { user, currentLanguage } = useUserContext()
  const { id } = useParams()

  return (
    <PageContainer>
      <NavBack
        title={t('TECHNICAL VIEW OF AN LETTER')}
        to={
          '/' + currentLanguage + Routes.businessMail + Routes.businessMailsGrid
        }
      />
      <Container>
        <Row>
          <Col>
            <QueryRendererContainer
              query={LetterQuery}
              variables={{
                companyId: user.companyId,
                letterId: id,
              }}
              loading={
                <div style={{ height: '30px', width: '30px' }}>
                  <LoadingSpinner
                    theme={{
                      components: {
                        spinner: {
                          /**
                           * Has to be a { css } oject from styled-components
                           */
                          fontSize: portalTheme.font.size.bodySmall,
                          fontWeight: portalTheme.font.weight.regular,
                          size: '20px',
                        },
                      },
                    }}
                    style={{
                      position: 'unset',
                      backgroundColor: 'white',
                    }}
                  />
                </div>
              }
              render={(data) => {
                const letter = data?.Me?.Company?.businessPost?.letter
                return <Letter letterObject={letter} />
              }}
            />
          </Col>
        </Row>
      </Container>
    </PageContainer>
  )
}

export default LetterContainer
