import styled from 'styled-components/macro'
import { Col as C, Row as R, Container as Cont } from 'react-grid-system'
import { portalTheme } from 'pyrexx-react-library'

const Row = styled(R).attrs((props) => ({
  align: 'center',
  ...props,
}))``

export const DataRow = styled(Row)`
  padding: 24px 32px;

  background-color: #fff;

  &:nth-child(odd):not(:first-child) {
    background-color: #f8f8f8;
  }
`

export const TitleRow = styled(Row)`
  background-color: #fff;
  padding: 16px 32px;
  margin-bottom: 8px;

  font-size: 13px;
  font-weight: ${portalTheme.font.weight.bold};
`

export const Container = styled(Cont)``
export const Col = styled(C)``
