export const columnCheckbox = {
  headerName: '',
  field: 'checkbox',
  checkboxSelection: true,
  width: 50,
  sortable: false,
  menuTabs: [],
  suppressSizeToFit: true,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
}

export const withHiddenParams = {
  hide: true,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
}

export const withFilterParams = {
  filterParams: {
    buttons: ['apply', 'clear', 'reset'],
    closeOnApply: true,
    suppressAndOrCondition: true,
  },
}

export const statusBarParams = {
  statusBar: {
    statusPanels: [
      // {
      //   statusPanel: 'agTotalAndFilteredRowCountComponent',
      //   align: 'left',
      // },
      // {
      //   statusPanel: 'agTotalRowCountComponent',
      //   align: 'center',
      // },
      // { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      { statusPanel: 'agAggregationComponent' },
    ],
  },
}
