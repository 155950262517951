import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query GridFiltersQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        GridFilters(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          grid
          filter
          settings
        }
      }
    }
  }
`
