import { useTranslation } from 'react-i18next'
import { appointmentTypeEnumList } from '../enumLists'

const AppointmentTypeRendererHelper = (props) => {
  const { value } = props
  const { t } = useTranslation()

  if (!value) {
    return ''
  }

  return t(appointmentTypeEnumList(value))
}

export default AppointmentTypeRendererHelper
