/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSalutationType = "COMPANY" | "MR" | "MRS" | "NONE" | "%future added value";
export type UserTitleType = "DR" | "NONE" | "PROF" | "PROF_DR" | "%future added value";
export type UpdateLetterMutationVariables = {|
  companyId: string,
  letterId: number,
  layout: string,
  name: string,
  recipient1Title: UserTitleType,
  recipient1Salutation: UserSalutationType,
  recipient1FirstName: string,
  recipient1LastName: string,
  recipient2Title: UserTitleType,
  recipient2Salutation: UserSalutationType,
  recipient2FirstName: string,
  recipient2LastName: string,
  recipientCompanyName: string,
  streetAndNumber: string,
  additionalAddressInfo: string,
  zip: string,
  city: string,
  country: string,
|};
export type UpdateLetterMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +updateLetter: ?{|
          +id: string,
          +tableId: number,
          +layout: string,
          +pageCount: number,
          +preview: ?string,
        |}
      |}
    |}
  |}
|};
export type UpdateLetterMutation = {|
  variables: UpdateLetterMutationVariables,
  response: UpdateLetterMutationResponse,
|};
*/


/*
mutation UpdateLetterMutation(
  $companyId: ID!
  $letterId: Int!
  $layout: String!
  $name: String!
  $recipient1Title: UserTitleType!
  $recipient1Salutation: UserSalutationType!
  $recipient1FirstName: String!
  $recipient1LastName: String!
  $recipient2Title: UserTitleType!
  $recipient2Salutation: UserSalutationType!
  $recipient2FirstName: String!
  $recipient2LastName: String!
  $recipientCompanyName: String!
  $streetAndNumber: String!
  $additionalAddressInfo: String!
  $zip: String!
  $city: String!
  $country: String!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        updateLetter(id: $letterId, layout: $layout, internalName: $name, recipient1Title: $recipient1Title, recipient1Salutation: $recipient1Salutation, recipient1FirstName: $recipient1FirstName, recipient1LastName: $recipient1LastName, recipient2Title: $recipient2Title, recipient2Salutation: $recipient2Salutation, recipient2FirstName: $recipient2FirstName, recipient2LastName: $recipient2LastName, recipientCompanyName: $recipientCompanyName, streetAndNumber: $streetAndNumber, additionalAddressInfo: $additionalAddressInfo, zip: $zip, city: $city, country: $country) {
          id
          tableId
          layout
          pageCount
          preview
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "additionalAddressInfo"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "city"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "country"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "layout"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "letterId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipient1FirstName"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipient1LastName"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipient1Salutation"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipient1Title"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipient2FirstName"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipient2LastName"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipient2Salutation"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipient2Title"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recipientCompanyName"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "streetAndNumber"
},
v17 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "zip"
},
v18 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessPostMutation",
            "kind": "LinkedField",
            "name": "businessPost",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "additionalAddressInfo",
                    "variableName": "additionalAddressInfo"
                  },
                  {
                    "kind": "Variable",
                    "name": "city",
                    "variableName": "city"
                  },
                  {
                    "kind": "Variable",
                    "name": "country",
                    "variableName": "country"
                  },
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "letterId"
                  },
                  {
                    "kind": "Variable",
                    "name": "internalName",
                    "variableName": "name"
                  },
                  {
                    "kind": "Variable",
                    "name": "layout",
                    "variableName": "layout"
                  },
                  {
                    "kind": "Variable",
                    "name": "recipient1FirstName",
                    "variableName": "recipient1FirstName"
                  },
                  {
                    "kind": "Variable",
                    "name": "recipient1LastName",
                    "variableName": "recipient1LastName"
                  },
                  {
                    "kind": "Variable",
                    "name": "recipient1Salutation",
                    "variableName": "recipient1Salutation"
                  },
                  {
                    "kind": "Variable",
                    "name": "recipient1Title",
                    "variableName": "recipient1Title"
                  },
                  {
                    "kind": "Variable",
                    "name": "recipient2FirstName",
                    "variableName": "recipient2FirstName"
                  },
                  {
                    "kind": "Variable",
                    "name": "recipient2LastName",
                    "variableName": "recipient2LastName"
                  },
                  {
                    "kind": "Variable",
                    "name": "recipient2Salutation",
                    "variableName": "recipient2Salutation"
                  },
                  {
                    "kind": "Variable",
                    "name": "recipient2Title",
                    "variableName": "recipient2Title"
                  },
                  {
                    "kind": "Variable",
                    "name": "recipientCompanyName",
                    "variableName": "recipientCompanyName"
                  },
                  {
                    "kind": "Variable",
                    "name": "streetAndNumber",
                    "variableName": "streetAndNumber"
                  },
                  {
                    "kind": "Variable",
                    "name": "zip",
                    "variableName": "zip"
                  }
                ],
                "concreteType": "Letter",
                "kind": "LinkedField",
                "name": "updateLetter",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tableId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "layout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "pageCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "preview",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v17/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateLetterMutation",
    "selections": (v18/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v10/*: any*/),
      (v9/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v14/*: any*/),
      (v13/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/),
      (v0/*: any*/),
      (v17/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateLetterMutation",
    "selections": (v18/*: any*/)
  },
  "params": {
    "cacheID": "6fdd906d910af2e006fd78486adcf5a4",
    "id": null,
    "metadata": {},
    "name": "UpdateLetterMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateLetterMutation(\n  $companyId: ID!\n  $letterId: Int!\n  $layout: String!\n  $name: String!\n  $recipient1Title: UserTitleType!\n  $recipient1Salutation: UserSalutationType!\n  $recipient1FirstName: String!\n  $recipient1LastName: String!\n  $recipient2Title: UserTitleType!\n  $recipient2Salutation: UserSalutationType!\n  $recipient2FirstName: String!\n  $recipient2LastName: String!\n  $recipientCompanyName: String!\n  $streetAndNumber: String!\n  $additionalAddressInfo: String!\n  $zip: String!\n  $city: String!\n  $country: String!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        updateLetter(id: $letterId, layout: $layout, internalName: $name, recipient1Title: $recipient1Title, recipient1Salutation: $recipient1Salutation, recipient1FirstName: $recipient1FirstName, recipient1LastName: $recipient1LastName, recipient2Title: $recipient2Title, recipient2Salutation: $recipient2Salutation, recipient2FirstName: $recipient2FirstName, recipient2LastName: $recipient2LastName, recipientCompanyName: $recipientCompanyName, streetAndNumber: $streetAndNumber, additionalAddressInfo: $additionalAddressInfo, zip: $zip, city: $city, country: $country) {\n          id\n          tableId\n          layout\n          pageCount\n          preview\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1260e060a69c2d336eb2ac519373d27f';

module.exports = node;
