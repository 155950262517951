import React from 'react'
import { DateTimeRenderer } from '../../../components/AgGrid/renderers'

const NotEquippedUsageUnitAppointmentAccessDateRenderer = (props) => {
  const { value } = props
  if (!value || !value.length > 0) {
    return ''
  }
  return <DateTimeRenderer value={value[0].executionDate} />
}

export default NotEquippedUsageUnitAppointmentAccessDateRenderer
