/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RestoreAccountRequestMutationVariables = {|
  username: string
|};
export type RestoreAccountRequestMutationResponse = {|
  +restoreAccountRequest: ?boolean
|};
export type RestoreAccountRequestMutation = {|
  variables: RestoreAccountRequestMutationVariables,
  response: RestoreAccountRequestMutationResponse,
|};
*/


/*
mutation RestoreAccountRequestMutation(
  $username: String!
) {
  restoreAccountRequest(username: $username)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "kind": "ScalarField",
    "name": "restoreAccountRequest",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RestoreAccountRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RestoreAccountRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "81c770761a336d40e8805843878cfb0b",
    "id": null,
    "metadata": {},
    "name": "RestoreAccountRequestMutation",
    "operationKind": "mutation",
    "text": "mutation RestoreAccountRequestMutation(\n  $username: String!\n) {\n  restoreAccountRequest(username: $username)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8e3246f48c91908411ab8220f7ea2cf3';

module.exports = node;
