import React from 'react'
import AppointmentAccessReasonRendererHelper from '../../../components/AgGrid/RendererHelper/AppointmentAccessReasonRendererHelper'

const AppointmentServiceAccessRenderer = (props) => {
  const { value } = props

  return <AppointmentAccessReasonRendererHelper value={value} />
}

export default AppointmentServiceAccessRenderer
