/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReleaseQuotaMutationVariables = {|
  companyId: string,
  appointmentId: string,
|};
export type ReleaseQuotaMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +releaseFromQuota: ?number
    |}
  |}
|};
export type ReleaseQuotaMutation = {|
  variables: ReleaseQuotaMutationVariables,
  response: ReleaseQuotaMutationResponse,
|};
*/


/*
mutation ReleaseQuotaMutation(
  $companyId: ID!
  $appointmentId: ID!
) {
  Me {
    Company(id: $companyId) {
      releaseFromQuota(appointmentId: $appointmentId)
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appointmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "appointmentId",
                "variableName": "appointmentId"
              }
            ],
            "kind": "ScalarField",
            "name": "releaseFromQuota",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReleaseQuotaMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReleaseQuotaMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "805ec8d7102abc073949b47aec031720",
    "id": null,
    "metadata": {},
    "name": "ReleaseQuotaMutation",
    "operationKind": "mutation",
    "text": "mutation ReleaseQuotaMutation(\n  $companyId: ID!\n  $appointmentId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      releaseFromQuota(appointmentId: $appointmentId)\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f0110be92d693f7928b22d74b835825d';

module.exports = node;
