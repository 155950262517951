import React, { useState } from 'react'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { withTranslation } from 'react-i18next'

import AddNewsletterSubscription from '../../mutations/AddNewsletterSubscription'
import { ButtonPrimary, SimpleNote, StyledInput } from 'pyrexx-react-library'

const NewsletterForm = (props) => {
  const [submitStatus, setSubmitStatus] = useState({
    status: false,
    message: '',
  })

  const schema = Yup.object({
    newsletterSubscriptionEmail: Yup.string()
      .email()
      .required(props.t('WE NEED A CONTACT EMAIL')),
  })

  const formik = useFormik({
    initialValues: {
      newsletterSubscriptionEmail: '',
    },
    validationSchema: schema,
    onSubmit: (values, formikb) => {
      AddNewsletterSubscription(
        schema.cast(values),
        (success) => {
          if (success.addNewsletterSubscription.success) {
            setSubmitStatus({
              status: 'success',
              // eslint-disable-next-line
              // prettier-ignore
              message: props.t('NEWSLETTER SUSCRIPTION REQUEST WAS SUCCESSFULLY SENT'),
            })
            formikb.resetForm()
          } else {
            setSubmitStatus({
              status: 'error',
              // eslint-disable-next-line
              // prettier-ignore
              message: props.t('AN UNEXPECTED ERROR OCCURED, PLEASE TRY AGAIN OR CALL THE SUPPORT'),
            })
          }
        },
        // eslint-disable-next-line handle-callback-err
        (error) => {
          setSubmitStatus({
            status: 'error',
            // eslint-disable-next-line
            // prettier-ignore
            message: props.t('AN UNEXPECTED ERROR OCCURED, PLEASE TRY AGAIN OR CALL THE SUPPORT'),
          })
        }
      )
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row justify='center'>
        <Col xs={12}>
          <StyledInput
            type='input'
            name='newsletterSubscriptionEmail'
            id='newsletterSubscriptionEmail'
            onChange={formik.handleChange}
            value={formik.values.newsletterSubscriptionEmail}
            label={props.t('EMAIL')}
            feedbackMessage={
              formik.touched.newsletterSubscriptionEmail &&
              formik.errors.newsletterSubscriptionEmail
            }
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
      </Row>
      <Row justify='center'>
        <Col xs='content'>
          <ButtonPrimary type='submit'>
            {props.t('SEND SUSCRIPTION')}
          </ButtonPrimary>
        </Col>
      </Row>
      <Row justify='center' style={{ marginBottom: '1rem' }}>
        <Col xs='content'>
          {submitStatus.status && (
            <SimpleNote
              noteStatus={submitStatus.status}
              text={submitStatus.message}
            />
          )}
        </Col>
      </Row>
    </form>
  )
}

export default withTranslation('translation')(NewsletterForm)
