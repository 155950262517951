import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../../components/AgGrid/columnDefs'
import { CountLettersQuery, LettersGridQuery } from '../queries'
import {
  dateTimeValueGetter,
  lettersStatusValueGetter,
  lettersTypeValueGetter,
} from '../valueGetter'
import UserContext from '../../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../../configs/RouteConfig'
import { LettersStatusRenderer, TitleRenderer } from '../renderer'
import LettersStatusFilter from '../filters/LettersStatusFilter'
import { DateRenderer } from '../../../../components/AgGrid/renderers'
import PreviewRenderer from '../renderer/PreviewRenderer'
import SubjectRenderer from '../renderer/SubjectRenderer'

export const useLettersMassGrid = (triggerPreviewLetterQuery) => {
  const { currentLanguage, redirectRoute } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService) => {
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t(lettersStatusValueGetter(params))
          case 'type':
            return t(lettersTypeValueGetter(params))
          case 'sentAt':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          case 'createdAt':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          default:
            return params.value
        }
      }
      const LetterColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          colId: 'status',
          field: 'status',
          cellRenderer: LettersStatusRenderer,
          width: 70,
          sortable: true,
          filter: 'LettersStatusFilter',
          ...withFilterParams,
        },
        {
          headerName: t('SUBJECT'),
          field: 'title',
          width: 120,
          sortable: true,
          cellRenderer: SubjectRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('INTERNAL LABEL'),
          field: 'internalTitle',
          width: 120,
          sortable: true,
          cellRenderer: TitleRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('CREATED DATE'),
          field: 'createdAt',
          width: 70,
          sortable: true,
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'P',
          },
          ...withFilterParams,
        },
        {
          headerName: t('SENT DATE'),
          field: 'sentAt',
          width: 70,
          sortable: true,
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'P',
          },
          ...withFilterParams,
        },
        {
          headerName: t('PAGE COUNT'),
          field: 'pageCount',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('Recipients'),
          field: 'countRecipients',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('ATTACHMENTS'),
          field: 'countAttachments',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('PREVIEW'),
          field: 'tableId',
          width: 70,
          sortable: false,
          cellRenderer: PreviewRenderer,
          cellRendererParams: {
            triggerPreviewLetterQuery: triggerPreviewLetterQuery,
          },
          filter: false,
        },
      ]
      const LettersCustomFilter = [
        {
          filterType: 'text',
          operator: 'AND',
          name: 'type',
          conditions: [
            {
              type: 'equals',
              value: 'MASS',
            },
          ],
        },
      ]
      return {
        query: LettersGridQuery,
        columnDefs: LetterColumnDefs,
        customFilters: LettersCustomFilter,
        countQuery: CountLettersQuery,
        extractor: (response) => response.Me?.Company?.businessPost.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            const route =
              params?.node?.data?.status !== 'DRAFT'
                ? Routes.businessMail +
                  Routes.letter +
                  '/' +
                  params?.node?.data?.tableId
                : Routes.businessMail +
                  Routes.businessMailEdit +
                  '/' +
                  params?.node?.data?.tableId
            return [
              {
                // custom item
                name: t('GO TO LETTER'),
                action: function () {
                  redirectRoute(route)
                },
              },
              'copy',
            ]
          },
          components: {
            LettersStatusFilter,
          },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: {},
      }
    },
    [currentLanguage, redirectRoute, t, triggerPreviewLetterQuery]
  )
  return { getParams }
}
