import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'react-grid-system'

import { ButtonSecondary, ModalHint, portalTheme } from 'pyrexx-react-library'
import PageContainer from '../../components/PageContainer'
import PreviousAppointments from './PreviousAppointments'
import NavBack from '../../components/NavBack'
import Overview from './Overview'
import Thumbnails from './Thumbnails'
import ServiceDetails from './ServiceDetails'

import { Routes } from '../../configs/RouteConfig'
import UserContext, { useUserContext } from '../../helper/userContext'
import ReleaseQuotaMutation from '../../mutations/ReleaseQuotaMutation'

const Appointment = (props) => {
  const { appointment = {}, loading = false } = props
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const location = useLocation()
  const { user } = useUserContext()

  const [modal, setModal] = useState({
    show: false,
    message: '',
    buttonMessage: '',
    primaryButton: {
      usePrimaryButton: true,
      useFunction: () => {},
    },
    showButtonClose: true,
  })

  const previousRoute =
    location?.state?.from || '/' + currentLanguage + Routes.appointments

  const previousAppointments = appointment?.previousAppointments

  const { images = [] } = appointment

  const triggerQuotaReleaseMutation = () => {
    ReleaseQuotaMutation(
      {
        appointmentId: appointment?.tableId,
        companyId: user.companyId,
      },
      user.accessToken,
      () => {
        setModal({
          show: true,
          message: t('WE HAVE RELEASED YOUR QUOTA'),
          buttonMessage: t('UNDERSTOOD'),
          primaryButton: {
            usePrimaryButton: true,
            useFunction: () => {},
          },
          showButtonClose: false,
        })
      },
      () => {
        setModal({
          show: true,
          message: t('ERROR WITH YOUR REQUEST. PLEASE TRY AGAIN'),
          buttonMessage: t('UNDERSTOOD'),
          showButtonClose: false,
          primaryButton: {
            usePrimaryButton: true,
            useFunction: () => {},
          },
        })
      }
    )
  }

  return (
    <PageContainer>
      <ModalHint
        isOpen={modal.show}
        handleModalClose={() => {
          setModal({ show: false })
        }}
        message={modal.message}
        buttonCloseMessage={modal.buttonMessage}
        title=''
        showButtonClose={modal.showButtonClose}
        primaryButton={modal.primaryButton}
      />
      <NavBack
        title={t('APPOINTMENT DETAILS')}
        to={previousRoute}
        style={{ marginBottom: '16px' }}
      />
      <Overview appointment={appointment} loading={loading} />

      {!loading && (
        <>
          <div style={{ marginTop: '2rem', paddingBottom: '0.5rem' }}>
            {t('PREVIOUS APPOINTMENTS')}
          </div>
          <PreviousAppointments previousAppointments={previousAppointments} />

          <div style={{ marginTop: '2rem', paddingBottom: '0.5rem' }}>
            {t('FILES')} / {t('PICTURES')}
          </div>
          <Thumbnails images={images} />

          <Row
            justify='between'
            align='end'
            style={{ marginTop: '2rem', paddingBottom: '0.5rem' }}
          >
            <Col xs='content'>{t('SERVICE DETAILS')}</Col>
            <Col xs={9}>
              <Row justify='end' align='end'>
                <Col
                  xs={6}
                  style={{ fontSize: portalTheme.font.size.headingTable }}
                >
                  {t(
                    'THIS SERVICE IS INCLUDED IN A FREE VOLUME. TO RELEASE IT FROM THE FREE VOLUME YOU CAN ORDER A SINGLE INVOICE.'
                  )}
                </Col>
                <Col xs='content'>
                  <ButtonSecondary
                    onClick={() => {
                      setModal({
                        show: true,
                        message: t(
                          'PLEASE CONFIRM THAT YOU WANT TO RELEASE YOUR QUOTA'
                        ),
                        buttonMessage: t('CONFIRM'),
                        primaryButton: {
                          usePrimaryButton: true,
                          useFunction: () => triggerQuotaReleaseMutation(),
                        },
                        showButtonClose: true,
                      })
                    }}
                    style={{
                      height: '2.5rem',
                      paddingLeft: '1rem',
                      paddingRight: '1rem',
                    }}
                  >
                    {t('RELEASE FROM FREE VOLUME')}
                  </ButtonSecondary>
                </Col>
              </Row>
            </Col>
          </Row>

          <ServiceDetails appointmentId={appointment?.tableId} />
        </>
      )}
    </PageContainer>
  )
}

Appointment.propTypes = {
  appointment: PropTypes.object,
  loading: PropTypes.bool,
}

export default Appointment
