import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation UpdateUserMutation(
    $companyId: ID!
    $userId: ID!
    $firstname: String
    $lastname: String
    $permissions: [PermissionType]!
    $contracts: [Int]
    $billingPartitions: [Int]
    $removePermissions: [PermissionType]!
    $removeContracts: [Int]
    $removeBillingPartitions: [Int]
  ) {
    Me {
      Company(id: $companyId) {
        User(id: $userId) {
          changeCoreData(firstName: $firstname, lastName: $lastname)
          addPermissions(permissions: $permissions)
          addRestrictions(
            contracts: $contracts
            billingPartitions: $billingPartitions
          )
          removePermissions(permissions: $removePermissions)
          removeRestrictions(
            contracts: $removeContracts
            billingPartitions: $removeBillingPartitions
          )
        }
      }
    }
  }
`

export default (variables = {}, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
