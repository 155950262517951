/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppointmentGetBulletinDownloadPathQueryVariables = {|
  companyId: string,
  appointmentId: string,
|};
export type AppointmentGetBulletinDownloadPathQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +Appointment: ?{|
        +data: ?{|
          +id: string,
          +tableId: number,
          +download: ?{|
            +name: string,
            +path: string,
            +token: string,
          |},
        |}
      |}
    |}
  |}
|};
export type AppointmentGetBulletinDownloadPathQuery = {|
  variables: AppointmentGetBulletinDownloadPathQueryVariables,
  response: AppointmentGetBulletinDownloadPathQueryResponse,
|};
*/


/*
query AppointmentGetBulletinDownloadPathQuery(
  $companyId: ID!
  $appointmentId: ID!
) {
  Me {
    Company(id: $companyId) {
      Appointment(id: $appointmentId) {
        data: bulletin {
          id
          tableId
          download {
            name
            path
            token
          }
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appointmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "appointmentId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": "data",
  "args": null,
  "concreteType": "Bulletin",
  "kind": "LinkedField",
  "name": "bulletin",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tableId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DownloadDetails",
      "kind": "LinkedField",
      "name": "download",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "token",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppointmentGetBulletinDownloadPathQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointment",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppointmentGetBulletinDownloadPathQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointment",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "def9c9ba49855d11c170169874633f89",
    "id": null,
    "metadata": {},
    "name": "AppointmentGetBulletinDownloadPathQuery",
    "operationKind": "query",
    "text": "query AppointmentGetBulletinDownloadPathQuery(\n  $companyId: ID!\n  $appointmentId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      Appointment(id: $appointmentId) {\n        data: bulletin {\n          id\n          tableId\n          download {\n            name\n            path\n            token\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '54f14db3f7d7e997d362bc75e7d7b779';

module.exports = node;
