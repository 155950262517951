/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSalutationType = "COMPANY" | "MR" | "MRS" | "NONE" | "%future added value";
export type UserTitleType = "DR" | "NONE" | "PROF" | "PROF_DR" | "%future added value";
export type TransferTokenMutationVariables = {|
  transferToken: string
|};
export type TransferTokenMutationResponse = {|
  +info: ?{|
    +status: boolean,
    +Me: ?{|
      +id: string,
      +name: ?string,
      +firstname: ?string,
      +lastname: ?string,
      +salutation: UserSalutationType,
      +title: UserTitleType,
      +can: $ReadOnlyArray<?string>,
      +Companies: ?$ReadOnlyArray<?{|
        +id: string,
        +name: string,
      |}>,
    |},
    +accessToken: ?string,
    +refreshToken: ?string,
  |}
|};
export type TransferTokenMutation = {|
  variables: TransferTokenMutationVariables,
  response: TransferTokenMutationResponse,
|};
*/


/*
mutation TransferTokenMutation(
  $transferToken: String!
) {
  info: transferToken(transferToken: $transferToken) {
    status
    Me {
      id
      name
      firstname
      lastname
      salutation
      title
      can
      Companies {
        id
        name
      }
    }
    accessToken
    refreshToken
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "transferToken"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": "info",
    "args": [
      {
        "kind": "Variable",
        "name": "transferToken",
        "variableName": "transferToken"
      }
    ],
    "concreteType": "Token",
    "kind": "LinkedField",
    "name": "transferToken",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "salutation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "can",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyInfo",
            "kind": "LinkedField",
            "name": "Companies",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TransferTokenMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TransferTokenMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7475c613e79ac66459d138a429d5d5e3",
    "id": null,
    "metadata": {},
    "name": "TransferTokenMutation",
    "operationKind": "mutation",
    "text": "mutation TransferTokenMutation(\n  $transferToken: String!\n) {\n  info: transferToken(transferToken: $transferToken) {\n    status\n    Me {\n      id\n      name\n      firstname\n      lastname\n      salutation\n      title\n      can\n      Companies {\n        id\n        name\n      }\n    }\n    accessToken\n    refreshToken\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '20a831bcd1bed1bbaa2f161fe164d681';

module.exports = node;
