import React from 'react'
import AppointmentAccessReasonRendererHelper from '../../../components/AgGrid/RendererHelper/AppointmentAccessReasonRendererHelper'

const AppointmentAccessRenderer = (props) => {
  const { value } = props

  if (!value) {
    return ''
  }

  return <AppointmentAccessReasonRendererHelper value={value} />
}

export default AppointmentAccessRenderer
