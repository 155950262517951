import React from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import PureContact from '../Support/Contact'

const Contact = () => {
  const { t } = useTranslation()

  return (
    <PageContainer>
      <PageTitle>{t('CONTACT')}</PageTitle>
      <PureContact />
    </PageContainer>
  )
}

export default Contact
