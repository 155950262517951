import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'

import { ButtonPrimary, SimpleNote } from 'pyrexx-react-library'
import PageContainer from '../../components/PageContainer'
import RequestResetPasswordMutation from '../../mutations/RequestResetPasswordMutation'
import Spacer from '../../components/Spacer'

import { useUserContext } from '../../helper/userContext'
import { Routes } from '../../configs/RouteConfig'
import { Box, BoxInner, EmailInput, StyledSimpleModal } from './styles'
import RestoreAccountRequestMutation from '../../mutations/RestoreAccountRequestMutation'

import {
  initialState,
  SET_LOADER,
  CLOSE_COMPONENT,
  SUBMIT_WAS_SUCCESSFUL,
  SUBMIT_FAILED,
  submitReducer,
} from '../../reducers/submitReducer'

const ForgotPassword = () => {
  const { user, currentLanguage } = useUserContext()
  const { t } = useTranslation()
  const history = useHistory()

  const [state, dispatch] = useReducer(submitReducer, initialState)

  const closeModal = () => {
    dispatch({ type: CLOSE_COMPONENT })
    history.push('/' + currentLanguage + Routes.login)
  }

  const schema = Yup.object({
    username: Yup.string().required(t('IS REQUIRED')),
  })

  const onError = () => {
    dispatch({
      type: SUBMIT_FAILED,
      payload: t('ERROR WITH YOUR REQUEST. PLEASE TRY AGAIN'),
    })
  }

  const formik = useFormik({
    initialValues: { username: '' },
    validationSchema: schema,
    onSubmit: (values) => {
      dispatch({ type: SET_LOADER })

      const { username } = values
      RequestResetPasswordMutation(
        username,
        user.accessToken,
        ({ requestPasswordReset }) => {
          if (requestPasswordReset) {
            dispatch({
              type: SUBMIT_WAS_SUCCESSFUL,
              payload: t(
                'WE HAVE SENT THE NEXT STEPS TO YOUR REGISTERED EMAIL'
              ),
            })
          } else {
            RestoreAccountRequestMutation(
              username,
              user.accessToken,
              () => {
                history.push('/' + currentLanguage + Routes.requestPassword)
              },
              onError
            )
          }
        },
        onError
      )
    },
  })

  return (
    <PageContainer>
      <Box>
        <BoxInner>
          <h3>{t('YOUR ACCESS WILL BE RESTORED SOON')}</h3>

          <Spacer />

          <p>{t('TO CONTINUE WE NEED YOUR USERNAME')}</p>

          <Spacer />

          <EmailInput
            name='username'
            id='username'
            onChange={formik.handleChange}
            value={formik.values.username}
            label={t('USERNAME')}
            feedbackMessage={formik.touched.username && formik.errors.username}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />

          <Spacer y={48} />

          <ButtonPrimary
            onClick={formik.handleSubmit}
            isLoading={state.loading}
          >
            {t('CONTINUE')}
          </ButtonPrimary>
        </BoxInner>
      </Box>

      <StyledSimpleModal
        isOpen={state.showComponent}
        handleModalClose={closeModal}
        showButtonClose={false}
        closeOnOutsideClick={false}
      >
        <SimpleNote text={state.message} noteStatus={state.status} />
        <ButtonPrimary onClick={closeModal}>{t('UNDERSTOOD')}</ButtonPrimary>
      </StyledSimpleModal>
    </PageContainer>
  )
}

export default ForgotPassword
