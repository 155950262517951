import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`

export const Box = styled.div`
  display: flex;
  gap: 16px;

  background-color: #fff;
  padding: 16px;

  width: 100%;
`

export const BoxLabel = styled.h4`
  flex-basis: 300px;

  margin: 0;
`

export const BoxContent = styled.div`
  flex: 1;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
`

export const UserInfoBox = styled(Box)`
  gap: 50px;
`

export const UserInfoLeftSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  padding-right: 50px;

  border-right: 1px solid #333;
`

export const UserInfoRightSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  gap: 54px;

  & > div {
    flex: 1;
  }
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`
