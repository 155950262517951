/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UploadCompanyLogoMutationVariables = {|
  companyId: string,
  file: any,
|};
export type UploadCompanyLogoMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +uploadLogo: ?boolean
    |}
  |}
|};
export type UploadCompanyLogoMutation = {|
  variables: UploadCompanyLogoMutationVariables,
  response: UploadCompanyLogoMutationResponse,
|};
*/


/*
mutation UploadCompanyLogoMutation(
  $companyId: ID!
  $file: Upload!
) {
  Me {
    Company(id: $companyId) {
      uploadLogo(file: $file)
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "file"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "file",
                "variableName": "file"
              }
            ],
            "kind": "ScalarField",
            "name": "uploadLogo",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadCompanyLogoMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadCompanyLogoMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "19dc901ab9288125eac331cb9433db52",
    "id": null,
    "metadata": {},
    "name": "UploadCompanyLogoMutation",
    "operationKind": "mutation",
    "text": "mutation UploadCompanyLogoMutation(\n  $companyId: ID!\n  $file: Upload!\n) {\n  Me {\n    Company(id: $companyId) {\n      uploadLogo(file: $file)\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '40a6c890d17dcb47d1ba21715ed614d6';

module.exports = node;
