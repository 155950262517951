import React from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-grid-system'

import { useUserContext } from '../../../helper/userContext'
import formatDateLocale from '../../../helper/formatDateLocale'

export default (props) => {
  const { quotasInfo = [], loading = false } = props
  const { currentLanguage } = useUserContext()

  const { t } = useTranslation()

  const ContainerStyle = {
    backgroundColor: 'white',
    marginTop: '10px',
    height: '70px',
    paddingTop: '20px',
  }

  const firstContainerStyle = {
    backgroundColor: 'white',
    height: '40px',
    fontSize: '15px',
    paddingTop: '8px',
  }

  const checkIfLoading = () => {
    if (loading) {
      return (
        <Container style={ContainerStyle}>
          <Row>
            <Col xs={2}> {t('LOADING')} </Col>
          </Row>
        </Container>
      )
    }

    return quotasInfo.map((quota, index) => (
      <Container key={quota?.id} style={ContainerStyle}>
        <Row key={index}>
          <Col xs={2}> {quota?.quota?.name} </Col>
          <Col xs={1}>{quota?.contract?.tableId}</Col>
          <Col xs={2}>
            {formatDateLocale(currentLanguage, quota?.contract?.date, 'P') +
              ' / ' +
              formatDateLocale(currentLanguage, quota?.contract?.endDate, 'P')}
          </Col>
          <Col xs={2}> {quota?.quota.quantity} </Col>
          <Col xs={1}> {quota?.quota?.unit} </Col>
          <Col xs={1}> {quota?.quota?.initCreditCache} </Col>
          <Col xs={3}> {quota?.quota?.usedCreditCache} </Col>
        </Row>
      </Container>
    ))
  }

  return (
    <>
      <Container style={firstContainerStyle}>
        <Row>
          <Col xs={2}>{t('NAME')}</Col>
          <Col xs={1}>{t('CONTRACT')}</Col>
          <Col xs={2}>{t('FROM/TO')}</Col>
          <Col xs={2}>{t('QUANTITY PER YEAR')}</Col>
          <Col xs={1}>{t('UNIT')}</Col>
          <Col xs={1}>{t('CONTINGENT')}</Col>
          <Col xs={3}>{t('ALREADY USED CONTINGENT')}</Col>
        </Row>
      </Container>
      {checkIfLoading()}
    </>
  )
}
