import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import bellSrc from '../../../assets/overview/notification.png'

const DefaultPreviewScreen = ({ numberOfUnreadItems = 0 }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <BellImg src={bellSrc} width='100' height='100' alt='bell' />
      <UnreadLabel>
        {numberOfUnreadItems} {t('UNREAD')}
      </UnreadLabel>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
`

const BellImg = styled.img`
  width: 300px;
  height: 300px;
`

const UnreadLabel = styled.span`
  position: absolute;
  top: 83%;
  color: var(--pyrexx-red);
`

const NotificationPreview = ({
  selectedNotification = {},
  numberOfUnreadItems = 0,
}) => {
  const preview = selectedNotification?.preview || null

  return (
    <div>
      {preview || (
        <DefaultPreviewScreen numberOfUnreadItems={numberOfUnreadItems} />
      )}
    </div>
  )
}

NotificationPreview.propTypes = {}

export default NotificationPreview
