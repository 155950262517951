import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { Icon, portalTheme } from 'pyrexx-react-library'
import { useUserListGrid } from './gridConfigHooks/useUserListGrid'
import { useAgGridUltra } from '../../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import SimpleLink from '../../../components/Simple/SimpleLink'

import { Routes } from '../../../configs/RouteConfig'
import { useUserContext } from '../../../helper/userContext'

import '../../../styles/ag-grid.css'
import '../../../styles/ag-theme-pyrexx.css'

const StyledSimpleLink = styled(SimpleLink)`
  display: flex;
  align-items: center;
  gap: 8px;

  margin: 0;
  margin-right: 24px;

  & > span {
    font-family: ${portalTheme.font.family.quicksand};
  }
`

const CreateUserButton = () => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  return (
    <StyledSimpleLink
      to={'/' + currentLanguage + Routes.admin + Routes.createUser}
    >
      <span>{t('ADD USER')}</span>
      <Icon icon='add-user' size={25} color='#000' />
    </StyledSimpleLink>
  )
}

const UserListGrid = (props) => {
  const grids = [
    {
      id: 'user-list-grid',
      label: '',
      useGetParams: useUserListGrid(),
      extraItems: () => <CreateUserButton />,
    },
  ]

  const { generateGrid } = useAgGridUltra('UserList', grids)

  return generateGrid()
}

export default UserListGrid
