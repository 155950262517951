import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query PropertyManagementHouseEntranceGridQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        data: HouseEntrances(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          address {
            street
            number
            zip
            city
          }
          billingPartitions {
            id
            name
            id
          }
        }
        id
      }
      id
    }
  }
`
