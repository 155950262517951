import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query UsageUnitContainerQuery($companyId: ID!, $usageUnitId: ID!) {
    Me {
      Company(id: $companyId) {
        id
        Contracts(options: { endRow: -1 }) {
          serviceCategory {
            id
            tableId
            name
          }
        }
        UsageUnit(id: $usageUnitId) {
          id
          tableId
          referenceNumber
          rooms
          size
          type
          floor
          position
          address {
            street
            number
            zip
            city
          }
          tenants(
            options: {
              filters: [
                {
                  filterType: number
                  operator: AND
                  name: "status"
                  conditions: [{ value: ["STATUS_ACTIVE"], type: equals }]
                }
              ]
            }
          ) {
            id
            firstname
            lastname
            begin
            end
            status
            contact {
              type
              value
            }
          }
        }
      }
    }
  }
`
