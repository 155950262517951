import React from 'react'
import styled from 'styled-components/macro'

import Skeleton from '../Skeleton'

const SkeletonField = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 32px;

  &:last-child,
  &:last-of-type {
    margin-bottom: 0;
  }
`

const SkeletonList = ({ numberOfItems = 3, ...restOfProps }) => {
  const array = [...Array(numberOfItems).keys()]

  return (
    <>
      {array.map((i, idx) => (
        <SkeletonField key={idx} {...restOfProps}>
          <Skeleton xl style={{ paddingBottom: '12px' }} />
          <Skeleton lg />
        </SkeletonField>
      ))}
    </>
  )
}

export default SkeletonList
