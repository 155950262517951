import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import styled from 'styled-components/macro'

import { ButtonIcon } from 'pyrexx-react-library'

const MIN_ZOOM_RANGE = 0.05
const MAX_ZOOM_RANGE = 3
const STEP_ZOOM_RANGE = 0.05

const ImageCropper = (props) => {
  const { image = '', onCropComplete = () => {}, aspect = 1 } = props

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const decreaseZoom = () => {
    setZoom((prevState) => {
      if (prevState <= MIN_ZOOM_RANGE) {
        return MIN_ZOOM_RANGE
      } else {
        return parseFloat((prevState - STEP_ZOOM_RANGE).toFixed(2))
      }
    })
  }

  const increaseZoom = () => {
    setZoom((prevState) => {
      if (prevState >= MAX_ZOOM_RANGE) {
        return MAX_ZOOM_RANGE
      } else {
        return parseFloat((prevState + STEP_ZOOM_RANGE).toFixed(2))
      }
    })
  }

  return (
    <Wrapper>
      <CropContainer>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          restrictPosition={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          // objectFit='horizontal-cover'
        />
      </CropContainer>

      <Controls>
        <ZoomButton icon='hide' size='40' handleClick={decreaseZoom} />
        <ZoomRange
          type='range'
          value={zoom}
          min={MIN_ZOOM_RANGE}
          max={MAX_ZOOM_RANGE}
          step={STEP_ZOOM_RANGE}
          aria-labelledby='Zoom'
          onChange={(e) => {
            const formattedValue = parseFloat(e.target.value)
            setZoom(formattedValue)
          }}
        />
        <ZoomButton icon='expand' size='40' handleClick={increaseZoom} />
      </Controls>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 0;

  background-color: #000;
  border-radius: 1rem;
`

const CropContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;
  bottom: 80px;
`

const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 40%;
  transform: translateX(-50%);
  height: 40px;

  &:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }

  &:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }
`

const ZoomRange = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 3px solid #fff;
  border-bottom: 3px solid #fff;
  border-radius: 5px;
  background: var(--pyrexx-link, #3f51b5);
  width: 100%;

  &::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid var(--pyrexx-link, #3f51b5);
    background: var(--pyrexx-link, #3f51b5);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid var(--pyrexx-link, #3f51b5);
    background: var(--pyrexx-link, #3f51b5);
    border-radius: 50%;
    width: 25px;
    height: 25px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`

const ZoomButton = styled(ButtonIcon)`
  color: #fff;
`

ImageCropper.propTypes = {
  image: PropTypes.string,
  onCropComplete: PropTypes.func,
  aspect: PropTypes.number,
}

export default ImageCropper
