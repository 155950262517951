import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation RequestResetPasswordMutation($username: String!) {
    requestPasswordReset(username: $username)
  }
`

export default (username, accessToken, onCompleted, onError) => {
  const variables = {
    username,
  }

  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted,
    onError,
  })
}
