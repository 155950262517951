import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: ${(props) => (props.isDropdownOpen ? 'block' : 'none')};

  position: ${(props) => (props.isStatic ? 'static' : 'absolute')};
  top: calc(100% + 5px);
  z-index: 500;
  ${(props) => props.onlyClick && `width:100%;`}

  ${({ align, alignRight }) => {
    if (align === 'right' || alignRight) return 'right: 0;'
    if (align === 'left') return 'left: 0;'
  }}
`

const DropdownContainer = (props) => {
  const {
    onlyClick = '',
    isDropdownOpen = false,
    setIsDropdownOpen = () => {},
    keepOpen = false,
    children,
    alignRight = false,
    isStatic = false,
    align = 'left',
    ...restOfProps
  } = props

  const dropdown = useRef(null)

  // close the dropdown on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (isDropdownOpen && !dropdown.current.contains(target) && !keepOpen) {
        setIsDropdownOpen(false)
      }
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close the dropdown when esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (isDropdownOpen && keyCode === 27 && !keepOpen) {
        setIsDropdownOpen(false)
      }
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  useEffect(() => {
    if (keepOpen) {
      setIsDropdownOpen(true)
    }
  }, [keepOpen, setIsDropdownOpen])

  return (
    <Wrapper
      onlyClick={onlyClick}
      isDropdownOpen={isDropdownOpen}
      ref={dropdown}
      alignRight={alignRight}
      isStatic={isStatic}
      align={align}
      {...restOfProps}
    >
      {children}
    </Wrapper>
  )
}

export default DropdownContainer

DropdownContainer.propTypes = {
  isDropdownOpen: PropTypes.bool,
  setIsDropdownOpen: PropTypes.func,
  keepOpen: PropTypes.bool,
  children: PropTypes.any.isRequired,
  alignRight: PropTypes.bool,
  isStatic: PropTypes.bool,
}
