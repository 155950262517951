import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: inline-flex;
  justify-content: center;
  text-align: center;

  background-color: #8aa8c8;
  color: #fff;

  padding: ${(props) => props?.padding || '4px 8px'};

  //// Styles from mockup
  min-width: ${(props) => props?.minWidth || '41.71px'};
  //height: 24.71px;
  border-radius: 17.5px;
  font-size: 14px;

  &:empty {
    padding: 0;
  }
`

const Badge = ({ children = '', ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>
}

Badge.propTypes = {
  children: PropTypes.any,
}

export default Badge
