export const WhiteRoutes = [
  'home',
  'help',
  'contact',
  'faq',
  'download-center',
  'rate-us',
  'register-newsletter',
  'terms-of-service',
  'privacy',
  'imprint',
  'login',
  'logout',
  'register',
  'error-info',
  'forgot-password',
  'reset-password',
  'request-password',
]
