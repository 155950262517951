/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChangeUserPasswordMutationVariables = {|
  companyId: string,
  userId: string,
  password: string,
|};
export type ChangeUserPasswordMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +User: ?{|
        +changePassword: ?boolean
      |}
    |}
  |}
|};
export type ChangeUserPasswordMutation = {|
  variables: ChangeUserPasswordMutationVariables,
  response: ChangeUserPasswordMutationResponse,
|};
*/


/*
mutation ChangeUserPasswordMutation(
  $companyId: ID!
  $userId: ID!
  $password: String!
) {
  Me {
    Company(id: $companyId) {
      User(id: $userId) {
        changePassword(password: $password)
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "userId"
              }
            ],
            "concreteType": "UserMutations",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "password",
                    "variableName": "password"
                  }
                ],
                "kind": "ScalarField",
                "name": "changePassword",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeUserPasswordMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChangeUserPasswordMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "3541a0a3bbae1243366db944e555d9c8",
    "id": null,
    "metadata": {},
    "name": "ChangeUserPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeUserPasswordMutation(\n  $companyId: ID!\n  $userId: ID!\n  $password: String!\n) {\n  Me {\n    Company(id: $companyId) {\n      User(id: $userId) {\n        changePassword(password: $password)\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '270bf6c767faed0a74b3d14c94e33490';

module.exports = node;
