import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import QueryRendererContainer from '../../components/QueryRendererContainer'
import Contracts from './Contracts'
import PageTitle from '../../components/PageTitle'
import PageContainer from '../../components/PageContainer'
import Tabs from '../../components/Tabs'
import QuotaListGrid from './quota/Quota'

import environment from '../../environments/pom/Environment'
import ContractsContainerQuery from './queries/ContractsContainer'
import UserContext from '../../helper/userContext'
import getServices from '../../helper/getServices'
import useUrlRedirection from '../../hooks/useUrlRedirection'
import { serviceCategoryEnumList } from '../../components/AgGrid/enumLists'

const ContractsContainer = ({ pageSection = '' }) => {
  const { user } = useContext(UserContext)
  const { t } = useTranslation()
  const urlRedirectionCallback = useUrlRedirection()

  const variables = {
    companyId: user.companyId,
  }

  const tabs = [
    {
      key: 'performance',
      label: t('CONTRACT SERVICES'),
      content: (
        <QueryRendererContainer
          environment={environment(user.accessToken)}
          query={ContractsContainerQuery}
          variables={variables}
          loading={<Contracts loading />}
          render={(data) => {
            const contracts = data?.Me?.Company?.Contracts
            const services = getServices(contracts)
            const translatedServices = services.map((service) => {
              if (service.name !== 'Dienstleistung Rauchwarnmelder') {
                return {
                  ...service,
                  label: t(serviceCategoryEnumList(service.name)),
                  disabled: true,
                }
              } else {
                return {
                  ...service,
                  label: t(serviceCategoryEnumList(service.name)),
                  selected: true,
                }
              }
            })

            return (
              <Contracts services={translatedServices} contracts={contracts} />
            )
          }}
        />
      ),
    },
    // {
    //   key: 'control',
    //   label: t('CONTRACT MANAGEMENT'),
    //   content: '',
    // },
    {
      key: 'quota',
      label: t('QUOTA'),
      content: <QuotaListGrid />,
    },
  ]

  return (
    <PageContainer>
      <PageTitle>{t('CONTRACTS')}</PageTitle>
      <Tabs
        tabs={tabs}
        pageSection={pageSection}
        urlRedirectionCallback={urlRedirectionCallback}
      />
    </PageContainer>
  )
}
export default ContractsContainer
