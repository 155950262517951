import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query RecipientsUploadExampleQuery($companyId: ID!, $type: String) {
    Me {
      Company(id: $companyId) {
        businessPost {
          data: recipientsUploadExample(type: $type)
        }
      }
    }
  }
`
