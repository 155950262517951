import React from 'react'
import styled from 'styled-components/macro'

import VariablePreview from './VariablePreview'

function VariableList(props) {
  const { variables = [], onClick } = props

  return (
    <div className='app__variable-list'>
      <List>
        {variables.map((variable) => {
          return (
            <li key={variable.id}>
              <VariablePreview
                type='list'
                id={variable.id}
                onClick={onClick}
                {...variable}
              />
            </li>
          )
        })}
      </List>
    </div>
  )
}

const List = styled.div`
  list-style: none;

  display: flex;
  flex-direction: column;
  gap: 8px;
`

export default VariableList
