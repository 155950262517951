import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import QueryRendererContainer from '../../components/QueryRendererContainer/'
import PyrexxOverview from '../../components/PyrexxOverview'

import UserContext from '../../helper/userContext'
import { ContractsOverviewQuery } from './queries'

export default (props) => {
  const { contracts = [], setFilterModel = () => {} } = props

  const { user } = useContext(UserContext)
  const { t } = useTranslation()

  const defaultFilters = {
    companyId: user.companyId,
    dateFilter: [],
    serviceFilter: [],
    contractFilter: [],
  }

  const customKeys = {
    customServiceFilterKey: 'serviceCategory.tableId',
  }

  const [variables, setVariables] = React.useState({})

  const getElements = (statuses = {}) => {
    const { completed = 0, planned = 0, incompleted = 0 } = statuses || {}

    const titleElements = [
      { id: 'COMPLETED', label: t('COMPLETED') },
      { id: 'PLANNED', label: t('PLANNED') },
      { id: 'INCOMPLETE', label: t('INCOMPLETE') },
    ]

    const rowElements = [
      {
        id: 'default',
        columns: [
          {
            id: 'completed',
            label: completed,
            status: 'success',
          },
          {
            id: 'planned',
            label: planned,
            status: 'warning',
          },
          {
            id: 'incompleted',
            label: incompleted,
            status: 'error',
          },
        ],
      },
    ]

    return {
      titleElements,
      rowElements,
    }
  }

  const { titleElements, rowElements } = getElements()

  return (
    <QueryRendererContainer
      query={ContractsOverviewQuery}
      variables={{
        ...defaultFilters,
        ...variables,
      }}
      loading={
        <PyrexxOverview
          titleElements={titleElements}
          rowElements={rowElements}
          setFilterModel={setFilterModel}
          setVariables={setVariables}
          customKeys={customKeys}
          loading
        />
      }
      render={(data) => {
        const statuses = data?.Me?.Company

        const { titleElements, rowElements } = getElements(statuses)

        return (
          <PyrexxOverview
            contracts={contracts}
            titleElements={titleElements}
            rowElements={rowElements}
            setFilterModel={setFilterModel}
            setVariables={setVariables}
            customKeys={customKeys}
            variant='div'
          />
        )
      }}
    />
  )
}
