import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query LetterPreviewQuery($letterId: ID!, $companyId: ID!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          data: letter(id: $letterId) {
            id
            tableId
            preview
            title
          }
        }
      }
    }
  }
`
