import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query GetBulletinDownloadPathQuery(
    $filters: [Filter]
    $companyId: ID!
    $propertyUnitId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        HouseEntrance(id: $propertyUnitId) {
          data: bulletins(options: { filters: $filters }) {
            id
            tableId
            download {
              name
              path
              token
            }
          }
        }
      }
    }
  }
`
