function dummyTranslate(text) {
  return text
}

const usageUnitTypeEnumList = (value) => {
  switch (value) {
    case 'UNKNOWN':
      return ''

    case 'PRIVATE':
      return dummyTranslate('RESIDENCE')

    case 'COMMON':
      return dummyTranslate('COMMON SPACE')

    case 'BUSINESS':
      return dummyTranslate('BUSINESS')

    case 'NONE':
    default:
      return ''
  }
}

export default usageUnitTypeEnumList
