import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation ReleaseQuotaMutation($companyId: ID!, $appointmentId: ID!) {
    Me {
      Company(id: $companyId) {
        releaseFromQuota(appointmentId: $appointmentId)
      }
    }
  }
`

export default (variables, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
