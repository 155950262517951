import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import DropdownContainer from '../DropdownContainer'
import LabelContainer from '../Simple/EmptyButton'

const Wrapper = styled.div`
  display: inline-flex;
  position: relative;
`

const DropdownWithLabel = (props) => {
  const {
    openLeftSide = false,
    onlyClick = '',
    openFromStart = false,
    renderLabel,
    children,
    passPropsToChildren = false,
    ...restOfProps
  } = props

  const [isDropdownOpen, setIsDropdownOpen] = useState(openFromStart)
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState)
  }

  return (
    <Wrapper>
      <LabelContainer
        type='button'
        onClick={toggleDropdown}
        aria-haspopup='true'
        aria-expanded={isDropdownOpen}
      >
        {renderLabel && renderLabel(isDropdownOpen)}
      </LabelContainer>

      <DropdownContainer
        openLeftSide={openLeftSide}
        onlyClick={onlyClick}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        {...restOfProps}
      >
        {passPropsToChildren
          ? React.Children.map(children, (child) =>
              React.cloneElement(child, { isDropdownOpen, setIsDropdownOpen })
            )
          : children}
      </DropdownContainer>
    </Wrapper>
  )
}

DropdownWithLabel.propTypes = {
  openFromStart: PropTypes.bool,
  renderLabel: PropTypes.func,
  children: PropTypes.any.isRequired,
  passPropsToChildren: PropTypes.bool,
}

export default DropdownWithLabel
