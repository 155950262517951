function dummyTranslate(text) {
  return text
}

const usageUnitLockPeriodStatusEnumList = (value) => {
  switch (value) {
    case 'ACTIVE':
    case 'PENDING':
      return dummyTranslate('LOCKED')

    case 'DEACTIVATED':
      return dummyTranslate('UNLOCKED')

    case 'NOTLOCKED': // this is not implemented in the enum list is just for special uses
      return dummyTranslate('ACTIVE')

    case 'LOCKED': // this is not implemented in the enum list is just for special uses
      return dummyTranslate('LOCKED')

    case 'NONE':
    default:
      return ''
  }
}

export default usageUnitLockPeriodStatusEnumList
