import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import QueryRendererContainer from '../../components/QueryRendererContainer/QueryRendererContainer'
import UsageUnit from './UsageUnit'
import UsageUnitNotFound from './UsageUnitNotFound'

import environment from '../../environments/pom/Environment'
import UsageUnitContainerQuery from './queries/UsageUnitContainer'
import UserContext from '../../helper/userContext'
import getServices from '../../helper/getServices'
import { serviceCategoryEnumList } from '../../components/AgGrid/enumLists'

const UsageUnitContainer = () => {
  const { user } = useContext(UserContext)
  const { usageUnitId } = useParams()
  const { t } = useTranslation()

  const variables = {
    companyId: user.companyId,
    usageUnitId,
  }

  return (
    <QueryRendererContainer
      environment={environment(user.accessToken)}
      query={UsageUnitContainerQuery}
      variables={variables}
      loading={<UsageUnit loading />}
      render={(data) => {
        /**
         * @param {{Me:object}} data
         * @param {{Company:object}} data.Me
         * @param {{Appointment:object}} data.Me.Company
         * @param {{usageUnit:array}} data.Me.Company.Appointment
         */
        const usageUnit = data.Me?.Company?.UsageUnit
        const services = getServices(data.Me?.Company?.Contracts)
        const translatedServices = services.map((service) => ({
          ...service,
          label: t(serviceCategoryEnumList(service.name)),
        }))

        if (usageUnit) {
          return (
            <UsageUnit
              usageUnit={usageUnit}
              services={translatedServices}
              usageUnitId={usageUnitId}
            />
          )
        } else {
          return <UsageUnitNotFound />
        }
      }}
    />
  )
}

export default UsageUnitContainer
