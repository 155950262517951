/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type quotaGridQueryVariables = {|
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
  companyId: string,
|};
export type quotaGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +id: string,
      +Quotas: ?$ReadOnlyArray<?{|
        +id: string,
        +name: ?string,
        +appointment: ?{|
          +id: ?string,
          +date: ?any,
          +tableId: ?number,
          +usageUnit: ?{|
            +id: string,
            +referenceNumber: string,
            +tableId: number,
          |},
          +houseEntrance: ?{|
            +id: string,
            +address: ?{|
              +street: string,
              +city: string,
              +number: string,
            |},
          |},
        |},
      |}>,
    |}
  |}
|};
export type quotaGridQuery = {|
  variables: quotaGridQueryVariables,
  response: quotaGridQueryResponse,
|};
*/


/*
query quotaGridQuery(
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      id
      Quotas(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
        id
        name
        appointment {
          id
          date
          tableId
          usageUnit {
            id
            referenceNumber
            tableId
          }
          houseEntrance {
            id
            address {
              street
              city
              number
            }
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "companyId"
    }
  ],
  "concreteType": "Company",
  "kind": "LinkedField",
  "name": "Company",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "endRow",
              "variableName": "endRow"
            },
            {
              "kind": "Variable",
              "name": "filters",
              "variableName": "filters"
            },
            {
              "kind": "Variable",
              "name": "sorting",
              "variableName": "sortModel"
            },
            {
              "kind": "Variable",
              "name": "startRow",
              "variableName": "startRow"
            }
          ],
          "kind": "ObjectValue",
          "name": "options"
        }
      ],
      "concreteType": "InvoiceItem",
      "kind": "LinkedField",
      "name": "Quotas",
      "plural": true,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Appointment",
          "kind": "LinkedField",
          "name": "appointment",
          "plural": false,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UsageUnit",
              "kind": "LinkedField",
              "name": "usageUnit",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "referenceNumber",
                  "storageKey": null
                },
                (v6/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "HouseEntrance",
              "kind": "LinkedField",
              "name": "houseEntrance",
              "plural": false,
              "selections": [
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Address",
                  "kind": "LinkedField",
                  "name": "address",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "street",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "city",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "number",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "quotaGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "quotaGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "83dfe506f2d47ce4169e592900095b13",
    "id": null,
    "metadata": {},
    "name": "quotaGridQuery",
    "operationKind": "query",
    "text": "query quotaGridQuery(\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      id\n      Quotas(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n        id\n        name\n        appointment {\n          id\n          date\n          tableId\n          usageUnit {\n            id\n            referenceNumber\n            tableId\n          }\n          houseEntrance {\n            id\n            address {\n              street\n              city\n              number\n            }\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '451d3a1a88fe416da3659e0dc4eabb8a';

module.exports = node;
