import React from 'react'
import styled from 'styled-components/macro'
import { houseEntranceIdValueGetter } from '../valueGetter'

const ValueContainer = styled.div`
  display: flex;
  justify-content: center;
`
const HouseEntranceIdRenderer = (props) => {
  return <ValueContainer>{houseEntranceIdValueGetter(props)}</ValueContainer>
}
export default HouseEntranceIdRenderer
