import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query HouseEntranceUsageUnitsGridQuery($companyId: ID!, $filters: [Filter]) {
    Me {
      Company(id: $companyId) {
        data: UsageUnits(options: { filters: $filters }) {
          id
          tableId
          referenceNumber
          floor
          position
          tenants {
            id
            tableId
            firstname
            lastname
          }
          CAN {
            one
            two
            three
            four
            five
          }
        }
      }
    }
  }
`
