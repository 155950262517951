/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type AppointmentsCardQueryVariables = {|
  companyId: string,
  dateFilter: $ReadOnlyArray<?Condition>,
|};
export type AppointmentsCardQueryResponse = {|
  +Me: ?{|
    +appointmentsStatus: ?{|
      +done: number,
      +pending: number,
    |}
  |}
|};
export type AppointmentsCardQuery = {|
  variables: AppointmentsCardQueryVariables,
  response: AppointmentsCardQueryResponse,
|};
*/


/*
query AppointmentsCardQuery(
  $companyId: ID!
  $dateFilter: [Condition]!
) {
  Me {
    appointmentsStatus: Company(id: $companyId) {
      done: countAppointments(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["DONE", "CLOSED", "CHECKED"], type: equals}]}, {name: "date", filterType: date, operator: AND, conditions: $dateFilter}]})
      pending: countAppointments(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["OPEN", "DIAGNOSE_PENDING"], type: equals}]}, {name: "date", filterType: date, operator: AND, conditions: $dateFilter}]})
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "conditions",
      "variableName": "dateFilter"
    },
    {
      "kind": "Literal",
      "name": "filterType",
      "value": "date"
    },
    {
      "kind": "Literal",
      "name": "name",
      "value": "date"
    },
    {
      "kind": "Literal",
      "name": "operator",
      "value": "AND"
    }
  ],
  "kind": "ObjectValue",
  "name": "filters.1"
},
v3 = {
  "alias": "done",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "DONE",
                      "CLOSED",
                      "CHECKED"
                    ]
                  }
                ],
                "filterType": "text",
                "name": "status",
                "operator": "AND"
              }
            },
            (v2/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countAppointments",
  "storageKey": null
},
v4 = {
  "alias": "pending",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "OPEN",
                      "DIAGNOSE_PENDING"
                    ]
                  }
                ],
                "filterType": "text",
                "name": "status",
                "operator": "AND"
              }
            },
            (v2/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countAppointments",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppointmentsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "appointmentsStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppointmentsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "appointmentsStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "926f55fce2b23725b240e7abce49d5a3",
    "id": null,
    "metadata": {},
    "name": "AppointmentsCardQuery",
    "operationKind": "query",
    "text": "query AppointmentsCardQuery(\n  $companyId: ID!\n  $dateFilter: [Condition]!\n) {\n  Me {\n    appointmentsStatus: Company(id: $companyId) {\n      done: countAppointments(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"DONE\", \"CLOSED\", \"CHECKED\"], type: equals}]}, {name: \"date\", filterType: date, operator: AND, conditions: $dateFilter}]})\n      pending: countAppointments(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"OPEN\", \"DIAGNOSE_PENDING\"], type: equals}]}, {name: \"date\", filterType: date, operator: AND, conditions: $dateFilter}]})\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c88e5cb29ce3ef85cef85b1930bbe3e8';

module.exports = node;
