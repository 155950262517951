import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { LoadingSpinner } from 'pyrexx-react-library'

const Loader = styled.div`
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.3);
  }
`

export default (props) => {
  const { t } = useTranslation()

  return (
    <Loader>
      <LoadingSpinner message={t('LOADING')} />
    </Loader>
  )
}
