import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomSearchbox } from '../../../../../Layout/Header/MainHeader.styles'
import { Col, Row } from 'react-grid-system'

const FilterSearch = (props) => {
  const { changeSearchWord, searchWord } = props
  const { t } = useTranslation()

  return (
    <Row>
      <Col>
        <CustomSearchbox
          twoColStyle
          disabledborder
          disabled={false}
          disabledComponent={<span>{t('LOADING')}</span>}
          style={{ width: '100%', marginTop: '15px' }}
          id='searchFilterText'
          clearSearchAfterSubmit={false}
          handleSubmit={() => {
            console.log('submit')
          }}
          handleChange={(search) => {
            changeSearchWord(search)
          }}
          placeholder={t('SEARCH')}
          searchText={searchWord}
        />
      </Col>
    </Row>
  )
}
export default FilterSearch
