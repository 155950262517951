import React from 'react'
import AppointmentTypeRendererHelper from '../../../components/AgGrid/RendererHelper/AppointmentTypeRendererHelper'

const AppointmentTypeRenderer = (props) => {
  const { value } = props

  return <AppointmentTypeRendererHelper value={value} />
}

export default AppointmentTypeRenderer
