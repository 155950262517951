const usageUnitInventoryQuantityValueGetter = (props) => {
  const { value } = props
  if (!value) {
    return ''
  }
  return value.reduce(
    (accumulator, currentValue) => accumulator + currentValue.quantity,
    0
  )
}

export default usageUnitInventoryQuantityValueGetter
