/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type InvoicesCardQueryVariables = {|
  companyId: string,
  dateFilter: $ReadOnlyArray<?Condition>,
|};
export type InvoicesCardQueryResponse = {|
  +Me: ?{|
    +invoicesStatus: ?{|
      +open: ?number,
      +reminded: ?number,
    |}
  |}
|};
export type InvoicesCardQuery = {|
  variables: InvoicesCardQueryVariables,
  response: InvoicesCardQueryResponse,
|};
*/


/*
query InvoicesCardQuery(
  $companyId: ID!
  $dateFilter: [Condition]!
) {
  Me {
    invoicesStatus: Company(id: $companyId) {
      open: countInvoices(options: {filters: [{name: "status", filterType: number, operator: AND, conditions: [{value: ["OPEN"], type: equals}]}, {name: "invoiceDate", filterType: date, operator: AND, conditions: $dateFilter}]})
      reminded: countInvoices(options: {filters: [{name: "status", filterType: number, operator: AND, conditions: [{value: ["REMINDED"], type: equals}]}, {name: "invoiceDate", filterType: date, operator: AND, conditions: $dateFilter}]})
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "dateFilter"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "conditions",
      "variableName": "dateFilter"
    },
    {
      "kind": "Literal",
      "name": "filterType",
      "value": "date"
    },
    {
      "kind": "Literal",
      "name": "name",
      "value": "invoiceDate"
    },
    {
      "kind": "Literal",
      "name": "operator",
      "value": "AND"
    }
  ],
  "kind": "ObjectValue",
  "name": "filters.1"
},
v3 = {
  "alias": "open",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "OPEN"
                    ]
                  }
                ],
                "filterType": "number",
                "name": "status",
                "operator": "AND"
              }
            },
            (v2/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countInvoices",
  "storageKey": null
},
v4 = {
  "alias": "reminded",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "REMINDED"
                    ]
                  }
                ],
                "filterType": "number",
                "name": "status",
                "operator": "AND"
              }
            },
            (v2/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countInvoices",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoicesCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "invoicesStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvoicesCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "invoicesStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d9171997e749fdf3bbc395c17d4453a",
    "id": null,
    "metadata": {},
    "name": "InvoicesCardQuery",
    "operationKind": "query",
    "text": "query InvoicesCardQuery(\n  $companyId: ID!\n  $dateFilter: [Condition]!\n) {\n  Me {\n    invoicesStatus: Company(id: $companyId) {\n      open: countInvoices(options: {filters: [{name: \"status\", filterType: number, operator: AND, conditions: [{value: [\"OPEN\"], type: equals}]}, {name: \"invoiceDate\", filterType: date, operator: AND, conditions: $dateFilter}]})\n      reminded: countInvoices(options: {filters: [{name: \"status\", filterType: number, operator: AND, conditions: [{value: [\"REMINDED\"], type: equals}]}, {name: \"invoiceDate\", filterType: date, operator: AND, conditions: $dateFilter}]})\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e01fd4df6af9fb9e3e3c18be1ad183bc';

module.exports = node;
