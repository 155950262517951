import styled from 'styled-components/macro'
import { Col } from 'react-grid-system'
import { portalTheme } from 'pyrexx-react-library'

export const StyledCopyrightFooter = styled(Col)`
  background-color: ${portalTheme.color.basicColorAccentRed};
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 14px;
  color: ${portalTheme.color.basicColorWhite};
`
