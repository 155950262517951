/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RecipientsUploadExampleQueryVariables = {|
  companyId: string,
  type?: ?string,
|};
export type RecipientsUploadExampleQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +data: string
      |}
    |}
  |}
|};
export type RecipientsUploadExampleQuery = {|
  variables: RecipientsUploadExampleQueryVariables,
  response: RecipientsUploadExampleQueryResponse,
|};
*/


/*
query RecipientsUploadExampleQuery(
  $companyId: ID!
  $type: String
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        data: recipientsUploadExample(type: $type)
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessPost",
  "kind": "LinkedField",
  "name": "businessPost",
  "plural": false,
  "selections": [
    {
      "alias": "data",
      "args": [
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "type"
        }
      ],
      "kind": "ScalarField",
      "name": "recipientsUploadExample",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RecipientsUploadExampleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RecipientsUploadExampleQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "564f2d86a45b662b8f7887ef15bc3c69",
    "id": null,
    "metadata": {},
    "name": "RecipientsUploadExampleQuery",
    "operationKind": "query",
    "text": "query RecipientsUploadExampleQuery(\n  $companyId: ID!\n  $type: String\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        data: recipientsUploadExample(type: $type)\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '78572950f8f38582d87efe48139ccca4';

module.exports = node;
