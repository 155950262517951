import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components/macro'

import SimpleLink from '../../../components/Simple/SimpleLink'
import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'
import EmptyButton from '../../../components/Simple/EmptyButton'

import { Routes } from '../../../configs/RouteConfig'

import notificationActiveImg from '../../../assets/overview/notification-active.png'
import notificationInactiveImg from '../../../assets/overview/notification-inactive.png'
import ticketInactiveImg from '../../../assets/overview/ticket-inactive.png'

const Wrapper = styled.section`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  height: 100%;

  padding: 24px;
  background-color: #fff;

  & h3,
  & h4,
  & p {
    margin: 0;
  }

  & [data-status] {
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
  }

  & [data-status-light] {
    flex-shrink: 0;
    margin-top: 4px;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  border-bottom: 3px solid #f7faf5;
`

const Body = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-flow: column nowrap;
  gap: 16px;
`

const NotificationsWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;

  overflow: auto;
  max-height: 400px;
`

const Tabs = styled.div`
  display: flex;
  gap: 10px;
`

const TabButton = styled(EmptyButton)`
  padding: 0 5px;
  border-bottom: 5px solid;
  border-bottom-color: transparent;

  transition: border-bottom-color 300ms ease-out;

  &:hover {
    border-bottom-color: var(--pyrexx-red);
    transition: border-bottom-color 300ms ease-in;
  }

  ${(props) =>
    props.isActive &&
    css`
      border-bottom-color: var(--pyrexx-red);
      transition: border-bottom-color 300ms ease-in;
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      border-bottom-color: transparent;
      cursor: not-allowed;

      &:hover {
        border-bottom-color: transparent;
      }
    `}
`

const OverviewNotifications = (props) => {
  const { notifications = [] } = props
  const { t } = useTranslation()

  const [tab, setTab] = useState('all')

  const bodyTitles = {
    all: t('ALL'),
    notification: t('NOTIFICATIONS'),
    ticket: t('TICKETS'),
  }

  return (
    <Wrapper>
      <Header>
        <h3>{t('SUPPORT')}</h3>

        <Tabs>
          <TabButton onClick={() => setTab('all')} isActive={tab === 'all'}>
            {t('ALL')}
          </TabButton>
          <TabButton
            onClick={() => setTab('notification')}
            isActive={tab === 'notification'}
          >
            <img
              src={
                tab === 'notification'
                  ? notificationActiveImg
                  : notificationInactiveImg
              }
              alt='notification'
              width={35}
              height={35}
            />
          </TabButton>
          <TabButton
            // onClick={() => setTab('ticket')}
            isActive={tab === 'ticket'}
            isDisabled
          >
            <img src={ticketInactiveImg} alt='ticket' width={35} height={35} />
          </TabButton>
        </Tabs>
      </Header>

      <Body>
        <h4>{bodyTitles[tab]}</h4>

        <NotificationsWrapper>
          {notifications.length > 0 ? (
            notifications
              .filter((n) => (tab === 'all' ? true : n.type === tab))
              .map((n) => (
                <StatusLightWithLabel
                  key={n.id}
                  status={n.status}
                  label={n.text}
                />
              ))
          ) : (
            <p>{t('NO OPEN NOTIFICATIONS')}</p>
          )}
        </NotificationsWrapper>

        <SimpleLink
          to={'/:lang' + Routes.support + Routes.notifications}
          style={{ textAlign: 'right' }}
        >
          {t('GO TO NOTIFICATIONS') + ' >'}
        </SimpleLink>
      </Body>
    </Wrapper>
  )
}

OverviewNotifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      text: PropTypes.string,
    })
  ),
}

export default OverviewNotifications
