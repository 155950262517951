/**
 * Transforms an array of contracts with billingPeriods into an array of pure
 * unrepeated billingPeriods
 *
 * @param {[]} contracts
 * @returns {{id: String, tableId: Number, from: String, to: String}[]}
 */
export default (contracts = []) =>
  contracts
    .reduce((acc, curr) => {
      let currentBillingPeriods = curr?.billingPeriods

      currentBillingPeriods =
        Array.isArray(currentBillingPeriods) && currentBillingPeriods != null
          ? currentBillingPeriods
          : []

      return [...acc, ...currentBillingPeriods]
    }, [])
    .filter(
      (contract, idx, self) =>
        idx ===
          self.findIndex(
            (c) => c?.from === contract?.from && c?.to === contract?.to
          ) && contract?.tableId != null
    )
    .sort((a, b) => {
      if (a?.from < b?.from) {
        return 1
      } else if (a?.from > b?.from) {
        return -1
      }
      return 0
    })
