import React, { useCallback, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { Icon } from 'leaflet'

import { portalTheme } from 'pyrexx-react-library'
import Skeleton from '../../components/Skeleton'

import 'leaflet/dist/leaflet.css'
import 'react-leaflet-markercluster/dist/styles.min.css'
import markerIconPng from 'leaflet/dist/images/marker-icon.png'
import SimpleLink from '../../components/Simple/SimpleLink'
import { Routes } from '../../configs/RouteConfig'
import UserContext from '../../helper/userContext'
import { fetchQuery } from 'react-relay'
import environment from '../../environments/pom/Environment'
import PropertyManagementGetHouseEntranceCoordinatesQuery from './queries/PropertyManagementGetHouseEntranceCoordinates'
import * as Throttle from 'promise-parallel-throttle'

const DefaultCol = styled(Col)`
  background-color: ${portalTheme.color.basicColorWhite};
`

const BorderBottomRow = styled(Row)`
  border-bottom: 1px solid #979797;
`

const SmallTitleCol = styled(Col)`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  font-size: 12px;
`

const BigLabelCol = styled(Col)`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  font-size: 14px;
`

const BigLabelColDisabled = styled(BigLabelCol)`
  color: ${portalTheme.color.disabledColor};
`

const MapCol = styled(Col)`
  padding: 0 !important;
  border-right: 1px solid ${portalTheme.color.linkColorSecondary};
`

const MapContainerStyled = styled(MapContainer)`
  height: 100%;
  isolation: isolate;
`

const PropertyUnitOverview = (props) => {
  const { loading = true, houseEntrancesCount = 0 } = props
  const { currentLanguage } = useContext(UserContext)
  const { user } = useContext(UserContext)
  const [houseEntranceCoordinates, setHouseEntranceCoordinates] = useState({
    loading: true,
    coordinates: [],
  })

  const getHouseEntranceCoordinates = useCallback(() => {
    const promises = []
    const chunkSize = 5000

    const chunks = Math.ceil(houseEntrancesCount / chunkSize)
    for (let i = 0; i < chunks; i++) {
      const start = i * (chunkSize + 1)
      const end = start + chunkSize
      const CoordinatesVariables = {
        companyId: user.companyId,
        startRow: start,
        endRow: end,
      }

      promises.push(() => {
        return fetchQuery(
          environment(user.accessToken),
          PropertyManagementGetHouseEntranceCoordinatesQuery,
          CoordinatesVariables,
          {
            force: true,
          }
        )
          .then((res) => {
            return res.Me?.Company?.HouseEntrances
          })
          .catch((e) => {
            console.log(e)
          })
      })
    }

    const currentCoordinates = { coordinates: [], address: [] }

    Throttle.all(promises)
      .then((houses) => {
        houses.flat(1).forEach((house) => {
          currentCoordinates.coordinates.push([
            house.coordinates.lat,
            house.coordinates.lng,
          ])
          currentCoordinates.address.push({
            address: house.address,
            tableId: house.tableId,
            countUnits: house.countUsageUnits,
          })
        })
      })
      .then(() => {
        setHouseEntranceCoordinates({
          loading: false,
          coordinates: currentCoordinates,
        })
      })
      .catch((e) => {
        console.log(e)
      })
  }, [houseEntrancesCount, user.accessToken, user.companyId])

  useEffect(() => {
    if (!loading && houseEntranceCoordinates.loading) {
      getHouseEntranceCoordinates()
    }
  }, [getHouseEntranceCoordinates, houseEntranceCoordinates, loading])

  const { t } = useTranslation()
  return (
    <Container>
      <Row>
        <DefaultCol>
          <Row style={{ height: '100%' }}>
            <MapCol xs={9}>
              {loading || houseEntranceCoordinates.loading ? (
                <Skeleton fill={1} />
              ) : (
                <MapContainerStyled
                  bounds={houseEntranceCoordinates.coordinates.coordinates}
                  doubleClickZoom
                  scrollWheelZoom
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  />
                  <MarkerClusterGroup>
                    {houseEntranceCoordinates.coordinates.coordinates.map(
                      (house, index) => {
                        return (
                          <Marker
                            key={'marker' + index}
                            position={[house[0], house[1]]}
                            icon={
                              new Icon({
                                iconUrl: markerIconPng,
                                iconSize: [25, 41],
                                iconAnchor: [12, 41],
                              })
                            }
                          >
                            <Popup>
                              <Container>
                                <Row style={{ paddingTop: '1rem' }}>
                                  <Col style={{ fontSize: '1.5rem' }}>
                                    {houseEntranceCoordinates.coordinates
                                      .address[index].address.street +
                                      ' ' +
                                      houseEntranceCoordinates.coordinates
                                        .address[index].address.number}
                                  </Col>
                                </Row>
                                <Row style={{ paddingTop: '1rem' }}>
                                  <Col>
                                    {houseEntranceCoordinates.coordinates
                                      .address[index].address.zip +
                                      ' ' +
                                      houseEntranceCoordinates.coordinates
                                        .address[index].address.city}
                                  </Col>
                                </Row>
                                <Row style={{ paddingTop: '1rem' }}>
                                  <Col xs='content'>
                                    {t('USAGE UNIT AMOUNT:') +
                                      ' ' +
                                      houseEntranceCoordinates.coordinates
                                        .address[index].countUnits}
                                  </Col>
                                </Row>
                                <Row style={{ padding: '1rem' }}>
                                  <SimpleLink
                                    to={
                                      '/' +
                                      currentLanguage +
                                      Routes.propertyUnit +
                                      '/' +
                                      houseEntranceCoordinates.coordinates
                                        .address[index].tableId
                                    }
                                  >
                                    {t('GO TO PROPERTY PAGE')}
                                  </SimpleLink>
                                </Row>
                              </Container>
                            </Popup>
                          </Marker>
                        )
                      }
                    )}
                  </MarkerClusterGroup>
                </MapContainerStyled>
              )}
            </MapCol>
            <Col xs={3}>
              <Container>
                <BorderBottomRow
                  justify='between'
                  style={{ paddingTop: '2rem', paddingBottom: '2rem' }}
                >
                  <BigLabelCol>{t('ENQUIRIES')}</BigLabelCol>
                  <BigLabelCol xs='content'> &gt; </BigLabelCol>
                </BorderBottomRow>
                <Row style={{ marginTop: '2rem', marginBottom: '4rem' }}>
                  <SmallTitleCol>{t('COMMON ACTIONS')}</SmallTitleCol>
                </Row>
                <Row
                  justify='between'
                  style={{ marginTop: '2rem', marginBottom: '4rem' }}
                >
                  <BigLabelColDisabled>
                    {t('(UN)LOCK USAGE UNIT FOR SERVICES')}
                  </BigLabelColDisabled>
                  <BigLabelColDisabled xs='content'> &gt; </BigLabelColDisabled>
                </Row>
                <Row
                  justify='between'
                  style={{ marginTop: '2rem', marginBottom: '4rem' }}
                >
                  <BigLabelColDisabled>
                    {t('CHANGE FACILITY MANAGEMENT')}
                  </BigLabelColDisabled>
                  <BigLabelColDisabled xs='content'> &gt; </BigLabelColDisabled>
                </Row>
                <Row
                  justify='between'
                  style={{ marginTop: '2rem', marginBottom: '4rem' }}
                >
                  <BigLabelColDisabled>
                    {t('CHANGE OF PORTFOLIO')}
                  </BigLabelColDisabled>
                  <BigLabelColDisabled xs='content'> &gt; </BigLabelColDisabled>
                </Row>
              </Container>
            </Col>
          </Row>
        </DefaultCol>
      </Row>
    </Container>
  )
}

PropertyUnitOverview.propTypes = {
  loading: PropTypes.bool,
}

export default PropertyUnitOverview
