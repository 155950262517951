/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type RoomUsageType = "BEDROOM" | "CHILDRENS_BEDROOM" | "COMMON_SPACES" | "COMMON_SPACES_ATTIC" | "COMMON_SPACES_BASEMENT" | "CORRIDOR" | "DININGROOM" | "GUESTROOM" | "KITCHEN" | "LIVINGROOM" | "LIVINGROOM_BEDROOM" | "LOGGIA" | "NONE" | "OFFICE_WORKROOM" | "ROOM" | "WALK_THROUGH_ROOM" | "WC_BATHROOM" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type ServiceDetailsQueryVariables = {|
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
  companyId: string,
  appointmentId: string,
|};
export type ServiceDetailsQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +Appointment: ?{|
        +data: $ReadOnlyArray<?{|
          +id: string,
          +actionPosition: ?{|
            +id: string,
            +name: ?string,
          |},
          +appointment: ?{|
            +serviceReason: ?{|
              +id: string,
              +name: ?string,
            |}
          |},
          +room: ?{|
            +id: string,
            +type: ?RoomUsageType,
          |},
        |}>
      |}
    |}
  |}
|};
export type ServiceDetailsQuery = {|
  variables: ServiceDetailsQueryVariables,
  response: ServiceDetailsQueryResponse,
|};
*/


/*
query ServiceDetailsQuery(
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
  $companyId: ID!
  $appointmentId: ID!
) {
  Me {
    Company(id: $companyId) {
      Appointment(id: $appointmentId) {
        data: registrations(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
          id
          actionPosition {
            id
            name
          }
          appointment {
            serviceReason {
              id
              name
            }
            id
          }
          room {
            id
            type
          }
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "appointmentId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "appointmentId"
  }
],
v8 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "endRow",
        "variableName": "endRow"
      },
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "sorting",
        "variableName": "sortModel"
      },
      {
        "kind": "Variable",
        "name": "startRow",
        "variableName": "startRow"
      }
    ],
    "kind": "ObjectValue",
    "name": "options"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = [
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ActionPosition",
  "kind": "LinkedField",
  "name": "actionPosition",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "ServiceReason",
  "kind": "LinkedField",
  "name": "serviceReason",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Room",
  "kind": "LinkedField",
  "name": "room",
  "plural": false,
  "selections": [
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointment",
                "plural": false,
                "selections": [
                  {
                    "alias": "data",
                    "args": (v8/*: any*/),
                    "concreteType": "Registration",
                    "kind": "LinkedField",
                    "name": "registrations",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Appointment",
                        "kind": "LinkedField",
                        "name": "appointment",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ServiceDetailsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointment",
                "plural": false,
                "selections": [
                  {
                    "alias": "data",
                    "args": (v8/*: any*/),
                    "concreteType": "Registration",
                    "kind": "LinkedField",
                    "name": "registrations",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Appointment",
                        "kind": "LinkedField",
                        "name": "appointment",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v9/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v13/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23e2a172c476fe9395d8d43b829529da",
    "id": null,
    "metadata": {},
    "name": "ServiceDetailsQuery",
    "operationKind": "query",
    "text": "query ServiceDetailsQuery(\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n  $companyId: ID!\n  $appointmentId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      Appointment(id: $appointmentId) {\n        data: registrations(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n          id\n          actionPosition {\n            id\n            name\n          }\n          appointment {\n            serviceReason {\n              id\n              name\n            }\n            id\n          }\n          room {\n            id\n            type\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1831f3512fc3addd7dd36914d73d1531';

module.exports = node;
