import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  z-index: 100;

  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? '1' : 0)};

  position: absolute;

  bottom: calc(100% + 1rem);
  right: 22px;

  transition: visibility 200ms ease-in-out, opacity 200ms ease-in-out;
`

const Notification = styled.div`
  background-color: #89a8c8;
  color: #fff;
  //box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
  //  0 2px 4px -1px rgba(0, 0, 0, 0.3);

  filter: drop-shadow(0 2px 4px rgb(137, 168, 200));

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;

  padding: 16px;

  &:before {
    content: '';
    position: absolute;
    right: 0;
    top: 100%;
    width: 0;
    height: 0;
    border-right: 13px solid #89a8c8;
    border-bottom: 13px solid transparent;
  }
`

const AddColumnNotification = ({ show = false, message = '' }) => {
  return (
    <Wrapper show={show}>
      <Notification>
        <span>{message}</span>
      </Notification>
    </Wrapper>
  )
}

export default AddColumnNotification
