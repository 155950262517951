import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { StyledInput } from 'pyrexx-react-library'

const Status = styled.div`
  width: 4px;
  height: 34px;

  background-color: red;
`

const SimpleInput = ({
  type = 'text',
  label,
  value,
  onChange,
  id,
  withStatus = false,
  feedbackMessage,
  className,
}) => {
  return (
    <div className={className}>
      {withStatus && <Status />}

      <StyledInput
        type={type}
        label={label}
        value={value}
        onChange={onChange}
        feedbackStyle='invalid'
        feedbackMessage={feedbackMessage}
        id={id}
        name={id}
        setFieldValue={() => {}}
      />
    </div>
  )
}

SimpleInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  withStatus: PropTypes.bool,
  feedbackMessage: PropTypes.string,
}

export default SimpleInput
