import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query AppointmentQuery($companyId: ID!, $appointmentId: ID!) {
    Me {
      Company(id: $companyId) {
        Appointment(id: $appointmentId) {
          id
          tableId
          status
          usageUnit {
            id
            tableId
            floor
            position
            tenants(
              options: {
                endRow: -1
                filters: [
                  {
                    filterType: number
                    operator: AND
                    name: "status"
                    conditions: [{ value: ["STATUS_ACTIVE"], type: equals }]
                  }
                ]
              }
            ) {
              id
              tableId
              firstname
              lastname
              begin
              end
            }
          }
          referenceNumber
          address {
            street
            zip
            city
            number
          }
          date
          timeframe
          type
          accessReason
          executionDate
          images {
            tableId
            name
            description
            url
          }
          registrations {
            id
            images {
              id
              tableId
              name
              description
              url
            }
          }
          previousAppointments {
            id
            tableId
            date
            timeframe
            type
            accessReason
            executionDate
          }
        }
      }
    }
  }
`
