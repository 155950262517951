import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query PropertyManagementUsageUnitGridQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        data: UsageUnits(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          type
          referenceNumber
          address {
            street
            number
            zip
            city
          }
          lockPeriods(
            options: {
              startRow: 0
              endRow: 0
              sorting: [{ colId: "begin", sort: "DESC" }]
              filters: [
                {
                  name: "status"
                  filterType: text
                  operator: AND
                  conditions: [{ value: "ACTIVE", type: equals }]
                }
              ]
            }
          ) {
            id
            status
            begin
            end
            reason
          }
          inventory {
            id
            quantity
          }
          billingPartitions {
            name
            id
          }
          lockStatus
          CAN {
            one
            two
            three
            four
            five
          }
        }
        id
      }
      id
    }
  }
`
