import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { Icon } from 'pyrexx-react-library'
import EmptyButton from '../../../components/Simple/EmptyButton'
import Feedback from './Feedback'
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  useAccordionContext,
} from '@reach/accordion'

const AccordionItemStyled = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`

const AccordionButtonStyled = styled(EmptyButton)`
  width: 100%;
`

const AccordionButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  padding: 16px;

  background-color: #fff;
`

const AccordionPanelRow = styled.div`
  padding: 24px;
  margin-left: 16px;

  background-color: #fff;
`
const HeaderWrapper = styled.h3`
  margin: 0;
`

const Header = styled.span`
  font-size: 16px;
  text-align: left;
`

const Content = styled.span``

const IconStyled = styled(Icon).attrs(() => ({
  size: 40,
}))`
  color: var(--pyrexx-link);
  flex-shrink: 0;
`

const HeaderIcon = ({ isOpen = false }) => {
  return isOpen ? <IconStyled icon='hide' /> : <IconStyled icon='expand' />
}

const FaqItem = (props) => {
  const {
    id,
    index = 0,
    header,
    content,
    withFeedback = true,
    onItemOpened = () => {},
    onFeedbackGiven = () => {},
  } = props

  const { openPanels } = useAccordionContext()
  const isOpen = openPanels.includes(index)

  useEffect(() => {
    if (isOpen) {
      onItemOpened(id)
    }
    // eslint-disable-next-line
  }, [isOpen])

  return (
    <AccordionItem as={AccordionItemStyled}>
      <HeaderWrapper>
        <AccordionButton as={AccordionButtonStyled}>
          <AccordionButtonRow>
            <Header>{header}</Header>
            <HeaderIcon isOpen={isOpen} />
          </AccordionButtonRow>
        </AccordionButton>
      </HeaderWrapper>

      <AccordionPanel>
        <AccordionPanelRow>
          <Content>{content}</Content>

          {withFeedback && (
            <Feedback
              id={id}
              handleFeedbackChange={(e) =>
                onFeedbackGiven({ id, value: e.target.value })
              }
            />
          )}
        </AccordionPanelRow>
      </AccordionPanel>
    </AccordionItem>
  )
}

FaqItem.propTypes = {
  id: PropTypes.any.isRequired,
  index: PropTypes.number,
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  withFeedback: PropTypes.bool,
  onItemOpened: PropTypes.func,
  onFeedbackGiven: PropTypes.func,
}

export default FaqItem
