import React from 'react'
import { CKEditor as CKEditorReact } from '@ckeditor/ckeditor5-react'

import CustomEditor from './build/customEditor'

// Setup: https://ckeditor.com/docs/ckeditor5/latest/installation/getting-started/frameworks/react.html
// Getting data: https://ckeditor.com/docs/ckeditor5/latest/installation/getting-started/basic-api.html#getting-the-editor-data-with-getdata
// Config: https://ckeditor.com/docs/ckeditor5/latest/installation/getting-started/configuration.html
// props: https://ckeditor.com/docs/ckeditor5/latest/installation/getting-started/frameworks/react.html#component-properties

const CKEditor = (props) => {
  const {
    editor = CustomEditor.Editor,
    onChange = () => {},
    editorConfiguration,
    currentData = '',
    editorRef = {},
    editorHeight = false,
  } = props

  return (
    <CKEditorReact
      editor={editor}
      data={currentData}
      config={editorConfiguration}
      onReady={(editor) => {
        // You can store the "editor" and use when it is needed.
        editorRef.current = editor

        if (editorHeight) {
          editor.editing.view.change((writer) => {
            writer.setStyle(
              'height',
              editorHeight + 'px',
              editor.editing.view.document.getRoot()
            )
          })
        }
      }}
      onChange={(event, editor) => {
        // const data = editor.getData()
        // console.log({ event, editor, data })
        // console.log(data)
        // console.log('Editor changed')
        onChange(event, editor)
        editorRef.current = editor
      }}
      onError={(err) => {
        console.error('Error with editor', err)
      }}
      // onBlur={(event, editor) => {
      //   console.log('Blur.', editor)
      // }}
      // onFocus={(event, editor) => {
      //   console.log('Focus.', editor)
      // }}
    />
  )
}

export default CKEditor
