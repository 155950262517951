import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query CompanyLayoutsQuery($companyId: ID!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          companyLayouts {
            id
            tableId
            name
            layout
            status
          }
        }
      }
    }
  }
`
