import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { Icon, portalTheme } from 'pyrexx-react-library'
import SkeletonList from '../../components/SkeletonList'
import SinglePdfDownload from '../../components/SinglePdfDownload/SinglePdfDownload'

import Status from './renderers/Status'
import downloadImg from '../../assets/download.svg'

import formatDateLocale from '../../helper/formatDateLocale'
import UserContext from '../../helper/userContext'
import formatValue from '../../helper/formatValue'

const Overview = (props) => {
  const { appointment = {}, loading = false } = props
  const { t } = useTranslation()
  const { currentLanguage } = useContext(UserContext)

  const {
    tableId: appointmentId = '',
    status = '',
    usageUnit = {},
    referenceNumber = '',
    address = {},
    date = '',
    type = '',
    timeframe = '',
    executionDate = '',
  } = appointment

  const {
    tableId: usageUnitId = '',
    floor = '',
    position = '',
    tenants = [],
  } = usageUnit || {}
  const { street = '', number = '', zip = '', city = '' } = address || {}

  const appointmentIdValue = appointmentId ? 'l_' + appointmentId : '-'
  const fDate = date ? formatDateLocale(currentLanguage, date, 'E P') : '-'
  const fExecutionDate = executionDate
    ? formatDateLocale(currentLanguage, executionDate, 'E P p')
    : '-'
  const streetNumberValue =
    !street && !number ? '-' : formatValue(street) + ' ' + formatValue(number)
  const zipCityValue =
    !zip && !city ? '-' : formatValue(zip) + ' ' + formatValue(city)
  const floorPositionValue =
    !floor && !position
      ? '-'
      : formatValue(floor) + '. - ' + formatValue(position)

  return (
    <Container>
      <Row>
        <Box xs={3} style={{ marginRight: '1rem' }}>
          {loading ? (
            <SkeletonList numberOfItems={3} />
          ) : (
            <>
              <Field>
                <LabelCol xs={12}>{t('PYREXX SERVICE NUMBER')}</LabelCol>
                <ValueCol xs={12}>{appointmentIdValue}</ValueCol>
              </Field>

              <Field>
                <LabelCol xs={12}>{t('PYREXX USAGE UNIT NUMBER')}</LabelCol>
                <ValueCol xs={12}>{usageUnitId || '-'}</ValueCol>
              </Field>

              <Field>
                <LabelCol xs={12}>{t('ALLOCATION NUMBER')}</LabelCol>
                <ValueCol xs={12}>{referenceNumber || '-'}</ValueCol>
              </Field>

              <Field>
                <LabelCol xs={12}>{t('STATUS')}</LabelCol>
                <ValueCol xs={12}>
                  <Status status={status} />
                </ValueCol>
              </Field>
            </>
          )}
        </Box>

        <Box>
          <Row style={{ height: '100%' }}>
            <CenterCol xs={8}>
              {loading ? (
                <Row>
                  <Col>
                    <SkeletonList numberOfItems={3} />
                  </Col>

                  <Col>
                    <SkeletonList numberOfItems={3} />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col style={{ marginLeft: '16px' }}>
                    <Field style={{ marginBottom: '8px' }}>
                      <LabelCol xs={12} style={{ margin: '0 0 0 -16px' }}>
                        {t('APPOINTMENT DETAILS')}
                      </LabelCol>
                    </Field>

                    <Field>
                      <LabelCol xs={12}>{t('APPOINTMENT TYPE')}</LabelCol>
                      <ValueCol xs={12}>{type || '-'}</ValueCol>
                    </Field>

                    <Field>
                      <LabelCol xs={12}>{t('DATE')}</LabelCol>
                      <ValueCol xs={12}>{fDate}</ValueCol>
                    </Field>

                    <Field>
                      <LabelCol xs={12}>{t('TIMEFRAME')}</LabelCol>
                      <ValueCol xs={12}>{timeframe || '-'}</ValueCol>
                    </Field>

                    <Field>
                      <LabelCol xs={12}>{t('APPOINTMENT EXECUTED')}</LabelCol>
                      <ValueCol xs={12}>{fExecutionDate}</ValueCol>
                    </Field>
                  </Col>

                  <Col style={{ marginLeft: '16px' }}>
                    <Field style={{ marginBottom: '8px' }}>
                      <LabelCol xs={12} style={{ margin: '0 0 0 -16px' }}>
                        {t('DETAILS')}
                      </LabelCol>
                    </Field>

                    <Field>
                      <LabelCol xs={12}>{t('CURRENT TENANT')}</LabelCol>
                      <ValueCol xs={12}>
                        {tenants?.length > 0
                          ? tenants.map((tenant) => (
                              <TenantField key={tenant.tableId}>
                                {tenant?.firstname} {tenant?.lastname}
                              </TenantField>
                            ))
                          : '-'}
                      </ValueCol>
                    </Field>

                    <Field>
                      <LabelCol xs={12}>{t('ADDRESS')}</LabelCol>
                      <ValueCol xs={12}>{streetNumberValue}</ValueCol>
                      <ValueCol xs={12}>{zipCityValue}</ValueCol>
                    </Field>

                    <Field>
                      <LabelCol xs={12}>{t('FLOOR AND POSITION')}</LabelCol>
                      <ValueCol xs={12}>{floorPositionValue}</ValueCol>
                    </Field>
                  </Col>
                </Row>
              )}
            </CenterCol>

            <Col style={{ display: 'flex', alignItems: 'center' }}>
              <Container>
                {loading ? (
                  <SkeletonList numberOfItems={2} />
                ) : (
                  <>
                    <Row justify='between' style={{ paddingBottom: '2rem' }}>
                      <BigLabelCol>{t('PERFORMANCE LOG')}</BigLabelCol>
                      <SinglePdfDownload
                        value={appointment.tableId}
                        type='appointment'
                      >
                        <BigLabelCol xs='content'>
                          <Icon
                            icon='download'
                            style={{ color: '#00B6E7', cursor: 'pointer' }}
                            size='25px'
                          />
                        </BigLabelCol>
                      </SinglePdfDownload>
                    </Row>
                    <Row>
                      <BigLabelCol xs={4} style={{ display: 'flex' }}>
                        <img
                          src={downloadImg}
                          alt='download icon'
                          width='70px'
                        />
                      </BigLabelCol>
                      <BigLabelCol xs={8} style={{ alignSelf: 'flex-end' }}>
                        {fDate}
                      </BigLabelCol>
                    </Row>
                  </>
                )}
              </Container>
            </Col>
          </Row>
        </Box>
      </Row>
    </Container>
  )
}

Overview.propTypes = {
  appointment: PropTypes.shape({
    tableId: PropTypes.any,
    status: PropTypes.string,
    usageUnit: PropTypes.shape({
      tableId: PropTypes.any,
      floor: PropTypes.any,
      position: PropTypes.string,
      tenants: PropTypes.array,
    }),
    referenceNumber: PropTypes.string,
    address: PropTypes.object,
    date: PropTypes.string,
    type: PropTypes.string,
    timeframe: PropTypes.string,
    executionDate: PropTypes.string,
  }),
  loading: PropTypes.bool,
}

const Box = styled(Col)`
  background-color: ${portalTheme.color.basicColorWhite};

  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};

  padding: 24px !important;
`

const Field = styled(Row)`
  margin-bottom: 24px;

  &:last-child {
    margin: 0;
  }
`

const LabelCol = styled(Col)`
  font-size: 13px;

  margin-bottom: 4px;
`

const ValueCol = styled(Col)`
  font-weight: ${portalTheme.font.weight.bold};
  font-size: 16px;
`

const TenantField = styled.p`
  margin: 0;
`

const BigLabelCol = styled(Col)`
  font-size: 14px;
`

const CenterCol = styled(Col)`
  border-right: 1px solid ${portalTheme.color.linkColorSecondary};
`

export default Overview
