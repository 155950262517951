import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query AttachmentsGridQuery($companyId: ID!, $letterId: ID!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          letter(id: $letterId) {
            data: attachments {
              id
              tableId
              name
              extension
              filename
            }
          }
        }
      }
    }
  }
`
