import React from 'react'
import styled from 'styled-components/macro'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'

import { Icon, portalTheme } from 'pyrexx-react-library'

const GridContainer = styled.div`
  position: relative;

  height: 600px;
  width: 100%;
`

const gridOptions = {
  sortingOrder: ['desc', 'asc'],
  defaultColDef: {
    sortable: false,
    menuTabs: ['filterMenuTab', 'generalMenuTab'],
    wrapText: true,
    autoHeight: true,
    resizable: true,
  },
  animateRows: true,
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
  enableRangeSelection: true,
  scrollbarWidth: 15,
  popupParent: document.querySelector('body'),
  sideBar: {
    toolPanels: [],
  },
}

const DownloadIcon = ({ value = '' }) => {
  return (
    <a href={value} target='_blank' rel='noopener noreferrer'>
      <Icon
        icon='download'
        size={20}
        color={portalTheme.color.linkColorPrimary}
      />
    </a>
  )
}

const DownloadCenterGrid = ({ rowData = {} }) => {
  return (
    <GridContainer className='ag-theme-pyrexx'>
      <AgGridReact rowData={rowData} gridOptions={gridOptions}>
        <AgGridColumn checkboxSelection headerCheckboxSelection width='50' />
        <AgGridColumn
          field='title'
          headerName='Title'
          width='400'
          sort='asc'
          sortable='true'
          filter='agTextColumnFilter'
          filterParams={{
            buttons: ['apply', 'clear', 'reset'],
            closeOnApply: true,
            suppressAndOrCondition: true,
          }}
          flex={5}
        />
        <AgGridColumn
          field='source_url'
          headerName=''
          width='100'
          cellRenderer={DownloadIcon}
          flex={1}
        />
      </AgGridReact>
    </GridContainer>
  )
}

export default DownloadCenterGrid
