import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { Icon } from 'pyrexx-react-library'
import EmptyButton from '../Simple/EmptyButton'
import DropdownBadge from './DropdownBadge'

const Wrapper = styled(DropdownBadge)`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  width: auto;
  max-width: 350px;

  background-color: #c5cfda;
`

const Label = styled.div`
  display: flex;

  margin-right: 8px;

  word-break: break-all;
  hyphens: auto;
`

const CloseButton = styled(EmptyButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  border-radius: 50%;
  padding: 5px;
  color: #fff;

  transition: 200ms ease-in;
  outline: none;

  &:hover,
  &:focus {
    background-color: #acb8bf;
  }

  &:active {
    background-color: #7e878c;
  }
`

const FilterBadge = ({
  children = '',
  handleClose = () => {},
  style,
  ...restOfProps
}) => {
  return (
    <Wrapper style={style} {...restOfProps}>
      <Label>{children}</Label>
      <CloseButton onClick={handleClose}>
        <Icon icon='close' size='10px' />
      </CloseButton>
    </Wrapper>
  )
}

FilterBadge.propTypes = {
  children: PropTypes.any,
  handleClose: PropTypes.func,
}

export default FilterBadge
