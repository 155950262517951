import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import VariableList from './VariableList'

const VariablesBox = ({
  variables = [],
  editorRef,
  divideIntoColumns = false,
}) => {
  const { t } = useTranslation()

  if (variables.length === 0) {
    return (
      <EmptyVariablesWrapper>
        <p>{t('NO AVAILABLE VARIABLES')}</p>
      </EmptyVariablesWrapper>
    )
  }

  return (
    <Wrapper divideIntoColumns={divideIntoColumns}>
      <VariableList
        variables={variables}
        onClick={(id) => {
          // console.log(editorRef.current)
          editorRef.current.execute('insertVariable', id)
          editorRef.current.editing.view.focus()
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid #ccced1;
  border-color: var(--ck-color-base-border, #ccced1);
  padding: 8px;

  ${(props) => {
    if (props.divideIntoColumns) {
      return `
        & > div > div {
         flex-wrap: wrap;
         max-height: 200px;
        }
      `
    }
  }}
`

const EmptyVariablesWrapper = styled(Wrapper)`
  background-color: rgba(239, 244, 247, 0.4);

  & p {
    text-align: center;
    margin: 20px;
    font-size: 20px;
    font-family: 'Quicksand', sans-serif;
  }
`

export default VariablesBox
