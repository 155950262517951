import { appointmentAccessReasonEnumList } from '../../../components/AgGrid/enumLists'
const noEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessValueGetter = (
  props
) => {
  const { value } = props

  if (!value || !value.length > 0) {
    return ''
  }
  return appointmentAccessReasonEnumList(value[0].accessReason)
}

export default noEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessValueGetter
