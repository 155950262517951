import React from 'react'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  gap: 24px;

  padding: 24px 32px;
  background-color: #fff;
`

const NameColumn = styled.div`
  flex: 25%;
`

const Name = styled.h4`
  margin: 0;
`

const DescriptionColumn = styled.div`
  flex: 75%;
`

const RoleInformationItem = ({ name, description }) => {
  return (
    <Wrapper>
      <NameColumn>
        <Name>{name}</Name>
      </NameColumn>
      <DescriptionColumn>{description}</DescriptionColumn>
    </Wrapper>
  )
}

export default RoleInformationItem
