import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query GetDropdownDataQuery($companyId: ID!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          companyLayouts(
            options: {
              filters: [
                {
                  name: "status"
                  filterType: text
                  operator: AND
                  conditions: [{ value: ["ACTIVE"], type: equals }]
                }
              ]
            }
          ) {
            id
            tableId
            name
            layout
            status
          }
        }
      }
    }
  }
`
