import React from 'react'
import { Redirect } from 'react-router-dom'
// eslint-disable-next-line import/no-unresolved
import { RumRoute as Route } from 'pyrexx-react-library'
import { Routes } from '../configs/RouteConfig'
import { useUserContext } from '../helper/userContext'

function PrivateRoute({ children, ...rest }) {
  const { user, currentLanguage } = useUserContext()

  const permissions = user?.userPermissions || []
  const isAdmin = permissions.includes('ADMIN')

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/' + currentLanguage + Routes.user,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
