import styled from 'styled-components/macro'
import { portalTheme } from 'pyrexx-react-library'

export const TitleModal = styled.h3`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  font-size: ${portalTheme.font.size.headingTiny};
  margin-bottom: 0;
`
export const TransferPurpose = styled.div`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  font-size: ${portalTheme.font.size.headingRegular};
  border: 2px solid ${portalTheme.color.linkColorPrimaryHover};
  user-select: text;
  cursor: text;
  height: 48px;
`
