import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import {
  ContractPartitionRenderer,
  NoEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessDateRenderer,
  NoEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessRenderer,
  NoEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeRenderer,
  NoEntryIntoTheUsageUnitWithoutEquipmentStatusRenderer,
  TenantFullNameRenderer,
  UsageUnitReferenceNumberRenderer,
} from '../renderers'
import {
  Filter5NoEntryIntoTheUsageUnitWithoutEquipmentCountQuery,
  Filter5NoEntryIntoTheUsageUnitWithoutEquipmentQuery,
} from '../queries'
import {
  contractPartitionValueGetter,
  dateTimeValueGetter,
  tenantFullNameValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { getPriorityHeightFromDisplayedColumns } from '../../../helper/calculateRowHeight'
import noEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessValueGetter from '../valueGetter/noEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessValueGetter'
import noEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeValueGetter from '../valueGetter/noEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeValueGetter'

export const useFilter5NoEntryIntoTheUsageUnitWithoutEquipmentGrid = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService) => {
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t('NO ACCESS - NO OUTFITTING')
          case 'appointments.accessReason':
            return t(
              noEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessValueGetter(
                params
              )
            )
          case 'appointments.type':
            return t(
              noEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeValueGetter(
                params
              )
            )
          case 'tenants':
            return tenantFullNameValueGetter(params)
          case 'appointments.executionDate':
            return dateTimeValueGetter({
              value:
                params?.value && params?.value[0]
                  ? params.value[0].executionDate
                  : '',
              currentLanguage: currentLanguage,
              formatStr: 'Pp',
            })
          case 'billingPartitions.name':
            return contractPartitionValueGetter(params)
          default:
            return params.value
        }
      }
      const getRowHeight = (params) => {
        const displayedColumns =
          params.node.columnApi?.getAllDisplayedVirtualColumns()
        const data = params?.data
        const getWidth = (field) =>
          params.node.columnApi?.getColumn?.(field)?.getActualWidth?.()

        const columnParams = [
          {
            field: 'billingPartitions',
            value: data?.billingPartitions,
            fieldProp: 'name',
            colWidth: getWidth('billingPartitions'),
            extraRows: 1,
            isLastCol: true,
          },
          {
            field: 'appointments.accessReason',
            value: data?.appointments?.[0]?.accessReason,
            colWidth: getWidth('appointments.accessReason'),
          },
        ]

        return getPriorityHeightFromDisplayedColumns({
          columnParams,
          displayedColumns,
          defaultHeight: 110,
        })
      }
      const Filter5NoEntryIntoTheUsageUnitWithoutEquipmentColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          colId: 'status',
          width: 70,
          sortable: false,
          filter: false,
          cellRenderer: NoEntryIntoTheUsageUnitWithoutEquipmentStatusRenderer,
        },
        {
          headerName: t('ALLOCATION NUMBER'),
          field: 'tableId',
          cellRenderer: UsageUnitReferenceNumberRenderer,
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 1 }),
          field: 'CAN.one',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 2 }),
          field: 'CAN.two',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 3 }),
          field: 'CAN.three',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 4 }),
          field: 'CAN.four',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 5 }),
          field: 'CAN.five',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('STREET'),
          field: 'address.street',
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('STREET NR.'),
          field: 'address.number',
          width: 70,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('CITY'),
          field: 'address.city',
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('RESIDENT'),
          field: 'tenants',
          width: 110,
          cellRenderer: TenantFullNameRenderer,
          filter: false, // TODO: make filterable?
        },
        {
          headerName: t('ACCESS'),
          colId: 'appointments.accessReason',
          field: 'appointments',
          width: 80,
          cellRenderer:
            NoEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessRenderer,
          filter: false, // TODO: make filterable?
          sortable: false,
        },
        {
          headerName: t('ACCESS DATE'),
          colId: 'appointments.executionDate',
          field: 'appointments',
          width: 100,
          cellRenderer:
            NoEntryIntoTheUsageUnitWithoutEquipmentAppointmentAccessDateRenderer,
          filter: false, // TODO: make filterable?
          sortable: false,
        },
        {
          headerName: t('APPOINTMENT TYPE'),
          colId: 'appointments.type',
          field: 'appointments',
          width: 100,
          cellRenderer:
            NoEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeRenderer,
          filter: false, // TODO: make filterable?
          sortable: false,
        },
        {
          headerName: t('CONTRACT PARTITION'),
          field: 'billingPartitions',
          colId: 'billingPartitions.name',
          width: 100,
          sortable: false,
          filter: false, // TODO: make filterable?
          cellRenderer: ContractPartitionRenderer,
        },
      ]
      const Filter5NoEntryIntoTheUsageUnitWithoutEquipmentCustomFilter = [
        {
          name: 'contract.serviceCategory.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [currentService.tableId],
              type: 'equals',
            },
          ],
        },
        {
          filterType: 'text',
          operator: 'AND',
          name: 'installedSmokeDetectors',
          conditions: [
            {
              type: 'equals',
              value: '0',
            },
          ],
        },
        {
          filterType: 'text',
          operator: 'AND',
          name: 'appointment.accessReason',
          conditions: [
            {
              type: 'equals',
              value: [
                'NO_ACCESS_DENIED',
                'NO_ACCESS_VACANT_NO_KEY',
                'NO_ACCESS_RENOVATION',
                'NO_ACCESS_HYGIENICALLY_INFEASIBLE',
                'NO_ACCESS_DENIED_NEED_RESPITE',
                'NO_ACCESS_VACANT_KEY_DOES_NOT_FIT',
              ],
            },
          ],
        },
      ]
      return {
        query: Filter5NoEntryIntoTheUsageUnitWithoutEquipmentQuery,
        columnDefs: Filter5NoEntryIntoTheUsageUnitWithoutEquipmentColumnDefs,
        customFilters:
          Filter5NoEntryIntoTheUsageUnitWithoutEquipmentCustomFilter,
        countQuery: Filter5NoEntryIntoTheUsageUnitWithoutEquipmentCountQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          getRowHeight,
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
      }
    },
    [currentLanguage, t]
  )
  return { getParams }
}
