function dummyTranslate(text) {
  return text
}

const syncStatusTypeEnumList = (value) => {
  switch (value) {
    case 'IN_PREPARATION':
      return dummyTranslate('IN PROGRESS')
    case 'IN_CLARIFYING':
      return dummyTranslate('IN PROGRESS')
    case 'DATA_INCORRECT_REQUESTED':
      return dummyTranslate('DATA INCORRECT & REQUESTED AGAIN')
    case 'UPCOMING':
      return dummyTranslate('IN PROGRESS')
    case 'DEACTIVATED':
      return dummyTranslate('DEACTIVATED')
    case 'DONE':
      return dummyTranslate('DONE')
    case 'IN_PROGRESS':
      return dummyTranslate('IN PROGRESS')
    case 'NONE':
    default:
      return ''
  }
}

export default syncStatusTypeEnumList
