import React from 'react'
import { useTranslation } from 'react-i18next'

import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'

import { AppointmentStatusMap } from '../../../constants/colorMap'
import { appointmentStatusEnumList } from '../../../components/AgGrid/enumLists'

export default React.forwardRef((props, ref) => {
  const { value } = props
  const { t } = useTranslation()

  // src: https://www.ag-grid.com/documentation/react/fine-tuning/#styling-a-react-component
  React.useImperativeHandle(ref, () => ({
    getReactContainerStyle() {
      return {
        // display: 'flex',
        // alignItems: 'center',
        // height: '100%',
      }
    },
  }))
  return (
    <StatusLightWithLabel
      status={AppointmentStatusMap[value]}
      label={t(appointmentStatusEnumList(value))}
    />
  )
})
