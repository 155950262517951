/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSalutationType = "COMPANY" | "MR" | "MRS" | "NONE" | "%future added value";
export type UserTitleType = "DR" | "NONE" | "PROF" | "PROF_DR" | "%future added value";
export type AuthenticateMutationVariables = {|
  username: string,
  password: string,
|};
export type AuthenticateMutationResponse = {|
  +info: {|
    +status: boolean,
    +accessToken: ?string,
    +refreshToken: ?string,
    +Me: ?{|
      +id: string,
      +name: ?string,
      +firstname: ?string,
      +lastname: ?string,
      +salutation: UserSalutationType,
      +title: UserTitleType,
      +can: $ReadOnlyArray<?string>,
      +role: ?string,
      +email: ?string,
      +Companies: ?$ReadOnlyArray<?{|
        +id: string,
        +name: string,
      |}>,
    |},
  |}
|};
export type AuthenticateMutation = {|
  variables: AuthenticateMutationVariables,
  response: AuthenticateMutationResponse,
|};
*/


/*
mutation AuthenticateMutation(
  $username: String!
  $password: String!
) {
  info: authenticate(username: $username, password: $password) {
    status
    accessToken
    refreshToken
    Me {
      id
      name
      firstname
      lastname
      salutation
      title
      can
      role
      email
      Companies {
        id
        name
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": "info",
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "username",
        "variableName": "username"
      }
    ],
    "concreteType": "Token",
    "kind": "LinkedField",
    "name": "authenticate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "salutation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "can",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyInfo",
            "kind": "LinkedField",
            "name": "Companies",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthenticateMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AuthenticateMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "61d6d01dabe18e98605fe07bbb0d89c7",
    "id": null,
    "metadata": {},
    "name": "AuthenticateMutation",
    "operationKind": "mutation",
    "text": "mutation AuthenticateMutation(\n  $username: String!\n  $password: String!\n) {\n  info: authenticate(username: $username, password: $password) {\n    status\n    accessToken\n    refreshToken\n    Me {\n      id\n      name\n      firstname\n      lastname\n      salutation\n      title\n      can\n      role\n      email\n      Companies {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '069155bc26f3b770ccb92916d0ed5b2f';

module.exports = node;
