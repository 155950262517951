import React, { useCallback, useReducer, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { ButtonPrimary, SimpleModal, SimpleNote } from 'pyrexx-react-library'
import Upload from './Upload'
import ImageCropper from './ImageCropper'

import handleLocalStorage from '../../helper/handleLocalStorage'
import { CenterRow } from '../../styles/HelperStylesComponents.styles'
import getCroppedImg from './cropImage'
import UploadCompanyLogoMutation from '../../mutations/UploadCompanyLogoMutation'
import { useUserContext } from '../../helper/userContext'

import {
  initialState,
  SET_LOADER,
  CLOSE_COMPONENT,
  SUBMIT_WAS_SUCCESSFUL,
  SUBMIT_FAILED,
  submitReducer,
} from '../../reducers/submitReducer'

const OUTPUT_IMAGE_DIMENSIONS = { width: 200, height: 200 }

const ImageUpload = () => {
  const { user } = useUserContext()
  const { t } = useTranslation()

  const [image, setImage] = useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [state, dispatch] = useReducer(submitReducer, initialState)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const saveCroppedImage = useCallback(async () => {
    try {
      // croppedImage -> { blob: null, base64: null }
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        undefined,
        undefined,
        OUTPUT_IMAGE_DIMENSIONS
      )
      // console.log('done', { croppedImage })
      return croppedImage
      // setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, image])

  const onError = () => {
    dispatch({
      type: SUBMIT_FAILED,
      payload: t('ERROR UPLOADING LOGO'),
    })
    console.log('Error uploading logo')
  }

  const uploadImage = () => {
    dispatch({ type: SET_LOADER })

    saveCroppedImage()
      .then((croppedImage) => {
        UploadCompanyLogoMutation(
          { companyId: user.companyId, file: null },
          croppedImage.blob,
          user.accessToken,
          () => {
            dispatch({
              type: SUBMIT_WAS_SUCCESSFUL,
              payload: t('IMAGE UPLOADED SUCCESSFULLY'),
            })
            // save in local storage
            handleLocalStorage('set', 'companyLogo', croppedImage.base64)
          },
          onError
        )
      })
      .catch(onError)
  }

  return (
    <>
      <Wrapper>
        <LeftCol noImage={!image}>
          <Upload setImgSrc={setImage} />
        </LeftCol>

        {image && (
          <RightCol>
            <ImageCropper image={image} onCropComplete={onCropComplete} />
          </RightCol>
        )}
      </Wrapper>

      {image && (
        <CenterRow style={{ marginTop: '1rem' }}>
          <ButtonPrimary onClick={uploadImage} isLoading={state.loading}>
            {t('UPLOAD')}
          </ButtonPrimary>
        </CenterRow>
      )}

      <SimpleModal
        isOpen={state.showComponent}
        handleModalClose={() => dispatch({ type: CLOSE_COMPONENT })}
      >
        <SimpleNote text={state.message} noteStatus={state.status} />
      </SimpleModal>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
`

const LeftCol = styled.div`
  flex: 1;

  ${({ noImage }) =>
    noImage &&
    `
    width: 50%;
    flex: unset;
  `}
`

const RightCol = styled.div`
  flex: 2;
`

export default ImageUpload
