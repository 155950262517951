import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@reach/disclosure'

import BaseGridModal from './BaseGridModal'

import { ButtonIcon, GridContainer, StyledButton, StyledPanel } from './styles'
import defaultGridOptions from './defaultGridOptions'

const BaseGrid = (props) => {
  const {
    id = '',
    rowData = [],
    currentData = [],
    selectedData = [],
    handleSelectionChange = (id, rowData, selectedIds) => {},
    title = '',
    children,
    // loading = false,
    className,
  } = props

  const [isOpen, setIsOpen] = useState(false)
  const [modal, setModal] = useState({ show: false, message: '' })
  const closeModal = () => setModal({ show: false, message: '' })

  const onRowSelected = (params) => {
    if (id === 'contracts') {
      const billingPartitionIds = params?.node?.data?.billingPartitions || []
      const diff = billingPartitionIds.filter((bp) =>
        selectedData.includes(bp.tableId)
      )
      if (diff.length > 0) {
        params.node.setSelected(false)

        const contractId = params?.node?.data?.tableId
        const billingPartitions = selectedData.reduce(
          (prev, curr) => prev + ', ' + curr
        )

        setModal({
          show: true,
          message: `YOU CAN NOT SELECT CONTRACTS WHEN A RELATED BILLING PARTITION WAS ALREADY SELECTED.\n\n CONTRACT NR: ${contractId}, BILLING PARTITIONS NRS: ${billingPartitions}`,
        })
      }
    }

    if (id === 'billingPartitions') {
      const contractId = params?.node?.data?.contractId || null

      if (selectedData.includes(contractId)) {
        params.node.setSelected(false)

        const billingPartitionId = params?.node?.data?.tableId

        setModal({
          show: true,
          message: `YOU CAN NOT SELECT BILLING PARTITIONS WHEN A RELATED CONTRACT WAS ALREADY SELECTED.\n\n CONTRACT NR: ${contractId}, BILLING PARTITION: ${billingPartitionId}`,
        })
      }
    }
  }

  const onSelectionChanged = (params) => {
    const selectedIds = params.api.getSelectedRows().map((r) => r.tableId)
    handleSelectionChange(id, rowData, selectedIds)
  }

  const onRowDataUpdated = (params) => {
    params.api.getModel().forEachNode(function (node) {
      const defaultCheckboxValue = currentData.includes(node.data.tableId)

      // suppressFinishActions parameter prevents onSelectionChanged event to be triggered
      node.setSelected(defaultCheckboxValue, null, true)
    })

    onSelectionChanged(params)
  }

  return (
    <Disclosure open={isOpen} onChange={() => setIsOpen(!isOpen)}>
      <div>
        <DisclosureButton as={StyledButton}>
          <h4>{title}</h4>
          <ButtonIcon open={isOpen} />
        </DisclosureButton>

        <DisclosurePanel as={StyledPanel}>
          <GridContainer className={'ag-theme-pyrexx ' + className}>
            <AgGridReact
              onRowSelected={onRowSelected}
              onSelectionChanged={onSelectionChanged}
              onRowDataUpdated={onRowDataUpdated}
              rowData={rowData}
              {...defaultGridOptions}
            >
              <AgGridColumn
                checkboxSelection
                headerCheckboxSelection
                width='100'
              />
              {children}
            </AgGridReact>
          </GridContainer>
        </DisclosurePanel>
      </div>

      <BaseGridModal modal={modal} closeModal={closeModal} />
    </Disclosure>
  )
}

BaseGrid.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  currentData: PropTypes.array,
  handleSelectionChange: PropTypes.func,
  id: PropTypes.string,
  loading: PropTypes.bool,
  rowData: PropTypes.array,
  selectedData: PropTypes.array,
  title: PropTypes.string,
}

export default BaseGrid
