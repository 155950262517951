import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../environments/pom/Environment'

const mutation = graphql`
  mutation DeleteCompanyLayoutMutation(
    $companyId: ID!
    $companyLayoutId: Int!
  ) {
    Me {
      Company(id: $companyId) {
        businessPost {
          deleteCompanyLayout(companyLayoutId: $companyLayoutId)
        }
      }
    }
  }
`

export default (variables = {}, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
