/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PermissionType = "ADMIN" | "APPOINTMENT" | "CONTRACT" | "DATA_UPDATE" | "INVENTORY" | "INVOICE" | "NONE" | "POST_LETTER" | "POST_VIEW_ALL" | "QUOTA" | "%future added value";
export type UpdateUserMutationVariables = {|
  companyId: string,
  userId: string,
  firstname?: ?string,
  lastname?: ?string,
  permissions: $ReadOnlyArray<?PermissionType>,
  contracts?: ?$ReadOnlyArray<?number>,
  billingPartitions?: ?$ReadOnlyArray<?number>,
  removePermissions: $ReadOnlyArray<?PermissionType>,
  removeContracts?: ?$ReadOnlyArray<?number>,
  removeBillingPartitions?: ?$ReadOnlyArray<?number>,
|};
export type UpdateUserMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +User: ?{|
        +changeCoreData: ?boolean,
        +addPermissions: ?boolean,
        +addRestrictions: ?boolean,
        +removePermissions: ?boolean,
        +removeRestrictions: ?boolean,
      |}
    |}
  |}
|};
export type UpdateUserMutation = {|
  variables: UpdateUserMutationVariables,
  response: UpdateUserMutationResponse,
|};
*/


/*
mutation UpdateUserMutation(
  $companyId: ID!
  $userId: ID!
  $firstname: String
  $lastname: String
  $permissions: [PermissionType]!
  $contracts: [Int]
  $billingPartitions: [Int]
  $removePermissions: [PermissionType]!
  $removeContracts: [Int]
  $removeBillingPartitions: [Int]
) {
  Me {
    Company(id: $companyId) {
      User(id: $userId) {
        changeCoreData(firstName: $firstname, lastName: $lastname)
        addPermissions(permissions: $permissions)
        addRestrictions(contracts: $contracts, billingPartitions: $billingPartitions)
        removePermissions(permissions: $removePermissions)
        removeRestrictions(contracts: $removeContracts, billingPartitions: $removeBillingPartitions)
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "billingPartitions"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contracts"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstname"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastname"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "permissions"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "removeBillingPartitions"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "removeContracts"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "removePermissions"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v10 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "userId"
              }
            ],
            "concreteType": "UserMutations",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "firstName",
                    "variableName": "firstname"
                  },
                  {
                    "kind": "Variable",
                    "name": "lastName",
                    "variableName": "lastname"
                  }
                ],
                "kind": "ScalarField",
                "name": "changeCoreData",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "permissions",
                    "variableName": "permissions"
                  }
                ],
                "kind": "ScalarField",
                "name": "addPermissions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "billingPartitions",
                    "variableName": "billingPartitions"
                  },
                  {
                    "kind": "Variable",
                    "name": "contracts",
                    "variableName": "contracts"
                  }
                ],
                "kind": "ScalarField",
                "name": "addRestrictions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "permissions",
                    "variableName": "removePermissions"
                  }
                ],
                "kind": "ScalarField",
                "name": "removePermissions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "billingPartitions",
                    "variableName": "removeBillingPartitions"
                  },
                  {
                    "kind": "Variable",
                    "name": "contracts",
                    "variableName": "removeContracts"
                  }
                ],
                "kind": "ScalarField",
                "name": "removeRestrictions",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserMutation",
    "selections": (v10/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v9/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v8/*: any*/),
      (v7/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateUserMutation",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "aa9b732404443428d879318fc6304ad1",
    "id": null,
    "metadata": {},
    "name": "UpdateUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateUserMutation(\n  $companyId: ID!\n  $userId: ID!\n  $firstname: String\n  $lastname: String\n  $permissions: [PermissionType]!\n  $contracts: [Int]\n  $billingPartitions: [Int]\n  $removePermissions: [PermissionType]!\n  $removeContracts: [Int]\n  $removeBillingPartitions: [Int]\n) {\n  Me {\n    Company(id: $companyId) {\n      User(id: $userId) {\n        changeCoreData(firstName: $firstname, lastName: $lastname)\n        addPermissions(permissions: $permissions)\n        addRestrictions(contracts: $contracts, billingPartitions: $billingPartitions)\n        removePermissions(permissions: $removePermissions)\n        removeRestrictions(contracts: $removeContracts, billingPartitions: $removeBillingPartitions)\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a70bea0db4e7f980bd312a985c824e55';

module.exports = node;
