import React from 'react'
import styled from 'styled-components/macro'

const PageTitle = ({ children, ...restOfProps }) => {
  return (
    <Wrapper {...restOfProps}>
      <Header>{children}</Header>
    </Wrapper>
  )
}

export default PageTitle

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  position: relative;
`

const Header = styled.h2``
