import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Sticky from '../Sticky'
import ActionCenter from './ActionCenter'
import DownloadModal from './DownloadModal'

import * as S from './ActionCenter.styles'
import { Col, Row } from 'react-grid-system'
import styled from 'styled-components'
import { ButtonIcon, portalTheme } from 'pyrexx-react-library'
import OwnFilters from '../AgGrid/AgGridUltra/components/OwnFilters/OwnFilters'

const FullScreenButton = styled(ButtonIcon)`
  &:hover,
  &:focus {
    background-color: #dbe7f3;
  }
`

const ActionCenterContainer = (props) => {
  const { t } = useTranslation()
  const {
    minimalVersion = false,
    boxedVersion = false,
    noFilterMenu = false,
    noActionDropDown = false,
    dropDownItems = [
      { type: 'support' },
      { type: 'exportWholeList' },
      { type: 'exportSelected' },
    ],
    gridApi,
    gridColumnApi,
    selectedRows = [],
    setForceRefresh,
    withPDFDownload,
    handlePDFPromises,
    title = '',
    isFullScreen,
    setIsFullScreen,
    actionCenterHeight,
    filterVariables,
    filterModel,
    setFilterModel,
    fileDownloadName = t('FILE'),
    extraItems = false,
    gridStateName,
    gridState,
    filterOptions,
    setFilterOptions,
    currentFilter,
    setCurrentFilter,
    ownFilterChanged,
    setOwnFilterChanged,
    getFiltersData,
    currentGrid,
    loadingFilter,
    setLoadingFilter,
  } = props

  const [downloadModal, setDownloadModal] = useState('')
  const openDownloadModal = (modal) => setDownloadModal(modal)
  const closeDownloadModal = () => setDownloadModal('')

  const [downloadProgress, setDownloadProgress] = useState(0)

  const generateExtraItems = useCallback(() => {
    if (extraItems !== false && typeof extraItems === 'object') {
      return extraItems
    }

    if (extraItems !== false) {
      return extraItems(selectedRows, setForceRefresh, gridApi)
    }
  }, [extraItems, gridApi, selectedRows, setForceRefresh])

  const generateMinimalActionCenter = useCallback(() => {
    return (
      <ActionCenter
        key='minimalActionCenter'
        dropDownItems={dropDownItems}
        minimalVersion
        downloadModal={downloadModal}
        openDownloadModal={openDownloadModal}
        downloadProgress={downloadProgress}
        numberOfItems={selectedRows.length}
        setForceRefresh={setForceRefresh}
        selectedRows={selectedRows}
        labelText={t('SELECTED')}
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        filterVariables={filterVariables}
        gridApi={gridApi}
      />
    )
  }, [
    downloadModal,
    downloadProgress,
    dropDownItems,
    filterModel,
    filterVariables,
    gridApi,
    selectedRows,
    setFilterModel,
    setForceRefresh,
    t,
  ])

  const generateActionCenter = useCallback(() => {
    const getActionCenterRow = () => {
      return (
        <S.ActionCenterRow
          boxedVersion={boxedVersion}
          actionCenterHeight={actionCenterHeight}
          isFullScreen={isFullScreen}
        >
          <Row align='center' nogutter style={{ height: '100%' }}>
            <Col style={{ display: 'flex', justifyContent: 'flex-start' }}>
              {!noActionDropDown && (
                <ActionCenter
                  key='defaultActionCenter'
                  downloadModal={downloadModal}
                  dropDownItems={dropDownItems}
                  openDownloadModal={openDownloadModal}
                  downloadProgress={downloadProgress}
                  numberOfItems={selectedRows.length}
                  setForceRefresh={setForceRefresh}
                  selectedRows={selectedRows}
                  labelText={t('SELECTED')}
                  filterModel={filterModel}
                  setFilterModel={setFilterModel}
                  filterVariables={filterVariables}
                  gridApi={gridApi}
                />
              )}
            </Col>
            <Col xs='content'>
              <h3 style={{ margin: 0, fontSize: '14px' }}>{title}</h3>
            </Col>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {generateExtraItems()}
              {!noFilterMenu && (
                <OwnFilters
                  isFullScreen={isFullScreen}
                  gridStateName={gridStateName}
                  gridState={gridState}
                  gridColumnApi={gridColumnApi}
                  setFilterModel={setFilterModel}
                  filterOptions={filterOptions}
                  setFilterOptions={setFilterOptions}
                  currentFilter={currentFilter}
                  setCurrentFilter={setCurrentFilter}
                  ownFilterChanged={ownFilterChanged}
                  setOwnFilterChanged={setOwnFilterChanged}
                  getFiltersData={getFiltersData}
                  currentGrid={currentGrid}
                  gridApi={gridApi}
                  loadingFilter={loadingFilter}
                  setLoadingFilter={setLoadingFilter}
                />
              )}

              <FullScreenButton
                icon={isFullScreen ? 'reducesize' : 'fullscreen'}
                size='25px'
                style={{
                  color: portalTheme.color.linkColorPrimary,
                  width: '25px',
                  height: '25px',
                  marginLeft: '15px',
                }}
                handleClick={() => {
                  setIsFullScreen(!isFullScreen)
                }}
              />
            </Col>
          </Row>
        </S.ActionCenterRow>
      )
    }

    return isFullScreen ? (
      getActionCenterRow()
    ) : (
      <Sticky shadow={false}>{getActionCenterRow()}</Sticky>
    )
  }, [
    isFullScreen,
    boxedVersion,
    actionCenterHeight,
    noActionDropDown,
    downloadModal,
    dropDownItems,
    downloadProgress,
    selectedRows,
    setForceRefresh,
    t,
    filterModel,
    setFilterModel,
    filterVariables,
    gridApi,
    title,
    generateExtraItems,
    noFilterMenu,
    gridStateName,
    gridState,
    gridColumnApi,
    filterOptions,
    setFilterOptions,
    currentFilter,
    setCurrentFilter,
    ownFilterChanged,
    setOwnFilterChanged,
    getFiltersData,
    currentGrid,
    loadingFilter,
    setLoadingFilter,
    setIsFullScreen,
  ])

  return (
    <>
      {minimalVersion ? generateMinimalActionCenter() : generateActionCenter()}
      <DownloadModal
        downloadModal={downloadModal}
        modalId={minimalVersion ? 'minimal-modal-download' : 'modal-download'}
        closeDownloadModal={closeDownloadModal}
        setDownloadProgress={setDownloadProgress}
        gridApi={gridApi}
        selectedRows={selectedRows}
        withPDFDownload={withPDFDownload}
        handlePDFPromises={handlePDFPromises}
        fileDownloadName={fileDownloadName}
      />
    </>
  )
}

export default ActionCenterContainer

ActionCenterContainer.propTypes = {
  gridApi: PropTypes.object,
  selectedRows: PropTypes.array,
  countQuery: PropTypes.any,
  withPDFDownload: PropTypes.bool,
  handlePDFPromises: PropTypes.func,
  title: PropTypes.string,
}
