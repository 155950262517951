import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-grid-system'
import {
  Icon,
  LetterLayout,
  LoadingSpinner,
  ModalPdfViewer,
  portalTheme,
} from 'pyrexx-react-library'
import { useUserContext } from '../../helper/userContext'
import SimpleLink from '../../components/Simple/SimpleLink'
import { Routes } from '../../configs/RouteConfig'
import { useLocation } from 'react-router-dom'
import SimpleButton from '../../components/Simple/SimpleButton'
import { DeleteCompanyLayoutMutation } from '../CreateCompanyLayout/mutations'
import companyLayoutStatusEnumList from '../../components/AgGrid/enumLists/companyLayoutStatusEnumList'
import styled from 'styled-components/macro'
import { fetchQuery } from 'react-relay'
import environment from '../../environments/pom/Environment'
import { CompanyLayoutPreviewQuery } from './queries'

const LayoutCol = styled(Col)`
  &:nth-child(n + 3) {
    margin-top: 20px;
  }
`

const SingleCompanyLayoutItem = (props) => {
  const { layout, retry } = props
  const { t } = useTranslation()
  const { user, currentLanguage } = useUserContext()
  const [showDelete, setShowDelete] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const location = useLocation()
  const [modelOpen, setModelOpen] = useState(0) // 0 none; 1:Send confirmation 2:Valiation check 3: no cost position 4: letterSent
  const [previewLoad, setPreviewLoad] = useState(true)
  const [preview, setPreview] = useState(false)
  const generateShowDelete = useCallback(() => {
    return (
      <Row>
        <Col>
          <Row>
            <Col>{t('DELETE?')}</Col>
          </Row>
          {!showLoading ? (
            <Row>
              <Col>
                <SimpleButton
                  onClick={() => {
                    setShowLoading(true)
                    DeleteCompanyLayoutMutation(
                      {
                        companyId: user.companyId,
                        companyLayoutId: layout.tableId,
                      },
                      user.accessToken,
                      () => {
                        retry()
                      }
                    )
                  }}
                >
                  {t('YES')}
                </SimpleButton>
              </Col>
              <Col>
                <SimpleButton
                  onClick={() => {
                    setShowDelete(false)
                  }}
                >
                  {t('NO')}
                </SimpleButton>
              </Col>
            </Row>
          ) : (
            <div style={{ height: '30px', width: '30px' }}>
              <LoadingSpinner
                theme={{
                  components: {
                    spinner: {
                      /**
                       * Has to be a { css } oject from styled-components
                       */
                      fontSize: portalTheme.font.size.bodySmall,
                      fontWeight: portalTheme.font.weight.regular,
                      size: '20px',
                    },
                  },
                }}
                style={{
                  position: 'unset',
                  backgroundColor: 'white',
                }}
              />
            </div>
          )}
        </Col>
      </Row>
    )
  }, [layout.tableId, retry, showLoading, t, user.accessToken, user.companyId])

  console.log('layout', layout.layout)

  const getCompanyPreview = useCallback(() => {
    setPreviewLoad(true)
    setModelOpen(true)
    fetchQuery(
      environment(user.accessToken),
      CompanyLayoutPreviewQuery,
      {
        companyId: user.companyId,
        companyLayoutId: layout.tableId,
      },
      {
        force: true,
      }
    )
      .then((data) => {
        setPreview(data.Me?.Company?.businessPost.data.preview)
        setPreviewLoad(false)
      })
      .catch((e) => {
        setPreviewLoad(false)
        console.log('OWNFILTER: getFiltersData ERROR CATCH', e)
      })
  }, [layout.tableId, user.accessToken, user.companyId])
  return (
    <LayoutCol xs={6}>
      {showDelete ? (
        generateShowDelete()
      ) : (
        <Row>
          <Col xs='content'>
            <LetterLayout
              containers={JSON.parse(layout.layout)}
              adminEdit={false}
              previewShow={false}
              previewWidth={210}
            />
          </Col>
          <Col>
            <Row>
              <Col xs='content'>
                <SimpleLink
                  to={{
                    pathname:
                      '/' +
                      currentLanguage +
                      Routes.admin +
                      Routes.createCompanyLayoutEdit +
                      '/' +
                      layout.tableId,
                    state: {
                      from: location.pathname,
                    },
                  }}
                  style={{ fontSize: 'inherit' }}
                >
                  <Icon icon='Edit' size={20} style={{ color: 'black' }} />
                </SimpleLink>
              </Col>
              <Col xs='content'>
                <SimpleButton
                  onClick={() => {
                    getCompanyPreview()
                  }}
                >
                  <Icon
                    icon='Preview'
                    size={20}
                    style={{
                      color: portalTheme.color.basicColorBlack,
                    }}
                  />
                </SimpleButton>
              </Col>
              <Col xs='content'>
                <SimpleButton
                  onClick={() => {
                    setShowDelete(true)
                  }}
                >
                  <Icon
                    icon='close'
                    size={20}
                    style={{
                      color: portalTheme.color.errorColor,
                    }}
                  />
                </SimpleButton>
              </Col>
            </Row>
            <Row>
              <Col
                xs='content'
                style={{
                  color:
                    layout.status === 'ACTIVE'
                      ? portalTheme.color.successColor
                      : portalTheme.color.warningColor,
                }}
              >
                {companyLayoutStatusEnumList(layout.status)}
              </Col>
            </Row>
            <Row>
              <Col style={{ wordBreak: 'break-word', hyphens: 'auto' }}>
                {layout.name}
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {modelOpen ? (
        <ModalPdfViewer
          isOpen={modelOpen}
          handleModalClose={() => {
            setModelOpen(false)
          }}
          loading={previewLoad}
          loadingText={t('LOADING')}
          base64={preview}
          filename={layout.name + '.pdf'}
          showButtonClose
          primaryButton={{ usePrimaryButton: false }}
        />
      ) : (
        ''
      )}
    </LayoutCol>
  )
}

export default SingleCompanyLayoutItem
