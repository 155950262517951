import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-grid-system'
import { useUserContext } from '../../../helper/userContext'
import { Routes } from '../../../configs/RouteConfig'
import NavBack from '../../../components/NavBack'
import LayoutPreview from '../../../components/LayoutPreview/LayoutPreview'
import {
  EnqueueLetterMutation,
  UpdateLetterMutation,
  UploadRecipientsMutation,
} from './mutations'
import {
  ButtonPrimary,
  Icon,
  LoadingSpinner,
  ModalPdfViewer,
  portalTheme,
  SimpleModal,
  SimpleNote,
  StyledInput,
} from 'pyrexx-react-library'
import * as Yup from 'yup'
import { setNestedObjectValues } from 'formik'
import { fetchQuery } from 'react-relay'
import environment from '../../../environments/pom/Environment'
import { useParams } from 'react-router-dom'
import DotLoader from '../../../components/LoadingSpinner/DotLoader'
import { LetterPreviewQuery } from '../Letters/queries'
import { LinkButtonStyled } from '../../../styles/HelperStylesComponents.styles'
import replaceTableTag from './helperFunctions/replaceTableTag'
import FileUpload from '../../../components/FileUpload/FileUpload'
import styled from 'styled-components/macro'
import { RecipientsUploadExampleQuery } from './queries'
import { parseInt } from 'lodash'
import Recipients from './Recipients/Recipients'
import { LetterRecipientsQuery } from './Recipients/queries'
import Attachments from './Attachments/Attachments'
import { LetterAttachmentsQuery } from './Attachments/queries'
import { UploadAttachmentsMutation } from './Attachments/mutations'

import CustomEditor from '../../../editor/build/customEditor'
import { availableVariables } from '../../../editor/availableVariables'
import ReactDOM from 'react-dom'
import VariablePreview from '../../../editor/VariablePreview'
import { CKEditor as CKEditorReact } from '@ckeditor/ckeditor5-react'
import SubjectOverride from './SubjectOverride'
import MassLetterTooltip from './MassLetterTooltip'
import UpdateSaveButton from '../../../components/UpdateSavedButton/UpdateSaveButton'
import VariablesBox from '../../../editor/VariablesBox'

const StyledDownloadIcon = styled(Icon)`
  color: ${portalTheme.color.linkColorPrimary};
`
const BusinessMailCreateMassLetter = (props) => {
  const { layoutData, pageCount, setPageCount, pdfPreview, setPdfPreview } =
    props
  const { t } = useTranslation()
  const { user, currentLanguage, redirectRoute } = useUserContext()
  const { id } = useParams()
  const [formikValuesClone, setFormikValuesClone] = useState(false)
  const [enqueueLetterLoader, setEnqueueLetterLoader] = useState(false)
  const [enqueueLetterStatus, setEnqueueLetterStatus] = useState(false)
  const [openModal, setOpenModal] = useState(true)
  const [modalState, setModalState] = useState(0) // 0 none; 1:Send confirmation 2:Valiation check 3: no cost position 4: letterSent
  const [shipConfirm, setShipConfirm] = useState(false)
  const [pdfBase64Loading, setPdfBase64Loading] = useState(false)
  const [containerFormErrors, setContainerFormErrors] = useState([])
  const [csvDownloadLoading, setCsvDownloadLoading] = useState(false)
  const [uploadRecipientsLoading, setUploadRecipientsLoading] = useState(false)
  const [customVariables, setCustomVariables] = useState(
    layoutData?.variables || []
  )

  const [uploadAttachmentsLoading, setUploadAttachmentsLoading] =
    useState(false)
  const [uploadStatusMsg, setUploadStatusMsg] = useState({
    open: false,
    msg: '',
    status: 'success',
  })
  const [layoutChangeStatus, setLayoutChangeStatus] = useState({
    msg: '',
    status: '',
    open: false,
  })

  const returnUndefinedContentVariables = useCallback(
    (content = '', tmpCustomVariables = [], info = 'nothing') => {
      const tmpUndefinedVariables = []
      const regex = /{{var\["(.*?)"\]}}/g
      const foundVariables = []
      let found
      while ((found = regex.exec(content))) {
        foundVariables.push(found[1])
      }
      const tmpAvailAbleVariables = [...tmpCustomVariables]
      availableVariables.forEach((item) => {
        tmpAvailAbleVariables.push(item.name)
      })
      foundVariables.forEach((item) => {
        if (!tmpAvailAbleVariables.find((needle) => needle === item)) {
          tmpUndefinedVariables.push(item)
        }
      })
      return tmpUndefinedVariables
    },
    []
  )

  const subjectContainerKeyId = {
    containerKey: 'content',
    id: 'subject',
  }
  const recipientContainerKeyId = {
    containerKey: 'addressWindow',
    id: 'letterRecipient',
  }
  const contentContainerKeyId = {
    containerKey: 'content',
    id: 'letterContent',
  }

  const [undefinedVariables, setUndefinedVariables] = useState(() => {
    const parseLayout = JSON.parse(layoutData.layout)
    return {
      content: returnUndefinedContentVariables(
        parseLayout[contentContainerKeyId.containerKey].find(
          (object) => object.id === contentContainerKeyId.id
        ).content,
        layoutData?.variables
      ),
      subject: returnUndefinedContentVariables(
        parseLayout[subjectContainerKeyId.containerKey].find(
          (object) => object.id === subjectContainerKeyId.id
        ).content,
        layoutData?.variables
      ),
    }
  })

  const getAllVariables = useCallback(() => {
    const transformedCustomVariables = customVariables.map((v) => ({
      id: v,
      name: v,
      value: v,
      label: v,
    }))

    const tmpUndefinedVariablesContent = undefinedVariables?.content.map(
      (v) => ({
        id: v,
        name: v,
        value: v,
        label: v,
        notAvailable: true,
      })
    )

    const tmpUndefinedVariablesSubject = undefinedVariables?.subject.map(
      (v) => ({
        id: v,
        name: v,
        value: v,
        label: v,
        notAvailable: true,
      })
    )
    const tmpVariables = [
      // ...availableVariables,
      ...transformedCustomVariables,
      ...tmpUndefinedVariablesContent,
      ...tmpUndefinedVariablesSubject,
    ]
    return tmpVariables
  }, [customVariables, undefinedVariables])

  const returnUndefinedVariables = useCallback(() => {
    return undefinedVariables
  }, [undefinedVariables])

  Yup.addMethod(
    Yup.string,
    'checkUndefinedVariableContent',
    function (errorMessage) {
      return this.test(
        `checkUndefinedVariableContent`,
        errorMessage,
        function (value) {
          const { path, createError } = this
          return (
            returnUndefinedVariables()?.content.length < 1 ||
            createError({ path, message: errorMessage })
          )
        }
      )
    }
  )

  Yup.addMethod(
    Yup.string,
    'checkUndefinedVariableSubject',
    function (errorMessage) {
      return this.test(
        `checkUndefinedVariableSubject`,
        errorMessage,
        function (value) {
          const { path, createError } = this
          return (
            undefinedVariables?.subject.length < 1 ||
            createError({ path, message: errorMessage })
          )
        }
      )
    }
  )

  useEffect(() => {}, [pdfPreview])

  const enqueueLetter = useCallback(
    (id, callBack = () => {}) => {
      setEnqueueLetterLoader(true)
      EnqueueLetterMutation(
        {
          companyId: user.companyId,
          letterId: id,
        },
        user.accessToken,
        (finishedLetterData) => {
          setEnqueueLetterStatus(
            finishedLetterData?.Me?.Company?.businessPost?.enqueueLetter
          )
          setModalState(4)
          setOpenModal(true)
          callBack()
          setEnqueueLetterLoader(false)
        }
      )
    },
    [user.accessToken, user.companyId]
  )

  const getLetterAttachments = useCallback(
    (formikTemp) => {
      fetchQuery(
        environment(user.accessToken),
        LetterAttachmentsQuery,
        {
          letterId: id,
          companyId: user.companyId,
        },
        {
          force: true,
        }
      )
        .then((data) => {
          formikTemp.setFieldValue(
            'attachments',
            data.Me?.Company?.businessPost?.letter?.data
          )
          setPdfPreview(data.Me?.Company?.businessPost?.letter?.preview)
          setPageCount(data.Me?.Company?.businessPost?.letter?.pageCount)
          setUploadAttachmentsLoading(false)
        })
        .catch((e) => {
          console.log('PREVIEW PDF: letterPreview ERROR CATCH', e)
          setUploadAttachmentsLoading(false)
        })
    },
    [id, setPageCount, setPdfPreview, user.accessToken, user.companyId]
  )

  const uploadAttachments = useCallback(
    (formikTemp) => {
      setUploadAttachmentsLoading(true)
      const variables = {
        companyId: user.companyId,
        letterId: parseInt(id),
        files: null,
      }
      UploadAttachmentsMutation(
        variables,
        user.accessToken,
        formikTemp.values?.fileUploadAttachments,
        (data) => {
          formikTemp.setFieldValue('fileUploadAttachments', [])
          getLetterAttachments(formikTemp)
        },
        (data) => {
          setUploadAttachmentsLoading(false)
          setUploadStatusMsg({
            open: true,
            msg: t('INVALID FORMAT'),
            status: 'error',
          })
        }
      )
    },
    [getLetterAttachments, id, t, user.accessToken, user.companyId]
  )

  const getLetterRecipients = useCallback(
    (formikTemp) => {
      fetchQuery(
        environment(user.accessToken),
        LetterRecipientsQuery,
        {
          letterId: id,
          companyId: user.companyId,
        },
        {
          force: true,
        }
      )
        .then((data) => {
          formikTemp.setFieldValue(
            recipientContainerKeyId.containerKey +
              '.' +
              recipientContainerKeyId.id +
              '.' +
              'recipients',
            data.Me?.Company?.businessPost?.letter?.data
          )

          setCustomVariables(data.Me?.Company?.businessPost?.letter?.variables)
          const parseContent = JSON.parse(
            data.Me?.Company?.businessPost?.letter?.layout
          )
          const newUndefinedVariables = {
            content: returnUndefinedContentVariables(
              parseContent[contentContainerKeyId.containerKey].find(
                (object) => object.id === contentContainerKeyId.id
              ).content,
              data.Me?.Company?.businessPost?.letter?.variables
            ),
            subject: returnUndefinedContentVariables(
              parseContent[subjectContainerKeyId.containerKey].find(
                (object) => object.id === subjectContainerKeyId.id
              ).content,
              data.Me?.Company?.businessPost?.letter?.variables
            ),
          }

          setUndefinedVariables(newUndefinedVariables)
          setUploadStatusMsg({
            open: true,
            msg: t('UPLOAD SUCCESS'),
            status: 'success',
          })
          setUploadRecipientsLoading(false)
        })
        .catch((e) => {
          console.log('PREVIEW PDF: letterPreview ERROR CATCH', e)
          setUploadRecipientsLoading(false)
        })
    },
    [
      contentContainerKeyId.containerKey,
      contentContainerKeyId.id,
      id,
      recipientContainerKeyId.containerKey,
      recipientContainerKeyId.id,
      returnUndefinedContentVariables,
      subjectContainerKeyId.containerKey,
      subjectContainerKeyId.id,
      t,
      user.accessToken,
      user.companyId,
    ]
  )

  const uploadRecipients = useCallback(
    (formikTemp) => {
      setUploadRecipientsLoading(true)
      const variables = {
        companyId: user.companyId,
        letterId: parseInt(id),
        files: null,
      }
      UploadRecipientsMutation(
        variables,
        user.accessToken,
        formikTemp.values?.[recipientContainerKeyId.containerKey]?.[
          recipientContainerKeyId.id
        ]?.fileUpload[0],
        (data) => {
          formikTemp.setFieldValue(
            recipientContainerKeyId.containerKey +
              '.' +
              recipientContainerKeyId.id +
              '.' +
              'fileUpload',
            []
          )
          getLetterRecipients(formikTemp)
        },
        (data) => {
          setUploadRecipientsLoading(false)
          setUploadStatusMsg({
            open: true,
            msg: t('INVALID FORMAT'),
            status: 'error',
          })
        }
      )
    },
    [
      getLetterRecipients,
      id,
      recipientContainerKeyId.containerKey,
      recipientContainerKeyId.id,
      t,
      user.accessToken,
      user.companyId,
    ]
  )

  const downloadExample = useCallback(() => {
    setCsvDownloadLoading(true)
    fetchQuery(
      environment(user.accessToken),
      RecipientsUploadExampleQuery,
      {
        type: 'csv',
        companyId: user.companyId,
      },
      {
        force: true,
      }
    )
      .then((data) => {
        const base64 = data.Me?.Company?.businessPost.data
        const linkSource = `data:.csv;base64,${base64}`
        const downloadLink = document.createElement('a')
        const fileName = 'Recipients_Example.csv'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        setCsvDownloadLoading(false)
      })
      .catch((e) => {
        console.log('PREVIEW PDF: letterPreview ERROR CATCH', e)
        setCsvDownloadLoading(false)
      })
  }, [user.accessToken, user.companyId])

  const submitMutation = (
    id,
    containers,
    values,
    overRideSetLoader,
    updateStatus,
    callBack = () => {}
  ) => {
    if (overRideSetLoader) {
      overRideSetLoader(true)
    }
    UpdateLetterMutation(
      {
        companyId: user.companyId,
        name: values.name,
        letterId: id,
        layout: JSON.stringify(containers, null, 2),
        recipient1Title: 'NONE',
        recipient1Salutation: 'NONE',
        recipient1FirstName: '',
        recipient1LastName: '',
        recipient2Title: 'NONE',
        recipient2Salutation: 'NONE',
        recipient2FirstName: '',
        recipient2LastName: '',
        recipientCompanyName: '',
        streetAndNumber: '',
        additionalAddressInfo: '',
        zip: '',
        city: '',
        country: '',
        internalName:
          values?.[recipientContainerKeyId.containerKey]?.[
            recipientContainerKeyId.id
          ]?.name,
      },
      user.accessToken,
      (finishedLetterData) => {
        setPageCount(
          finishedLetterData?.Me?.Company?.businessPost?.updateLetter?.pageCount
        )
        setPdfPreview(
          finishedLetterData?.Me?.Company?.businessPost?.updateLetter?.preview
        )
        setLayoutChangeStatus({
          msg: t('LAYOUT UPDATED SUCCESFULLY'),
          status: 'success',
          open: true,
        })
        callBack(finishedLetterData)
        if (overRideSetLoader) {
          overRideSetLoader(false)
        }
      },
      () => {
        setLayoutChangeStatus({
          msg: t('UNKNOWN ERROR'),
          status: 'error',
          open: true,
        })
      }
    )
  }

  const getRecipientOverride = useCallback(() => {
    return {
      containerKey: recipientContainerKeyId.containerKey,
      id: recipientContainerKeyId.id,
      formikIncludeSchema: {
        [recipientContainerKeyId.containerKey]: Yup.object().shape({
          [recipientContainerKeyId.id]: Yup.object().shape({
            recipients: Yup.array()
              .min(1, t('AT LEAST ONE RECIPIENT IS REQUIRED'))
              .required(t('AT LEAST ONE RECIPIENT TYPE IS REQUIRED'))
              .nullable(),
            fileUpload: Yup.array().nullable(),
          }),
        }),
      },
      formikIncludeInitValues: {
        [recipientContainerKeyId.containerKey]: {
          [recipientContainerKeyId.id]: {
            recipients: layoutData.recipients || [],
            fileUpload: [],
          },
        },
      },
      getForm: (formikTmp, updateFunction, loading) => {
        return (
          <>
            <Row>
              <Col xs={1}>
                <Row align='center' justify='center'>
                  <Icon icon='placeholder-for-icon' size='26px' />
                </Row>
              </Col>
              <Col>
                <p style={{ margin: '0 0 15px 0' }}>
                  <strong>
                    {t(
                      'ADD LETTER DATA INCLUDING VARIABLES FOR MASS LETTERS AS CSV'
                    )}
                  </strong>
                </p>
              </Col>
              <Col xs={1}>
                <MassLetterTooltip>
                  <div>
                    <p style={{ marginTop: 0 }}>
                      {t(
                        'YOU CAN UPLOAD A .CSV FILE WITH ALL RECIPIENTS HERE, ' +
                          'INCLUDING ADDITIONAL DATA THAT IS RELEVANT TO PERSONALIZE EACH LETTER.'
                      )}
                    </p>
                    <p>
                      {t(
                        'FEEL FREE TO CHOOSE COLUMN NAMES THAT YOU CAN USE AS VARIABLES IN YOUR TEMPLATES, ' +
                          'P.EXP. TO ADDRESS EACH RECIPIENT WITH PERSONALIZED INFORMATION'
                      )}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {t(
                        'TO UPLOAD A CSV FILE YOU CAN EITHER CLICK THE BUTTON OR DRAG AND DROP THE FILE HERE'
                      )}
                    </p>
                  </div>
                </MassLetterTooltip>
              </Col>
            </Row>

            <Row align='center' justify='end' style={{ marginBottom: '1rem' }}>
              <Col>{t('UPLOAD RECIPIENTS')}</Col>
              <Col xs='content'>
                <LinkButtonStyled
                  type='button'
                  disabled={csvDownloadLoading}
                  onClick={() => {
                    console.log('DOWNLOAD')
                    downloadExample()
                  }}
                >
                  <Row>
                    <Col>{t('EXAMPLE FILE')}</Col>
                    <Col xs='content'>
                      {!csvDownloadLoading ? (
                        <StyledDownloadIcon
                          icon='download'
                          size='1rem'
                          color={portalTheme.color.linkColorPrimary}
                        />
                      ) : (
                        <LoadingSpinner
                          theme={{
                            components: {
                              spinner: {
                                /**
                                 * Has to be a { css } oject from styled-components
                                 */
                                fontSize: portalTheme.font.size.bodySmall,
                                fontWeight: portalTheme.font.weight.regular,
                                size: '20px',
                              },
                            },
                          }}
                          style={{
                            position: 'unset',
                            backgroundColor: 'white',
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                </LinkButtonStyled>
              </Col>
            </Row>
            <Row style={{ marginBottom: '2rem' }}>
              <Col>
                <Recipients
                  letterId={id}
                  recipients={
                    formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.recipients
                  }
                />
              </Col>
            </Row>
            <p>
              {t(
                'TO ADD NEW RECIPIENTS UPLOAD CSV OR EXCEL FILES. CHECK THE EXAMPLE FILE ABOVE FOR THE CORRECT FORMAT'
              )}
            </p>
            <Row>
              <Col>
                <FileUpload
                  uploadStatusMsg={uploadStatusMsg}
                  setUploadStatusMsg={setUploadStatusMsg}
                  setFieldValue={formikTmp.setFieldValue}
                  onUpload={formikTmp.handleChange}
                  files={
                    formikTmp.values?.[recipientContainerKeyId.containerKey]?.[
                      recipientContainerKeyId.id
                    ]?.fileUpload
                  }
                  multiFileMode={false}
                  name={
                    recipientContainerKeyId.containerKey +
                    '.' +
                    recipientContainerKeyId.id +
                    '.' +
                    'fileUpload'
                  }
                  supportedFileTypes='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                  maxsizeKb={10000}
                  formik={formikTmp}
                  labels={{
                    maxSizeReached: t(
                      'SIZE OF FILES EXCEEDS THE ALLOWED MAXIMUM'
                    ),
                  }}
                />
              </Col>
            </Row>
            <Row justify='center'>
              <Col xs='content'>
                <ButtonPrimary
                  type='button'
                  disabled={uploadRecipientsLoading}
                  isLoading={uploadRecipientsLoading}
                  onClick={() => {
                    console.log('upload')
                    if (
                      formikTmp.values?.[
                        recipientContainerKeyId.containerKey
                      ]?.[recipientContainerKeyId.id]?.fileUpload.length > 0
                    ) {
                      setUploadStatusMsg({
                        open: false,
                        msg: '',
                        status: false,
                      })
                      uploadRecipients(formikTmp)
                    } else {
                      setUploadStatusMsg({
                        open: true,
                        msg: t('PLEASE SELECT A FILE FIRST'),
                        status: 'warning',
                      })
                    }
                  }}
                >
                  {t('UPLOAD DATA')}
                </ButtonPrimary>
              </Col>
            </Row>
          </>
        )
      },
    }
  }, [
    csvDownloadLoading,
    downloadExample,
    id,
    layoutData.recipients,
    recipientContainerKeyId.containerKey,
    recipientContainerKeyId.id,
    t,
    uploadRecipients,
    uploadRecipientsLoading,
    uploadStatusMsg,
  ])

  const getSubjectOverride = useCallback(() => {
    return {
      containerKey: 'content',
      id: 'subject',
      formikIncludeSchema: {
        [subjectContainerKeyId.containerKey]: Yup.object().shape({
          [subjectContainerKeyId.id]: Yup.object().shape({
            data: Yup.string().checkUndefinedVariableSubject(
              t(
                'SUBJECT HAS UNDEFINED VARIABLES (RED MARKED), PLEASE REMOVE THEM!'
              )
            ),
          }),
        }),
      },
      formikIncludeInitValues: {
        [subjectContainerKeyId.containerKey]: {
          [subjectContainerKeyId.id]: {
            data: JSON.parse(layoutData.layout)[
              subjectContainerKeyId.containerKey
            ].find((object) => object.id === subjectContainerKeyId.id).content,
          },
        },
      },
      getForm: (formikTmp, updateFunction, loading) => {
        if (!formikValuesClone) {
          setFormikValuesClone(formikTmp.values)
        }
        return (
          <SubjectOverride
            formikTmp={formikTmp}
            setFormikValuesClone={setFormikValuesClone}
            setContainerFormErrors={setContainerFormErrors}
            containerFormErrors={containerFormErrors}
            formikValuesClone={formikValuesClone}
            updateFunction={updateFunction}
            loading={loading}
            subjectContainerKeyId={subjectContainerKeyId}
            allVariables={getAllVariables()}
            setUndefinedVariables={setUndefinedVariables}
            undefinedVariables={undefinedVariables}
            customVariables={customVariables}
            returnUndefinedContentVariables={returnUndefinedContentVariables}
          />
        )
      },
    }
  }, [
    containerFormErrors,
    customVariables,
    formikValuesClone,
    getAllVariables,
    layoutData.layout,
    returnUndefinedContentVariables,
    subjectContainerKeyId,
    t,
    undefinedVariables,
  ])

  const editorRef = useRef(null)

  const getContentOverride = useCallback(() => {
    return {
      containerKey: 'content',
      id: 'letterContent',
      formikIncludeSchema: {
        [contentContainerKeyId.containerKey]: Yup.object().shape({
          [contentContainerKeyId.id]: Yup.object().shape({
            data: Yup.string()
              .min(
                3,
                t('LETTER CONTENT MUST BE AT LEAST {CHARACTER} CHARACTERS', {
                  CHARACTER: 3,
                })
              )
              .checkUndefinedVariableContent(
                t(
                  'CONTENT HAS UNDEFINED VARIABLES (RED MARKED), PLEASE REMOVE THEM!'
                )
              )
              .required(
                t('LETTER CONTENT MUST BE AT LEAST {CHARACTER} CHARACTERS', {
                  CHARACTER: 3,
                })
              ),
          }),
        }),
      },
      formikIncludeInitValues: {
        [contentContainerKeyId.containerKey]: {
          [contentContainerKeyId.id]: {
            data: JSON.parse(layoutData.layout)[
              contentContainerKeyId.containerKey
            ].find((object) => object.id === contentContainerKeyId.id).content,
          },
        },
      },
      getForm: (formikTmp, updateFunction, loading) => {
        if (!formikValuesClone) {
          setFormikValuesClone(formikTmp.values)
        }

        const editorConfig = {
          // The configuration of the Products plugin. It specifies a function that will allow
          // the editor to render a React <VariablePreview> component inside a product widget.
          variables: {
            variablesRenderer: (id, domElement) => {
              const variable = getAllVariables().find(
                (variable) => variable.id === id
              )

              const handleRemove = (id) => {
                editorRef.current.execute('removeVariable', id)
                editorRef.current.editing.view.focus()
              }

              // const variableElement = createRoot(domElement)
              ReactDOM.render(
                <VariablePreview
                  id={id}
                  handleRemove={handleRemove}
                  {...variable}
                />,
                domElement
              )
            },
          },
        }

        const handleEditorDataChange = (evt, editor) => {
          formikTmp.setFieldValue(
            contentContainerKeyId.containerKey +
              '.' +
              contentContainerKeyId.id +
              '.data',
            editor.getData()
          )
          const newUndefinedVariables = {
            content: returnUndefinedContentVariables(
              editor.getData(),
              customVariables
            ),
            subject: undefinedVariables.subject,
          }

          setUndefinedVariables(newUndefinedVariables)
        }

        const handleEditorReady = (editor) => {
          editorRef.current = editor

          editor.editing.view.change((writer) => {
            writer.setStyle(
              'height',
              '400px',
              editor.editing.view.document.getRoot()
            )
          })
        }

        const handleSave = () => {
          if (editorRef.current) {
            setFormikValuesClone(formikTmp.values)
            const newContainerFormErrors = containerFormErrors.reduce(
              (prev, current) => {
                if (
                  contentContainerKeyId.containerKey +
                    '_' +
                    contentContainerKeyId.id !==
                  current
                ) {
                  return [...prev, current]
                } else {
                  return [...prev]
                }
              },
              []
            )
            setContainerFormErrors(newContainerFormErrors)
            const data = editorRef.current.getData()
            const newData = replaceTableTag(data)

            formikTmp.validateForm()
            if (newData) {
              updateFunction(newData)
            } else {
              updateFunction('')
            }
          }
        }

        const noChangedContent = () => {
          return (
            formikTmp.values?.[contentContainerKeyId.containerKey]?.[
              contentContainerKeyId.id
            ]?.data ===
            formikValuesClone?.[contentContainerKeyId.containerKey]?.[
              contentContainerKeyId.id
            ]?.data
          )
        }

        return (
          <div>
            <Row>
              <Col>
                <h4 style={{ margin: ' 0 0 15px 0' }}>{t('WRITE TEXT')} </h4>
              </Col>
              <Col xs={1}>
                <MassLetterTooltip>
                  <div>
                    <p style={{ marginTop: 0 }}>
                      {t(
                        'YOU CAN CREATE THE TEMPLATE CONTENT FOR ALL LETTERS ' +
                          'THAT ARE PART OF YOUR MASS LETTER HERE. ' +
                          'YOU CAN USE VARIABLES THAT CAN BE USED TO PERSONALIZE EACH LETTER.'
                      )}
                    </p>
                    <p>
                      {t(
                        'YOU CAN POSITION VARIABLES (DEFINED BY THE COLUMNS IN YOUR UPLOADED CSV ' +
                          'WHERE THEY ARE NEEDED AND BY THAT PUT THE CORRECT INFORMATION FOR THE CORRECT ' +
                          'RECEIPIENT IN THEIR SPECIFIC PLACES'
                      )}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {t(
                        'WE ONLY SHOW VARIABLES THAT WHERE FOUND AS COLUMNS IN THE UPLOADED CSV.'
                      )}
                    </p>
                  </div>
                </MassLetterTooltip>
              </Col>
            </Row>
            <Row>
              <Col>
                {formikTmp.errors?.[contentContainerKeyId.containerKey]?.[
                  contentContainerKeyId.id
                ]?.data && (
                  <SimpleNote
                    style={{ fontSize: '14px' }}
                    noteStatus='error'
                    text={
                      formikTmp.errors?.[contentContainerKeyId.containerKey]?.[
                        contentContainerKeyId.id
                      ]?.data
                    }
                  />
                )}
              </Col>
            </Row>
            <VariablesEditorWrapper>
              <CKEditorReact
                editor={CustomEditor.EditorMassLetter}
                data={
                  formikTmp.values?.[contentContainerKeyId.containerKey]?.[
                    contentContainerKeyId.id
                  ]?.data
                }
                config={editorConfig}
                onChange={handleEditorDataChange}
                onReady={handleEditorReady}
              />

              <div>
                <h4 style={{ margin: 0 }}>{t('PARAMETERS')}</h4>
                <VariablesBox
                  variables={getAllVariables()}
                  editorRef={editorRef}
                  divideIntoColumns
                />
              </div>
            </VariablesEditorWrapper>

            <Row
              justify='end'
              align='end'
              style={{
                paddingBottom: '20px',
                paddingTop: '20px',
              }}
            >
              <Col>
                <UpdateSaveButton
                  loading={loading}
                  handleSave={handleSave}
                  noChangedContent={noChangedContent}
                />
              </Col>
            </Row>
          </div>
        )
      },
    }
  }, [
    containerFormErrors,
    contentContainerKeyId.containerKey,
    contentContainerKeyId.id,
    customVariables,
    formikValuesClone,
    getAllVariables,
    layoutData.layout,
    returnUndefinedContentVariables,
    t,
    undefinedVariables.subject,
  ])

  const downloadPdf = useCallback(() => {
    setPdfBase64Loading(true)
    fetchQuery(
      environment(user.accessToken),
      LetterPreviewQuery,
      {
        letterId: id,
        companyId: user.companyId,
      },
      {
        force: true,
      }
    )
      .then((data) => {
        const base64 = data.Me?.Company?.businessPost.data.preview
        const linkSource = `data:application/pdf;base64,${base64}`
        const downloadLink = document.createElement('a')
        const fileName =
          'letter_' + data.Me?.Company?.businessPost.data.tableId + '.pdf'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        setPdfBase64Loading(false)
      })
      .catch((e) => {
        console.log('PREVIEW PDF: letterPreview ERROR CATCH', e)
        setPdfBase64Loading(false)
      })
  }, [id, user.accessToken, user.companyId])

  const generateModalContentNoCostPosition = useCallback(() => {
    return (
      <>
        <SimpleModal
          isOpen={openModal}
          showButtonClose
          closeOnOutsideClick={false}
          handleModalClose={() => setOpenModal(false)}
        >
          <Row justify='start'>
            <Col xs='content'>
              <SimpleNote noteStatus='error' text={t('COULD NOT SEND')} />
            </Col>
          </Row>
          <Row justify='start' style={{ marginTop: '20px' }}>
            <Col xs='content'>
              <Container>
                <Row>
                  <Col
                    style={{
                      fontSize: '12px',
                      padding: '10px',
                    }}
                  >
                    {t(
                      'LETTERS ARE CALCULATED ACCORDING TO THE CONTRACTUALLY STORED COST ITEM. IF A WARNING APPEARS, THIS COST ITEM DOES NOT YET EXIST. PLEASE CONTACT VERTRAG@PYREXX.COM SO THAT THEY CAN BE ACTIVATED FOR YOU.'
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      fontSize: '12px',
                      padding: '10px',
                    }}
                  >
                    {t('YOU CAN STILL DOWNLOAD THE LETTER.')}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row justify='end' style={{ marginTop: '20px' }}>
            <Col xs='content'>
              {pdfBase64Loading ? (
                <Row nogutter>
                  <Col xs='content'>
                    <div style={{ height: '30px', width: '30px' }}>
                      <LoadingSpinner
                        theme={{
                          components: {
                            spinner: {
                              /**
                               * Has to be a { css } oject from styled-components
                               */
                              fontSize: portalTheme.font.size.bodySmall,
                              fontWeight: portalTheme.font.weight.regular,
                              size: '20px',
                            },
                          },
                        }}
                        style={{
                          position: 'unset',
                          backgroundColor: 'white',
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs='content'>{t('PLEASE WAIT')}</Col>
                </Row>
              ) : (
                <LinkButtonStyled
                  style={{ textAlign: 'left' }}
                  type='button'
                  onClick={() => {
                    downloadPdf()
                  }}
                >
                  <Row nogutter align='center' justify='center'>
                    <Col xs='content'>
                      <Icon icon='download' size='20px' />
                    </Col>
                    <Col xs='content' style={{ paddingLeft: '5px' }}>
                      {t('DOWNLOAD PDF')}
                    </Col>
                  </Row>
                </LinkButtonStyled>
              )}
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }, [downloadPdf, openModal, pdfBase64Loading, t])

  const generateModalContentValidation = useCallback(() => {
    return (
      <>
        <SimpleModal
          isOpen={openModal}
          showButtonClose
          closeOnOutsideClick={false}
          handleModalClose={() => setOpenModal(false)}
        >
          <Row justify='center'>
            <Col xs='content'>
              <SimpleNote
                noteStatus='warning'
                text={t('PLEASE WAIT FOR VALIDATION AND DONT CLOSE THE WINDOW')}
              />
            </Col>
          </Row>
          <Row justify='center' style={{ marginTop: '40px' }}>
            <Col xs='content'>
              <DotLoader />
            </Col>
          </Row>
          <Row justify='center' style={{ marginTop: '20px' }}>
            <Col xs='content'>
              <ButtonPrimary
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                {t('CONFIRM')}
              </ButtonPrimary>
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }, [openModal, t])

  const generateModalContentSendLetter = useCallback(() => {
    return (
      <>
        <SimpleModal
          isOpen={openModal}
          showButtonClose
          closeOnOutsideClick={false}
          handleModalClose={() => setOpenModal(false)}
        >
          <Row justify='start'>
            <Col xs='content'>
              <SimpleNote noteStatus='success' text={t('READY TO SEND')} />
            </Col>
          </Row>
          <Row justify='start' style={{ marginTop: '20px' }}>
            <Col xs='content'>
              <Container>
                <Row>
                  <Col
                    style={{
                      backgroundColor: '#B0DDF0',
                      borderRadius: '10px',
                      padding: '10px',
                    }}
                  >
                    {t(
                      'WHEN THIS LETTER IS SENT, THE COST POSITION IN THE CONTRACT WILL BE ACTIVATED'
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px' }}>
            <Col>
              <StyledInput
                label={t('I HEREBY CONFIRM PAID SHIPPING')}
                type='checkbox'
                value={shipConfirm}
                onChange={() => setShipConfirm(!shipConfirm)}
                id='shipConfirm'
                name='shipConfirm'
                setFieldValue={() => {}}
              />
            </Col>
          </Row>
          <Row justify='end' style={{ marginTop: '10px' }}>
            <Col xs='content'>
              {enqueueLetterLoader ? (
                <Row nogutter>
                  <Col xs='content'>
                    <div style={{ height: '30px', width: '30px' }}>
                      <LoadingSpinner
                        theme={{
                          components: {
                            spinner: {
                              /**
                               * Has to be a { css } oject from styled-components
                               */
                              fontSize: portalTheme.font.size.bodySmall,
                              fontWeight: portalTheme.font.weight.regular,
                              size: '20px',
                            },
                          },
                        }}
                        style={{
                          position: 'unset',
                          backgroundColor: 'white',
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs='content'>{t('PLEASE WAIT')}</Col>
                </Row>
              ) : (
                <ButtonPrimary
                  disabled={!shipConfirm}
                  onClick={() => {
                    enqueueLetter(id)
                  }}
                >
                  {t('SEND')}
                </ButtonPrimary>
              )}
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }, [enqueueLetter, enqueueLetterLoader, id, openModal, shipConfirm, t])

  const generateModalContentFormError = useCallback(() => {
    return (
      <>
        <SimpleModal
          isOpen={openModal}
          showButtonClose
          closeOnOutsideClick={false}
          handleModalClose={() => setOpenModal(false)}
        >
          <Row justify='start'>
            <Col xs='content'>
              <SimpleNote noteStatus='error' text={t('MISSING CONTENT')} />
            </Col>
          </Row>
          <Row justify='start' style={{ marginTop: '20px' }}>
            <Col xs='content'>
              <Container>
                <Row>
                  <Col
                    style={{
                      borderRadius: '10px',
                      padding: '10px',
                    }}
                  >
                    {t(
                      'PLEASE CHECK THE RED MARKED CONTAINER ON THE LETTER. YOU NEED TO FILL THE REQUIRED FIELD BEFORE YOU CAN SEND THE LETTER'
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <Row justify='end' style={{ marginTop: '10px' }}>
            <Col xs='content'>
              <ButtonPrimary
                onClick={() => {
                  setOpenModal(false)
                }}
              >
                {t('CLOSE')}
              </ButtonPrimary>
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }, [openModal, t])

  const generateModalContentSent = useCallback(() => {
    const getNoteStatus = () => {
      switch (enqueueLetterStatus) {
        case 'SUCCESS':
          return {
            msg: t('LETTER SUCCESSFULLY SENT'),
            noteStatus: 'success',
          }
        case 'NO_COST_POSITION':
          return {
            msg: t('COULD NOT SEND LETTER. NO COST POSITION FOUND.'),
            noteStatus: 'error',
          }
        case 'LETTER_ALREADY_SENT':
          return {
            msg: t('LETTER WAS ALREADY SENT'),
            noteStatus: 'warning',
          }
        case 'INVALID_LAYOUT':
          return {
            msg: t(
              'COULD NOT SEND LETTER. INVALID LAYOUT. PLEASE ASK FOR SUPPORT!'
            ),
            noteStatus: 'warning',
          }
        default:
          return {
            msg: t('UNKNOWN ERROR. PLEASE ASK SUPPORT'),
            noteStatus: 'error',
          }
      }
    }
    const notStatus = getNoteStatus()
    return (
      <>
        <SimpleModal
          isOpen={openModal}
          showButtonClose={false}
          closeOnOutsideClick={false}
          handleModalClose={() => setOpenModal(false)}
        >
          <Row justify='start'>
            <Col xs='content'>
              <SimpleNote
                noteStatus={notStatus.noteStatus}
                text={notStatus.msg}
              />
            </Col>
          </Row>
          <Row justify='end' style={{ marginTop: '10px' }}>
            <Col xs='content'>
              <ButtonPrimary
                onClick={() => {
                  setOpenModal(false)
                  if (enqueueLetterStatus === 'SUCCESS') {
                    redirectRoute(
                      Routes.businessMail + Routes.businessMailsGrid
                    )
                  }
                }}
              >
                {t('CLOSE')}
              </ButtonPrimary>
            </Col>
          </Row>
        </SimpleModal>
      </>
    )
  }, [enqueueLetterStatus, openModal, redirectRoute, t])

  const generateModals = useCallback(() => {
    switch (modalState) {
      case 1:
        return generateModalContentSendLetter()
      case 2:
        return generateModalContentValidation()
      case 3:
        return generateModalContentNoCostPosition()
      case 4:
        return generateModalContentSent()
      case 5:
        return generateModalContentFormError()
      default:
        return ''
    }
  }, [
    generateModalContentFormError,
    generateModalContentNoCostPosition,
    generateModalContentSendLetter,
    generateModalContentSent,
    generateModalContentValidation,
    modalState,
  ])
  useEffect(() => {
    if (modalState > 0) {
      setOpenModal(true)
    }
  }, [modalState])

  const getContainersHasErrors = (errors) => {
    const keyify = (obj, prefix = '') =>
      Object.keys(obj).reduce((res, el) => {
        if (Array.isArray(obj[el])) {
          return res
        } else if (typeof obj[el] === 'object' && obj[el] !== null) {
          return [...res, ...keyify(obj[el], prefix + el + '_')]
        }
        return [...res, prefix + el]
      }, [])
    const firstStep = keyify(errors)
    const secondStep = firstStep.reduce((prev, current) => {
      const splitted = current.split('_')
      if (splitted.length === 3) {
        return [...prev, splitted[0] + '_' + splitted[1]]
      } else {
        return [...prev, current]
      }
    }, [])
    const finishedErrors = [...new Set(secondStep)]
    setContainerFormErrors(finishedErrors)
    return finishedErrors
  }

  const [modal, setModal] = useState({
    show: false,
    filename: 'example.pdf',
    loading: true,
    loadingText: t('PLEASE WAIT'),
    base64: false,
    primaryButton: {
      usePrimaryButton: true,
    },
    showButtonClose: true,
  })

  const handleModalClose = () => {
    setModal({
      show: false,
      filename: 'example.pdf',
      loading: true,
      loadingText: t('PLEASE WAIT'),
      base64: '',
      primaryButton: {
        usePrimaryButton: true,
      },
      showButtonClose: true,
    })
  }

  const triggerPreviewLetterQuery = useCallback(() => {
    setModal({
      show: true,
      loading: false,
      loadingText: t('PLEASE WAIT'),
      filename: t('LETTER') + '_' + layoutData.tableId + '.pdf',
      base64: pdfPreview,
      primaryButton: {
        usePrimaryButton: true,
      },
      showButtonClose: true,
    })
  }, [layoutData.tableId, pdfPreview, t])

  const specialEditorFormWithoutActiveContainer = [
    {
      name: 'uploadAttachments',
      formikIncludeSchema: {
        fileUploadAttachments: Yup.array().nullable(),
        attachments: Yup.array().nullable(),
      },
      formikIncludeInitValues: {
        fileUploadAttachments: [],
        attachments: layoutData.attachments || [],
      },
      getForm: (formikTmp, updateFunction, loading) => {
        return (
          <>
            <Row align='center' justify='end' style={{ marginBottom: '1rem' }}>
              <Col>{t('UPLOAD ATTACHMENTS')}</Col>
            </Row>
            <Row style={{ marginBottom: '2rem' }}>
              <Col>
                <Attachments
                  letterId={id}
                  getLetterAttachments={() => {
                    getLetterAttachments(formikTmp)
                  }}
                  attachments={formikTmp.values?.attachments}
                />
              </Col>
            </Row>
            <p>
              {t(
                'ATTACHMENTS NEED TO BE UNCOMPRESSED PDFs WITH A4 PORTRAIT MODE'
              )}
            </p>
            <Row>
              <Col>
                <FileUpload
                  uploadStatusMsg={uploadStatusMsg}
                  setUploadStatusMsg={setUploadStatusMsg}
                  setFieldValue={formikTmp.setFieldValue}
                  onUpload={formikTmp.handleChange}
                  files={formikTmp.values?.fileUploadAttachments}
                  multiFileMode
                  name='fileUploadAttachments'
                  supportedFileTypes='application/pdf'
                  maxsizeKb={10000}
                  formik={formikTmp}
                  labels={{
                    maxSizeReached: t(
                      'SIZE OF FILES EXCEEDS THE ALLOWED MAXIMUM'
                    ),
                  }}
                />
              </Col>
            </Row>
            <Row justify='center'>
              <Col xs='content'>
                <ButtonPrimary
                  type='button'
                  disabled={uploadAttachmentsLoading}
                  isLoading={uploadAttachmentsLoading}
                  onClick={() => {
                    console.log('upload')
                    if (formikTmp.values?.fileUploadAttachments.length > 0) {
                      setUploadStatusMsg({
                        open: false,
                        msg: '',
                        status: false,
                      })
                      uploadAttachments(formikTmp)
                    } else {
                      setUploadStatusMsg({
                        open: true,
                        msg: t('PLEASE SELECT A FILE FIRST'),
                        status: 'warning',
                      })
                    }
                  }}
                >
                  {t('UPLOAD DATA')}
                </ButtonPrimary>
              </Col>
            </Row>
          </>
        )
      },
    },
  ]

  return (
    <Container>
      <Row>
        <Col>
          <NavBack
            title=''
            to={
              '/' +
              currentLanguage +
              Routes.businessMail +
              Routes.businessMailCreate
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <LayoutPreview
            containerFormikOverride={[
              getSubjectOverride(),
              getContentOverride(),
              getRecipientOverride(),
            ]}
            nameLabel={t('INTERNAL NAME (OPTIONAL)')}
            name={layoutData.internalTitle}
            replacements={layoutData.replacements}
            nameIsReq={false}
            status={layoutData.status}
            adminEdit={false}
            submitMutation={submitMutation}
            containerFormErrors={containerFormErrors}
            overrideSendButtonMutation={(id, formikTmp) => {
              formikTmp.validateForm().then((errors) => {
                formikTmp.setTouched(setNestedObjectValues(errors, true))
                getContainersHasErrors(errors)
                if (
                  errors &&
                  Object.keys(errors).length === 0 &&
                  Object.getPrototypeOf(errors) === Object.prototype
                ) {
                  if (layoutData.enqueuePreCheck === 'NO_COST_POSITION') {
                    setModalState(3)
                    setOpenModal(true)
                  } else {
                    setModalState(1)
                    setOpenModal(true)
                  }
                } else {
                  setModalState(5)
                  setOpenModal(true)
                }
              })
            }}
            specialEditorFormWithoutActiveContainer={
              specialEditorFormWithoutActiveContainer
            }
            saveButtonText={t('SEND')}
            saveButtonTextDraft={t('SEND LETTER')}
            saveButtonTextActive={t('LETTER SENT')}
            id={layoutData.tableId}
            initContainer={JSON.parse(layoutData.layout)}
            pageCount={pageCount}
            triggerPreview={triggerPreviewLetterQuery}
            setPageCount={setPageCount}
            setPdfPreview={setPdfPreview}
            layoutChangeStatus={layoutChangeStatus}
            setLayoutChangeStatus={setLayoutChangeStatus}
          />
        </Col>
      </Row>
      {generateModals()}
      {modal.show && (
        <ModalPdfViewer
          isOpen={modal.show}
          handleModalClose={handleModalClose}
          loading={modal.loading}
          loadingText={modal.loadingText}
          base64={modal.base64}
          filename={modal.filename}
          showButtonClose={modal.showButtonClose}
          primaryButton={{ usePrimaryButton: true }}
        />
      )}
    </Container>
  )
}

const VariablesEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export default BusinessMailCreateMassLetter
