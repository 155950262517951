import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query AppointmentGetBulletinDownloadPathQuery(
    $companyId: ID!
    $appointmentId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        Appointment(id: $appointmentId) {
          data: bulletin {
            id
            tableId
            download {
              name
              path
              token
            }
          }
        }
      }
    }
  }
`
