import { useHistory, useRouteMatch } from 'react-router-dom'

const useUrlRedirection = (path = '/:lang/:site') => {
  const history = useHistory()
  const { url } = useRouteMatch(path)

  const callback = (pageSection) => {
    history.push(url + '/' + pageSection)
  }

  return callback
}

export default useUrlRedirection
