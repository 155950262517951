import styled from 'styled-components/macro'
import { Col as C, Container as Cont, Row as R } from 'react-grid-system'

export const Container = styled(Cont)`
  background-color: #fff;

  padding: 12px !important;
`

export const Row = styled(R)``

export const Col = styled(C)`
  display: flex;
  align-items: center;

  padding: 8px 0;
`

export const Field = styled.div`
  margin: 0;
`
