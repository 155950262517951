import React, { useContext } from 'react'

import SimpleLink from '../../../components/Simple/SimpleLink'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'

const UsageUnitReferenceNumberRenderer = (props) => {
  const { referenceNumber, tableId } = props.data

  const { currentLanguage } = useContext(UserContext)

  return (
    <SimpleLink
      to={'/' + currentLanguage + Routes.usageUnit + '/' + tableId}
      style={{ fontSize: 'inherit' }}
    >
      {referenceNumber}
    </SimpleLink>
  )
}

export default UsageUnitReferenceNumberRenderer
