import React from 'react'
import styled from 'styled-components/macro'

import { LoadingSpinner } from 'pyrexx-react-library'

const LoadingBox = styled.div`
  width: 100%;

  background-color: ${(props) => props.backgroundColor};
  min-height: 100px;

  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
  }
`

export default (props) => {
  const { spinner = true, backgroundColor = '#f2f2f2', ...rest } = props

  return (
    <LoadingBox backgroundColor={backgroundColor} {...rest}>
      {spinner && <LoadingSpinner {...rest} />}
    </LoadingBox>
  )
}
