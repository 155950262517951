const contractPerformanceValueGetter = (props) => {
  const { value } = props
  let concatString = ''
  value
    .filter((item) => item?.actionPosition?.name)
    .forEach((item) => (concatString += item?.name + '\r\n'))
  return concatString
}

export default contractPerformanceValueGetter
