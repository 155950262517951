import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components/macro'

import { ButtonIcon } from 'pyrexx-react-library'

const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.6);

  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;

  justify-content: center;
  align-items: center;

  ${({ isOpen }) =>
    isOpen &&
    `
      display: flex;
  `}

  user-select: auto;
`

const CloseButton = styled(ButtonIcon)`
  position: absolute;
  right: 24px;
  top: 24px;
  color: #fff;
`

const ImageModal = (props) => {
  const {
    imageSrc = '',
    isOpen = false,
    handleModalClose,
    closeOnOutsideClick = true,
    children,
    id = 'modal-container',
    ...rest
  } = props

  const handleOnEscKeyPress = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        handleModalClose()
      }
    },
    [handleModalClose]
  )

  const handleOnOutsideClick = useCallback(
    (e) => {
      const isClickOutside = e.target === document.getElementById(id)

      if (closeOnOutsideClick && isClickOutside) {
        handleModalClose()
      }
    },
    [id, closeOnOutsideClick, handleModalClose]
  )

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscKeyPress, false)
    document.addEventListener('click', handleOnOutsideClick, false)

    return () => {
      document.removeEventListener('keydown', handleOnEscKeyPress, false)
      document.removeEventListener('click', handleOnOutsideClick, false)
    }
  }, [handleOnEscKeyPress, handleOnOutsideClick])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <Wrapper
      isOpen={isOpen}
      role='dialog'
      onClick={handleOnOutsideClick}
      id={id}
    >
      <div {...rest}>
        <img src={imageSrc} alt='appointment img' />
      </div>

      <CloseButton
        handleClick={handleModalClose}
        icon='close'
        size='35px'
        data-test-id='button-close'
      />
    </Wrapper>
  )
}

export default ImageModal
