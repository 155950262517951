import { getDateFilterModel } from './getFilterModel'

export default (customDateFilterKey) => {
  const currentYear = new Date().getFullYear()
  const periodStart = `${currentYear}-01-01`
  const periodEnd = `${currentYear}-12-31`

  const data = {
    tableId: 'default date filter',
    from: periodStart,
    to: periodEnd,
  }

  return getDateFilterModel(data, null, customDateFilterKey)
}
