import React, { useState, forwardRef, useImperativeHandle } from 'react'
import GridEnumFilter from '../../../components/GridEnumFilter'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import { usageUnitLockPeriodStatusEnumList } from '../../../components/AgGrid/enumLists'

const map = {
  NOTLOCKED: ['DEACTIVATED'],
  LOCKED: ['ACTIVE', 'PENDING'],
}

const colorMap = {
  NOTLOCKED: 'success',
  LOCKED: 'disabled',
}

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  return (
    <GridEnumFilter
      map={map}
      colorMap={colorMap}
      translateLabel={(value, t) => {
        return t(usageUnitLockPeriodStatusEnumList(value))
      }}
      setFilterModel={setFilterModel}
    />
  )
})
