import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as TabStyles from './Tabs.styles'

import Tab from '../Tab'

import useUpdateEffect from '../../hooks/useUpdateEffect'

const Tabs = (props) => {
  const {
    tabs = [],
    pageSection = '',
    borderBottomStyle = '',
    content = false,
    description = '',
    tabsStyle = {},
    noContent = false,
    urlRedirectionCallback = (key) => {},
  } = props

  const defaultTab =
    tabs.find((tab) => tab.selected === true) ||
    tabs.find((tab) => tab.key === pageSection) ||
    tabs[0]
  // console.log('defaultTab', tabs, pageSection, defaultTab)
  const [selectedTab, setSelectedTab] = useState(defaultTab?.key)

  useUpdateEffect(() => {
    setSelectedTab(defaultTab?.key)
  }, [pageSection])

  const handleTabClick = (key) => {
    setSelectedTab(key)
    const onClickEvent = tabs.find((tab) => tab.key === key)?.onClick
    onClickEvent && onClickEvent()

    urlRedirectionCallback(key)
  }

  const renderContent = () => {
    if (noContent) {
      return ''
    }

    return (
      content || (
        <TabStyles.TabsContent data-testid='tabs-content'>
          {tabs.find((tab) => tab.key === selectedTab)?.content}
        </TabStyles.TabsContent>
      )
    )
  }

  return (
    <TabStyles.Wrapper>
      <TabStyles.TabsRow
        style={tabsStyle}
        borderBottomStyle={borderBottomStyle}
      >
        {description && (
          <TabStyles.TabsDescription>{description}</TabStyles.TabsDescription>
        )}

        <TabStyles.TabsNavigator>
          {tabs
            .filter((filterItem) => filterItem.label)
            .map((tab) => {
              return (
                <Tab
                  key={tab.key}
                  id={tab.key}
                  label={tab.label}
                  isSelected={
                    tab.key === selectedTab ||
                    tab?.extraIndicatorKey?.find(
                      (searchItem) => searchItem === pageSection
                    )
                  }
                  onClick={() => handleTabClick(tab.key)}
                />
              )
            })}
        </TabStyles.TabsNavigator>
      </TabStyles.TabsRow>
      {renderContent()}
    </TabStyles.Wrapper>
  )
}

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string,
      content: PropTypes.any,
      selected: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  pageSection: PropTypes.string,
  borderBottomStyle: PropTypes.string,
  content: PropTypes.any,
  description: PropTypes.string,
  tabsStyle: PropTypes.object,
  noContent: PropTypes.bool,
  urlRedirectionCallback: PropTypes.func,
}

export default Tabs
