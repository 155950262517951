import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation SubmitContactFormMutation(
    $salutation: UserSalutationType
    $lastName: String!
    $firstName: String!
    $phoneNumber: String
    $email: String!
    $contactDepartmentValue: String
    $subject: String
    $content: String!
  ) {
    submitContactForm(
      salutation: $salutation
      lastName: $lastName
      firstName: $firstName
      email: $email
      subject: $subject
      content: $content
      phoneNumber: $phoneNumber
      contactDepartmentValue: $contactDepartmentValue
    )
  }
`

export default (
  {
    salutation,
    lastName,
    firstName,
    email,
    subject,
    content,
    phoneNumber,
    contactDepartmentValue,
  },
  accessToken,
  onCompleted,
  onError
) => {
  const variables = {
    salutation,
    lastName,
    firstName,
    email,
    subject,
    content,
    phoneNumber,
    contactDepartmentValue,
  }

  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted,
    onError,
  })
}
