import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import graphql from 'babel-plugin-relay/macro'

import { ButtonPrimary } from 'pyrexx-react-library'
import PageContainer from '../../../components/PageContainer'
import NavBack from '../../../components/NavBack'
import UserInfoInput from './UserInfoInput'
import PermissionsInput from './PermissionsInput'
import BillingPartitionsGrid from './grids/BillingPartitionsGrid'
import ContractsGrid from './grids/ContractsGrid'
import QueryRendererContainer from '../../../components/QueryRendererContainer'

import UpdateUserMutation from '../../../mutations/UpdateUserMutation'
import ChangeUserPasswordMutation from '../../../mutations/ChangeUserPasswordMutation'
import ChangeUserEmailMutation from '../../../mutations/ChangeUserEmailMutation'
import { useUserContext } from '../../../helper/userContext'
import getBillingPartitions from './helpers/getBillingPartitions'
import { Routes } from '../../../configs/RouteConfig'
import { ButtonRow, Wrapper } from './styles'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const PureUpdateUser = (props) => {
  const {
    userId = '',
    userInfo = {},
    availablePermissions = [],
    availableContracts = [],
    availableBillingPartitions = [],
    currentPermissions = [],
    currentContracts = [],
    currentBillingPartitions = [],
    loading = false,
  } = props
  const { user, currentLanguage } = useUserContext()
  const { t } = useTranslation()
  const history = useHistory()

  const [newUser, setNewUser] = useState(() => ({
    firstname: userInfo.firstname,
    lastname: userInfo.lastname,
    email: userInfo.email,
    username: userInfo.username,
    password: '',
    permissions: currentPermissions,
    contracts: currentContracts,
    billingPartitions: currentBillingPartitions,
    removePermissions: [],
    removeContracts: [],
    removeBillingPartitions: [],
  }))

  // useEffect(() => {
  //   setNewUser({
  //     firstname: userInfo.firstname,
  //     lastname: userInfo.lastname,
  //     email: userInfo.email,
  //     username: userInfo.username,
  //     password: '',
  //     permissions: currentPermissions,
  //     contracts: currentContracts,
  //     billingPartitions: currentBillingPartitions,
  //     removePermissions: [],
  //     removeContracts: [],
  //     removeBillingPartitions: [],
  //   })
  // }, [
  //   currentBillingPartitions,
  //   currentContracts,
  //   currentPermissions,
  //   userInfo.email,
  //   userInfo.firstname,
  //   userInfo.lastname,
  //   userInfo.username,
  // ])

  const [error] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  // const [emailError, setEmailError] = useState('')

  const handleUserChange = (key, e) => {
    const value = e.target.value
    setNewUser((prevState) => ({ ...prevState, [key]: value }))
  }

  const handleArrayChange = (e, key, option) => {
    const checked = e.target.checked

    let addData = []
    let removeData = []
    if (checked) {
      addData = [...newUser[key], option]
      removeData = newUser['remove' + capitalizeFirstLetter(key)].filter(
        (o) => o !== option
      )
    } else {
      addData = newUser[key].filter((o) => o !== option)
      removeData = [...newUser['remove' + capitalizeFirstLetter(key)], option]
    }
    setNewUser((prevState) => ({
      ...prevState,
      [key]: addData,
      ['remove' + capitalizeFirstLetter(key)]: removeData,
    }))
  }

  const handleSelectionChange = (key, rowData = [], selectedIds = []) => {
    const addData = selectedIds
    const removeData = rowData
      .map((bp) => bp.tableId)
      .filter((id) => !selectedIds.includes(id))

    setNewUser((prevState) => ({
      ...prevState,
      [key]: addData,
      ['remove' + capitalizeFirstLetter(key)]: removeData,
    }))
  }

  const isEmailDifferent = userInfo.email !== newUser.email
  const passwordChanged = newUser.password.length > 0

  const updateUser = () => {
    setIsLoading(true)

    if (isEmailDifferent) {
      ChangeUserEmailMutation(
        {
          companyId: user.companyId,
          userId,
          email: newUser.email,
        },
        user.accessToken,
        (res) => {
          console.log(res)
        },
        (error) => {
          console.log({ error })
        }
      )
    }

    if (passwordChanged) {
      ChangeUserPasswordMutation(
        {
          companyId: user.companyId,
          userId,
          password: newUser.password,
        },
        user.accessToken,
        (res) => {
          console.log(res)
        },
        (error) => {
          console.log({ error })
        }
      )
    }

    UpdateUserMutation(
      {
        companyId: user.companyId,
        userId,
        firstname: newUser.firstname,
        lastname: newUser.lastname,
        permissions: newUser.permissions,
        contracts: newUser.contracts,
        billingPartitions: newUser.billingPartitions,
        removePermissions: newUser.removePermissions,
        removeContracts: newUser.removeContracts,
        removeBillingPartitions: newUser.removeBillingPartitions,
      },
      user.accessToken,
      () => {
        console.log('success', newUser)
        setIsLoading(false)
        history.push('/' + currentLanguage + Routes.admin)
      },
      (error) => {
        setIsLoading(false)
        console.log(error.source.errors[0].extensions)
      }
    )
  }

  return (
    <PageContainer>
      <NavBack
        title={t('UPDATE USER')}
        to={'/' + currentLanguage + Routes.admin}
      />

      <Wrapper>
        <UserInfoInput
          page='update-user'
          newUser={newUser}
          handleUserChange={handleUserChange}
        />

        <PermissionsInput
          permissions={newUser.permissions}
          handleArrayChange={handleArrayChange}
          availablePermissions={availablePermissions}
          loading={loading}
        />

        <ContractsGrid
          rowData={availableContracts}
          currentData={newUser.contracts}
          selectedData={newUser.billingPartitions}
          handleSelectionChange={handleSelectionChange}
        />

        <BillingPartitionsGrid
          rowData={availableBillingPartitions}
          currentData={newUser.billingPartitions}
          selectedData={newUser.contracts}
          handleSelectionChange={handleSelectionChange}
        />
      </Wrapper>

      {error && <p>{error}</p>}

      {loading ? null : (
        <ButtonRow>
          <ButtonPrimary isLoading={isLoading} onClick={updateUser}>
            {t('UPDATE USER')}
          </ButtonPrimary>
        </ButtonRow>
      )}
    </PageContainer>
  )
}

const query = graphql`
  query UpdateUserQuery($companyId: ID!, $userId: ID!) {
    Me {
      Company(id: $companyId) {
        availablePermissions: permissions
        contracts: Contracts {
          tableId
          name
          billingPartitions {
            tableId
            name
          }
        }
        User(id: $userId) {
          tableId
          username
          firstname
          lastname
          email
          currentPermissions: permissions
          currentRestrictions: restrictions {
            contracts
            billingPartitions
          }
        }
      }
    }
  }
`

const UpdateUser = () => {
  const { user } = useUserContext()
  const { userId } = useParams()

  return (
    <QueryRendererContainer
      query={query}
      variables={{ companyId: user.companyId, userId }}
      // loading={<PureUpdateUser loading />}
      render={(data) => {
        const { availablePermissions, contracts } = data.Me.Company

        const availableContracts = contracts.map((c) => ({
          tableId: c.tableId,
          name: c.name,
          billingPartitions: c.billingPartitions,
        }))
        const availableBillingPartitions = getBillingPartitions(contracts)

        const {
          tableId,
          username,
          firstname,
          lastname,
          email,
          currentPermissions,
          currentRestrictions,
        } = data.Me.Company.User

        return (
          <PureUpdateUser
            userId={userId}
            userInfo={{ tableId, username, firstname, lastname, email }}
            availablePermissions={availablePermissions}
            availableContracts={availableContracts}
            availableBillingPartitions={availableBillingPartitions}
            currentPermissions={currentPermissions}
            currentContracts={currentRestrictions?.contracts}
            currentBillingPartitions={currentRestrictions?.billingPartitions}
          />
        )
      }}
    />
  )
}

export default UpdateUser
