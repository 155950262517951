import styled from 'styled-components/macro'
import { Container, Col } from 'react-grid-system'

import homeBackground from '../../assets/HomeBackground.png'
import { portalTheme } from 'pyrexx-react-library'

const ContainerStyled = styled(Container)`
  background-color: #e5e5e5;
  background-image: url(${homeBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const StyledCol = styled(Col)`
  background-color: ${portalTheme.color.basicColorWhite};
  padding-top: 2rem;
  padding-bottom: 2rem;
`

const TitleStyled = styled.h1`
  text-align: center;
  font-size: ${portalTheme.font.size.headingRegular};
`

const DescriptionStyled = styled.p`
  text-align: center;

  font-size: ${portalTheme.font.size.headingSmall};
`

export { ContainerStyled, TitleStyled, DescriptionStyled, StyledCol }
