import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query PropertyManagementGetHouseEntranceCoordinatesQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
  ) {
    Me {
      Company(id: $companyId) {
        HouseEntrances(options: { startRow: $startRow, endRow: $endRow }) {
          id
          tableId
          address {
            street
            number
            zip
            city
          }
          coordinates {
            lat
            lng
          }
          countUsageUnits
        }
      }
    }
  }
`
