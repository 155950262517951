import styled, { css } from 'styled-components/macro'

import { ButtonIcon, LoadingSpinner, portalTheme } from 'pyrexx-react-library'

import homeBackground from '../../assets/HomeBackground.png'
import React from 'react'

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  padding: 2rem 3rem;

  background-color: #e5e5e5;
  background-image: url(${homeBackground});
  //background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const Searchbox = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;

  background-color: #fff;

  border-radius: 50px;

  height: 46px;
  //width: 90%;
  //max-width: 350px;

  padding: 10px;
  margin: 16px 0;
`

export const SearchInput = styled.input`
  flex: 1;

  border: none;
  border-radius: 50px;

  font-family: ${portalTheme.font.family.quicksand};
  font-size: 1em;
  color: ${portalTheme.color.basicColorBlack};

  min-width: 0;

  padding-right: 10px;

  transition: all 0.5s;

  &::placeholder {
    color: #767676;
    text-align: center;
  }

  &::-moz-placeholder {
    opacity: 1;
  }

  &:focus {
    outline: none;
    color: ${portalTheme.color.linkColorPrimary};

    &::placeholder {
      color: ${portalTheme.color.linkColorPrimary};
    }
  }
`

export const SearchButton = styled(ButtonIcon).attrs((props) => ({
  icon: 'search',
  size: '28px',
  handleClick: props.handleClick,
}))`
  margin-right: 0.5em;
`

export const ClearButton = styled(ButtonIcon).attrs((props) => ({
  icon: 'close',
  size: '20px',
  isHidden: props.isHidden,
  handleClick: props.handleClick,
}))`
  margin: 0.5em;
`

// Switcher.js

export const CompaniesBox = styled.div`
  display: flex;
  flex-flow: column nowrap;

  background-color: #fff;

  width: 450px;

  ${(props) =>
    props.enableScroll &&
    css`
      max-height: 230px;
      overflow-y: scroll;
    `}
`

const selectedCompanyItemCSS = css`
  color: #fff;
  background-color: #89a8c8;
`

export const CompanyItem = styled.button`
  margin: 0;
  padding: 1rem 2rem;

  border: none;
  font-size: inherit;
  font-family: inherit;
  text-align: left;

  &:nth-child(odd) {
    background-color: #fff;
  }

  &:nth-child(even) {
    background-color: #f8f8f8;
  }

  cursor: pointer;
  user-select: auto;

  &:hover,
  &:focus {
    ${selectedCompanyItemCSS};
  }

  &:active {
    ${selectedCompanyItemCSS};
  }

  && {
    ${(props) => props.isSelected && selectedCompanyItemCSS};
  }
`

export const NotFoundMessage = styled.p`
  margin: 1.5rem;
`

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 2rem 0;
`

export const LoadingStyled = styled.div`
  background-color: transparent;

  margin: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  & div {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
  }
`

export const Loading = (props) => {
  const { spinner = true } = props

  return <LoadingStyled>{spinner && <LoadingSpinner />}</LoadingStyled>
}
