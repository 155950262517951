import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import { LoadingSpinner, portalTheme } from 'pyrexx-react-library'
import FilterItemContainer from './FilterItemContainer/FilterItemContainer'

const StyledFilterList = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 300px;
  align-content: center;
`

const FilterList = (props) => {
  const {
    searchWord = '',
    setOpenFromStart,
    setConfirmEditActive,
    setDeleteActive,
    setCurrentFilter,
    filterOptions,
    editActive,
    setEditActive,
    currentGrid,
    MessageBoxVisible,
    setMessageBoxVisible,
    loadingFilter,
  } = props
  const { t } = useTranslation()

  const generateFilteredItems = useCallback(
    (filterItems) => {
      return filterItems.map((filter, index) => {
        return (
          <FilterItemContainer
            setOpenFromStart={setOpenFromStart}
            filterItem={filter}
            setDeleteActive={setDeleteActive}
            setConfirmEditActive={setConfirmEditActive}
            setCurrentFilter={setCurrentFilter}
            editActive={editActive}
            setEditActive={setEditActive}
            currentGrid={currentGrid}
            MessageBoxVisible={MessageBoxVisible}
            setMessageBoxVisible={setMessageBoxVisible}
            key={'FilterItem' + index}
          />
        )
      })
    },
    [
      MessageBoxVisible,
      currentGrid,
      editActive,
      setConfirmEditActive,
      setCurrentFilter,
      setDeleteActive,
      setEditActive,
      setMessageBoxVisible,
      setOpenFromStart,
    ]
  )

  const generateFilterItems = useCallback(() => {
    let filteredItems = filterOptions
    if (searchWord === '') {
      return generateFilteredItems(filteredItems)
    }

    filteredItems = filterOptions.filter(
      (filterItem) =>
        filterItem.filter.toLowerCase().indexOf(searchWord.toLowerCase()) !== -1
    )
    if (filteredItems.length < 1) {
      return <span>{t('NO FILTERS FOUND')}</span>
    }

    return generateFilteredItems(filteredItems)
  }, [filterOptions, generateFilteredItems, searchWord, t])

  return (
    <StyledFilterList>
      {loadingFilter ? (
        <div style={{ height: '30px', width: '30px' }}>
          <LoadingSpinner
            theme={{
              components: {
                spinner: {
                  /**
                   * Has to be a { css } oject from styled-components
                   */
                  fontSize: portalTheme.font.size.bodySmall,
                  fontWeight: portalTheme.font.weight.regular,
                  size: '20px',
                },
              },
            }}
            style={{
              position: 'unset',
              backgroundColor: 'white',
            }}
          />
        </div>
      ) : (
        generateFilterItems()
      )}
      {filterOptions && filterOptions.length < 1 && (
        <span>{t('NO FILTERS AVAILABLE')}</span>
      )}
    </StyledFilterList>
  )
}
export default FilterList
