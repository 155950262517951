export default (contracts = []) => {
  return contracts.reduce((prev, curr) => {
    const billingPartitions = curr.billingPartitions.map((bp) => ({
      ...bp,
      tableId: bp.tableId,
      contractId: curr.tableId,
    }))

    return [...prev, ...billingPartitions]
  }, [])
}
