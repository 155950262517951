import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Col, Row } from 'react-grid-system'
import * as Yup from 'yup'
import { CustomSearchbox } from '../MainHeader.styles'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import {
  ButtonPrimary,
  LoadingSpinner,
  portalTheme,
  SimpleNote,
} from 'pyrexx-react-library'
import {
  LinkButtonStyled,
  StyledMountingAnimation,
} from '../../../../styles/HelperStylesComponents.styles'
import SearchContainerQuery from './queries/SearchContainer'
import UserContext from '../../../../helper/userContext'
import { css } from 'styled-components'
import { Routes } from '../../../../configs/RouteConfig'
import {
  CustomStyledInput,
  StyledColCenter,
  StyledColColor,
  StyledColResultsOverflow,
  StyledColSearchResultItem,
  StyledContainerLink,
  StyledErrorContainer,
} from './SearchContainer.styles'
import { fetchQuery } from 'react-relay'
import environment from '../../../../environments/pom/Environment'

const SearchContainer = ({
  handleChange,
  querySearchVariables,
  setQuerySearchVariables,
  searchModalOpen,
  initFiltersForSearchModal,
  handleModalClose,
}) => {
  const increaseCounter = 50
  const ref = useRef({
    triggerCheckbox: false,
    searchCursor: 0,
    firstOpen: true,
    noMoreAddItems: false,
    currentSearchText: '',
  })
  const { t } = useTranslation()

  const listInnerRef = useRef()
  const [loading, setLoading] = useState(false)
  const [searchResultsArray, setSearchResultsArray] = useState([])
  const [searchResultsCount, setSearchResultsCount] = useState(0)
  const [showSearchTextError, setShowSearchTextError] = useState(false)

  useEffect(() => {
    if (showSearchTextError) {
      const timeOutId = setTimeout(() => {
        setShowSearchTextError(false)
      }, 3000)
      return () => clearTimeout(timeOutId)
    }
  }, [showSearchTextError])

  const schema = Yup.object({
    searchText: Yup.string()
      .min(3, t('SEARCH TEXT MUST BE AT LEAST 3 CHARACTERS'))
      .required(t('SEARCH TEXT MUST BE AT LEAST 3 CHARACTERS')),
    filters: Yup.array()
      .min(1, t('AT LEAST ONE FILTER TYPE IS REQUIRED'))
      .required(t('AT LEAST ONE FILTER TYPE IS REQUIRED'))
      .nullable(),
  })

  const formik = useFormik({
    initialValues: {
      searchText: querySearchVariables.searchText,
      filters: querySearchVariables.filters,
    },
    validationSchema: schema,
    onSubmit: (values, formikb) => {
      ref.current.currentSearchText = querySearchVariables.searchText
      ref.current.searchCursor = 0
      ref.current.noMoreAddItems = false
      setSearchResultsArray([])
      getSearchData()
    },
  })

  useEffect(() => {
    if (querySearchVariables.searchText !== formik.values.searchText) {
      formik.setFieldValue('searchText', querySearchVariables.searchText)
    }
  }, [formik, querySearchVariables.searchText])

  useEffect(() => {
    if (searchModalOpen && ref.current.firstOpen) {
      if (formik.errors.searchText) {
        setShowSearchTextError(true)
      }
      ref.current.firstOpen = false
      ref.current.searchCursor = 0
      formik.handleSubmit()
    }
    if (!searchModalOpen) {
      ref.current.firstOpen = true
    }
  }, [formik, querySearchVariables.searchText, searchModalOpen])

  const { user, redirectRoute } = useContext(UserContext)
  const generateQueryVariable = useCallback(() => {
    return {
      companyId: user.companyId,
      startRow: ref.current.searchCursor,
      endRow: ref.current.searchCursor + increaseCounter,
      sortModel: [],
      SearchInvoices: querySearchVariables.filters.includes('invoices'),
      SearchInvoicesCount:
        querySearchVariables.filters.includes('invoices') &&
        ref.current.searchCursor === 0,
      Invoicesfilters: [
        {
          name: 'invoiceNumber',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],
      SearchHouseEntrances: querySearchVariables.filters.includes('addresses'),
      SearchHouseEntrancesCount:
        querySearchVariables.filters.includes('addresses') &&
        ref.current.searchCursor === 0,
      HouseEntrancesfilters: [
        {
          name: 'address.street',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
        {
          name: 'address.zip',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
        {
          name: 'address.city',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],
      SearchAppointments: querySearchVariables.filters.includes('performances'),
      SearchAppointmentsCount:
        querySearchVariables.filters.includes('performances') &&
        ref.current.searchCursor === 0,
      Appointmentsfilters: [
        {
          name: 'tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],
      SearchTenants: querySearchVariables.filters.includes('tenants'),
      SearchTenantsCount:
        querySearchVariables.filters.includes('tenants') &&
        ref.current.searchCursor === 0,
      Tenantsfilters: [
        {
          name: 'tenants.firstname',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
        {
          name: 'tenants.lastname',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],
      TenantsSubfilters: [
        {
          name: 'fullname',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],
      SearchCustomerNumbers:
        querySearchVariables.filters.includes('customerNumbers'),
      SearchCustomerNumbersCount:
        querySearchVariables.filters.includes('customerNumbers') &&
        ref.current.searchCursor === 0,
      CustomerNumbersfilters: [
        {
          name: 'referenceNumber',
          filterType: 'text',
          operator: 'OR',
          conditions: [
            {
              value: querySearchVariables.searchText,
              type: 'contains',
            },
          ],
        },
      ],
    }
  }, [
    querySearchVariables.filters,
    querySearchVariables.searchText,
    user.companyId,
  ])

  const generateSearchResultsItem = useCallback(
    (type, data, index) => {
      const generateItemRow = (route, color, label, content) => {
        return (
          <StyledContainerLink
            key={type + index + ref.current.searchCursor}
            fluid
            onClick={() => {
              handleModalClose()
              redirectRoute(route)
            }}
          >
            <Row nogutter>
              <StyledColColor xs='content' color={color}>
                {label}
              </StyledColColor>
            </Row>
            <Row nogutter>
              <StyledColSearchResultItem>
                {content.map((item, index) => {
                  return (
                    <Row key={type + item.tableId + index}>
                      <Col>
                        {getHighlightedText(
                          item,
                          querySearchVariables.searchText
                        )}
                      </Col>
                    </Row>
                  )
                })}
              </StyledColSearchResultItem>
            </Row>
          </StyledContainerLink>
        )
      }

      const getHighlightedText = (text, highlight) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
        return (
          <span>
            {' '}
            {parts.map((part, i) => (
              <span
                key={i}
                style={
                  part.toLowerCase() === highlight.toLowerCase()
                    ? {
                        fontWeight: 'bold',
                        color: portalTheme.color.linkColorPrimary,
                      }
                    : {}
                }
              >
                {part}
              </span>
            ))}{' '}
          </span>
        )
      }
      switch (type) {
        case 'invoice':
          return generateItemRow(
            Routes.invoice + '/' + data.invoiceNumber,
            '#aaccef',
            t('INVOICE'),
            [
              t('INVOICE NUMBER: {INVOICENUMBER}', {
                INVOICENUMBER: data.invoiceNumber,
              }),
            ]
          )
        case 'address':
          return generateItemRow(
            Routes.propertyUnit + '/' + data.tableId,
            '#d6c2c1',
            t('ADDRESS'),
            [
              data.address.street + ' ' + data.address.number,
              data.address.zip + ' ' + data.address.city,
            ]
          )
        case 'appointment':
          return generateItemRow(
            Routes.appointment + '/' + data.tableId,
            '#e3fb73',
            t('PERFORMANCE'),
            [
              t('PERFORMANCE NUMBER: {PERFORMANCENUMBER}', {
                PERFORMANCENUMBER: data.tableId,
              }),
              data.tenant ? data.tenant.fullname : t('VACANCY'),
              data.address.street + ' ' + data.address.number,
              data.address.zip + ' ' + data.address.city,
            ]
          )
        case 'tenant':
          return generateItemRow(
            Routes.usageUnit + '/' + data.tableId,
            '#aaccef',
            t('INHABITANT'),
            [
              t('CUSTOMER NUMBER: {CUSTOMERENUMBER}', {
                CUSTOMERENUMBER: data.referenceNumber,
              }),
              data.tenant ? data.tenant.fullname : t('VACANCY'),
              data.address.street + ' ' + data.address.number,
              data.address.zip + ' ' + data.address.city,
            ]
          )
        case 'customerNumber':
          return generateItemRow(
            Routes.usageUnit + '/' + data.tableId,
            '#ffd270',
            t('CUSTOMER NUMBER'),
            [
              t('CUSTOMER NUMBER: {CUSTOMERENUMBER}', {
                CUSTOMERENUMBER: data.referenceNumber,
              }),
              data.address.street + ' ' + data.address.number,
              data.address.zip + ' ' + data.address.city,
            ]
          )
        default:
          return ''
      }
    },
    [handleModalClose, querySearchVariables.searchText, redirectRoute, t]
  )

  const generateSearchResultArray = useCallback(
    (data) => {
      let results = []
      let count = 0

      if (data?.AppointmentsSearch) {
        results = [
          ...results,
          ...data?.AppointmentsSearch.map((item, index) => {
            return generateSearchResultsItem('appointment', item, index)
          }),
        ]
      }
      if (data?.CountAppointmentsSearch) {
        count = count + data?.CountAppointmentsSearch
      }

      if (data?.TenantsSearch) {
        results = [
          ...results,
          ...data?.TenantsSearch.map((item, index) => {
            return item.tenants.map((tenant) => {
              const tempItem = {
                ...item,
                tenant: {
                  fullname: tenant.fullname,
                },
              }
              return generateSearchResultsItem('tenant', tempItem, index)
            })
          }),
        ].flat(1)
      }
      if (data?.CountTenantsSearch) {
        count = count + data?.CountTenantsSearch
      }

      if (data?.InvoicesSearch) {
        results = [
          ...results,
          ...data?.InvoicesSearch.map((item, index) => {
            return generateSearchResultsItem('invoice', item, index)
          }),
        ]
      }
      if (data?.CountInvoicesSearch) {
        count = count + data?.CountInvoicesSearch
      }

      if (data?.CustomerNumbersSearch) {
        results = [
          ...results,
          ...data?.CustomerNumbersSearch.map((item, index) => {
            return generateSearchResultsItem('customerNumber', item, index)
          }),
        ]
      }
      if (data?.CountCustomerNumbersSearch) {
        count = count + data?.CountCustomerNumbersSearch
      }

      if (data?.HouseEntrancesSearch) {
        results = [
          ...results,
          ...data?.HouseEntrancesSearch.map((item, index) => {
            return generateSearchResultsItem('address', item, index)
          }),
        ]
      }
      if (data?.CountHouseEntrancesSearch) {
        count = count + data?.CountHouseEntrancesSearch
      }

      if (ref.current.searchCursor === 0) {
        setSearchResultsCount(count)
      }
      return results
    },
    [generateSearchResultsItem]
  )

  const getSearchData = useCallback(
    (addItems = false) => {
      setLoading(true)
      return fetchQuery(
        environment(user.accessToken),
        SearchContainerQuery,
        generateQueryVariable(),
        {
          force: true,
        }
      )
        .then((data) => {
          const results = data.Me?.Company
          const resultArray = generateSearchResultArray(results)
          if (addItems) {
            if (resultArray.length === 0) {
              ref.current.noMoreAddItems = true
            } else {
              setSearchResultsArray((state) => {
                return [...state, ...resultArray]
              })
            }
          } else {
            setSearchResultsArray(resultArray)
          }
          setLoading(false)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    [generateQueryVariable, generateSearchResultArray, user.accessToken]
  )

  const generateSearchView = useCallback(() => {
    if (loading && searchResultsArray.length === 0) {
      return <span>{t('PLEASE WAIT')}</span>
    }
    if (searchResultsArray.length === 0) {
      return <span>{t('NO RESULTS')}</span>
    }

    const onScroll = () => {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
        if (scrollTop + clientHeight >= scrollHeight - 15) {
          // TO SOMETHING HERE
          if (!loading && !ref.current.noMoreAddItems) {
            if (
              ref.current.currentSearchText !== querySearchVariables.searchText
            ) {
              setQuerySearchVariables((state) => {
                return { ...state, searchText: ref.current.currentSearchText }
              })
            }
            ref.current.searchCursor =
              ref.current.searchCursor + increaseCounter + 1
            getSearchData(true)
          }
        }
      }
    }
    return (
      <>
        <Row style={{ marginBottom: '1rem' }} nogutter>
          <Col>
            {t('RESULT {COUNT}', {
              COUNT: '(' + searchResultsCount + ')',
            })}
          </Col>
        </Row>
        <Row nogutter>
          <StyledColResultsOverflow
            xs={12}
            onScroll={() => onScroll()}
            ref={listInnerRef}
          >
            {searchResultsArray}
            {loading && (
              <div style={{ height: '30px', width: '30px' }}>
                <LoadingSpinner
                  theme={{
                    components: {
                      spinner: {
                        /**
                         * Has to be a { css } oject from styled-components
                         */
                        fontSize: portalTheme.font.size.bodySmall,
                        fontWeight: portalTheme.font.weight.regular,
                        size: '20px',
                      },
                    },
                  }}
                  style={{
                    position: 'unset',
                    backgroundColor: 'white',
                  }}
                />
              </div>
            )}
          </StyledColResultsOverflow>
        </Row>
      </>
    )
  }, [
    getSearchData,
    loading,
    querySearchVariables.searchText,
    searchResultsArray,
    searchResultsCount,
    setQuerySearchVariables,
    t,
  ])

  const generateFilters = useCallback(() => {
    const themeCheckBox = {
      components: {
        customCheckboxRadio: {
          icon: {
            fontSize: '18px',
          },
        },
        styledInput: {
          additionalStyles: css`
            line-height: normal;
          `,
        },
      },
    }

    return initFiltersForSearchModal.map((filterObject) => {
      return (
        <CustomStyledInput
          theme={themeCheckBox}
          key={filterObject.search}
          type='checkbox'
          disabled={loading}
          value={formik.values.filters.includes(filterObject.search)}
          onChange={() => {
            ref.current.triggerCheckbox = true
            if (formik.values.filters.includes(filterObject.search)) {
              const newFilterArray = formik.values.filters.filter(
                (search) => search !== filterObject.search
              )
              formik.setFieldValue('filters', newFilterArray)
            } else {
              formik.setFieldValue('filters', [
                ...formik.values.filters,
                filterObject.search,
              ])
            }
          }}
          id={filterObject.search}
          name={filterObject.search}
          label={filterObject.label}
          setFieldValue={() => {}}
        />
      )
    })
  }, [formik, initFiltersForSearchModal, loading])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setQuerySearchVariables((state) => {
        return { ...state, filters: formik.values.filters }
      })
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [formik.values.filters, setQuerySearchVariables])

  useEffect(() => {
    if (!formik.errors.filters && ref.current.triggerCheckbox) {
      if (formik.errors.searchText) {
        setShowSearchTextError(true)
        return
      }
      ref.current.searchCursor = 0
      getSearchData()
    }
  }, [formik.errors, getSearchData, querySearchVariables.filters])

  return (
    <>
      <Row style={{ marginBottom: '10px' }}>
        <Col offset={{ xs: 3 }} xs={9}>
          <Row justify='start' align='center' gutterWidth={10}>
            <Col xs={5}>
              <CustomSearchbox
                twoColStyle
                disabledborder
                disabled={loading}
                disabledComponent={
                  <LoadingSpinner
                    theme={{
                      components: {
                        spinner: {
                          /**
                           * Has to be a { css } oject from styled-components
                           */
                          fontSize: portalTheme.font.size.bodySmall,
                          fontWeight: portalTheme.font.weight.regular,
                          size: '20px',
                        },
                      },
                    }}
                    style={{
                      position: 'unset',
                      backgroundColor: 'white',
                    }}
                  />
                }
                style={{ width: '100%' }}
                hideIcon
                id='searchText'
                clearSearchAfterSubmit={false}
                handleSubmit={() => {
                  if (formik.errors.searchText) {
                    setShowSearchTextError(true)
                    return
                  }
                  formik.handleSubmit()
                }}
                handleChange={(search) => {
                  ref.current.triggerCheckbox = false
                  handleChange(search)
                  formik.setFieldValue('searchText', search)
                }}
                placeholder={t('SEARCH')}
                searchText={querySearchVariables.searchText}
              />
              <StyledErrorContainer>
                <StyledMountingAnimation
                  transitionName='ShowSearchTextError'
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={2000}
                >
                  {showSearchTextError && (
                    <span>{formik.errors.searchText}</span>
                  )}
                </StyledMountingAnimation>
              </StyledErrorContainer>
            </Col>
            <Col
              xs='content'
              style={{ display: 'flex', justifyItems: 'start' }}
            >
              <ButtonPrimary
                disabled={loading}
                style={{
                  height: '35px',
                  paddingLeft: '2rem',
                  paddingRight: '2rem',
                  fontSize: '14px',
                }}
                onClick={() => {
                  if (formik.errors.searchText) {
                    setShowSearchTextError(true)
                    return
                  }
                  formik.handleSubmit()
                }}
              >
                {t('SEARCH')}
              </ButtonPrimary>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Row justify='between'>
            <StyledColCenter xs='content'>{t('FILTER')}</StyledColCenter>
            <StyledColCenter xs='content'>
              {!loading && (
                <LinkButtonStyled
                  disabled={loading}
                  type='button'
                  onClick={() => {
                    formik.setFieldValue(
                      'filters',
                      initFiltersForSearchModal.map(
                        (filterObject) => filterObject.search
                      )
                    )
                    return false
                  }}
                >
                  {t('RESET')}
                </LinkButtonStyled>
              )}
            </StyledColCenter>
          </Row>
          <Row>
            <Col>{generateFilters()}</Col>
          </Row>
          {formik.errors?.filters && (
            <Row>
              <Col>
                <SimpleNote
                  noteStatus='warning'
                  text={formik.errors?.filters}
                />
              </Col>
            </Row>
          )}
        </Col>
        <Col xs={9}>{searchModalOpen && generateSearchView()}</Col>
      </Row>
    </>
  )
}

export default SearchContainer
