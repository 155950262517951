function dummyTranslate(text) {
  return text
}

const layoutPreviewDummyTranslations = (value) => {
  switch (value) {
    case 'LOGO_UPLOAD':
      return dummyTranslate('LOGO UPLOAD')
    case 'SENDER_ADDRESS':
      return dummyTranslate('SENDER ADDRESS')
    case 'ADD_LETTERHEAD':
      return dummyTranslate('ADD LETTER HEAD')
    case 'ADD_FOOTER':
      return dummyTranslate('ADD FOOTER')
    case 'ADD_RECIPIENT':
      return dummyTranslate('ADD RECIPIENT')
    case 'ADD_SUBJECT':
      return dummyTranslate('ADD SUBJECT')
    case 'WRITE_TEXT':
      return dummyTranslate('WRITE TEXT')
    case 'NONE':
    default:
      return value
  }
}

export default layoutPreviewDummyTranslations
