function dummyTranslate(text) {
  return text
}

const usageUnitLockPeriodReasonEnumList = (value) => {
  switch (value) {
    case 'OWN_SMOKE_DETECTOR':
      return dummyTranslate('INHABITANT HAS OWN SMOKE DETECTORS')

    case 'TENANT_REJECTED_SMOKE_DETECTORS':
      return dummyTranslate('INHABITANT REJECTED SMOKE DETECTORS')

    case 'SMOKE_DETECTORS_NOT_USEFUL':
      return dummyTranslate('SMOKE DETECTORS NOT REASONABLE IN THIS UNIT')

    case 'UNIT_DOESNT_EXIST':
      return dummyTranslate('UNIT DOES NOT EXIST')

    case 'TEMP_FOR_RENOVATION':
      return dummyTranslate('UNIT LOCKED FOR RENOVATIONS')

    case 'UNIT_DEMOLITION':
      return dummyTranslate('UNIT WAS DEMOLISHED')

    case 'UNIT_SOLD':
      return dummyTranslate('UNIT WAS SOLD')

    case 'MODERNIZATION':
      return dummyTranslate('REMOVED DUE TO RESIDENCE CONSOLIDATION')

    case 'LOCKED_TIL_INDIVIDUAL_ORDER':
      return dummyTranslate('UNIT LOCKED UNTIL AN INDIVIDUAL ORDER')

    case 'CUSTOMER_WISH':
      return dummyTranslate('CUSTOMER DOES NOT WANT OUTFITTING')

    case 'SYSTEM':
      return dummyTranslate('UNKNOWN')

    case 'UNKNOWN':
      return dummyTranslate('UNKNOWN')

    case 'TEMP_FOR_CLEARIFICATION':
      return dummyTranslate('TEMPORARILY LOCKED FOR CLARIFICATION')

    case 'CONTRACT_CANCELLED':
      return dummyTranslate('CONTRACT CANCELLED')

    case 'CONTRACT_EXPIRED':
      return dummyTranslate('CONTRACT EXPIRED')

    case 'HIGH_CEILING':
      return dummyTranslate('TOO HIGH CEILINGS')

    case 'SMOKE_DETECTORS_IN_SAFE_KEEPING':
      return dummyTranslate('SMOKE DETECTORS IN SAFE KEEPING')

    case 'CUSTOMER_TEMP_DEFERRED_INSTALLATION':
      return dummyTranslate('CUSTOMER DOES NOT WANT OUTFITTING CURRENTLY')

    case 'DECENTRAL_WARM_WATER':
      return dummyTranslate('DECENTRAL WARM WATER SUPPLY')

    case 'UNEXPECTED_BUSINESS_UNIT':
      return dummyTranslate('BUSINESS UNIT WITHOUT OUTFITTING ORDER')

    case 'NONE':
    default:
      return ''
  }
}

export default usageUnitLockPeriodReasonEnumList
