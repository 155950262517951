import React from 'react'
import styled from 'styled-components/macro'
import { css } from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Icon } from 'pyrexx-react-library'

const BackButtonLabel = ({ disabled = false, ...restOfProps }) => {
  const { t } = useTranslation()

  return (
    <Wrapper disabled={disabled} {...restOfProps}>
      <Icon icon='arrow-left' style={{ marginRight: '.25rem' }} size='20px' />
      <p style={{ fontSize: '20px' }}>{t('BACK')}</p>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  font-family: 'Quicksand', sans-serif;

  & p {
    margin: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      color: #d8d8d8;
      cursor: not-allowed;
    `}
`

BackButtonLabel.propTypes = {
  disabled: PropTypes.bool,
}

export default BackButtonLabel
