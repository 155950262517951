import { hasCookie } from 'pyrexx-react-library'
const BANNER_PREFIX = 'pomPortal'

const localStorageAvailable = () => {
  // TODO INCLUDE IT AGAIN IF THE BANNER I IMPLEMENTED

  if (!hasCookie('preferences', BANNER_PREFIX)) {
    return false
  }

  const test = true
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

const IsJsonString = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const IsJsonObject = (object) => {
  if (JSON.stringify(object)) {
    return true
  } else {
    return false
  }
}

const returnGetItem = (item) => {
  if (IsJsonString(item)) {
    return JSON.parse(item)
  } else {
    return item
  }
}

const returnSetItem = (item) => {
  if (IsJsonObject(item)) {
    return JSON.stringify(item)
  } else {
    return item
  }
}

const handleLocalStorage = (command, key, value, fallback) => {
  if (localStorageAvailable()) {
    switch (command) {
      case 'get':
        return localStorage.getItem(key)
          ? returnGetItem(localStorage.getItem(key))
          : fallback
      case 'set':
        localStorage.setItem(key, returnSetItem(value))
        break
      case 'clear':
        localStorage.removeItem(key)
        sessionStorage.removeItem(key)
        break
      default:
    }
  } else {
    switch (command) {
      case 'get':
        return sessionStorage.getItem(key)
          ? returnGetItem(sessionStorage.getItem(key))
          : fallback
      case 'set':
        sessionStorage.setItem(key, returnSetItem(value))
        break
      case 'clear':
        localStorage.removeItem(key)
        sessionStorage.removeItem(key)
        break
      default:
    }
  }
}

export default handleLocalStorage
