import React from 'react'
import BulletedList from '../../../components/AgGrid/renderers/BulletedList'

const ContractPerformanceRenderer = (props) => {
  const { value } = props
  return (
    <BulletedList>
      {value
        .filter((item) => item?.actionPosition?.name)
        .map((item) => (
          <li key={item?.actionPosition?.id}>{item?.actionPosition?.name}</li>
        ))}
    </BulletedList>
  )
}
export default ContractPerformanceRenderer
