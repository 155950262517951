/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LetterPreviewQueryVariables = {|
  letterId: string,
  companyId: string,
|};
export type LetterPreviewQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +data: ?{|
          +id: string,
          +tableId: number,
          +preview: ?string,
          +title: ?string,
        |}
      |}
    |}
  |}
|};
export type LetterPreviewQuery = {|
  variables: LetterPreviewQueryVariables,
  response: LetterPreviewQueryResponse,
|};
*/


/*
query LetterPreviewQuery(
  $letterId: ID!
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        data: letter(id: $letterId) {
          id
          tableId
          preview
          title
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "letterId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessPost",
  "kind": "LinkedField",
  "name": "businessPost",
  "plural": false,
  "selections": [
    {
      "alias": "data",
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "letterId"
        }
      ],
      "concreteType": "Letter",
      "kind": "LinkedField",
      "name": "letter",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tableId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preview",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LetterPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "LetterPreviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9f1ec87c01dce8aaba363bec995525b5",
    "id": null,
    "metadata": {},
    "name": "LetterPreviewQuery",
    "operationKind": "query",
    "text": "query LetterPreviewQuery(\n  $letterId: ID!\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        data: letter(id: $letterId) {\n          id\n          tableId\n          preview\n          title\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '863273f5100755c29059d32538b06b82';

module.exports = node;
