import React from 'react'
import { withTranslation } from 'react-i18next'
import { Container, Row, Col } from 'react-grid-system'
import styled from 'styled-components/macro'

import { SimpleLink, Icon } from 'pyrexx-react-library'

import { Links } from '../../../configs/LinkConfig'

const ColStyled = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.6rem;
`

const TopHeader = (props) => {
  return (
    <Container xs sm md lg>
      <Row align='center'>
        <ColStyled align='center'>
          <Icon
            icon='versionchange'
            size='30px'
            color='red'
            style={{ marginRight: '1rem' }}
          />
          <SimpleLink
            url={Links.pcpPage}
            label={props.t('GO BACK TO ACTUAL PCP VERSION')}
          />
        </ColStyled>
      </Row>
    </Container>
  )
}

export default withTranslation('translation')(TopHeader)
