/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LetterEnqueueResultType = "INVALID_LAYOUT" | "LETTER_ALREADY_SENT" | "NONE" | "NO_COST_POSITION" | "NO_RECIPIENT" | "QUOTA_EXCEEDED" | "SUCCESS" | "UNEXPECTED_ERROR" | "%future added value";
export type LetterRecipientStatusType = "INVALID" | "PENDING" | "SENT" | "%future added value";
export type LetterStatusType = "DRAFT" | "NONE" | "PENDING" | "SENT" | "%future added value";
export type LetterType = "MASS" | "NONE" | "SINGLE" | "%future added value";
export type MimeType = "BMP" | "CSV" | "GIF" | "HTML" | "JPG" | "PDF" | "PLAINTEXT" | "PNG" | "SVG" | "XML" | "X_BMP" | "X_MS_BMP" | "%future added value";
export type UserSalutationType = "COMPANY" | "MR" | "MRS" | "NONE" | "%future added value";
export type UserTitleType = "DR" | "NONE" | "PROF" | "PROF_DR" | "%future added value";
export type LetterQueryVariables = {|
  companyId: string,
  letterId: string,
|};
export type LetterQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +letter: ?{|
          +id: string,
          +tableId: number,
          +companyId: number,
          +type: ?LetterType,
          +sender: ?{|
            +id: string,
            +tableId: number,
            +firstname: ?string,
            +name: ?string,
            +salutation: UserSalutationType,
            +title: UserTitleType,
            +username: ?string,
          |},
          +attachments: ?$ReadOnlyArray<?{|
            +id: string,
            +tableId: number,
            +filename: ?string,
            +extension: ?string,
            +mimeType: ?MimeType,
            +name: ?string,
          |}>,
          +replacements: ?$ReadOnlyArray<?{|
            +name: string,
            +value: string,
          |}>,
          +recipients: ?$ReadOnlyArray<?{|
            +id: string,
            +firstname: string,
            +lastname: string,
            +salutation: string,
            +street: string,
            +streetNumber: string,
            +zip: string,
            +city: string,
            +country: string,
            +status: LetterRecipientStatusType,
            +uniqueReference: string,
            +tableId: number,
          |}>,
          +status: ?LetterStatusType,
          +internalTitle: ?string,
          +title: ?string,
          +createdAt: any,
          +sentAt: ?any,
          +pageCount: number,
          +recipient1Salutation: ?UserSalutationType,
          +recipient1Title: ?UserTitleType,
          +recipient1FirstName: ?string,
          +recipient1LastName: ?string,
          +recipient2Salutation: ?UserSalutationType,
          +recipient2Title: ?UserTitleType,
          +recipient2FirstName: ?string,
          +recipient2LastName: ?string,
          +recipientCompany: ?string,
          +addressStreetAndNo: ?string,
          +addressAdditional: ?string,
          +addressZip: ?string,
          +enqueuePreCheck: LetterEnqueueResultType,
          +addressCity: ?string,
          +addressCountry: ?string,
          +layout: string,
          +preview: ?string,
          +variables: ?$ReadOnlyArray<?string>,
        |}
      |}
    |}
  |}
|};
export type LetterQuery = {|
  variables: LetterQueryVariables,
  response: LetterQueryResponse,
|};
*/


/*
query LetterQuery(
  $companyId: ID!
  $letterId: ID!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        letter(id: $letterId) {
          id
          tableId
          companyId
          type
          sender {
            id
            tableId
            firstname
            name
            salutation
            title
            username
          }
          attachments {
            id
            tableId
            filename
            extension
            mimeType
            name
          }
          replacements {
            name
            value
          }
          recipients {
            id
            firstname
            lastname
            salutation
            street
            streetNumber
            zip
            city
            country
            status
            uniqueReference
            tableId
          }
          status
          internalTitle
          title
          createdAt
          sentAt
          pageCount
          recipient1Salutation
          recipient1Title
          recipient1FirstName
          recipient1LastName
          recipient2Salutation
          recipient2Title
          recipient2FirstName
          recipient2LastName
          recipientCompany
          addressStreetAndNo
          addressAdditional
          addressZip
          enqueuePreCheck
          addressCity
          addressCountry
          layout
          preview
          variables
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "letterId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "salutation",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessPost",
  "kind": "LinkedField",
  "name": "businessPost",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "letterId"
        }
      ],
      "concreteType": "Letter",
      "kind": "LinkedField",
      "name": "letter",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "companyId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserInfo",
          "kind": "LinkedField",
          "name": "sender",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LetterAttachment",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "filename",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "extension",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mimeType",
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LetterVariableReplacement",
          "kind": "LinkedField",
          "name": "replacements",
          "plural": true,
          "selections": [
            (v5/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "LetterRecipient",
          "kind": "LinkedField",
          "name": "recipients",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastname",
              "storageKey": null
            },
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "street",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "streetNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "zip",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "city",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "country",
              "storageKey": null
            },
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "uniqueReference",
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        },
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalTitle",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sentAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pageCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipient1Salutation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipient1Title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipient1FirstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipient1LastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipient2Salutation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipient2Title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipient2FirstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipient2LastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipientCompany",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressStreetAndNo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressAdditional",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressZip",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enqueuePreCheck",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressCity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressCountry",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "layout",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preview",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "variables",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LetterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LetterQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58f290a3c106974868b8055673798121",
    "id": null,
    "metadata": {},
    "name": "LetterQuery",
    "operationKind": "query",
    "text": "query LetterQuery(\n  $companyId: ID!\n  $letterId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        letter(id: $letterId) {\n          id\n          tableId\n          companyId\n          type\n          sender {\n            id\n            tableId\n            firstname\n            name\n            salutation\n            title\n            username\n          }\n          attachments {\n            id\n            tableId\n            filename\n            extension\n            mimeType\n            name\n          }\n          replacements {\n            name\n            value\n          }\n          recipients {\n            id\n            firstname\n            lastname\n            salutation\n            street\n            streetNumber\n            zip\n            city\n            country\n            status\n            uniqueReference\n            tableId\n          }\n          status\n          internalTitle\n          title\n          createdAt\n          sentAt\n          pageCount\n          recipient1Salutation\n          recipient1Title\n          recipient1FirstName\n          recipient1LastName\n          recipient2Salutation\n          recipient2Title\n          recipient2FirstName\n          recipient2LastName\n          recipientCompany\n          addressStreetAndNo\n          addressAdditional\n          addressZip\n          enqueuePreCheck\n          addressCity\n          addressCountry\n          layout\n          preview\n          variables\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '053be583c97f8ae50ecacbcbbb402a68';

module.exports = node;
