import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
  withHiddenParams,
} from '../../../components/AgGrid/columnDefs'
import {
  AppointmentAccessRenderer,
  AppointmentBulletinWithDownloadRenderer,
  AppointmentIdRenderer,
  AppointmentPropertyNumberRenderer,
  AppointmentStatusRenderer,
  AppointmentTypeRenderer,
  ContractServiceCategoryRenderer,
  HouseEntranceIdRenderer,
} from '../renderers'
import { AppointmentsGridQuery, CountAppointmentsQuery } from '../queries'
import {
  appointmentAccessReasonValueGetter,
  appointmentStatusLabelValueGetter,
  appointmentTypeValueGetter,
  dateTimeValueGetter,
  houseEntranceIdValueGetter,
  serviceCategoryValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../configs/RouteConfig'
import { DateRenderer } from '../../../components/AgGrid/renderers'
import SimpleGridFilter from '../../../components/AgGrid/filters/SimpleGridFilter'
import {
  AppointmentAccessReasonFilter,
  AppointmentStatusFilter,
  AppointmentTypeFilter,
  ContractServiceCategoryFilter,
} from '../filters'
import { getPriorityHeightFromDisplayedColumns } from '../../../helper/calculateRowHeight'

export const usePropertyGrid = () => {
  const { currentLanguage, redirectRoute } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(() => {
    const getRowHeight = (params) => {
      const displayedColumns =
        params.node.columnApi?.getAllDisplayedVirtualColumns()
      const data = params?.data
      const getWidth = (field) =>
        params.node.columnApi?.getColumn?.(field)?.getActualWidth?.()

      const columnParams = [
        {
          field: 'accessReason',
          value: data?.accessReason,
          colWidth: getWidth('accessReason'),
          isLastCol: true,
        },
      ]

      return getPriorityHeightFromDisplayedColumns({
        columnParams,
        displayedColumns,
      })
    }
    const valueGetter = (params) => {
      const field = params.column.getColDef().field
      const colId = params.column.getColDef().colId
      const search = colId || field
      switch (search) {
        case 'status':
          return t(appointmentStatusLabelValueGetter(params))
        case 'houseEntrance':
          return houseEntranceIdValueGetter({ data: params.node.data })
        case 'contract.serviceCategory.tableId':
          return t(serviceCategoryValueGetter({ data: params.node.data }))
        case 'type':
          return t(appointmentTypeValueGetter(params))
        case 'date':
          return dateTimeValueGetter({
            ...params,
            currentLanguage: currentLanguage,
            formatStr: 'P',
          })
        case 'accessReason':
          return t(appointmentAccessReasonValueGetter(params))
        default:
          return params.value
      }
    }
    const AppointmentsGridColumnDefs = [
      columnCheckbox,
      {
        field: 'contract.tableId',
        colId: 'contract',
        width: 100,
        filter: 'SimpleGridFilter',
        ...withHiddenParams,
      },
      {
        headerName: t('PROPERTY'),
        field: 'houseEntrance.tableId',
        colId: 'houseEntrance',
        rowGroup: true,
        cellRenderer: HouseEntranceIdRenderer,
        ...withHiddenParams,
      },
      {
        headerName: t('STATUS'),
        field: 'status',
        width: 100,
        filter: 'AppointmentStatusFilter',
        cellRenderer: AppointmentStatusRenderer,
        ...withHiddenParams,
      },
      {
        headerName: t('ALLOCATION NUMBER'),
        field: 'usageUnit.referenceNumber',
        width: 100,
        cellRenderer: AppointmentPropertyNumberRenderer,
        ...withFilterParams,
      },
      {
        headerName: t('APPOINTMENT ID'),
        field: 'tableId',
        width: 70,
        hide: true,
        cellRenderer: AppointmentIdRenderer,
        ...withFilterParams,
        type: 'rightAligned',
      },
      {
        headerName: t('SERVICE TYPE'),
        colId: 'contract.serviceCategory.tableId',
        field: 'contract',
        width: 80,
        filter: 'ContractServiceCategoryFilter',
        cellRenderer: ContractServiceCategoryRenderer,
        ...withFilterParams,
        sortable: false,
      },
      {
        headerName: t('APPOINTMENT TYPE'),
        field: 'type',
        width: 110,
        filter: 'AppointmentTypeFilter',
        cellRenderer: AppointmentTypeRenderer,
      },
      {
        headerName: t('DATE'),
        field: 'date',
        colId: 'date',
        width: 80,
        filter: 'agDateColumnFilter',
        ...withFilterParams,
        sort: 'desc',
        cellRenderer: DateRenderer,
      },
      {
        headerName: t('TIME FRAME'),
        field: 'timeframe',
        width: 100,
        ...withFilterParams,
      },
      {
        headerName: t('BULLETIN'),
        field: 'bulletin.tableId',
        width: 60,
        cellRenderer: AppointmentBulletinWithDownloadRenderer,
        type: 'rightAligned',
      },
      {
        headerName: t('ACCESS'),
        field: 'accessReason',
        width: 100,
        filter: 'AppointmentAccessReasonFilter',
        cellRenderer: AppointmentAccessRenderer,
        ...withFilterParams,
      },
    ]
    const AppointmentsGridCustomFilter = []
    return {
      query: AppointmentsGridQuery,
      columnDefs: AppointmentsGridColumnDefs,
      customFilters: AppointmentsGridCustomFilter,
      countQuery: CountAppointmentsQuery,
      groupingHandler: (keys = [], cols = [], params) => {
        const firstKey = keys[0]
        const rowGroupCols = cols.slice(0)
        rowGroupCols.push({
          displayName: '',
          field: 'date',
          id: 'date',
        })

        const data = params?.parentNode?.data
        if (data != null) {
          return {
            rowGroupCols,
            groupKeys: [firstKey, data?.date],
          }
        } else {
          return {
            rowGroupCols,
            groupKeys: keys,
          }
        }
      },
      extractor: (response) => response.Me?.Company?.data,
      customGridOptions: {
        getContextMenuItems: (params) => {
          return [
            {
              // custom item
              name: t('GO TO PROPERTY {PROPERTYID}', {
                PROPERTYID: params?.node?.data?.houseEntrance.tableId,
              }),
              action: function () {
                redirectRoute(
                  Routes.propertyUnit +
                    '/' +
                    params?.node?.data?.houseEntrance.tableId
                )
              },
            },
            'copy',
          ]
        },
        getRowHeight,
        components: {
          AppointmentStatusFilter,
          SimpleGridFilter,
          AppointmentTypeFilter,
          ContractServiceCategoryFilter,
          AppointmentAccessReasonFilter,
        },
        autoGroupColumnDef: {
          minWidth: 300,
          headerName: t('PROPERTY'),
        },
        getChildCount: (data) => data.count,
        processCellForClipboard: valueGetter,
        defaultCsvExportParams: {
          processCellCallback: valueGetter,
          skipColumns: ['contract', 'status', 'bulletin.tableId'],
        },
      },
      customVariables: {},
    }
  }, [currentLanguage, redirectRoute, t])
  return { getParams }
}
