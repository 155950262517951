function dummyTranslate(text) {
  return text
}

const invoiceTypeEnumList = (value) => {
  switch (value) {
    case 1:
    case 'DELIVERY_INVOICE':
      return dummyTranslate('DELIVERY INVOICE')

    case 2:
    case 'SERVICE_ACCOUNTING':
      return dummyTranslate('SERVICE INVOICE')

    case 3:
    case 'PARTIAL_INVOICE':
      return dummyTranslate('DISAGIO INVOICE')

    case 4:
    case 'INTERIM_BILLING':
      return dummyTranslate('INTERIM INVOICE')

    case 5:
    case 'FINAL_SETTLEMENT':
      return dummyTranslate('FINAL INVOICE')

    case 6:
    case 'CANCELLATION_INVOICE':
      return dummyTranslate('REVERSAL INVOICE')

    case 7:
    case 'PRO_FORMA_INVOICE':
      return dummyTranslate('REQUEST FOR PAYMENT / PROFORMA INVOICE')

    case 8:
    case 'RECEIPT':
      return dummyTranslate('RECEIPT')

    case 9:
    case 'STANDING_INVOICE':
      return dummyTranslate('STANDING INVOICE')

    case 10:
    case 'PAYMENT':
      return dummyTranslate('PAYMENT')

    case 'NONE':
    default:
      return ''
  }
}

export default invoiceTypeEnumList
