import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import {
  CountStatusHistoryQuery,
  HouseEntranceUsageUnitsGridQuery,
} from '../queries'
import { tenantFullNameValueGetter } from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../configs/RouteConfig'
import { TenantFullNameRenderer, UsageUnitLinkRenderer } from '../renderers'

export const useUsageUnitGrid = () => {
  const { redirectRoute } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { propertyUnitId } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'tenants':
            return tenantFullNameValueGetter(params)
          default:
            return params.value
        }
      }
      const HouseEntranceUsageUnitColumnDefs = [
        columnCheckbox,
        {
          headerName: t('PYREXX USAGE UNIT NUMBER'),
          field: 'tableId',
          width: 70,
          cellRenderer: UsageUnitLinkRenderer,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('CUSTOMERS OWN USAGE UNIT NUMBER'),
          field: 'referenceNumber',
          width: 70,
          cellRenderer: UsageUnitLinkRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 1 }),
          field: 'CAN.one',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 2 }),
          field: 'CAN.two',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 3 }),
          field: 'CAN.three',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 4 }),
          field: 'CAN.four',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('ALLOCATION NUMBER {count}', { count: 5 }),
          field: 'CAN.five',
          width: 100,
          hide: true,
          type: 'rightAligned',
          ...withFilterParams,
        },
        {
          headerName: t('FLOOR'),
          field: 'floor',
          width: 70,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('DESCRIPTION OF THE LOCATION'),
          field: 'position',
          width: 150,
          ...withFilterParams,
        },
        {
          headerName: t('RESIDENT'),
          field: 'tenants',
          width: 70,
          cellRenderer: TenantFullNameRenderer,
          ...withFilterParams,
        },
      ]
      const HouseEntranceUsageUnitCustomFilter = [
        {
          name: 'contract.serviceCategory.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [currentService.tableId],
              type: 'equals',
            },
          ],
        },
        {
          name: 'houseEntrance',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [propertyUnitId],
              type: 'equals',
            },
          ],
        },
      ]
      return {
        query: HouseEntranceUsageUnitsGridQuery,
        columnDefs: HouseEntranceUsageUnitColumnDefs,
        customFilters: HouseEntranceUsageUnitCustomFilter,
        countQuery: CountStatusHistoryQuery,
        countExtractor: (response) => response.Me?.Company?.HouseEntrance?.data,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          serverSideInfiniteScroll: false,
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO USAGE UNIT {REFERENCENUMBER}', {
                  REFERENCENUMBER: params?.node?.data?.referenceNumber,
                }),
                action: function () {
                  redirectRoute(
                    Routes.usageUnit + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
            ]
          },
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { propertyUnitId },
      }
    },
    [redirectRoute, t]
  )
  return { getParams }
}
