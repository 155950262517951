import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import {
  CountRenderedServicesQuery,
  RenderedServicesGridQuery,
} from '../queries'
import {
  appointmentAccessReasonValueGetter,
  appointmentStatusLabelValueGetter,
  appointmentTypeValueGetter,
  dateTimeValueGetter,
  tenantFullNameValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import {
  AppointmentAccessRenderer,
  AppointmentIdRenderer,
  AppointmentStatusRenderer,
  AppointmentTypeRenderer,
  TenantFullNameRenderer,
} from '../renderers'
import { DateRenderer } from '../../../components/AgGrid/renderers'
import {
  AppointmentAccessReasonFilter,
  AppointmentStatusFilter,
  AppointmentTypeFilter,
} from '../filters'

export const useRenderedServicesGrid = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { usageUnitId } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'appointment.status':
            return t(appointmentStatusLabelValueGetter(params))
          case 'appointment.accessReason':
            return t(appointmentAccessReasonValueGetter(params))
          case 'appointment.type':
            return t(appointmentTypeValueGetter(params))
          case 'appointment.tenant':
            return tenantFullNameValueGetter(params)
          case 'appointment.date':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'EP',
            })
          case 'executionTime':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'p',
            })
          case 'appointment.executionDate':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          default:
            return params.value
        }
      }
      const RenderedServicesColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          field: 'appointment.status',
          width: 80,
          filter: 'AppointmentStatusFilter',
          cellRenderer: AppointmentStatusRenderer,
        },
        {
          headerName: t('ACTION POSITION'),
          field: 'actionPosition.name',
          width: 80,
          ...withFilterParams,
        },
        {
          headerName: t('APPOINTMENT DATE'),
          field: 'appointment.date',
          width: 80,
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'EP',
          },
        },
        {
          headerName: t('TIMEFRAME'),
          field: 'appointment.timeframe',
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('PYREXX PERFORMANCE NUMBER'),
          field: 'appointment.tableId',
          width: 100,
          cellRenderer: AppointmentIdRenderer,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('CAUSE OF ORDER'),
          field: 'appointment.serviceReason.name',
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('INHABITANT'),
          field: 'appointment.tenant',
          width: 100,
          cellRenderer: TenantFullNameRenderer,
          filter: false, // TODO add Tenant filter from UsageUnits->registration->appointment->tenant
        },
        {
          headerName: t('APPOINTMENT TYPE'),
          field: 'appointment.type',
          width: 100,
          filter: 'AppointmentTypeFilter',
          cellRenderer: AppointmentTypeRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('ACCESS'),
          field: 'appointment.accessReason',
          width: 100,
          filter: 'AppointmentAccessReasonFilter',
          cellRenderer: AppointmentAccessRenderer,
        },
        {
          headerName: t('EXECUTED DATE'),
          field: 'appointment.executionDate',
          width: 70,
          filter: 'agDateColumnFilter',
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'P',
          },
          ...withFilterParams,
        },
        {
          headerName: t('EXECUTED TIME'),
          colId: 'appointment.executionTime', // TODO duplicate ids, filtering and sorting is unavailable
          field: 'appointment.executionDate',
          width: 70,
          sortable: false,
          filter: false,
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'p',
          },
        },
      ]
      const RenderedServicesCustomFilter = []
      return {
        query: RenderedServicesGridQuery,
        columnDefs: RenderedServicesColumnDefs,
        customFilters: RenderedServicesCustomFilter,
        countQuery: CountRenderedServicesQuery,
        extractor: (response) => response.Me?.Company?.UsageUnit?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return ['copy']
          },
          components: {
            AppointmentStatusFilter,
            AppointmentTypeFilter,
            AppointmentAccessReasonFilter,
          },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { usageUnitId },
      }
    },
    [currentLanguage, t]
  )
  return { getParams }
}
