import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { positionRight } from '@reach/popover'

import { Icon, portalTheme } from 'pyrexx-react-library'
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuLink,
  MenuPopover,
} from './HelloMenu.styles'
import UserSalutation from '../Layout/Header/UserSalutation'

import { Links } from '../../configs/LinkConfig'
import { Routes } from '../../configs/RouteConfig'
import handleLocalStorage from '../../helper/handleLocalStorage'

const HelloMenu = ({
  user,
  isAdmin = false,
  hasMultipleCompanies = false,
  currentLanguage = 'de',
  logout = () => {},
}) => {
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <Menu>
      {({ isExpanded }) => (
        <>
          <MenuButton>
            <>
              <UserSalutation user={user} currentLanguage={currentLanguage} />

              {isExpanded ? (
                <Icon
                  icon='arrow-up'
                  size='30px'
                  color={portalTheme.color.linkColorPrimary}
                />
              ) : (
                <Icon
                  icon='arrow-down'
                  size='30px'
                  color={portalTheme.color.linkColorPrimary}
                />
              )}
            </>
          </MenuButton>

          <MenuPopover position={positionRight}>
            <MenuItems>
              <MenuLink as='a' href={Links.pcpPage}>
                <Icon icon='versionchange' size='20px' aria-hidden />
                {t('GO BACK TO CURRENT PCP')}
              </MenuLink>

              {hasMultipleCompanies && (
                <MenuLink
                  as={Link}
                  to={{
                    pathname: '/' + currentLanguage + Routes.companySwitcher,
                    state: { from: location },
                  }}
                  onSelect={() =>
                    handleLocalStorage('set', 'companySwitcherViaLogin', false)
                  }
                >
                  <Icon icon='companyswitch' size='20px' aria-hidden />

                  {t('SWITCH COMPANY')}
                </MenuLink>
              )}

              {/* BETA */}
              {isAdmin && (
                <MenuLink as={Link} to={'/' + currentLanguage + Routes.admin}>
                  <Icon icon='admin' size='20px' aria-hidden />
                  {t('ADMIN')}
                </MenuLink>
              )}

              <MenuLink as={Link} to={'/' + currentLanguage + Routes.user}>
                <Icon icon='my-account' size='20px' aria-hidden />
                {t('MY ACCOUNT')}
              </MenuLink>

              <MenuLink
                as={Link}
                to={'/' + currentLanguage + Routes.login}
                onSelect={logout}
              >
                <Icon icon='logout' size='20px' aria-hidden />
                {t('LOGOUT')}
              </MenuLink>
            </MenuItems>
          </MenuPopover>
        </>
      )}
    </Menu>
  )
}

HelloMenu.propTypes = {
  user: PropTypes.shape({
    salutation: PropTypes.string,
    title: PropTypes.string,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
  }).isRequired,
  isAdmin: PropTypes.bool,
  hasMultipleCompanies: PropTypes.bool,
  currentLanguage: PropTypes.string,
  logout: PropTypes.func,
}

export default HelloMenu
