/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractsCardQueryVariables = {|
  companyId: string
|};
export type ContractsCardQueryResponse = {|
  +Me: ?{|
    +contractsStatus: ?{|
      +completed: ?number,
      +planned: ?number,
      +incompleted: ?number,
    |}
  |}
|};
export type ContractsCardQuery = {|
  variables: ContractsCardQueryVariables,
  response: ContractsCardQueryResponse,
|};
*/


/*
query ContractsCardQuery(
  $companyId: ID!
) {
  Me {
    contractsStatus: Company(id: $companyId) {
      completed: countContracts(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["DONE_BUT_NOT_FINAL_BILLED", "FINAL_BILLED_NOT_EXPIRED_NO_MAINTENANCE"], type: equals}]}]})
      planned: countContracts(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["ACTIVE", "UNDER_CONSTRUCTION"], type: equals}]}]})
      incompleted: countContracts(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["DISCARDED", "READY_FOR_ACTIVATION", "TEMP_SUSPENDED", "EXPIRED_AND_FINAL_BILLED", "MERGED_INTO_OTHER_CONTRACT"], type: equals}]}]})
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": "completed",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "DONE_BUT_NOT_FINAL_BILLED",
                  "FINAL_BILLED_NOT_EXPIRED_NO_MAINTENANCE"
                ]
              }
            ],
            "filterType": "text",
            "name": "status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "countContracts",
  "storageKey": "countContracts(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"DONE_BUT_NOT_FINAL_BILLED\",\"FINAL_BILLED_NOT_EXPIRED_NO_MAINTENANCE\"]}],\"filterType\":\"text\",\"name\":\"status\",\"operator\":\"AND\"}]})"
},
v3 = {
  "alias": "planned",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "ACTIVE",
                  "UNDER_CONSTRUCTION"
                ]
              }
            ],
            "filterType": "text",
            "name": "status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "countContracts",
  "storageKey": "countContracts(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"ACTIVE\",\"UNDER_CONSTRUCTION\"]}],\"filterType\":\"text\",\"name\":\"status\",\"operator\":\"AND\"}]})"
},
v4 = {
  "alias": "incompleted",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "DISCARDED",
                  "READY_FOR_ACTIVATION",
                  "TEMP_SUSPENDED",
                  "EXPIRED_AND_FINAL_BILLED",
                  "MERGED_INTO_OTHER_CONTRACT"
                ]
              }
            ],
            "filterType": "text",
            "name": "status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "countContracts",
  "storageKey": "countContracts(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"DISCARDED\",\"READY_FOR_ACTIVATION\",\"TEMP_SUSPENDED\",\"EXPIRED_AND_FINAL_BILLED\",\"MERGED_INTO_OTHER_CONTRACT\"]}],\"filterType\":\"text\",\"name\":\"status\",\"operator\":\"AND\"}]})"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContractsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "contractsStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContractsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "contractsStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e2b4f754b8177235db9bf8f75415b73",
    "id": null,
    "metadata": {},
    "name": "ContractsCardQuery",
    "operationKind": "query",
    "text": "query ContractsCardQuery(\n  $companyId: ID!\n) {\n  Me {\n    contractsStatus: Company(id: $companyId) {\n      completed: countContracts(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"DONE_BUT_NOT_FINAL_BILLED\", \"FINAL_BILLED_NOT_EXPIRED_NO_MAINTENANCE\"], type: equals}]}]})\n      planned: countContracts(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"ACTIVE\", \"UNDER_CONSTRUCTION\"], type: equals}]}]})\n      incompleted: countContracts(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"DISCARDED\", \"READY_FOR_ACTIVATION\", \"TEMP_SUSPENDED\", \"EXPIRED_AND_FINAL_BILLED\", \"MERGED_INTO_OTHER_CONTRACT\"], type: equals}]}]})\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b41f3ab1a1faed4ffc445cadb452b77b';

module.exports = node;
