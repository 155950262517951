import { fetchQuery } from 'react-relay'
import environment from '../environments/pom/Environment'
import CompanyDataQuery from './CompanyData'

export default async (companyId = '', accessToken = '') => {
  const variables = {
    companyId,
  }

  try {
    return await fetchQuery(
      environment(accessToken),
      CompanyDataQuery,
      variables
    )
  } catch (e) {
    console.log(e)
    return {}
  }
}
