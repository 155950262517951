// permissions available
// ADMIN
// INVOICE
// CONTRACT
// INVENTORY
// QUOTA
// APPOINTMENT
// DATA_UPDATE

export const APPOINTMENTS_PERMISSIONS = ['CONTRACT', 'APPOINTMENT']
export const CONTRACTS_PERMISSIONS = ['CONTRACT']
export const INVOICES_PERMISSIONS = ['CONTRACT', 'INVOICE']
export const PROPERTY_MANAGEMENT_PERMISSIONS = []
export const BUSINESS_MAIL_PERMISSIONS = ['POST_LETTER']
