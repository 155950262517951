import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation UploadCompanyLogoMutation($companyId: ID!, $file: Upload!) {
    Me {
      Company(id: $companyId) {
        uploadLogo(file: $file)
      }
    }
  }
`

export default (variables = {}, file, accessToken, onCompleted, onError) => {
  let uploadables

  if (file) {
    uploadables = {
      file,
    }
  }

  commitMutation(environment(accessToken), {
    mutation,
    variables,
    uploadables,
    onCompleted,
    onError,
  })
}
