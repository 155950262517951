import React, { useContext } from 'react'

import SimpleLink from '../../../components/Simple/SimpleLink'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'
import { useLocation } from 'react-router-dom'

const AppointmentIdRenderer = (props) => {
  const { value, data } = props
  const { currentLanguage } = useContext(UserContext)
  const location = useLocation()

  return (
    <SimpleLink
      to={{
        pathname:
          '/' + currentLanguage + Routes.appointment + '/' + data.tableId,
        state: {
          from: location.pathname,
        },
      }}
      style={{ fontSize: 'inherit' }}
    >
      {value}
    </SimpleLink>
  )
}

export default AppointmentIdRenderer
