/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SyncEquipTimeOrderType = "AS_SOON_AS_POSSIBLE_INCLUSIVE_ADDITIONAL_COST" | "EQUIP_WITHIN_REGULAR_PYREXX_INTERVALS" | "NONE" | "%future added value";
export type SyncFileType = "API_TENANT_UPDATE" | "COMPANY_LOGO" | "DEMOLITION_NOTIFICATION" | "DISPOSAL_NOTIFICATION" | "DRINKWATER_DATA" | "DRINKWATER_ORDER_NO_DATA" | "FULL_UPDATE" | "INCREMENTAL_UPDATE" | "MISC" | "PLEASE_SELECT" | "PROPERTIES" | "%future added value";
export type UploadSyncFileMutationVariables = {|
  companyId: string,
  type: SyncFileType,
  contract: number,
  date: any,
  phoneNumber: string,
  uploader: string,
  outfittingPriority: SyncEquipTimeOrderType,
  email: string,
  note?: ?string,
  files: $ReadOnlyArray<any>,
|};
export type UploadSyncFileMutationResponse = {|
  +status: ?{|
    +Company: ?{|
      +uploadSyncFile: ?boolean
    |}
  |}
|};
export type UploadSyncFileMutation = {|
  variables: UploadSyncFileMutationVariables,
  response: UploadSyncFileMutationResponse,
|};
*/


/*
mutation UploadSyncFileMutation(
  $companyId: ID!
  $type: SyncFileType!
  $contract: Int!
  $date: DateTime!
  $phoneNumber: String!
  $uploader: String!
  $outfittingPriority: SyncEquipTimeOrderType!
  $email: String!
  $note: String
  $files: [Upload!]!
) {
  status: Me {
    Company(id: $companyId) {
      uploadSyncFile(type: $type, contract: $contract, date: $date, uploader: $uploader, equipTimeOrder: $outfittingPriority, phoneNumber: $phoneNumber, email: $email, note: $note, file: $files)
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contract"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "date"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "files"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "note"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "outfittingPriority"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phoneNumber"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "uploader"
},
v10 = [
  {
    "alias": "status",
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "contract",
                "variableName": "contract"
              },
              {
                "kind": "Variable",
                "name": "date",
                "variableName": "date"
              },
              {
                "kind": "Variable",
                "name": "email",
                "variableName": "email"
              },
              {
                "kind": "Variable",
                "name": "equipTimeOrder",
                "variableName": "outfittingPriority"
              },
              {
                "kind": "Variable",
                "name": "file",
                "variableName": "files"
              },
              {
                "kind": "Variable",
                "name": "note",
                "variableName": "note"
              },
              {
                "kind": "Variable",
                "name": "phoneNumber",
                "variableName": "phoneNumber"
              },
              {
                "kind": "Variable",
                "name": "type",
                "variableName": "type"
              },
              {
                "kind": "Variable",
                "name": "uploader",
                "variableName": "uploader"
              }
            ],
            "kind": "ScalarField",
            "name": "uploadSyncFile",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadSyncFileMutation",
    "selections": (v10/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v8/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v7/*: any*/),
      (v9/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadSyncFileMutation",
    "selections": (v10/*: any*/)
  },
  "params": {
    "cacheID": "8574b22ba5f8f1ec8fb799d81e68cec7",
    "id": null,
    "metadata": {},
    "name": "UploadSyncFileMutation",
    "operationKind": "mutation",
    "text": "mutation UploadSyncFileMutation(\n  $companyId: ID!\n  $type: SyncFileType!\n  $contract: Int!\n  $date: DateTime!\n  $phoneNumber: String!\n  $uploader: String!\n  $outfittingPriority: SyncEquipTimeOrderType!\n  $email: String!\n  $note: String\n  $files: [Upload!]!\n) {\n  status: Me {\n    Company(id: $companyId) {\n      uploadSyncFile(type: $type, contract: $contract, date: $date, uploader: $uploader, equipTimeOrder: $outfittingPriority, phoneNumber: $phoneNumber, email: $email, note: $note, file: $files)\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7c18150a8907cc768bc858c80e16d103';

module.exports = node;
