/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddFilterMutationVariables = {|
  grid: string,
  filter: string,
  settings: any,
  companyId: string,
|};
export type AddFilterMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +createGridFilter: ?{|
        +id: string,
        +tableId: number,
        +grid: string,
        +filter: string,
        +settings: any,
      |}
    |}
  |}
|};
export type AddFilterMutation = {|
  variables: AddFilterMutationVariables,
  response: AddFilterMutationResponse,
|};
*/


/*
mutation AddFilterMutation(
  $grid: String!
  $filter: String!
  $settings: JSON!
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      createGridFilter(grid: $grid, filter: $filter, settings: $settings) {
        id
        tableId
        grid
        filter
        settings
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "grid"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "settings"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "filter",
                "variableName": "filter"
              },
              {
                "kind": "Variable",
                "name": "grid",
                "variableName": "grid"
              },
              {
                "kind": "Variable",
                "name": "settings",
                "variableName": "settings"
              }
            ],
            "concreteType": "GridFilter",
            "kind": "LinkedField",
            "name": "createGridFilter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tableId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "grid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "filter",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "settings",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddFilterMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddFilterMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "4a4b2efb92990cae8a1f54689b99fb26",
    "id": null,
    "metadata": {},
    "name": "AddFilterMutation",
    "operationKind": "mutation",
    "text": "mutation AddFilterMutation(\n  $grid: String!\n  $filter: String!\n  $settings: JSON!\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      createGridFilter(grid: $grid, filter: $filter, settings: $settings) {\n        id\n        tableId\n        grid\n        filter\n        settings\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f1d1716d78b531a57b03cee1ab3f779c';

module.exports = node;
