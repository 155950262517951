/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PropertyUnitContainerQueryVariables = {|
  companyId: string,
  propertyUnitId: string,
|};
export type PropertyUnitContainerQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +Contracts: ?$ReadOnlyArray<?{|
        +serviceCategory: ?{|
          +id: string,
          +tableId: number,
          +name: string,
        |}
      |}>,
      +HouseEntrance: ?{|
        +id: string,
        +tableId: number,
        +number: string,
        +address: ?{|
          +street: string,
          +zip: string,
          +city: string,
          +number: string,
        |},
        +coordinates: ?{|
          +lat: number,
          +lng: number,
        |},
        +countUsageUnits: number,
      |},
    |}
  |}
|};
export type PropertyUnitContainerQuery = {|
  variables: PropertyUnitContainerQueryVariables,
  response: PropertyUnitContainerQueryResponse,
|};
*/


/*
query PropertyUnitContainerQuery(
  $companyId: ID!
  $propertyUnitId: ID!
) {
  Me {
    Company(id: $companyId) {
      Contracts(options: {endRow: -1}) {
        serviceCategory {
          id
          tableId
          name
        }
        id
      }
      HouseEntrance(id: $propertyUnitId) {
        id
        tableId
        number
        address {
          street
          zip
          city
          number
        }
        coordinates {
          lat
          lng
        }
        countUsageUnits
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "propertyUnitId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "kind": "Literal",
    "name": "options",
    "value": {
      "endRow": -1
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ServiceCategory",
  "kind": "LinkedField",
  "name": "serviceCategory",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "propertyUnitId"
    }
  ],
  "concreteType": "HouseEntrance",
  "kind": "LinkedField",
  "name": "HouseEntrance",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zip",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Coordinates",
      "kind": "LinkedField",
      "name": "coordinates",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lng",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countUsageUnits",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyUnitContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "Contracts",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": "Contracts(options:{\"endRow\":-1})"
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropertyUnitContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "Contracts",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": "Contracts(options:{\"endRow\":-1})"
              },
              (v7/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5aaae0295eab78df6ac4ba57cccb805b",
    "id": null,
    "metadata": {},
    "name": "PropertyUnitContainerQuery",
    "operationKind": "query",
    "text": "query PropertyUnitContainerQuery(\n  $companyId: ID!\n  $propertyUnitId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      Contracts(options: {endRow: -1}) {\n        serviceCategory {\n          id\n          tableId\n          name\n        }\n        id\n      }\n      HouseEntrance(id: $propertyUnitId) {\n        id\n        tableId\n        number\n        address {\n          street\n          zip\n          city\n          number\n        }\n        coordinates {\n          lat\n          lng\n        }\n        countUsageUnits\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b446c726cc504c4253f525e91222d47';

module.exports = node;
