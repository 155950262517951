import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import handleLocalStorage from '../../../../helper/handleLocalStorage'
import ActionCenterContainer from '../../../ActionCenter'
import ArrowDropdown from '../../../ArrowDropdown'
import Pills from '../../../Pills'
import Pill from '../../../Pill'
import { useTranslation } from 'react-i18next'
import UserContext from '../../../../helper/userContext'
import ServerSideDatasource from '../../../../environments/api/serverSideDataSource'
import defaultGridOptions from '../../defaultGridOptions'
import { agGridLocales } from '../../locales'
import AgGridUltra from '../AgGridUltra'
import LoadingBox from '../../../LoadingSpinner/LoadingBox'
import Tab from '../../../Tab'
import * as TabStyles from '../../../Tabs/Tabs.styles'
import { statusBarParams } from '../../columnDefs'
import { ButtonIcon } from 'pyrexx-react-library'
import { useLocation } from 'react-router-dom'
import { fetchQuery } from 'react-relay'
import environment from '../../../../environments/pom/Environment'
import GetFilters from '../components/OwnFilters/queries/GridFilters'
import stringToHash from '../../../../helper/stringToHash'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

export const useAgGridUltra = (
  routeName,
  grids,
  initFilterVariables = {},
  minimalVersion = false,
  boxedVersion = false,
  rowCountHeight = false,
  noFilterMenu = false,
  noActionDropDown = false
) => {
  const query = useQuery()
  // const tab = query.get('tab')
  const gridIdOverUrl = query.get('gridId')
  const customFilterOverUrl = query.get('filterId')
  const { t } = useTranslation()
  const ref = useRef({
    quickSave1: null,
    quickSave2: null,
    quickSave3: null,
    quickSave4: null,
    timeoutHandleSelectionChanged: false,
  })
  const actionCenterHeight = 64
  const originName = routeName + 'Origin'
  const predefinedGridId = handleLocalStorage('get', originName)
  let initialGrid =
    grids.find((grid) => grid.id === predefinedGridId) || grids[0]
  if (gridIdOverUrl) {
    initialGrid = grids.find((grid) => grid.id === gridIdOverUrl) || grids[0]
  }
  const [firstLoad, setFirstLoad] = useState(true)
  const [currentService, setCurrentService] = useState(false)
  const [currentGrid, setCurrentGrid] = useState(initialGrid)
  const [onGridChangedDeps, setOnGridChangedDeps] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [filterModel, setFilterModel] = useState({})
  const [filterVariables, setFilterVariables] = useState(initFilterVariables)
  const [forceRefresh, setForceRefresh] = useState(false)

  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)

  const [gridStateName, setGridStateName] = useState(false)
  const [gridState, setGridState] = useState(false)
  const [
    loadedGridStateFromLocaleStorage,
    setLoadedGridStateFromLocaleStorage,
  ] = useState(false)
  const [filterOptions, setFilterOptions] = useState(false)

  const [currentFilter, setCurrentFilter] = useState(false)
  const [ownFilterChanged, setOwnFilterChanged] = useState(false)
  const [loadingFilter, setLoadingFilter] = useState(true)
  const [componentProps, setComponentProps] = useState(false)

  const gridFunctions = {
    setForceRefresh,
    setFilterModel,
    setIsFullScreen,
    setGridState,
  }

  // const localStorageSaveGridState = useCallback(() => {
  //   if (
  //     gridState &&
  //     currentGrid?.id &&
  //     gridStateName &&
  //     !currentGrid?.component
  //   ) {
  //     handleLocalStorage('set', gridStateName, gridState)
  //   }
  // }, [currentGrid, gridState, gridStateName])

  useEffect(() => {
    if (
      gridApi &&
      gridColumnApi &&
      currentGrid &&
      currentGrid?.id &&
      !currentGrid?.component &&
      !loadedGridStateFromLocaleStorage &&
      !firstLoad
    ) {
      let loadedGridState
      if (
        customFilterOverUrl &&
        filterOptions.find(
          (item) => parseInt(item.tableId) === parseInt(customFilterOverUrl)
        )
      ) {
        loadedGridState = filterOptions.find(
          (item) => parseInt(item.tableId) === parseInt(customFilterOverUrl)
        )

        setLoadedGridStateFromLocaleStorage(loadedGridState)
        setGridState(loadedGridState)
        setCurrentFilter(loadedGridState)
      } else {
        loadedGridState = handleLocalStorage('get', gridStateName, null, {})
        setLoadedGridStateFromLocaleStorage(loadedGridState)
        setGridState(loadedGridState)

        if (loadedGridState?.state) {
          gridColumnApi.applyColumnState({
            state: loadedGridState.state,
            applyOrder: true,
          })
        }
        if (loadedGridState?.filter) {
          gridApi.setFilterModel(loadedGridState?.filter)
        }
      }
    }
  }, [
    currentGrid,
    customFilterOverUrl,
    filterOptions,
    firstLoad,
    gridApi,
    gridColumnApi,
    gridStateName,
    loadedGridStateFromLocaleStorage,
  ])

  useEffect(() => {
    if (currentGrid && currentGrid?.id && !currentGrid?.component) {
      setGridStateName(stringToHash(originName + 'gridState' + currentGrid?.id))
    }
  }, [currentGrid, originName])

  // useEffect(() => {
  //   if (
  //     gridState &&
  //     currentGrid &&
  //     currentGrid?.id &&
  //     gridStateName &&
  //     !currentGrid?.component
  //   ) {
  //     localStorageSaveGridState()
  //   }
  // }, [gridState, currentGrid, gridStateName, localStorageSaveGridState])
  //
  useEffect(() => {
    return () => {
      handleLocalStorage('clear', originName)
    }
  }, [originName])

  const onGridReady = (params) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  useEffect(() => {
    if (gridApi) {
      gridApi.setFilterModel(filterModel)
    }
  }, [filterModel, gridApi])

  const { user, replaceSearchQuery } = useContext(UserContext)

  const getFiltersData = useCallback(
    (filterId = false) => {
      setLoadingFilter(true)
      return fetchQuery(
        environment(user.accessToken),
        GetFilters,
        {
          companyId: user.companyId,
          startRow: 0,
          endRow: 100,
          sortModel: [],
          filters: [
            {
              name: 'grid',
              filterType: 'text',
              operator: 'AND',
              conditions: [
                {
                  value: gridStateName,
                  type: 'equals',
                },
              ],
            },
          ],
        },
        {
          force: true,
        }
      )
        .then((data) => {
          const results = data.Me?.Company?.GridFilters
          setFilterOptions(results)
          if (filterId) {
            const tempCurrentFilter = results.filter(
              (filterItem) => filterItem.tableId === filterId
            )
            if (tempCurrentFilter.length > 0) {
              setCurrentFilter(tempCurrentFilter[0])
            }
          }
          if (firstLoad) {
            setFirstLoad(false)
          }
          setLoadingFilter(false)
        })
        .catch((e) => {
          console.log('OWNFILTER: getFiltersData ERROR CATCH', e)
        })
    },
    [firstLoad, gridStateName, user.accessToken, user.companyId]
  )

  useEffect(() => {
    if (gridStateName) {
      getFiltersData()
    }
  }, [firstLoad, getFiltersData, gridStateName])

  const getServerSideDatasource = useCallback(
    ({
      query,
      customFilters,
      extractor,
      countQuery,
      countExtractor,
      customVariables,
      groupingHandler,
      refresh = false,
    }) => {
      const serverSource = new ServerSideDatasource(
        query,
        extractor,
        user,
        customVariables,
        customFilters,
        countQuery,
        countExtractor,
        groupingHandler,
        refresh
      )
      return serverSource
    },
    [user]
  )

  const showSelectAllCheckbox = useCallback((params) => {
    // i used >= because the getDisplayedRowCount function count somtimes one more item i dont know why :/
    if (
      params.api.getSelectedRows().length >=
        params.api.getDisplayedRowCount() - 1 &&
      params.api.getSelectedRows().length > 0
    ) {
      return (
        <ButtonIcon
          icon='checkbox-checked'
          size='20px'
          style={{ color: '#89A8C8' }}
          onClick={() => {
            params.api.deselectAll()
          }}
        />
      )
    } else {
      return (
        <ButtonIcon
          icon='checkbox-unchecked'
          size='20px'
          style={{ color: '#89A8C8' }}
          onClick={() => {
            params.api.forEachNode((node) => {
              node.setSelected(true)
            })
          }}
        />
      )
    }
  }, [])

  const getGridOption = useCallback(() => {
    const { getParams } = currentGrid.useGetParams
    const { columnDefs, customGridOptions = {} } = getParams(
      currentService,
      filterVariables,
      gridFunctions
    )
    const serverSideDatasource = getServerSideDatasource({
      refresh: forceRefresh,
      ...getParams(currentService, filterVariables, gridFunctions),
    })

    let filteredCheckboxColumnDef = columnDefs.filter(
      (item) => item.field !== 'checkbox'
    )
    if (columnDefs.find((item) => item.field === 'checkbox')) {
      const checkbox = {
        headerName: '',
        field: 'checkbox',
        checkboxSelection: true,
        width: 50,
        sortable: false,
        menuTabs: [],
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        headerComponent: showSelectAllCheckbox,
      }
      filteredCheckboxColumnDef = [checkbox, ...filteredCheckboxColumnDef]
    }

    return {
      ...defaultGridOptions,
      serverSideDatasource,
      columnDefs: filteredCheckboxColumnDef,
      ...customGridOptions,
    }
  }, [
    currentGrid.useGetParams,
    currentService,
    filterVariables,
    forceRefresh,
    getServerSideDatasource,
    gridFunctions,
    showSelectAllCheckbox,
  ])

  const createGridOption = useCallback(() => {
    // console.log('createGridOption')
    const gridOptions = getGridOption()
    return {
      ...gridOptions,
      getRowId: (params) => {
        return params.data.id
      },
      getLocaleText: (params) => {
        return t(agGridLocales[params.key])
      },
      ...statusBarParams,
    }
  }, [getGridOption, t])

  useEffect(() => {
    if (
      gridApi &&
      currentGrid &&
      onGridChangedDeps &&
      !currentGrid?.component
    ) {
      const { getParams } = currentGrid.useGetParams

      gridApi.deselectAll() // Clears selection after service or filter change
      gridApi.setServerSideDatasource(
        getServerSideDatasource({
          refresh: forceRefresh,
          ...getParams(currentService, filterVariables, gridFunctions),
        })
      )

      gridApi.refreshCells({ force: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGridChangedDeps])

  useEffect(() => {
    if (currentGrid?.onChange) {
      currentGrid.onChange()
    }
  }, [currentGrid])

  useEffect(() => {
    if (gridApi && currentGrid && currentService && !currentGrid?.component) {
      const { getParams } = currentGrid.useGetParams

      gridApi.deselectAll() // Clears selection after service or filter change
      gridApi.setServerSideDatasource(
        getServerSideDatasource({
          refresh: forceRefresh,
          ...getParams(currentService, filterVariables, gridFunctions),
        })
      )

      gridApi.refreshCells({ force: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentService])

  useEffect(() => {
    if (gridApi && currentGrid && forceRefresh && !currentGrid?.component) {
      const { getParams } = currentGrid.useGetParams

      gridApi.deselectAll() // Clears selection after service or filter change
      gridApi.setServerSideDatasource(
        getServerSideDatasource({
          refresh: forceRefresh,
          ...getParams(currentService, filterVariables, gridFunctions),
        })
      )

      gridApi.refreshCells({ force: true })
      setForceRefresh(false)
    }
    setForceRefresh(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefresh])

  // Handle rows selection
  const [selectedRows, setSelectedRows] = useState([])

  const handleSelectionChanged = useCallback((rows, params = false) => {
    clearTimeout(ref.current.timeoutHandleSelectionChanged)
    ref.current.timeoutHandleSelectionChanged = setTimeout(() => {
      setSelectedRows(rows)
      if (params) {
        params.api.refreshHeader()
      }
    }, 50)
  }, [])

  const generateServiceDropDown = useCallback(
    (services) => {
      return (
        <ArrowDropdown
          label={currentService?.label}
          dropdownItems={services}
          selected={currentService}
          setSelected={setCurrentService}
          description={t('SERVICES')}
        />
      )
    },
    [currentService, t]
  )

  const generatePills = useCallback(() => {
    return (
      <Pills>
        {grids.map((grid) => (
          <Pill
            key={grid.id}
            isActive={grid.id === currentGrid.id}
            disabled={grid?.disabled}
            onClick={() => {
              setGridApi(null)
              setCurrentFilter(false)
              // setFirstLoad(true)
              setSelectedRows([])
              setCurrentGrid(grid)
              replaceSearchQuery('?gridId=' + grid.id)
            }}
          >
            {grid.label}
          </Pill>
        ))}
      </Pills>
    )
  }, [currentGrid.id, grids, replaceSearchQuery])

  const generateTabs = useCallback(
    (props = {}) => {
      const { tabsStyle = {}, borderBottomStyle = '', description = '' } = props
      return (
        <TabStyles.Wrapper>
          <TabStyles.TabsRow
            style={tabsStyle}
            borderBottomStyle={borderBottomStyle}
          >
            {description && (
              <TabStyles.TabsDescription>
                {description}
              </TabStyles.TabsDescription>
            )}

            <TabStyles.TabsNavigator>
              {grids.map((grid) => (
                <Tab
                  key={grid.id}
                  isSelected={grid.id === currentGrid.id}
                  onClick={() => {
                    setGridApi(null)
                    setCurrentFilter(false)
                    // setFirstLoad(true)
                    setSelectedRows([])
                    setCurrentGrid(grid)
                    replaceSearchQuery('?gridId=' + grid.id)
                  }}
                  label={grid.label}
                />
              ))}
            </TabStyles.TabsNavigator>
          </TabStyles.TabsRow>
        </TabStyles.Wrapper>
      )
    },
    [currentGrid.id, grids, replaceSearchQuery]
  )

  const generateActionCenter = useCallback(
    (
      minimalVersion = false,
      boxedVersion = false,
      noFilterMenu = false,
      noActionDropDown = false
    ) => {
      const actionCenterProps = {
        minimalVersion,
        boxedVersion,
        noFilterMenu,
        noActionDropDown,
        gridApi,
        gridColumnApi,
        selectedRows,
        setForceRefresh,
        isFullScreen,
        setIsFullScreen,
        filterModel,
        setFilterModel,
        actionCenterHeight,
        gridName: currentGrid.id,
        gridStateName,
        gridState,
        filterOptions,
        setFilterOptions,
        currentFilter,
        setCurrentFilter,
        ownFilterChanged,
        setOwnFilterChanged,
        getFiltersData,
        currentGrid,
        loadingFilter,
        setLoadingFilter,
        fileDownloadName: currentGrid.downloadFileName || currentGrid.label,
        dropDownItems:
          currentGrid.customActionCenterItems &&
          currentGrid.customActionCenterItems,
        extraItems: currentGrid.extraItems && currentGrid.extraItems,
      }

      return (
        <ActionCenterContainer
          title={currentGrid.label}
          {...actionCenterProps}
        />
      )
    },
    [
      gridApi,
      gridColumnApi,
      selectedRows,
      isFullScreen,
      filterModel,
      currentGrid,
      gridStateName,
      gridState,
      filterOptions,
      currentFilter,
      ownFilterChanged,
      getFiltersData,
      loadingFilter,
    ]
  )

  const generateGrid = useCallback(
    (loading = false) => {
      // console.log('generate Grid loading=', loading)
      if (ref.current.quickSave1 !== createGridOption) {
        ref.current.quickSave1 = createGridOption
        // console.log('changed createGridOption')
      }
      if (ref.current.quickSave2 !== currentGrid.id) {
        ref.current.quickSave2 = currentGrid.id
        // console.log('changed currentGrid.id')
      }
      if (ref.current.quickSave3 !== loading) {
        ref.current.quickSave3 = loading
        // console.log('changed loading')
      }
      if (ref.current.quickSave4 !== generateActionCenter) {
        ref.current.quickSave4 = generateActionCenter
        // console.log('changed generateActionCenter')
      }
      if (currentGrid?.component) {
        return currentGrid?.component(componentProps)
      }
      const gridProps = {
        handleSelectionChanged,
        onGridReady,
        createGridOption,
        minimalVersion,
        boxedVersion,
        generateActionCenter,
        isFullScreen,
        actionCenterHeight,
        setFilterModel,
        filterModel,
        setGridState,
        setOwnFilterChanged,
        currentFilter,
        rowCountHeight,
        noFilterMenu,
        noActionDropDown,
      }
      return !loading && !firstLoad ? (
        <AgGridUltra
          key={'Ultra' + currentGrid.id}
          id={currentGrid.id}
          {...gridProps}
        />
      ) : (
        <LoadingBox spinner />
      )
    },
    [
      boxedVersion,
      componentProps,
      createGridOption,
      currentFilter,
      currentGrid,
      filterModel,
      firstLoad,
      generateActionCenter,
      handleSelectionChanged,
      isFullScreen,
      minimalVersion,
      rowCountHeight,
      noFilterMenu,
      noActionDropDown,
    ]
  )

  return {
    generateServiceDropDown,
    generatePills,
    generateActionCenter,
    currentService,
    setCurrentService,
    currentGrid,
    gridApi,
    handleSelectionChanged,
    onGridReady,
    getGridOption,
    setOnGridChangedDeps,
    createGridOption,
    generateGrid,
    setFilterModel,
    generateTabs,
    setFilterVariables,
    filterModel,
    filterVariables,
    selectedRows,
    setSelectedRows,
    setForceRefresh,
    setComponentProps,
  }
}
