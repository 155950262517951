import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query PropertyUnitInventoryGridQuery(
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
    $propertyUnitId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        HouseEntrance(id: $propertyUnitId) {
          id
          tableId
          data: usageUnits {
            id
            tableId
            referenceNumber
            floor
            position
            tenants {
              id
              tableId
              firstname
              lastname
            }
            inventory(options: { sorting: $sortModel, filters: $filters }) {
              id
              position {
                id
                room {
                  id
                  type
                }
              }
              product {
                id
                name
              }
              quantity
              installationDate
            }
          }
        }
      }
    }
  }
`
