const houseEntranceIdValueGetter = (props) => {
  const { data } = props
  const address = [
    data?.address?.street ?? '',
    data?.address?.number ?? '',
    ', ',
    data?.address?.zip ?? '',
    data?.address?.city ?? '',
  ]
  return address.join(' ')
}

export default houseEntranceIdValueGetter
