import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonSecondary } from 'pyrexx-react-library'

const QuotaButtonRenderer = (props) => {
  const { t } = useTranslation()
  const { setModal, setAppointmentId, triggerQuotaReleaseMutation } = props

  const customSecondaryButtonStyle = {
    height: '30px',
    width: '200px',
    paddingLeft: '0px',
    paddingRight: '0px',
  }

  const modalMessage =
    t('AFTER CONFIRMATION A SINGLE SERVICE INVOICE INVOICE IS CREATED.') +
    '\n' +
    t('THE INVOICE IS DISPATCHED WITH THE NEXT INVOICE RUN.') +
    '\n\n' +
    t('THE RELIEVED AMOUNTS ARE ADDED BACK TO THE QUOTA.')

  const modalButtonText = t('YES, PLEASE')

  return (
    <>
      <ButtonSecondary
        style={customSecondaryButtonStyle}
        onClick={() => {
          setModal({
            show: true,
            message: modalMessage,
            buttonMessage: modalButtonText,
            primaryButton: {
              usePrimaryButton: true,
              useFunction: () => triggerQuotaReleaseMutation(),
            },
            showButtonClose: true,
          })
          setAppointmentId(props.data.appointment.tableId)
        }}
      >
        {t('RELEASE FROM QUOTA')}
      </ButtonSecondary>
    </>
  )
}

export default QuotaButtonRenderer
