import React from 'react'
import styled from 'styled-components/macro'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { portalTheme, ButtonPrimary } from 'pyrexx-react-library'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 2rem;
`

const Main = styled.p`
  font-size: 72px;
  font-weight: ${portalTheme.font.weight.bold};

  text-align: center;
  margin: 0 0;
`

const Description = styled.p`
  font-size: 24px;
  font-weight: ${portalTheme.font.weight.light};

  text-align: center;
  margin: 3rem 0;
`

const NotFound = (props) => {
  return (
    <Container>
      <Main>404</Main>
      <Description>{props.t('WE COULD NOT FIND THIS PAGE')}</Description>

      <Link to='/'>
        <ButtonPrimary>{props.t('BACK HOME')}</ButtonPrimary>
      </Link>
    </Container>
  )
}

export default withTranslation('translation')(NotFound)
