import {
  Environment,
  Network,
  QueryResponseCache,
  RecordSource,
  Store,
} from 'relay-runtime'

const oneMinute = 60 * 1000
const cache = new QueryResponseCache({ size: 500, ttl: oneMinute * 5 })

function fetchQuery(
  operation,
  variables,
  cacheConfig,
  endpoint,
  accessToken,
  uploadables = false
) {
  const queryID = operation.text
  const isMutation = operation.operationKind === 'mutation'
  const isQuery = operation.operationKind === 'query'
  const forceFetch = cacheConfig && cacheConfig.force

  // Try to get data from cache on queries
  const fromCache = cache.get(queryID, variables)
  if (isQuery && fromCache !== null && !forceFetch) {
    return fromCache
  }

  const request = {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + accessToken,
    },
  }

  if (uploadables?.files) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.')
    }

    const formData = new FormData()
    formData.append(
      'operations',
      JSON.stringify({
        query: operation.text,
        variables,
      })
    )

    const fileObject = {}
    uploadables.files.forEach((file, index) => {
      fileObject[index] = ['variables.files.' + index]
    })

    formData.append('map', JSON.stringify(fileObject))
    uploadables.files.forEach((file, index) => {
      formData.append(index, file)
    })
    request.body = formData
  } else if (uploadables?.file) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.')
    }

    const formData = new FormData()
    formData.append(
      'operations',
      JSON.stringify({
        query: operation.text,
        variables,
      })
    )
    formData.append('map', JSON.stringify({ 0: ['variables.file'] }))
    formData.append('0', uploadables.file)

    request.body = formData
  } else {
    request.headers['Content-Type'] = 'application/json'
    request.body = JSON.stringify({
      query: operation.text,
      variables,
    })
  }

  // Otherwise, fetch data from server
  return fetch(endpoint, request)
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      // Update cache on queries

      // this is a workaround because otherwise we dont get any errors in queryRenderer
      if (json && json.errors) {
        return { data: null, errors: json.errors }
      }

      if (isQuery && json) {
        cache.set(queryID, variables, json)
      }
      // Clear cache on mutations
      if (isMutation) {
        cache.clear()
      }

      return json
    })
}

function generateEnvironment(endpoint, accessToken) {
  const network = Network.create(
    (operation, variables, cacheConfig, uploadables) =>
      fetchQuery(
        operation,
        variables,
        cacheConfig,
        endpoint,
        accessToken,
        uploadables
      )
  )
  const source = new RecordSource()
  const store = new Store(source)

  return new Environment({ network, store })
}

export default generateEnvironment
