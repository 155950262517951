export default {
  domLayout: 'autoHeight',
  sortingOrder: ['desc', 'asc'],
  defaultColDef: {
    sortable: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      buttons: ['apply', 'clear', 'reset'],
      closeOnApply: true,
      suppressAndOrCondition: true,
    },
    menuTabs: ['filterMenuTab', 'generalMenuTab'],
    wrapText: true,
    autoHeight: true,
    resizable: true,
  },
  animateRows: true,
  rowSelection: 'multiple',
  suppressRowClickSelection: true,
  enableRangeSelection: true,
  maintainColumnOrder: true,
  getContextMenuItems: (params) => {
    return ['copy']
  },
  popupParent: document.querySelector('body'),
  sideBar: {
    // src: https://www.ag-grid.com/documentation/react/tool-panel-columns/#section-visibility
    toolPanels: [
      {
        id: 'columns',
        labelDefault: '',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivotMode: true,
        },
      },
    ],
  },
}
