import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import NotificationsList from './NotificationsList'
import NotificationPreview from './NotificationPreview'
import ArrowDropdown from '../../../components/ArrowDropdown'
import Spacer from '../../../components/Spacer'

import { SupportColumn, SupportContainer } from '../Support.styles'

// eslint-disable-next-line
const initialNotifications = [
  {
    id: 'n1',
    category: 'Category 1',
    subject: 'Service 1',
    content: 'This is the content',
    wasRead: true,
    preview: <p>PREVIEW NOTIFICATION 1</p>,
    datetime: new Date().toISOString(),
  },
  {
    id: 'n2',
    category: 'Category 2',
    subject: 'Service 2',
    content: 'This is the content',
    wasRead: false,
    preview: <p>PREVIEW NOTIFICATION 2</p>,
    datetime: new Date().toISOString(),
  },
  {
    id: 'n3',
    category: 'Category 1',
    subject: 'Service 3',
    content: 'This is the content',
    wasRead: false,
    preview: <p>PREVIEW NOTIFICATION 3</p>,
    datetime: new Date().toISOString(),
  },
  {
    id: 'n4',
    category: 'Category 2',
    subject: 'Service 4',
    content: 'This is the content',
    wasRead: true,
    preview: <p>PREVIEW NOTIFICATION 4</p>,
    datetime: new Date().toISOString(),
  },
]

const Notifications = () => {
  const { t } = useTranslation()

  // BETA DISABLED
  // eslint-disable-next-line
  const [notifications, setNotifications] = useState([])
  const [selectedNotification, setSelectedNotification] = useState({})

  const listOfCategories = [
    ...new Set(notifications.map((n) => n.category)),
  ].map((n) => ({ label: n, id: n }))

  listOfCategories.unshift({ label: t('ALL'), id: 'all' })

  const [categoryDropdown, setCategoryDropdown] = useState({
    label: t('ALL'),
    id: 'all',
  })

  const listOfRead = [
    { label: t('ALL'), id: 'all' },
    { label: t('READ'), id: 'read', value: true },
    { label: t('NOT READ'), id: 'not-read', value: false },
  ]

  const [readDropdown, setReadDropdown] = useState({
    label: t('ALL'),
    id: 'all',
  })

  const onNotificationClick = (noti) => {
    setSelectedNotification(noti)
  }

  const filteredNotifications = notifications.filter(
    (n) =>
      (categoryDropdown.id === 'all' ||
        n.category === categoryDropdown.label) &&
      (readDropdown.id === 'all' || n.wasRead === readDropdown.value)
  )

  const numberOfUnreadItems = filteredNotifications.filter(
    (n) => n.wasRead === false
  ).length

  useEffect(() => {
    setSelectedNotification({})
  }, [categoryDropdown.label])

  return (
    <>
      <FiltersRow>
        <ArrowDropdown
          dropdownItems={listOfRead}
          label={readDropdown.label}
          selected={readDropdown}
          setSelected={setReadDropdown}
        />
        <ArrowDropdown
          dropdownItems={listOfCategories}
          label={categoryDropdown.label}
          selected={categoryDropdown}
          setSelected={setCategoryDropdown}
        />
      </FiltersRow>

      <SupportContainer>
        <SupportColumn>
          <NotificationsList
            notifications={filteredNotifications}
            selectedNotification={selectedNotification}
            onNotificationClick={onNotificationClick}
          />
        </SupportColumn>

        <SupportColumn>
          <NotificationPreview
            selectedNotification={selectedNotification}
            numberOfUnreadItems={numberOfUnreadItems}
          />
          <Spacer />
        </SupportColumn>
      </SupportContainer>
    </>
  )
}

const FiltersRow = styled.div`
  display: flex;
  gap: 32px;
  margin-bottom: 16px;
`

export default Notifications
