import { lettersStatusEnumList } from '../../../../components/AgGrid/enumLists'

const lettersStatusValueGetter = (props) => {
  const { value } = props
  const status = value || 'DRAFT'

  return lettersStatusEnumList(status)
}

export default lettersStatusValueGetter
