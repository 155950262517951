import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'

import NavItem from './NavItem'

const NavBar = (props) => {
  const { navItems = [] } = props

  return navItems ? (
    <Row nogutter style={{ gap: '50px' }}>
      {navItems.map((navItem) => {
        if (navItem.isHidden) {
          return null
        }

        if (navItem.isAlternative) {
          return (
            <Col xs='content' key={navItem.id} style={{ margin: '0 0 0 auto' }}>
              <NavItem
                label={navItem.label}
                to={navItem.to}
                isDisabled={navItem.isDisabled}
                isAlternative={navItem.isAlternative}
                isShown={navItem.isShown}
              />
            </Col>
          )
        }

        return (
          <Col xs='content' key={navItem.id}>
            <NavItem
              label={navItem.label}
              to={navItem.to}
              isDisabled={navItem.isDisabled}
              isAlternative={navItem.isAlternative}
              isShown={navItem.isShown}
            />
          </Col>
        )
      })}
    </Row>
  ) : null
}

NavBar.propTypes = {
  navItems: PropTypes.array,
}

export default NavBar
