import React, { useContext } from 'react'
import { withTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-grid-system'
import { NavLink } from 'react-router-dom'
import { css } from 'styled-components/macro'
import UserContext from '../../../../helper/userContext'

import { RouteLink, portalTheme, helperStyles } from 'pyrexx-react-library'

import { Routes } from '../../../../configs/RouteConfig'

const whiteLinksCSS = css`
  color: ${portalTheme.color.basicColorWhite};
  ${helperStyles.linkDecorationCSS}
`

const LinkFooterTheme = {
  components: {
    routeLink: {
      additionalStyles: whiteLinksCSS,
    },
  },
}

const CopyrightFooter = (props) => {
  const { currentLanguage } = useContext(UserContext)

  return (
    <Container xs sm md>
      <Row align='center'>
        <Col component='span' xs={6}>
          {props.t('PYREXX - SIMPLE, CLEAN, SAFE')}
        </Col>

        <Col xs={6}>
          <Row justify='end'>
            <Col component='span' xs='content'>
              <RouteLink
                key='linkImprint'
                navLinkElement={
                  <NavLink to={'/' + currentLanguage + Routes.imprint}>
                    {props.t('IMPRINT')}
                  </NavLink>
                }
                theme={LinkFooterTheme}
              />
            </Col>

            <Col component='span' xs='content'>
              <RouteLink
                key='linkDataPrivacy'
                navLinkElement={
                  <NavLink to={'/' + currentLanguage + Routes.privacy}>
                    {props.t('DATA PRIVACY')}
                  </NavLink>
                }
                theme={LinkFooterTheme}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default withTranslation('translation')(CopyrightFooter)
