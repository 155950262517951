import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import { SimpleCheckbox } from '../../../components/Simple'
import Skeleton from '../../../components/Skeleton'

import { Box, BoxContent, BoxLabel } from './styles'

// const availablePermissions = [
//   'NONE',
//   'ADMIN',
//   'INVOICE',
//   'CONTRACT',
//   'INVENTORY',
//   'QUOTA',
//   'APPOINTMENT',
//   'DATA_UPDATE',
// ]

const PermissionsInput = (props) => {
  const {
    permissions = [],
    handleArrayChange = () => {},
    availablePermissions = [],
    loading = false,
  } = props
  const { t } = useTranslation()

  return (
    <Box>
      <BoxLabel>{t('ROLES')}</BoxLabel>

      <BoxContent>
        {loading ? (
          <>
            <Skeleton variant='half' />
            <Skeleton variant='half' />
            <Skeleton variant='half' />
            <Skeleton variant='half' />
          </>
        ) : (
          availablePermissions.map((option) => (
            <SimpleCheckbox
              key={option}
              label={option}
              onChange={(e) => handleArrayChange(e, 'permissions', option)}
              id={`permissions-${option}`}
              value={permissions.includes(option)}
            />
          ))
        )}
      </BoxContent>
    </Box>
  )
}

PermissionsInput.propTypes = {
  availablePermissions: PropTypes.array,
  handleArrayChange: PropTypes.any,
  loading: PropTypes.bool,
  permissions: PropTypes.array,
}

export default PermissionsInput
