import React from 'react'
import styled, { css } from 'styled-components/macro'

import EmptyButton from '../../../components/Simple/EmptyButton'

const FaqSelectorList = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, auto);
`

const FaqCategoryButton = styled(EmptyButton)`
  padding: 16px 24px;
  border-radius: 10px;
  background-color: #fff;

  &:hover {
    transition: background-color 200ms ease-out;
    background-color: #c3cfdb;
  }

  transition: background-color 200ms ease-in;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #c3cfdb;
    `}

  ${(props) =>
    props.disabled &&
    css`
      color: #d8d8d8;
      cursor: not-allowed;
    `}
`

const FaqCategory = ({ category = {}, handleClick = () => {} }) => {
  return (
    <FaqCategoryButton onClick={() => handleClick(category)}>
      {category.label}
    </FaqCategoryButton>
  )
}

const FaqSelector = ({ categories = [], handleClick = () => {} }) => {
  return (
    <FaqSelectorList>
      {categories.map((c) => (
        <FaqCategory key={c.id} category={c} handleClick={handleClick} />
      ))}
    </FaqSelectorList>
  )
}

export default FaqSelector
