import React, { useContext } from 'react'
import graphql from 'babel-plugin-relay/macro'
import { useParams } from 'react-router-dom'

import QueryRendererContainer from '../../components/QueryRendererContainer/QueryRendererContainer'
import Invoice from './Invoice'
import InvoiceNotFound from './InvoiceNotFound/InvoiceNotFound'

import environment from '../../environments/pom/Environment'
import UserContext from '../../helper/userContext'
import formatDateLocale from '../../helper/formatDateLocale'

const query = graphql`
  query InvoiceContainerQuery($companyId: ID!, $invoiceNumber: [String]!) {
    Me {
      Company(id: $companyId) {
        Invoices(
          options: {
            filters: [
              {
                name: "invoiceNumber"
                filterType: text
                operator: AND
                conditions: [{ value: $invoiceNumber, type: equals }]
              }
            ]
          }
        ) {
          status
          name
          tableId
          description
          invoiceDate
          discount
          discountReason
          paymentTarget
          value
          invoiceNumber
          price {
            net
            gross
            vat
            percentage
          }
          remark
          type
          Contract {
            name
            status
            date
          }
          Items {
            name
            status
            reason
            unit
            vat
            quantity
            price {
              net
              gross
              vat
              percentage
            }
            id
          }
          preview
        }
      }
    }
  }
`

const InvoiceContainer = () => {
  const { user } = useContext(UserContext)
  const { invoiceNumber } = useParams()

  const variables = {
    companyId: user.companyId,
    invoiceNumber,
  }
  const { currentLanguage } = useContext(UserContext)

  return (
    <QueryRendererContainer
      environment={environment(user.accessToken)}
      query={query}
      variables={variables}
      render={(data) => {
        const invoice = data.Me?.Company?.Invoices[0]

        if (invoice) {
          const info = {
            status: invoice.status,
            invoiceNumber: invoice.invoiceNumber,
            invoiceId: invoice.tableId,
            invoiceDate: formatDateLocale(
              currentLanguage,
              invoice.invoiceDate,
              'P'
            ),
            contractDate: formatDateLocale(
              currentLanguage,
              invoice.Contract.date,
              'P'
            ),
            preview: invoice.preview,
            discount: invoice.discount,
            discountReason: () => {
              if (Date.parse(invoice.discountReason)) {
                return formatDateLocale(
                  currentLanguage,
                  invoice.discountReason,
                  'P'
                )
              }
              return invoice.discountReason
            },
            invoiceType: invoice.type,
            invoiceDescription: invoice.description,
            invoiceNote: invoice.remark,
            invoicePrice: invoice.price,
            invoicePaymentTerm:
              invoice.paymentTarget &&
              formatDateLocale(currentLanguage, invoice.paymentTarget, 'P'),
            invoiceItems: invoice.Items,
          }

          return <Invoice info={info} />
        } else {
          return <InvoiceNotFound />
        }
      }}
    />
  )
}
export default InvoiceContainer
