function dummyTranslate(text) {
  return text
}

const tenantStatusEnumList = (value) => {
  switch (value) {
    case 'STATUS_NOT_YET_MOVED_IN':
      return dummyTranslate('NOT YET MOVED IN')

    case 'STATUS_ACTIVE':
      return dummyTranslate('ACTIVE')

    case 'STATUS_MOVED_OUT':
      return dummyTranslate('MOVED OUT')

    case 'NONE':
    default:
      return ''
  }
}

export default tenantStatusEnumList
