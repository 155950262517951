import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Visible } from 'react-grid-system'

import SimpleLink from '../../Simple/SimpleLink'
import AuthenticatedHeader from './AuthenticatedHeader'
import UnauthenticatedHeader from './UnauthenticatedHeader'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'
import {
  CustomSearchbox,
  MainHeaderRow,
  RightSection,
  StyledModal,
} from './MainHeader.styles'
import pyrexxLogo from '../../../assets/pyrexx_logo.svg'
import SearchContainer from './SearchContainer/SearchContainer'

const MainHeader = () => {
  const { isAuth, currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const [searchModalOpen, setSearchModalOpen] = useState(false)
  const initFiltersForSearchModal = [
    { search: 'performances', label: t('PERFORMANCE NUMBER') },
    { search: 'tenants', label: t('INHABITANT NAME') },
    { search: 'invoices', label: t('INVOICE') },
    { search: 'customerNumbers', label: t('CUSTOMER NUMBER') },
    { search: 'addresses', label: t('ADDRESS') },
  ]
  const [querySearchVariables, setQuerySearchVariables] = useState({
    searchText: '',
    filters: initFiltersForSearchModal.map(
      (filterObject) => filterObject.search
    ),
  })

  const handleSubmit = () => {
    setSearchModalOpen(true)
  }

  const handleChange = (search) => {
    setQuerySearchVariables((state) => {
      return { ...state, searchText: search }
    })
  }

  return (
    <Container xs sm md lg>
      <MainHeaderRow align='center' nogutter>
        <Col align='start' style={{ display: 'inline-flex' }}>
          <SimpleLink
            to={'/' + currentLanguage + Routes.overview}
            style={{ display: 'inline-flex' }}
          >
            <img src={pyrexxLogo} height={45} alt='pyrexx' />
          </SimpleLink>
        </Col>

        <Visible md lg xl>
          <Col align='center'>
            {isAuth && (
              <CustomSearchbox
                disabledborder
                clearSearchAfterSubmit={false}
                handleChange={(search) => {
                  handleChange(search)
                }}
                handleSubmit={handleSubmit}
                searchText={querySearchVariables.searchText}
                placeholder={t('SEARCH')}
              />
            )}
          </Col>

          <Col align='right'>
            <RightSection>
              {isAuth ? <AuthenticatedHeader /> : <UnauthenticatedHeader />}
            </RightSection>
          </Col>
        </Visible>
        <StyledModal
          data-simple-modal='searchBox'
          isOpen={searchModalOpen}
          handleModalClose={() => setSearchModalOpen(false)}
        >
          <SearchContainer
            handleChange={handleChange}
            querySearchVariables={querySearchVariables}
            setQuerySearchVariables={setQuerySearchVariables}
            searchModalOpen={searchModalOpen}
            initFiltersForSearchModal={initFiltersForSearchModal}
            handleModalClose={() => setSearchModalOpen(false)}
          />
        </StyledModal>
      </MainHeaderRow>
    </Container>
  )
}

export default MainHeader
