import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-grid-system'
import { withTranslation } from 'react-i18next'

import {
  ContainerStyled,
  TitleStyled,
  DescriptionStyled,
  SubtitleStyled,
} from './SupportContainer.styles'

const SupportContainer = (props) => {
  const { title, description, subtitle, form } = props

  return (
    <ContainerStyled component='article' xs sm md>
      <Row justify='between' align='center' nowrap nogutter>
        <Col>
          <TitleStyled>{title}</TitleStyled>
        </Col>
      </Row>

      <Row justify='center' align='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          <DescriptionStyled>{description}</DescriptionStyled>
        </Col>
      </Row>

      {subtitle && (
        <Row justify='center' align='center'>
          <Col xs={12} sm={10} md={8} lg={6}>
            <SubtitleStyled>{subtitle}</SubtitleStyled>
          </Col>
        </Row>
      )}

      <Row justify='center' align='center'>
        <Col xs={12} sm={10} md={8} lg={6}>
          {form}
        </Col>
      </Row>
    </ContainerStyled>
  )
}

SupportContainer.propTypes = {
  title: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
  subtitle: PropTypes.any,
  form: PropTypes.element.isRequired,
  prevStep: PropTypes.string,
  showBackArrow: PropTypes.bool,
}

SupportContainer.defaultProps = {
  prevStep: '',
  showBackArrow: false,
}

export default withTranslation('translation')(SupportContainer)
