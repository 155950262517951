import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

import PageContainer from '../../components/PageContainer'
import NavBack from '../../components/NavBack'
import SimpleInput from '../../components/Simple/SimpleInput'
import Spacer from '../../components/Spacer'
import { Box, BoxInner } from './Box'
import { ButtonPrimary, SimpleModal, SimpleNote } from 'pyrexx-react-library'

import { useUserContext } from '../../helper/userContext'
import { Routes } from '../../configs/RouteConfig'
import handleLocalStorage from '../../helper/handleLocalStorage'
import useUpdateEffect from '../../hooks/useUpdateEffect'
import ChangeEmailMutation from '../../mutations/ChangeEmailMutation'
import { USER_EMAIL_KEY } from '../../helper/auth'

const UserEditEmail = () => {
  const { currentLanguage, user, setUser } = useUserContext()
  const { t } = useTranslation()
  const history = useHistory()

  const [newEmail, setNewEmail] = useState('')
  const [modal, setModal] = useState(false)
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = () => setModal(false)

  const schema = yup.object().shape({
    email: yup.string().email(t('INVALID EMAIL')).required(t('IS REQUIRED')),
  })

  const confirmAction = () => {
    if (newEmail.length > 0) {
      setIsLoading(true)
    }

    schema
      .validate({
        email: newEmail,
      })
      .then(({ email: validatedEmail }) => {
        ChangeEmailMutation(
          validatedEmail,
          user.accessToken,
          () => {
            setIsLoading(false)
            setUser((prevState) => ({ ...prevState, email: validatedEmail }))
            handleLocalStorage('set', USER_EMAIL_KEY, validatedEmail)
            handleLocalStorage('set', 'temp-waitingForEmailConfirmation', true)
            setModal(true)
          },
          (error) => {
            setIsLoading(false)
            setError(error?.source?.errors[0].extensions?.validation?.email[0])
          }
        )
      })
      .catch((error) => {
        setIsLoading(false)
        setError(error?.errors[0])
      })
  }

  const modalAction = () => {
    history.push('/' + currentLanguage + Routes.user)
  }

  useUpdateEffect(() => {
    setError('')
  }, [newEmail])

  return (
    <PageContainer>
      <NavBack
        title={t('CHANGE EMAIL')}
        to={'/' + currentLanguage + Routes.user}
      />

      <Box fullWidth={false} style={{ height: '250px' }}>
        <BoxInner style={{}}>
          <SimpleInput
            label={t('EMAIL ADDRESS')}
            value={newEmail}
            id='edit-email-input'
            onChange={(e) => setNewEmail(e.target.value)}
            feedbackMessage={error}
          />
        </BoxInner>
      </Box>

      <Spacer />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ButtonPrimary onClick={confirmAction} isLoading={isLoading}>
          {t('CONFIRM')}
        </ButtonPrimary>
      </div>

      <Spacer />

      <SimpleModal
        isOpen={modal}
        handleModalClose={closeModal}
        closeOnOutsideClick={false}
        showButtonClose={false}
      >
        <Box
          style={{
            flexDirection: 'column',
            gap: '24px',
          }}
        >
          <SimpleNote
            noteStatus='warning'
            // eslint-disable-next-line
            text={t(
              'WE HAVE SENT A VERIFICATION EMAIL TO YOUR EMAIL ADDRESS. PLEASE CLICK THE LINK INSIDE THAT EMAIL.'
            )}
          />
          <ButtonPrimary onClick={modalAction}>
            {t('BACK TO MY PROFILE')}
          </ButtonPrimary>
        </Box>
      </SimpleModal>
    </PageContainer>
  )
}

UserEditEmail.propTypes = {}

export default UserEditEmail
