import React, { useCallback, useEffect, useState } from 'react'
import { useUserContext } from '../../../helper/userContext'
import { Routes } from '../../../configs/RouteConfig'
import { fetchQuery } from 'react-relay'
import environment from '../../../environments/pom/Environment'
import { LetterQuery } from '../Letter/queries'
import { useParams } from 'react-router-dom'
import LoadingBox from '../../../components/LoadingSpinner/LoadingBox'
import BusinessMailCreateSingleLetter from './BusinessMailCreateSingleLetter'
import BusinessMailCreateMassLetter from './BusinessMailCreateMassLetter'

const BusinessMailEdit = (props) => {
  const { user, redirectRoute } = useUserContext()
  const { id } = useParams()
  const [layoutData, setLayoutData] = useState(false)

  const [loading, setLoading] = useState(true)
  const [pageCount, setPageCount] = useState(0)
  const [pdfPreview, setPdfPreview] = useState(false)
  const variables = {
    companyId: user.companyId,
    letterId: id,
  }

  const getLayoutData = useCallback(() => {
    return fetchQuery(environment(user.accessToken), LetterQuery, variables, {
      force: true,
    })
      .then((data) => {
        if (!data?.Me?.Company?.businessPost?.letter) {
          redirectRoute(Routes.businessMail + Routes.businessMailNotFound)
        } else {
          setLayoutData(data?.Me?.Company?.businessPost?.letter)
        }
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [redirectRoute, user.accessToken, variables])

  useEffect(() => {
    if (!layoutData) {
      getLayoutData()
    }
    if (!pageCount) {
      setPageCount(layoutData.pageCount)
      setPdfPreview(layoutData.preview)
    }
  }, [layoutData, getLayoutData, pageCount])

  if (loading) {
    return <LoadingBox spinner />
  } else {
    if (layoutData.type === 'SINGLE') {
      return (
        <BusinessMailCreateSingleLetter
          layoutData={layoutData}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pdfPreview={pdfPreview}
          setPdfPreview={setPdfPreview}
        />
      )
    } else {
      return (
        <BusinessMailCreateMassLetter
          layoutData={layoutData}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pdfPreview={pdfPreview}
          setPdfPreview={setPdfPreview}
        />
      )
    }
  }
}

export default BusinessMailEdit
