import { css } from 'styled-components/macro'

import { portalTheme } from 'pyrexx-react-library'

export const SimpleCSS = css`
  font-family: ${portalTheme.font.family.opensans};
  font-size: ${portalTheme.font.size.bodyRegular};
  font-weight: ${portalTheme.font.weight.regular};

  cursor: pointer;
  background-color: transparent;
  text-decoration: none;

  color: ${portalTheme.color.linkColorPrimary};

  &:hover,
  &:focus {
    color: ${portalTheme.color.linkColorPrimaryHover};
  }

  &:active {
    color: ${portalTheme.color.linkColorPrimaryActive};
  }
`

export const FontQuicksand = css`
  font-family: ${portalTheme.font.family.quicksand};
`
