import { useTranslation } from 'react-i18next'
import { usageUnitLockPeriodReasonEnumList } from '../enumLists'

const UsageUnitLockPeriodReasonRendererHelper = (props) => {
  const { value } = props
  const { t } = useTranslation()
  return t(usageUnitLockPeriodReasonEnumList(value))
}

export default UsageUnitLockPeriodReasonRendererHelper
