import React, { useContext } from 'react'
import { Container } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import NavBar from '../../NavBar'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'
import isPermissionIncluded from '../../../helper/isPermissionIncluded'
import {
  APPOINTMENTS_PERMISSIONS,
  BUSINESS_MAIL_PERMISSIONS,
  CONTRACTS_PERMISSIONS,
  INVOICES_PERMISSIONS,
  PROPERTY_MANAGEMENT_PERMISSIONS,
} from '../../../constants/permissions'

const BottomHeader = () => {
  const { currentLanguage, user } = useContext(UserContext)
  const { t } = useTranslation()

  const permissions = user.userPermissions || []

  const navItems = [
    {
      id: 'overview',
      label: t('OVERVIEW'),
      to: '/' + currentLanguage + Routes.overview,
    },
    {
      id: 'appointments',
      label: t('APPOINTMENTS'),
      to: '/' + currentLanguage + Routes.appointments,
      isHidden: !isPermissionIncluded(permissions, APPOINTMENTS_PERMISSIONS),
    },
    {
      id: 'contracts',
      label: t('CONTRACTS'),
      to: '/' + currentLanguage + Routes.contracts,
      isHidden: !isPermissionIncluded(permissions, CONTRACTS_PERMISSIONS),
    },
    {
      id: 'invoices',
      label: t('INVOICES'),
      to: '/' + currentLanguage + Routes.invoices,
      isHidden: !isPermissionIncluded(permissions, INVOICES_PERMISSIONS),
    },
    {
      id: 'propertyManagement',
      label: t('PORTFOLIO MANAGEMENT'),
      to: '/' + currentLanguage + Routes.propertyManagement,
      isHidden: !isPermissionIncluded(
        permissions,
        PROPERTY_MANAGEMENT_PERMISSIONS
      ),
    },
    {
      id: 'businessMail',
      label: t('BUSINESS MAIL'),
      to: '/' + currentLanguage + Routes.businessMail,
      isHidden: !isPermissionIncluded(permissions, BUSINESS_MAIL_PERMISSIONS),
    },
    {
      id: 'support',
      label: t('SUPPORT'),
      to: '/' + currentLanguage + Routes.support,
      isAlternative: true,
    },
  ]

  return (
    <Container
      xs
      sm
      md
      lg
      style={{
        backgroundColor: 'white',
      }}
    >
      <NavBar navItems={navItems} />
    </Container>
  )
}

export default BottomHeader
