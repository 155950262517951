/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FireLoadsMarkAsDoneMutationVariables = {|
  ids: $ReadOnlyArray<?string>,
  companyId: string,
|};
export type FireLoadsMarkAsDoneMutationResponse = {|
  +status: ?{|
    +Company: ?{|
      +FireLoads: ?{|
        +markAsDone: ?$ReadOnlyArray<?number>
      |}
    |}
  |}
|};
export type FireLoadsMarkAsDoneMutation = {|
  variables: FireLoadsMarkAsDoneMutationVariables,
  response: FireLoadsMarkAsDoneMutationResponse,
|};
*/


/*
mutation FireLoadsMarkAsDoneMutation(
  $ids: [String]!
  $companyId: ID!
) {
  status: Me {
    Company(id: $companyId) {
      FireLoads(options: {filters: [{filterType: text, operator: AND, name: "id", conditions: [{type: equals, value: $ids}]}]}) {
        markAsDone
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = [
  {
    "alias": "status",
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "fields": [
                  {
                    "items": [
                      {
                        "fields": [
                          {
                            "items": [
                              {
                                "fields": [
                                  {
                                    "kind": "Literal",
                                    "name": "type",
                                    "value": "equals"
                                  },
                                  {
                                    "kind": "Variable",
                                    "name": "value",
                                    "variableName": "ids"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "conditions.0"
                              }
                            ],
                            "kind": "ListValue",
                            "name": "conditions"
                          },
                          {
                            "kind": "Literal",
                            "name": "filterType",
                            "value": "text"
                          },
                          {
                            "kind": "Literal",
                            "name": "name",
                            "value": "id"
                          },
                          {
                            "kind": "Literal",
                            "name": "operator",
                            "value": "AND"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "filters.0"
                      }
                    ],
                    "kind": "ListValue",
                    "name": "filters"
                  }
                ],
                "kind": "ObjectValue",
                "name": "options"
              }
            ],
            "concreteType": "FireLoadMutations",
            "kind": "LinkedField",
            "name": "FireLoads",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "markAsDone",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FireLoadsMarkAsDoneMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FireLoadsMarkAsDoneMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "fc470dd9475b45db829c0a858265d5e3",
    "id": null,
    "metadata": {},
    "name": "FireLoadsMarkAsDoneMutation",
    "operationKind": "mutation",
    "text": "mutation FireLoadsMarkAsDoneMutation(\n  $ids: [String]!\n  $companyId: ID!\n) {\n  status: Me {\n    Company(id: $companyId) {\n      FireLoads(options: {filters: [{filterType: text, operator: AND, name: \"id\", conditions: [{type: equals, value: $ids}]}]}) {\n        markAsDone\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '49e96d0d6b4a13f0fcfa7d18a6e3567e';

module.exports = node;
