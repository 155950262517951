import { useCallback } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { CountServiceCostsQuery, ServiceCostsGridQuery } from '../queries'
import { priceGetterValue } from '../valueGetter'
import { useTranslation } from 'react-i18next'
import {
  ServiceCostStatusRendererOnlyActive,
  TenantFullNameRenderer,
} from '../renderers'
import { PriceRenderer } from '../../../components/AgGrid/renderers'

export const useCostsGrid = () => {
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { usageUnitId, referenceNumber } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t('SERVICE RENDERED')
          case 'netValue':
            return priceGetterValue(params)
          default:
            return params.value
        }
      }
      const ServiceCostsColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          field: 'status',
          width: 70,
          sortable: false,
          filter: false, // Only show ACTIVE
          cellRenderer: ServiceCostStatusRendererOnlyActive,
        },
        {
          headerName: t('ALLOCATION NUMBER'),
          colId: 'referenceNumber',
          width: 70,
          sortable: false,
          filter: false,
          valueGetter: (params) => referenceNumber,
        },
        {
          headerName: t('INHABITANT'),
          field: 'tenant',
          width: 70,
          cellRenderer: TenantFullNameRenderer,
          filter: false, // TODO add tenant filter over serviceCosts->Tenant
        },
        {
          headerName: t('PROOF OF PERFORMANCE'),
          field: 'proofOfPerformance',
          width: 150,
          ...withFilterParams,
        },
        {
          headerName: t('YEAR OF SERVICE'),
          field: 'yearOfService',
          width: 60,
          ...withFilterParams,
          sort: 'desc',
          type: 'rightAligned',
        },
        {
          headerName: t('NET VALUE'),
          field: 'netValue',
          width: 60,
          cellRenderer: PriceRenderer,
          filter: false,
          sortable: false, // TODO FILTERABLE?
          type: 'rightAligned',
        },
      ]
      const ServiceCostsCustomFilter = [
        {
          name: 'serviceCategory.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [currentService.tableId],
              type: 'equals',
            },
          ],
        },
        {
          name: 'status',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: 'SERVICE_RENDERED',
              type: 'equals',
            },
          ],
        },
      ]
      return {
        query: ServiceCostsGridQuery,
        columnDefs: ServiceCostsColumnDefs,
        customFilters: ServiceCostsCustomFilter,
        countQuery: CountServiceCostsQuery,
        extractor: (response) => response.Me?.Company?.UsageUnit?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return ['copy']
          },
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { usageUnitId },
      }
    },
    [t]
  )
  return { getParams }
}
