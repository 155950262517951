import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecipientsGrid } from './gridConfigHooks/useRecipientsGrid'
import { useAgGridUltra } from '../../../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'

const Recipients = (props) => {
  const { letterId, recipients = [] } = props
  const { t } = useTranslation()

  const grids = [
    {
      id: 'recipientsUpload-grid',
      label: t('UPLOADED RECIPIENTS'),
      useGetParams: useRecipientsGrid(letterId),
    },
  ]

  const { generateGrid, setForceRefresh } = useAgGridUltra(
    'recipientsUploadGrid',
    grids,
    {},
    false,
    true,
    5,
    true,
    true
  )

  useEffect(() => {
    if (recipients.length > 0) {
      setForceRefresh(true)
    }
  }, [recipients, setForceRefresh])

  return generateGrid(false)
}

export default Recipients
