import React from 'react'
import styled from 'styled-components/macro'

import { portalTheme } from 'pyrexx-react-library'

const { successColor, warningColor, errorColor, disabledColor } =
  portalTheme.color

export const getColor = (status) => {
  switch (status) {
    case 3:
    case 'success':
      return successColor
    case 5:
    case 'warning':
      return warningColor
    case 1:
    case 'error':
      return errorColor
    case 4:
    case 'disabled':
      return disabledColor
    case 'empty':
      return 'transparent'
    default:
      return 'black'
  }
}

const StatusLight = styled.div`
  height: ${(props) => props.size || '18px'};
  width: ${(props) => props.size || '18px'};

  border-radius: 50%;
  background-color: ${(props) => props.color};

  // fix ie11 bug
  // https://github.com/philipwalton/flexbugs#flexbug-1
  @media all and (-ms-high-contrast: none) {
    flex-shrink: 0;
    flex-basis: auto;
    margin-right: 0.5rem;
  }
`

export default (props) => {
  const { status = '', size = '' } = props

  return <StatusLight color={getColor(status)} size={size} data-status-light='' />
}
