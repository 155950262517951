import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query LettersGridQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        businessPost {
          data: letters(
            options: {
              startRow: $startRow
              endRow: $endRow
              sorting: $sortModel
              filters: $filters
            }
          ) {
            id
            tableId
            sender {
              id
              tableId
              firstname
              lastname
            }
            createdAt
            status
            internalTitle
            title
            type
            sentAt
            recipient1FirstName
            recipient1LastName
            addressStreetAndNo
            addressZip
            addressCity
            pageCount
            countRecipients
            countAttachments
          }
        }
      }
    }
  }
`
