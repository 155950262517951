import React from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import Tabs from '../../components/Tabs'
import useUrlRedirection from '../../hooks/useUrlRedirection'
import BusinessMailCreate from './LetterCreate/BusinessMailCreate'
import Letters from './Letters/Letters'
import BusinessMailEdit from './LetterEdit/BusinessMailEdit'

const BusinessMailContainer = (props) => {
  const { pageSection = 'businessMailsGrid' } = props
  const { t } = useTranslation()
  const urlRedirectionCallback = useUrlRedirection()

  const OverviewTabs = [
    {
      key: 'list',
      label: t('MAILS'),
      content: <Letters />,
    },
    {
      key: 'add',
      extraIndicatorKey: ['edit'],
      label: t('CREATE MAIL'),
      content: <BusinessMailCreate />,
    },
    {
      key: 'edit',
      label: '',
      content: <BusinessMailEdit />,
    },
  ]

  return (
    <PageContainer fluid>
      <PageTitle>{t('BUSINESS MAIL')}</PageTitle>
      <Tabs
        tabs={OverviewTabs}
        pageSection={pageSection}
        urlRedirectionCallback={urlRedirectionCallback}
      />
    </PageContainer>
  )
}

export default BusinessMailContainer
