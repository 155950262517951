import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { portalTheme, ButtonPrimary } from 'pyrexx-react-library'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: 2rem;
`

const Description = styled.p`
  font-size: 24px;
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.light};

  text-align: center;
  margin: 3rem 0;
  margin-top: 0;
`

const NotFound = (props) => {
  const { currentLanguage } = useContext(UserContext)

  return (
    <Container>
      <Description>{props.t('WE COULD NOT FIND THE INVOICE')}</Description>

      <Link to={'/' + currentLanguage + Routes.invoices}>
        <ButtonPrimary>{props.t('BACK TO INVOICES')}</ButtonPrimary>
      </Link>
    </Container>
  )
}

export default withTranslation('translation')(NotFound)
