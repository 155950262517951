import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import Spacer from '../../components/Spacer'
import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useClosedOperationsGrid } from './gridConfigHooks/useClosedOperationsGrid'
import { fetchQuery } from 'react-relay'
import environment from '../../environments/pom/Environment'
import * as Throttle from 'promise-parallel-throttle'
import { CountOperationsQuery, OperationsGridQuery } from './queries'
import UserContext from '../../helper/userContext'
import OperationsOverview from './OperationsOverview'
import { LinkButtonStyled } from '../../styles/HelperStylesComponents.styles'
import {
  ButtonPrimary,
  ButtonSecondary,
  Icon,
  SimpleModal,
} from 'pyrexx-react-library'
import { useOpenOperationsGrid } from './gridConfigHooks/useOpenOperationsGrid'
import FireLoadsMarkAsDoneMutation from './mutations/FireLoadsMarkAsDoneMutation'
import { Col, Row } from 'react-grid-system'

const Operations = () => {
  const { t } = useTranslation()
  const { user } = useContext(UserContext)
  const [modalOoen, setModalOpen] = useState(false)

  const [houseEntranceCoordinates, setHouseEntranceCoordinates] = useState({
    loading: true,
    coordinates: [],
  })

  const [updatingRows, setUpdatingRows] = useState(false)

  const getHouseEntranceForOperationsCoordinates = useCallback(
    (status) => {
      setHouseEntranceCoordinates({
        loading: true,
        coordinates: [],
      })
      const CountCoordinatesVariables = {
        companyId: user.companyId,
        filters: [
          {
            name: 'status',
            filterType: 'text',
            operator: 'AND',
            conditions: [
              {
                value: status,
                type: 'equals',
              },
            ],
          },
        ],
      }

      fetchQuery(
        environment(user.accessToken),
        CountOperationsQuery,
        CountCoordinatesVariables,
        {
          force: true,
        }
      )
        .then((res) => {
          const houseEntrancesCount = res.Me?.Company?.data

          const promises = []
          const chunkSize = 5000
          const chunks = Math.ceil(houseEntrancesCount / chunkSize)
          for (let i = 0; i < chunks; i++) {
            const start = i * (chunkSize + 1)
            const end = start + chunkSize

            promises.push(() => {
              return fetchQuery(
                environment(user.accessToken),
                OperationsGridQuery,
                {
                  sortModel: [],
                  startRow: start,
                  endRow: end,
                  ...CountCoordinatesVariables,
                },
                {
                  force: true,
                }
              )
                .then((res) => {
                  return res.Me?.Company?.data
                })
                .catch((e) => {
                  console.log(e)
                })
            })
          }

          const currentCoordinates = { coordinates: [], address: [] }

          Throttle.all(promises)
            .then((houses) => {
              houses.flat(1).forEach((houseTmp) => {
                const house = houseTmp.houseEntrance
                currentCoordinates.coordinates.push([
                  house.coordinates.lat,
                  house.coordinates.lng,
                ])
                currentCoordinates.address.push({
                  address: house.address,
                  tableId: house.tableId,
                  countUnits: house.countUsageUnits,
                })
              })
            })
            .then(() => {
              setHouseEntranceCoordinates({
                loading: false,
                coordinates: currentCoordinates,
              })
            })
            .catch((e) => {
              console.log(e)
            })
        })
        .catch((e) => {
          console.log(e)
        })
    },
    [user.accessToken, user.companyId]
  )

  useEffect(() => {
    if (houseEntranceCoordinates.loading) {
      getHouseEntranceForOperationsCoordinates('OPEN')
    }
  }, [
    getHouseEntranceForOperationsCoordinates,
    houseEntranceCoordinates.loading,
  ])

  const generateSetAllDoneLink = (selectedRows, setForceRefresh, gridApi) => {
    return (
      <LinkButtonStyled
        onClick={() => {
          gridApi.forEachNode((node) => {
            node.setSelected(true)
          })
          setModalOpen(true)
        }}
        style={{ marginRight: '1rem' }}
      >
        {t('MARK AS DONE')}
        <Icon
          icon='bullet-point'
          style={{ marginLeft: '.25rem' }}
          size='16px'
        />
      </LinkButtonStyled>
    )
  }

  const grids = [
    {
      id: 'open-operations',
      label: t('OPEN'),
      useGetParams: useOpenOperationsGrid(),
      customActionCenterItems: [
        { type: 'support' },
        {
          type: 'labelSeparator',
          label: t('FIRE LOAD STATUS CHANGE'),
        },
        /**
        {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t('FORWARDED TO EXECUTOR'),
        },
        {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t('PROCESSING PENDING'),
        },
        {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t('FIXED'),
        },
         **/
        {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t('DONE'),
          onClick: (selectedRows, setForceRefresh) => {
            setModalOpen(true)
          },
        },
        {
          type: 'labelSeparator',
          label: t('LIST EXPORT'),
        },
        { type: 'exportWholeList' },
        { type: 'exportSelected' },
      ],
      extraItems: generateSetAllDoneLink,
    },
    {
      id: 'closed-operations',
      label: t('COMPLETED'),
      useGetParams: useClosedOperationsGrid(),
      customActionCenterItems: [
        /**
        { type: 'support' },
        {
          type: 'labelSeparator',
          label: t('FIRE LOAD STATUS CHANGE'),
        },

        {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t('FORWARDED TO EXECUTOR'),
        },

        {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t('PROCESSING PENDING'),
          onClick: (selectedRows, setForceRefresh) => {
            const ids = selectedRows.map((item) => {
              return item.id.split('::')[2]
            })
            if (ids.length === 0) {
              return
            }
            setUpdatingRows(true)
            const variables = {
              ids: ids,
              companyId: user.companyId,
            }
            FireLoadsMarkAsPendingMutation(
              variables,
              user.accessToken,
              (data) => {
                setForceRefresh(true)
                setUpdatingRows(false)
                console.log('finished set as pending', ids, data)
              }
            )
            console.log('click', selectedRows, ids)
          },
        },
        {
          type: 'custom',
          disabled: false,
          icon: 'bullet-point',
          label: t('FIXED'),
        },
         **/
        {
          type: 'labelSeparator',
          label: t('LIST EXPORT'),
        },
        { type: 'exportWholeList' },
        { type: 'exportSelected' },
      ],
    },
  ]

  const {
    generateGrid,
    generatePills,
    selectedRows,
    setForceRefresh,
    setSelectedRows,
  } = useAgGridUltra('Operations', grids)

  return (
    <PageContainer>
      <PageTitle>{t('INCIDENTS')}</PageTitle>
      <OperationsOverview
        loading={houseEntranceCoordinates.loading}
        houseEntrancesCoordinates={houseEntranceCoordinates.coordinates}
      />
      <Spacer />
      {generatePills()}
      {generateGrid(updatingRows)}
      <SimpleModal
        isOpen={modalOoen}
        closeOnOutsideClick
        handleModalClose={() => setModalOpen(false)}
        style={{ maxWidth: '75%' }}
      >
        <Row justify='center'>
          <Col xs='content'>
            <h3>{t('SET SELECTED FIRELOADS AS DONE?')}</h3>
          </Col>
        </Row>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonPrimary
              onClick={() => {
                const ids = selectedRows.map((item) => {
                  return item.id.split('::')[2]
                })
                if (ids.length === 0) {
                  return
                }
                setUpdatingRows(true)
                const variables = {
                  ids: ids,
                  companyId: user.companyId,
                }
                FireLoadsMarkAsDoneMutation(
                  variables,
                  user.accessToken,
                  (data) => {
                    setSelectedRows([])
                    setForceRefresh(true)
                    setUpdatingRows(false)
                  }
                )
                setModalOpen(false)
              }}
            >
              {t('CONFIRM')}
            </ButtonPrimary>
          </Col>
          <Col>
            <ButtonSecondary onClick={() => setModalOpen(false)}>
              {t('CANCEL')}
            </ButtonSecondary>
          </Col>
        </Row>
      </SimpleModal>
    </PageContainer>
  )
}

export default Operations
