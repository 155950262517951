import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import QueryRendererContainer from '../../components/QueryRendererContainer/QueryRendererContainer'
import PropertyUnit from './PropertyUnit'
import PropertyUnitNotFound from './PropertyUnitNotFound'

import environment from '../../environments/pom/Environment'
import PropertyUnitContainerQuery from './queries/PropertyUnitContainer'
import UserContext from '../../helper/userContext'
import getServices from '../../helper/getServices'
import { serviceCategoryEnumList } from '../../components/AgGrid/enumLists'

const PropertyUnitContainer = () => {
  const { user } = useContext(UserContext)
  const { propertyUnitId } = useParams()
  const { t } = useTranslation()

  const variables = {
    companyId: user.companyId,
    propertyUnitId,
  }

  return (
    <QueryRendererContainer
      environment={environment(user.accessToken)}
      query={PropertyUnitContainerQuery}
      variables={variables}
      loading={<PropertyUnit loading />}
      render={(data) => {
        /**
         * @param {{Me:object}} data
         * @param {{Company:object}} data.Me
         * @param {{Appointment:object}} data.Me.Company
         * @param {{usageUnit:array}} data.Me.Company.Appointment
         */
        const propertyUnit = data.Me?.Company?.HouseEntrance
        const services = getServices(data.Me?.Company?.Contracts)
        const translatedServices = services.map((service) => ({
          ...service,
          label: t(serviceCategoryEnumList(service.name)),
        }))

        if (propertyUnit) {
          return (
            <PropertyUnit
              propertyUnit={propertyUnit}
              propertyUnitId={propertyUnitId}
              services={translatedServices}
            />
          )
        } else {
          return <PropertyUnitNotFound />
        }
      }}
    />
  )
}

export default PropertyUnitContainer
