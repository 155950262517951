import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import EmptyButton from '../Simple/EmptyButton'

const Wrapper = styled(EmptyButton)`
  padding: 16px 24px;
  border-radius: 10px;
  background-color: #fff;

  ${(props) => props.isActive && 'background-color: #C3CFDB;'}
  ${(props) => props.disabled && 'color: #D8D8D8; cursor: not-allowed;'}
`

const Pill = (props) => {
  const {
    isActive = false,
    disabled = false,
    onClick,
    children,
    ...rest
  } = props

  return (
    <Wrapper
      isActive={isActive}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Wrapper>
  )
}

Pill.propTypes = {
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.any,
}

export default Pill
