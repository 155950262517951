import React from 'react'

import LivechatButton from '../../components/LivechatButton/LivechatButton'
import Tabs from '../../components/Tabs'
import styled from 'styled-components/macro'

const StyledLivechatButtonWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: -23px;
`

const RelativeContainerWrapper = styled.div`
  position: relative;
`

const TabsWithLivechat = (props) => {
  const { tabs = [], pageSection = '', urlRedirectionCallback } = props

  return (
    <RelativeContainerWrapper>
      <Tabs
        tabs={tabs}
        pageSection={pageSection}
        urlRedirectionCallback={urlRedirectionCallback}
      />
      <StyledLivechatButtonWrapper>
        <LivechatButton />
      </StyledLivechatButtonWrapper>
    </RelativeContainerWrapper>
  )
}

export default TabsWithLivechat
