import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { StyledInput } from 'pyrexx-react-library'
import StatusLight from '../../../components/Status/StatusLight'

import useUpdateEffect from '../../../hooks/useUpdateEffect'

const Wrapper = styled.div`
  padding: 0 1rem;
  padding-top: 10px;
`

const Spacer = styled.div`
  margin-left: 1rem;
`

const FilterItemWrapper = styled.div`
  & > span > label > label > div {
    align-items: center;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const Label = styled.p`
  margin: 0;
  font-size: 16px;
`

const FilterItem = ({ status = '', label, value, onChange }) => {
  return (
    <FilterItemWrapper>
      <StyledInput
        label={
          <LabelWrapper>
            {status ? <StatusLight status={status} size='1rem' /> : null}
            <Label>{label}</Label>
          </LabelWrapper>
        }
        type='checkbox'
        value={value}
        onChange={onChange}
        id={`status-custom-filter-${status}`}
        name={`status-custom-filter-${status}`}
        setFieldValue={() => {}}
      />
    </FilterItemWrapper>
  )
}

const statusMap = {
  paid: 3,
  open: 5,
  reminded: 1,
}

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  useEffect(() => {
    // console.log('StatusFilter rendered')
  })

  const { t } = useTranslation()

  const [statusFilter, setStatusFilter] = useState([])

  const [selectAll, setSelectAll] = useState(false)
  const [filterState, setFilterState] = useState({
    paid: false,
    open: false,
    reminded: false,
  })

  useUpdateEffect(() => {
    // console.log('handleAllFilter')
    const handleAllFilter = (val) => {
      setFilterState({ paid: val, open: val, reminded: val })
    }
    handleAllFilter(selectAll)
  }, [selectAll])

  useUpdateEffect(() => {
    // console.log('filterState update: ', filterState)

    let selected = statusFilter || []

    for (const [key, value] of Object.entries(filterState)) {
      if (value) {
        if (!selected.includes(statusMap[key])) {
          selected = [...selected, statusMap[key]]
        }
      } else {
        if (selected.includes(statusMap[key])) {
          selected = selected.filter((f) => f !== statusMap[key])
        }
      }
    }

    // console.log('selected', selected)

    setStatusFilter(selected)
  }, [filterState])

  useUpdateEffect(() => {
    // console.log('statusFilter update: ', statusFilter)
    filterChangedCallback()
  }, [statusFilter])

  useImperativeHandle(ref, () => {
    // console.log('useImperativeHandle')

    // src: https://www.ag-grid.com/documentation/react/component-filter/#react-methods--lifecycle
    return {
      isFilterActive() {
        // console.log('isFilterActive')
        return statusFilter != null && statusFilter.length > 0
      },
      getModel() {
        if (statusFilter === null || statusFilter.length <= 0) {
          return null
        }

        const model = {
          name: 'status',
          operator: 'OR',
          filterType: 'number',
        }

        statusFilter.forEach((filterItem, idx) => {
          model['condition' + (idx + 1)] = {
            type: 'equals',
            filterType: 'number',
            filter: filterItem,
          }
        })

        // console.log('getModel', model, statusFilter)

        return model
      },
      setModel(model) {
        // console.log('setModel', model)
        // TODO improve this
        setStatusFilter(model ? [model.condition1.filter] : [])
      },
    }
  })

  return (
    <Wrapper>
      <FilterItem
        value={selectAll}
        onChange={() => setSelectAll(!selectAll)}
        label={t('ALL STATUS')}
      />
      <Spacer>
        <FilterItem
          value={filterState.paid}
          onChange={() =>
            setFilterState((prev) => ({ ...prev, paid: !prev.paid }))
          }
          status={3}
          label={t('PAID')}
        />
        <FilterItem
          value={filterState.open}
          onChange={() =>
            setFilterState((prev) => ({ ...prev, open: !prev.open }))
          }
          status={5}
          label={t('OPEN')}
        />
        <FilterItem
          value={filterState.reminded}
          onChange={() =>
            setFilterState((prev) => ({ ...prev, reminded: !prev.reminded }))
          }
          status={1}
          label={t('DUNNING LETTER')}
        />
      </Spacer>
    </Wrapper>
  )
})
