import React from 'react'
import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'
import { useTranslation } from 'react-i18next'
import { notEquippedUsageUnitStatusValueGetter } from '../valueGetter'

const NotEquippedUsageUnitStatusRenderer = (props) => {
  const { t } = useTranslation()
  const status = notEquippedUsageUnitStatusValueGetter(props)
  return <StatusLightWithLabel status={status.color} label={t(status.msg)} />
}

export default NotEquippedUsageUnitStatusRenderer
