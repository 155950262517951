import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import AgGrid from '../../components/AgGrid'
import ServiceDetailsQuery from './queries/ServiceDetails'

import ServerSideDatasource from '../../environments/api/serverSideDataSource'
import {
  columnCheckbox,
  withFilterParams,
} from '../../components/AgGrid/columnDefs'
import UserContext from '../../helper/userContext'
import defaultGridOptions from '../../components/AgGrid/defaultGridOptions'

const ServiceDetails = (props) => {
  const { appointmentId } = props
  const { user } = useContext(UserContext)
  const { t } = useTranslation()

  const ServiceDetailsColumnDefs = [
    columnCheckbox,
    {
      headerName: t('ACTION POSITION'),
      field: 'actionPosition.name',
      width: 100,
      ...withFilterParams,
    },
    {
      headerName: t('CAUSE OF ORDER'),
      field: 'appointment.serviceReason.name',
      width: 100,
      ...withFilterParams,
    },
    {
      headerName: t('TYPE OF USE'),
      field: 'room.type',
      width: 100,
      ...withFilterParams,
    },
  ]

  const serverSideDatasource = new ServerSideDatasource(
    ServiceDetailsQuery,
    (response) => response.Me?.Company?.Appointment?.data,
    user,
    { appointmentId },
    []
  )

  // eslint-disable-next-line no-unused-vars
  const [gridApi, setGridApi] = useState(null)

  const onGridReady = (params) => {
    setGridApi(params.api)

    params.api.sizeColumnsToFit()
  }

  const gridOptions = {
    ...defaultGridOptions,
    serverSideDatasource,
    columnDefs: ServiceDetailsColumnDefs,
  }

  return <AgGrid onGridReady={onGridReady} gridOptions={gridOptions} />
}

ServiceDetails.propTypes = {
  appointmentId: PropTypes.any.isRequired,
}

export default ServiceDetails
