import React from 'react'
import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'
import { useTranslation } from 'react-i18next'
import { usageUnitLockPeriodStatusValueGetter } from '../valueGetter'

const UsageUnitLockPeriodStatusRenderer = (props) => {
  const { value } = props
  const { t } = useTranslation()

  const UsageUnitLockedStatusMap = {
    NOT_LOCKED: 'success',
    PARTLY_LOCKED: 'disabled',
    COMPLETELY_LOCKED: 'disabled',
  }

  return (
    <StatusLightWithLabel
      status={UsageUnitLockedStatusMap[value]}
      label={t(usageUnitLockPeriodStatusValueGetter(props))}
    />
  )
}

export default UsageUnitLockPeriodStatusRenderer
