import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { StyledInput } from 'pyrexx-react-library'
import StatusLight from '../Status/StatusLight'
import { css } from 'styled-components'

const Wrapper = styled.div`
  & > span > label > label > div {
    align-items: center;
  }

  & > span > label > label > div > div {
    display: flex;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
  word-wrap: anywhere;
`

const Label = styled.p`
  margin: 0;
  font-size: 13px;
`

export default function FilterCheckbox(props) {
  const {
    id = 'filter-checkbox',
    label = '',
    status = '',
    checked = false,
    onChange = () => {},
  } = props

  const themeCheckBox = {
    components: {
      customCheckboxRadio: {
        icon: {
          fontSize: '20px',
        },
      },
      styledInput: {
        additionalStyles: css`
          height: auto;
        `,
      },
    },
  }

  return (
    <Wrapper>
      <StyledInput
        theme={themeCheckBox}
        label={
          <LabelWrapper>
            {status ? <StatusLight status={status} size='1rem' /> : null}
            <Label>{label}</Label>
          </LabelWrapper>
        }
        type='checkbox'
        value={checked}
        onChange={onChange}
        id={id}
        name={id}
        setFieldValue={() => {}}
        overrideActiveIcon={props.overrideActiveIcon}
        overrideDeactivatedIcon={props.overrideDeactivatedIcon}
      />
    </Wrapper>
  )
}

FilterCheckbox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.any,
  status: PropTypes.oneOf(['success', 'error', 'warning', 'empty', 'disabled']),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}
