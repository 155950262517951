/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChangeEmailMutationVariables = {|
  email: string
|};
export type ChangeEmailMutationResponse = {|
  +Me: ?{|
    +changeEmail: ?boolean
  |}
|};
export type ChangeEmailMutation = {|
  variables: ChangeEmailMutationVariables,
  response: ChangeEmailMutationResponse,
|};
*/


/*
mutation ChangeEmailMutation(
  $email: String!
) {
  Me {
    changeEmail(email: $email)
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "email",
            "variableName": "email"
          }
        ],
        "kind": "ScalarField",
        "name": "changeEmail",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9403b0dc977e061623895ce98fcce5da",
    "id": null,
    "metadata": {},
    "name": "ChangeEmailMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeEmailMutation(\n  $email: String!\n) {\n  Me {\n    changeEmail(email: $email)\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b4aecefe57514df69a48910773be151d';

module.exports = node;
