import styled from 'styled-components/macro'

import { Icon, portalTheme, SimpleModal } from 'pyrexx-react-library'
import EmptyButton from '../Simple/EmptyButton'

export const ActionCenterRow = styled.div`
  background-color: #eff4f7;
  height: ${(props) =>
    props?.actionCenterHeight ? props.actionCenterHeight : '64'}px;
  ${(props) =>
    props.isFullScreen
      ? `
      padding: 0;
      margin: 0;
    `
      : !props.boxedVersion
      ? `
      padding: 12px calc(((100vw - 100% - 1rem) / 2));
      margin: 0 calc(((100vw - 100% - 1rem) / 2) * -1);
    `
      : `
      padding: 12px;
      margin: 5;
    `}
`

export const Label = styled.div`
  font-family: ${portalTheme.font.family.quicksand};
  font-weight: ${portalTheme.font.weight.regular};
  display: flex;

  ${(props) =>
    props.minimalVersion
      ? `
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          gap: 3px;
          padding:1px;
          height: 30px;
          transition: height 0.2s ease-in-out;
          &.hasItems {
            height: 50px;
            transition: height 0.2s ease-in-out;
          }
        `
      : `
          background-color: #fff;
          gap: 16px;
          align-items: center;
          justify-content: space-between;
          padding: 0 1rem;
          width: 72px;
          transition: width 0.2s ease-in-out;
          &.hasItems {
            width: 210px;
            transition: width 0.2s ease-in-out;
          }
        `}
  &:hover,
  &:focus {
    background-color: #dbe7f3;
  }

  ${(props) => props.isDropdownOpen && `background-color: #dbe7f3;`};

  // Styles from mockup
  //min-height: 50px;
  border-radius: 10px;
  font-size: 14px;
`

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;

  width: 335px;

  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
`

export const ProgressBarDropdown = styled(Dropdown)`
  position: relative;

  padding: 1.5rem;
`

export const LabelSeparator = styled.span`
  font-size: 14px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

export const DropdownItemWrapper = styled(EmptyButton)`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;

  background-color: #fff;
  font-size: 14px;

  padding: 1rem;

  &:hover,
  &:focus {
    background-color: #edf9fe;
  }

  ${(props) =>
    props.disabled &&
    'opacity: .25; background-color: white; cursor: not-allowed; '}
`

export const IconWithText = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;

  & > span {
    //word-break: break-all;
    text-align: start;
  }
`

export const MoreActionIcon = styled(Icon).attrs((props) => ({
  icon: 'more-action',
  size: props?.size || '40px',
  color: '#4ab4e2',
}))`
  ${Label}:hover &, ${Label}:focus & {
    color: #979797;
  }
`

// Downloads Modal styles
export const DownloadModalContainer = styled.div`
  position: absolute;
  z-index: 1001; // higher than header's 1000 z-index
  isolation: isolate;
`

export const DownloadModal = styled(SimpleModal)`
  border-radius: 10px;

  align-items: center;
`

export const TitleRow = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  gap: 8px;

  margin-top: -20px;
  margin-bottom: 32px;
`

export const CheckboxesRow = styled.div`
  display: flex;
  justify-content: space-between;

  width: 50%;

  &:nth-child(3) {
    margin: 8px 0;
  }
`

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  margin: 8px 0;

  &:last-child {
    margin-bottom: 0;
  }
`
