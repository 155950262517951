// Source: https://www.ag-grid.com/documentation/react/filter-provided-simple/
export const filterPaid = {
  status: {
    filterType: 'number',
    operator: 'AND',
    condition1: {
      filterType: 'number',
      type: 'equals',
      filter: 'PAID',
    },
  },
}

export const filterOpen = {
  status: {
    filterType: 'number',
    operator: 'AND',
    condition1: {
      filterType: 'number',
      type: 'equals',
      filter: 'OPEN',
    },
  },
}

export const filterReminded = {
  status: {
    filterType: 'number',
    operator: 'AND',
    condition1: {
      filterType: 'number',
      type: 'equals',
      filter: 'REMINDED',
    },
  },
}
