import React, { useState, forwardRef, useImperativeHandle } from 'react'
import GridEnumFilter from '../../../components/GridEnumFilter'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import { roomTypeEnumList } from '../../../components/AgGrid/enumLists'

// TODO make it work with full store grids
const map = {
  CORRIDOR: ['CORRIDOR'],
  BEDROOM: ['BEDROOM'],
  CHILDRENS_BEDROOM: ['CHILDRENS_BEDROOM'],
  GUESTROOM: ['GUESTROOM'],
  LIVINGROOM: ['LIVINGROOM'],
  OFFICE_WORKROOM: ['OFFICE_WORKROOM'],
  COMMON_SPACES: ['COMMON_SPACES'],
  KITCHEN: ['KITCHEN'],
  ROOM: ['ROOM'],
  WC_BATHROOM: ['WC_BATHROOM'],
  DININGROOM: ['DININGROOM'],
  COMMON_SPACES_BASEMENT: ['COMMON_SPACES_BASEMENT'],
  COMMON_SPACES_ATTIC: ['COMMON_SPACES_ATTIC'],
  WALK_THROUGH_ROOM: ['WALK_THROUGH_ROOM'],
  LIVINGROOM_BEDROOM: ['LIVINGROOM_BEDROOM'],
  LOGGIA: ['LOGGIA'],
}

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  return (
    <GridEnumFilter
      map={map}
      translateLabel={(value, t) => {
        return t(roomTypeEnumList(value))
      }}
      setFilterModel={setFilterModel}
    />
  )
})
