import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'

import { ButtonIcon, Icon, portalTheme, SimpleNote } from 'pyrexx-react-library'
import { Col, Container, Row } from 'react-grid-system'
import { LinkButtonStyled } from '../../styles/HelperStylesComponents.styles'

const Upload = ({ setImgSrc = () => {}, onClose = () => {} }) => {
  const { t } = useTranslation()

  const [uploadedImage, setUploadedImage] = useState(null)
  const [uploadImageError, setUploadImageError] = useState(null)

  const onDropAccepted = useCallback((acceptedFiles) => {
    setUploadedImage(acceptedFiles[0])
    setUploadImageError(null)

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        setImgSrc(reader.result)
        onClose()
      }

      reader.readAsDataURL(file)
    })
    // eslint-disable-next-line
  }, [])

  const onDropRejected = useCallback((rejectedFiles) => {
    console.log({ rejectedFiles })
    setUploadedImage(null)
    setUploadImageError(rejectedFiles[0].errors[0])
  }, [])

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: true,
    noKeyboard: true,
    maxSize: 7000000,
    maxFiles: 1,
    accept: '.jpg, .jpeg, .png',
  })

  return (
    <div>
      <h3 style={{ margin: '0 0 15px 0' }}>{t('PLEASE UPLOAD YOUR LOGO')}</h3>
      <p>{t('FILE EXTENSIONS: JPG, JPEG, PNG. MAXIMUM FILE SIZE 7MB')}</p>

      {uploadImageError?.message.length > 0 && (
        <SimpleNote text={t(uploadImageError?.message)} noteStatus='error' />
      )}

      {/* <Dropzone {...getRootProps()} className='dropzone'> */}
      {/*  <DropzoneInner isDragActive={isDragActive}> */}
      {/*    <input {...getInputProps()} /> */}
      {/*    <Icon icon='drag-and-drop' size='80px' color='#fff' /> */}
      {/*  </DropzoneInner> */}
      {/* </Dropzone> */}

      <div {...getRootProps()} className='dropzone'>
        <Container fluid style={{ position: 'relative' }}>
          <Row
            justify='between'
            style={{ border: '1px dotted #E9EDEE', borderRadius: '19px' }}
          >
            <StyledDropArea>
              <input {...getInputProps()} />
              <StyledDropAreaText>Drag & Drop</StyledDropAreaText>
              <span> </span>
              <Row
                justify='center'
                align='center'
                style={{
                  height: '100%',
                }}
              >
                <Col xs='content'>
                  <StyledIcon
                    icon='drag-and-drop'
                    size='60px'
                    color={portalTheme.color.linkColorPrimary}
                  />
                </Col>
              </Row>
            </StyledDropArea>

            <Col xs='content' style={{ width: '5px' }} />

            <StyledSelectFile>
              <LinkButtonStyled
                type='button'
                onClick={open}
                style={{
                  width: '100%',
                  height: '100%',
                  justifyContent: 'center',
                }}
              >
                {t('SELECT FILE')}
              </LinkButtonStyled>
            </StyledSelectFile>
          </Row>
        </Container>
      </div>

      {uploadedImage?.name.length > 0 && (
        <StyledSimpleNote
          noteStatus='success'
          text={
            <FilenameRow>
              <Icon icon='file' size='25' />
              <span>{uploadedImage?.name}</span>
              <DeleteImageButton
                icon='close'
                size='15'
                handleClick={() => {
                  setImgSrc(null)
                  setUploadedImage(null)
                }}
              />
            </FilenameRow>
          }
        />
      )}
    </div>
  )
}

const StyledSimpleNote = styled(SimpleNote)`
  margin-top: 1rem;
`

const FilenameRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem 1rem;
  padding-left: 0;
`

const DeleteImageButton = styled(ButtonIcon)`
  margin-left: 0.5rem;
  color: var(--pyrexx-red, red);
`

const StyledDropArea = styled(Col)`
  background-color: #e9edee;
  height: 120px;
  border-radius: 19px;
  z-index: 1;
`

const StyledSelectFile = styled(Col)`
  height: 120px;
  width: 100%;
  border-radius: 19px;

  &:hover,
  &:visited,
  &:focus {
    background-color: ${portalTheme.color.basicColorLightGrey2};
    cursor: pointer;
    outline: 0;
    text-decoration: none;
  }
`

const StyledDropAreaText = styled.span`
  position: absolute;
  left: 2rem;
  top: 2rem;

  font-family: ${portalTheme.font.family.quicksand};
  font-weight: 500;
  font-size: 14px;
  color: #d8d8d8;
`

const StyledIcon = styled(Icon)`
  color: #d8d8d8;
`

export default Upload
