import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ButtonPrimary, SimpleNote } from 'pyrexx-react-library'
import Spacer from '../../../components/Spacer'
import NotificationSettingsList from './NotificationSettingsList'
import Pills from './Pills'
import NewsletterCheckbox from './NewsletterCheckbox'
import NoEmailModal from './NoEmailModal'
import {
  ButtonRow,
  SimpleNoteWrapper,
  HeaderRow,
  Header,
} from './UserNotificationSettings.styles'

import handleLocalStorage from '../../../helper/handleLocalStorage'
import { Routes } from '../../../configs/RouteConfig'
import { useUserContext } from '../../../helper/userContext'
import useUpdateEffect from '../../../hooks/useUpdateEffect'

const Content = styled.div`
  position: relative;
`

const WarningWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  z-index: 10;

  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.85);
`

const initialNotificationsState = {
  inventory: {
    n1: 'i',
    n2: 'i',
    n3: 'i',
  },
  appointments: {
    n1: 'i',
    n2: 'i',
    n3: 'i',
  },
  invoices: {
    n1: 'i',
    n2: 'i',
    n3: 'i',
  },
}

const initialOptionsState = {
  notificationsByEmail: false,
  newsletter: false,
}

const notificationsData = {
  inventory: [
    {
      id: 'n1',
      label: 'Inventory Notification 1',
    },
    {
      id: 'n2',
      label: 'Inventory Notification 2',
    },
    {
      id: 'n3',
      label: 'Inventory Notification 3',
    },
  ],
  appointments: [
    {
      id: 'n1',
      label: 'Appointments Notification 1',
    },
    {
      id: 'n2',
      label: 'Appointments Notification 2',
    },
    {
      id: 'n3',
      label: 'Appointments Notification 3',
    },
  ],
  invoices: [
    {
      id: 'n1',
      label: 'Invoices Notification 1',
    },
    {
      id: 'n2',
      label: 'Invoices Notification 2',
    },
    {
      id: 'n3',
      label: 'Invoices Notification 3',
    },
  ],
}

const UserNotificationSettings = ({
  email,
  waitingForEmailConfirmation,
  setWaitingForEmailConfirmation,
}) => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()
  const history = useHistory()

  const [notifications, setNotifications] = useState(
    () =>
      handleLocalStorage('get', 'notifications') || initialNotificationsState
  )

  const handleNotifications = (id, nid, value) => {
    setNotifications((prev) => {
      return {
        ...prev,
        [id]: {
          ...prev[id],
          [nid]: value,
        },
      }
    })
  }

  const [options, setOptions] = useState(
    () => handleLocalStorage('get', 'options') || initialOptionsState
  )

  const toggleOption = (id) => {
    setOptions((prev) => {
      return {
        ...prev,
        [id]: !prev[id],
      }
    })
  }

  const handleSave = () => {
    setChangesMade(false)
    handleLocalStorage('set', 'notifications', notifications)
    handleLocalStorage('set', 'options', options)
  }

  const [changesMade, setChangesMade] = useState(false)

  useUpdateEffect(() => {
    setChangesMade(true)
  }, [notifications, options])

  const pills = [
    {
      key: 'inventory',
      label: t('PORTFOLIO'),
      content: (
        <NotificationSettingsList
          key='inventory'
          id='inventory'
          notificationsData={notificationsData?.inventory}
          category={notifications?.inventory}
          handleNotifications={handleNotifications}
        />
      ),
    },
    {
      key: 'appointments',
      label: t('APPOINTMENTS'),
      content: (
        <NotificationSettingsList
          key='appointments'
          id='appointments'
          notificationsData={notificationsData?.appointments}
          category={notifications?.appointments}
          handleNotifications={handleNotifications}
        />
      ),
    },
    {
      key: 'invoices',
      label: t('INVOICES'),
      content: (
        <NotificationSettingsList
          key='invoices'
          id='invoices'
          notificationsData={notificationsData?.invoices}
          category={notifications?.invoices}
          handleNotifications={handleNotifications}
        />
      ),
    },
  ]

  const [modal, setModal] = useState(false)
  const closeModal = () => {
    setModal(false)
  }

  const toggleModal = (option) => {
    if (options[option] === false && email.length <= 0) {
      setModal(true)
    } else {
      toggleOption(option)
    }
  }

  const modalAction = () => {
    // toggleOption(option)
    history.push('/' + currentLanguage + Routes.editEmail)
  }

  const showEmailWarning = email.length <= 0 || waitingForEmailConfirmation

  return (
    <div>
      <HeaderRow>
        <Header>{t('EMAIL SUBSCRIPTION')}</Header>
      </HeaderRow>

      <Content>
        {showEmailWarning && (
          <WarningWrapper>
            <SimpleNote
              text={t('PLEASE SPECIFY YOUR EMAIL ADDRESS IN YOUR PROFILE')}
              noteStatus='warning'
            />
          </WarningWrapper>
        )}

        <Pills pills={pills} />

        <Spacer />
        <NewsletterCheckbox options={options} toggleModal={toggleModal} />

        {/* <Spacer /> */}
        {/* <ErrorNoteRow> */}
        {/*  <SimpleNote */}
        {/*    noteStatus='error' */}
        {/*    text={t('AT LEAST ONE PERSON NEEDS TO RECEIVE THIS MESSAGE.')} */}
        {/*  /> */}
        {/* </ErrorNoteRow> */}

        <Spacer />
        <ButtonRow>
          <ButtonPrimary onClick={handleSave}>
            {t('SAVE CHANGES')}
          </ButtonPrimary>

          {changesMade && (
            <SimpleNoteWrapper>
              <SimpleNote
                noteStatus='warning'
                text={t('CHANGES HAVE BEEN MADE. PLEASE SAVE THEM.')}
              />
            </SimpleNoteWrapper>
          )}
        </ButtonRow>

        <Spacer />
        <NoEmailModal
          modal={modal}
          modalAction={modalAction}
          closeModal={closeModal}
        />
      </Content>
    </div>
  )
}

UserNotificationSettings.propTypes = {}

export default UserNotificationSettings
