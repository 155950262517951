import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { CountRenderedServicesQuery, InventoryGridQuery } from '../queries'
import { dateTimeValueGetter, roomTypeValueGetter } from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { RoomTypeRenderer } from '../renderers'
import { DateRenderer } from '../../../components/AgGrid/renderers'
import { RoomTypeFilter } from '../filters'

export const useInventoryGrid = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { usageUnitId } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'position.room.type':
            return t(roomTypeValueGetter(params))
          case 'installationDate':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'Pp',
            })
          default:
            return params.value
        }
      }
      const InventoryColumnDefs = [
        columnCheckbox,
        {
          headerName: t('ROOM'),
          field: 'position.room.type',
          width: 100,
          // filter: 'RoomTypeFilter',
          filter: false, // TODO enumFilter dont work because of FullServerSideStore ?
          cellRenderer: RoomTypeRenderer,
        },
        {
          headerName: t('PRODUCT'),
          field: 'product.name',
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('QUANTITY'),
          field: 'quantity',
          width: 70,
          sortable: false,
          filter: false,
          type: 'rightAligned',
        },
        {
          headerName: t('INSTALLATION DATE'),
          field: 'installationDate',
          width: 80,
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'Pp',
          },
        },
      ]
      const InventoryCustomFilter = []
      return {
        query: InventoryGridQuery,
        columnDefs: InventoryColumnDefs,
        customFilters: InventoryCustomFilter,
        countQuery: CountRenderedServicesQuery,
        extractor: (response) => response.Me?.Company?.UsageUnit?.data,
        customGridOptions: {
          serverSideInfiniteScroll: false,
          getContextMenuItems: (params) => {
            return ['copy']
          },
          components: {
            RoomTypeFilter,
          },
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { usageUnitId },
      }
    },
    [currentLanguage, t]
  )
  return { getParams }
}
