/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type LockReasonType = "CONTRACT_CANCELLED" | "CONTRACT_EXPIRED" | "CUSTOMER_TEMP_DEFERRED_INSTALLATION" | "CUSTOMER_WISH" | "DECENTRAL_WARM_WATER" | "HIGH_CEILING" | "LOCKED_TIL_INDIVIDUAL_ORDER" | "MODERNIZATION" | "NONE" | "OWN_SMOKE_DETECTOR" | "SMOKE_DETECTORS_IN_SAFE_KEEPING" | "SMOKE_DETECTORS_NOT_USEFUL" | "SYSTEM" | "TEMP_FOR_CLEARIFICATION" | "TEMP_FOR_RENOVATION" | "TENANT_REJECTED_SMOKE_DETECTORS" | "UNEXPECTED_BUSINESS_UNIT" | "UNIT_DEMOLITION" | "UNIT_DOESNT_EXIST" | "UNIT_SOLD" | "UNKNOWN" | "%future added value";
export type LockStatusType = "ACTIVE" | "DEACTIVATED" | "NONE" | "PENDING" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type UsageUnitTypeType = "BUSINESS" | "COMMON" | "NONE" | "PRIVATE" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type UsageUnitsStatusHistoryGridQueryVariables = {|
  companyId: string,
  propertyUnitId: string,
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
|};
export type UsageUnitsStatusHistoryGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +HouseEntrance: ?{|
        +id: string,
        +tableId: number,
        +data: $ReadOnlyArray<?{|
          +id: string,
          +referenceNumber: string,
          +type: UsageUnitTypeType,
          +lockPeriods: $ReadOnlyArray<?{|
            +id: string,
            +status: LockStatusType,
            +begin: ?any,
            +reason: LockReasonType,
            +initiator: string,
            +serviceCategory: ?{|
              +id: string,
              +tableId: number,
              +name: string,
            |},
          |}>,
          +tenants: $ReadOnlyArray<?{|
            +id: ?string,
            +tableId: ?number,
            +firstname: string,
            +lastname: string,
          |}>,
          +CAN: ?{|
            +one: string,
            +two: string,
            +three: string,
            +four: string,
            +five: string,
          |},
        |}>,
      |}
    |}
  |}
|};
export type UsageUnitsStatusHistoryGridQuery = {|
  variables: UsageUnitsStatusHistoryGridQueryVariables,
  response: UsageUnitsStatusHistoryGridQueryResponse,
|};
*/


/*
query UsageUnitsStatusHistoryGridQuery(
  $companyId: ID!
  $propertyUnitId: ID!
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
) {
  Me {
    Company(id: $companyId) {
      HouseEntrance(id: $propertyUnitId) {
        id
        tableId
        data: usageUnits {
          id
          referenceNumber
          type
          lockPeriods(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
            id
            status
            begin
            reason
            initiator
            serviceCategory {
              id
              tableId
              name
            }
          }
          tenants {
            id
            tableId
            firstname
            lastname
          }
          CAN {
            one
            two
            three
            four
            five
          }
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "propertyUnitId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "propertyUnitId"
    }
  ],
  "concreteType": "HouseEntrance",
  "kind": "LinkedField",
  "name": "HouseEntrance",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v8/*: any*/),
    {
      "alias": "data",
      "args": null,
      "concreteType": "UsageUnit",
      "kind": "LinkedField",
      "name": "usageUnits",
      "plural": true,
      "selections": [
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "referenceNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "endRow",
                  "variableName": "endRow"
                },
                {
                  "kind": "Variable",
                  "name": "filters",
                  "variableName": "filters"
                },
                {
                  "kind": "Variable",
                  "name": "sorting",
                  "variableName": "sortModel"
                },
                {
                  "kind": "Variable",
                  "name": "startRow",
                  "variableName": "startRow"
                }
              ],
              "kind": "ObjectValue",
              "name": "options"
            }
          ],
          "concreteType": "LockPeriod",
          "kind": "LinkedField",
          "name": "lockPeriods",
          "plural": true,
          "selections": [
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "begin",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reason",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "initiator",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ServiceCategory",
              "kind": "LinkedField",
              "name": "serviceCategory",
              "plural": false,
              "selections": [
                (v7/*: any*/),
                (v8/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "tenants",
          "plural": true,
          "selections": [
            (v7/*: any*/),
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastname",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerAllocationNumber",
          "kind": "LinkedField",
          "name": "CAN",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "one",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "two",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "three",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "four",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "five",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UsageUnitsStatusHistoryGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "UsageUnitsStatusHistoryGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f2ad9d639504a4232c12e200ab5b120",
    "id": null,
    "metadata": {},
    "name": "UsageUnitsStatusHistoryGridQuery",
    "operationKind": "query",
    "text": "query UsageUnitsStatusHistoryGridQuery(\n  $companyId: ID!\n  $propertyUnitId: ID!\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n) {\n  Me {\n    Company(id: $companyId) {\n      HouseEntrance(id: $propertyUnitId) {\n        id\n        tableId\n        data: usageUnits {\n          id\n          referenceNumber\n          type\n          lockPeriods(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n            id\n            status\n            begin\n            reason\n            initiator\n            serviceCategory {\n              id\n              tableId\n              name\n            }\n          }\n          tenants {\n            id\n            tableId\n            firstname\n            lastname\n          }\n          CAN {\n            one\n            two\n            three\n            four\n            five\n          }\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7be05365b8a0073d6b7919ac4018cb00';

module.exports = node;
