/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FetchContactDepartmentsQueryVariables = {||};
export type FetchContactDepartmentsQueryResponse = {|
  +Config: ?{|
    +contactDepartments: $ReadOnlyArray<?{|
      +value: string,
      +label: string,
    |}>
  |}
|};
export type FetchContactDepartmentsQuery = {|
  variables: FetchContactDepartmentsQueryVariables,
  response: FetchContactDepartmentsQueryResponse,
|};
*/


/*
query FetchContactDepartmentsQuery {
  Config {
    contactDepartments {
      value
      label
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Config",
    "kind": "LinkedField",
    "name": "Config",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Option",
        "kind": "LinkedField",
        "name": "contactDepartments",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "label",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FetchContactDepartmentsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FetchContactDepartmentsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "9d70c544c17c8bbce007cf200b820370",
    "id": null,
    "metadata": {},
    "name": "FetchContactDepartmentsQuery",
    "operationKind": "query",
    "text": "query FetchContactDepartmentsQuery {\n  Config {\n    contactDepartments {\n      value\n      label\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '37abe7ba1a097d30b9481c0b9bbfec6a';

module.exports = node;
