import React from 'react'

import * as S from './styles'

const Dropleft = ({ children, ...rest }) => {
  return (
    <S.DropleftContainer {...rest}>
      <S.Dropleft>{children}</S.Dropleft>
    </S.DropleftContainer>
  )
}

export default Dropleft
