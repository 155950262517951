/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteAttachmentMutationVariables = {|
  companyId: string,
  fileId: string,
|};
export type DeleteAttachmentMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +deleteLetterAttachment: ?boolean
      |}
    |}
  |}
|};
export type DeleteAttachmentMutation = {|
  variables: DeleteAttachmentMutationVariables,
  response: DeleteAttachmentMutationResponse,
|};
*/


/*
mutation DeleteAttachmentMutation(
  $companyId: ID!
  $fileId: ID!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        deleteLetterAttachment(letterAttachmentId: $fileId)
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "fileId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessPostMutation",
            "kind": "LinkedField",
            "name": "businessPost",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "letterAttachmentId",
                    "variableName": "fileId"
                  }
                ],
                "kind": "ScalarField",
                "name": "deleteLetterAttachment",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteAttachmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteAttachmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9cdce1df50a077c0cbc642743a6965fa",
    "id": null,
    "metadata": {},
    "name": "DeleteAttachmentMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteAttachmentMutation(\n  $companyId: ID!\n  $fileId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        deleteLetterAttachment(letterAttachmentId: $fileId)\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '112f61cf8ad73f327366b706675be898';

module.exports = node;
