/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DeleteFilterMutationVariables = {|
  gridId: string,
  companyId: string,
|};
export type DeleteFilterMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +GridFilter: ?{|
        +delete: ?boolean
      |}
    |}
  |}
|};
export type DeleteFilterMutation = {|
  variables: DeleteFilterMutationVariables,
  response: DeleteFilterMutationResponse,
|};
*/


/*
mutation DeleteFilterMutation(
  $gridId: ID!
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      GridFilter(id: $gridId) {
        delete
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gridId"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "gridId"
              }
            ],
            "concreteType": "GridFilterMutations",
            "kind": "LinkedField",
            "name": "GridFilter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "delete",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteFilterMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteFilterMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0200643c1e7a53115342552b7c2a39c2",
    "id": null,
    "metadata": {},
    "name": "DeleteFilterMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteFilterMutation(\n  $gridId: ID!\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      GridFilter(id: $gridId) {\n        delete\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b8f7a63f9b571231841fad8f88a9fb87';

module.exports = node;
