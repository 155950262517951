import styled from 'styled-components/macro'

const BulletedList = styled.ul`
  padding-left: 16px;
  padding: 0;
  margin: 0;

  & > li:not(:last-child) {
    padding-bottom: 8px;
  }
`

export default BulletedList
