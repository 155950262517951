import React, { useContext } from 'react'

import SimpleLink from '../../../components/Simple/SimpleLink'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'
import handleLocalStorage from '../../../helper/handleLocalStorage'

const AppointmentUsageUnitReferenceNumberLinkRenderer = (props) => {
  const { referenceNumber, usageUnit } = props.data

  const { currentLanguage } = useContext(UserContext)

  // TODO improve
  const saveOrigin = () => {
    handleLocalStorage('set', 'usageUnitOrigin', 'appointment-history')
  }

  return (
    <SimpleLink
      to={
        '/' +
        currentLanguage +
        Routes.usageUnit +
        '/' +
        usageUnit?.tableId +
        '?gridId=appointment-history'
      }
      style={{ fontSize: 'inherit' }}
      onClick={saveOrigin}
    >
      {referenceNumber}
    </SimpleLink>
  )
}

export default AppointmentUsageUnitReferenceNumberLinkRenderer
