import { fetchQuery } from 'react-relay'

import FilterModel from './FilterModel'
import generateEnvironment from '../../utils/generateEnvironment'
import DownloadDatasource from './DownloadDataSource'

export default class ServerSideDatasource {
  /**
   * @callback extractorCallback
   * @callback groupingHandlerCallback
   */

  /**
   * @param {Object} query
   * @param {extractorCallback} extractor
   * @param {Object} user
   * @param {Object} variables
   * @param {Array} customFilters
   * @param {Object} countQuery
   * @param {extractorCallback} [countExtractor]
   * @param {groupingHandlerCallback} groupingHandler
   */
  constructor(
    query,
    extractor,
    user,
    variables = {},
    customFilters = [],
    countQuery,
    countExtractor,
    groupingHandler,
    forceRefresh = false
  ) {
    this.query = query
    this.extractor = extractor
    this.user = user
    this.countQuery = countQuery
    this.countExtractor = countExtractor || extractor
    this.groupingHandler = groupingHandler
    this.setVariables({ variables }).setCustomFilters({ customFilters })
    this.forceRefresh = forceRefresh
  }

  getRows(params) {
    const RemoveDefaultCustomFilterWithSameUserFilters = (
      userFilters,
      defaultCustomFilters
    ) => {
      let newDefaultCustomFilters = defaultCustomFilters
      userFilters.forEach((singleFilter, index) => {
        const sameFilter = defaultCustomFilters.find(
          (element) => element.name === singleFilter.name
        )
        if (sameFilter) {
          newDefaultCustomFilters = newDefaultCustomFilters.filter(
            (element) => element.name !== singleFilter.name
          )
        }
      })
      return newDefaultCustomFilters
    }
    let { groupKeys = [], rowGroupCols = [] } = params.request
    const {
      startRow = 0,
      endRow = 0,
      sortModel = {},
      filterModel = {},
      companyId = this.user.companyId,
    } = params.request
    const pageSize = endRow - startRow
    const filters = FilterModel(filterModel)
    const customFilters = this.customFilters || []

    const newDefaultCustomFilters =
      RemoveDefaultCustomFilterWithSameUserFilters(filters, customFilters)

    if (this.groupingHandler) {
      const res = this.groupingHandler(groupKeys, rowGroupCols, params)
      rowGroupCols = res.rowGroupCols
      groupKeys = res.groupKeys
    }
    fetchQuery(
      generateEnvironment(process.env.REACT_APP_API_URL, this.user.accessToken),
      this.query,
      {
        startRow,
        endRow,
        sortModel,
        filters: [...filters, ...newDefaultCustomFilters],
        groupKeys: groupKeys.map((value) => String(value)),
        rowGroupCols,
        companyId,
        ...this.variables,
      },
      {
        force: false,
      }
    )
      .then(this.extractor)
      .then((rows) => {
        params.successCallback(
          rows,
          rows.length <= pageSize ? params.request.startRow + rows.length : -1
        )
      })
      .catch((error) => {
        console.log('error in grid request', error.message, error.source)
        params.fail()
      })
  }

  getDownloadDataSource(
    loaded,
    setDownloadLoad,
    withPdfDownload,
    chunkSize,
    startRow,
    maxRowsPerFile
  ) {
    return new DownloadDatasource(
      this,
      this.countQuery,
      this.countExtractor,
      loaded,
      setDownloadLoad,
      withPdfDownload,
      chunkSize,
      startRow,
      maxRowsPerFile
    )
  }

  setVariables({ variables = {} }) {
    this.variables = {}
    for (const attr in variables) {
      this.variables[attr] = String(variables[attr])
    }
    return this
  }

  setCustomFilters({ customFilters = [] }) {
    for (let i = customFilters.length - 1; i >= 0; i--) {
      for (let ii = customFilters[i].conditions.length - 1; ii >= 0; ii--) {
        if (typeof customFilters[i].conditions[ii].value.map !== 'undefined') {
          customFilters[i].conditions[ii].value = customFilters[i].conditions[
            ii
          ].value?.map((value) => {
            return String(value)
          })
        } else {
          customFilters[i].conditions[ii].value = String(
            customFilters[i].conditions[ii].value
          )
        }
      }
    }
    this.customFilters = customFilters
    return this
  }
}
