import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation ResetPasswordMutation($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password)
  }
`

export default (token, password, accessToken, callBack, errorCallBack) => {
  const variables = {
    token,
    password,
  }

  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
