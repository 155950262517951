import React from 'react'
import styled from 'styled-components/macro'

import AppointmentsCard from './AppointmentsCard'
import ContractsCard from './ContractsCard'
import InvoicesCard from './InvoicesCard'
import PropertyManagementCard from './PropertyManagementCard'

import { useUserContext } from '../../helper/userContext'
import isPermissionIncluded from '../../helper/isPermissionIncluded'
import {
  APPOINTMENTS_PERMISSIONS,
  CONTRACTS_PERMISSIONS,
  INVOICES_PERMISSIONS,
  PROPERTY_MANAGEMENT_PERMISSIONS,
} from '../../constants/permissions'

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em 1em;

  ${({ childrenCount }) => childrenCount <= 2 && `grid-template-columns: 1fr`};

  @media screen and(max-width: 765px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }

  // Add IE11 support
  @media all and (-ms-high-contrast: none) {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr 1fr;
    gap: 1em 1em;

    & > div:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-row: 1;
    }

    & > div:nth-child(2) {
      -ms-grid-column: 2;
      -ms-grid-row: 1;
    }

    & > div:nth-child(3) {
      -ms-grid-column: 1;
      -ms-grid-row: 2;
    }

    & > div:nth-child(4) {
      -ms-grid-column: 2;
      -ms-grid-row: 2;
    }

    & > div:nth-child(5) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    & > div:nth-child(6) {
      -ms-grid-column: 2;
      -ms-grid-row: 3;
    }
  }
`

const ServiceCards = () => {
  const { user } = useUserContext()

  const permissions = user.userPermissions || []

  const showInvoicesCard = isPermissionIncluded(
    permissions,
    INVOICES_PERMISSIONS
  )
  const showContractsCard = isPermissionIncluded(
    permissions,
    CONTRACTS_PERMISSIONS
  )
  const showPropertyManagementCard = isPermissionIncluded(
    permissions,
    PROPERTY_MANAGEMENT_PERMISSIONS
  )
  const showAppointmentsCard = isPermissionIncluded(
    permissions,
    APPOINTMENTS_PERMISSIONS
  )

  const childrenCount =
    showInvoicesCard +
    showContractsCard +
    showPropertyManagementCard +
    showAppointmentsCard

  return (
    <GridContainer childrenCount={childrenCount}>
      <InvoicesCard isHidden={!showInvoicesCard} />
      <ContractsCard isHidden={!showContractsCard} />
      <PropertyManagementCard isHidden={!showPropertyManagementCard} />
      <AppointmentsCard isHidden={!showAppointmentsCard} />
    </GridContainer>
  )
}

export default ServiceCards
