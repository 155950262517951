import React, { useContext } from 'react'
import quotaOverviewQuery from './queries/quotaOverviewQuery'
import QueryRendererContainer from '../../../components/QueryRendererContainer'
import QuotaOverviewTable from './QuotaOverviewTable'
import UserContext from '../../../helper/userContext'

export default (props) => {
  const { user } = useContext(UserContext)

  const variables = {
    companyId: user.companyId,
    rowGroupCols: [
      {
        id: 'quota',
        field: 'quota',
      },
    ],
  }

  return (
    <QueryRendererContainer
      query={quotaOverviewQuery}
      loading={<QuotaOverviewTable loading />}
      variables={variables}
      render={(data) => {
        const quotasInfo = data?.Me?.Company?.Quotas

        return <QuotaOverviewTable quotasInfo={quotasInfo} />
      }}
    />
  )
}
