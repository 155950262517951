import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation AddNewsletterSubscription($newsletterSubscriptionInfo: NewsletterSubscriptionInfo!) {
    addNewsletterSubscription(newsletterSubscriptionInfo: $newsletterSubscriptionInfo) {
      success
    }
  }
`

export default (newsletterSubscriptionInfo, callBack, errorCallBack) => {
  const variables = {
    newsletterSubscriptionInfo: newsletterSubscriptionInfo
  }

  commitMutation(environment, {
    mutation,
    variables,
    onCompleted: (requestData) => {
      callBack(requestData)
    },
    onError: (err) => errorCallBack(err)
  })
}
