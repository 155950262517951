const ONE_TIME_GRAFFITI = 'ONE_TIME_GRAFFITI' // hardcode ONE_TIME_GRAFFITI ENUM

export default (contracts = []) => {
  const combinedIds = []
  let specialContract
  let count = 0

  return contracts
    .map((contract) => ({
      id: contract?.id,
      tableId: contract?.tableId,
      name: contract?.name,
      type: contract?.type || '',
    }))
    .filter(
      (contract, idx, self) =>
        idx ===
        self.findIndex(
          (s) =>
            s?.tableId === contract?.tableId &&
            contract?.tableId != null &&
            contract?.tableId.length !== 0
        ) // either id or tableId depending on the query
    )
    .reduce((prev, acc) => {
      if (acc?.type === ONE_TIME_GRAFFITI) {
        if (specialContract == null) {
          specialContract = acc
        }
        combinedIds.push(acc?.tableId)
      } else {
        return [...prev, acc]
      }

      count = count + 1

      if (count === 1) {
        return [...prev, { ...specialContract, specialCase: true, combinedIds }]
      } else {
        return prev
      }
    }, [])
}
