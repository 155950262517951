import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query UsageUnitsStatusHistoryGridQuery(
    $companyId: ID!
    $propertyUnitId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        HouseEntrance(id: $propertyUnitId) {
          id
          tableId
          data: usageUnits {
            id
            referenceNumber
            type
            lockPeriods(
              options: {
                startRow: $startRow
                endRow: $endRow
                sorting: $sortModel
                filters: $filters
              }
            ) {
              id
              status
              begin
              reason
              initiator
              serviceCategory {
                id
                tableId
                name
              }
            }
            tenants {
              id
              tableId
              firstname
              lastname
            }
            CAN {
              one
              two
              three
              four
              five
            }
          }
        }
      }
    }
  }
`
