import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import graphql from 'babel-plugin-relay/macro'
import * as yup from 'yup'

import { ButtonPrimary } from 'pyrexx-react-library'
import PageContainer from '../../../components/PageContainer'
import NavBack from '../../../components/NavBack'
import UserInfoInput from './UserInfoInput'
import PermissionsInput from './PermissionsInput'
import BillingPartitionsGrid from './grids/BillingPartitionsGrid'
import ContractsGrid from './grids/ContractsGrid'
import ErrorModal from './components/ErrorModal'
import QueryRendererContainer from '../../../components/QueryRendererContainer'

import CreateUserMutation from '../../../mutations/CreateUserMutation'
import { useUserContext } from '../../../helper/userContext'
import getBillingPartitions from './helpers/getBillingPartitions'
import { Routes } from '../../../configs/RouteConfig'
import { ButtonRow, Wrapper } from './styles'

const PureCreateUser = (props) => {
  const {
    availablePermissions = [],
    availableContracts = [],
    availableBillingPartitions = [],
    condition = {},
    loading = false,
  } = props
  const { user, currentLanguage } = useUserContext()
  const { t } = useTranslation()
  const history = useHistory()

  const [newUser, setNewUser] = useState({
    firstname: '',
    lastname: '',
    email: '',
    username: '',
    password: '',
    permissions: [],
    contracts: [],
    billingPartitions: [],
  })
  const [errorModal, setErrorModal] = useState({ show: false, message: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [emailError, setEmailError] = useState('')

  const closeErrorModal = () => setErrorModal({ show: false, message: '' })

  const schema = yup.object().shape({
    email: yup.string().email(t('INVALID EMAIL')),
  })

  const handleUserChange = (key, e) => {
    const value = e.target.value
    setNewUser((prevState) => ({ ...prevState, [key]: value }))
  }

  const handleArrayChange = (e, key, option) => {
    const checked = e.target.checked

    let newData
    if (checked) {
      newData = [...newUser[key], option]
    } else {
      newData = newUser[key].filter((o) => o !== option)
    }
    setNewUser((prevState) => ({ ...prevState, [key]: newData }))
  }

  const handleSelectionChange = (key, rowData = [], selectedIds = []) => {
    setNewUser((prevState) => ({ ...prevState, [key]: selectedIds }))
  }

  const createUser = () => {
    setIsLoading(true)
    setErrorModal({ show: false, message: '' })
    setEmailError('')

    schema
      .validate({
        email: newUser.email,
      })
      .then(({ email: validatedEmail }) => {
        CreateUserMutation(
          {
            companyId: user.companyId,
            firstname: newUser.firstname,
            lastname: newUser.lastname,
            email: validatedEmail,
            username: newUser.username,
            password: newUser.password,
            permissions: newUser.permissions,
            contracts: newUser.contracts,
            billingPartitions: newUser.billingPartitions,
          },
          user.accessToken,
          (data) => {
            console.log('success', newUser)
            setIsLoading(false)
            history.push('/' + currentLanguage + Routes.admin)
          },
          (error) => {
            setIsLoading(false)
            setErrorModal({
              show: true,
              message: 'THERE IS AN ERROR WITH THE MUTATION',
            })
            console.log(error.source.errors[0].extensions)
          }
        )
      })
      .catch((error) => {
        setIsLoading(false)
        setEmailError(error.errors[0])
        setErrorModal({
          show: true,
          message: 'THERE IS AN ERROR WITH THE FIELDS',
        })
      })
  }

  return (
    <PageContainer>
      <NavBack
        title={t('ADD USER')}
        to={'/' + currentLanguage + Routes.admin}
      />

      <Wrapper>
        <UserInfoInput
          page='create-user'
          newUser={newUser}
          handleUserChange={handleUserChange}
          emailError={emailError}
        />

        <PermissionsInput
          permissions={newUser.permissions}
          handleArrayChange={handleArrayChange}
          availablePermissions={availablePermissions}
          loading={loading}
        />

        <ContractsGrid
          rowData={availableContracts}
          selectedData={newUser.billingPartitions}
          handleSelectionChange={handleSelectionChange}
        />

        <BillingPartitionsGrid
          rowData={availableBillingPartitions}
          selectedData={newUser.contracts}
          handleSelectionChange={handleSelectionChange}
          condition={condition}
        />
      </Wrapper>

      {loading ? null : (
        <ButtonRow>
          <ButtonPrimary isLoading={isLoading} onClick={createUser}>
            {t('ADD USER')}
          </ButtonPrimary>
        </ButtonRow>
      )}

      <ErrorModal modal={errorModal} closeModal={closeErrorModal} />
    </PageContainer>
  )
}

const query = graphql`
  query CreateUserQuery($companyId: ID!) {
    Me {
      Company(id: $companyId) {
        availablePermissions: permissions
        contracts: Contracts {
          tableId
          name
          billingPartitions {
            tableId
            name
          }
        }
      }
    }
  }
`

const CreateUser = () => {
  const { user } = useUserContext()

  return (
    <QueryRendererContainer
      query={query}
      variables={{ companyId: user.companyId }}
      loading={<PureCreateUser loading />}
      render={(data) => {
        const { availablePermissions, contracts } = data.Me.Company

        const availableContracts = contracts.map((c) => ({
          tableId: c.tableId,
          name: c.name,
          billingPartitions: c.billingPartitions,
        }))
        const availableBillingPartitions = getBillingPartitions(contracts)

        return (
          <PureCreateUser
            availablePermissions={availablePermissions}
            availableContracts={availableContracts}
            availableBillingPartitions={availableBillingPartitions}
          />
        )
      }}
    />
  )
}

export default CreateUser
