import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import StatusLight from './StatusLight'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 0.5rem;
`

const StatusLightWithLabel = (props) => {
  const { status = '', label = '', ...restOfProps } = props

  return (
    <Wrapper data-status=''>
      {status && <StatusLight status={status} {...restOfProps} />}
      <span data-status-light-label=''>{label}</span>
    </Wrapper>
  )
}

StatusLightWithLabel.propTypes = {
  status: PropTypes.any,
  label: PropTypes.any,
}

export default StatusLightWithLabel
