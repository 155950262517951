import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import DownloadCenterGrid from './DownloadCenterGrid'

import { useUserContext } from '../../../helper/userContext'

async function fetchData() {
  const res = await fetch(process.env.REACT_APP_WP_MEDIA_ENDPOINT, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
  const json = res.json()
  return json
}

const LANG_MAP = {
  en: 'downloads-en',
  de: 'downloads-de',
}

const DownloadCenter = () => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  const { data = [] } = useQuery('download-center', fetchData, {
    refetchOnWindowFocus: false,
  })

  const rows = data
    .map((r) => ({
      id: r.id,
      title: r.title.rendered,
      slug: r.slug,
      source_url: r.source_url,
      mime_type: r.mime_type,
      pyrexx_media_category: r.pyrexx_media_category,
    }))
    .filter((r) => r.pyrexx_media_category === LANG_MAP[currentLanguage])

  return (
    <>
      <h3>{t('DOWNLOAD GRID')}</h3>

      <DownloadCenterGrid rowData={rows} />
    </>
  )
}

export default DownloadCenter
