import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import Tabs from '../../components/Tabs'
import UserListGrid from './UserList/UserListGrid'
import RoleInformation from './RoleInformation/RoleInformation'
import LogoUpload from './LogoUpload/LogoUpload'

import useUrlRedirection from '../../hooks/useUrlRedirection'
import CompanyLayouts from '../CompanyLayouts/CompanyLayouts'

const queryClient = new QueryClient()

const Admin = ({ pageSection = '' }) => {
  const { t } = useTranslation()
  const urlRedirectionCallback = useUrlRedirection()

  const tabs = [
    {
      key: 'user-list',
      label: t('USER LIST'),
      content: <UserListGrid />,
    },
    {
      key: 'role-information',
      label: t('ROLE INFORMATION'),
      content: <RoleInformation />,
    },
    {
      key: 'logo-upload',
      label: t('LOGO UPLOAD'),
      content: <LogoUpload />,
    },
    {
      key: 'companyLayouts',
      label: t('COMPANY LAYOUTS'),
      content: <CompanyLayouts />,
    },
  ]

  return (
    <QueryClientProvider client={queryClient}>
      <PageContainer>
        <PageTitle>{t('ADMINISTRATION')}</PageTitle>
        <Tabs
          tabs={tabs}
          pageSection={pageSection}
          urlRedirectionCallback={urlRedirectionCallback}
        />
      </PageContainer>
    </QueryClientProvider>
  )
}

Admin.propTypes = {
  pageSection: PropTypes.string,
}

export default Admin
