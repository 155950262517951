export const initialState = {
  showComponent: false,
  message: '',
  status: 'warning',
  loading: false,
}

export const SET_LOADER = 'SET_LOADER'
export const CLOSE_COMPONENT = 'CLOSE_COMPONENT'
export const SUBMIT_WAS_SUCCESSFUL = 'SUBMIT_WAS_SUCCESSFUL'
export const SUBMIT_FAILED = 'SUBMIT_FAILED'

export const submitReducer = (state, action) => {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...initialState,
        loading: true,
      }

    case CLOSE_COMPONENT:
      return {
        ...initialState,
        showComponent: false,
      }

    case SUBMIT_WAS_SUCCESSFUL:
      return {
        showComponent: true,
        status: 'success',
        message: action.payload,
        loading: false,
      }

    case SUBMIT_FAILED:
      return {
        showComponent: true,
        status: 'error',
        message: action.payload,
        loading: false,
      }

    default:
      return state
  }
}
