import React, { useState } from 'react'

import Wrapper from '../../../components/Pills'
import Pill from '../../../components/Pill'

const Pills = ({ pills }) => {
  const [currentPill, setCurrentPill] = useState(pills[0])

  return (
    <>
      <Wrapper>
        {pills.map((pill) => (
          <Pill
            key={pill.key}
            isActive={pill.key === currentPill.key}
            onClick={() => setCurrentPill(pill)}
          >
            {pill.label}
          </Pill>
        ))}
      </Wrapper>

      {currentPill.content}
    </>
  )
}

export default Pills
