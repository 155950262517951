import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { BulletinsGridQuery, CountBulletinsQuery } from '../queries'
import { dateTimeValueGetter } from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { BulletinDownloadRenderer } from '../renderers'
import { DateRenderer } from '../../../components/AgGrid/renderers'

export const useBulletinOverviewGrid = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { propertyUnitId } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'date':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          default:
            return params.value
        }
      }
      const BulletinsColumnDefs = [
        columnCheckbox,
        {
          headerName: t('BULLETIN'),
          field: 'tableId',
          width: 100,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('FLOORS'),
          field: 'floors',
          width: 100,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('DATE'),
          field: 'date',
          width: 100,
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
          cellRenderer: DateRenderer,
        },
        {
          headerName: t('TIME FRAME'),
          field: 'timeframe',
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('DOWNLOAD'),
          field: 'tableId',
          width: 150,
          sortable: false,
          filter: false,
          cellRenderer: BulletinDownloadRenderer,
          cellRendererParams: {
            propertyUnitId: propertyUnitId,
          },
        },
      ]
      const BulletinsCustomFilter = []
      return {
        query: BulletinsGridQuery,
        columnDefs: BulletinsColumnDefs,
        customFilters: BulletinsCustomFilter,
        countQuery: CountBulletinsQuery,
        extractor: (response) => response.Me?.Company?.HouseEntrance?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return ['copy']
          },
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { propertyUnitId },
      }
    },
    [currentLanguage, t]
  )
  return { getParams }
}
