import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import TabsWithLivechat from './TabsWithLivechat'
import Notifications from './Notifications'
import FaqDownload from './FaqDownload'
import Contact from './Contact'

import useUrlRedirection from '../../hooks/useUrlRedirection'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

const Support = ({ pageSection = '' }) => {
  const { t } = useTranslation()

  const urlRedirectionCallback = useUrlRedirection()

  const tabs = [
    // {
    //   key: 'enquiries',
    //   label: t('SERVICE TASKS / ENQUIRIES'),
    //   content: <Enquiries />,
    // },
    {
      key: 'notifications',
      label: t('NOTIFICATIONS'),
      content: <Notifications />,
    },
    // {
    //   key: 'status',
    //   label: t('POM STATUS'),
    //   content: <PomStatus />,
    // },
    {
      key: 'faq',
      label: t('FAQ / DOWNLOAD CENTER'),
      content: <FaqDownload />,
    },
    {
      key: 'contact',
      label: t('CONTACT'),
      content: <Contact />,
    },
  ]

  return (
    <QueryClientProvider client={queryClient}>
      <PageContainer>
        <PageTitle>{t('SUPPORT')}</PageTitle>
        <TabsWithLivechat
          tabs={tabs}
          pageSection={pageSection}
          urlRedirectionCallback={urlRedirectionCallback}
        />
      </PageContainer>
    </QueryClientProvider>
  )
}

Support.propTypes = {
  pageSection: PropTypes.string,
}

export default Support
