/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NewsletterSubscriptionInfo = {|
  newsletterSubscriptionEmail: string
|};
export type AddNewsletterSubscriptionVariables = {|
  newsletterSubscriptionInfo: NewsletterSubscriptionInfo
|};
export type AddNewsletterSubscriptionResponse = {|
  +addNewsletterSubscription: {|
    +success: boolean
  |}
|};
export type AddNewsletterSubscription = {|
  variables: AddNewsletterSubscriptionVariables,
  response: AddNewsletterSubscriptionResponse,
|};
*/


/*
mutation AddNewsletterSubscription(
  $newsletterSubscriptionInfo: NewsletterSubscriptionInfo!
) {
  addNewsletterSubscription(newsletterSubscriptionInfo: $newsletterSubscriptionInfo) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newsletterSubscriptionInfo"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "newsletterSubscriptionInfo",
        "variableName": "newsletterSubscriptionInfo"
      }
    ],
    "concreteType": "Success",
    "kind": "LinkedField",
    "name": "addNewsletterSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNewsletterSubscription",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddNewsletterSubscription",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9aec9aa5bee894bccaaafaec83f9393f",
    "id": null,
    "metadata": {},
    "name": "AddNewsletterSubscription",
    "operationKind": "mutation",
    "text": "mutation AddNewsletterSubscription(\n  $newsletterSubscriptionInfo: NewsletterSubscriptionInfo!\n) {\n  addNewsletterSubscription(newsletterSubscriptionInfo: $newsletterSubscriptionInfo) {\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2256b41626788402cdea4dc3496e9ec7';

module.exports = node;
