import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { columnCheckbox } from '../../../../components/AgGrid/columnDefs'
import UsernameRenderer from '../renderer'
import { DateRenderer } from '../../../../components/AgGrid/renderers'
import { CountUserListQuery, UserListGridQuery } from '../queries'
import SimpleGridFilter from '../../../../components/AgGrid/filters/SimpleGridFilter'

export const useUserListGrid = () => {
  const { t } = useTranslation()

  const getParams = useCallback(() => {
    const UserListGridColumnDef = [
      columnCheckbox,
      {
        headerName: t('USERNAME'),
        field: 'username',
        width: 100,
        sortable: true,
        cellRenderer: UsernameRenderer,
      },
      {
        headerName: t('FIRST NAME'),
        field: 'firstname',
        width: 75,
      },
      {
        headerName: t('SURNAME'),
        field: 'lastname',
        width: 75,
      },
      {
        headerName: t('CONTACT'),
        field: 'email',
        width: 150,
      },
      {
        headerName: t('PERMISSIONS'),
        field: 'permissions',
        width: 100,
        cellRenderer: ({ value = [] }) => {
          return <span>{value.reduce((prev, acc) => prev + ', ' + acc)}</span>
        },
      },
      {
        headerName: t('CREATED AT'),
        field: 'created',
        width: 100,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['apply', 'clear', 'reset'],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
        cellRenderer: DateRenderer,
      },
      {
        headerName: t('LAST LOGIN'),
        field: 'last_activity',
        width: 100,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['apply', 'clear', 'reset'],
          closeOnApply: true,
          suppressAndOrCondition: true,
        },
        cellRenderer: DateRenderer,
      },
    ]

    const UserListGridCustomFilter = []
    return {
      query: UserListGridQuery,
      columnDefs: UserListGridColumnDef,
      customFilters: UserListGridCustomFilter,
      countQuery: CountUserListQuery,
      extractor: (response) => response.Me?.Company?.Users,
      countExtractor: (response) => response.Me?.Company?.data,
      customGridOptions: {},
      components: {
        SimpleGridFilter,
      },
    }
  }, [t])
  return { getParams }
}
