/**
 * Transforms an array of contracts with serviceCategory fields into an array of
 * pure unrepeated services
 *
 * @param {[]} contracts
 * @returns {{id: String, tableId: Number, name: String}[]}
 */
export default (contracts = []) =>
  contracts
    .map((item) => item?.serviceCategory)
    .filter(
      (contract, idx, self) =>
        idx ===
        self.findIndex(
          (s) =>
            s?.id === contract?.id && contract != null && contract?.id != null
        ) // either id or tableId depending on the query
    )
