import React, { forwardRef, useImperativeHandle, useState } from 'react'
import GridEnumFilter from '../../../components/GridEnumFilter'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import { syncTypeEnumList } from '../../../components/AgGrid/enumLists'

// TODO add real types
const map = {
  FULL_UPDATE: ['FULL_UPDATE'],
  INCREMENTAL_UPDATE: ['INCREMENTAL_UPDATE'],
  API_TENANT_UPDATE: ['API_TENANT_UPDATE'],
}

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  return (
    <GridEnumFilter
      map={map}
      translateLabel={(value, t) => {
        return t(syncTypeEnumList(value))
      }}
      setFilterModel={setFilterModel}
    />
  )
})
