import isValid from '../../../helper/isValid'

const tenantFullNameValueGetter = (props) => {
  const { value } = props

  if ((isValid(value) && value.length < 1) || !value) {
    return ''
  }

  let tenant
  if (isValid(value)) {
    tenant = value[0]
  } else {
    tenant = value
  }

  return tenant?.firstname + ' ' + tenant?.lastname
}

export default tenantFullNameValueGetter
