import React, { useContext } from 'react'
import { withTranslation } from 'react-i18next'
import { Col, Container, Row, Visible } from 'react-grid-system'
import { NavLink } from 'react-router-dom'
import UserContext, { useUserContext } from '../../../../helper/userContext'

import { RouteLink, SimpleLink, SimpleList } from 'pyrexx-react-library'
import MobilePullDown from '../../../MobilePullDown/MobilePullDown'

import { Routes } from '../../../../configs/RouteConfig'
import { Links } from '../../../../configs/LinkConfig'

const LinksFooter = (props) => {
  const LinkFooterTheme = {}
  const { user } = useUserContext()
  const { currentLanguage } = useContext(UserContext)

  const portalLinks = {
    label: props.t('SERVICE PORTAL'),
    links: [
      <RouteLink
        key='linkCreateAppointment'
        navLinkElement={
          <NavLink to={'/' + currentLanguage + Routes.createAppointment}>
            {props.t('MAKE AN APPOINTMENT')}
          </NavLink>
        }
        theme={LinkFooterTheme}
      />,
      <RouteLink
        key='linkAppointmentManagement'
        navLinkElement={
          <NavLink to={'/' + currentLanguage + Routes.appointmentManagement}>
            {props.t('APPOINTMENT MANAGEMENT')}
          </NavLink>
        }
        theme={LinkFooterTheme}
      />,
    ],
  }

  const isLoggedIn = user?.accessToken && user?.refreshToken

  const authSupportLinks = {
    label: props.t('SUPPORT'),
    links: [
      <RouteLink
        key='linkFaq'
        navLinkElement={
          <NavLink to={'/' + currentLanguage + Routes.support + Routes.faq}>
            {props.t('FAQ')}
          </NavLink>
        }
        theme={LinkFooterTheme}
      />,
      <RouteLink
        key='linkContactInfo'
        navLinkElement={
          <NavLink to={'/' + currentLanguage + Routes.support + Routes.contact}>
            {props.t('CONTACT')}
          </NavLink>
        }
        theme={LinkFooterTheme}
      />,
      <RouteLink
        key='linkDownloadCenter'
        navLinkElement={
          <NavLink to={'/' + currentLanguage + Routes.support + Routes.faq}>
            {props.t('DOWNLOAD CENTER')}
          </NavLink>
        }
        theme={LinkFooterTheme}
      />,
    ],
  }

  const unAuthSupportLinks = {
    label: props.t('SUPPORT'),
    links: [
      <RouteLink
        key='linkContactInfo'
        navLinkElement={
          <NavLink to={'/' + currentLanguage + Routes.contact}>
            {props.t('CONTACT')}
          </NavLink>
        }
        theme={LinkFooterTheme}
      />,
    ],
  }

  const supportLinks = isLoggedIn ? authSupportLinks : unAuthSupportLinks

  /**
   const newsletterLinks = {
    label: props.t('NEWSLETTER'),
    links: [
      <RouteLink
        key='linkRegisterNewsletter'
        navLinkElement={
          <NavLink to={'/' + currentLanguage + Routes.newsletter}>
            {props.t('NEWSLETTER REGISTER')}
          </NavLink>
        }
        theme={LinkFooterTheme}
      />,
    ],
  }
   **/

  const pyrexxLinks = {
    label: props.t('PYREXX GMBH'),
    links: [
      <SimpleLink
        key='linkAboutUs'
        label={props.t('ABOUT US')}
        url={Links.aboutUs}
        target='_BLANK'
      />,
      <SimpleLink
        key='linkPyrexxHomePage'
        label={props.t('PYREXX HOMEPAGE')}
        url={Links.pyrexx}
        target='_BLANK'
      />,
      <SimpleLink
        key='linkPQPPage'
        label={props.t('PQP OFFER PORTAL')}
        url={Links.pqpPage}
        target='_BLANK'
      />,
      <SimpleLink
        key='linkWebAppPage'
        label={props.t('APP PYREXX WEB APP')}
        url={Links.webAppPage}
        target='_BLANK'
      />,
    ],
  }

  return (
    <Container xs sm md>
      <Row>
        <Col md={12} lg={3}>
          <Visible lg xl>
            <SimpleList label={portalLinks.label} values={portalLinks.links} />
          </Visible>

          <Visible xs sm md>
            <MobilePullDown
              key='mobilePullDown-portalLinks'
              headerLabel={portalLinks.label}
              values={portalLinks.links}
            />
          </Visible>
        </Col>

        <Col md={12} lg={3}>
          <Visible lg xl>
            <SimpleList
              label={supportLinks.label}
              values={supportLinks.links}
            />
          </Visible>

          <Visible xs sm md>
            <MobilePullDown
              key='mobilePullDown-supportLinks'
              headerLabel={supportLinks.label}
              values={supportLinks.links}
            />
          </Visible>
        </Col>

        <Col md={12} lg={3}>
          {/**
           <Visible lg xl>
           <SimpleList
           label={newsletterLinks.label}
           values={newsletterLinks.links}
           />
           </Visible>

           <Visible xs sm md>
           <MobilePullDown
           key='mobilePullDown-newsletterLinks'
           headerLabel={newsletterLinks.label}
           values={newsletterLinks.links}
           />
           </Visible>
           **/}
        </Col>

        <Col md={12} lg={3}>
          <Visible lg xl>
            <SimpleList label={pyrexxLinks.label} values={pyrexxLinks.links} />
          </Visible>

          <Visible xs sm md>
            <MobilePullDown
              key='mobilePullDown-pyrexxLinks'
              headerLabel={pyrexxLinks.label}
              values={pyrexxLinks.links}
            />
          </Visible>
        </Col>
      </Row>
    </Container>
  )
}

export default withTranslation('translation')(LinksFooter)
