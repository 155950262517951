import React, { useEffect } from 'react'
import { withTranslation } from 'react-i18next'

const Register = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>REGISTER CONTENT</div>
  )
}

export default withTranslation('translation')(Register)
