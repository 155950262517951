/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CompanyLayoutStatusType = "ACTIVE" | "DRAFT" | "%future added value";
export type GetDropdownDataQueryVariables = {|
  companyId: string
|};
export type GetDropdownDataQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +companyLayouts: ?$ReadOnlyArray<?{|
          +id: string,
          +tableId: number,
          +name: string,
          +layout: string,
          +status: CompanyLayoutStatusType,
        |}>
      |}
    |}
  |}
|};
export type GetDropdownDataQuery = {|
  variables: GetDropdownDataQueryVariables,
  response: GetDropdownDataQueryResponse,
|};
*/


/*
query GetDropdownDataQuery(
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        companyLayouts(options: {filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["ACTIVE"], type: equals}]}]}) {
          id
          tableId
          name
          layout
          status
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessPost",
  "kind": "LinkedField",
  "name": "businessPost",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "options",
          "value": {
            "filters": [
              {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "ACTIVE"
                    ]
                  }
                ],
                "filterType": "text",
                "name": "status",
                "operator": "AND"
              }
            ]
          }
        }
      ],
      "concreteType": "CompanyLayout",
      "kind": "LinkedField",
      "name": "companyLayouts",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tableId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "layout",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        }
      ],
      "storageKey": "companyLayouts(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"ACTIVE\"]}],\"filterType\":\"text\",\"name\":\"status\",\"operator\":\"AND\"}]})"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetDropdownDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetDropdownDataQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7565253aa1746f2769a734785229aef4",
    "id": null,
    "metadata": {},
    "name": "GetDropdownDataQuery",
    "operationKind": "query",
    "text": "query GetDropdownDataQuery(\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        companyLayouts(options: {filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"ACTIVE\"], type: equals}]}]}) {\n          id\n          tableId\n          name\n          layout\n          status\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ee9266293875c40497f6641e64e21a9f';

module.exports = node;
