import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query PropertyUnitContainerQuery($companyId: ID!, $propertyUnitId: ID!) {
    Me {
      Company(id: $companyId) {
        Contracts(options: { endRow: -1 }) {
          serviceCategory {
            id
            tableId
            name
          }
        }
        HouseEntrance(id: $propertyUnitId) {
          id
          tableId
          number
          address {
            street
            zip
            city
            number
          }
          coordinates {
            lat
            lng
          }
          countUsageUnits
        }
      }
    }
  }
`
