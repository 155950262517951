import ReactDOM from 'react-dom'
import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-grid-system'
import { CKEditor as CKEditorReact } from '@ckeditor/ckeditor5-react'
import styled from 'styled-components/macro'

import { SimpleNote } from 'pyrexx-react-library'
import MassLetterTooltip from './MassLetterTooltip'

import CustomEditor from '../../../editor/build/customEditor'
import VariablePreview from '../../../editor/VariablePreview'
import VariablesBox from '../../../editor/VariablesBox'
import Characters from '../../../editor/Characters'
import UpdateSaveButton from '../../../components/UpdateSavedButton/UpdateSaveButton'

import replaceTableTag from './helperFunctions/replaceTableTag'

const MAX_CHARACTERS = 140

const SubjectOverride = (props) => {
  const {
    formikTmp,
    updateFunction,
    loading,
    setFormikValuesClone,
    formikValuesClone,
    setContainerFormErrors,
    containerFormErrors,
    subjectContainerKeyId,
    allVariables = [],
    returnUndefinedContentVariables,
    undefinedVariables,
    setUndefinedVariables,
    customVariables,
  } = props

  const editorRef = useRef(null)
  const { t } = useTranslation()

  const [currentChars, setCurrentChars] = useState(0)

  // if (!formikValuesClone) {
  //   setFormikValuesClone(formikTmp.values)
  // }

  const noChangedContent = useCallback(() => {
    return (
      formikTmp.values?.[subjectContainerKeyId.containerKey]?.[
        subjectContainerKeyId.id
      ]?.data ===
      formikValuesClone?.[subjectContainerKeyId.containerKey]?.[
        subjectContainerKeyId.id
      ]?.data
    )
  }, [
    formikTmp.values,
    subjectContainerKeyId.containerKey,
    subjectContainerKeyId.id,
    formikValuesClone,
  ])

  const editorConfig = {
    // The configuration of the Products plugin. It specifies a function that will allow
    // the editor to render a React <VariablePreview> component inside a product widget.
    toolbar: ['bold', 'italic'],
    wordCount: {
      onUpdate: (evt, data) => {
        setCurrentChars(evt.characters)
      },
    },
    limitWordCount: {
      maxCharacters: MAX_CHARACTERS,
    },
    variables: {
      variablesRenderer: (id, domElement) => {
        const variable = allVariables.find((variable) => variable.id === id)

        const handleRemove = (id) => {
          editorRef.current.execute('removeVariable', id)
          editorRef.current.editing.view.focus()
        }

        // const variableElement = createRoot(domElement)
        ReactDOM.render(
          <VariablePreview id={id} handleRemove={handleRemove} {...variable} />,
          domElement
        )
      },
    },
  }

  const handleEditorDataChange = (evt, editor) => {
    formikTmp.setFieldValue(
      subjectContainerKeyId.containerKey +
        '.' +
        subjectContainerKeyId.id +
        '.data',
      editor.getData()
    )

    const newUndefinedVariables = {
      content: undefinedVariables.content,
      subject: returnUndefinedContentVariables(
        editor.getData(),
        customVariables
      ),
    }

    setUndefinedVariables(newUndefinedVariables)
  }

  const handleEditorReady = (editor) => {
    editorRef.current = editor

    // editor.editing.view.change((writer) => {
    //   writer.setStyle('height', '55px', editor.editing.view.document.getRoot())
    // })
  }

  const handleSave = () => {
    setFormikValuesClone(formikTmp.values)
    formikTmp.validateForm()
    const newContainerFormErrors = containerFormErrors.reduce(
      (prev, current) => {
        if (
          subjectContainerKeyId.containerKey +
            '_' +
            subjectContainerKeyId.id !==
          current
        ) {
          return [...prev, current]
        } else {
          return [...prev]
        }
      },
      []
    )
    setContainerFormErrors(newContainerFormErrors)

    updateFunction(
      replaceTableTag(
        formikTmp.values?.[subjectContainerKeyId.containerKey]?.[
          subjectContainerKeyId.id
        ]?.data
      )
    )
  }

  return (
    <div>
      <Row>
        <Col>
          <h4 style={{ margin: ' 0 0 15px 0' }}>
            {t('ADD LETTER SUBJECT (OPTIONAL)')}
          </h4>
        </Col>
        <Col xs={1}>
          <MassLetterTooltip>
            <div>
              <p style={{ marginTop: 0 }}>
                Nehmen Sie hier bitte alle notwendigen Konfigurationen vor,
                damit die Briefe den richtigen Betreff erhalten. Dazu können Sie
                auch Variablen einsetzen und den Briefbetreff somit individuell
                gestalten.
              </p>
              <p>
                Hier können Sie Variable (definiert durch die Spaltennamen der
                Upload-Datei) an die richtige Stelle platzieren und dadurch für
                jeden Brief die richtige Information anzeigen, wie den Adressat
                und adressatspezifische Zusatzinformationen.
              </p>
              <p style={{ marginBottom: 0 }}>
                Es tauchen nur die Variablen auf, die als Spaltenköpfe in der
                Upload-Datei gefunden wurden. Überprüfen Sie gern die
                Spaltennamen im Schritt “Briefkomponenten und Variablen
              </p>
            </div>
          </MassLetterTooltip>
        </Col>
      </Row>
      <Row>
        <Col>
          {formikTmp.errors?.[subjectContainerKeyId.containerKey]?.[
            subjectContainerKeyId.id
          ]?.data && (
            <SimpleNote
              style={{ fontSize: '14px' }}
              noteStatus='error'
              text={
                formikTmp.errors?.[subjectContainerKeyId.containerKey]?.[
                  subjectContainerKeyId.id
                ]?.data
              }
            />
          )}
        </Col>
      </Row>
      <VariablesEditorWrapper>
        <CKEditorReact
          editor={CustomEditor.SingleLineEditor}
          data={
            formikTmp.values?.[subjectContainerKeyId.containerKey]?.[
              subjectContainerKeyId.id
            ]?.data
          }
          config={editorConfig}
          onChange={handleEditorDataChange}
          onReady={handleEditorReady}
        />
        <Characters currentChars={currentChars} maxChars={MAX_CHARACTERS} />

        <div>
          <h4 style={{ margin: 0 }}>{t('VARIABLEN')}</h4>
          <VariablesBox
            variables={allVariables}
            editorRef={editorRef}
            divideIntoColumns
          />
        </div>
      </VariablesEditorWrapper>

      <Row
        justify='end'
        align='end'
        style={{
          paddingBottom: '20px',
          paddingTop: '20px',
        }}
      >
        <Col>
          <UpdateSaveButton
            loading={loading}
            handleSave={handleSave}
            noChangedContent={noChangedContent}
          />
        </Col>
      </Row>
    </div>
  )
}

const VariablesEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export default SubjectOverride
