import React from 'react'
import { useTranslation } from 'react-i18next'

import { SimpleCheckbox } from '../../../components/Simple'

const NewsletterCheckbox = ({ options = {}, toggleModal = () => {} }) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <SimpleCheckbox
        label={t('RECEIVE THE POM NEWSLETTER')}
        value={options?.newsletter}
        onChange={() => toggleModal('newsletter')}
        id='newsletter-input'
      />
    </div>
  )
}

export default NewsletterCheckbox
