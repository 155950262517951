import { useRef, useEffect } from 'react'

// Only renders after dependencies update, not at mounting
// src: https://stackoverflow.com/a/57632587
export default function useUpdateEffect(effect, dependencies = []) {
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      effect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
