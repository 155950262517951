import styled, { css } from 'styled-components'
import { Col, Row } from 'react-grid-system'
import { Icon, portalTheme } from 'pyrexx-react-library'
import { useDrag, useDrop } from 'react-dnd'
import React, { useRef } from 'react'
import { LinkButtonStyled } from '../../../styles/HelperStylesComponents.styles'

const StyledTrashIcon = styled(Icon)`
  color: ${portalTheme.color.errorColor};
`

const StyledDragAndDropItem = styled.div`
  border: 1px solid;
  border-color: ${portalTheme.color.basicColorLightGrey1};

  &:nth-child(odd) {
    background: ${portalTheme.color.basicColorLightGrey2};
  }

  background-color: white;
  cursor: pointer;
  ${(props) =>
    props.isDragging
      ? css`
          opacity: 0;
        `
      : css`
          opacity: 1;
        `}
`

const SelectedFile = (props) => {
  const { file, index, removeFile, moveListItem } = props

  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: 'item',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  // useDrop - the list item is also a drop area
  // eslint-disable-next-line
  const [spec, dropRef] = useDrop({
    accept: 'item',
    hover: (item, monitor) => {
      const dragIndex = item.index
      const hoverIndex = index
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

      moveListItem(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
  })

  const ref = useRef(null)
  const dragDropRef = dragRef(dropRef(ref))

  const formatSizeUnits = (bytes) => {
    if (bytes >= 1073741824) {
      bytes = (bytes / 1073741824).toFixed(2) + ' GB'
    } else if (bytes >= 1048576) {
      bytes = (bytes / 1048576).toFixed(2) + ' MB'
    } else if (bytes >= 1024) {
      bytes = (bytes / 1024).toFixed(2) + ' KB'
    } else if (bytes > 1) {
      bytes = bytes + ' bytes'
    } else if (bytes === 1) {
      bytes = bytes + ' byte'
    } else {
      bytes = '0 bytes'
    }
    return bytes
  }

  return (
    <StyledDragAndDropItem ref={dragDropRef} isDragging={isDragging}>
      <Row nogutter align='center' justify='start'>
        <Col xs='content' style={{ paddingRight: '5px', fontWeight: 'bold' }}>
          {index + 1}.
        </Col>
        <Col>{file.path}</Col>
        <Col xs='content' style={{ paddingRight: '8px', fontWeight: 'bold' }}>
          <span>{formatSizeUnits(file.size)}</span>
        </Col>
        <Col xs='content'>
          <LinkButtonStyled onClick={() => removeFile(index)} type='button'>
            <StyledTrashIcon icon='trash' size='1.3em' aria-hidden='true' />
          </LinkButtonStyled>
        </Col>
      </Row>
    </StyledDragAndDropItem>
  )
}

export default SelectedFile
