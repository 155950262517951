/**
 import React from 'react'


const StyledWrapperDiv = styled.div`
  background-color: #fff;
  width: 150px;
  height: 60px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
`

const StyledImage = styled.img`
  position: absolute;
  height: 50px;
  top: 6%;
  color: #e8231b;
`

const StyledContentWrapper = styled.div`
  text-align: center;
`

const StyledTopContentDiv = styled.div`
  padding-left: 7%;
  padding-top: 5px;
`

const StyledBottomContentDiv = styled.div`
  padding-left: 16%;
`

const StyledSimpleLink = styled(SimpleLink)`
  color: black;
`
**/
const LivechatButton = () => {
  // const { currentLanguage } = useContext(UserContext)

  return null

  /**
  return (
    <StyledWrapperDiv>
      <StyledSimpleLink to={'/' + currentLanguage + Routes.livechat}>
        <StyledImage src={LivechatIcon} alt='' />
        <StyledContentWrapper>
          <StyledTopContentDiv>
            <span>Pyrexx</span>
          </StyledTopContentDiv>
          <StyledBottomContentDiv>
            <span>Livechat</span>
          </StyledBottomContentDiv>
        </StyledContentWrapper>
      </StyledSimpleLink>
    </StyledWrapperDiv>
  )
   **/
}

export default LivechatButton
