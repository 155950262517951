import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import QueryRendererContainer from '../../components/QueryRendererContainer'
import PyrexxOverview from '../../components/PyrexxOverview'

import { AppointmentsOverviewQuery } from './queries'
import UserContext from '../../helper/userContext'
import {
  filterDone,
  filterNotDone,
  filterPending,
  filterRegular,
  filterRegularDone,
  filterRegularNotDone,
  filterRegularPending,
  filterService,
  filterServiceDone,
  filterServiceNotDone,
  filterServicePending,
  filterSpecial,
  filterSpecialDone,
  filterSpecialNotDone,
  filterSpecialPending,
} from '../../constants/appointmentFilterModels'

const AppointmentsOverview = (props) => {
  const { setFilterModel } = props

  const { user } = useContext(UserContext)
  const { t } = useTranslation()

  const defaultFilters = {
    companyId: user.companyId,
    dateFilter: [],
    serviceFilter: [],
    contractFilter: [],
  }

  const [variables, setVariables] = useState({})

  const { titleElements, rowElements } = getElements(null, t)

  return (
    <QueryRendererContainer
      query={AppointmentsOverviewQuery}
      variables={{
        ...defaultFilters,
        ...variables,
      }}
      loading={
        <PyrexxOverview
          titleElements={titleElements}
          rowElements={rowElements}
          setFilterModel={setFilterModel}
          setVariables={setVariables}
          loading
        />
      }
      render={(data) => {
        const contracts = data?.Me?.Company?.Contracts
        const statuses = data?.Me?.Company

        const { titleElements, rowElements } = getElements(statuses)

        return (
          <PyrexxOverview
            contracts={contracts}
            titleElements={titleElements}
            rowElements={rowElements}
            setFilterModel={setFilterModel}
            setVariables={setVariables}
          />
        )
      }}
    />
  )
}

export default AppointmentsOverview

const getElements = (statuses = {}, t = (v) => v) => {
  const {
    regularDone = 0,
    serviceDone = 0,
    specialDone = 0,
    regularPending = 0,
    servicePending = 0,
    specialPending = 0,
    regularNotDone = 0,
    serviceNotDone = 0,
    specialNotDone = 0,
  } = statuses || {}

  const titleElements = [
    {
      id: 'done',
      label: t('EXECUTED'),
      filter: {
        filterId: 'box-done',
        label: t('EXECUTED'),
        filterModel: filterDone,
      },
    },
    {
      id: 'pending',
      label: t('PENDING'),
      filter: {
        filterId: 'box-pending',
        label: t('PENDING'),
        filterModel: filterPending,
      },
    },
    {
      id: 'not-done',
      label: t('NOT EXECUTED'),
      filter: {
        filterId: 'box-not-done',
        label: t('NOT EXECUTED'),
        filterModel: filterNotDone,
      },
    },
  ]

  const rowElements = [
    {
      id: 'regular',
      label: t('REGULAR APPOINTMENTS'),
      filter: {
        filterId: 'box-regular',
        filterRow: 'regular',
        label: t('REGULAR'),
        filterModel: filterRegular,
      },
      columns: [
        {
          id: 'regularDone',
          label: regularDone,
          status: 'success',
          filter: {
            filterId: 'box-regularDone',
            filterRow: 'regular',
            label: t('REGULAR') + '/' + t('EXECUTED'),
            filterModel: filterRegularDone,
          },
        },
        {
          id: 'regularPending',
          label: regularPending,
          status: 'warning',
          filter: {
            filterId: 'box-regularPending',
            filterRow: 'regular',
            label: t('REGULAR') + '/' + t('PENDING'),
            filterModel: filterRegularPending,
          },
        },
        {
          id: 'regularNotDone',
          label: regularNotDone,
          status: 'error',
          filter: {
            filterId: 'box-regularNotDone',
            filterRow: 'regular',
            label: t('REGULAR') + '/' + t('NOT EXECUTED'),
            filterModel: filterRegularNotDone,
          },
        },
      ],
    },
    {
      id: 'service',
      label: t('SERVICE APPOINTMENTS'),
      filter: {
        filterId: 'box-service',
        filterRow: 'service',
        label: t('SERVICE'),
        filterModel: filterService,
      },
      columns: [
        {
          id: 'serviceDone',
          label: serviceDone,
          status: 'success',
          filter: {
            filterId: 'box-serviceDone',
            filterRow: 'service',
            label: t('SERVICE') + '/' + t('EXECUTED'),
            filterModel: filterServiceDone,
          },
        },
        {
          id: 'servicePending',
          label: servicePending,
          status: 'warning',
          filter: {
            filterId: 'box-servicePending',
            filterRow: 'service',
            label: t('SERVICE') + '/' + t('PENDING'),
            filterModel: filterServicePending,
          },
        },
        {
          id: 'serviceNotDone',
          label: serviceNotDone,
          status: 'error',
          filter: {
            filterId: 'box-serviceNotDone',
            filterRow: 'service',
            label: t('SERVICE') + '/' + t('NOT EXECUTED'),
            filterModel: filterServiceNotDone,
          },
        },
      ],
    },
    {
      id: 'special',
      label: t('SPECIAL APPOINTMENTS'),
      filter: {
        filterId: 'box-special',
        filterRow: 'special',
        label: t('SPECIAL'),
        filterModel: filterSpecial,
      },
      columns: [
        {
          id: 'specialDone',
          label: specialDone,
          status: 'success',
          filter: {
            filterId: 'box-specialDone',
            filterRow: 'special',
            label: t('SPECIAL') + '/' + t('EXECUTED'),
            filterModel: filterSpecialDone,
          },
        },
        {
          id: 'specialPending',
          label: specialPending,
          status: 'warning',
          filter: {
            filterId: 'box-specialPending',
            filterRow: 'special',
            label: t('SPECIAL') + '/' + t('PENDING'),
            filterModel: filterSpecialPending,
          },
        },
        {
          id: 'specialNotDone',
          label: specialNotDone,
          status: 'error',
          filter: {
            filterId: 'box-specialNotDone',
            filterRow: 'special',
            label: t('SPECIAL') + '/' + t('NOT EXECUTED'),
            filterModel: filterSpecialNotDone,
          },
        },
      ],
    },
  ]

  return {
    titleElements,
    rowElements,
  }
}
