import React, { useContext } from 'react'

import SimpleLink from '../../../../components/Simple/SimpleLink'

import { Routes } from '../../../../configs/RouteConfig'
import UserContext from '../../../../helper/userContext'

const UsernameRenderer = (props) => {
  const { data, value } = props
  const { currentLanguage } = useContext(UserContext)

  const { tableId } = data

  return (
    <SimpleLink
      to={'/' + currentLanguage + Routes.admin + '/' + tableId}
      style={{ fontSize: 'inherit' }}
    >
      {value}
    </SimpleLink>
  )
}

export default UsernameRenderer
