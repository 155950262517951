import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components/macro'

import { ButtonPrimary } from 'pyrexx-react-library'
import PageContainer from '../../components/PageContainer'
import PasswordStrengthInput from '../../components/PasswordStrength/PasswordStrengthInput'
import PasswordStrengthSuggestions from '../../components/PasswordStrength/PasswordStrengthSuggestions'
import Spacer from '../../components/Spacer'

import { useUserContext } from '../../helper/userContext'
import { Routes } from '../../configs/RouteConfig'
import ResetPasswordMutation from '../../mutations/ResetPasswordMutation'
import useError from '../User/useError'
import getSearchParamsFromUrl from '../../helper/getSearchParamsFromUrl'
import { CenterRow } from '../../styles/HelperStylesComponents.styles'
import { Box, BoxInner, CheckIcon } from './styles'

const initialState = {
  password: '',
}

const ResetPassword = () => {
  const { user, currentLanguage } = useUserContext()
  const { t } = useTranslation()
  const { token = '' } = getSearchParamsFromUrl()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [showPage, setShowPage] = useState(false)
  const [firstPassword, setFirstPassword] = useState(initialState)
  const [secondPassword, setSecondPassword] = useState(initialState)

  const [
    FirstPasswordError,
    setFirstPasswordErrorMessage,
    clearFirstPasswordError,
  ] = useError()

  const [
    SecondPasswordError,
    setSecondPasswordErrorMessage,
    clearSecondPasswordError,
  ] = useError()

  useEffect(() => {
    clearFirstPasswordError()
  }, [clearFirstPasswordError, firstPassword.password])

  useEffect(() => {
    clearSecondPasswordError()
  }, [clearSecondPasswordError, secondPassword.password])

  useEffect(() => {
    const passwordsMatch = firstPassword?.password === secondPassword?.password

    if (!passwordsMatch && secondPassword?.password?.length > 0) {
      setSecondPasswordErrorMessage('PASSWORDS DO NOT MATCH')
    } else {
      clearSecondPasswordError()
    }
  }, [
    clearSecondPasswordError,
    firstPassword.password,
    secondPassword.password,
    setSecondPasswordErrorMessage,
  ])

  const confirmAction = () => {
    const firstPasswordExist = firstPassword?.password.length > 0
    const passwordsAreValid = firstPassword?.isValid && secondPassword?.isValid
    const passwordsMatch = firstPassword?.password === secondPassword?.password

    const condition = firstPasswordExist && passwordsAreValid && passwordsMatch

    if (!firstPasswordExist) {
      setFirstPasswordErrorMessage(t('TYPE IN YOUR NEW PASSWORD'))
    } else {
      clearFirstPasswordError()
    }

    // 2. Check if firstPassword is valid
    if (!firstPassword?.isValid) {
      setFirstPasswordErrorMessage(t('PASSWORD INVALID'))
    } else {
      clearFirstPasswordError()
    }

    // 3. Check if passwords match, if not, send an inline error
    if (!passwordsMatch) {
      setSecondPasswordErrorMessage(t('PASSWORDS DO NOT MATCH'))
    } else {
      clearSecondPasswordError()
    }

    // If all conditions match
    if (condition) {
      setIsLoading(true)

      ResetPasswordMutation(
        token,
        firstPassword.password,
        user.accessToken,
        (response) => {
          setIsLoading(false)
          const res = response.resetPassword
          if (res) {
            setShowPage(true)
          } else {
            console.log('was false')
          }
        },
        (err) => {
          setIsLoading(false)
          console.log(err)
        }
      )
    }
  }

  if (!token) {
    return (
      <PageContainer>
        <Box>
          <BoxInner>
            <h3>{t('UPDATING YOUR PASSWORD')}</h3>
            <Spacer />
            <p>{t('WE NEED YOUR EMAIL FIRST')}</p>
            <Spacer />
            <ButtonPrimary
              onClick={() =>
                history.push('/' + currentLanguage + Routes.forgotPassword)
              }
            >
              {t('SAVE EMAIL')}
            </ButtonPrimary>
            <Spacer />
          </BoxInner>
        </Box>
      </PageContainer>
    )
  }

  if (showPage) {
    return (
      <PageContainer>
        <Box>
          <BoxInner>
            <h3>{t('YOUR PASSWORD WAS UPDATED')}</h3>
            <Spacer />
            <CenterRow>
              <CheckIcon icon='bullet-point' size='30' />
              <p>{t('PLEASE LOG IN AGAIN')}</p>
            </CenterRow>
            <Spacer />
            <ButtonPrimary
              onClick={() => history.push('/' + currentLanguage + Routes.login)}
            >
              {t('BACK TO LOGIN')}
            </ButtonPrimary>
            <Spacer />
          </BoxInner>
        </Box>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <Box fullWidth={false}>
        <BoxInner>
          <h3>{t('UPDATING YOUR PASSWORD')}</h3>

          <div
            // eslint-disable-next-line
            css={`
              position: relative;
            `}
          >
            <Label>{t('NEW PASSWORD')}</Label>
            <PasswordStrengthInput
              setPassword={setFirstPassword}
              inputProps={{
                id: 'first-password-input',
                placeholder: t('TYPE NEW PASSWORD'),
              }}
            />
            <FirstPasswordError />

            <PasswordStrengthSuggestions
              warning={firstPassword?.feedback?.warning}
              suggestions={firstPassword?.feedback?.suggestions}
            />
          </div>

          <div>
            <Label>{t('REPEAT NEW PASSWORD')}</Label>
            <PasswordStrengthInput
              setPassword={setSecondPassword}
              inputProps={{
                id: 'second-password-input',
                placeholder: t('TYPE IN NEW PASSWORD AGAIN'),
              }}
              withFeedback={false}
            />
            <SecondPasswordError />
          </div>

          <ButtonPrimary onClick={confirmAction} isLoading={isLoading}>
            {t('CONTINUE')}
          </ButtonPrimary>

          <Spacer />
        </BoxInner>
      </Box>
    </PageContainer>
  )
}

const Label = styled.h4`
  margin: 0;

  &:first-of-type {
    margin-top: 24px;
  }
`

export default ResetPassword
