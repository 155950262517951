import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation RefreshTokenMutation($accessToken: String!, $refreshToken: String!) {
    info: refreshToken(accessToken: $accessToken, refreshToken: $refreshToken) {
      status
      Me {
        id
        name
        firstname
        lastname
        salutation
        title
        role
        email
        can
        Companies(options: null) {
          id
          name
        }
      }
      accessToken
      refreshToken
    }
  }
`

export default (accessToken, refreshToken, callBack, errorCallBack) => {
  const variables = {
    accessToken,
    refreshToken,
  }

  commitMutation(environment(), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
