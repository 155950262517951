/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type ContractTypeType = "ASP" | "B2C_ONE_TIME" | "DWA_CONTRACTOR" | "DWA_SUBCONTRACTOR" | "MINI_MAINTENANCE_CONTRACT" | "MISC_SERVICE" | "NONE" | "ONE_TIME_EXTERNAL_MAINTENANCE" | "ONE_TIME_GRAFFITI" | "ONLY_TS_ON_DEMAND" | "REMOTE_MAINTENANCE_CONTRACT" | "SD_MAINTENANCE_CONTRACT" | "SD_PURCHASE_ASSEMBLY_NO_TS" | "SD_PURCHASE_ASSEMBLY_TS_ON_DEMAND" | "SD_PURCHASE_WITHOUT_ASSEMBLY" | "UMBRELLA_GRAFFITI" | "%future added value";
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type InvoicesOverviewQueryVariables = {|
  companyId: string,
  dateFilter: $ReadOnlyArray<?Condition>,
  serviceFilter: $ReadOnlyArray<?Condition>,
  contractFilter: $ReadOnlyArray<?Condition>,
|};
export type InvoicesOverviewQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +paid: ?number,
      +open: ?number,
      +reminded: ?number,
      +Contracts: ?$ReadOnlyArray<?{|
        +id: string,
        +tableId: number,
        +name: ?string,
        +type: ?ContractTypeType,
        +billingPeriods: $ReadOnlyArray<?{|
          +id: string,
          +tableId: number,
          +from: any,
          +to: ?any,
        |}>,
        +serviceCategory: ?{|
          +id: string,
          +tableId: number,
          +name: string,
        |},
      |}>,
    |}
  |}
|};
export type InvoicesOverviewQuery = {|
  variables: InvoicesOverviewQueryVariables,
  response: InvoicesOverviewQueryResponse,
|};
*/


/*
query InvoicesOverviewQuery(
  $companyId: ID!
  $dateFilter: [Condition]!
  $serviceFilter: [Condition]!
  $contractFilter: [Condition]!
) {
  Me {
    Company(id: $companyId) {
      paid: countInvoices(options: {filters: [{name: "status", filterType: number, operator: AND, conditions: [{value: ["PAID"], type: equals}]}, {name: "invoiceDate", filterType: date, operator: AND, conditions: $dateFilter}, {name: "contract.serviceCategory.tableId", filterType: text, operator: AND, conditions: $serviceFilter}, {name: "contract", filterType: number, operator: AND, conditions: $contractFilter}]})
      open: countInvoices(options: {filters: [{name: "status", filterType: number, operator: AND, conditions: [{value: ["OPEN"], type: equals}]}, {name: "invoiceDate", filterType: date, operator: AND, conditions: $dateFilter}, {name: "contract.serviceCategory.tableId", filterType: text, operator: AND, conditions: $serviceFilter}, {name: "contract", filterType: number, operator: AND, conditions: $contractFilter}]})
      reminded: countInvoices(options: {filters: [{name: "status", filterType: number, operator: AND, conditions: [{value: ["REMINDED"], type: equals}]}, {name: "invoiceDate", filterType: date, operator: AND, conditions: $dateFilter}, {name: "contract.serviceCategory.tableId", filterType: text, operator: AND, conditions: $serviceFilter}, {name: "contract", filterType: number, operator: AND, conditions: $contractFilter}]})
      Contracts(options: {endRow: -1}) {
        id
        tableId
        name
        type
        billingPeriods(options: {endRow: -1}) {
          id
          tableId
          from
          to
        }
        serviceCategory {
          id
          tableId
          name
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractFilter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFilter"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "serviceFilter"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v5 = {
  "kind": "Literal",
  "name": "operator",
  "value": "AND"
},
v6 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "conditions",
      "variableName": "dateFilter"
    },
    {
      "kind": "Literal",
      "name": "filterType",
      "value": "date"
    },
    {
      "kind": "Literal",
      "name": "name",
      "value": "invoiceDate"
    },
    (v5/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters.1"
},
v7 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "conditions",
      "variableName": "serviceFilter"
    },
    {
      "kind": "Literal",
      "name": "filterType",
      "value": "text"
    },
    {
      "kind": "Literal",
      "name": "name",
      "value": "contract.serviceCategory.tableId"
    },
    (v5/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters.2"
},
v8 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "conditions",
      "variableName": "contractFilter"
    },
    {
      "kind": "Literal",
      "name": "filterType",
      "value": "number"
    },
    {
      "kind": "Literal",
      "name": "name",
      "value": "contract"
    },
    (v5/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "filters.3"
},
v9 = {
  "alias": "paid",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "PAID"
                    ]
                  }
                ],
                "filterType": "number",
                "name": "status",
                "operator": "AND"
              }
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countInvoices",
  "storageKey": null
},
v10 = {
  "alias": "open",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "OPEN"
                    ]
                  }
                ],
                "filterType": "number",
                "name": "status",
                "operator": "AND"
              }
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countInvoices",
  "storageKey": null
},
v11 = {
  "alias": "reminded",
  "args": [
    {
      "fields": [
        {
          "items": [
            {
              "kind": "Literal",
              "name": "filters.0",
              "value": {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "REMINDED"
                    ]
                  }
                ],
                "filterType": "number",
                "name": "status",
                "operator": "AND"
              }
            },
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/)
          ],
          "kind": "ListValue",
          "name": "filters"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "kind": "ScalarField",
  "name": "countInvoices",
  "storageKey": null
},
v12 = [
  {
    "kind": "Literal",
    "name": "options",
    "value": {
      "endRow": -1
    }
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": (v12/*: any*/),
  "concreteType": "Contract",
  "kind": "LinkedField",
  "name": "Contracts",
  "plural": true,
  "selections": [
    (v13/*: any*/),
    (v14/*: any*/),
    (v15/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v12/*: any*/),
      "concreteType": "BillingPeriod",
      "kind": "LinkedField",
      "name": "billingPeriods",
      "plural": true,
      "selections": [
        (v13/*: any*/),
        (v14/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "from",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "to",
          "storageKey": null
        }
      ],
      "storageKey": "billingPeriods(options:{\"endRow\":-1})"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceCategory",
      "kind": "LinkedField",
      "name": "serviceCategory",
      "plural": false,
      "selections": [
        (v13/*: any*/),
        (v14/*: any*/),
        (v15/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": "Contracts(options:{\"endRow\":-1})"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoicesOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "InvoicesOverviewQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v16/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eb9ae48dc48edc5368eeeccae0d541a8",
    "id": null,
    "metadata": {},
    "name": "InvoicesOverviewQuery",
    "operationKind": "query",
    "text": "query InvoicesOverviewQuery(\n  $companyId: ID!\n  $dateFilter: [Condition]!\n  $serviceFilter: [Condition]!\n  $contractFilter: [Condition]!\n) {\n  Me {\n    Company(id: $companyId) {\n      paid: countInvoices(options: {filters: [{name: \"status\", filterType: number, operator: AND, conditions: [{value: [\"PAID\"], type: equals}]}, {name: \"invoiceDate\", filterType: date, operator: AND, conditions: $dateFilter}, {name: \"contract.serviceCategory.tableId\", filterType: text, operator: AND, conditions: $serviceFilter}, {name: \"contract\", filterType: number, operator: AND, conditions: $contractFilter}]})\n      open: countInvoices(options: {filters: [{name: \"status\", filterType: number, operator: AND, conditions: [{value: [\"OPEN\"], type: equals}]}, {name: \"invoiceDate\", filterType: date, operator: AND, conditions: $dateFilter}, {name: \"contract.serviceCategory.tableId\", filterType: text, operator: AND, conditions: $serviceFilter}, {name: \"contract\", filterType: number, operator: AND, conditions: $contractFilter}]})\n      reminded: countInvoices(options: {filters: [{name: \"status\", filterType: number, operator: AND, conditions: [{value: [\"REMINDED\"], type: equals}]}, {name: \"invoiceDate\", filterType: date, operator: AND, conditions: $dateFilter}, {name: \"contract.serviceCategory.tableId\", filterType: text, operator: AND, conditions: $serviceFilter}, {name: \"contract\", filterType: number, operator: AND, conditions: $contractFilter}]})\n      Contracts(options: {endRow: -1}) {\n        id\n        tableId\n        name\n        type\n        billingPeriods(options: {endRow: -1}) {\n          id\n          tableId\n          from\n          to\n        }\n        serviceCategory {\n          id\n          tableId\n          name\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6ec257863cb8a0a014e42591e1c9e812';

module.exports = node;
