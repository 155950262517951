/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateFilterMutationVariables = {|
  gridId: string,
  grid: string,
  filter: string,
  settings: any,
  companyId: string,
|};
export type UpdateFilterMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +GridFilter: ?{|
        +update: ?{|
          +id: string,
          +tableId: number,
          +grid: string,
          +filter: string,
          +settings: any,
        |}
      |}
    |}
  |}
|};
export type UpdateFilterMutation = {|
  variables: UpdateFilterMutationVariables,
  response: UpdateFilterMutationResponse,
|};
*/


/*
mutation UpdateFilterMutation(
  $gridId: ID!
  $grid: String!
  $filter: String!
  $settings: JSON!
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      GridFilter(id: $gridId) {
        update(grid: $grid, filter: $filter, settings: $settings) {
          id
          tableId
          grid
          filter
          settings
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "grid"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gridId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "settings"
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "gridId"
              }
            ],
            "concreteType": "GridFilterMutations",
            "kind": "LinkedField",
            "name": "GridFilter",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "filter",
                    "variableName": "filter"
                  },
                  {
                    "kind": "Variable",
                    "name": "grid",
                    "variableName": "grid"
                  },
                  {
                    "kind": "Variable",
                    "name": "settings",
                    "variableName": "settings"
                  }
                ],
                "concreteType": "GridFilter",
                "kind": "LinkedField",
                "name": "update",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tableId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filter",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "settings",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateFilterMutation",
    "selections": (v5/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateFilterMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "b05448feca965f18528fa82dc0b65f72",
    "id": null,
    "metadata": {},
    "name": "UpdateFilterMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateFilterMutation(\n  $gridId: ID!\n  $grid: String!\n  $filter: String!\n  $settings: JSON!\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      GridFilter(id: $gridId) {\n        update(grid: $grid, filter: $filter, settings: $settings) {\n          id\n          tableId\n          grid\n          filter\n          settings\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4b89e6b08df229aac4979b61a776490f';

module.exports = node;
