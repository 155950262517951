import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query quotaOverviewQuery($companyId: ID!, $rowGroupCols: [RowGroup]) {
    Me {
      Company(id: $companyId) {
        Quotas(options: { rowGroupCols: $rowGroupCols }) {
          id
          contract {
            id
            date
            endDate
            tableId
          }
          quota {
            id
            name
            initCreditCache
            usedCreditCache
            unit
            quantity
          }
        }
      }
    }
  }
`
