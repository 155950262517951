import { useTranslation } from 'react-i18next'
import { appointmentAccessReasonEnumList } from '../enumLists'

const AppointmentAccessReasonRendererHelper = (props) => {
  const { value } = props
  const { t } = useTranslation()

  if (!value) {
    return ''
  }

  return t(appointmentAccessReasonEnumList(value))
}

export default AppointmentAccessReasonRendererHelper
