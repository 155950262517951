import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../../../environments/pom/Environment'

const mutation = graphql`
  mutation DeleteAttachmentMutation($companyId: ID!, $fileId: ID!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          deleteLetterAttachment(letterAttachmentId: $fileId)
        }
      }
    }
  }
`

export default (variables = {}, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
