import React, { forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgba(239, 244, 247, 0.4);
`

const Message = styled.p`
  margin: 32px;
  font-size: 24px;
  font-family: 'Quicksand', sans-serif;
`

export default forwardRef((props, ref) => {
  const { api } = props
  const { t } = useTranslation()

  const headerHeight = api?.getSizesForCurrentTheme()?.headerHeight || 0

  useImperativeHandle(ref, () => ({
    getReactContainerStyle() {
      return {
        display: 'inline-block',
        height: '100%',
        width: '100%',
        paddingTop: headerHeight + 'px',
      }
    },
  }))

  return (
    <Wrapper>
      <Message>{t('NO ROWS FOUND')}</Message>
    </Wrapper>
  )
})
