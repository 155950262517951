import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query InventoryGridQuery($companyId: ID!, $usageUnitId: ID!) {
    Me {
      Company(id: $companyId) {
        UsageUnit(id: $usageUnitId) {
          data: inventory(options: { endRow: 2000 }) {
            id
            position {
              id
              room {
                id
                type
              }
            }
            product {
              id
              name
            }
            quantity
            installationDate
          }
        }
      }
    }
  }
`
