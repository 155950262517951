import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../environments/pom/Environment'

const mutation = graphql`
  mutation ChangeUserEmailMutation(
    $companyId: ID!
    $userId: ID!
    $email: String!
  ) {
    Me {
      Company(id: $companyId) {
        User(id: $userId) {
          changeEmail(email: $email)
        }
      }
    }
  }
`

export default (variables, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
