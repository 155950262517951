export const Links = {
  backToHomepage: 'https://www.pyrexx.com',
  facebook: 'https://de-de.facebook.com/Pyrexx.Group/',
  xing: 'https://www.xing.com/companies/pyrexxgmbh',
  linkedin: 'https://www.linkedin.com/company/pyrexx-group/',
  youtube: 'https://www.youtube.com/channel/UC9DRy7sFpxjm3mqOXada98A',
  aboutUs: 'https://www.pyrexx.com/de/ueber-uns/',
  pyrexx: 'https://www.pyrexx.com',
  pcpPage: 'https://www.px-portal.com/vdp/pcp',
  pqpPage: 'https://pqp.pyrexx.com',
  webAppPage: 'https://pyrexx.com/app',
  noImageAvailable: '/img/no-image.jpg',
  serviceCenterEmail: 'service@pyrexx.com',
  contractEmail: 'vertrag@pyrexx.com',
  serviceEmail: 'dienstleistung@pyrexx.com',
}
