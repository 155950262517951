import React from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import InvoicesOverview from './InvoicesOverview'
import Spacer from '../../components/Spacer'

import PyrexxOverviewProvider from '../../providers/PyrexxOverviewProvider'
import getDefaultDateFilter from '../../helper/getDefaultDateFilter'
import handleLocalStorage from '../../helper/handleLocalStorage'
import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useInvoicesGrid } from './gridConfigHooks/useInvoicesGrid'

const Invoices = () => {
  const { t } = useTranslation()

  const grids = [
    {
      id: 'invoices-grid',
      label: '',
      downloadFileName: t('INVOICES'),
      useGetParams: useInvoicesGrid(),
    },
  ]

  const { generateGrid, setFilterModel } = useAgGridUltra('Invoices', grids)

  const customKeys = {
    customDateFilterKey: 'invoiceDate',
  }

  const initialStateUiFilters = [
    getDefaultDateFilter(customKeys?.customDateFilterKey),
  ]
  const initialStateBoxFilters = [handleLocalStorage('get', 'invoicesFilter')]

  return (
    <PyrexxOverviewProvider
      initialStateUiFilters={initialStateUiFilters}
      initialStateBoxFilters={initialStateBoxFilters}
    >
      <PageContainer>
        <PageTitle>{t('INVOICES')}</PageTitle>
        <InvoicesOverview setFilterModel={setFilterModel} />
        <Spacer />
        {generateGrid()}
      </PageContainer>
    </PyrexxOverviewProvider>
  )
}

export default Invoices
