import { useTranslation } from 'react-i18next'
import { noEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeValueGetter } from '../valueGetter'

const NoEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeRenderer = (
  props
) => {
  const { t } = useTranslation()
  return t(
    noEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeValueGetter(props)
  )
}

export default NoEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeRenderer
