import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query PropertyUnitAppointmentHistoryGridQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
  ) {
    Me {
      id
      Company(id: $companyId) {
        data: Appointments(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          type
          date
          timeframe
          accessReason
          status
          initiator
          serviceReason {
            id
            name
          }
          executionDate
          tenant {
            id
            firstname
            lastname
          }
          contract {
            id
            serviceCategory {
              id
              tableId
              name
            }
          }
        }
      }
    }
  }
`
