import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query ContractsContainerQuery($companyId: ID!) {
    Me {
      Company(id: $companyId) {
        Contracts(options: { endRow: -1 }) {
          id
          tableId
          name
          type
          billingPeriods(options: { endRow: -1 }) {
            id
            tableId
            from
            to
          }
          serviceCategory {
            id
            tableId
            name
          }
        }
      }
    }
  }
`
