import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import SimpleAnchor from '../../../components/Simple/SimpleAnchor'

import { Links } from '../../../configs/LinkConfig'

const ContactInformation = () => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <InnerWrapper>
        <Card>
          <Header>{t('SERVICE CENTER')}</Header>

          <ul>
            <li>{t('MAINTENANCE TASKS AND ENQUIRIES TO TICKETS')}</li>
            <li>{t('ENQUIRIES TO APPOINTMENTS')}</li>
          </ul>

          <div>
            <SimpleAnchor href={'mailto:' + Links.serviceCenterEmail}>
              {Links.serviceCenterEmail}
            </SimpleAnchor>
          </div>

          <span>+49 30 74 74 74 74</span>
        </Card>

        <Card>
          <Header>{t('CONTRACT ACCOUNT MANAGEMENT')}</Header>

          <ul>
            <li>{t('AGREEMENTS')}</li>
            <li>{t('CUSTOMER AND INHABITANT PORTAL')}</li>
            <li>{t('INVOICES')}</li>
            <li>{t('DISAGIOS')}</li>
            <li>{t('OPERATING AND INCIDENTAL COSTS')}</li>
            <li>{t('UNSCHEDULED MISSIONS / RETROFITTING')}</li>
          </ul>

          <div>
            <SimpleAnchor href={'mailto:' + Links.contractEmail}>
              {Links.contractEmail}
            </SimpleAnchor>
          </div>

          <span>+49 30 74 74 74 710</span>
        </Card>

        <Card>
          <Header>{t('SERVICE DISTRIBUTION')}</Header>

          <div>
            <SimpleAnchor href={'mailto:' + Links.serviceEmail}>
              {Links.serviceEmail}
            </SimpleAnchor>
          </div>

          <span>+49 30 74 74 74 120</span>
        </Card>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 32px;
  background-color: #fff;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`

const Card = styled.div`
  display: flex;
  flex-flow: column nowrap;

  padding: 24px;
  background-color: #e8edee;

  & > div {
    padding-left: 40px;
    margin-bottom: 8px;
  }

  & > div:nth-child(2) {
    margin: 8px 0;
  }

  & > span {
    padding-left: 40px;
  }
`

const Header = styled.h3`
  margin: 0;
`

export default ContactInformation
