import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Switcher from './Switcher'
import {
  ClearButton,
  Wrapper,
  Searchbox,
  SearchButton,
  SearchInput,
} from './styles'

const CompanySwitcher = () => {
  const { t } = useTranslation()

  const [searchText, setSearchText] = useState('')
  const [isClearButtonHidden, setIsClearButtonHidden] = useState(true)
  const [showSearchBar, setShowSearchBar] = useState(false)

  useEffect(() => {
    if (searchText.length > 0) {
      setIsClearButtonHidden(false)
    } else {
      setIsClearButtonHidden(true)
    }
  }, [searchText])

  return (
    <Wrapper>
      <h1>{t('WELCOME TO THE PYREXX OBJECT MANAGER')}</h1>
      <h2>{t('THE PYREXX SERVICE PORTAL FOR THE REAL ESTATE SECTOR')}</h2>

      <div>
        <p>{t('WHAT PORTFOLIO WOULD YOU LIKE TO VIEW?')}</p>

        {showSearchBar && (
          <Searchbox htmlFor='searchbox-company-switcher'>
            <SearchButton />
            <SearchInput
              type='text'
              name='searchbox-company-switcher'
              placeholder={t('ENTER COMPANY NAME')}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <ClearButton
              isHidden={isClearButtonHidden}
              handleClick={() => setSearchText('')}
            />
          </Searchbox>
        )}
      </div>

      <div>
        <Switcher searchText={searchText} setShowSearchBar={setShowSearchBar} />
      </div>
    </Wrapper>
  )
}

export default CompanySwitcher
