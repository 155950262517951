import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'

import { ButtonIcon, Icon, SimpleNote } from 'pyrexx-react-library'
import SimpleButton from '../Simple/SimpleButton'

import { Row } from '../../routes/User/UserInfo/UploadImageModal'

const Upload = ({ setImgSrc = () => {}, onClose = () => {} }) => {
  const { t } = useTranslation()

  const [uploadedImage, setUploadedImage] = useState(null)
  const [uploadImageError, setUploadImageError] = useState(null)

  const onDropAccepted = useCallback((acceptedFiles) => {
    // console.log({ acceptedFiles })
    setUploadedImage(acceptedFiles[0])
    setUploadImageError(null)

    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        // handleLocalStorage('set', 'temp-img-src', reader.result)
        setImgSrc(reader.result)
        onClose()
      }

      reader.readAsDataURL(file)
    })
    // eslint-disable-next-line
  }, [])

  const onDropRejected = useCallback((rejectedFiles) => {
    console.log({ rejectedFiles })
    setUploadedImage(null)
    setUploadImageError(rejectedFiles[0].errors[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    noClick: false,
    noKeyboard: true,
    maxSize: 7000000,
    maxFiles: 1,
    accept: '.jpg, .jpeg, .png',
  })

  return (
    <>
      <h3>{t('PLEASE UPLOAD YOUR LOGO')}</h3>
      <p>{t('FILE EXTENSIONS: JPG, JPEG, PNG. MAXIMUM FILE SIZE 7MB')}</p>
      <SimpleButton font='quicksand' onClick={open}>
        {t('CLICK HERE TO UPLOAD')}
      </SimpleButton>
      <Row>
        <p>{t('OR USE DRAG AND DROP')}</p>

        {uploadImageError?.message.length > 0 && (
          <SimpleNote text={t(uploadImageError?.message)} noteStatus='error' />
        )}
      </Row>

      <Dropzone {...getRootProps()} className='dropzone'>
        <DropzoneInner isDragActive={isDragActive}>
          <input {...getInputProps()} />
          <Icon icon='drag-and-drop' size='80px' color='#fff' />
        </DropzoneInner>
      </Dropzone>

      {uploadedImage?.name.length > 0 && (
        <StyledSimpleNote
          noteStatus='success'
          text={
            <FilenameRow>
              <Icon icon='file' size='25' />
              <span>{uploadedImage?.name}</span>
              <DeleteImageButton
                icon='close'
                size='15'
                handleClick={() => {
                  setImgSrc(null)
                  setUploadedImage(null)
                }}
              />
            </FilenameRow>
          }
        />
      )}
    </>
  )
}

const Dropzone = styled.div`
  background-color: #c5cfda;
  height: 240px;
  border-radius: 19px;

  padding: 16px;
`

const DropzoneInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  border-width: 4px;
  border-style: dashed;
  border-color: transparent;
  border-radius: 19px;

  transition: border-color 200ms ease-out;

  ${(props) =>
    props.isDragActive &&
    css`
      border-color: #fff;
      transition: border-color 200ms ease-in;
    `}
`

const StyledSimpleNote = styled(SimpleNote)`
  margin-top: 1rem;
`

const FilenameRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  padding: 0.5rem 1rem;
  padding-left: 0;
`

const DeleteImageButton = styled(ButtonIcon)`
  margin-left: 0.5rem;
  color: var(--pyrexx-red, red);
`

export default Upload
