import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Col, Container, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'
import { fetchQuery } from 'react-relay'
import * as Yup from 'yup'

import PageContainer from '../../components/PageContainer'
import LayoutPreview from '../../components/LayoutPreview/LayoutPreview'
import NavBack from '../../components/NavBack'
import Loader from '../../components/LoadingSpinner/Loader'

import environment from '../../environments/pom/Environment'
import { UpdateCompanyLayoutMutation } from '../CreateCompanyLayout/mutations'
import { CompanyLayoutQuery } from './queries'
import { useUserContext } from '../../helper/userContext'
import { Routes } from '../../configs/RouteConfig'

import ImageHeaderOverride from './ImageHeaderOverride'
import SidebarOverride from './SidebarOverride'
import FooterOverride from './FooterOverride'
import { StyledInput } from 'pyrexx-react-library'

const CreateCompanyLayoutEdit = () => {
  const { t } = useTranslation()
  const { user, currentLanguage } = useUserContext()
  const { id } = useParams()

  const [companyLayoutData, setCompanyLayoutData] = useState(false)
  const [containers, setContainers] = useState(false)
  const [containerFormErrors, setContainerFormErrors] = useState([])
  const [formikValuesClone, setFormikValuesClone] = useState(false)

  const [layoutChangeStatus, setLayoutChangeStatus] = useState({
    msg: '',
    status: '',
    open: false,
  })

  const getCompanyLayoutData = useCallback(() => {
    return fetchQuery(
      environment(user.accessToken),
      CompanyLayoutQuery,
      {
        companyId: user.companyId,
        companyLayoutId: id,
      },
      {
        force: true,
      }
    )
      .then((data) => {
        setCompanyLayoutData(data?.Me?.Company?.businessPost?.companyLayout)
        setContainers(
          JSON.parse(data?.Me?.Company?.businessPost?.companyLayout.layout)
        )
      })
      .catch((e) => {
        console.log(e)
      })
  }, [id, user.accessToken, user.companyId])

  useEffect(() => {
    if (!companyLayoutData) {
      getCompanyLayoutData()
    }
  }, [companyLayoutData, getCompanyLayoutData])

  const submitMutation = (
    companyLayoutId,
    containers,
    values,
    overRideSetLoader,
    updateStatus,
    callBack = () => {}
  ) => {
    if (overRideSetLoader) {
      overRideSetLoader(true)
    }

    UpdateCompanyLayoutMutation(
      {
        companyId: user.companyId,
        companyLayoutId: companyLayoutId,
        name: values.name,
        status: updateStatus,
        layout: JSON.stringify(containers, null, 2),
      },
      user.accessToken,
      (finishedLetterData) => {
        setLayoutChangeStatus({
          msg: t('LAYOUT UPDATED SUCCESFULLY'),
          status: 'success',
          open: true,
        })
        callBack()
        if (overRideSetLoader) {
          overRideSetLoader(false)
        }
      },
      () => {
        setLayoutChangeStatus({
          msg: t('UNKNOWN ERROR'),
          status: 'error',
          open: true,
        })
      }
    )
  }

  const getSpecialEditorFormWithoutActiveContainer = useCallback(() => {
    return [
      {
        name: 'footerHeaderSetting',
        formikIncludeSchema: {
          footerKeep: Yup.bool(),
          headerKeep: Yup.bool(),
        },
        formikIncludeInitValues: {
          footerKeep: containers?.footer?.firstPageOnly
            ? !containers?.footer?.firstPageOnly
            : true,
          headerKeep: containers?.header?.firstPageOnly
            ? !containers?.header?.firstPageOnly
            : true,
        },
        getForm: (formikTmp, updateFunction, loading) => {
          return (
            <>
              <Row
                align='center'
                justify='end'
                style={{ marginBottom: '1rem' }}
              >
                <Col>
                  {t(
                    'HERE YOU CAN DECIDE WHETHER YOU WANT TO USE THE HEADER AND FOOTER FROM THE FIRST PAGE. YOUR DECISION AFFECTS ALL SUBSEQUENT PAGES IN THE LETTER'
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: '2rem' }}>
                <Col>
                  <Row>
                    <Col>
                      <StyledInput
                        type='checkbox'
                        name='headerKeep'
                        id='headerKeep'
                        onChange={formikTmp.handleChange}
                        value={formikTmp.values.headerKeep}
                        label={t('KEEP LOGO')}
                        setFieldValue={formikTmp.setFieldValue}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <StyledInput
                        type='checkbox'
                        name='footerKeep'
                        id='footerKeep'
                        onChange={formikTmp.handleChange}
                        value={formikTmp.values.footerKeep}
                        label={t('KEEP FOOTER')}
                        setFieldValue={formikTmp.setFieldValue}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )
        },
      },
    ]
  }, [containers, t])

  const imageHeaderOverride = {
    containerKey: 'header',
    id: 'letterHeader',
    formikIncludeSchema: {
      imgSrc: Yup.string(),
    },
    formikIncludeInitValues: {
      imgSrc: '',
    },
    getForm: (formikTmp, updateFunction, loading) => {
      return (
        <ImageHeaderOverride
          formik={formikTmp}
          updateFunction={updateFunction}
        />
      )
    },
  }

  const sidebarContainerKeyId = {
    containerKey: 'content',
    id: 'sidebarContacts',
  }
  const getSidebarOverride = useCallback(() => {
    return {
      containerKey: 'content',
      id: 'sidebarContacts',
      formikIncludeSchema: {
        [sidebarContainerKeyId.containerKey]: Yup.object().shape({
          [sidebarContainerKeyId.id]: Yup.object().shape({
            data: Yup.string(),
          }),
        }),
      },
      formikIncludeInitValues: {
        [sidebarContainerKeyId.containerKey]: {
          [sidebarContainerKeyId.id]: {
            data: JSON.parse(companyLayoutData.layout)[
              sidebarContainerKeyId.containerKey
            ].find((object) => object.id === sidebarContainerKeyId.id).content,
          },
        },
      },
      getForm: (formikTmp, updateFunction, loading) => {
        if (!formikValuesClone) {
          setFormikValuesClone(formikTmp.values)
        }
        return (
          <SidebarOverride
            loading={loading}
            sidebarContainerKeyId={sidebarContainerKeyId}
            formik={formikTmp}
            updateFunction={updateFunction}
            setFormikValuesClone={setFormikValuesClone}
            formikValuesClone={formikValuesClone}
            setContainerFormErrors={setContainerFormErrors}
            containerFormErrors={containerFormErrors}
          />
        )
      },
    }
  }, [
    companyLayoutData.layout,
    containerFormErrors,
    formikValuesClone,
    sidebarContainerKeyId,
  ])

  const footerContainerKeyId = {
    containerKey: 'footer',
    id: 'letterFooter',
  }

  const getInitValuesFooter = useCallback(() => {
    const data = [
      JSON.parse(companyLayoutData.layout)[footerContainerKeyId.containerKey],
    ].find((object) => object.id === footerContainerKeyId.id).content
    const parser = new DOMParser()
    const temp = parser.parseFromString(data, 'text/html').documentElement
    return temp
  }, [
    companyLayoutData.layout,
    footerContainerKeyId.containerKey,
    footerContainerKeyId.id,
  ])

  const getFooterOverride = useCallback(() => {
    return {
      containerKey: 'footer',
      id: 'letterFooter',
      formikIncludeSchema: {
        [footerContainerKeyId.containerKey]: Yup.object().shape({
          [footerContainerKeyId.id]: Yup.object().shape({
            data1: Yup.string(),
            data2: Yup.string(),
            data3: Yup.string(),
            data4: Yup.string(),
          }),
        }),
      },
      formikIncludeInitValues: {
        [footerContainerKeyId.containerKey]: {
          [footerContainerKeyId.id]: {
            data1:
              getInitValuesFooter().querySelector(
                '#' +
                  footerContainerKeyId.containerKey +
                  footerContainerKeyId.id +
                  '1'
              )?.innerHTML || '',
            data2:
              getInitValuesFooter().querySelector(
                '#' +
                  footerContainerKeyId.containerKey +
                  footerContainerKeyId.id +
                  '2'
              )?.innerHTML || '',
            data3:
              getInitValuesFooter().querySelector(
                '#' +
                  footerContainerKeyId.containerKey +
                  footerContainerKeyId.id +
                  '3'
              )?.innerHTML || '',
            data4:
              getInitValuesFooter().querySelector(
                '#' +
                  footerContainerKeyId.containerKey +
                  footerContainerKeyId.id +
                  '4'
              )?.innerHTML || '',
          },
        },
      },
      getForm: (formikTmp, updateFunction, loading) => {
        if (!formikValuesClone) {
          setFormikValuesClone(formikTmp.values)
        }
        return (
          <FooterOverride
            loading={loading}
            footerContainerKeyId={footerContainerKeyId}
            formik={formikTmp}
            updateFunction={updateFunction}
            setFormikValuesClone={setFormikValuesClone}
            formikValuesClone={formikValuesClone}
            setContainerFormErrors={setContainerFormErrors}
            containerFormErrors={containerFormErrors}
          />
        )
      },
    }
  }, [
    containerFormErrors,
    footerContainerKeyId,
    formikValuesClone,
    getInitValuesFooter,
  ])

  return (
    <PageContainer>
      <NavBack
        title={t('EDIT BUSINESS POST LAYOUT')}
        to={'/' + currentLanguage + Routes.admin + Routes.companyLayouts}
      />
      <Container>
        <Row>
          <Col>
            {!companyLayoutData || !containers ? (
              <Loader />
            ) : (
              <LayoutPreview
                hidePreview
                containerFormikOverride={[
                  imageHeaderOverride,
                  getSidebarOverride(),
                  getFooterOverride(),
                ]}
                adminEdit
                specialEditorFormWithoutActiveContainer={getSpecialEditorFormWithoutActiveContainer()}
                name={companyLayoutData.name}
                status={companyLayoutData.status}
                submitMutation={submitMutation}
                saveButtonTextDraft={t('PUBLISH LAYOUT')}
                saveButtonTextActive={t('UNPUBLISH LAYOUT')}
                id={companyLayoutData.tableId}
                initContainer={containers}
                layoutChangeStatus={layoutChangeStatus}
                setLayoutChangeStatus={setLayoutChangeStatus}
              />
            )}
          </Col>
        </Row>
      </Container>
    </PageContainer>
  )
}

export default CreateCompanyLayoutEdit
