import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query LetterLayoutQuery($companyId: ID!, $letterLayoutId: ID!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          letterLayout(id: $letterLayoutId) {
            id
            tableId
            name
            layout
          }
        }
      }
    }
  }
`
