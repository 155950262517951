import { usageUnitLockPeriodReasonEnumList } from '../../../components/AgGrid/enumLists'

const usageUnitLockPeriodReasonValueGetter = (props) => {
  const { value } = props
  const reason = value && value[0] ? value[0].reason : ''
  if (!reason) {
    return ''
  }
  return usageUnitLockPeriodReasonEnumList(reason)
}

export default usageUnitLockPeriodReasonValueGetter
