import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import NavBack from '../../components/NavBack'
import Spacer from '../../components/Spacer'
import PropertyUnitOverview from './PropertyUnitOverview'
import { Routes } from '../../configs/RouteConfig'
import UserContext from '../../helper/userContext'
import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useUsageUnitGrid } from './gridConfigHooks/useUsageUnitGrid'
import { useStatusOverviewGrid } from './gridConfigHooks/useStatusOverviewGrid'
import { usePerformanceOverviewGrid } from './gridConfigHooks/usePerformanceOverviewGrid'
import { useInventoryGrid } from './gridConfigHooks/useInventoryGrid'
import { useBulletinOverviewGrid } from './gridConfigHooks/useBulletinOverviewGrid'
import { useAppointmentOverviewGrid } from './gridConfigHooks/useAppointmentOverviewGrid'

const PropertyUnit = (props) => {
  const {
    propertyUnit = {},
    propertyUnitId,
    loading = false,
    services = [],
  } = props
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()

  const grids = [
    {
      id: 'usage-unit-overview',
      label: t('USAGE UNIT OVERVIEW'),
      useGetParams: useUsageUnitGrid(),
    },
    {
      id: 'status-overview',
      label: t('STATUS'),
      useGetParams: useStatusOverviewGrid(),
    },
    {
      id: 'appointment-overview',
      label: t('APPOINTMENT OVERVIEW'),
      useGetParams: useAppointmentOverviewGrid(),
    },
    {
      id: 'performance-overview',
      label: t('PERFORMANCE OVERVIEW'),
      useGetParams: usePerformanceOverviewGrid(),
    },
    {
      id: 'inventory-overview',
      label: t('INVENTORY OVERVIEW'),
      useGetParams: useInventoryGrid(),
    },
    {
      id: 'bulletin-overview',
      label: t('BULLETIN OVERVIEW'),
      useGetParams: useBulletinOverviewGrid(),
    },
  ]

  const {
    generateServiceDropDown,
    generatePills,
    currentService,
    setCurrentService,
    generateGrid,
    setFilterVariables,
    filterVariables,
  } = useAgGridUltra('PropertyUnit', grids)

  useEffect(() => {
    if (services.length > 0 && !currentService && propertyUnitId) {
      setCurrentService(services[0])
      setFilterVariables({ propertyUnitId })
    }
  }, [
    currentService,
    propertyUnitId,
    services,
    setCurrentService,
    setFilterVariables,
  ])
  return (
    <PageContainer fluid>
      <NavBack
        title={t('PROPERTY VIEW')}
        to={'/' + currentLanguage + Routes.appointments} // TODO use proper redirection
        style={{ marginBottom: '16px' }}
      />

      <PropertyUnitOverview propertyUnit={propertyUnit} loading={loading} />
      <Spacer />
      {generateServiceDropDown(services)}
      {generatePills()}
      {generateGrid(
        !(!loading && currentService && filterVariables?.propertyUnitId)
      )}
    </PageContainer>
  )
}

export default PropertyUnit

PropertyUnit.propTypes = {
  propertyUnit: PropTypes.object,
  appointmentId: PropTypes.string,
  loading: PropTypes.bool,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.any,
      tableId: PropTypes.any,
      selected: PropTypes.any,
      disabled: PropTypes.any,
    })
  ),
}
