import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import ContractsOverview from './ContractsOverview'
import Spacer from '../../components/Spacer'

import { useAgGridUltra } from '../../components/AgGrid/AgGridUltra/hooks/useAgGridUltra'
import { useFilter1NotEquippedUsageUnitGrid } from './gridsConfigHooks/useFilter1NotEquippedUsageUnitGrid'
import { useFilter2AccessNotGrantedGrid } from './gridsConfigHooks/useFilter2AccessNotGrantedGrid'
import { useFilter3MaintenanceServiceProvidedGrid } from './gridsConfigHooks/useFilter3MaintenanceServiceProvidedGrid'
import { useFilter4SingleServiceProvidedGrid } from './gridsConfigHooks/useFilter4SingleServiceProvidedGrid'
import { useFilter5NoEntryIntoTheUsageUnitWithoutEquipmentGrid } from './gridsConfigHooks/useFilter5NoEntryIntoTheUsageUnitWithoutEquipmentGrid'

import PyrexxOverviewProvider from '../../providers/PyrexxOverviewProvider'
import getDefaultDateFilter from '../../helper/getDefaultDateFilter'

const Contracts = (props) => {
  const { services = [], contracts = [], loading = false } = props
  const { t } = useTranslation()

  const grids = [
    {
      id: 'not-equipped-usage-units',
      label: t('NOT EQUIPPED USAGE UNITS'),
      useGetParams: useFilter1NotEquippedUsageUnitGrid(),
    },
    {
      id: 'access-not-granted-service-not-yet-fully-provided',
      label: t('ACCESS NOT GRANTED / SERVICE NOT YET FULLY PROVIDED'),
      useGetParams: useFilter2AccessNotGrantedGrid(),
    },
    {
      id: 'Maintenance-service-provided-in-full',
      label: t('MAINTENANCE SERVICE PROVIDED IN FULL'),
      useGetParams: useFilter3MaintenanceServiceProvidedGrid(),
    },
    {
      id: 'single-service-provided',
      label: t('SINGLE SERVICE PROVIDED'),
      useGetParams: useFilter4SingleServiceProvidedGrid(),
    },
    {
      id: 'No-entry-into-the-usage-unit-without-equipment',
      label: t('NO ENTRY INTO THE USAGE UNIT WITHOUT EQUIPMENT'),
      useGetParams: useFilter5NoEntryIntoTheUsageUnitWithoutEquipmentGrid(),
    },
  ]

  const {
    generateServiceDropDown,
    generatePills,
    currentService,
    setCurrentService,
    generateGrid,
  } = useAgGridUltra('Contracts', grids)

  useEffect(() => {
    if (services.length > 0 && !currentService) {
      const tmpService = services.filter((item) => item.selected)[0]
      setCurrentService(tmpService)
    }
  }, [currentService, services, setCurrentService])

  const initialStateUiFilters = [getDefaultDateFilter()]

  return (
    <PyrexxOverviewProvider initialStateUiFilters={initialStateUiFilters}>
      <PageContainer fluid>
        <ContractsOverview
          contracts={contracts}
          setFilterModel={() => {}}
          loading={loading}
        />
        <Spacer />

        {generateServiceDropDown(services)}
        {generatePills()}
        {generateGrid(!(!loading && currentService))}
      </PageContainer>
    </PyrexxOverviewProvider>
  )
}

export default Contracts

Contracts.propTypes = {
  loading: PropTypes.bool,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.any,
      tableId: PropTypes.any,
      selected: PropTypes.any,
      disabled: PropTypes.any,
    })
  ),
}
