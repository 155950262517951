import {
  ButtonSecondary,
  Icon,
  portalTheme,
  SimpleNote,
} from 'pyrexx-react-library'
import { Col, Row } from 'react-grid-system'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const UpdateSaveButton = (props) => {
  const { t } = useTranslation()
  const {
    loading = false,
    handleSave = () => {},
    noChangedContent = () => false,
  } = props

  const generateButton = useCallback(() => {
    if (loading) {
      return (
        <ButtonSecondary
          style={{
            margin: '0px',
            height: '2rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
          disabled
        >
          {t('PLEASE WAIT')}
        </ButtonSecondary>
      )
    } else {
      return (
        <ButtonSecondary
          onClick={handleSave}
          disabled={noChangedContent()}
          style={{
            margin: '0px',
            height: '2rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
          }}
        >
          {!noChangedContent() ? (
            t('UPDATE')
          ) : (
            <Row nogutter>
              <Col xs='content' style={{ marginRight: '5px' }}>
                <Icon
                  icon='bullet-point'
                  size={12}
                  style={{ color: portalTheme.color.successColor }}
                />
              </Col>
              <Col xs='content'>{t('SAVED')}</Col>
            </Row>
          )}
        </ButtonSecondary>
      )
    }
  }, [handleSave, loading, noChangedContent, t])

  return (
    <>
      <Row justify='between'>
        <Col xs='content'>
          {!noChangedContent() && (
            <SimpleNote
              text={t('CURRENT CONTENT IST NOT SAVED')}
              noteStatus='error'
              style={{ fontSize: '12px' }}
            />
          )}
        </Col>
        <Col xs='content'>{generateButton()}</Col>
      </Row>
    </>
  )
}

export default UpdateSaveButton
