import React from 'react'
import NotificationSettingsItem from './NotificationSettingsItem'

const NotificationSettingsList = (props) => {
  const {
    id = '',
    notificationsData = [],
    category = {},
    handleNotifications,
  } = props

  return notificationsData.map((n) => (
    <NotificationSettingsItem
      key={id + '-' + n?.id}
      id={id}
      nid={n?.id}
      label={n?.label}
      initialValue={category?.[n?.id]}
      handleNotifications={handleNotifications}
    />
  ))
}
export default NotificationSettingsList
