function dummyTranslate(text) {
  return text
}
const notEquippedUsageUnitStatusValueGetter = (props) => {
  const { data, value } = props
  if (!value || !value.length > 0) {
    return ''
  }

  if (data.lockPeriods.length > 0) {
    return {
      color: 'error',
      msg: dummyTranslate('INCOMPLETE AND TEMPORARILY BLOCKED'),
    }
  }

  switch (value[0].status) {
    case 'OPEN':
      return {
        color: 'warning',
        msg: dummyTranslate('PENDING'),
      }
    case 'DONE':
    case 'CLOSED':
    case 'DEACTIVATED':
    case 'CHECKED':
      return {
        color: 'error',
        msg: dummyTranslate('INCOMPLETE'),
      }
    default:
      return {
        color: 'error',
        msg: dummyTranslate('UNDEFINED STATUS: {STATUS}', {
          STATUS: value[0].status,
        }),
      }
  }
}

export default notEquippedUsageUnitStatusValueGetter
