import React from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonPrimary, SimpleModal, SimpleNote } from 'pyrexx-react-library'

import { ButtonRow } from '../styles'

const BaseGridModal = ({ modal = {}, closeModal = () => {} }) => {
  const { t } = useTranslation()

  return (
    <SimpleModal
      handleModalClose={closeModal}
      isOpen={modal.show}
      closeOnOutsideClick={false}
      showButtonClose={false}
    >
      <SimpleNote text={modal.message} />
      <hr />
      <ButtonRow>
        <ButtonPrimary onClick={closeModal}>{t('I UNDERSTAND')}</ButtonPrimary>
      </ButtonRow>
    </SimpleModal>
  )
}

export default BaseGridModal
