import React, { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import CustomEditor from '../../editor/build/customEditor'
import CKEditor from '../../editor/CKEditor'
import HTMLRendered from '../../editor/HTMLRendered'
import { Col, Row } from 'react-grid-system'
import UpdateSaveButton from '../../components/UpdateSavedButton/UpdateSaveButton'

const FooterOverride = ({
  formik,
  updateFunction,
  loading,
  setFormikValuesClone,
  formikValuesClone,
  setContainerFormErrors,
  containerFormErrors,
  footerContainerKeyId,
}) => {
  const { t } = useTranslation()

  const getStartCurrentScreen = useCallback(() => {
    let count = 1
    if (
      formik.values?.[footerContainerKeyId.containerKey]?.[
        footerContainerKeyId.id
      ]?.data2
    ) {
      count++
    }
    if (
      formik.values?.[footerContainerKeyId.containerKey]?.[
        footerContainerKeyId.id
      ]?.data3
    ) {
      count++
    }
    if (
      formik.values?.[footerContainerKeyId.containerKey]?.[
        footerContainerKeyId.id
      ]?.data4
    ) {
      count++
    }
    return count
  }, [
    footerContainerKeyId.containerKey,
    footerContainerKeyId.id,
    formik.values,
  ])

  // eslint-disable-next-line
  const [footerData, setFooterData] = useState({})
  const [currentScreen, setCurrentScreen] = useState(getStartCurrentScreen())

  const editor1Ref = useRef(null)
  const editor2Ref = useRef(null)
  const editor3Ref = useRef(null)
  const editor4Ref = useRef(null)

  const noChangedContent = useCallback(() => {
    return (
      formik.values?.[footerContainerKeyId.containerKey]?.[
        footerContainerKeyId.id
      ]?.data1 ===
        formikValuesClone?.[footerContainerKeyId.containerKey]?.[
          footerContainerKeyId.id
        ]?.data1 &&
      formik.values?.[footerContainerKeyId.containerKey]?.[
        footerContainerKeyId.id
      ]?.data2 ===
        formikValuesClone?.[footerContainerKeyId.containerKey]?.[
          footerContainerKeyId.id
        ]?.data2 &&
      formik.values?.[footerContainerKeyId.containerKey]?.[
        footerContainerKeyId.id
      ]?.data3 ===
        formikValuesClone?.[footerContainerKeyId.containerKey]?.[
          footerContainerKeyId.id
        ]?.data3 &&
      formik.values?.[footerContainerKeyId.containerKey]?.[
        footerContainerKeyId.id
      ]?.data4 ===
        formikValuesClone?.[footerContainerKeyId.containerKey]?.[
          footerContainerKeyId.id
        ]?.data4
    )
  }, [
    formik.values,
    formikValuesClone,
    footerContainerKeyId.containerKey,
    footerContainerKeyId.id,
  ])

  const renderScreen = (id, currentScreen) => {
    const condition = id <= currentScreen
    // if (!condition) {
    // console.log('remove screen', id);
    // console.log({..});
    // setFooterData((prev) => {
    //   return { ...prev }.delete(id)
    // })
    // }

    return condition
  }

  // const handleSave = () => {
  //   if (editorRef.current) {
  //     const data = editorRef.current.getData()
  //     setData(data)
  //     updateFunction('<div>' + data + '</div>')
  //   }
  // }

  const handleSave = useCallback(() => {
    setFormikValuesClone(formik.values)
    const newContainerFormErrors = containerFormErrors.reduce(
      (prev, current) => {
        if (
          formikValuesClone.containerKey + '_' + formikValuesClone.id !==
          current
        ) {
          return [...prev, current]
        } else {
          return [...prev]
        }
      },
      []
    )
    setContainerFormErrors(newContainerFormErrors)

    updateFunction(
      '<div style="display: flex; justify-content: space-between; flex-flow: row nowrap;">' +
        '<div id="' +
        footerContainerKeyId.containerKey +
        footerContainerKeyId.id +
        '1' +
        '">' +
        formik.values?.[footerContainerKeyId.containerKey]?.[
          footerContainerKeyId.id
        ]?.data1 +
        '</div>' +
        '<div id="' +
        footerContainerKeyId.containerKey +
        footerContainerKeyId.id +
        '2' +
        '">' +
        formik.values?.[footerContainerKeyId.containerKey]?.[
          footerContainerKeyId.id
        ]?.data2 +
        '</div>' +
        '<div id="' +
        footerContainerKeyId.containerKey +
        footerContainerKeyId.id +
        '3' +
        '">' +
        formik.values?.[footerContainerKeyId.containerKey]?.[
          footerContainerKeyId.id
        ]?.data3 +
        '</div>' +
        '<div id="' +
        footerContainerKeyId.containerKey +
        footerContainerKeyId.id +
        '4' +
        '">' +
        formik.values?.[footerContainerKeyId.containerKey]?.[
          footerContainerKeyId.id
        ]?.data4 +
        '</div>' +
        '</div>'
    )
  }, [
    containerFormErrors,
    footerContainerKeyId.containerKey,
    footerContainerKeyId.id,
    formik.values,
    formikValuesClone.containerKey,
    formikValuesClone.id,
    setContainerFormErrors,
    setFormikValuesClone,
    updateFunction,
  ])
  return (
    <div>
      <h3 style={{ margin: '0 0 15px 0' }}>{t('ADD FOOTER')}</h3>
      <p>{t('PLEASE FILL IN THE FOOTER')}</p>

      <div>
        <ColumnRadioWrapper>
          <ColumnRadio>
            <label>
              <input
                type='radio'
                value='1'
                name='column-picker'
                checked={currentScreen === 1}
                onChange={() => setCurrentScreen(1)}
              />
              1 Spaltig
            </label>
            <Screen>
              {renderScreen('1', currentScreen) ? (
                <HTMLRendered data={footerData['1']} />
              ) : (
                <EmptyScreen>1</EmptyScreen>
              )}
            </Screen>
          </ColumnRadio>

          <ColumnRadio>
            <label>
              <input
                type='radio'
                value='2'
                name='column-picker'
                checked={currentScreen === 2}
                onChange={() => setCurrentScreen(2)}
              />
              2 Spaltig
            </label>
            <Screen>
              {renderScreen('2', currentScreen) ? (
                <HTMLRendered data={footerData['2']} />
              ) : (
                <EmptyScreen>2</EmptyScreen>
              )}
            </Screen>
          </ColumnRadio>

          <ColumnRadio>
            <label>
              <input
                type='radio'
                value='3'
                name='column-picker'
                checked={currentScreen === 3}
                onChange={() => setCurrentScreen(3)}
              />
              3 Spaltig
            </label>
            <Screen>
              {renderScreen('3', currentScreen) ? (
                <HTMLRendered data={footerData['3']} />
              ) : (
                <EmptyScreen>3</EmptyScreen>
              )}
            </Screen>
          </ColumnRadio>

          <ColumnRadio>
            <label>
              <input
                type='radio'
                value='4'
                name='column-picker'
                checked={currentScreen === 4}
                onChange={() => setCurrentScreen(4)}
              />
              4 Spaltig
            </label>
            <Screen>
              {renderScreen('4', currentScreen) ? (
                <HTMLRendered data={footerData['4']} />
              ) : (
                <EmptyScreen>4</EmptyScreen>
              )}
            </Screen>
          </ColumnRadio>
        </ColumnRadioWrapper>

        <Parent>
          {renderScreen('1', currentScreen) && (
            <div>
              <ScreenLabel>1. Spalten</ScreenLabel>
              <CKEditor
                editor={CustomEditor.SimpleEditor}
                currentData={
                  formik.values?.[footerContainerKeyId.containerKey]?.[
                    footerContainerKeyId.id
                  ]?.data1
                }
                onChange={(evt, editor) => {
                  formik.setFieldValue(
                    footerContainerKeyId.containerKey +
                      '.' +
                      footerContainerKeyId.id +
                      '.data1',
                    editor.getData()
                  )
                }}
                editorRef={editor1Ref}
                editorHeight={150}
              />
            </div>
          )}

          {renderScreen('2', currentScreen) && (
            <div>
              <ScreenLabel>2. Spalten</ScreenLabel>
              <CKEditor
                editor={CustomEditor.SimpleEditor}
                currentData={
                  formik.values?.[footerContainerKeyId.containerKey]?.[
                    footerContainerKeyId.id
                  ]?.data2
                }
                onChange={(evt, editor) => {
                  formik.setFieldValue(
                    footerContainerKeyId.containerKey +
                      '.' +
                      footerContainerKeyId.id +
                      '.data2',
                    editor.getData()
                  )
                }}
                editorRef={editor2Ref}
                editorHeight={150}
              />
            </div>
          )}

          {renderScreen('3', currentScreen) && (
            <div>
              <ScreenLabel>3. Spalten</ScreenLabel>
              <CKEditor
                editor={CustomEditor.SimpleEditor}
                currentData={
                  formik.values?.[footerContainerKeyId.containerKey]?.[
                    footerContainerKeyId.id
                  ]?.data3
                }
                onChange={(evt, editor) => {
                  formik.setFieldValue(
                    footerContainerKeyId.containerKey +
                      '.' +
                      footerContainerKeyId.id +
                      '.data3',
                    editor.getData()
                  )
                }}
                editorRef={editor3Ref}
                editorHeight={150}
              />
            </div>
          )}

          {renderScreen('4', currentScreen) && (
            <div>
              <ScreenLabel>4. Spalten</ScreenLabel>
              <CKEditor
                editor={CustomEditor.SimpleEditor}
                currentData={
                  formik.values?.[footerContainerKeyId.containerKey]?.[
                    footerContainerKeyId.id
                  ]?.data4
                }
                onChange={(evt, editor) => {
                  formik.setFieldValue(
                    footerContainerKeyId.containerKey +
                      '.' +
                      footerContainerKeyId.id +
                      '.data4',
                    editor.getData()
                  )
                }}
                editorRef={editor4Ref}
                editorHeight={150}
              />
            </div>
          )}
        </Parent>
      </div>

      <Row
        justify='end'
        align='end'
        style={{
          paddingBottom: '20px',
          paddingTop: '20px',
        }}
      >
        <Col>
          <UpdateSaveButton
            loading={loading}
            handleSave={handleSave}
            noChangedContent={noChangedContent}
          />
        </Col>
      </Row>
    </div>
  )
}

const ColumnRadioWrapper = styled.div`
  display: flex;
  gap: 8px;
`

const ColumnRadio = styled.div`
  flex: 1;
`

const Parent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px 8px;
  grid-template-areas:
    '. .'
    '. .';
`
const Screen = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border: 1px solid #ddd;
  padding: 16px;

  min-height: 6rem;
`

const EmptyScreen = ({ children }) => {
  return <EmptyScreenWrapper>{children}</EmptyScreenWrapper>
}

const EmptyScreenWrapper = styled.div`
  font-size: 2rem;
  font-weight: bold;

  margin: 0 auto;
`

const ScreenLabel = styled.p`
  margin: 8px 0;
`

export default FooterOverride
