import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query Filter3MaintenanceServiceProvidedCountQuery(
    $companyId: ID!
    $filters: [Filter]
  ) {
    Me {
      id
      Company(id: $companyId) {
        data: countAppointments(options: { filters: $filters })
      }
    }
  }
`
