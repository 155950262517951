import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ArrowDropdown from '../../../components/ArrowDropdown'
import { SmallerBox } from './UserNotificationSettings.styles'

const NotificationSettingsItem = (props) => {
  const {
    id = '',
    nid = '',
    label = '',
    initialValue,
    handleNotifications = () => {},
  } = props
  const { t } = useTranslation()

  const items = [
    { id: 'i', value: 'i', label: t('IMMEDIATELY') },
    // { id: 'd', label: t('DAILY') },
    { id: 'w', value: 'w', label: t('WEEKLY') },
    // { id: 'm', label: t('MONTHLY') },
    // { id: 'y', label: t('YEARLY') },
    { id: 'n', value: 'n', label: t('NEVER') },
  ]

  const [currentNotification, setCurrentNotification] = useState(
    items.find((i) => i?.value === initialValue)
  )

  const setSelected = (item) => {
    setCurrentNotification(item)
    handleNotifications(id, nid, item?.value)
  }

  return (
    <SmallerBox
      style={{
        justifyContent: 'space-between',
        marginBottom: '16px',
      }}
    >
      <p key={label}>{label}</p>

      <ArrowDropdown
        label={currentNotification?.label}
        dropdownItems={items}
        selected={currentNotification}
        setSelected={setSelected}
      />
    </SmallerBox>
  )
}

NotificationSettingsItem.propTypes = {
  id: PropTypes.string,
  nid: PropTypes.string,
  label: PropTypes.string,
  initialValue: PropTypes.string.isRequired,
  handleNotifications: PropTypes.func,
}

export default NotificationSettingsItem
