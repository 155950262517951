/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LetterEnqueueResultType = "INVALID_LAYOUT" | "LETTER_ALREADY_SENT" | "NONE" | "NO_COST_POSITION" | "NO_RECIPIENT" | "QUOTA_EXCEEDED" | "SUCCESS" | "UNEXPECTED_ERROR" | "%future added value";
export type EnqueueLetterMutationVariables = {|
  companyId: string,
  letterId: string,
|};
export type EnqueueLetterMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +enqueueLetter: ?LetterEnqueueResultType
      |}
    |}
  |}
|};
export type EnqueueLetterMutation = {|
  variables: EnqueueLetterMutationVariables,
  response: EnqueueLetterMutationResponse,
|};
*/


/*
mutation EnqueueLetterMutation(
  $companyId: ID!
  $letterId: ID!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        enqueueLetter(id: $letterId)
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "letterId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessPostMutation",
            "kind": "LinkedField",
            "name": "businessPost",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "letterId"
                  }
                ],
                "kind": "ScalarField",
                "name": "enqueueLetter",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EnqueueLetterMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EnqueueLetterMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "68c43d4c76d0b2aa89549d3281a33fef",
    "id": null,
    "metadata": {},
    "name": "EnqueueLetterMutation",
    "operationKind": "mutation",
    "text": "mutation EnqueueLetterMutation(\n  $companyId: ID!\n  $letterId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        enqueueLetter(id: $letterId)\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a294aae6d76d68122c24aaf60cd6349d';

module.exports = node;
