import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Container, Row } from 'react-grid-system'
import ArrowDropdown from '../../../components/ArrowDropdown'
import { fetchQuery } from 'react-relay'
import environment from '../../../environments/pom/Environment'
import UserContext from '../../../helper/userContext'
import { GetDropdownDataQuery } from './queries'
import { Routes } from '../../../configs/RouteConfig'
import {
  ButtonPrimary,
  CustomCheckboxRadio,
  SimpleLabel,
  SimpleNote,
} from 'pyrexx-react-library'
import { useLocation } from 'react-router-dom'
import { CreateLetterMutation } from './mutations'

const BusinessMailCreate = (props) => {
  const { t } = useTranslation()
  const [firstLoad, setFirstLoad] = useState(true)
  const [loadingDropdown, setLoadingDropdown] = useState(true)
  // eslint-disable-next-line no-unused-vars
  const [letterType, setLetterType] = useState({
    type: 'SINGLE',
    route: Routes.businessMail + Routes.businessMailEdit,
  })
  const [errorMsg, setErrorMsg] = useState('')
  const [companyLayouts, setCompanyLayouts] = useState([])
  const [currentLayout, setCurrentLayout] = useState(false)
  const location = useLocation()
  const [loadingCreateNewLayout, setLoadingCreateNewLayout] = useState(false)

  const { user, redirectRoute } = useContext(UserContext)

  // HARDCODED
  const isBetaEnabled = user.companyId === 'pom::f::24403'

  useEffect(() => {
    if (firstLoad) {
      getDropdownData()
    }
    // eslint-disable-next-line no-use-before-define
  }, [firstLoad, getDropdownData])

  const getDropdownData = useCallback(() => {
    setLoadingDropdown(true)
    return fetchQuery(
      environment(user.accessToken),
      GetDropdownDataQuery,
      {
        companyId: user.companyId,
      },
      {
        force: true,
      }
    )
      .then((data) => {
        const results = data.Me?.Company?.businessPost?.companyLayouts
        if (results.length) {
          const dropDownArray = results.map((layout) => {
            return {
              id: layout.tableId,
              label: layout.name,
              layout: layout.layout,
            }
          })

          setCompanyLayouts(dropDownArray)
          setCurrentLayout({
            id: results[0].tableId,
            label: results[0].name,
            layout: results[0].layout,
          })
        } else {
        }

        setFirstLoad(false)
        setLoadingDropdown(false)
      })
      .catch((e) => {
        console.log('getDropdownData: ERROR CATCH', e)
      })
  }, [user.accessToken, user.companyId])

  if (companyLayouts.length < 1 && !firstLoad) {
    return (
      <Container>
        <Row
          justify='center'
          align='center'
          style={{ backgroundColor: 'white', padding: '30px' }}
        >
          <Col xs='content'>
            <SimpleNote
              text={t(
                'NO COMPANY LAYOUTS FOUND! PLEASE CREATE FIRST ONE IN THE ADMIN AREA'
              )}
              noteStatus='warning'
            />
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container>
      <Row
        justify='center'
        align='center'
        style={{ backgroundColor: 'white', paddingTop: '30px' }}
      >
        <Col xs='content'>
          <ArrowDropdown
            label={currentLayout?.label}
            dropdownItems={companyLayouts}
            selected={currentLayout}
            setSelected={setCurrentLayout}
            description={t('COMPANY LAYOUTS')}
          />
        </Col>
      </Row>
      {errorMsg && (
        <Row
          justify='center'
          align='center'
          style={{
            backgroundColor: 'white',
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          <Col xs='content'>
            <SimpleNote
              text={errorMsg}
              noteStatus='error'
              style={{
                fontSize: '12px',
                lineHeight: 'normal',
                whiteSpace: 'nowrap',
              }}
            />
          </Col>
        </Row>
      )}

      {/* BETA FEATURE */}
      {isBetaEnabled ? (
        <Row
          justify='center'
          align='center'
          style={{
            paddingTop: '30px',
            backgroundColor: 'white',
            paddingBottom: '30px',
          }}
        >
          <Col xs='content'>
            <SimpleLabel>{t('MAIL TYPE')}</SimpleLabel>
          </Col>
          <Col xs='content'>
            <CustomCheckboxRadio
              type='radio'
              name='letterTypeName'
              id='SingleTypeIdTrue'
              onChange={() => {
                setLetterType({
                  type: 'SINGLE',
                  route: Routes.businessMail + Routes.businessMailEdit,
                })
              }}
              isChecked={letterType.type === 'SINGLE'}
              disabled={false}
              labelElement={t('CREATE SINGLE MAIL')}
            />
          </Col>
          <Col xs='content'>
            <CustomCheckboxRadio
              type='radio'
              name='letterTypeName'
              id='MassTypeIdTrue'
              onChange={() => {
                setLetterType({
                  type: 'MASS',
                  route: Routes.businessMail + Routes.businessMailEdit,
                })
              }}
              isChecked={letterType.type === 'MASS'}
              disabled={false}
              labelElement={t('CREATE MASS MAIL')}
            />
          </Col>
        </Row>
      ) : null}

      <Row justify='center' align='center' style={{ marginTop: '20px' }}>
        <Col xs='content'>
          <ButtonPrimary
            disabled={
              loadingDropdown ||
              companyLayouts.length < 1 ||
              loadingCreateNewLayout
            }
            isLoading={loadingDropdown || loadingCreateNewLayout}
            onClick={() => {
              setLoadingCreateNewLayout(true)
              CreateLetterMutation(
                {
                  companyId: user.companyId,
                  companyLayoutId: currentLayout.id,
                  letterType: letterType.type,
                },
                user.accessToken,
                (data) => {
                  setLoadingCreateNewLayout(false)
                  redirectRoute(
                    letterType.route +
                      '/' +
                      data.Me.Company.businessPost.data.tableId,
                    false,
                    location.pathname
                  )
                },
                (errors) => {
                  setLoadingCreateNewLayout(false)
                  console.log(
                    'DEBUG ERRORS HERE_---',
                    errors.source.errors[0].extensions,
                    errors.source.errors[0].extensions.category,
                    errors.source.errors[0].extensions.validation?.letterType
                  )
                  if (
                    errors.source.errors[0] &&
                    errors.source.errors[0].extensions.category ===
                      'validation' &&
                    errors.source.errors[0].extensions?.validation?.letterType
                  ) {
                    setErrorMsg(
                      errors.source.errors[0].extensions?.validation?.letterType
                    )
                  }
                }
              )
            }}
          >
            {t('CREATE MAIL')}
          </ButtonPrimary>
        </Col>
      </Row>
    </Container>
  )
}

export default BusinessMailCreate
