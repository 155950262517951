import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../environments/pom/Environment'

const mutation = graphql`
  mutation UploadSyncFileMutation(
    $companyId: ID!
    $type: SyncFileType!
    $contract: Int!
    $date: DateTime!
    $phoneNumber: String!
    $uploader: String!
    $outfittingPriority: SyncEquipTimeOrderType!
    $email: String!
    $note: String
    $files: [Upload!]!
  ) {
    status: Me {
      Company(id: $companyId) {
        uploadSyncFile(
          type: $type
          contract: $contract
          date: $date
          uploader: $uploader
          equipTimeOrder: $outfittingPriority
          phoneNumber: $phoneNumber
          email: $email
          note: $note
          file: $files
        )
      }
    }
  }
`

export default (variables, accessToken, files, callBack, errorCallBack) => {
  let uploadables

  if (files) {
    uploadables = {
      files,
    }
  }

  commitMutation(environment(accessToken), {
    mutation,
    variables,
    uploadables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
