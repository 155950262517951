import React from 'react'
import { useTranslation } from 'react-i18next'
import { invoiceTypeValueGetter } from '../valueGetter'

const InvoiceTypeRenderer = (props) => {
  const { t } = useTranslation()

  return <span>{t(invoiceTypeValueGetter(props))}</span>
}

export default InvoiceTypeRenderer
