/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SwitcherQueryVariables = {||};
export type SwitcherQueryResponse = {|
  +Me: ?{|
    +Companies: ?$ReadOnlyArray<?{|
      +id: string,
      +name: string,
    |}>
  |}
|};
export type SwitcherQuery = {|
  variables: SwitcherQueryVariables,
  response: SwitcherQueryResponse,
|};
*/


/*
query SwitcherQuery {
  Me {
    Companies {
      id
      name
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "CompanyInfo",
  "kind": "LinkedField",
  "name": "Companies",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SwitcherQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SwitcherQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f1a14b9c1b6c5f6482a4d9ecd8bd754",
    "id": null,
    "metadata": {},
    "name": "SwitcherQuery",
    "operationKind": "query",
    "text": "query SwitcherQuery {\n  Me {\n    Companies {\n      id\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '841d7cda51e1c1758658809d57a27b0f';

module.exports = node;
