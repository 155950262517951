function dummyTranslate(text) {
  return text
}

const operationsStatusEnumList = (value) => {
  switch (value) {
    case 'OPEN':
      return dummyTranslate('OPEN')
    case 'SEEN':
      return dummyTranslate('COMPLETED')
    case 'NONE':
    default:
      return ''
  }
}

export default operationsStatusEnumList
