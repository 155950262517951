import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { CountTenantHistoryQuery, TenantHistoryGridQuery } from '../queries'
import {
  contactValueGetter,
  dateTimeValueGetter,
  tenantFullNameValueGetter,
  tenantStatusValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { ContactRenderer, TenantStatusRenderer } from '../renderers'
import { DateRenderer } from '../../../components/AgGrid/renderers'
import { TenantStatusFilter } from '../filters'
import { getPriorityHeightFromDisplayedColumns } from '../../../helper/calculateRowHeight'

export const useTenantHistoryGrid = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { usageUnitId } = filterVariables
      const getRowHeight = (params) => {
        const displayedColumns =
          params.node.columnApi?.getAllDisplayedVirtualColumns()
        const data = params?.data
        const getWidth = (field) =>
          params.node.columnApi?.getColumn?.(field)?.getActualWidth?.()

        const columnParams = [
          {
            field: 'contact',
            value: data?.contact,
            fieldProp: 'value',
            colWidth: getWidth('contact'),
            isLastCol: true,
          },
        ]

        return getPriorityHeightFromDisplayedColumns({
          columnParams,
          displayedColumns,
        })
      }
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t(tenantStatusValueGetter(params))
          case 'fullname':
            return t(
              tenantFullNameValueGetter({
                value: {
                  firstname: params?.node?.data?.firstname,
                  lastname: params?.node?.data?.lastname,
                },
              })
            )
          case 'begin':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          case 'end':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'P',
            })
          case 'contact':
            return contactValueGetter(params)
          default:
            return params.value
        }
      }
      const TenantHistoryColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          field: 'status',
          width: 80,
          filter: 'TenantStatusFilter',
          cellRenderer: TenantStatusRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('NAME'),
          field: 'fullname',
          width: 70,
          sortable: false,
          ...withFilterParams,
        },
        {
          headerName: t('BEGIN'),
          field: 'begin',
          width: 70,
          sort: 'desc',
          cellRenderer: DateRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('END'),
          field: 'end',
          width: 70,
          cellRenderer: DateRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('CONTACT'),
          field: 'contact',
          width: 70,
          sortable: false, // TODO array of objects, do we need s/f?
          filter: false,
          cellRenderer: ContactRenderer,
        },
      ]
      const TenantHistoryCustomFilter = []
      return {
        query: TenantHistoryGridQuery,
        columnDefs: TenantHistoryColumnDefs,
        customFilters: TenantHistoryCustomFilter,
        countQuery: CountTenantHistoryQuery,
        extractor: (response) => response.Me?.Company?.UsageUnit?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return ['copy']
          },
          components: {
            TenantStatusFilter,
          },
          getRowHeight,
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { usageUnitId },
      }
    },
    [currentLanguage, t]
  )
  return { getParams }
}
