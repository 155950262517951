import React, { useCallback, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

import { ButtonPrimary, SimpleModal, SimpleNote } from 'pyrexx-react-library'
import getCroppedImg from '../../components/ImageCropper/cropImage'
import Upload from './UploadVariant'
import { CenterRow } from '../../styles/HelperStylesComponents.styles'

import {
  CLOSE_COMPONENT,
  initialState,
  SUBMIT_FAILED,
  submitReducer,
} from '../../reducers/submitReducer'

const OUTPUT_IMAGE_DIMENSIONS = { width: 500, height: 300 }

const POSITION_MAP = {
  left: 'display: flex; justify-content: flex-start; height: 100%; width: 100%;',
  center: 'display: flex; justify-content: center; height: 100%; width: 100%;',
  right: 'display: flex; justify-content: flex-end; height: 100%; width: 100%;',
}

const ImageHeaderOverride = ({ formik, updateFunction, loading }) => {
  const { t } = useTranslation()

  const [image, setImage] = useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [state, dispatch] = useReducer(submitReducer, initialState)
  // eslint-disable-next-line
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  // eslint-disable-next-line
  const saveCroppedImage = useCallback(async () => {
    try {
      // croppedImage -> { blob: null, base64: null }
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        undefined,
        undefined,
        OUTPUT_IMAGE_DIMENSIONS
      )
      // console.log('done', { croppedImage })
      return croppedImage
      // setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, image])

  const onError = () => {
    dispatch({
      type: SUBMIT_FAILED,
      payload: t('ERROR UPLOADING LOGO. TRY AGAIN'),
    })
    console.log('Error uploading logo')
  }

  const [imgPosition, setImagePosition] = React.useState('left')

  const onRatioChange = (newPos) => {
    setImagePosition(newPos)
  }

  const uploadImage = (imgSrc) => {
    try {
      updateFunction(
        `<div style="${POSITION_MAP[imgPosition]}"><img id="imgContainer" height="100%" src="${imgSrc}" alt="header"/></div>`
      )
    } catch {
      onError()
    }
  }

  return (
    <div>
      <Wrapper>
        <LeftCol noImage={!image}>
          <Upload setImgSrc={setImage} />
        </LeftCol>

        {image && (
          <RightCol>
            <ImageContainer image={image} />
          </RightCol>
        )}
      </Wrapper>

      {image && (
        <RadioWrapper>
          <RadioElement
            align='left'
            imgPosition={imgPosition}
            onRatioChange={onRatioChange}
          />
          <RadioElement
            align='center'
            imgPosition={imgPosition}
            onRatioChange={onRatioChange}
          />
          <RadioElement
            align='right'
            imgPosition={imgPosition}
            onRatioChange={onRatioChange}
          />
        </RadioWrapper>
      )}

      {image && (
        <CenterRow>
          <ButtonPrimary
            onClick={() => uploadImage(image)}
            isLoading={state.loading}
          >
            {t('UPLOAD')}
          </ButtonPrimary>
        </CenterRow>
      )}

      <SimpleModal
        isOpen={state.showModal}
        handleModalClose={() => dispatch({ type: CLOSE_COMPONENT })}
      >
        <SimpleNote text={state.message} noteStatus={state.status} />
      </SimpleModal>
    </div>
  )
}

const ImageContainer = ({ image }) => {
  return (
    <ImageContainerWrapper>
      <Image src={image} alt='uploaded logo' />
    </ImageContainerWrapper>
  )
}

const ImageContainerWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const Image = styled.img`
  border: 0.5px solid #666;
  max-height: 300px;
  max-width: 100%;
  object-fit: contain;
`

const RadioElement = ({
  imgPosition = 'left',
  align = 'left',
  onRatioChange = () => {},
}) => {
  return (
    <RadioElementLabel>
      <input
        type='radio'
        name={align}
        value={align}
        checked={imgPosition === align}
        onChange={() => onRatioChange(align)}
      />
      <ButtonDirection align={align} checked={imgPosition === align} />
    </RadioElementLabel>
  )
}

const RadioElementLabel = styled.label`
  & > input {
    display: none;
  }
`

const ButtonDirection = ({ align = 'left', checked = false }) => {
  const map = {
    left: 'left: 10px;',
    center: 'left: 50%;',
    right: 'right: 10px;',
  }

  return (
    <ButtonDirectionWrapper checked={checked}>
      <Square />
      <Line align={map[align]} />
    </ButtonDirectionWrapper>
  )
}

const ButtonDirectionWrapper = styled.div`
  display: flex;
  align-items: center;

  position: relative;
  scale: 0.75;
  opacity: 0.5;

  padding: 25px 16px;
  border: 2px solid #dfdfdf;
  border-radius: 5px;

  ${(props) => props.checked && 'border-color: #83a8cb; opacity: 1;'};
`
const Square = styled.div`
  width: 50px;
  height: 32px;

  background-color: #dfdfdf;
`
const Line = styled.div`
  position: absolute;
  ${(props) => props.align};

  height: 40px;
  width: 2px;

  background-color: black;
`

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-flow: column nowrap;
`

const RadioWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;

  padding: 32px;
`

const LeftCol = styled.div`
  & > .dropzone {
    height: 200px;
  }
`

const RightCol = styled.div``

export default ImageHeaderOverride
