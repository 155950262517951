import React from 'react'

import ContactInformation from './ContactInformation'
import ContactForm from './ContactForm'

import { SupportColumn, SupportContainer } from '../Support.styles'

const Contact = () => {
  return (
    <SupportContainer>
      <SupportColumn>
        <ContactInformation />
      </SupportColumn>

      <SupportColumn>
        <ContactForm />
      </SupportColumn>
    </SupportContainer>
  )
}

export default Contact
