// Source: https://www.ag-grid.com/documentation/react/filter-provided-simple/
const TYPE_REGULAR_SQUEMA = {
  type: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['T1', 'T2'],
    },
  },
}

const TYPE_SERVICE_SQUEMA = {
  type: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['TI', 'TS'],
    },
  },
}

const TYPE_SPECIAL_SQUEMA = {
  type: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['TX', 'TA'],
    },
  },
}

const STATUS_DONE_SQUEMA = {
  status: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['DONE', 'CLOSED', 'CHECKED'],
    },
  },
}

const STATUS_PENDING_SQUEMA = {
  status: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['OPEN', 'DIAGNOSE_PENDING'],
    },
  },
}

const STATUS_NOT_DONE_SQUEMA = {
  status: {
    filterType: 'text',
    operator: 'AND',
    condition1: {
      filterType: 'text',
      type: 'equals',
      filter: ['DEACTIVATED'],
    },
  },
}

export const filterRegular = {
  ...TYPE_REGULAR_SQUEMA,
}

export const filterService = {
  ...TYPE_SERVICE_SQUEMA,
}

export const filterSpecial = {
  ...TYPE_SPECIAL_SQUEMA,
}

export const filterDone = {
  ...STATUS_DONE_SQUEMA,
}

export const filterPending = {
  ...STATUS_PENDING_SQUEMA,
}

export const filterNotDone = {
  ...STATUS_NOT_DONE_SQUEMA,
}

export const filterRegularDone = {
  ...TYPE_REGULAR_SQUEMA,
  ...STATUS_DONE_SQUEMA,
}

export const filterServiceDone = {
  ...TYPE_SERVICE_SQUEMA,
  ...STATUS_DONE_SQUEMA,
}

export const filterSpecialDone = {
  ...TYPE_SPECIAL_SQUEMA,
  ...STATUS_DONE_SQUEMA,
}

export const filterRegularPending = {
  ...TYPE_REGULAR_SQUEMA,
  ...STATUS_PENDING_SQUEMA,
}

export const filterServicePending = {
  ...TYPE_SERVICE_SQUEMA,
  ...STATUS_PENDING_SQUEMA,
}

export const filterSpecialPending = {
  ...TYPE_SPECIAL_SQUEMA,
  ...STATUS_PENDING_SQUEMA,
}

export const filterRegularNotDone = {
  ...TYPE_REGULAR_SQUEMA,
  ...STATUS_NOT_DONE_SQUEMA,
}

export const filterServiceNotDone = {
  ...TYPE_SERVICE_SQUEMA,
  ...STATUS_NOT_DONE_SQUEMA,
}

export const filterSpecialNotDone = {
  ...TYPE_SPECIAL_SQUEMA,
  ...STATUS_NOT_DONE_SQUEMA,
}
