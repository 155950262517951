/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type LetterStatusType = "DRAFT" | "NONE" | "PENDING" | "SENT" | "%future added value";
export type LetterType = "MASS" | "NONE" | "SINGLE" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type LettersGridQueryVariables = {|
  companyId: string,
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
|};
export type LettersGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +data: ?$ReadOnlyArray<?{|
          +id: string,
          +tableId: number,
          +sender: ?{|
            +id: string,
            +tableId: number,
            +firstname: ?string,
            +lastname: ?string,
          |},
          +createdAt: any,
          +status: ?LetterStatusType,
          +internalTitle: ?string,
          +title: ?string,
          +type: ?LetterType,
          +sentAt: ?any,
          +recipient1FirstName: ?string,
          +recipient1LastName: ?string,
          +addressStreetAndNo: ?string,
          +addressZip: ?string,
          +addressCity: ?string,
          +pageCount: number,
          +countRecipients: ?number,
          +countAttachments: ?number,
        |}>
      |}
    |}
  |}
|};
export type LettersGridQuery = {|
  variables: LettersGridQueryVariables,
  response: LettersGridQueryResponse,
|};
*/


/*
query LettersGridQuery(
  $companyId: ID!
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        data: letters(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
          id
          tableId
          sender {
            id
            tableId
            firstname
            lastname
          }
          createdAt
          status
          internalTitle
          title
          type
          sentAt
          recipient1FirstName
          recipient1LastName
          addressStreetAndNo
          addressZip
          addressCity
          pageCount
          countRecipients
          countAttachments
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessPost",
  "kind": "LinkedField",
  "name": "businessPost",
  "plural": false,
  "selections": [
    {
      "alias": "data",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "endRow",
              "variableName": "endRow"
            },
            {
              "kind": "Variable",
              "name": "filters",
              "variableName": "filters"
            },
            {
              "kind": "Variable",
              "name": "sorting",
              "variableName": "sortModel"
            },
            {
              "kind": "Variable",
              "name": "startRow",
              "variableName": "startRow"
            }
          ],
          "kind": "ObjectValue",
          "name": "options"
        }
      ],
      "concreteType": "Letter",
      "kind": "LinkedField",
      "name": "letters",
      "plural": true,
      "selections": [
        (v6/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "UserInfo",
          "kind": "LinkedField",
          "name": "sender",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastname",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sentAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipient1FirstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipient1LastName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressStreetAndNo",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressZip",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressCity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pageCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countRecipients",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "countAttachments",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "LettersGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "LettersGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "76f8cfbfe5b378dd8e07f92cd433f5b9",
    "id": null,
    "metadata": {},
    "name": "LettersGridQuery",
    "operationKind": "query",
    "text": "query LettersGridQuery(\n  $companyId: ID!\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        data: letters(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n          id\n          tableId\n          sender {\n            id\n            tableId\n            firstname\n            lastname\n          }\n          createdAt\n          status\n          internalTitle\n          title\n          type\n          sentAt\n          recipient1FirstName\n          recipient1LastName\n          addressStreetAndNo\n          addressZip\n          addressCity\n          pageCount\n          countRecipients\n          countAttachments\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b29903132a064b336bf211e30a3526ec';

module.exports = node;
