import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;

  background-color: #f2f2f2;
  height: 100%;
  width: 100%;

  //& > * {
  //  opacity: 0.5;
  //}
`

const DisabledImg = styled.img`
  width: 80px;
`

const NotAvailableContent = ({ imgSrc = '' }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      {imgSrc && <DisabledImg src={imgSrc} alt='' />}

      <span>{t('NOT AVAILABLE')}</span>
    </Wrapper>
  )
}

export default NotAvailableContent
