import { useState } from 'react'

export default function useFilterCheckbox(initialState = {}) {
  const [uiFilter, setUiFilter] = useState(initialState)

  const toggleCheckbox = (checkbox = '') => {
    setUiFilter((prevState) => ({
      ...prevState,
      [checkbox]: !prevState[checkbox],
    }))
  }

  const setAllCheckboxes = (allCheckboxValue) => {
    setUiFilter((prevState) => {
      const draft = prevState
      Object.keys(draft).forEach((key) => {
        draft[key] = allCheckboxValue
      })
      return { ...prevState, ...draft }
    })
  }

  return [uiFilter, toggleCheckbox, setAllCheckboxes]
}
