import { useTranslation } from 'react-i18next'
import ConfirmOverlay from '../ConfirmOverlay/ConfirmOverlay'
import React, { useContext } from 'react'
import UserContext from '../../../../../../helper/userContext'
import UpdateFilterMutation from '../mutations/UpdateFilterMutation'

const ConfirmNameChange = (props) => {
  const {
    setOpenFromStart,
    confirmEditActive,
    setConfirmEditActive,
    getFiltersData,
    gridStateName,
    gridState,
    setLoadingFilter,
  } = props
  const { t } = useTranslation()
  const { user } = useContext(UserContext)

  return (
    <ConfirmOverlay
      cancelClick={() => {
        setOpenFromStart(false)
        setConfirmEditActive(false)
      }}
      confirmClick={() => {
        setLoadingFilter(true)
        setConfirmEditActive(false)
        setOpenFromStart(false)
        const variables = {
          gridId: confirmEditActive?.id,
          grid: gridStateName,
          filter: confirmEditActive.name,
          settings: JSON.stringify(gridState),
          companyId: user.companyId,
        }
        UpdateFilterMutation(variables, user.accessToken, (data) => {
          if (data.Me?.Company?.GridFilter?.update) {
            getFiltersData(data.Me.Company.GridFilter.update.tableId)
          }
        })
      }}
      confirmButtonLabel={t('CHANGE NOW')}
      noteColor='warning'
      name={confirmEditActive?.name}
      infoText={t(
        'ATTENTION IF YOU SAVE THE CHANGES FOR THIS FILTER IT WILL BE COMPANY-WIDE'
      )}
    />
  )
}
export default ConfirmNameChange
