import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query Filter3MaintenanceServiceProvidedQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        data: Appointments(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          registrations(
            options: { rowGroupCols: [{ id: "actionPosition.id" }] }
          ) {
            id
            actionPosition {
              id
              name
            }
          }
          usageUnit {
            id
            referenceNumber
            tableId
          }
          address {
            city
            street
            number
          }
          tenant {
            id
            firstname
            lastname
          }
          referenceNumber
          accessReason
          executionDate
          type
          billingPartition {
            id
            name
          }
        }
      }
    }
  }
`
