import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../environments/pom/Environment'

const mutation = graphql`
  mutation CreateCompanyLayoutMutation($companyId: ID!, $letterLayoutId: Int!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          createCompanyLayout(letterLayoutId: $letterLayoutId) {
            id
            tableId
          }
        }
      }
    }
  }
`

export default (variables = {}, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
