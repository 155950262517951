function dummyTranslate(text) {
  return text
}

const lettersTypeEnumList = (value) => {
  switch (value) {
    case 'SINGLE':
      return dummyTranslate('SINGLE LETTER')

    case 'MASS':
      return dummyTranslate('MASS LETTER')

    case 'NONE':
    default:
      return ''
  }
}

export default lettersTypeEnumList
