import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-grid-system'
import { ButtonSecondary, StyledInput } from 'pyrexx-react-library'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import UserContext from '../../../../../../helper/userContext'
import AddFilterMutation from '../mutations/AddFilterMutation'
import styled from 'styled-components/macro'
import { LinkButtonStyled } from '../../../../../../styles/HelperStylesComponents.styles'

const Line = styled.hr`
  border: 0;
  clear: both;
  display: block;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-right: -20px;
  background-color: grey;
  height: 1px;
`

const NewFilterAdd = (props) => {
  const {
    getFiltersData,
    gridStateName,
    gridState,
    setOpenFromStart,
    filterOptions,
    setLoadingFilter,
    loadingFilter,
    currentFilter,
    setCurrentFilter,
    setEditActive,
    setConfirmEditActive,
    setFilterModel,
    gridColumnApi,
    gridApi,
  } = props
  const { t } = useTranslation()
  const { user } = useContext(UserContext)

  Yup.addMethod(Yup.string, 'nameDoublette', function (errorMessage) {
    return this.test(`nameDoublette`, errorMessage, function (value) {
      const { path, createError } = this
      return (
        !filterOptions.find((item) => item.filter === value) ||
        createError({ path, message: errorMessage })
      )
    })
  })

  const schema = Yup.object({
    name: Yup.string()
      .min(3, t('NAME MUST BE AT LEAST 3 CHARACTERS'))
      .required(t('NAME MUST BE AT LEAST 3 CHARACTERS'))
      .nameDoublette(t('FILTER NAME ALREADY EXISTS')),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: schema,
    onSubmit: (values, formikb) => {
      setOpenFromStart(true)
      setLoadingFilter(true)
      const variables = {
        grid: gridStateName,
        filter: values.name,
        settings: JSON.stringify(gridState),
        companyId: user.companyId,
      }
      AddFilterMutation(variables, user.accessToken, (data) => {
        const filterTableId = data.Me?.Company?.createGridFilter?.tableId
        if (filterTableId) {
          getFiltersData(filterTableId)
        }
        setOpenFromStart(false)
      })
      formik.resetForm()
    },
  })

  if (Object.keys(gridState).length > 0 && !loadingFilter) {
    return (
      <>
        <Row justify='between'>
          <Col>{t('NEW CUSTOM FILTER')}</Col>
          <Col xs='content'>
            {currentFilter && (
              <Row>
                <Col
                  xs='content'
                  style={{
                    marginBottom: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LinkButtonStyled
                    style={{ fontSize: '12px' }}
                    type='button'
                    onClick={() => {
                      setOpenFromStart(true)
                      setEditActive(false)
                      setConfirmEditActive({
                        id: currentFilter.tableId,
                        name: currentFilter.filter,
                      })
                      return false
                    }}
                  >
                    {t('SAVE CHANGES')}
                  </LinkButtonStyled>
                </Col>
              </Row>
            )}
            <Row>
              <Col
                xs='content'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <LinkButtonStyled
                  style={{ fontSize: '12px' }}
                  type='button'
                  onClick={() => {
                    setOpenFromStart(true)
                    setCurrentFilter(false)
                    setFilterModel({})
                    gridColumnApi.resetColumnState()
                    gridApi.refreshHeader()
                    gridColumnApi.autoSizeAllColumns()
                    setTimeout(() => {
                      setOpenFromStart(false)
                    }, 1000)
                    return false
                  }}
                >
                  {t('RESET FILTER')}
                </LinkButtonStyled>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <StyledInput
              type='input'
              name='name'
              id='name'
              onChange={formik.handleChange}
              value={formik.values.name}
              label={t('NEW FILTER NAME')}
              feedbackMessage={formik.touched.name && formik.errors.name}
              feedbackStyle='invalid'
              setFieldValue={formik.setFieldValue}
            />
          </Col>
        </Row>
        <Row nogutter justify='end'>
          <Col xs='content'>
            <ButtonSecondary
              onClick={() => {
                formik.handleSubmit()
              }}
              style={{
                margin: '0px',
                height: '2rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              }}
            >
              {t('SAVE AS MY TEAM FILTER')}
            </ButtonSecondary>
          </Col>
        </Row>
        <Line />
      </>
    )
  } else {
    return ''
  }
}
export default NewFilterAdd
