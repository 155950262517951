import React, { useState } from 'react'
import styled from 'styled-components/macro'

const Message = styled.p`
  color: #d1462f;
  margin: 4px 0 24px 0;
  height: 15px;
  font-size: 12px;

  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`

const ErrorMessage = ({ show = false, message = '' }) => {
  return <Message show={show}>{message}</Message>
}

const useError = (initialState = { status: false, message: '' }) => {
  const [error, setError] = useState(initialState)

  const setErrorMessage = React.useCallback(
    (message) => setError({ status: true, message }),
    []
  )
  const clearError = React.useCallback(
    () => setError({ status: false, message: '' }),
    []
  )

  const ErrorMessageComponent = () => {
    return <ErrorMessage show={error.status} message={error.message} />
  }

  return [ErrorMessageComponent, setErrorMessage, clearError]
}

export default useError
