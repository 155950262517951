import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import {
  AppointmentAccessRenderer,
  AppointmentNotGrantedStatusRenderer,
  AppointmentTypeRenderer,
  TenantFullNameRenderer,
  UsageUnitReferenceNumberRenderer,
} from '../renderers'
import {
  Filter2AccessNotGrantedCountQuery,
  Filter2AccessNotGrantedQuery,
} from '../queries'
import {
  appointmentTypeValueGetter,
  dateTimeValueGetter,
  tenantFullNameValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { DateRenderer } from '../../../components/AgGrid/renderers'
import appointmentAccessReasonValueGetter from '../valueGetter/appointmentAccessReasonValueGetter'
import { AppointmentIdRenderer } from '../../Appointments/renderers'

export const useFilter2AccessNotGrantedGrid = () => {
  const { currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService) => {
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t('ACCESS NOT GRANTED')
          case 'tenant':
            return tenantFullNameValueGetter(params)
          case 'accessReason':
            return t(appointmentAccessReasonValueGetter(params))
          case 'executionDate':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'Pp',
            })
          case 'type':
            return t(appointmentTypeValueGetter(params))
          default:
            return params.value
        }
      }

      /**
       const getRowHeight = (params) => {
    return calculateRowHeightWithParams({
      params,
      field: 'accessReason',
    })
  }**/
      const Filter2AccessNotGrantedColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          colId: 'status',
          width: 70,
          sortable: false,
          filter: false,
          cellRenderer: AppointmentNotGrantedStatusRenderer,
        },
        {
          headerName: t('ALLOCATION NUMBER'),
          field: 'usageUnit.tableId',
          cellRenderer: UsageUnitReferenceNumberRenderer,
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('APPOINTMENT ID'),
          field: 'tableId',
          width: 70,
          hide: true,
          cellRenderer: AppointmentIdRenderer,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('STREET'),
          colId: 'address.street',
          field: 'address.street',
          width: 100,
          ...withFilterParams,
        },
        {
          headerName: t('STREET NR.'),
          field: 'address.number',
          width: 70,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('CITY'),
          field: 'address.city',
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('RESIDENT'),
          field: 'tenant',
          width: 110,
          cellRenderer: TenantFullNameRenderer,
          filter: false, // TODO: Make filterable?
        },
        {
          headerName: t('ACCESS'),
          field: 'accessReason',
          width: 80,
          cellRenderer: AppointmentAccessRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('ACCESS DATE'),
          field: 'executionDate',
          width: 100,
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'Pp',
          },
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
        },
        {
          headerName: t('APPOINTMENT TYPE'),
          field: 'type',
          width: 100,
          cellRenderer: AppointmentTypeRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('CONTRACT PARTITION'),
          field: 'billingPartition.name',
          width: 140,
          ...withFilterParams,
        },
      ]
      const Filter2AccessNotGrantedCustomFilter = [
        {
          name: 'contract.serviceCategory.tableId',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: [currentService.tableId],
              type: 'equals',
            },
          ],
        },
        {
          filterType: 'text',
          operator: 'OR',
          name: 'accessReason',
          conditions: [
            {
              type: 'equals',
              value: [
                'NO_ACCESS_AWAY',
                'NO_ACCESS_DENIED',
                'NO_ACCESS_VACANT_NO_KEY',
                'NO_ACCESS_TENANT_OWNS_RM',
                'NO_ACCESS_RENOVATION',
                'NO_ACCESS_DENIED_NEED_RESPITE',
                'NO_ACCESS_NO_ADULT_ON_SITE',
                'NO_ACCESS_VACANT_KEY_DOES_NOT_FIT',
                'NO_ACCESS_HYGIENICALLY_INFEASIBLE',
                'NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE',
                'NO_ACCESS_PARTLY_DONE_SOME_LOCATIONS_LOCKED',
                'NO_ACCESS_FOREIGN_SD_INSTALLED_NO_MAINTENANCE',
                'NO_ACCESS_RAMSHACKLE_SURFACE',
                'NO_ACCESS_INFEASIBLE',
              ],
            },
          ],
        },
      ]
      return {
        query: Filter2AccessNotGrantedQuery,
        columnDefs: Filter2AccessNotGrantedColumnDefs,
        customFilters: Filter2AccessNotGrantedCustomFilter,
        countQuery: Filter2AccessNotGrantedCountQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
      }
    },
    [currentLanguage, t]
  )
  return { getParams }
}
