import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query PropertyManagementContainerQuery($companyId: ID!) {
    Me {
      Company(id: $companyId) {
        countHouseEntrances
        Contracts(
          options: {
            endRow: -1
            filters: [
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [{ value: ["ACTIVE"], type: equals }]
              }
            ]
          }
        ) {
          id
          tableId
          name
          serviceCategory {
            id
            tableId
            name
          }
        }
      }
    }
  }
`
