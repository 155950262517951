/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChangeUserEmailMutationVariables = {|
  companyId: string,
  userId: string,
  email: string,
|};
export type ChangeUserEmailMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +User: ?{|
        +changeEmail: ?boolean
      |}
    |}
  |}
|};
export type ChangeUserEmailMutation = {|
  variables: ChangeUserEmailMutationVariables,
  response: ChangeUserEmailMutationResponse,
|};
*/


/*
mutation ChangeUserEmailMutation(
  $companyId: ID!
  $userId: ID!
  $email: String!
) {
  Me {
    Company(id: $companyId) {
      User(id: $userId) {
        changeEmail(email: $email)
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "userId"
              }
            ],
            "concreteType": "UserMutations",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "email",
                    "variableName": "email"
                  }
                ],
                "kind": "ScalarField",
                "name": "changeEmail",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeUserEmailMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ChangeUserEmailMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "594de9a9c7e9a069d7bde3ab72243da3",
    "id": null,
    "metadata": {},
    "name": "ChangeUserEmailMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeUserEmailMutation(\n  $companyId: ID!\n  $userId: ID!\n  $email: String!\n) {\n  Me {\n    Company(id: $companyId) {\n      User(id: $userId) {\n        changeEmail(email: $email)\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '25f3b3106756dd9a7c8250d23356e6c2';

module.exports = node;
