import React, { useCallback, useContext } from 'react'
import { QueryRenderer } from 'react-relay'
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom'

// import ErrorMessage from './ErrorMessage'
// import Loading from '../LoadingSpinner/LoadingSpinner'
// import Loader from '../LoadingSpinner/Loader'
import UserContext from '../../helper/userContext'
import generateEnvironment from '../../utils/generateEnvironment'
import { Routes } from '../../configs/RouteConfig'
import { LoadingSpinner } from 'pyrexx-react-library'
import { WhiteRoutes } from '../../configs/WhiteListForRoutes'
import { useErrorHandler } from 'react-error-boundary'

const QueryRendererContainer = ({
  query,
  variables,
  render,
  env,
  forceLoad,
  loading = <LoadingSpinner />,
}) => {
  const match = useRouteMatch('/:lang/:site/:extra?/:id?')
  const { user, isTokenValid } = useContext(UserContext)
  const location = useLocation()
  const handleError = useErrorHandler()

  const renderQuery = useCallback(
    ({ error, props, retry }) => {
      const errorCategory = error?.source?.errors[0]?.extensions?.category || ''

      if (
        error &&
        (errorCategory === 'authentication' || errorCategory === 'graphql') &&
        !WhiteRoutes.includes(match.params.site)
      ) {
        console.log('error rendering query container', error)

        return isTokenValid((isValid) => {
          if (isValid !== true) {
            return (
              <Redirect
                to={{
                  pathname: '/' + match.params.lang + Routes.login,
                  state: { redirect: location.pathname + location.search },
                }}
              />
            )
          }
        })
      } else if (error) {
        console.log(error)

        if (errorCategory === 'authorization') {
          handleError({ message: 'YOU DO NOT HAVE ACCESS TO THIS PAGE' })
        } else {
          handleError(error)
        }

        return null
      } else if (props) {
        return render(props, retry)
      }
      return loading
    },
    [
      handleError,
      isTokenValid,
      loading,
      location.pathname,
      location.search,
      match.params.lang,
      match.params.site,
      render,
    ]
  )

  return (
    <QueryRenderer
      environment={
        env ||
        generateEnvironment(process.env.REACT_APP_API_URL, user.accessToken)
      }
      query={query}
      variables={variables}
      render={renderQuery}
      cacheConfig={{ force: forceLoad || false }}
    />
  )
}

export default QueryRendererContainer
