import React from 'react'
import { Route, Redirect } from 'react-router-dom'

import { Routes } from '../configs/RouteConfig'
import { useUserContext } from '../helper/userContext'

// 1. Check redirection to the company banner
// 2. Check redirection when trying to access a url and the user is not logged in
// 3. Check redirection after regular login

function AuthRedirection({ children, ...rest }) {
  const { currentLanguage, isAuth = false } = useUserContext() || {}

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          <Redirect
            to={{
              pathname: '/' + currentLanguage + Routes.overview,
              state: { from: location },
            }}
          />
        ) : (
          children
        )
      }
    />
  )
}

export default AuthRedirection
