import React, { useContext } from 'react'
import { ImprintInfo } from 'pyrexx-react-library'

import UserContext from '../../helper/userContext'
import graphql from 'babel-plugin-relay/macro'
import QueryRendererContainer from '../../components/QueryRendererContainer/QueryRendererContainer'
import environment from '../../environments/pom/Environment'
import PageContainer from '../../components/PageContainer'

const query = graphql`
  query ImprintQuery($lang: Language!) {
    IMPRINT: Document(type: IMPRINT, lang: $lang) {
      id
      coId
      date
      title
      paragraphs {
        number
        name
        sections {
          name
          number
          text
        }
      }
    }
  }
`

const Imprint = (props) => {
  const { currentLanguage, user } = useContext(UserContext)

  const variables = {
    lang: currentLanguage,
  }

  return (
    <QueryRendererContainer
      environment={environment(user.accessToken)}
      query={query}
      variables={variables}
      render={(data) => {
        return (
          <PageContainer noGutter>
            <ImprintInfo content={data} />
          </PageContainer>
        )
      }}
    />
  )
}
export default Imprint
