import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import FilterBadge from './FilterBadge'

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 8px;
`

const FilterBadges = (props) => {
  const { filters = [], removeFilter = (filter) => {} } = props

  return (
    <Wrapper>
      {filters.map((filter) => (
        <FilterBadge
          key={filter?.filterId}
          handleClose={() => removeFilter(filter)}
        >
          {filter?.label}
        </FilterBadge>
      ))}
    </Wrapper>
  )
}

FilterBadges.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      filterId: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  removeFilter: PropTypes.func,
}

export default FilterBadges
