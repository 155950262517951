function dummyTranslate(text) {
  return text
}

const lettersStatusEnumList = (value) => {
  switch (value) {
    case 'SENT':
      return dummyTranslate('SENT')

    case 'DRAFT':
      return dummyTranslate('DRAFT')

    case 'PENDING':
      return dummyTranslate('PENDING')

    case 'NONE':
    default:
      return ''
  }
}

export default lettersStatusEnumList
