import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import { FontQuicksand, SimpleCSS } from './Simple.styles'

const SimpleLink = styled(Link)`
  ${SimpleCSS}

  ${(props) => (props.font === 'quicksand' ? FontQuicksand : null)}
`

export default (props) => {
  const { children, font, ...restOfProps } = props

  return (
    <SimpleLink font={font} {...restOfProps}>
      {children}
    </SimpleLink>
  )
}
