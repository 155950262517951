import React, { forwardRef, useImperativeHandle, useState } from 'react'
import GridEnumFilter from '../../../components/GridEnumFilter'
import useUpdateEffect from '../../../hooks/useUpdateEffect'

const map = {
  DONE: ['DONE'],
  IN_PROGRESS: ['IN_PROGRESS', 'UPCOMING', 'IN_CLARIFYING', 'IN_PREPARATION'],
  DATA_INCORRECT: ['DATA_INCORRECT_REQUESTED'],
  DEACTIVATED: ['DEACTIVATED'],
}

const colorMap = {
  DONE: 'success',
  IN_PROGRESS: 'warning',
  DATA_INCORRECT: 'error',
  DEACTIVATED: 'disabled',
}

const translateLabel = (label, t) => {
  switch (label) {
    case 'DONE':
      return t('DONE')
    case 'IN_PROGRESS':
      return t('IN PROGRESS')
    case 'DATA_INCORRECT':
      return t('DATA INCORRECT')
    case 'DEACTIVATED':
      return t('DEACTIVATED')
    default:
      return ''
  }
}

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  return (
    <GridEnumFilter
      map={map}
      colorMap={colorMap}
      translateLabel={translateLabel}
      setFilterModel={setFilterModel}
    />
  )
})
