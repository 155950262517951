import { usageUnitLockPeriodReasonValueGetter } from '../valueGetter'
import { useTranslation } from 'react-i18next'

const UsageUnitLockPeriodReasonRenderer = (props) => {
  const { t } = useTranslation()

  return t(usageUnitLockPeriodReasonValueGetter(props))
}

export default UsageUnitLockPeriodReasonRenderer
