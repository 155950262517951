/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppointmentAccessType = "ACCESS" | "NONE" | "NO_ACCESS_22" | "NO_ACCESS_23" | "NO_ACCESS_24" | "NO_ACCESS_25" | "NO_ACCESS_26" | "NO_ACCESS_27" | "NO_ACCESS_28" | "NO_ACCESS_29" | "NO_ACCESS_30" | "NO_ACCESS_31" | "NO_ACCESS_32" | "NO_ACCESS_33" | "NO_ACCESS_34" | "NO_ACCESS_35" | "NO_ACCESS_36" | "NO_ACCESS_37" | "NO_ACCESS_38" | "NO_ACCESS_39" | "NO_ACCESS_40" | "NO_ACCESS_AWAY" | "NO_ACCESS_DENIED" | "NO_ACCESS_DENIED_NEED_RESPITE" | "NO_ACCESS_FOREIGN_SD_INSTALLED_NO_MAINTENANCE" | "NO_ACCESS_HYGIENICALLY_INFEASIBLE" | "NO_ACCESS_INFEASIBLE" | "NO_ACCESS_JUNCTION_BOX_BROKEN" | "NO_ACCESS_JUNCTION_BOX_DESTROYED" | "NO_ACCESS_JUNCTION_BOX_GRAFFITI_FOUND" | "NO_ACCESS_JUNCTION_BOX_INACCESSIBLE" | "NO_ACCESS_JUNCTION_BOX_MISSING" | "NO_ACCESS_JUNCTION_BOX_OPENED" | "NO_ACCESS_NO_ADULT_ON_SITE" | "NO_ACCESS_PARTLY_DONE_SOME_LOCATIONS_LOCKED" | "NO_ACCESS_RAMSHACKLE_SURFACE" | "NO_ACCESS_RENOVATION" | "NO_ACCESS_TENANT_OWNS_RM" | "NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE" | "NO_ACCESS_VACANT_KEY_DOES_NOT_FIT" | "NO_ACCESS_VACANT_NO_KEY" | "%future added value";
export type AppointmentStatusType = "CANCELLED" | "CHECKED" | "CLOSED" | "DEACTIVATED" | "DIAGNOSE_PENDING" | "DONE" | "NONE" | "OPEN" | "PLANNED" | "TEMP_DEFERRED" | "UNTERMINATED" | "%future added value";
export type AppointmentTypeType = "P" | "REMOTE_DATA_COLLECTION" | "T1" | "T2" | "TA" | "TI" | "TS" | "TX" | "%future added value";
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type RenderedServicesGridQueryVariables = {|
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
  companyId: string,
  usageUnitId: string,
|};
export type RenderedServicesGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +UsageUnit: ?{|
        +data: $ReadOnlyArray<?{|
          +id: string,
          +actionPosition: ?{|
            +id: string,
            +name: ?string,
          |},
          +appointment: ?{|
            +id: ?string,
            +status: ?AppointmentStatusType,
            +tableId: ?number,
            +date: ?any,
            +timeframe: ?string,
            +serviceReason: ?{|
              +id: string,
              +name: ?string,
            |},
            +tenant: ?{|
              +id: ?string,
              +firstname: string,
              +lastname: string,
            |},
            +type: ?AppointmentTypeType,
            +accessReason: ?AppointmentAccessType,
            +executionDate: ?any,
          |},
        |}>
      |}
    |}
  |}
|};
export type RenderedServicesGridQuery = {|
  variables: RenderedServicesGridQueryVariables,
  response: RenderedServicesGridQueryResponse,
|};
*/


/*
query RenderedServicesGridQuery(
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
  $companyId: ID!
  $usageUnitId: ID!
) {
  Me {
    Company(id: $companyId) {
      UsageUnit(id: $usageUnitId) {
        data: registrations(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
          id
          actionPosition {
            id
            name
          }
          appointment {
            id
            status
            tableId
            date
            timeframe
            serviceReason {
              id
              name
            }
            tenant {
              id
              firstname
              lastname
            }
            type
            accessReason
            executionDate
          }
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "usageUnitId"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v7 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "usageUnitId"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v10 = {
  "alias": "data",
  "args": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "endRow",
          "variableName": "endRow"
        },
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "sorting",
          "variableName": "sortModel"
        },
        {
          "kind": "Variable",
          "name": "startRow",
          "variableName": "startRow"
        }
      ],
      "kind": "ObjectValue",
      "name": "options"
    }
  ],
  "concreteType": "Registration",
  "kind": "LinkedField",
  "name": "registrations",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ActionPosition",
      "kind": "LinkedField",
      "name": "actionPosition",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Appointment",
      "kind": "LinkedField",
      "name": "appointment",
      "plural": false,
      "selections": [
        (v8/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tableId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timeframe",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ServiceReason",
          "kind": "LinkedField",
          "name": "serviceReason",
          "plural": false,
          "selections": (v9/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstname",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastname",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accessReason",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "executionDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RenderedServicesGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "UsageUnit",
                "kind": "LinkedField",
                "name": "UsageUnit",
                "plural": false,
                "selections": [
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "RenderedServicesGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v7/*: any*/),
                "concreteType": "UsageUnit",
                "kind": "LinkedField",
                "name": "UsageUnit",
                "plural": false,
                "selections": [
                  (v10/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f49b6a307bafb842d50f291b8ede6a42",
    "id": null,
    "metadata": {},
    "name": "RenderedServicesGridQuery",
    "operationKind": "query",
    "text": "query RenderedServicesGridQuery(\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n  $companyId: ID!\n  $usageUnitId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      UsageUnit(id: $usageUnitId) {\n        data: registrations(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n          id\n          actionPosition {\n            id\n            name\n          }\n          appointment {\n            id\n            status\n            tableId\n            date\n            timeframe\n            serviceReason {\n              id\n              name\n            }\n            tenant {\n              id\n              firstname\n              lastname\n            }\n            type\n            accessReason\n            executionDate\n          }\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7bf5ff51d08b82ab920cdb7b5fee126f';

module.exports = node;
