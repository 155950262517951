import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query LetterRecipientsQuery($companyId: ID!, $letterId: ID!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          letter(id: $letterId) {
            layout
            variables
            data: recipients {
              id
              uniqueReference
              firstname
              lastname
              street
              streetNumber
              city
              zip
            }
          }
        }
      }
    }
  }
`
