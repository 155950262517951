import ReactDOM from 'react-dom'
import React, { useCallback, useReducer, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import { Col, Container, Row } from 'react-grid-system'
import { useUserContext } from '../../helper/userContext'
import { Routes } from '../../configs/RouteConfig'
import NavBack from '../../components/NavBack'
import {
  ButtonPrimary,
  ButtonSecondary,
  LoadingSpinner,
  portalTheme,
  SimpleModal,
  SimpleNote,
} from 'pyrexx-react-library'
import QueryRendererContainer from '../../components/QueryRendererContainer'
import LayoutPreview from '../../components/LayoutPreview/LayoutPreview'
import { LetterLayoutQuery } from './queries'
import {
  CreateCompanyLayoutMutation,
  UpdateCompanyLayoutMutation,
} from '../CreateCompanyLayout/mutations'
import * as Yup from 'yup'
import CKEditor from '../../editor/CKEditor'

import VariablePreview from '../../editor/VariablePreview'
import VariablesBox from '../../editor/VariablesBox'
import {
  availableVariables,
  department,
  letter,
  letterCreator,
} from '../../editor/availableVariables'
import HTMLRendered from '../../editor/HTMLRendered'
import styled from 'styled-components/macro'
import Upload from '../../components/ImageCropper/Upload'
import ImageCropper from '../../components/ImageCropper'
import { CenterRow } from '../../styles/HelperStylesComponents.styles'
import {
  CLOSE_COMPONENT,
  initialState,
  SET_LOADER,
  SUBMIT_FAILED,
  SUBMIT_WAS_SUCCESSFUL,
  submitReducer,
} from '../../reducers/submitReducer'
import getCroppedImg from '../../components/ImageCropper/cropImage'

import { CKEditor as CKEditorReact } from '@ckeditor/ckeditor5-react'
import CustomEditor from '../../editor/build/customEditor'

const CreateCompanyLayoutAdd = () => {
  const { t } = useTranslation()
  const { user, currentLanguage, redirectRoute } = useUserContext()
  const [submitLoading, setSubmitLoading] = useState(false)

  const submitMutation = (id, containers, values) => {
    setSubmitLoading(true)
    CreateCompanyLayoutMutation(
      {
        companyId: user.companyId,
        letterLayoutId: id,
      },
      user.accessToken,
      (data) => {
        UpdateCompanyLayoutMutation(
          {
            companyId: user.companyId,
            companyLayoutId:
              data.Me.Company.businessPost.createCompanyLayout.tableId,
            name: values.name,
            layout: JSON.stringify(containers, null, 2),
          },
          user.accessToken,
          (finishedLetterData) => {
            redirectRoute(Routes.admin + Routes.companyLayouts)
            setSubmitLoading(false)
          }
        )
      }
    )
  }

  const [data, setData] = React.useState('')
  const editorRef = useRef(null)

  const editorConfig = {
    // The configuration of the Products plugin. It specifies a function that will allow
    // the editor to render a React <VariablePreview> component inside a product widget.
    variables: {
      variablesRenderer: (id, domElement) => {
        const variable = availableVariables.find(
          (variable) => variable.id === id
        )

        const handleRemove = (id) => {
          editorRef.current.execute('removeVariable', id)
          editorRef.current.editing.view.focus()
        }

        // const variableElement = createRoot(domElement)
        ReactDOM.render(
          <VariablePreview id={id} handleRemove={handleRemove} {...variable} />,
          domElement
        )
      },
    },
  }

  // A handler executed when the user types or modifies the editor content.
  // It updates the state of the application.
  const handleEditorDataChange = (evt, editor) => {
    console.log('data changed')

    setData(editor.getData())
  }

  // A handler executed when the editor has been initialized and is ready.
  // It synchronizes the initial data state and saves the reference to the editor instance.
  const handleEditorReady = (editor) => {
    editorRef.current = editor

    setData(editorRef.current.getData())

    console.log('editor', editor.editing.view)

    console.log(editor.editing.view.rangeCount)

    editor.editing.view.change((writer) => {
      console.log({ writer })
      writer.setStyle('height', '495px', editor.editing.view.document.getRoot())
      // writer.setStyle('height', '400px', editor.editing.view.document.getRoot())
    })

    // CKEditor 5 inspector allows you to take a peek into the editor's model and view
    // data layers. Use it to debug the application and learn more about the editor.
    // CKEditorInspector.attach(editorRef.current)
  }

  const OUTPUT_IMAGE_DIMENSIONS = { width: 200, height: 200 }
  const [image, setImage] = useState(null)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [state, dispatch] = useReducer(submitReducer, initialState)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    // console.log(croppedArea, croppedAreaPixels)
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const saveCroppedImage = useCallback(async () => {
    try {
      // croppedImage -> { blob: null, base64: null }
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        undefined,
        undefined,
        OUTPUT_IMAGE_DIMENSIONS
      )
      // console.log('done', { croppedImage })
      return croppedImage
      // setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [OUTPUT_IMAGE_DIMENSIONS, croppedAreaPixels, image])

  const onError = () => {
    dispatch({
      type: SUBMIT_FAILED,
      payload: t('ERROR UPLOADING LOGO'),
    })
    console.log('Error uploading logo')
  }

  const positionMap = {
    left: 'position:absolute; top:0; left: 0; height:100%',
    right: 'position:absolute; top:0; right: 0; height:100%',
    center: 'position:absolute; top:0; left: 50%; height:100%',
  }

  const [imgPosition, setImagePosition] = React.useState('left')

  const onRatioChange = (newPos) => {
    setImagePosition(newPos)
  }

  const imageHeaderOverride = {
    containerKey: 'header',
    id: 'letterHeader',
    formikIncludeSchema: {
      imgSrc: Yup.string(),
    },
    formikIncludeInitValues: {
      imgSrc: '',
    },
    getForm: (formikTmp, updateFunction, loading) => {
      const uploadImage = () => {
        dispatch({ type: SET_LOADER })

        saveCroppedImage()
          .then((croppedImage) => {
            const imgSrc = croppedImage.base64

            updateFunction(
              `<img id="imgContainer" src="${imgSrc}" style="${positionMap[imgPosition]}"  alt="header"/>`
            )

            dispatch({
              type: SUBMIT_WAS_SUCCESSFUL,
              payload: t('IMAGE UPLOADED SUCCESSFULLY'),
            })
          })
          .catch(onError)
      }

      return (
        <div>
          <Wrapper>
            <LeftCol noImage={!image}>
              <Upload setImgSrc={setImage} />
            </LeftCol>

            {image && (
              <RightCol>
                <ImageCropper image={image} onCropComplete={onCropComplete} />
              </RightCol>
            )}
          </Wrapper>

          <RadioWrapper>
            <label>
              <input
                type='radio'
                name='left'
                value='left'
                checked={imgPosition === 'left'}
                onChange={() => onRatioChange('left')}
              />
              Left
            </label>

            <label>
              <input
                type='radio'
                name='center'
                value='center'
                checked={imgPosition === 'center'}
                onChange={() => onRatioChange('center')}
              />
              Center
            </label>

            <label>
              <input
                type='radio'
                name='right'
                value='right'
                checked={imgPosition === 'right'}
                onChange={() => onRatioChange('right')}
              />
              Right
            </label>
          </RadioWrapper>

          {image && (
            <CenterRow style={{ marginTop: '1rem' }}>
              <ButtonPrimary onClick={uploadImage} isLoading={state.loading}>
                {t('UPLOAD')}
              </ButtonPrimary>
            </CenterRow>
          )}

          <SimpleModal
            isOpen={state.showModal}
            handleModalClose={() => dispatch({ type: CLOSE_COMPONENT })}
          >
            <SimpleNote text={state.message} noteStatus={state.status} />
          </SimpleModal>
        </div>
      )
    },
  }

  const sidebarOverride = useCallback(() => {
    return {
      containerKey: 'content',
      id: 'sidebarContacts',
      formikIncludeSchema: {
        // data: Yup.string(),
      },
      formikIncludeInitValues: {
        // data: '<p>Hello</p>',
      },
      getForm: (formikTmp, updateFunction, loading) => {
        const handleSave = () => {
          if (editorRef.current) {
            const data = editorRef.current.getData()
            setData(data)
            updateFunction('<div>' + data + '</div>')
          }
        }

        return (
          <div>
            <h3>{t('ADD LETTER HEADER')}</h3>
            <p>{t('ADD AND USE PARAMETERS')}</p>

            <VariablesEditorWrapper>
              <CKEditorReact
                editor={CustomEditor.VariablesEditor}
                data={data}
                config={editorConfig}
                onChange={handleEditorDataChange}
                onReady={handleEditorReady}
              />

              <div>
                <h4 style={{ margin: 0 }}>{t('FURTHER DATA')}</h4>

                <p>
                  <strong>{t('CONTACT')}</strong>
                </p>
                <VariablesBox variables={letterCreator} editorRef={editorRef} />

                <p>
                  <strong>{t('DEPARTMENT')}</strong>
                </p>
                <VariablesBox variables={department} editorRef={editorRef} />

                <p>
                  <strong>{t('DATE FORMAT')}</strong>
                </p>
                <VariablesBox variables={letter} editorRef={editorRef} />
              </div>
            </VariablesEditorWrapper>

            <ButtonSecondary
              onClick={handleSave}
              disabled={loading}
              style={{
                margin: '16px 0',
                height: '2rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
              }}
            >
              {t('UPDATE')}
            </ButtonSecondary>
          </div>
        )
      },
    }
  }, [data, editorConfig, t])

  const [footerData, setFooterData] = React.useState({})
  const [currentScreen, setCurrentScreen] = useState(1)

  const editor1Ref = useRef(null)
  const editor2Ref = useRef(null)
  const editor3Ref = useRef(null)
  const editor4Ref = useRef(null)

  const renderScreen = (id, currentScreen) => {
    const condition = id <= currentScreen
    // if (!condition) {
    // console.log('remove screen', id);
    // console.log({..});
    // setFooterData((prev) => {
    //   return { ...prev }.delete(id)
    // })
    // }

    return condition
  }

  const footerOverride = {
    containerKey: 'footer',
    id: 'letterFooter',
    formikIncludeSchema: {
      // data: Yup.string(),
    },
    formikIncludeInitValues: {
      // data: '<p>Hello</p>',
    },
    getForm: (formikTmp, updateFunction, loading) => {
      // const handleSave = () => {
      //   if (editorRef.current) {
      //     const data = editorRef.current.getData()
      //     setData(data)
      //     updateFunction('<div>' + data + '</div>')
      //   }
      // }

      const handleUpdate = () => {
        const data1 = editor1Ref?.current?.getData() || ''
        const data2 = editor2Ref?.current?.getData() || ''
        const data3 = editor3Ref?.current?.getData() || ''
        const data4 = editor4Ref?.current?.getData() || ''

        setFooterData({
          1: data1,
          2: data2,
          3: data3,
          4: data4,
        })
        updateFunction(
          '<div style="display: flex; justify-content: space-between; flex-flow: row nowrap;">' +
            '<div>' +
            data1 +
            '</div>' +
            '<div>' +
            data2 +
            '</div>' +
            '<div>' +
            data3 +
            '</div>' +
            '<div>' +
            data4 +
            '</div>' +
            '</div>'
        )
      }

      return (
        <div>
          <div>
            <ColumnRadioWrapper>
              <ColumnRadio>
                <label>
                  <input
                    type='radio'
                    value='1'
                    name='column-picker'
                    checked={currentScreen === 1}
                    onChange={() => setCurrentScreen(1)}
                  />
                  1 Spaltig
                </label>
                <Screen>
                  {renderScreen('1', currentScreen) ? (
                    <HTMLRendered data={footerData['1']} />
                  ) : (
                    <EmptyScreen>1</EmptyScreen>
                  )}
                </Screen>
              </ColumnRadio>

              <ColumnRadio>
                <label>
                  <input
                    type='radio'
                    value='2'
                    name='column-picker'
                    checked={currentScreen === 2}
                    onChange={() => setCurrentScreen(2)}
                  />
                  2 Spaltig
                </label>
                <Screen>
                  {renderScreen('2', currentScreen) ? (
                    <HTMLRendered data={footerData['2']} />
                  ) : (
                    <EmptyScreen>2</EmptyScreen>
                  )}
                </Screen>
              </ColumnRadio>

              <ColumnRadio>
                <label>
                  <input
                    type='radio'
                    value='3'
                    name='column-picker'
                    checked={currentScreen === 3}
                    onChange={() => setCurrentScreen(3)}
                  />
                  3 Spaltig
                </label>
                <Screen>
                  {renderScreen('3', currentScreen) ? (
                    <HTMLRendered data={footerData['3']} />
                  ) : (
                    <EmptyScreen>3</EmptyScreen>
                  )}
                </Screen>
              </ColumnRadio>

              <ColumnRadio>
                <label>
                  <input
                    type='radio'
                    value='4'
                    name='column-picker'
                    checked={currentScreen === 4}
                    onChange={() => setCurrentScreen(4)}
                  />
                  4 Spaltig
                </label>
                <Screen>
                  {renderScreen('4', currentScreen) ? (
                    <HTMLRendered data={footerData['4']} />
                  ) : (
                    <EmptyScreen>4</EmptyScreen>
                  )}
                </Screen>
              </ColumnRadio>
            </ColumnRadioWrapper>

            <div>
              {renderScreen('1', currentScreen) && (
                <div>
                  <p>1</p>
                  <CKEditor
                    editor={CustomEditor.SimpleEditor}
                    currentData={footerData['1']}
                    editorRef={editor1Ref}
                  />
                </div>
              )}

              {renderScreen('2', currentScreen) && (
                <div>
                  <p>2</p>
                  <CKEditor
                    editor={CustomEditor.SimpleEditor}
                    currentData={footerData['2']}
                    editorRef={editor2Ref}
                  />
                </div>
              )}

              {renderScreen('3', currentScreen) && (
                <div>
                  <p>3</p>
                  <CKEditor
                    editor={CustomEditor.SimpleEditor}
                    currentData={footerData['3']}
                    editorRef={editor3Ref}
                  />
                </div>
              )}

              {renderScreen('4', currentScreen) && (
                <div>
                  <p>4</p>
                  <CKEditor
                    editor={CustomEditor.SimpleEditor}
                    currentData={footerData['4']}
                    editorRef={editor4Ref}
                  />
                </div>
              )}
            </div>
          </div>

          <br />

          <ButtonSecondary
            onClick={handleUpdate}
            disabled={loading}
            style={{
              margin: '0px',
              height: '2rem',
              paddingLeft: '1rem',
              paddingRight: '1rem',
            }}
          >
            {t('UPDATE')}
          </ButtonSecondary>
        </div>
      )
    },
  }

  return (
    <PageContainer>
      <NavBack
        title={t('CREATE BUSINESS POST LAYOUT')}
        to={'/' + currentLanguage + Routes.admin + Routes.createCompanyLayout}
      />
      <Container>
        <Row>
          <Col>
            <QueryRendererContainer
              query={LetterLayoutQuery}
              variables={{
                companyId: user.companyId,
                letterLayoutId: 1,
              }}
              loading={
                <div style={{ height: '30px', width: '30px' }}>
                  <LoadingSpinner
                    theme={{
                      components: {
                        spinner: {
                          /**
                           * Has to be a { css } oject from styled-components
                           */
                          fontSize: portalTheme.font.size.bodySmall,
                          fontWeight: portalTheme.font.weight.regular,
                          size: '20px',
                        },
                      },
                    }}
                    style={{
                      position: 'unset',
                      backgroundColor: 'white',
                    }}
                  />
                </div>
              }
              render={(data) => {
                const letterLayout =
                  data?.Me?.Company?.businessPost?.letterLayout
                return (
                  <LayoutPreview
                    containerFormikOverride={[
                      imageHeaderOverride,
                      sidebarOverride(),
                      footerOverride,
                    ]}
                    adminEdit
                    submitMutation={submitMutation}
                    submitLoading={submitLoading}
                    saveButtonText={t('PUBLISH LAYOUT')}
                    id={letterLayout.tableId}
                    initContainer={JSON.parse(letterLayout.layout)}
                  />
                )
              }}
            />
          </Col>
        </Row>
      </Container>
    </PageContainer>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  //flex-flow: column nowrap;
`

const VariablesEditorWrapper = styled.div`
  display: flex;
  gap: 1rem;

  & > * {
    flex: 1;
  }
`

const RadioWrapper = styled.div`
  padding: 32px;
  display: flex;
  justify-content: space-evenly;
`

const LeftCol = styled.div`
  flex: 1;
`

const RightCol = styled.div`
  flex: 2;
`

const ColumnRadioWrapper = styled.div`
  display: flex;
  gap: 8px;
`

const ColumnRadio = styled.div`
  flex: 1;
`

const Screen = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  border: 1px solid #ddd;
  padding: 16px;

  min-height: 6rem;
`

const EmptyScreen = ({ children }) => {
  return <EmptyScreenWrapper>{children}</EmptyScreenWrapper>
}

const EmptyScreenWrapper = styled.div`
  font-size: 2rem;
  font-weight: bold;

  margin: 0 auto;
`

export default CreateCompanyLayoutAdd
