import { commitMutation } from 'react-relay'
import graphql from 'babel-plugin-relay/macro'

import environment from '../../../environments/pom/Environment'

const mutation = graphql`
  mutation UpdateCompanyLayoutMutation(
    $companyId: ID!
    $companyLayoutId: Int!
    $name: String!
    $layout: String!
    $status: CompanyLayoutStatusType
  ) {
    Me {
      Company(id: $companyId) {
        businessPost {
          updateCompanyLayout(
            companyLayoutId: $companyLayoutId
            name: $name
            layout: $layout
            status: $status
          ) {
            id
            tableId
            name
            status
          }
        }
      }
    }
  }
`

export default (variables = {}, accessToken, callBack, errorCallBack) => {
  commitMutation(environment(accessToken), {
    mutation,
    variables,
    onCompleted: callBack,
    onError: errorCallBack,
  })
}
