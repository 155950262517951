import React, { useState, forwardRef, useImperativeHandle } from 'react'
import GridEnumFilter from '../../../components/GridEnumFilter'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import { usageUnitLockPeriodReasonEnumList } from '../../../components/AgGrid/enumLists'

const map = {
  OWN_SMOKE_DETECTOR: ['OWN_SMOKE_DETECTOR'],
  TENANT_REJECTED_SMOKE_DETECTORS: ['TENANT_REJECTED_SMOKE_DETECTORS'],
  SMOKE_DETECTORS_NOT_USEFUL: ['SMOKE_DETECTORS_NOT_USEFUL'],
  UNIT_DOESNT_EXIST: ['UNIT_DOESNT_EXIST'],
  TEMP_FOR_RENOVATION: ['TEMP_FOR_RENOVATION'],
  UNIT_DEMOLITION: ['UNIT_DEMOLITION'],
  UNIT_SOLD: ['UNIT_SOLD'],
  MODERNIZATION: ['MODERNIZATION'],
  LOCKED_TIL_INDIVIDUAL_ORDER: ['LOCKED_TIL_INDIVIDUAL_ORDER'],
  CUSTOMER_WISH: ['CUSTOMER_WISH'],
  SYSTEM: ['SYSTEM'],
  UNKNOWN: ['UNKNOWN'],
  TEMP_FOR_CLEARIFICATION: ['TEMP_FOR_CLEARIFICATION'],
  CONTRACT_CANCELLED: ['CONTRACT_CANCELLED'],
  CONTRACT_EXPIRED: ['CONTRACT_EXPIRED'],
  HIGH_CEILING: ['HIGH_CEILING'],
  SMOKE_DETECTORS_IN_SAFE_KEEPING: ['SMOKE_DETECTORS_IN_SAFE_KEEPING'],
  CUSTOMER_TEMP_DEFERRED_INSTALLATION: ['CUSTOMER_TEMP_DEFERRED_INSTALLATION'],
  DECENTRAL_WARM_WATER: ['DECENTRAL_WARM_WATER'],
  UNEXPECTED_BUSINESS_UNIT: ['UNEXPECTED_BUSINESS_UNIT'],
}

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  return (
    <GridEnumFilter
      map={map}
      translateLabel={(value, t) => {
        return t(usageUnitLockPeriodReasonEnumList(value))
      }}
      setFilterModel={setFilterModel}
    />
  )
})
