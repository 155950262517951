/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PermissionType = "ADMIN" | "APPOINTMENT" | "CONTRACT" | "DATA_UPDATE" | "INVENTORY" | "INVOICE" | "NONE" | "POST_LETTER" | "POST_VIEW_ALL" | "QUOTA" | "%future added value";
export type UpdateUserQueryVariables = {|
  companyId: string,
  userId: string,
|};
export type UpdateUserQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +availablePermissions: ?$ReadOnlyArray<?PermissionType>,
      +contracts: ?$ReadOnlyArray<?{|
        +tableId: number,
        +name: ?string,
        +billingPartitions: ?$ReadOnlyArray<?{|
          +tableId: number,
          +name: string,
        |}>,
      |}>,
      +User: ?{|
        +tableId: number,
        +username: ?string,
        +firstname: ?string,
        +lastname: ?string,
        +email: ?string,
        +currentPermissions: ?$ReadOnlyArray<?PermissionType>,
        +currentRestrictions: ?{|
          +contracts: ?$ReadOnlyArray<?number>,
          +billingPartitions: ?$ReadOnlyArray<?number>,
        |},
      |},
    |}
  |}
|};
export type UpdateUserQuery = {|
  variables: UpdateUserQueryVariables,
  response: UpdateUserQueryResponse,
|};
*/


/*
query UpdateUserQuery(
  $companyId: ID!
  $userId: ID!
) {
  Me {
    Company(id: $companyId) {
      availablePermissions: permissions
      contracts: Contracts {
        tableId
        name
        billingPartitions {
          tableId
          name
          id
        }
        id
      }
      User(id: $userId) {
        tableId
        username
        firstname
        lastname
        email
        currentPermissions: permissions
        currentRestrictions: restrictions {
          contracts
          billingPartitions
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": "availablePermissions",
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstname",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastname",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": "currentPermissions",
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v11 = {
  "alias": "currentRestrictions",
  "args": null,
  "concreteType": "UserRestrictionType",
  "kind": "LinkedField",
  "name": "restrictions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contracts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "billingPartitions",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "contracts",
                "args": null,
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "Contracts",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingPartition",
                    "kind": "LinkedField",
                    "name": "billingPartitions",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "UserInfo",
                "kind": "LinkedField",
                "name": "User",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "contracts",
                "args": null,
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "Contracts",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingPartition",
                    "kind": "LinkedField",
                    "name": "billingPartitions",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "UserInfo",
                "kind": "LinkedField",
                "name": "User",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a4d7f563a05156e5ef17a78cbcc91939",
    "id": null,
    "metadata": {},
    "name": "UpdateUserQuery",
    "operationKind": "query",
    "text": "query UpdateUserQuery(\n  $companyId: ID!\n  $userId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      availablePermissions: permissions\n      contracts: Contracts {\n        tableId\n        name\n        billingPartitions {\n          tableId\n          name\n          id\n        }\n        id\n      }\n      User(id: $userId) {\n        tableId\n        username\n        firstname\n        lastname\n        email\n        currentPermissions: permissions\n        currentRestrictions: restrictions {\n          contracts\n          billingPartitions\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ce72a76de70dbce8f897d7a1d4ef15ea';

module.exports = node;
