import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query InvoicesGridQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
  ) {
    Me {
      id
      Company(id: $companyId) {
        data: Invoices(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          tableId
          name
          download: tableId
          status
          price {
            net
            gross
            percentage
            vat
          }
          type
          invoiceNumber
          description
          invoiceDate
          value
          paymentTarget
          remark
          contract: Contract {
            id
            tableId
            date
            name
            serviceCategory {
              id
              tableId
              name
            }
          }
        }
      }
    }
  }
`
