import React, { useContext } from 'react'

import SimpleLink from '../../Simple/SimpleLink'
import HelloMenu from '../../HelloMenu'
import HeaderNotifications from './HeaderNotifications'

import { Routes } from '../../../configs/RouteConfig'
import UserContext from '../../../helper/userContext'
import handleLocalStorage from '../../../helper/handleLocalStorage'

const AuthenticatedHeader = () => {
  const { user, currentLanguage, logout } = useContext(UserContext)

  const hasNewNotifications = false

  const permissions = user?.userPermissions || []
  const isAdmin = permissions.includes('ADMIN')

  const hasMultipleCompanies =
    handleLocalStorage('get', 'multipleCompanies') || false

  return (
    <>
      <SimpleLink
        to={'/' + currentLanguage + Routes.support + Routes.notifications}
        style={{ display: 'flex' }}
      >
        <HeaderNotifications hasNewNotifications={hasNewNotifications} />
      </SimpleLink>

      <HelloMenu
        user={user}
        isAdmin={isAdmin}
        hasMultipleCompanies={hasMultipleCompanies}
        currentLanguage={currentLanguage}
        logout={logout}
      />
    </>
  )
}

export default AuthenticatedHeader
