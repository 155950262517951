import styled from 'styled-components/macro'

export const GridContainer = styled.div`
  /* original: https://grid.layoutit.com/?id=Lj55ZCx */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr;
  gap: 1em 1em;
  grid-template-areas:
    'company-banner company-banner notifications'
    'service-cards  service-cards  notifications';

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      'company-banner'
      'service-cards'
      'notifications';
  }

  /* add IE support */
  @media all and (-ms-high-contrast: none) {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto 1fr;
    gap: 1em 1em;
  }
`

export const GridCompanyBanner = styled.div`
  grid-area: company-banner;

  /* add IE support */
  @media all and (-ms-high-contrast: none) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
`

export const GridSupportNotifications = styled.div`
  grid-area: notifications;

  /* add IE support */
  @media all and (-ms-high-contrast: none) {
    -ms-grid-row: 1;
    -ms-grid-row-span: 2;
    -ms-grid-column: 3;
    -ms-grid-column-span: 3;
  }
`

export const GridServiceCards = styled.div`
  grid-area: service-cards;

  /* add IE support */
  @media all and (-ms-high-contrast: none) {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
  }
`
