import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './OwnFilters.styles'
import { CustomBubble } from './OwnFilters.styles'
import DropdownWithLabel from '../../../../DropdownWithLabel'
import FilterSearch from './FilterSearch/FilterSearch'
import FilterList from './FilterList/FilterList'
import { Col, Row } from 'react-grid-system'
import ConfirmDelete from './ConfirmDelete/ConfirmDelete'
import ConfirmNameChange from './ConfirmNameChange/ConfirmNameChange'
import { MessageBox, SimpleNote } from 'pyrexx-react-library'
import NewFilterAdd from './NewFilterAdd/NewFilterAdd'
import { StyledMountingAnimation } from '../../../../../styles/HelperStylesComponents.styles'

const OwnFilters = (props) => {
  const {
    gridStateName,
    gridState,
    gridColumnApi,
    gridApi,
    setFilterModel,
    filterOptions,
    setFilterOptions,
    currentFilter,
    setCurrentFilter,
    ownFilterChanged,
    getFiltersData,
    currentGrid,
    loadingFilter,
    setLoadingFilter,
    isFullScreen,
  } = props
  const { t } = useTranslation()
  const [MessageBoxVisible, setMessageBoxVisible] = useState(false)
  const [searchWord, setSearchWord] = useState('')
  const [deleteActive, setDeleteActive] = useState(false)
  const [confirmEditActive, setConfirmEditActive] = useState(false)
  const [editActive, setEditActive] = useState(false)
  const [openFromStart, setOpenFromStart] = useState(false)

  const changeSearchWord = (search) => {
    setSearchWord(search)
  }

  useEffect(() => {
    if (currentFilter) {
      const parsedState = JSON.parse(currentFilter.settings)
      setFilterModel(parsedState.filter)
      gridColumnApi.applyColumnState({
        state: parsedState.state,
        applyOrder: true,
      })
    }
  }, [currentFilter, gridColumnApi, setFilterModel])

  return (
    <div style={{ position: 'relative' }}>
      <StyledMountingAnimation
        transitionName='ShowSearchTextError'
        transitionEnterTimeout={500}
        transitionLeaveTimeout={2000}
      >
        {ownFilterChanged && (
          <CustomBubble isFullScreen={isFullScreen}>
            <SimpleNote
              text={t('HERE YOU CAN SAVE YOUR OWN FILTERS')}
              noteStatus='warning'
              style={{
                fontSize: '12px',
                lineHeight: 'normal',
                whiteSpace: 'nowrap',
              }}
            />
          </CustomBubble>
        )}
      </StyledMountingAnimation>

      <DropdownWithLabel
        keepOpen={openFromStart}
        align='right'
        renderLabel={(isDropdownOpen) => {
          return (
            <S.Label
              isDropdownOpen={isDropdownOpen}
              ownFilterChanged={ownFilterChanged}
            >
              {currentFilter?.filter || t('OWN FILTERS')}
              <S.MoreActionIcon />
            </S.Label>
          )
        }}
      >
        <S.Dropdown>
          <NewFilterAdd
            setEditActive={setEditActive}
            setConfirmEditActive={setConfirmEditActive}
            setFilterModel={setFilterModel}
            gridColumnApi={gridColumnApi}
            gridApi={gridApi}
            setCurrentFilter={setCurrentFilter}
            currentFilter={currentFilter}
            setOpenFromStart={setOpenFromStart}
            getFiltersData={getFiltersData}
            gridStateName={gridStateName}
            gridState={gridState}
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            loadingFilter={loadingFilter}
            setLoadingFilter={setLoadingFilter}
          />
          <Row justify='between'>
            <Col>{t('OWN FILTER LIST')}</Col>
          </Row>
          <Row>
            <Col>
              <FilterSearch
                searchWord={searchWord}
                changeSearchWord={changeSearchWord}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {!deleteActive && !confirmEditActive && (
                <FilterList
                  searchWord={searchWord}
                  setOpenFromStart={setOpenFromStart}
                  setConfirmEditActive={setConfirmEditActive}
                  setDeleteActive={setDeleteActive}
                  setCurrentFilter={setCurrentFilter}
                  filterOptions={filterOptions}
                  editActive={editActive}
                  setEditActive={setEditActive}
                  currentGrid={currentGrid}
                  MessageBoxVisible={MessageBoxVisible}
                  setMessageBoxVisible={setMessageBoxVisible}
                  loadingFilter={loadingFilter}
                />
              )}
            </Col>
          </Row>
          {deleteActive && (
            <ConfirmDelete
              setOpenFromStart={setOpenFromStart}
              deleteActive={deleteActive}
              setDeleteActive={setDeleteActive}
              getFiltersData={getFiltersData}
              setFilterOptions={setFilterOptions}
              setCurrentFilter={setCurrentFilter}
              currentFilter={currentFilter}
              gridColumnApi={gridColumnApi}
              setFilterModel={setFilterModel}
              gridApi={gridApi}
              setLoadingFilter={setLoadingFilter}
            />
          )}
          {confirmEditActive && (
            <ConfirmNameChange
              setOpenFromStart={setOpenFromStart}
              confirmEditActive={confirmEditActive}
              setConfirmEditActive={setConfirmEditActive}
              getFiltersData={getFiltersData}
              gridStateName={gridStateName}
              gridState={gridState}
              setFilterOptions={setFilterOptions}
              setCurrentFilter={setCurrentFilter}
              filterOptions={filterOptions}
              setLoadingFilter={setLoadingFilter}
            />
          )}
        </S.Dropdown>
        <MessageBox
          isVisible={MessageBoxVisible}
          closeMessageBox={() => {
            setMessageBoxVisible(false)
          }}
          statusColor='success-mark'
          updated={new Date()}
        >
          {t('FILTER LINK WAS COPIED TO CLIPBOARD')}
        </MessageBox>
      </DropdownWithLabel>
    </div>
  )
}
export default OwnFilters
