/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type SyncFileType = "API_TENANT_UPDATE" | "COMPANY_LOGO" | "DEMOLITION_NOTIFICATION" | "DISPOSAL_NOTIFICATION" | "DRINKWATER_DATA" | "DRINKWATER_ORDER_NO_DATA" | "FULL_UPDATE" | "INCREMENTAL_UPDATE" | "MISC" | "PLEASE_SELECT" | "PROPERTIES" | "%future added value";
export type SyncStatusType = "DATA_INCORRECT_REQUESTED" | "DEACTIVATED" | "DONE" | "IN_CLARIFYING" | "IN_PREPARATION" | "IN_PROGRESS" | "NONE" | "UPCOMING" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type SyncListGridQueryVariables = {|
  companyId: string,
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
|};
export type SyncListGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +data: ?$ReadOnlyArray<?{|
        +id: string,
        +status: SyncStatusType,
        +comment: ?string,
        +uploaderName: string,
        +dataUpload: ?any,
        +type: SyncFileType,
        +dataCount: ?number,
        +created: ?any,
        +files: ?$ReadOnlyArray<?{|
          +id: string,
          +name: ?string,
          +type: ?string,
        |}>,
        +contract: ?{|
          +name: ?string
        |},
      |}>
    |}
  |}
|};
export type SyncListGridQuery = {|
  variables: SyncListGridQueryVariables,
  response: SyncListGridQueryResponse,
|};
*/


/*
query SyncListGridQuery(
  $companyId: ID!
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
) {
  Me {
    Company(id: $companyId) {
      data: Sync(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
        id
        status
        comment
        uploaderName
        dataUpload
        type
        dataCount
        created
        files {
          id
          name
          type
        }
        contract {
          name
          id
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "endRow",
        "variableName": "endRow"
      },
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "sorting",
        "variableName": "sortModel"
      },
      {
        "kind": "Variable",
        "name": "startRow",
        "variableName": "startRow"
      }
    ],
    "kind": "ObjectValue",
    "name": "options"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "comment",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uploaderName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataUpload",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dataCount",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "files",
  "plural": true,
  "selections": [
    (v7/*: any*/),
    (v15/*: any*/),
    (v12/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SyncListGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v6/*: any*/),
                "concreteType": "Sync",
                "kind": "LinkedField",
                "name": "Sync",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Contract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SyncListGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v6/*: any*/),
                "concreteType": "Sync",
                "kind": "LinkedField",
                "name": "Sync",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v16/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Contract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "78dde8049486184d50b29d3de41fc17e",
    "id": null,
    "metadata": {},
    "name": "SyncListGridQuery",
    "operationKind": "query",
    "text": "query SyncListGridQuery(\n  $companyId: ID!\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n) {\n  Me {\n    Company(id: $companyId) {\n      data: Sync(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n        id\n        status\n        comment\n        uploaderName\n        dataUpload\n        type\n        dataCount\n        created\n        files {\n          id\n          name\n          type\n        }\n        contract {\n          name\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9ecee9f66e7ba6aa0ff27b61640954fa';

module.exports = node;
