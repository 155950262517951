import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { FilterBadges } from '../Badges'
import ContractsFilter from '../ContractsFilter'
import StatusBox from '../StatusBox'

import { FilterWrapper } from './PyrexxOverview.styles'
import handleLocalStorage from '../../helper/handleLocalStorage'
import { usePyrexxOverview } from '../../providers/PyrexxOverviewContext'

const PyrexxOverview = (props) => {
  const {
    contracts = [],
    titleElements = [],
    rowElements = [],
    setFilterModel = (filters) => {},
    setVariables = (apiFilters) => {},
    customKeys = {},
    variant,
    loading = false,
  } = props

  const {
    uiFilters,
    saveUiFilters,
    removeUiFilter,
    boxFilters,
    saveBoxFilters,
    removeBoxFilter,
  } = usePyrexxOverview()

  const handleClick = (el) => {
    const filter = el?.filter
    saveBoxFilters([filter])

    handleLocalStorage('clear', 'appointmentsFilter')
    handleLocalStorage('clear', 'invoicesFilter')
  }

  useEffect(() => {
    // console.log('uiFilters', uiFilters)
    // console.log('boxFilters', boxFilters)

    let apiFilters = {}
    for (const filter of uiFilters) {
      apiFilters = { ...apiFilters, ...filter?.apiFilterModel }
    }
    // console.log('apiFilters updated', apiFilters)
    setVariables(apiFilters)

    let agGridFilters = {}
    for (const filter of [...uiFilters, ...boxFilters]) {
      agGridFilters = { ...agGridFilters, ...filter?.filterModel }
    }
    // console.log('agGridFilters updated', agGridFilters)
    setFilterModel(agGridFilters)
    // eslint-disable-next-line
  }, [uiFilters, boxFilters])

  return (
    <div>
      <FilterWrapper>
        <FilterBadges filters={uiFilters} removeFilter={removeUiFilter} />

        <ContractsFilter
          contracts={contracts}
          saveFilters={saveUiFilters}
          filters={uiFilters}
          customKeys={customKeys}
        />
      </FilterWrapper>

      <StatusBox
        titleElements={titleElements}
        rowElements={rowElements}
        handleClick={handleClick}
        filters={boxFilters}
        removeFilter={removeBoxFilter}
        variant={variant}
        loading={loading}
      />
    </div>
  )
}

PyrexxOverview.propTypes = {
  contracts: PropTypes.array,
  titleElements: PropTypes.array,
  rowElements: PropTypes.array,
  setFilterModel: PropTypes.func,
  setVariables: PropTypes.func,
  customKeys: PropTypes.object,
  variant: PropTypes.oneOf(['button', 'link', 'anchor', 'div']),
  loading: PropTypes.bool,
}

export default PyrexxOverview
