import { appointmentTypeEnumList } from '../../../components/AgGrid/enumLists'

const noEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeValueGetter = (
  props
) => {
  const { value } = props
  if (!value || !value.length > 0) {
    return ''
  }
  return appointmentTypeEnumList(value[0].type)
}

export default noEntryIntoTheUsageUnitWithoutEquipmentAppointmentTypeValueGetter
