import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonIcon, portalTheme } from 'pyrexx-react-library'
import SimpleButton from '../../../components/Simple/SimpleButton'
import SimpleLink from '../../../components/Simple/SimpleLink'
import PlaceholderProfilePic from '../../../components/PlaceholderProfilePic'
import UploadImageModal from './UploadImageModal'

import { Routes } from '../../../configs/RouteConfig'
import { useUserContext } from '../../../helper/userContext'
import handleLocalStorage from '../../../helper/handleLocalStorage'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import {
  Boxes,
  Col,
  Container,
  EditButtonWrapper,
  Label,
  LeftBox,
  ProfilePic,
  RightBox,
  Row,
  StyledSimpleNote,
  Title,
  UserNotification,
} from './UserInfo.styles'

const UserInfo = ({
  info = {},
  waitingForEmailConfirmation,
  setWaitingForEmailConfirmation,
}) => {
  const { currentLanguage } = useUserContext()
  const { t } = useTranslation()

  const {
    userId = '',
    firstname = '',
    lastname = '',
    fullname = '',
    email = '',
  } = info

  const [hasPasswordChanged, setHasPasswordChanged] = useState(
    () => handleLocalStorage('get', 'temp-hasPasswordChanged') || false
  )
  const [uploadImageModal, setUploadImageModal] = useState(false)
  const [imgSrc, setImgSrc] = useState(
    () => handleLocalStorage('get', 'temp-img-src') || null
  )

  const openUploadImageModal = () => setUploadImageModal(true)
  const closeUploadImageModal = () => setUploadImageModal(false)

  React.useEffect(() => {
    return () => {
      handleLocalStorage('set', 'temp-hasPasswordChanged', false)
    }
  }, [])

  // TODO TEMP: wait for backend
  // eslint-disable-next-line
  const clearState = () => {
    setWaitingForEmailConfirmation(false)
    handleLocalStorage('clear', 'temp-waitingForEmailConfirmation')

    setHasPasswordChanged(false)
    handleLocalStorage('clear', 'temp-hasPasswordChanged')

    setImgSrc(null)
    handleLocalStorage('clear', 'temp-img-src')
  }

  useUpdateEffect(() => {
    handleLocalStorage('set', 'temp-waitingForEmailConfirmation', true)
  }, [waitingForEmailConfirmation])

  // eslint-disable-next-line
  const confirmEmail = () => {
    handleLocalStorage('set', 'temp-waitingForEmailConfirmation', false)
    window.location.reload(false)
  }

  return (
    <>
      {/* <div */}
      {/*  css={` */}
      {/*    position: fixed; */}
      {/*    left: 32px; */}
      {/*    bottom: 32px; */}
      {/*    z-index: 100; */}
      {/*  `} */}
      {/* > */}
      {/*  <button onClick={clearState}>Clear</button> */}
      {/*  <button onClick={confirmEmail}>Confirm email</button> */}
      {/* </div> */}

      <UserNotification show={waitingForEmailConfirmation}>
        <StyledSimpleNote
          text={t('PLEASE CONFIRM YOUR EMAIL ADDRESS')}
          noteStatus='warning'
        />

        <SimpleButton font='quicksand'>
          {t('RESEND REQUEST FOR EMAIL CONFIRMATION')}
        </SimpleButton>
      </UserNotification>

      <UserNotification show={hasPasswordChanged}>
        <StyledSimpleNote
          text={t('YOUR PASSWORD WAS UPDATED')}
          noteStatus='success'
        />
      </UserNotification>

      <Boxes>
        <LeftBox>
          <EditButtonWrapper>
            <ButtonIcon
              icon='edit'
              style={{ color: portalTheme.color.linkColorPrimary }}
              onClick={openUploadImageModal}
            />
          </EditButtonWrapper>

          {imgSrc ? (
            <ProfilePic
              src={imgSrc}
              alt='User profile'
              title='user profile picture'
              width='175'
              height='175'
            />
          ) : (
            <PlaceholderProfilePic firstname={firstname} lastname={lastname} />
          )}

          <UploadImageModal
            isOpen={uploadImageModal}
            handleModalClose={closeUploadImageModal}
            setImgSrc={setImgSrc}
          />
        </LeftBox>

        <RightBox>
          <Container>
            <Row>
              <Col xs={3}>
                <Title>{t('USER ID')}</Title>
                <Label>{userId}</Label>
              </Col>

              <Col xs={3}>
                <Title>{t('NAME')}</Title>
                <Label>{fullname}</Label>
              </Col>

              <Col xs={3}>
                <Title>
                  {t('EMAIL')}

                  <SimpleLink to={'/' + currentLanguage + Routes.editEmail}>
                    <ButtonIcon
                      icon='edit'
                      style={{ color: portalTheme.color.linkColorPrimary }}
                    />
                  </SimpleLink>
                </Title>

                <Label>{email.length > 0 ? email : '-'}</Label>
              </Col>

              <Col xs={3}>
                <Title>
                  {t('PASSWORD')}

                  <SimpleLink to={'/' + currentLanguage + Routes.editPassword}>
                    <ButtonIcon
                      icon='edit'
                      style={{ color: portalTheme.color.linkColorPrimary }}
                    />
                  </SimpleLink>
                </Title>
                <Label>********</Label>
              </Col>
            </Row>
          </Container>
        </RightBox>
      </Boxes>
    </>
  )
}

export default UserInfo
