import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query CountLettersQuery($filters: [Filter], $companyId: ID!) {
    Me {
      Company(id: $companyId) {
        businessPost {
          data: countLetters(options: { filters: $filters })
        }
      }
    }
  }
`
