import React from 'react'

import FaqList from './FaqList'

const Faq = ({ category = {} }) => {
  return (
    <>
      <p>{category.label}</p>
      <FaqList questions={category.questions} />
    </>
  )
}

export default Faq
