import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ButtonPrimary } from 'pyrexx-react-library'
import ArrowWithBadge from './ArrowWithBadge'
import Dropleft from './Dropleft'

import * as S from './styles'
import { DropleftBadge } from '../Badges'

const Dropdown = ({
  isDropdownOpen = false, // Comes from DropdownWithLabel because of passPropsToChildren prop
  setIsDropdownOpen = () => {}, // Comes from DropdownWithLabel because of passPropsToChildren prop
  ...props
}) => {
  const {
    innerFiltersList = [],
    saveFilters = () => {},
    filters = [],
    align = true,
  } = props
  const { t } = useTranslation()

  const closeDropdown = () => setIsDropdownOpen(false)

  const [dropleft, setDropleft] = useState('')
  const toggleDropleft = (filterId) => {
    setDropleft((prev) => (prev && prev === filterId ? '' : filterId))
  }
  const closeDropleft = () => setDropleft('')

  const [innerFilters, setInnerFilters] = useState([])
  const saveInnerFilters = (f) => {
    setInnerFilters((prevState) => {
      const filteredState = prevState.filter((i) => i?.filterId !== f?.filterId)
      return [...filteredState, f]
    })
  }

  useEffect(() => {
    if (!isDropdownOpen) {
      closeDropleft()
    }
  }, [isDropdownOpen])

  useEffect(() => {
    setInnerFilters(filters)
  }, [filters])

  return (
    <S.Dropdown>
      {innerFiltersList.map(
        ({ filterId, title, placeholderLabel, data, filterModelGenerator }) => {
          const currentInnerFilter = innerFilters.find(
            (f) => f?.filterId === filterId
          )

          return (
            <S.DropdownItem key={filterId}>
              <span>{title}</span>
              <ArrowWithBadge
                isActive={dropleft === filterId}
                handleClick={() => toggleDropleft(filterId)}
              >
                {currentInnerFilter?.label || placeholderLabel}
              </ArrowWithBadge>

              <Dropleft
                tabIndex='-1'
                filterId={filterId}
                dropleft={dropleft}
                align={align}
              >
                {data.map((d) => {
                  const currentDropleft = filterModelGenerator(d)

                  const specialCase = d?.specialCase || false

                  if (specialCase) {
                    return (
                      <div key={d?.id} style={{ paddingTop: '8px' }}>
                        <span
                          style={{
                            fontSize: '12px',
                            fontStyle: 'italic',
                            color: '#999',
                          }}
                        >
                          {t('SINGLE GRAFFITI REMOVAL')}
                        </span>
                        <DropleftBadge
                          onClick={() => {
                            saveInnerFilters(currentDropleft)
                            closeDropleft()
                          }}
                          selected={
                            currentInnerFilter?.tableId ===
                            currentDropleft?.tableId
                          }
                        >
                          {currentDropleft?.label}
                        </DropleftBadge>
                      </div>
                    )
                  }

                  return (
                    <DropleftBadge
                      key={d?.id}
                      onClick={() => {
                        saveInnerFilters(currentDropleft)
                        closeDropleft()
                      }}
                      selected={
                        currentInnerFilter?.tableId === currentDropleft?.tableId
                      }
                    >
                      {currentDropleft?.label}
                    </DropleftBadge>
                  )
                })}
              </Dropleft>
            </S.DropdownItem>
          )
        }
      )}

      <ButtonPrimary
        onClick={() => {
          saveFilters(innerFilters)
          closeDropdown()
        }}
      >
        {t('APPLY FILTER')}
      </ButtonPrimary>
    </S.Dropdown>
  )
}

export default Dropdown
