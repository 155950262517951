import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PageContainer from '../../components/PageContainer'
import PageTitle from '../../components/PageTitle'
import UserInfo from './UserInfo'
import Spacer from '../../components/Spacer'
import UserNotificationSettings from './UserNotificationSettings'

import handleLocalStorage from '../../helper/handleLocalStorage'
import { useUserContext } from '../../helper/userContext'

const User = () => {
  const { user } = useUserContext()
  const { t } = useTranslation()

  // TODO WAIT FOR BACKEND
  const [waitingForEmailConfirmation, setWaitingForEmailConfirmation] =
    useState(
      () =>
        handleLocalStorage('get', 'temp-waitingForEmailConfirmation') || false
    )

  const info = {
    userId: user.userId.replace('pom::pomuser::', ''),
    firstname: user.firstname,
    lastname: user.lastname,
    fullname: user.firstname + ' ' + user.lastname,
    email: user.email,
  }

  return (
    <PageContainer>
      <PageTitle>{t('MY ACCOUNT')}</PageTitle>
      <UserInfo
        info={info}
        waitingForEmailConfirmation={waitingForEmailConfirmation}
        setWaitingForEmailConfirmation={setWaitingForEmailConfirmation}
      />
      {/* BETA DISABLED */}
      <Spacer />
      <UserNotificationSettings
        email={info.email}
        waitingForEmailConfirmation={waitingForEmailConfirmation}
        setWaitingForEmailConfirmation={setWaitingForEmailConfirmation}
      />
    </PageContainer>
  )
}

export default User
