/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContactDataType = "EMAIL" | "NONE" | "TEL" | "WWW" | "%future added value";
export type TenantStatusType = "NONE" | "STATUS_ACTIVE" | "STATUS_MOVED_OUT" | "STATUS_NOT_YET_MOVED_IN" | "%future added value";
export type UsageUnitTypeType = "BUSINESS" | "COMMON" | "NONE" | "PRIVATE" | "%future added value";
export type UsageUnitContainerQueryVariables = {|
  companyId: string,
  usageUnitId: string,
|};
export type UsageUnitContainerQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +id: string,
      +Contracts: ?$ReadOnlyArray<?{|
        +serviceCategory: ?{|
          +id: string,
          +tableId: number,
          +name: string,
        |}
      |}>,
      +UsageUnit: ?{|
        +id: string,
        +tableId: number,
        +referenceNumber: string,
        +rooms: number,
        +size: number,
        +type: UsageUnitTypeType,
        +floor: number,
        +position: string,
        +address: ?{|
          +street: string,
          +number: string,
          +zip: string,
          +city: string,
        |},
        +tenants: $ReadOnlyArray<?{|
          +id: ?string,
          +firstname: string,
          +lastname: string,
          +begin: ?any,
          +end: ?any,
          +status: TenantStatusType,
          +contact: $ReadOnlyArray<?{|
            +type: ContactDataType,
            +value: string,
          |}>,
        |}>,
      |},
    |}
  |}
|};
export type UsageUnitContainerQuery = {|
  variables: UsageUnitContainerQueryVariables,
  response: UsageUnitContainerQueryResponse,
|};
*/


/*
query UsageUnitContainerQuery(
  $companyId: ID!
  $usageUnitId: ID!
) {
  Me {
    Company(id: $companyId) {
      id
      Contracts(options: {endRow: -1}) {
        serviceCategory {
          id
          tableId
          name
        }
        id
      }
      UsageUnit(id: $usageUnitId) {
        id
        tableId
        referenceNumber
        rooms
        size
        type
        floor
        position
        address {
          street
          number
          zip
          city
        }
        tenants(options: {filters: [{filterType: number, operator: AND, name: "status", conditions: [{value: ["STATUS_ACTIVE"], type: equals}]}]}) {
          id
          firstname
          lastname
          begin
          end
          status
          contact {
            type
            value
          }
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "usageUnitId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "options",
    "value": {
      "endRow": -1
    }
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ServiceCategory",
  "kind": "LinkedField",
  "name": "serviceCategory",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "id",
      "variableName": "usageUnitId"
    }
  ],
  "concreteType": "UsageUnit",
  "kind": "LinkedField",
  "name": "UsageUnit",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "referenceNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rooms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "size",
      "storageKey": null
    },
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Address",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "street",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zip",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "options",
          "value": {
            "filters": [
              {
                "conditions": [
                  {
                    "type": "equals",
                    "value": [
                      "STATUS_ACTIVE"
                    ]
                  }
                ],
                "filterType": "number",
                "name": "status",
                "operator": "AND"
              }
            ]
          }
        }
      ],
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenants",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "begin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "end",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactData",
          "kind": "LinkedField",
          "name": "contact",
          "plural": true,
          "selections": [
            (v6/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "tenants(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"STATUS_ACTIVE\"]}],\"filterType\":\"number\",\"name\":\"status\",\"operator\":\"AND\"}]})"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsageUnitContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "Contracts",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": "Contracts(options:{\"endRow\":-1})"
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsageUnitContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "Contracts",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": "Contracts(options:{\"endRow\":-1})"
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "22ca12aabb8adb44c9dc9066a3621df0",
    "id": null,
    "metadata": {},
    "name": "UsageUnitContainerQuery",
    "operationKind": "query",
    "text": "query UsageUnitContainerQuery(\n  $companyId: ID!\n  $usageUnitId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      id\n      Contracts(options: {endRow: -1}) {\n        serviceCategory {\n          id\n          tableId\n          name\n        }\n        id\n      }\n      UsageUnit(id: $usageUnitId) {\n        id\n        tableId\n        referenceNumber\n        rooms\n        size\n        type\n        floor\n        position\n        address {\n          street\n          number\n          zip\n          city\n        }\n        tenants(options: {filters: [{filterType: number, operator: AND, name: \"status\", conditions: [{value: [\"STATUS_ACTIVE\"], type: equals}]}]}) {\n          id\n          firstname\n          lastname\n          begin\n          end\n          status\n          contact {\n            type\n            value\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ddd4cb189be917daa2138b16ac8065a4';

module.exports = node;
