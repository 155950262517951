/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PropertyManagementContainerQueryVariables = {|
  companyId: string
|};
export type PropertyManagementContainerQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +countHouseEntrances: ?number,
      +Contracts: ?$ReadOnlyArray<?{|
        +id: string,
        +tableId: number,
        +name: ?string,
        +serviceCategory: ?{|
          +id: string,
          +tableId: number,
          +name: string,
        |},
      |}>,
    |}
  |}
|};
export type PropertyManagementContainerQuery = {|
  variables: PropertyManagementContainerQueryVariables,
  response: PropertyManagementContainerQueryResponse,
|};
*/


/*
query PropertyManagementContainerQuery(
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      countHouseEntrances
      Contracts(options: {endRow: -1, filters: [{name: "status", filterType: text, operator: AND, conditions: [{value: ["ACTIVE"], type: equals}]}]}) {
        id
        tableId
        name
        serviceCategory {
          id
          tableId
          name
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "countHouseEntrances",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "endRow": -1,
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "ACTIVE"
                ]
              }
            ],
            "filterType": "text",
            "name": "status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "concreteType": "Contract",
  "kind": "LinkedField",
  "name": "Contracts",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ServiceCategory",
      "kind": "LinkedField",
      "name": "serviceCategory",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": "Contracts(options:{\"endRow\":-1,\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"ACTIVE\"]}],\"filterType\":\"text\",\"name\":\"status\",\"operator\":\"AND\"}]})"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyManagementContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropertyManagementContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v6/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "751622c396845950be8b3b2ccda297f3",
    "id": null,
    "metadata": {},
    "name": "PropertyManagementContainerQuery",
    "operationKind": "query",
    "text": "query PropertyManagementContainerQuery(\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      countHouseEntrances\n      Contracts(options: {endRow: -1, filters: [{name: \"status\", filterType: text, operator: AND, conditions: [{value: [\"ACTIVE\"], type: equals}]}]}) {\n        id\n        tableId\n        name\n        serviceCategory {\n          id\n          tableId\n          name\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a77e13f429c94932d4e67151bcaf74f8';

module.exports = node;
