import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

const Wrapper = styled.section`
  display: flex;
  gap: 24px;

  background-color: white;
`

const LogoWrapper = styled.div`
  width: 9rem;
  height: 9rem;
`

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const CompanyInfo = styled.div`
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

  gap: 16px;
`

const CompanyName = styled.h2`
  margin: 0;
`

const SmallerCompanyName = styled(CompanyName)`
  font-size: 1.25rem;
`

const LinkToCompanySwitcher = styled.p`
  margin: 0;
  font-size: 1.1rem;
`

const PlaceholderLogo = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  margin: 0;

  background-color: #daeaff;
  font-size: 3.5rem;
`

const CompanyLogo = ({ logoSrc = '', companyName = 'A' }) => {
  const companyNameSubstring = companyName.substring(0, 3).toUpperCase()

  return (
    <LogoWrapper>
      {logoSrc ? (
        <Logo src={logoSrc} />
      ) : (
        <PlaceholderLogo>{companyNameSubstring}</PlaceholderLogo>
      )}
    </LogoWrapper>
  )
}

const CompanyBanner = (props) => {
  const { multipleCompanies = false, link = null } = props

  const logoSrc = props.logoSrc || ''
  const companyName = props.companyName || ''

  const CompanyNameSelector =
    companyName.length > 60 ? SmallerCompanyName : CompanyName

  return (
    <Wrapper>
      <CompanyLogo logoSrc={logoSrc} companyName={companyName} />

      <CompanyInfo>
        <CompanyNameSelector>{companyName}</CompanyNameSelector>

        {multipleCompanies ? (
          <LinkToCompanySwitcher>{link}</LinkToCompanySwitcher>
        ) : null}
      </CompanyInfo>
    </Wrapper>
  )
}

export default CompanyBanner

CompanyBanner.propTypes = {
  logoSrc: PropTypes.string,
  companyName: PropTypes.string,
  multipleCompanies: PropTypes.bool,
  link: PropTypes.any,
}
