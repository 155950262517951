import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import NotificationItem from './NotificationItem'

const NotificationsList = ({
  notifications = [],
  selectedNotification = {},
  onNotificationClick = () => {},
}) => {
  const { t } = useTranslation()

  if (notifications.length <= 0) {
    return <p>{t('NO OPEN NOTIFICATIONS')}</p>
  }

  return (
    <Wrapper>
      {notifications.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          isSelected={notification.id === selectedNotification.id}
          onNotificationClick={() => onNotificationClick(notification)}
        />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 8px;
`

NotificationsList.propTypes = {}

export default NotificationsList
