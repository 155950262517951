/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PermissionType = "ADMIN" | "APPOINTMENT" | "CONTRACT" | "DATA_UPDATE" | "INVENTORY" | "INVOICE" | "NONE" | "POST_LETTER" | "POST_VIEW_ALL" | "QUOTA" | "%future added value";
export type CreateUserQueryVariables = {|
  companyId: string
|};
export type CreateUserQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +availablePermissions: ?$ReadOnlyArray<?PermissionType>,
      +contracts: ?$ReadOnlyArray<?{|
        +tableId: number,
        +name: ?string,
        +billingPartitions: ?$ReadOnlyArray<?{|
          +tableId: number,
          +name: string,
        |}>,
      |}>,
    |}
  |}
|};
export type CreateUserQuery = {|
  variables: CreateUserQueryVariables,
  response: CreateUserQueryResponse,
|};
*/


/*
query CreateUserQuery(
  $companyId: ID!
) {
  Me {
    Company(id: $companyId) {
      availablePermissions: permissions
      contracts: Contracts {
        tableId
        name
        billingPartitions {
          tableId
          name
          id
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": "availablePermissions",
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "contracts",
                "args": null,
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "Contracts",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingPartition",
                    "kind": "LinkedField",
                    "name": "billingPartitions",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "contracts",
                "args": null,
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "Contracts",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BillingPartition",
                    "kind": "LinkedField",
                    "name": "billingPartitions",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8b7fa961ef9f0b342daf9a68d60288da",
    "id": null,
    "metadata": {},
    "name": "CreateUserQuery",
    "operationKind": "query",
    "text": "query CreateUserQuery(\n  $companyId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      availablePermissions: permissions\n      contracts: Contracts {\n        tableId\n        name\n        billingPartitions {\n          tableId\n          name\n          id\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7934e73480067c0ca09c1816265c2011';

module.exports = node;
