import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import { StyledInput } from 'pyrexx-react-library'

const Wrapper = styled.div`
  & > span > label > label > div {
    align-items: center;
  }

  & > span > label > div {
    ${(props) => !props.withFeedback && 'display: none;'}
  }

  & > span > label > label > div > div {
    display: flex;
  }

  & svg.icon {
    padding-right: 8px;
  }
`

const SimpleCheckbox = ({
  label,
  value,
  onChange,
  id,
  withFeedback = false,
  className,
}) => {
  return (
    <Wrapper withFeedback={withFeedback} className={className}>
      <StyledInput
        label={label}
        type='checkbox'
        value={value}
        onChange={onChange}
        id={id}
        name={id}
        setFieldValue={() => {}}
      />
    </Wrapper>
  )
}

SimpleCheckbox.propTypes = {
  label: PropTypes.any.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  withFeedback: PropTypes.bool,
}

export default SimpleCheckbox
