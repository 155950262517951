import React, { Suspense, useContext, useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { ErrorBoundary } from 'react-error-boundary'

import {
  CookieBanner,
  hasCookie,
  initDatadog,
  LoadingSpinner,
} from 'pyrexx-react-library'
import Layout from './Layout/Layout'
import Loading from './LoadingSpinner/LoadingSpinner'
import AppRouter from '../routes/AppRouter'

import UserContext from '../helper/userContext'
import { Routes } from '../configs/RouteConfig'
import { WhiteRoutes } from '../configs/WhiteListForRoutes'
import ErrorFallback from './ErrorFallback/ErrorFallback'

const BANNER_PREFIX = 'pomPortal'

const App = (props) => {
  const firstCall = {
    req: false,
    pref: false,
    stats: false,
  }
  const [cookies, setCookies] = useState({
    req: hasCookie('consent', BANNER_PREFIX),
    pref: hasCookie('preferences', BANNER_PREFIX),
    stats: hasCookie('statistics', BANNER_PREFIX),
  })

  const {
    isTokenValid,
    getValidLangAndSite,
    getValidPath,
    changeLanguage,
    currentLanguage,
    logout,
    getCompanySwitcherRedirectIfNeeded,
    getValidQueryString,
  } = useContext(UserContext)

  const location = useLocation()

  useEffect(() => {
    const params = getValidLangAndSite()
    if (params.lang !== currentLanguage) {
      changeLanguage(params.lang)
    }
  }, [changeLanguage, currentLanguage, getValidLangAndSite])

  const [initPage, setInitPage] = useState(false)

  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    const siteParams = getValidLangAndSite()
    const setPath = getValidPath(
      siteParams.lang,
      siteParams.site,
      siteParams.extra,
      siteParams.id
    )

    const setPathWithFilter = getValidPath(
      siteParams.lang,
      siteParams.site,
      siteParams.extra,
      siteParams.id,
      'transfertoken'
    )

    if (currentLanguage !== siteParams.lang || location.pathname !== setPath) {
      setRedirect({
        pathname: setPath,
      })
    } else {
      if (!WhiteRoutes.includes(siteParams.site)) {
        isTokenValid((isValid, command, transferToken) => {
          if (isValid !== true) {
            setRedirect({
              pathname: '/' + siteParams.lang + Routes.login,
              state: {
                redirect: setPathWithFilter,
                search: getValidQueryString(),
              },
            })
          } else {
            if (siteParams.extra === 'transfertoken') {
              setRedirect(getCompanySwitcherRedirectIfNeeded(setPathWithFilter))
            } else {
              if (setPath !== setPathWithFilter) {
                setRedirect({
                  pathname: setPathWithFilter,
                })
              } else {
                setRedirect(false)
                setInitPage(true)
              }
            }
          }
        })
      } else {
        setRedirect(false)
        setInitPage(true)
      }
    }
  }, [
    currentLanguage,
    getCompanySwitcherRedirectIfNeeded,
    getValidLangAndSite,
    getValidPath,
    getValidQueryString,
    isTokenValid,
    location.pathname,
    location.search,
    logout,
    redirect,
  ])

  useEffect(() => {
    if (initPage && cookies.stats && process.env.NODE_ENV === 'production') {
      initDatadog(
        process.env.REACT_APP_DATADOG_APP_ID,
        process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        process.env.REACT_APP_DATADOG_SITE,
        process.env.REACT_APP_DATADOG_SERVICE,
        process.env.REACT_APP_DATADOG_CLIENT_ENV,
        process.env.REACT_APP_DATADOG_CLIENT_VERSION,
        100,
        100,
        true
      )
    }
  }, [initPage, cookies.stats, cookies])

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (!initPage) {
    return <LoadingSpinner />
  }

  if (initPage) {
    return (
      <Layout cookies={cookies}>
        <Suspense fallback={<Loading />}>
          <CookieBanner
            bannerPrefix={BANNER_PREFIX}
            checkboxHeaderLabel={
              props.t('I CONSENT TO THE FOLLOWING COOKIES') + ' :'
            }
            // eslint-disable-next-line
            // prettier-ignore
            message={props.t('WE WANT TO USE SOME COOKIES TO HELP MAKING THE BEST POSSIBLE EXPERIENCE FOR YOU.' +
              'THE NECESSARY COOKIES SIMPLY SAVE YOUR CONSENT SETTING, NOTHING ELSE.' +
              'THE STATISTIC COOKIES HELP US GETTING SOME INSIGHT INTO YOUR USAGE OF OUR QUOTATION PORTAL, THE USABILITY COOKIES SAVE YOUR CONTACT DATA SO THAT YOU' +
              'DONT HAVE TO RETYPE THEM EVERY TIME. FOR MORE DETAILS SEE OUR PRIVACY POLICY')}
            declineButtonText={props.t('NO COOKIES PLEASE')}
            acceptButtonText={props.t('I CONSENT')}
            statisticsOptionText={props.t('STATISTICAL')}
            preferencesOptionText={props.t('USABILITY')}
            necessaryOptionText={props.t('NECESSARY')}
            showDeclineButton
            privacyPolicyLinkText={props.t('DATA PRIVACY')}
            policyLink={'/' + currentLanguage + Routes.privacy}
            onAccept={() => {
              if (firstCall.req) {
                firstCall.req = false
                setCookies({ ...cookies, req: true })
              }
            }}
            onAcceptPreferences={() => {
              if (firstCall.pref) {
                firstCall.pref = false
                setCookies({ ...cookies, pref: true })
              }
            }}
            onAcceptStatistics={() => {
              if (firstCall.stats) {
                firstCall.stats = false
                setCookies({ ...cookies, stats: true })
              }
            }}
            clickOnConfirm={() => {
              setCookies({
                req: hasCookie('consent', BANNER_PREFIX),
                pref: hasCookie('preferences', BANNER_PREFIX),
                stats: hasCookie('statistics', BANNER_PREFIX),
              })
            }}
            showMarketingOption={false}
          />
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => window.location.reload()}
            resetKeys={[location]}
          >
            <AppRouter />
          </ErrorBoundary>
        </Suspense>
      </Layout>
    )
  }
}

export default withTranslation('translation')(App)
