function dummyTranslate(text) {
  return text
}

const invoiceStatusEnumList = (value) => {
  switch (value) {
    case 'OPEN':
      return dummyTranslate('OPEN')

    case 'PAID':
      return dummyTranslate('PAID')

    case 'REMINDED':
      return dummyTranslate('DUNNING LETTER')

    case 'CANCELLED':
      return dummyTranslate('REVERSED')

    case 'NONE':
    default:
      return ''
  }
}

export default invoiceStatusEnumList
