import styled from 'styled-components/macro'

const Wrapper = styled.div``
const TabsRow = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;

  margin-bottom: 16px;
  border-bottom: ${(props) =>
    props?.borderBottomStyle ? props.borderBottomStyle : ''};
`
const TabsDescription = styled.h3`
  margin: 0;
  padding-bottom: 6px;
`
const TabsNavigator = styled.div`
  display: flex;
  gap: 12px;
`
const TabsContent = styled.div``

export { Wrapper, TabsRow, TabsDescription, TabsNavigator, TabsContent }
