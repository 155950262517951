import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query Filter2AccessNotGrantedCountQuery($companyId: ID!, $filters: [Filter]) {
    Me {
      id
      Company(id: $companyId) {
        data: countAppointments(options: { filters: $filters })
      }
    }
  }
`
