import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-grid-system'
import { withTranslation } from 'react-i18next'

import { StyledList, StyledSimplePullDown } from './MobilePullDown.styles'

const MobilePullDown = (props) => {
  const generateValues = useCallback(() => {
    return (
      <StyledList component='ul' fluid>
        {props.values.map((value, index) => {
          return (
            <Row
              component='li'
              key={'pulldown' + value + index}
              nogutter
              justify='center'
            >
              <Col xs='content'>{value}</Col>
            </Row>
          )
        })}
      </StyledList>
    )
  }, [props.values])

  return (
    <StyledSimplePullDown
      headerLabelElement={props.headerLabel}
      values={generateValues()}
    />
  )
}

MobilePullDown.propTypes = {
  values: PropTypes.arrayOf(PropTypes.any).isRequired,
  headerLabel: PropTypes.string.isRequired
}

export default withTranslation('translation')(MobilePullDown)
