/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type RoomUsageType = "BEDROOM" | "CHILDRENS_BEDROOM" | "COMMON_SPACES" | "COMMON_SPACES_ATTIC" | "COMMON_SPACES_BASEMENT" | "CORRIDOR" | "DININGROOM" | "GUESTROOM" | "KITCHEN" | "LIVINGROOM" | "LIVINGROOM_BEDROOM" | "LOGGIA" | "NONE" | "OFFICE_WORKROOM" | "ROOM" | "WALK_THROUGH_ROOM" | "WC_BATHROOM" | "%future added value";
export type InventoryGridQueryVariables = {|
  companyId: string,
  usageUnitId: string,
|};
export type InventoryGridQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +UsageUnit: ?{|
        +data: $ReadOnlyArray<?{|
          +id: ?string,
          +position: ?{|
            +id: string,
            +room: ?{|
              +id: string,
              +type: ?RoomUsageType,
            |},
          |},
          +product: ?{|
            +id: string,
            +name: string,
          |},
          +quantity: ?number,
          +installationDate: ?any,
        |}>
      |}
    |}
  |}
|};
export type InventoryGridQuery = {|
  variables: InventoryGridQueryVariables,
  response: InventoryGridQueryResponse,
|};
*/


/*
query InventoryGridQuery(
  $companyId: ID!
  $usageUnitId: ID!
) {
  Me {
    Company(id: $companyId) {
      UsageUnit(id: $usageUnitId) {
        data: inventory(options: {endRow: 2000}) {
          id
          position {
            id
            room {
              id
              type
            }
          }
          product {
            id
            name
          }
          quantity
          installationDate
        }
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "usageUnitId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "usageUnitId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "data",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "endRow": 2000
      }
    }
  ],
  "concreteType": "Device",
  "kind": "LinkedField",
  "name": "inventory",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Position",
      "kind": "LinkedField",
      "name": "position",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Room",
          "kind": "LinkedField",
          "name": "room",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "installationDate",
      "storageKey": null
    }
  ],
  "storageKey": "inventory(options:{\"endRow\":2000})"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InventoryGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "UsageUnit",
                "kind": "LinkedField",
                "name": "UsageUnit",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InventoryGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "UsageUnit",
                "kind": "LinkedField",
                "name": "UsageUnit",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "23634a52e460f6f1158bc4f45f9a712a",
    "id": null,
    "metadata": {},
    "name": "InventoryGridQuery",
    "operationKind": "query",
    "text": "query InventoryGridQuery(\n  $companyId: ID!\n  $usageUnitId: ID!\n) {\n  Me {\n    Company(id: $companyId) {\n      UsageUnit(id: $usageUnitId) {\n        data: inventory(options: {endRow: 2000}) {\n          id\n          position {\n            id\n            room {\n              id\n              type\n            }\n          }\n          product {\n            id\n            name\n          }\n          quantity\n          installationDate\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1ad75db17b886ba5db862bafdc68f218';

module.exports = node;
