function dummyTranslate(text) {
  return text
}

const syncTypeEnumList = (value) => {
  switch (value) {
    case 'PLEASE_SELECT':
      return dummyTranslate('PLEASE SELECT')
    case 'FULL_UPDATE':
      return dummyTranslate('FULL UPDATE')
    case 'INCREMENTAL_UPDATE':
      return dummyTranslate('INCREMENTAL UPDATE')
    case 'API_TENANT_UPDATE':
      return dummyTranslate('TENANT UPDATE')
    case 'COMPANY_LOGO':
      return dummyTranslate('COMPANY LOGO')
    case 'DISPOSAL_NOTIFICATION':
      return dummyTranslate('DISPOSAL NOTIFICATION')
    case 'DEMOLITION_NOTIFICATION':
      return dummyTranslate('DEMOLITION NOTIFICATION')
    case 'MISC':
      return dummyTranslate('MISC')
    case 'DRINKWATER_DATA':
      return dummyTranslate('DRINKWATER DATA')
    case 'DRINKWATER_ORDER_NO_DATA':
      return dummyTranslate('DRINKWATER ORDER NO DATA')
    case 'PROPERTIES':
      return dummyTranslate('PROPERTIES')
    default:
      return ''
  }
}

export default syncTypeEnumList
