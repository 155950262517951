import React from 'react'
import { useTranslation } from 'react-i18next'
import { appointmentTypeValueGetter } from '../valueGetter'

const AppointmentTypeRenderer = (props) => {
  const { t } = useTranslation()

  return <span>{t(appointmentTypeValueGetter(props))}</span>
}

export default AppointmentTypeRenderer
