import React from 'react'
import BulletedList from '../../../components/AgGrid/renderers/BulletedList'

const ContactRenderer = (props) => {
  const { value } = props

  return (
    <BulletedList>
      {value.map((v) => (
        <li key={v.value}>{v.value}</li>
      ))}
    </BulletedList>
  )
}

export default ContactRenderer
