/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PropertyManagementCardQueryVariables = {|
  companyId: string
|};
export type PropertyManagementCardQueryResponse = {|
  +Me: ?{|
    +propertyManagementStatus: ?{|
      +active: ?number,
      +locked: ?number,
      +partial: ?number,
    |}
  |}
|};
export type PropertyManagementCardQuery = {|
  variables: PropertyManagementCardQueryVariables,
  response: PropertyManagementCardQueryResponse,
|};
*/


/*
query PropertyManagementCardQuery(
  $companyId: ID!
) {
  Me {
    propertyManagementStatus: Company(id: $companyId) {
      active: countUsageUnits(options: {filters: [{name: "lockPeriod.status", filterType: text, operator: AND, conditions: [{value: ["ACTIVE"], type: equals}]}]})
      locked: countUsageUnits(options: {filters: [{name: "lockPeriod.status", filterType: text, operator: AND, conditions: [{value: ["DEACTIVATED"], type: equals}]}]})
      partial: countUsageUnits(options: {filters: [{name: "lockPeriod.status", filterType: text, operator: AND, conditions: [{value: ["PENDING"], type: equals}]}]})
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v2 = {
  "alias": "active",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "ACTIVE"
                ]
              }
            ],
            "filterType": "text",
            "name": "lockPeriod.status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "countUsageUnits",
  "storageKey": "countUsageUnits(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"ACTIVE\"]}],\"filterType\":\"text\",\"name\":\"lockPeriod.status\",\"operator\":\"AND\"}]})"
},
v3 = {
  "alias": "locked",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "DEACTIVATED"
                ]
              }
            ],
            "filterType": "text",
            "name": "lockPeriod.status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "countUsageUnits",
  "storageKey": "countUsageUnits(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"DEACTIVATED\"]}],\"filterType\":\"text\",\"name\":\"lockPeriod.status\",\"operator\":\"AND\"}]})"
},
v4 = {
  "alias": "partial",
  "args": [
    {
      "kind": "Literal",
      "name": "options",
      "value": {
        "filters": [
          {
            "conditions": [
              {
                "type": "equals",
                "value": [
                  "PENDING"
                ]
              }
            ],
            "filterType": "text",
            "name": "lockPeriod.status",
            "operator": "AND"
          }
        ]
      }
    }
  ],
  "kind": "ScalarField",
  "name": "countUsageUnits",
  "storageKey": "countUsageUnits(options:{\"filters\":[{\"conditions\":[{\"type\":\"equals\",\"value\":[\"PENDING\"]}],\"filterType\":\"text\",\"name\":\"lockPeriod.status\",\"operator\":\"AND\"}]})"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PropertyManagementCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "propertyManagementStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PropertyManagementCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": "propertyManagementStatus",
            "args": (v1/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "327a6847ab20b1c6015c7603f063cb6a",
    "id": null,
    "metadata": {},
    "name": "PropertyManagementCardQuery",
    "operationKind": "query",
    "text": "query PropertyManagementCardQuery(\n  $companyId: ID!\n) {\n  Me {\n    propertyManagementStatus: Company(id: $companyId) {\n      active: countUsageUnits(options: {filters: [{name: \"lockPeriod.status\", filterType: text, operator: AND, conditions: [{value: [\"ACTIVE\"], type: equals}]}]})\n      locked: countUsageUnits(options: {filters: [{name: \"lockPeriod.status\", filterType: text, operator: AND, conditions: [{value: [\"DEACTIVATED\"], type: equals}]}]})\n      partial: countUsageUnits(options: {filters: [{name: \"lockPeriod.status\", filterType: text, operator: AND, conditions: [{value: [\"PENDING\"], type: equals}]}]})\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '430c3e3edd2b13d6375c6bddbbc8f78c';

module.exports = node;
