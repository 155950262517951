import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query TenantHistoryGridQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
    $usageUnitId: ID!
  ) {
    Me {
      Company(id: $companyId) {
        UsageUnit(id: $usageUnitId) {
          data: tenants(
            options: {
              startRow: $startRow
              endRow: $endRow
              sorting: $sortModel
              filters: $filters
            }
          ) {
            id
            tableId
            status
            fullname
            firstname
            lastname
            begin
            end
            contact {
              type
              value
              verified
            }
          }
        }
      }
    }
  }
`
