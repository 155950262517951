import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import { CountStatusHistoryQuery, StatusHistoryGridQuery } from '../queries'
import {
  dateTimeValueGetter,
  tenantFullNameValueGetter,
  usageUnitLockPeriodReasonValueGetter,
  usageUnitLockPeriodStatusLabelValueGetter,
  usageUnitTypeValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../configs/RouteConfig'
import {
  TenantFullNameRenderer,
  UsageUnitLinkRenderer,
  UsageUnitLockPeriodReasonRenderer,
  UsageUnitLockPeriodStatusRenderer,
  UsageUnitTypeRenderer,
} from '../renderers'
import DateTimeRenderer from '../../../components/AgGrid/renderers/DateTimeRenderer'

export const useStatusOverviewGrid = () => {
  const { redirectRoute, currentLanguage } = useContext(UserContext)
  const { t } = useTranslation()
  const getParams = useCallback(
    (currentService, filterVariables) => {
      const { propertyUnitId } = filterVariables
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t(usageUnitLockPeriodStatusLabelValueGetter(params))
          case 'type':
            return t(usageUnitTypeValueGetter(params))
          case 'tenants':
            return tenantFullNameValueGetter(params)
          case 'reason':
            return t(usageUnitLockPeriodReasonValueGetter(params))
          case 'begin':
            return dateTimeValueGetter({
              ...params,
              currentLanguage: currentLanguage,
              formatStr: 'Pp',
            })
          default:
            return params.value
        }
      }
      const StatusHistoryColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          field: 'status',
          width: 70,
          cellRenderer: UsageUnitLockPeriodStatusRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('CUSTOMERS OWN USAGE UNIT NUMBER'),
          field: 'referenceNumber',
          width: 70,
          cellRenderer: UsageUnitLinkRenderer,
          filter: false,
        },
        {
          headerName: t('USAGE UNIT TYPE'),
          field: 'type',
          width: 70,
          cellRenderer: UsageUnitTypeRenderer,
          filter: false,
        },
        {
          headerName: t('RESIDENT'),
          field: 'tenants',
          width: 70,
          cellRenderer: TenantFullNameRenderer,
          filter: false,
        },
        {
          headerName: t('LOCK REASON'),
          field: 'reason',
          width: 100,
          cellRenderer: UsageUnitLockPeriodReasonRenderer,
          ...withFilterParams,
        },
        {
          headerName: t('LOCK INITIATOR'),
          field: 'initiator',
          width: 70,
          ...withFilterParams,
        },
        {
          headerName: t('TIME OF LOCK'),
          field: 'begin',
          width: 70,
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
          cellRenderer: DateTimeRenderer,
        },
      ]
      const StatusHistoryCustomFilter = []
      return {
        query: StatusHistoryGridQuery,
        columnDefs: StatusHistoryColumnDefs,
        customFilters: StatusHistoryCustomFilter,
        countQuery: CountStatusHistoryQuery,
        countExtractor: (response) => response.Me?.Company?.HouseEntrance?.data,
        extractor: (response) => {
          const data = response.Me?.Company?.HouseEntrance?.data.map(
            (usageUnit) => {
              return usageUnit.lockPeriods.map((locks) => {
                return {
                  ...locks,
                  referenceNumber: usageUnit.referenceNumber,
                  type: usageUnit.type,
                  tenants: usageUnit.tenants,
                }
              })
            }
          )
          return data.flat(1)
        },
        customGridOptions: {
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO USAGE UNIT {REFERENCENUMBER}', {
                  REFERENCENUMBER: params?.node?.data?.referenceNumber,
                }),
                action: function () {
                  redirectRoute(
                    Routes.usageUnit + '/' + params?.node?.data?.tableId
                  )
                },
              },
              'copy',
            ]
          },
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: { propertyUnitId },
      }
    },
    [currentLanguage, redirectRoute, t]
  )
  return { getParams }
}
