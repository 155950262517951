/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UploadRecipientsMutationVariables = {|
  companyId: string,
  letterId: number,
  file: any,
|};
export type UploadRecipientsMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +data: ?{|
          +valid: number,
          +invalid: number,
          +variables: ?$ReadOnlyArray<?string>,
        |}
      |}
    |}
  |}
|};
export type UploadRecipientsMutation = {|
  variables: UploadRecipientsMutationVariables,
  response: UploadRecipientsMutationResponse,
|};
*/


/*
mutation UploadRecipientsMutation(
  $companyId: ID!
  $letterId: Int!
  $file: Upload!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        data: uploadRecipients(letterId: $letterId, file: $file) {
          valid
          invalid
          variables
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "file"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "letterId"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessPostMutation",
            "kind": "LinkedField",
            "name": "businessPost",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "file",
                    "variableName": "file"
                  },
                  {
                    "kind": "Variable",
                    "name": "letterId",
                    "variableName": "letterId"
                  }
                ],
                "concreteType": "RecipientUploadType",
                "kind": "LinkedField",
                "name": "uploadRecipients",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "valid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "invalid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "variables",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadRecipientsMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadRecipientsMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c1f3360b08ba8319f4c0613a18b88f26",
    "id": null,
    "metadata": {},
    "name": "UploadRecipientsMutation",
    "operationKind": "mutation",
    "text": "mutation UploadRecipientsMutation(\n  $companyId: ID!\n  $letterId: Int!\n  $file: Upload!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        data: uploadRecipients(letterId: $letterId, file: $file) {\n          valid\n          invalid\n          variables\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '17e46a9b4528763538a3f60c5f237623';

module.exports = node;
