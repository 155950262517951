import React from 'react'
import styled from 'styled-components/macro'
import { useTranslation } from 'react-i18next'

import { InvoiceStatusRenderer } from '../../Invoices/renderers'

const Row = styled.div`
  & h3 {
    margin: 0;
    margin-bottom: 0.5rem;

    font-size: 13px;
    font-weight: 500;
  }

  & span {
    font-size: 16px;
    font-weight: 600;
  }

  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const InvoiceInfo = (props) => {
  const { info: { status, invoiceNumber } = {} } = props
  const { t } = useTranslation()

  return (
    <>
      <Row>
        <h3>{t('INVOICE STATUS')}</h3>
        <InvoiceStatusRenderer value={status} />
      </Row>

      <Row>
        <h3>{t('INVOICE NUMBER')}</h3>
        <span>{invoiceNumber}</span>
      </Row>
    </>
  )
}

export default InvoiceInfo
