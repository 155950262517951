/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CreateCompanyLayoutMutationVariables = {|
  companyId: string,
  letterLayoutId: number,
|};
export type CreateCompanyLayoutMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +createCompanyLayout: ?{|
          +id: string,
          +tableId: number,
        |}
      |}
    |}
  |}
|};
export type CreateCompanyLayoutMutation = {|
  variables: CreateCompanyLayoutMutationVariables,
  response: CreateCompanyLayoutMutationResponse,
|};
*/


/*
mutation CreateCompanyLayoutMutation(
  $companyId: ID!
  $letterLayoutId: Int!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        createCompanyLayout(letterLayoutId: $letterLayoutId) {
          id
          tableId
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "companyId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "letterLayoutId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessPostMutation",
            "kind": "LinkedField",
            "name": "businessPost",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "letterLayoutId",
                    "variableName": "letterLayoutId"
                  }
                ],
                "concreteType": "CompanyLayout",
                "kind": "LinkedField",
                "name": "createCompanyLayout",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tableId",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCompanyLayoutMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCompanyLayoutMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8f0da1540fdc93b783ffa0f41d775476",
    "id": null,
    "metadata": {},
    "name": "CreateCompanyLayoutMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCompanyLayoutMutation(\n  $companyId: ID!\n  $letterLayoutId: Int!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        createCompanyLayout(letterLayoutId: $letterLayoutId) {\n          id\n          tableId\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6d7b69dee726c70662dd2068a0974327';

module.exports = node;
