import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query OperationsGridQuery(
    $companyId: ID!
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
  ) {
    Me {
      Company(id: $companyId) {
        data: FireLoads(
          options: {
            startRow: $startRow
            endRow: $endRow
            sorting: $sortModel
            filters: $filters
          }
        ) {
          id
          floor
          date
          status
          description
          houseEntrance {
            id
            tableId
            address {
              street
              number
              zip
              city
            }
            coordinates {
              lat
              lng
            }
            countUsageUnits
          }
        }
      }
    }
  }
`
