import styled from 'styled-components/macro'
import { helperStyles, portalTheme, SimplePullDown } from 'pyrexx-react-library'
import { Container } from 'react-grid-system'

export const StyledSimplePullDown = styled(SimplePullDown)`
  border-bottom: 1px solid ${portalTheme.color.basicColorWhite};
`

export const StyledList = styled(Container)`
  li {
    margin-top: 20px;
    text-align: center;
  }
  &:last-child {
    margin-bottom: 20px;
  }
  ${helperStyles.unstyledList}
`
