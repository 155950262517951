import { useCallback, useContext } from 'react'
import {
  columnCheckbox,
  withFilterParams,
} from '../../../components/AgGrid/columnDefs'
import {
  dateTimeValueGetter,
  operationsStatusValueGetter,
} from '../valueGetter'
import UserContext from '../../../helper/userContext'
import { useTranslation } from 'react-i18next'
import { Routes } from '../../../configs/RouteConfig'
import { OperationsStatusRenderer } from '../renderer'
import PropertyUnitIdRenderer from '../renderer/PropertyUnitIdRenderer'
import SpaceHolderRendererHelper from '../../../components/AgGrid/RendererHelper/SpaceHolderRendererHelper'
import { DateRenderer } from '../../../components/AgGrid/renderers'
import { CountOperationsQuery, OperationsGridQuery } from '../queries'

export const useOpenOperationsGrid = () => {
  const { currentLanguage, redirectRoute } = useContext(UserContext)
  const { t } = useTranslation()

  const getParams = useCallback(
    (currentService) => {
      const valueGetter = (params) => {
        const field = params.column.getColDef().field
        const colId = params.column.getColDef().colId
        const search = colId || field
        switch (search) {
          case 'status':
            return t(operationsStatusValueGetter(params))
          case 'date':
            return dateTimeValueGetter({
              value: params?.value ? params?.value : '',
              currentLanguage: currentLanguage,
            })
          default:
            return params.value
        }
      }
      const OpenOperationsColumnDefs = [
        columnCheckbox,
        {
          headerName: t('STATUS'),
          field: 'status',
          cellRenderer: OperationsStatusRenderer,
          width: 70,
          sortable: false, // TODO enable sorting if possible
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
          filter: false, // TODO add status filter
          ...withFilterParams,
        },
        {
          headerName: t('PYREXX PROPERTY ID'),
          field: 'houseEntrance.tableId',
          width: 70,
          cellRenderer: PropertyUnitIdRenderer,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('STREET'),
          field: 'houseEntrance.address.street',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('STREET NR.'),
          field: 'houseEntrance.address.number',
          width: 70,
          sortable: true,
          ...withFilterParams,
          type: 'rightAligned',
        },
        {
          headerName: t('FLOOR'),
          field: 'floor',
          width: 70,
          sortable: true,
          ...withFilterParams,
        },
        {
          headerName: t('CUSTOMER ALLOCATION NUMBER (ABBREVATION)'),
          width: 70,
          sortable: false, // TODO REMOVE TBA
          filter: false,
          cellRenderer: SpaceHolderRendererHelper,
          cellRendererParams: { spaceHolderValue: 'TBA' },
        },
        {
          headerName: t('CREATED'),
          field: 'date',
          width: 100,
          cellRenderer: DateRenderer,
          cellRendererParams: {
            formatStr: 'Pp',
          },
          filter: 'agDateColumnFilter',
          ...withFilterParams,
          sort: 'desc',
        },
        {
          headerName: t('TYPE OF FIRE LOAD'),
          field: 'description',
          width: 100,
          ...withFilterParams,
          sort: 'desc',
        },
      ]
      const OpenOperationsCustomFilter = [
        {
          name: 'status',
          filterType: 'text',
          operator: 'AND',
          conditions: [
            {
              value: 'OPEN',
              type: 'equals',
            },
          ],
        },
      ]
      return {
        query: OperationsGridQuery,
        columnDefs: OpenOperationsColumnDefs,
        customFilters: OpenOperationsCustomFilter,
        countQuery: CountOperationsQuery,
        extractor: (response) => response.Me?.Company?.data,
        customGridOptions: {
          getContextMenuItems: (params) => {
            return [
              {
                // custom item
                name: t('GO TO PROPERTY {TABLEID}', {
                  TABLEID: params?.node?.data?.houseEntrance.tableId,
                }),
                action: function () {
                  redirectRoute(
                    Routes.propertyUnit +
                      '/' +
                      params?.node?.data?.houseEntrance.tableId
                  )
                },
              },
              'copy',
            ]
          },
          components: {},
          processCellForClipboard: valueGetter,
          defaultCsvExportParams: {
            processCellCallback: valueGetter,
          },
        },
        customVariables: {},
      }
    },
    [currentLanguage, redirectRoute, t]
  )
  return { getParams }
}
