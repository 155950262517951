import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query AppointmentHistoryGridQuery(
    $startRow: Int
    $endRow: Int
    $sortModel: [Sort]
    $filters: [Filter]
    $companyId: ID!
    $usageUnitId: ID!
  ) {
    Me {
      id
      Company(id: $companyId) {
        UsageUnit(id: $usageUnitId) {
          data: appointments(
            options: {
              startRow: $startRow
              endRow: $endRow
              sorting: $sortModel
              filters: $filters
            }
          ) {
            id
            tableId
            type
            date
            timeframe
            accessReason
            status
            initiator
            serviceReason {
              id
              name
            }
            executionDate
            tenant {
              id
              firstname
              lastname
            }
            contract {
              id
              serviceCategory {
                id
                tableId
                name
              }
            }
          }
        }
      }
    }
  }
`
