/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AttachmentUploadStatusType = "FILESYSTEM_ERROR" | "INVALID_ENTITY" | "SUCCESS" | "UNKNOWN_ERROR" | "%future added value";
export type UploadAttachmentsMutationVariables = {|
  companyId: string,
  letterId: string,
  files: $ReadOnlyArray<any>,
|};
export type UploadAttachmentsMutationResponse = {|
  +Me: ?{|
    +Company: ?{|
      +businessPost: ?{|
        +uploadLetterAttachments: ?$ReadOnlyArray<?{|
          +filename: string,
          +status: AttachmentUploadStatusType,
        |}>
      |}
    |}
  |}
|};
export type UploadAttachmentsMutation = {|
  variables: UploadAttachmentsMutationVariables,
  response: UploadAttachmentsMutationResponse,
|};
*/


/*
mutation UploadAttachmentsMutation(
  $companyId: ID!
  $letterId: ID!
  $files: [Upload!]!
) {
  Me {
    Company(id: $companyId) {
      businessPost {
        uploadLetterAttachments(letterId: $letterId, files: $files) {
          filename
          status
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "files"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "letterId"
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "MeMutations",
    "kind": "LinkedField",
    "name": "Me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "companyId"
          }
        ],
        "concreteType": "CompanyMutations",
        "kind": "LinkedField",
        "name": "Company",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BusinessPostMutation",
            "kind": "LinkedField",
            "name": "businessPost",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "files",
                    "variableName": "files"
                  },
                  {
                    "kind": "Variable",
                    "name": "letterId",
                    "variableName": "letterId"
                  }
                ],
                "concreteType": "LetterAttachmentUploadStatus",
                "kind": "LinkedField",
                "name": "uploadLetterAttachments",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "filename",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadAttachmentsMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UploadAttachmentsMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "4c5850eac1df6580310077be4d7c85f8",
    "id": null,
    "metadata": {},
    "name": "UploadAttachmentsMutation",
    "operationKind": "mutation",
    "text": "mutation UploadAttachmentsMutation(\n  $companyId: ID!\n  $letterId: ID!\n  $files: [Upload!]!\n) {\n  Me {\n    Company(id: $companyId) {\n      businessPost {\n        uploadLetterAttachments(letterId: $letterId, files: $files) {\n          filename\n          status\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c08f749f6afcdfa1311410acfcc93425';

module.exports = node;
