import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'

import { Icon, portalTheme } from 'pyrexx-react-library'
import DropdownWithLabel from '../DropdownWithLabel'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Description = styled.h3`
  margin: 0;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: ${portalTheme.font.size.bodyRegular};
  font-family: ${portalTheme.font.family.quicksand};

  padding: 8px 16px;
  color: #000;

  min-width: 260px;

  border-bottom: 2px solid #000;
  ${(props) =>
    props.isDropdownOpen &&
    css`
      border-bottom-color: ${portalTheme.color.linkColorPrimary};
    `}

  ${(props) =>
    props.onlyClick &&
    css`
      border: none;
      border-radius: 10px;
      background-color: #ffffff;
    `}
`

const Dropdown = styled.ul`
  display: flex;
  flex-flow: column wrap;

  margin: 0;
  padding: 0;

  ${(props) =>
    props.onlyClick
      ? css`
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: #ffffff;
        `
      : css`
          background-color: #fff;
        `}

  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  min-width: 260px;
`

const DropdownItem = styled.li`
  list-style-type: none;
  text-align: left;

  cursor: pointer;

  font-size: ${portalTheme.font.size.bodyRegular};
  font-family: ${portalTheme.font.family.quicksand};

  padding: 16px;

  &:hover,
  &:focus {
    background-color: #edf9fe;
  }

  ${(props) =>
    props.onlyClick &&
    css`
      color: #000;
    `};

  ${(props) =>
    props.isSelected &&
    css`
      opacity: 0.2;
      color: #000;
      cursor: default;
    `}

  ${(props) =>
    props.isDisabled &&
    css`
      color: ${portalTheme.color.disabledColor};
      cursor: default;

      &:hover,
      &:focus {
        background-color: #fff;
      }
    `}
`

// setIsDropdownOpen comes from DropdownWithLabel which passes props to its children
const DropdownWithProps = ({
  setIsDropdownOpen = () => {},
  ...dropdownProps
}) => {
  const {
    dropdownItems = [],
    selected = {},
    setSelected = () => {},
    onlyClick,
  } = dropdownProps

  return (
    <Dropdown onlyClick={onlyClick} data-arrow-dropdown-dropdown=''>
      {dropdownItems.map((item) => {
        return (
          <DropdownItem
            onlyClick={onlyClick}
            isDisabled={item?.disabled}
            key={item.id}
            isSelected={item.id === selected.id}
            onClick={() => {
              if (item.onClick) {
                const clickEvent = item.onClick
                clickEvent()
              } else {
                if (!item?.disabled) {
                  setSelected(item)
                  setIsDropdownOpen(false)
                }
              }
            }}
          >
            {item.label}
          </DropdownItem>
        )
      })}
    </Dropdown>
  )
}

const ArrowDropdown = (props) => {
  const {
    label = '',
    dropdownItems = [],
    selected = {},
    setSelected = () => {},
    onlyClick = false,
    description = '',
    className,
    openFromStart = false,
    ...dropdownProps
  } = props

  return (
    <Wrapper className={className}>
      {description && <Description>{description}</Description>}

      <DropdownWithLabel
        onlyClick={onlyClick}
        openFromStart={openFromStart}
        passPropsToChildren
        renderLabel={(isDropdownOpen) => (
          <Label
            onlyClick={onlyClick}
            isDropdownOpen={isDropdownOpen}
            data-arrow-dropdown-label=''
          >
            {label}

            {isDropdownOpen ? (
              <Icon
                icon='arrow-up'
                size='30px'
                color={portalTheme.color.linkColorPrimary}
              />
            ) : (
              <Icon
                icon='arrow-down'
                size='30px'
                color={portalTheme.color.linkColorPrimary}
              />
            )}
          </Label>
        )}
      >
        <DropdownWithProps
          dropdownItems={dropdownItems}
          selected={selected}
          setSelected={setSelected}
          onlyClick={onlyClick}
          {...dropdownProps}
        />
      </DropdownWithLabel>
    </Wrapper>
  )
}

ArrowDropdown.propTypes = {
  label: PropTypes.any,
  dropdownItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any,
      id: PropTypes.any,
      disabled: PropTypes.bool,
      selected: PropTypes.bool,
    })
  ),
  selected: PropTypes.any,
  setSelected: PropTypes.func,
  onlyClick: PropTypes.bool,
  description: PropTypes.string,
  openFromStart: PropTypes.bool,
}

export default ArrowDropdown
