import React, { forwardRef, useImperativeHandle, useState } from 'react'
import GridEnumFilter from '../../../../components/GridEnumFilter'
import useUpdateEffect from '../../../../hooks/useUpdateEffect'
import { lettersStatusEnumList } from '../../../../components/AgGrid/enumLists'

const map = {
  DRAFT: ['DRAFT'],
  PENDING: ['PENDING'],
  SENT: ['SENT'],
}

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  return (
    <GridEnumFilter
      map={map}
      translateLabel={(value, t) => {
        return t(lettersStatusEnumList(value))
      }}
      setFilterModel={setFilterModel}
    />
  )
})
