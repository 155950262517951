import React from 'react'
import SelectedFile from '../SelectedFile/SelectedFile'
import styled from 'styled-components'
import { portalTheme } from 'pyrexx-react-library'

const StyledFileList = styled.div`
  border: 1px solid;
  border-color: ${portalTheme.color.basicColorLightGrey2};
  background-color: ${portalTheme.color.basicColorDarkGrey1};
`

const FileList = (props) => {
  const { files, removeFile, moveListItem } = props

  return (
    <StyledFileList>
      {files.map((file, i) => (
        <SelectedFile
          key={file.path + '-' + file.lastModified + '-' + file.size}
          file={file}
          index={i}
          removeFile={removeFile}
          moveListItem={moveListItem}
        />
      ))}
    </StyledFileList>
  )
}

export default FileList
