import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'react-grid-system'
import {
  ButtonIcon,
  ButtonSecondary,
  portalTheme,
  SimpleNote,
  StyledInput,
} from 'pyrexx-react-library'
import styled from 'styled-components/macro'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import UserContext from '../../../../../../../../helper/userContext'

const StyledFilterItemEdit = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`

const FilterItemEdit = (props) => {
  const {
    setOpenFromStart,
    filterItem,
    setDeleteActive,
    setEditActive,
    setConfirmEditActive,
    currentGrid,
    setMessageBoxVisible,
  } = props
  const { t } = useTranslation()
  const { getValidUrlWithQuery } = useContext(UserContext)

  const schema = Yup.object({
    name: Yup.string()
      .min(3, t('NAME MUST BE AT LEAST 3 CHARACTERS'))
      .required(t('NAME MUST BE AT LEAST 3 CHARACTERS')),
  })

  const formik = useFormik({
    initialValues: {
      name: filterItem.filter,
    },
    validationSchema: schema,
    onSubmit: (values, formikb) => {
      setOpenFromStart(true)
      setEditActive(false)
      setConfirmEditActive({ id: filterItem.tableId, name: values.name })
    },
  })

  return (
    <StyledFilterItemEdit>
      <Row>
        <Col>
          <SimpleNote
            text={t('EDIT FILTER')}
            noteStatus='warning'
            style={{ fontSize: '12px' }}
          />
        </Col>
        <Col xs='content'>
          <Row gutterWidth={5}>
            <Col>
              <ButtonIcon
                disabled
                icon='edit'
                size='20px'
                style={{ color: portalTheme.color.disabledColor }}
                onClick={() => {
                  setOpenFromStart(false)
                  setEditActive(false)
                }}
              />
            </Col>
            <Col>
              <ButtonIcon
                icon='share'
                size='20px'
                style={{ color: '#000000' }}
                onClick={() => {
                  const query =
                    '?gridId=' +
                    currentGrid.id +
                    '&filterId=' +
                    filterItem.tableId
                  setMessageBoxVisible(true)
                  navigator.clipboard.writeText(getValidUrlWithQuery(query))
                }}
              />
            </Col>
            <Col>
              <ButtonIcon
                icon='close'
                size='20px'
                style={{ color: portalTheme.color.errorColor }}
                onClick={() => {
                  setOpenFromStart(true)
                  setEditActive(false)
                  setDeleteActive({
                    id: filterItem.tableId,
                    name: filterItem.filter,
                  })
                  console.log('delete')
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row nogutter>
        <Col>
          <StyledInput
            type='input'
            name='name'
            id='name'
            onChange={formik.handleChange}
            value={formik.values.name}
            label={false}
            feedbackMessage={formik.touched.name && formik.errors.name}
            feedbackStyle='invalid'
            setFieldValue={formik.setFieldValue}
          />
        </Col>
        <Col xs='content'>
          <ButtonSecondary
            onClick={() => {
              formik.handleSubmit()
            }}
            style={{
              margin: '0px',
              height: '2rem',
              paddingLeft: '1rem',
              paddingRight: '1rem',
            }}
          >
            {t('SAVE')}
          </ButtonSecondary>
        </Col>
      </Row>
    </StyledFilterItemEdit>
  )
}
export default FilterItemEdit
