import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useUserContext } from '../../helper/userContext'
import { Routes } from '../../configs/RouteConfig'

const Logout = () => {
  const { logout, currentLanguage } = useUserContext()
  const history = useHistory()

  useEffect(() => {
    logout()
    history.push('/' + currentLanguage + Routes.login)
  }, [currentLanguage, history, logout])

  return null
}

export default Logout
