import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Skeleton from '../../components/Skeleton'
import AccessReason from './renderers/AccessReason'

import formatDateLocale from '../../helper/formatDateLocale'
import UserContext from '../../helper/userContext'
import { Col, Container, DataRow, TitleRow } from './styles'

const PreviousAppointmentRow = ({ appointment = {}, loading = false }) => {
  const { currentLanguage } = useContext(UserContext)

  const {
    tableId = '',
    date = '',
    timeframe = '',
    type = '',
    accessReason = '',
    executionDate = '',
  } = appointment

  const fDate = date ? formatDateLocale(currentLanguage, date, 'E P') : ''
  const fExecutionDate = executionDate
    ? formatDateLocale(currentLanguage, executionDate, 'E P p')
    : ''

  return (
    <DataRow>
      <Col>{loading ? <Skeleton tf /> : tableId}</Col>
      <Col>{loading ? <Skeleton tf /> : fDate}</Col>
      <Col>{loading ? <Skeleton tf /> : timeframe}</Col>
      <Col>{loading ? <Skeleton tf /> : type}</Col>
      <Col>
        {loading ? (
          <Skeleton tf />
        ) : (
          <AccessReason accessReason={accessReason} />
        )}
      </Col>
      <Col>{loading ? <Skeleton tf /> : fExecutionDate}</Col>
    </DataRow>
  )
}

const PreviousAppointments = (props) => {
  const { previousAppointments = [], loading = false } = props
  const { t } = useTranslation()

  return (
    <Container>
      {previousAppointments.length > 0 || loading ? (
        <>
          <TitleRow>
            <Col>{loading ? <Skeleton tf /> : t('PYREXX SERVICE NUMBER')}</Col>
            <Col>{loading ? <Skeleton tf /> : t('DATE')}</Col>
            <Col>{loading ? <Skeleton tf /> : t('TIMEFRAME')}</Col>
            <Col>{loading ? <Skeleton tf /> : t('TYPE')}</Col>
            <Col>{loading ? <Skeleton tf /> : t('ACCESS REASON')}</Col>
            <Col>{loading ? <Skeleton tf /> : t('EXECUTION DATE')}</Col>
          </TitleRow>

          {loading ? (
            <PreviousAppointmentRow loading={loading} />
          ) : (
            previousAppointments.map((appointment) => (
              <PreviousAppointmentRow
                key={appointment?.tableId}
                appointment={appointment}
              />
            ))
          )}
        </>
      ) : (
        <DataRow>
          <Col>{t('NO PREVIOUS APPOINTMENTS AVAILABLE')}</Col>
        </DataRow>
      )}
    </Container>
  )
}

PreviousAppointments.propTypes = {
  previousAppointments: PropTypes.arrayOf(
    PropTypes.shape({
      tableId: PropTypes.any,
      date: PropTypes.string,
      timeframe: PropTypes.string,
      type: PropTypes.string,
      accessReason: PropTypes.string,
      executionDate: PropTypes.string,
    })
  ),
  loading: PropTypes.bool,
}

export default PreviousAppointments
