/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type Language = "de" | "en" | "es" | "%future added value";
export type PrivacyQueryVariables = {|
  lang: Language
|};
export type PrivacyQueryResponse = {|
  +DSS: ?{|
    +id: string,
    +coId: number,
    +date: any,
    +title: string,
    +paragraphs: ?$ReadOnlyArray<?{|
      +number: number,
      +name: string,
      +sections: ?$ReadOnlyArray<?{|
        +name: string,
        +number: number,
        +text: string,
      |}>,
    |}>,
  |}
|};
export type PrivacyQuery = {|
  variables: PrivacyQueryVariables,
  response: PrivacyQueryResponse,
|};
*/


/*
query PrivacyQuery(
  $lang: Language!
) {
  DSS: Document(type: DSS, lang: $lang) {
    id
    coId
    date
    title
    paragraphs {
      number
      name
      sections {
        name
        number
        text
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lang"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": "DSS",
    "args": [
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      },
      {
        "kind": "Literal",
        "name": "type",
        "value": "DSS"
      }
    ],
    "concreteType": "Document",
    "kind": "LinkedField",
    "name": "Document",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "coId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DocumentParagraph",
        "kind": "LinkedField",
        "name": "paragraphs",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "DocumentSection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PrivacyQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PrivacyQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "97ea8443692658d56e24956a7a970d15",
    "id": null,
    "metadata": {},
    "name": "PrivacyQuery",
    "operationKind": "query",
    "text": "query PrivacyQuery(\n  $lang: Language!\n) {\n  DSS: Document(type: DSS, lang: $lang) {\n    id\n    coId\n    date\n    title\n    paragraphs {\n      number\n      name\n      sections {\n        name\n        number\n        text\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '79d082a1e7ed8015bfd72a7cd7a4015b';

module.exports = node;
