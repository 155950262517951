import graphql from 'babel-plugin-relay/macro'

export default graphql`
  query AppointmentsOverviewQuery(
    $companyId: ID!
    $dateFilter: [Condition]!
    $serviceFilter: [Condition]!
    $contractFilter: [Condition]!
  ) {
    Me {
      Company(id: $companyId) {
        regularDone: countAppointments(
          options: {
            filters: [
              {
                name: "type"
                filterType: text
                operator: AND
                conditions: [{ value: ["T1", "T2"], type: equals }]
              }
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  { value: ["DONE", "CLOSED", "CHECKED"], type: equals }
                ]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        serviceDone: countAppointments(
          options: {
            filters: [
              {
                name: "type"
                filterType: text
                operator: AND
                conditions: [{ value: ["TI", "TS"], type: equals }]
              }
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  { value: ["DONE", "CLOSED", "CHECKED"], type: equals }
                ]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        specialDone: countAppointments(
          options: {
            filters: [
              {
                name: "type"
                filterType: text
                operator: AND
                conditions: [{ value: ["TX", "TA"], type: equals }]
              }
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  { value: ["DONE", "CLOSED", "CHECKED"], type: equals }
                ]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        regularPending: countAppointments(
          options: {
            filters: [
              {
                name: "type"
                filterType: text
                operator: AND
                conditions: [{ value: ["T1", "T2"], type: equals }]
              }
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  { value: ["OPEN", "DIAGNOSE_PENDING"], type: equals }
                ]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        servicePending: countAppointments(
          options: {
            filters: [
              {
                name: "type"
                filterType: text
                operator: AND
                conditions: [{ value: ["TI", "TS"], type: equals }]
              }
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  { value: ["OPEN", "DIAGNOSE_PENDING"], type: equals }
                ]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        specialPending: countAppointments(
          options: {
            filters: [
              {
                name: "type"
                filterType: text
                operator: AND
                conditions: [{ value: ["TX", "TA"], type: equals }]
              }
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [
                  { value: ["OPEN", "DIAGNOSE_PENDING"], type: equals }
                ]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        regularNotDone: countAppointments(
          options: {
            filters: [
              {
                name: "type"
                filterType: text
                operator: AND
                conditions: [{ value: ["T1", "T2"], type: equals }]
              }
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [{ value: ["DEACTIVATED"], type: equals }]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        serviceNotDone: countAppointments(
          options: {
            filters: [
              {
                name: "type"
                filterType: text
                operator: AND
                conditions: [{ value: ["TI", "TS"], type: equals }]
              }
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [{ value: ["DEACTIVATED"], type: equals }]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        specialNotDone: countAppointments(
          options: {
            filters: [
              {
                name: "type"
                filterType: text
                operator: AND
                conditions: [{ value: ["TX", "TA"], type: equals }]
              }
              {
                name: "status"
                filterType: text
                operator: AND
                conditions: [{ value: ["DEACTIVATED"], type: equals }]
              }
              {
                name: "date"
                filterType: date
                operator: AND
                conditions: $dateFilter
              }
              {
                name: "contract.serviceCategory.tableId"
                filterType: text
                operator: AND
                conditions: $serviceFilter
              }
              {
                name: "contract"
                filterType: number
                operator: AND
                conditions: $contractFilter
              }
            ]
          }
        )
        Contracts(options: { endRow: -1 }) {
          id
          tableId
          name
          type
          billingPeriods(options: { endRow: -1 }) {
            id
            tableId
            from
            to
          }
          serviceCategory {
            id
            tableId
            name
          }
        }
      }
    }
  }
`
