import React from 'react'
import { useTranslation } from 'react-i18next'
import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'
import { AppointmentStatusMap } from '../../../constants/colorMap'
import { appointmentStatusEnumList } from '../../../components/AgGrid/enumLists'

export default (props) => {
  const { status } = props
  const { t } = useTranslation()

  return (
    <StatusLightWithLabel
      status={AppointmentStatusMap[status]}
      label={t(appointmentStatusEnumList(status))}
    />
  )
}
