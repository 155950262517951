import { CSSTransitionGroup } from 'react-transition-group'
import styled from 'styled-components/macro'
import { helperStyles, portalTheme } from 'pyrexx-react-library'

export const StyledMountingAnimation = styled(CSSTransitionGroup)`
  ${({ transitionName }) => `.${transitionName}-enter`} {
    opacity: 0.01;
  }

  ${({ transitionName }) => `.${transitionName}-enter-active`} {
    opacity: 1;
    transition: opacity
      ${({ transitionEnterTimeout }) => transitionEnterTimeout}ms ease-in;
  }

  ${({ transitionName }) => `.${transitionName}-leave`} {
    opacity: 1;
  }

  ${({ transitionName }) => `.${transitionName}-leave-active`} {
    opacity: 0.01;
    transition: opacity
      ${({ transitionLeaveTimeout }) => transitionLeaveTimeout}ms ease-in;
  }
`

export const LinkButtonStyled = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  padding: 0px;
  align-items: center;
  color: ${portalTheme.color.linkColorPrimary};
  text-decoration: none;
  font-family: ${portalTheme.font.family.quicksand};
  ${helperStyles.buttonRemoveBrowserStylesCss}
`

export const CenterRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
