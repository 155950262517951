export const InvoiceStatusMap = {
  PAID: 'success',
  OPEN: 'warning',
  REMINDED: 'error',
  CANCELLED: 'disabled',
}

export const TenantStatusMap = {
  STATUS_NOT_YET_MOVED_IN: 'warning',
  STATUS_ACTIVE: 'success',
  STATUS_MOVED_OUT: 'disabled',
}

export const AppointmentStatusMap = {
  DONE: 'success',
  CLOSED: 'success',
  CHECKED: 'success',
  OPEN: 'warning',
  DIAGNOSE_PENDING: 'warning',
  DEACTIVATED: 'error',
}

export const OperationsStatusMap = {
  SEEN: 'success',
  OPEN: 'error',
}

export const SyncStatusTypeMap = {
  NONE: 'empty',
  IN_PROGRESS: 'warning',
  DONE: 'success',
  DEACTIVATED: 'disabled',
  UPCOMING: 'warning',
  DATA_INCORRECT_REQUESTED: 'error',
  IN_CLARIFYING: 'warning',
  IN_PREPARATION: 'warning',
}

export const AppointmentAccessReasonMap = {
  ACCESS: 'success',
  NO_ACCESS_AWAY: 'empty',
  NO_ACCESS_DENIED: 'empty',
  NO_ACCESS_VACANT_NO_KEY: 'empty',
  NO_ACCESS_RENOVATION: 'empty',
  NO_ACCESS_RAMSHACKLE_SURFACE: 'empty',
  NO_ACCESS_HYGIENICALLY_INFEASIBLE: 'empty',
  NO_ACCESS_DENIED_NEED_RESPITE: 'empty',
  NO_ACCESS_INFEASIBLE: 'empty',
  NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE: 'empty',
  NO_ACCESS_PARTLY_DONE_SOME_LOCATIONS_LOCKED: 'empty',
  NO_ACCESS_NO_ADULT_ON_SITE: 'empty',
  NO_ACCESS_VACANT_KEY_DOES_NOT_FIT: 'empty',
  NO_ACCESS_JUNCTION_BOX_DESTROYED: 'empty',
  NO_ACCESS_JUNCTION_BOX_OPENED: 'empty',
  NO_ACCESS_JUNCTION_BOX_BROKEN: 'empty',
  NO_ACCESS_JUNCTION_BOX_MISSING: 'empty',
  NO_ACCESS_JUNCTION_BOX_GRAFFITI_FOUND: 'empty',
  NO_ACCESS_JUNCTION_BOX_INACCESSIBLE: 'empty',
}

export const UsageUnitLockPeriodStatusMap = {
  ACTIVE: 'disabled',
  DEACTIVATED: 'success',
  PENDING: 'disabled',
}

// TODO: review and define correctly
export const UsageUnitStatusMap = {
  ACTIVE: 'success',
  STATUS_ACTIVE: 'success',
  SERVICE_RENDERED: 'success',
  DEACTIVATED: 'disabled',
  PENDING: 'disabled',
  SERVICE_NOT_RENDERED: 'disabled',
  PSEUDOTRASH: 'disabled',
  NOT_YET_MOVED_IN: 'disabled',
  STATUS_NOT_YET_MOVED_IN: 'disabled',
  MOVED_OUT: 'disabled',
  STATUS_MOVED_OUT: 'disabled',
  NONE: 'disabled',
}
