/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppointmentAccessType = "ACCESS" | "NONE" | "NO_ACCESS_22" | "NO_ACCESS_23" | "NO_ACCESS_24" | "NO_ACCESS_25" | "NO_ACCESS_26" | "NO_ACCESS_27" | "NO_ACCESS_28" | "NO_ACCESS_29" | "NO_ACCESS_30" | "NO_ACCESS_31" | "NO_ACCESS_32" | "NO_ACCESS_33" | "NO_ACCESS_34" | "NO_ACCESS_35" | "NO_ACCESS_36" | "NO_ACCESS_37" | "NO_ACCESS_38" | "NO_ACCESS_39" | "NO_ACCESS_40" | "NO_ACCESS_AWAY" | "NO_ACCESS_DENIED" | "NO_ACCESS_DENIED_NEED_RESPITE" | "NO_ACCESS_FOREIGN_SD_INSTALLED_NO_MAINTENANCE" | "NO_ACCESS_HYGIENICALLY_INFEASIBLE" | "NO_ACCESS_INFEASIBLE" | "NO_ACCESS_JUNCTION_BOX_BROKEN" | "NO_ACCESS_JUNCTION_BOX_DESTROYED" | "NO_ACCESS_JUNCTION_BOX_GRAFFITI_FOUND" | "NO_ACCESS_JUNCTION_BOX_INACCESSIBLE" | "NO_ACCESS_JUNCTION_BOX_MISSING" | "NO_ACCESS_JUNCTION_BOX_OPENED" | "NO_ACCESS_NO_ADULT_ON_SITE" | "NO_ACCESS_PARTLY_DONE_SOME_LOCATIONS_LOCKED" | "NO_ACCESS_RAMSHACKLE_SURFACE" | "NO_ACCESS_RENOVATION" | "NO_ACCESS_TENANT_OWNS_RM" | "NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE" | "NO_ACCESS_VACANT_KEY_DOES_NOT_FIT" | "NO_ACCESS_VACANT_NO_KEY" | "%future added value";
export type AppointmentStatusType = "CANCELLED" | "CHECKED" | "CLOSED" | "DEACTIVATED" | "DIAGNOSE_PENDING" | "DONE" | "NONE" | "OPEN" | "PLANNED" | "TEMP_DEFERRED" | "UNTERMINATED" | "%future added value";
export type AppointmentTypeType = "P" | "REMOTE_DATA_COLLECTION" | "T1" | "T2" | "TA" | "TI" | "TS" | "TX" | "%future added value";
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type RowGroup = {|
  id?: ?string,
  displayName?: ?string,
  field?: ?string,
|};
export type AppointmentsGridQueryVariables = {|
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
  groupKeys?: ?$ReadOnlyArray<?string>,
  rowGroupCols?: ?$ReadOnlyArray<?RowGroup>,
  companyId: string,
|};
export type AppointmentsGridQueryResponse = {|
  +Me: ?{|
    +id: string,
    +Company: ?{|
      +data: ?$ReadOnlyArray<?{|
        +id: ?string,
        +tableId: ?number,
        +type: ?AppointmentTypeType,
        +date: ?any,
        +timeframe: ?string,
        +accessReason: ?AppointmentAccessType,
        +status: ?AppointmentStatusType,
        +referenceNumber: ?string,
        +billingPartition: ?{|
          +id: string,
          +name: string,
        |},
        +contract: ?{|
          +tableId: number,
          +serviceCategory: ?{|
            +id: string,
            +tableId: number,
            +name: string,
          |},
        |},
        +usageUnit: ?{|
          +id: string,
          +tableId: number,
          +referenceNumber: string,
          +floor: number,
        |},
        +houseEntrance: ?{|
          +id: string,
          +tableId: number,
          +number: string,
        |},
        +address: ?{|
          +street: string,
          +zip: string,
          +city: string,
          +number: string,
        |},
        +bulletin: ?{|
          +id: string,
          +tableId: number,
        |},
        +count: ?number,
        +tenant: ?{|
          +id: ?string,
          +firstname: string,
          +lastname: string,
        |},
      |}>
    |},
  |}
|};
export type AppointmentsGridQuery = {|
  variables: AppointmentsGridQueryVariables,
  response: AppointmentsGridQueryResponse,
|};
*/


/*
query AppointmentsGridQuery(
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
  $groupKeys: [String]
  $rowGroupCols: [RowGroup]
  $companyId: ID!
) {
  Me {
    id
    Company(id: $companyId) {
      data: Appointments(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters, groupKeys: $groupKeys, rowGroupCols: $rowGroupCols}) {
        id
        tableId
        type
        date
        timeframe
        accessReason
        status
        referenceNumber
        billingPartition {
          id
          name
        }
        contract {
          tableId
          serviceCategory {
            id
            tableId
            name
          }
          id
        }
        usageUnit {
          id
          tableId
          referenceNumber
          floor
        }
        houseEntrance {
          id
          tableId
          number
        }
        address {
          street
          zip
          city
          number
        }
        bulletin {
          id
          tableId
        }
        count
        tenant {
          id
          firstname
          lastname
        }
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "groupKeys"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rowGroupCols"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v9 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "endRow",
        "variableName": "endRow"
      },
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "groupKeys",
        "variableName": "groupKeys"
      },
      {
        "kind": "Variable",
        "name": "rowGroupCols",
        "variableName": "rowGroupCols"
      },
      {
        "kind": "Variable",
        "name": "sorting",
        "variableName": "sortModel"
      },
      {
        "kind": "Variable",
        "name": "startRow",
        "variableName": "startRow"
      }
    ],
    "kind": "ObjectValue",
    "name": "options"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeframe",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessReason",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceNumber",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "BillingPartition",
  "kind": "LinkedField",
  "name": "billingPartition",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v17/*: any*/)
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "ServiceCategory",
  "kind": "LinkedField",
  "name": "serviceCategory",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v10/*: any*/),
    (v17/*: any*/)
  ],
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "UsageUnit",
  "kind": "LinkedField",
  "name": "usageUnit",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v10/*: any*/),
    (v16/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "floor",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "HouseEntrance",
  "kind": "LinkedField",
  "name": "houseEntrance",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v10/*: any*/),
    (v21/*: any*/)
  ],
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zip",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    (v21/*: any*/)
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "Bulletin",
  "kind": "LinkedField",
  "name": "bulletin",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppointmentsGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v9/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointments",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Contract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v19/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AppointmentsGridQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v9/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointments",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Contract",
                    "kind": "LinkedField",
                    "name": "contract",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v19/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v20/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f369de7e6c7163d4a274fd8c6f6a705a",
    "id": null,
    "metadata": {},
    "name": "AppointmentsGridQuery",
    "operationKind": "query",
    "text": "query AppointmentsGridQuery(\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n  $groupKeys: [String]\n  $rowGroupCols: [RowGroup]\n  $companyId: ID!\n) {\n  Me {\n    id\n    Company(id: $companyId) {\n      data: Appointments(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters, groupKeys: $groupKeys, rowGroupCols: $rowGroupCols}) {\n        id\n        tableId\n        type\n        date\n        timeframe\n        accessReason\n        status\n        referenceNumber\n        billingPartition {\n          id\n          name\n        }\n        contract {\n          tableId\n          serviceCategory {\n            id\n            tableId\n            name\n          }\n          id\n        }\n        usageUnit {\n          id\n          tableId\n          referenceNumber\n          floor\n        }\n        houseEntrance {\n          id\n          tableId\n          number\n        }\n        address {\n          street\n          zip\n          city\n          number\n        }\n        bulletin {\n          id\n          tableId\n        }\n        count\n        tenant {\n          id\n          firstname\n          lastname\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '71c1896382a77adf9e45b1d15a71e926';

module.exports = node;
