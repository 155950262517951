import { format } from 'date-fns'
import { de, enGB } from 'date-fns/locale'

const locales = {
  en: enGB,
  de: de,
}

const formatDateLocale = (langString, dateInput, formatStr) => {
  if (!dateInput) {
    return ''
  }
  let date
  if (typeof dateInput === 'string' || dateInput instanceof String) {
    date = new Date(dateInput)
  } else {
    date = dateInput
  }

  return format(date, formatStr, {
    locale: locales[langString],
  })
}

export default formatDateLocale
