import React, { forwardRef, useImperativeHandle, useState } from 'react'
import GridEnumFilter from '../../../components/GridEnumFilter'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import { appointmentStatusEnumList } from '../../../components/AgGrid/enumLists'

const map = {
  DONE: ['DONE', 'CLOSED', 'CHECKED'],
  PENDING: ['OPEN', 'DIAGNOSE_PENDING'],
  NOT_EXECUTED: ['DEACTIVATED'],
}

const colorMap = {
  DONE: 'success',
  PENDING: 'warning',
  NOT_EXECUTED: 'error',
}

export default forwardRef((props, ref) => {
  const { filterChangedCallback } = props

  const [filterModel, setFilterModel] = useState({})

  useUpdateEffect(() => {
    filterChangedCallback()
  }, [filterModel])

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return filterModel != null && Object.keys(filterModel).length > 0
      },
      getModel() {
        if (filterModel == null || Object.keys(filterModel).length <= 0) {
          return null
        }

        return filterModel
      },
      setModel(model) {
        setFilterModel(model)
      },
    }
  })

  return (
    <GridEnumFilter
      map={map}
      colorMap={colorMap}
      translateLabel={(value, t) => {
        return t(appointmentStatusEnumList(value))
      }}
      setFilterModel={setFilterModel}
    />
  )
})
