import React, { useCallback, useState } from 'react'
import UserContext from '../../helper/userContext'
import auth from '../../helper/auth'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

import RefreshTokenMutation from '../../mutations/RefreshTokenMutation'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import TransferTokenMutation from '../../mutations/TransferTokenMutation'
import handleLocalStorage from '../../helper/handleLocalStorage'
import { Routes } from '../../configs/RouteConfig'
import getCompanyData from '../../queries/getCompanyData'

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}
const UserProvider = ({ children, props }) => {
  const { i18n } = useTranslation()
  const history = useHistory()
  const matchRoute = useRouteMatch('/:lang/:site/:extra?/:id?')
  const [lastRoute, setLastRoute] = useState(false)

  const currentLanguage = i18n.language.substring(0, 2).toLowerCase()
  const availableLanguages = ['de', 'en']
  const languageMapping = { de: 'de_DE', en: 'en_GB' }
  const lastActionTimer = 600
  const query = useQuery()
  // User is the name of the "data" that gets stored in context
  const [user, setUser] = useState(() => ({
    accessToken: auth.getAccessToken(),
    refreshToken: auth.getRefreshToken(),
    userName: auth.getUserInfo().name,
    userId: auth.getUserInfo().id,
    firstname: auth.getUserInfo().firstname,
    lastname: auth.getUserInfo().lastname,
    salutation: auth.getUserInfo().salutation,
    title: auth.getUserInfo().title,
    role: auth.getUserInfo().role,
    email: auth.getUserInfo().email,
    userPermissions: auth.getUserPermissions(),
    companyName: auth.getCompanyInfo().name,
    companyId: auth.getCompanyInfo().id,
    companyLogo: auth.getCompanyInfo().logo,
    lastAction: Math.floor(Date.now() / 1000) + lastActionTimer,
    autoLogout: false,
  }))

  // Login updates the user data with a name parameter
  const login = (userObject) => {
    setUser(userObject)
  }

  // Logout updates the user data to default
  const logout = (autoLogout = false) => {
    setUser({
      accessToken: false,
      refreshToken: false,
      userName: false,
      userId: false,
      firstname: false,
      lastname: false,
      salutation: false,
      title: false,
      role: '',
      email: null,
      userPermissions: false,
      companyName: false,
      companyId: false,
      companyLogo: false,
      lastAction: Math.floor(Date.now() / 1000) + lastActionTimer,
      autoLogout: autoLogout,
    })
    auth.clearTokens()
  }

  const switchCompany = ({
    companyId = '',
    companyName = '',
    companyLogo = '',
    userPermissions = [],
  }) => {
    const newCompany = {
      companyId,
      companyName,
      companyLogo,
    }

    // Updates state
    setUser((prevState) => ({
      ...prevState,
      ...newCompany,
      userPermissions,
    }))

    // Updates localStorage
    auth.setCompanyInfo(newCompany)
    auth.setUserPermissions(userPermissions)
  }

  const handleLoginRequestData = useCallback(
    async (requestData) => {
      const {
        status = false,
        accessToken = '',
        refreshToken = '',
        Me = {},
      } = requestData.info
      if (status === true) {
        if (accessToken && refreshToken) {
          auth.setToken(accessToken, refreshToken)
        }
        auth.setUserInfo({
          userId: Me?.id,
          userName: Me?.name,
          firstname: Me?.firstname,
          lastname: Me?.lastname,
          salutation: Me?.salutation,
          title: Me?.title,
          role: Me?.role,
          email: Me?.email,
        })
        // auth.setUserPermissions(Me?.can)

        const companies = Me?.Companies || []
        const userHasMoreThanOneCompany = companies.length > 1

        if (userHasMoreThanOneCompany) {
          handleLocalStorage('set', 'multipleCompanies', true)
        } else {
          handleLocalStorage('set', 'multipleCompanies', false)
        }

        const favCompany = handleLocalStorage('get', 'favCompany') || {}
        const sameUser = Me?.id === favCompany?.userId
        let companyInfo = {}

        if (sameUser) {
          const firstCompanyId = companies[0]?.id
          const selectedCompany = favCompany.id || firstCompanyId

          const companyId =
            user?.companyId || companies?.includes(user?.companyId)
              ? user?.companyId
              : selectedCompany

          companyInfo = companies.find((company) => company?.id === companyId)
        } else {
          companyInfo = companies[0]
          if (userHasMoreThanOneCompany) {
            handleLocalStorage('clear', 'companySwitcherViaLogin')
            handleLocalStorage('clear', 'favCompany')
          } else {
            handleLocalStorage('set', 'favCompany', companyInfo)
          }
        }

        // fetch required company data
        const companyData = await getCompanyData(companyInfo?.id, accessToken)
        const { logo = '', permissions = [] } = companyData?.Me?.Company || {}

        auth.setCompanyInfo({
          companyId: companyInfo?.id,
          companyName: companyInfo?.name,
          companyLogo: logo,
        })
        auth.setUserPermissions(permissions)

        const loginObject = {
          accessToken: accessToken || user.accessToken,
          refreshToken: refreshToken || user.refreshToken,
          userName: Me?.name,
          userId: Me?.id,
          firstname: Me?.firstname,
          lastname: Me?.lastname,
          salutation: Me?.salutation,
          title: Me?.title,
          role: Me?.role,
          email: Me?.email,
          userPermissions: permissions,
          companyName: companyInfo?.name,
          companyId: companyInfo?.id,
          companyLogo: logo,
          lastAction: Math.floor(Date.now() / 1000) + lastActionTimer,
        }

        login(loginObject)

        return true
      } else {
        return false
      }
    },
    [user.accessToken, user.companyId, user.refreshToken]
  )

  const updateWithRefreshToken = useCallback(
    (callbackFunction) => {
      if (user.accessToken && user.refreshToken) {
        RefreshTokenMutation(
          user.accessToken,
          user.refreshToken,
          (requestData) => {
            const refreshTokenWasSuccess = handleLoginRequestData(requestData)
            return callbackFunction(refreshTokenWasSuccess)
          },
          // eslint-disable-next-line handle-callback-err
          (err) => {
            logout()
            return callbackFunction(err)
          }
        )
      } else {
        return callbackFunction(false)
      }
    },
    [handleLoginRequestData, user.accessToken, user.refreshToken]
  )

  const updateLastAction = useCallback(() => {
    if (user.lastAction < Math.floor(Date.now() / 1000)) {
      updateWithRefreshToken((isValid) => {
        if (!isValid) {
          logout(true)
        }
      })
    }
  }, [updateWithRefreshToken, user.lastAction])

  // ######################## -->

  const getValidLangAndSite = useCallback(() => {
    let lang = matchRoute?.params?.lang
      ? matchRoute.params.lang
      : currentLanguage
    lang = availableLanguages.includes(lang) ? lang : currentLanguage
    const site = matchRoute?.params?.site ? matchRoute.params.site : 'overview'
    const extra = matchRoute?.params?.extra ? matchRoute.params.extra : ''
    const id = matchRoute?.params?.id ? matchRoute.params.id : ''

    return { lang, site, currentLanguage, extra, id }
  }, [matchRoute, availableLanguages, currentLanguage])

  const getValidQueryString = useCallback(() => {
    const tab = query.get('tab')
    const gridId = query.get('gridId')
    const filterId = query.get('filterId')
    let queryString = '?'
    if (tab) {
      if (queryString !== '?') {
        queryString += '&'
      }
      queryString += 'tab=' + tab
    }

    if (gridId) {
      if (queryString !== '?') {
        queryString += '&'
      }
      queryString += 'gridId=' + gridId
    }

    if (filterId) {
      if (queryString !== '?') {
        queryString += '&'
      }
      queryString += 'filterId=' + filterId
    }

    if (queryString === '?') {
      return ''
    }
    return queryString
  }, [query])

  const getValidPath = useCallback(
    (lang, site, extra = '', id = '', filter = '') => {
      let setPath = '/' + lang + '/' + site
      if (filter !== extra) {
        setPath = extra ? setPath + '/' + extra : setPath
        setPath = id ? setPath + '/' + id : setPath
      }
      return setPath
    },
    []
  )

  const getValidUrlForLang = useCallback(
    (lang) => {
      const params = getValidLangAndSite()
      const setPath = getValidPath(lang, params.site, params.extra)
      return process.env.PUBLIC_URL + setPath
    },
    [getValidLangAndSite, getValidPath]
  )

  const getValidUrlWithQuery = useCallback(
    (query) => {
      const params = getValidLangAndSite()
      const setPath = getValidPath(params.lang, params.site, params.extra)
      return window.location.host + setPath + query
    },
    [getValidLangAndSite, getValidPath]
  )

  const pushSearchQuery = useCallback(
    (search) => {
      const params = getValidLangAndSite()
      const setPath = getValidPath(params.lang, params.site, params.extra)
      history.push({
        pathname: setPath,
        search: search,
        state: { searchChange: true },
      })
    },
    [getValidLangAndSite, getValidPath, history]
  )

  const replaceSearchQuery = useCallback(
    (search) => {
      const params = getValidLangAndSite()
      const setPath = getValidPath(params.lang, params.site, params.extra)
      history.replace({
        pathname: setPath,
        search: search,
        state: { searchChange: true },
      })
    },
    [getValidLangAndSite, getValidPath, history]
  )

  const changeLanguage = useCallback(
    (lang) => {
      lang = languageMapping[lang] || lang

      // eslint-disable-next-line handle-callback-err
      i18next.changeLanguage(lang, (err, t) => {
        const params = getValidLangAndSite()
        const setPath = getValidPath(
          lang.substring(0, 2).toLowerCase(),
          params.site,
          params.extra
        )
        history.push(setPath)
      })
    },
    [getValidLangAndSite, getValidPath, languageMapping, history]
  )

  // <----------------------

  const updateWithTransferToken = useCallback(
    (callbackFunction, transferToken) => {
      if (transferToken) {
        TransferTokenMutation(
          transferToken,
          (requestData) => {
            const transferTokenWasSuccess = handleLoginRequestData(requestData)
            return callbackFunction(transferTokenWasSuccess)
          },
          // eslint-disable-next-line handle-callback-err
          (err) => {
            logout()
            return callbackFunction(err)
          }
        )
      } else {
        return callbackFunction(false)
      }
    },
    [handleLoginRequestData]
  )

  const userTokenExists = useCallback(() => {
    if (user.accessToken && user.refreshToken) {
      return true
    } else {
      return false
    }
  }, [user.accessToken, user.refreshToken])

  const isTokenExpired = useCallback(() => {
    if (!user.accessToken || !user.refreshToken) {
      return true
    }

    const decoded = jwt_decode(user.accessToken)
    if (Date.now() < decoded.exp * 1000) {
      return false
    } else {
      logout(true)
      return true
    }
  }, [user.accessToken, user.refreshToken])

  const isTokenValid = useCallback(
    (callbackFunction) => {
      const transferTokenFunction = updateWithTransferToken
      const params = getValidLangAndSite()

      if (params.extra === 'transfertoken' && params.id) {
        return transferTokenFunction(callbackFunction, params.id)
      } else {
        if (isTokenExpired()) {
          return callbackFunction(false)
        } else {
          return callbackFunction(true)
        }
      }
    },
    [getValidLangAndSite, isTokenExpired, updateWithTransferToken]
  )

  const redirectRoute = (route, rawRoute = false, fromRoute = false) => {
    if (fromRoute) {
      setLastRoute(fromRoute)
    }

    if (rawRoute) {
      history.push(route)
    } else {
      history.push('/' + currentLanguage + route)
    }
  }

  const generateUrl = (route) => {
    return '/' + currentLanguage + route
  }

  const accessToken = user?.accessToken || ''
  const refreshToken = user?.refreshToken || ''
  const isAuth = accessToken.length > 0 && refreshToken.length > 0

  const getCompanySwitcherRedirectIfNeeded = (redirect = '', search = '') => {
    if (handleLocalStorage('get', 'favCompany') === undefined) {
      handleLocalStorage('set', 'showCompanySwitcherAfterLogin', true)
    }

    handleLocalStorage('set', 'companySwitcherViaLogin', true)

    const showCompanySwitcher =
      handleLocalStorage('get', 'showCompanySwitcherAfterLogin') &&
      handleLocalStorage('get', 'multipleCompanies')

    const redirectState = redirect ? { redirect, search } : {}

    if (showCompanySwitcher) {
      return {
        pathname: '/' + currentLanguage + Routes.companySwitcher,
        search: '',
        state: redirectState,
      }
    } else {
      return {
        pathname: redirect || '/' + currentLanguage + Routes.overview,
        search: search || '',
        state: {},
      }
    }
  }

  /**
   useEffect(() => {
    return history.listen((location, action) => {
      console.log('browser button event', action, location, history)
      const { key } = location
      switch (action) {
        case 'POP':
          // If there is no key, it was a goBack.
          if (key === undefined) {
            console.log("goBack")
          }
          else{
            console.log("goForward")
          }
          break
        case 'PUSH':
          break
        default:
          console.log('nothing action', action)
      }
    })
  }, [currentLanguage, getValidPath, history])
   **/
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        login,
        switchCompany,
        logout,
        isTokenExpired,
        updateWithRefreshToken,
        updateWithTransferToken,
        getCompanySwitcherRedirectIfNeeded,
        isTokenValid,
        handleLoginRequestData,
        userTokenExists,
        matchRoute,
        availableLanguages,
        languageMapping,
        getValidLangAndSite,
        getValidPath,
        getValidUrlForLang,
        getValidUrlWithQuery,
        changeLanguage,
        currentLanguage,
        redirectRoute,
        generateUrl,
        isAuth,
        updateLastAction,
        getValidQueryString,
        pushSearchQuery,
        replaceSearchQuery,
        lastRoute,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
