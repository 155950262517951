export const Routes = {
  login: '/login',
  logout: '/logout',
  register: '/register',
  companyLayouts: '/companyLayouts',
  createCompanyLayout: '/createCompanyLayout',
  createCompanyLayoutAdd: '/createCompanyLayoutAdd',
  createCompanyLayoutEdit: '/createCompanyLayoutEdit',
  businessMail: '/businessMail',
  letter: '/letter',
  businessMailNotFound: '/businessMailNotFound',
  businessMailsGrid: '/list',
  businessMailEdit: '/edit',
  businessMailCreate: '/add',
  errorInfo: '/error-info',
  companySwitcher: '/companies',
  support: '/support',
  overview: '/overview',
  appointments: '/appointments',
  appointment: '/appointment',
  contracts: '/contracts',
  invoices: '/invoices',
  invoice: '/invoice',
  propertyManagement: '/property-management',
  propertyManagementPortfolioOverview: '/portfolioOverview',
  propertyManagementOperations: '/operations',
  propertyManagementPortfolioDataUpload: '/portfolioDataUpload',
  commissioning: '/commissioning',
  createAppointment: '/create-appointment',
  appointmentManagement: '/appointment-management',
  help: '/help',
  contact: '/contact',
  faq: '/faq',
  downloadCenter: '/download-center',
  rateUs: '/rate-us',
  newsletter: '/register-newsletter',
  termsOfService: '/terms-of-service',
  privacy: '/privacy',
  imprint: '/imprint',
  qr: '/qr',
  usageUnit: '/usage-unit',
  propertyUnit: '/property-unit',
  user: '/user',
  editEmail: '/user/email',
  editPassword: '/user/password',
  notifications: '/notifications',
  status: '/status',
  admin: '/admin',
  userList: '/user-list',
  roleInformation: '/role-information',
  createUser: '/create-user',
  livechat: '/livechat',
  contractPerformance: '/performance',
  contractControl: '/control',
  contractQuota: '/quota',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  requestPassword: '/request-password',
  logoUpload: '/logo-upload',
  enquiries: '/enquiries',
}
