import styled, { css } from 'styled-components/macro'

export const SupportContainer = styled.div`
  display: flex;
  gap: 16px;
`

export const SupportColumn = styled.div`
  flex: 1;

  position: relative;

  ${(props) =>
    props.withScroll &&
    css`
      height: 600px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-right: 5px;
    `}
`
