/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSalutationType = "COMPANY" | "MR" | "MRS" | "NONE" | "%future added value";
export type SubmitContactFormMutationVariables = {|
  salutation?: ?UserSalutationType,
  lastName: string,
  firstName: string,
  phoneNumber?: ?string,
  email: string,
  contactDepartmentValue?: ?string,
  subject?: ?string,
  content: string,
|};
export type SubmitContactFormMutationResponse = {|
  +submitContactForm: ?boolean
|};
export type SubmitContactFormMutation = {|
  variables: SubmitContactFormMutationVariables,
  response: SubmitContactFormMutationResponse,
|};
*/


/*
mutation SubmitContactFormMutation(
  $salutation: UserSalutationType
  $lastName: String!
  $firstName: String!
  $phoneNumber: String
  $email: String!
  $contactDepartmentValue: String
  $subject: String
  $content: String!
) {
  submitContactForm(salutation: $salutation, lastName: $lastName, firstName: $firstName, email: $email, subject: $subject, content: $content, phoneNumber: $phoneNumber, contactDepartmentValue: $contactDepartmentValue)
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contactDepartmentValue"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "content"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phoneNumber"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "salutation"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subject"
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contactDepartmentValue",
        "variableName": "contactDepartmentValue"
      },
      {
        "kind": "Variable",
        "name": "content",
        "variableName": "content"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "phoneNumber",
        "variableName": "phoneNumber"
      },
      {
        "kind": "Variable",
        "name": "salutation",
        "variableName": "salutation"
      },
      {
        "kind": "Variable",
        "name": "subject",
        "variableName": "subject"
      }
    ],
    "kind": "ScalarField",
    "name": "submitContactForm",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitContactFormMutation",
    "selections": (v8/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v7/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SubmitContactFormMutation",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "92420809f7e48c97a1af2d47c4f95116",
    "id": null,
    "metadata": {},
    "name": "SubmitContactFormMutation",
    "operationKind": "mutation",
    "text": "mutation SubmitContactFormMutation(\n  $salutation: UserSalutationType\n  $lastName: String!\n  $firstName: String!\n  $phoneNumber: String\n  $email: String!\n  $contactDepartmentValue: String\n  $subject: String\n  $content: String!\n) {\n  submitContactForm(salutation: $salutation, lastName: $lastName, firstName: $firstName, email: $email, subject: $subject, content: $content, phoneNumber: $phoneNumber, contactDepartmentValue: $contactDepartmentValue)\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'dd3dc1bf712a192ccb942adbc70eb98b';

module.exports = node;
