import React from 'react'
import { useTranslation } from 'react-i18next'
import { UsageUnitLockPeriodStatusMap } from '../../../constants/colorMap'
import StatusLightWithLabel from '../../../components/Status/StatusLightWithLabel'
import { usageUnitLockPeriodStatusEnumList } from '../../../components/AgGrid/enumLists'

export default (props) => {
  const { value } = props
  const { t } = useTranslation()

  return (
    <StatusLightWithLabel
      status={UsageUnitLockPeriodStatusMap[value]}
      label={t(usageUnitLockPeriodStatusEnumList(value))}
    />
  )
}
