function dummyTranslate(text) {
  return text
}
const roomTypeEnumList = (value) => {
  switch (value) {
    case 'CORRIDOR':
      return dummyTranslate('CORRIDOR')

    case 'BEDROOM':
      return dummyTranslate('BEDROOM')

    case 'CHILDRENS_BEDROOM':
      return dummyTranslate('CHILDRENS BEDROOM')

    case 'GUESTROOM':
      return dummyTranslate('GUESTROOM')

    case 'LIVINGROOM':
      return dummyTranslate('LIVINGROOM')

    case 'OFFICE_WORKROOM':
      return dummyTranslate('OFFICE WORKROOM')

    case 'COMMON_SPACES':
      return dummyTranslate('COMMON SPACES')

    case 'KITCHEN':
      return dummyTranslate('KITCHEN')

    case 'ROOM':
      return dummyTranslate('ROOM')

    case 'WC_BATHROOM':
      return dummyTranslate('WC BATHROOM')

    case 'DININGROOM':
      return dummyTranslate('DININGROOM')

    case 'COMMON_SPACES_BASEMENT':
      return dummyTranslate('COMMON SPACES BASEMENT')

    case 'COMMON_SPACES_ATTIC':
      return dummyTranslate('COMMON SPACES ATTIC')

    case 'WALK_THROUGH_ROOM':
      return dummyTranslate('WALK THROUGH ROOM')

    case 'LIVINGROOM_BEDROOM':
      return dummyTranslate('LIVINGROOM BEDROOM')

    case 'LOGGIA':
      return dummyTranslate('LOGGIA')

    case 'NONE':
    default:
      return ''
  }
}

export default roomTypeEnumList
