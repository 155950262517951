/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AppointmentAccessType = "ACCESS" | "NONE" | "NO_ACCESS_22" | "NO_ACCESS_23" | "NO_ACCESS_24" | "NO_ACCESS_25" | "NO_ACCESS_26" | "NO_ACCESS_27" | "NO_ACCESS_28" | "NO_ACCESS_29" | "NO_ACCESS_30" | "NO_ACCESS_31" | "NO_ACCESS_32" | "NO_ACCESS_33" | "NO_ACCESS_34" | "NO_ACCESS_35" | "NO_ACCESS_36" | "NO_ACCESS_37" | "NO_ACCESS_38" | "NO_ACCESS_39" | "NO_ACCESS_40" | "NO_ACCESS_AWAY" | "NO_ACCESS_DENIED" | "NO_ACCESS_DENIED_NEED_RESPITE" | "NO_ACCESS_FOREIGN_SD_INSTALLED_NO_MAINTENANCE" | "NO_ACCESS_HYGIENICALLY_INFEASIBLE" | "NO_ACCESS_INFEASIBLE" | "NO_ACCESS_JUNCTION_BOX_BROKEN" | "NO_ACCESS_JUNCTION_BOX_DESTROYED" | "NO_ACCESS_JUNCTION_BOX_GRAFFITI_FOUND" | "NO_ACCESS_JUNCTION_BOX_INACCESSIBLE" | "NO_ACCESS_JUNCTION_BOX_MISSING" | "NO_ACCESS_JUNCTION_BOX_OPENED" | "NO_ACCESS_NO_ADULT_ON_SITE" | "NO_ACCESS_PARTLY_DONE_SOME_LOCATIONS_LOCKED" | "NO_ACCESS_RAMSHACKLE_SURFACE" | "NO_ACCESS_RENOVATION" | "NO_ACCESS_TENANT_OWNS_RM" | "NO_ACCESS_TENANT_THERE_INPUT_IMPOSSIBLE" | "NO_ACCESS_VACANT_KEY_DOES_NOT_FIT" | "NO_ACCESS_VACANT_NO_KEY" | "%future added value";
export type AppointmentTypeType = "P" | "REMOTE_DATA_COLLECTION" | "T1" | "T2" | "TA" | "TI" | "TS" | "TX" | "%future added value";
export type ConditionType = "contains" | "endsWith" | "equals" | "greaterThan" | "greaterThanOrEqual" | "inRange" | "lessThan" | "lessThanOrEqual" | "notContains" | "notEqual" | "startsWith" | "%future added value";
export type FilterType = "date" | "number" | "text" | "%future added value";
export type OperatorType = "AND" | "OR" | "%future added value";
export type Sort = {|
  colId?: ?string,
  sort?: ?string,
|};
export type Filter = {|
  name: string,
  filterType: FilterType,
  operator: OperatorType,
  denySelection?: ?boolean,
  conditions: $ReadOnlyArray<?Condition>,
|};
export type Condition = {|
  value: $ReadOnlyArray<?string>,
  type: ConditionType,
|};
export type Filter4SingleServiceProvidedQueryVariables = {|
  companyId: string,
  startRow?: ?number,
  endRow?: ?number,
  sortModel?: ?$ReadOnlyArray<?Sort>,
  filters?: ?$ReadOnlyArray<?Filter>,
|};
export type Filter4SingleServiceProvidedQueryResponse = {|
  +Me: ?{|
    +Company: ?{|
      +data: ?$ReadOnlyArray<?{|
        +id: ?string,
        +tableId: ?number,
        +registrations: $ReadOnlyArray<?{|
          +actionPosition: ?{|
            +id: string,
            +name: ?string,
          |}
        |}>,
        +usageUnit: ?{|
          +id: string,
          +referenceNumber: string,
          +tableId: number,
        |},
        +address: ?{|
          +city: string,
          +street: string,
          +number: string,
        |},
        +tenant: ?{|
          +id: ?string,
          +firstname: string,
          +lastname: string,
        |},
        +referenceNumber: ?string,
        +accessReason: ?AppointmentAccessType,
        +executionDate: ?any,
        +type: ?AppointmentTypeType,
        +billingPartition: ?{|
          +id: string,
          +name: string,
        |},
      |}>
    |}
  |}
|};
export type Filter4SingleServiceProvidedQuery = {|
  variables: Filter4SingleServiceProvidedQueryVariables,
  response: Filter4SingleServiceProvidedQueryResponse,
|};
*/


/*
query Filter4SingleServiceProvidedQuery(
  $companyId: ID!
  $startRow: Int
  $endRow: Int
  $sortModel: [Sort]
  $filters: [Filter]
) {
  Me {
    Company(id: $companyId) {
      data: Appointments(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {
        id
        tableId
        registrations(options: {rowGroupCols: [{id: "actionPosition.id"}]}) {
          actionPosition {
            id
            name
          }
          id
        }
        usageUnit {
          id
          referenceNumber
          tableId
        }
        address {
          city
          street
          number
        }
        tenant {
          id
          firstname
          lastname
        }
        referenceNumber
        accessReason
        executionDate
        type
        billingPartition {
          id
          name
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "endRow"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortModel"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "startRow"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "endRow",
        "variableName": "endRow"
      },
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "sorting",
        "variableName": "sortModel"
      },
      {
        "kind": "Variable",
        "name": "startRow",
        "variableName": "startRow"
      }
    ],
    "kind": "ObjectValue",
    "name": "options"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tableId",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "options",
    "value": {
      "rowGroupCols": [
        {
          "id": "actionPosition.id"
        }
      ]
    }
  }
],
v10 = [
  (v7/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "ActionPosition",
  "kind": "LinkedField",
  "name": "actionPosition",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referenceNumber",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "UsageUnit",
  "kind": "LinkedField",
  "name": "usageUnit",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v12/*: any*/),
    (v8/*: any*/)
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Address",
  "kind": "LinkedField",
  "name": "address",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenant",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastname",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "accessReason",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "executionDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "BillingPartition",
  "kind": "LinkedField",
  "name": "billingPartition",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Filter4SingleServiceProvidedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v6/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointments",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "Registration",
                    "kind": "LinkedField",
                    "name": "registrations",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/)
                    ],
                    "storageKey": "registrations(options:{\"rowGroupCols\":[{\"id\":\"actionPosition.id\"}]})"
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v12/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "Filter4SingleServiceProvidedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "Me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "Company",
            "plural": false,
            "selections": [
              {
                "alias": "data",
                "args": (v6/*: any*/),
                "concreteType": "Appointment",
                "kind": "LinkedField",
                "name": "Appointments",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "Registration",
                    "kind": "LinkedField",
                    "name": "registrations",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": "registrations(options:{\"rowGroupCols\":[{\"id\":\"actionPosition.id\"}]})"
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v12/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae654d2eecddd9c2b67eb8a33e8d57c6",
    "id": null,
    "metadata": {},
    "name": "Filter4SingleServiceProvidedQuery",
    "operationKind": "query",
    "text": "query Filter4SingleServiceProvidedQuery(\n  $companyId: ID!\n  $startRow: Int\n  $endRow: Int\n  $sortModel: [Sort]\n  $filters: [Filter]\n) {\n  Me {\n    Company(id: $companyId) {\n      data: Appointments(options: {startRow: $startRow, endRow: $endRow, sorting: $sortModel, filters: $filters}) {\n        id\n        tableId\n        registrations(options: {rowGroupCols: [{id: \"actionPosition.id\"}]}) {\n          actionPosition {\n            id\n            name\n          }\n          id\n        }\n        usageUnit {\n          id\n          referenceNumber\n          tableId\n        }\n        address {\n          city\n          street\n          number\n        }\n        tenant {\n          id\n          firstname\n          lastname\n        }\n        referenceNumber\n        accessReason\n        executionDate\n        type\n        billingPartition {\n          id\n          name\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '633eb47a56de9736ad54a4487676a403';

module.exports = node;
