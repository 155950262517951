import React, { useCallback, useContext, useState } from 'react'
import { fetchQuery } from 'react-relay'
import styled from 'styled-components/macro'

import { Icon, LoadingProgressBar } from 'pyrexx-react-library'

import environment from '../../environments/pom/Environment'
import UserContext from '../../helper/userContext'
import { StyledMountingAnimation } from '../../styles/HelperStylesComponents.styles'

const Container = styled.div`
  text-align: center;
`

const StyledIcon = styled(Icon)`
  cursor: pointer;
`

const SinglePdfDownload = (props) => {
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(0)

  const { accessToken = '' } = user || {}

  const variables = {
    [props.type + 'Id']: props.value,
    companyId: user?.companyId,
  }

  let fileName = ''
  let type = ''
  switch (props.type) {
    case 'invoice':
      fileName = 'InvoiceDownload'
      type = 'Invoice'
      break
    case 'appointment':
      fileName = 'AppointmentDownload'
      type = 'Appointment'
      break
    default:
  }

  const startDownload = useCallback(() => {
    import('../../downloadQueries/' + fileName + '.js')
      .then((DownloadQuery) => {
        setLoading(true)
        fetchQuery(environment(accessToken), DownloadQuery, variables, {
          force: true,
        })
          .then((res) => {
            initBrowserDownload(res.Me?.Company?.[type]?.download)
          })
          .catch((e) => {
            console.log(e)
          })
      })
      .catch((e) => {
        console.log(e)
      })
  }, [fileName, type, accessToken, variables])

  const initBrowserDownload = async (downloadDetails) => {
    const response = await fetch(
      process.env.REACT_APP_DOWNLOAD_PATH + downloadDetails.path,
      {
        headers: {
          Authorization: 'Bearer ' + downloadDetails.token,
          Accept: 'application/pdf',
        },
      }
    )

    const reader = response.body.getReader()

    // Step 2: get total length
    const contentLength = +response.headers.get('Content-Length')

    // Step 3: read the data
    let receivedLength = 0 // received that many bytes at the moment
    const chunks = [] // array of received binary chunks (comprises the body)
    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        setLoading(false)
        setDownloadProgress(0)
        break
      }

      chunks.push(value)
      receivedLength += value.length
      setDownloadProgress(Math.floor(receivedLength / (contentLength / 100)))

      console.log(`Received ${receivedLength} of ${contentLength}`)
    }

    const blob = new Blob(chunks, { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)

    const tempLink = document.createElement('a')
    tempLink.href = url
    tempLink.setAttribute('download', downloadDetails.name)
    tempLink.click()
    window.URL.revokeObjectURL(url)
  }

  const content = props.children ?? <StyledIcon icon='detail' size='16px' />
  return (
    <StyledMountingAnimation
      transitionName={fileName}
      transitionEnterTimeout={500}
      transitionLeaveTimeout={2000}
    >
      {loading && <LoadingProgressBar boxSpinner complete={downloadProgress} />}
      <Container onClick={startDownload}>{content}</Container>
    </StyledMountingAnimation>
  )
}

export default SinglePdfDownload
