import graphql from 'babel-plugin-relay/macro'

const FetchContactDepartmentsQuery = graphql`
  query FetchContactDepartmentsQuery {
    Config {
      contactDepartments {
        value
        label
      }
    }
  }
`

export default FetchContactDepartmentsQuery
