import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { useHistory } from 'react-router-dom'

import SimpleLink from '../Simple/SimpleLink'
import BackButtonLabel from './BackButtonLabel'

import { LinkButtonStyled } from '../../styles/HelperStylesComponents.styles'

const BackButton = ({ to = '/', historyBack = false }) => {
  const history = useHistory()

  const noHistory = history.length <= 1

  return (
    <>
      {historyBack ? (
        <LinkButtonStyled
          type='button'
          onClick={() => {
            history.goBack()
          }}
          style={{
            position: 'absolute',
            left: '0',
          }}
        >
          <BackButtonLabel disabled={noHistory} />
        </LinkButtonStyled>
      ) : (
        <SimpleLink
          to={to}
          style={{
            position: 'absolute',
            left: '0',
          }}
        >
          <BackButtonLabel />
        </SimpleLink>
      )}
    </>
  )
}

const NavBack = (props) => {
  const { title = '', to = '/', historyBack = false, ...restOfProps } = props

  return (
    <Wrapper {...restOfProps}>
      <BackButton to={to} historyBack={historyBack} />
      <h2>{title}</h2>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;

  position: relative;
`

NavBack.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  historyBack: PropTypes.bool,
}

export default NavBack
