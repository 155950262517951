import React, { useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import QueryRendererContainer from '../../components/QueryRendererContainer'
import PyrexxOverview from '../../components/PyrexxOverview'

import { InvoicesOverviewQuery } from './queries'
import UserContext from '../../helper/userContext'
import {
  filterOpen,
  filterPaid,
  filterReminded,
} from '../../constants/invoiceFilterModels'

const InvoicesOverview = (props) => {
  const { setFilterModel } = props

  const { user } = useContext(UserContext)
  const { t } = useTranslation()

  const defaultFilters = {
    companyId: user.companyId,
    dateFilter: [],
    serviceFilter: [],
    contractFilter: [],
  }

  const customKeys = {
    customDateFilterKey: 'invoiceDate',
  }

  const [variables, setVariables] = useState({})

  const { titleElements, rowElements } = getElements(null, t)

  return (
    <QueryRendererContainer
      query={InvoicesOverviewQuery}
      variables={{
        ...defaultFilters,
        ...variables,
      }}
      loading={
        <PyrexxOverview
          titleElements={titleElements}
          rowElements={rowElements}
          setFilterModel={setFilterModel}
          setVariables={setVariables}
          customKeys={customKeys}
          loading
        />
      }
      render={(data) => {
        const contracts = data?.Me?.Company?.Contracts
        const statuses = data?.Me?.Company

        const { titleElements, rowElements } = getElements(statuses, t)

        return (
          <PyrexxOverview
            contracts={contracts}
            titleElements={titleElements}
            rowElements={rowElements}
            setFilterModel={setFilterModel}
            setVariables={setVariables}
            customKeys={customKeys}
          />
        )
      }}
    />
  )
}

export default InvoicesOverview

const getElements = (statuses = {}, t = () => {}) => {
  const { paid = 0, open = 0, reminded = 0 } = statuses || {}

  const titleElements = [
    { id: 'paid', label: t('PAID') },
    { id: 'open', label: t('OPEN') },
    { id: 'reminded', label: t('DUNNING LETTER') },
  ]

  const rowElements = [
    {
      id: 'default',
      columns: [
        {
          id: 'paid',
          label: paid,
          status: 'success',
          filter: {
            filterId: 'box-paid',
            filterRow: 'default',
            label: t('PAID'),
            filterModel: filterPaid,
          },
        },
        {
          id: 'open',
          label: open,
          status: 'warning',
          filter: {
            filterId: 'box-open',
            filterRow: 'default',
            label: t('OPEN'),
            filterModel: filterOpen,
          },
        },
        {
          id: 'reminded',
          label: reminded,
          status: 'error',
          filter: {
            filterId: 'box-reminded',
            filterRow: 'default',
            label: t('DUNNING LETTER'),
            filterModel: filterReminded,
          },
        },
      ],
    },
  ]

  return {
    titleElements,
    rowElements,
  }
}
